<div class="form-log-container">
    <div class="form-log p-2 mb-2 {{disabled() != null?'d-none':''}}">
        <div class="d-flex flex-row pb-1">
            <div class="form-log-name mr-auto">{{getUserName()}}</div>
        </div>
        <div class="form-new-message-input pt-1">
            <textarea #messageInput [disabled]="isLoading" [maxLength]="NEW_MESSAGE_MAX_CHARS" [placeholder]="'comment.newmessage' | translate"></textarea>
        </div>
        <div class="d-flex pt-1">
            <div class="form-new-message-counter">{{getCharacterCount()}}</div>
            <div style="margin-right: 0; margin-left: auto;">
                <lumi-button (onClick)="handleClickAddComment($event)"
                             [disabled]="isLoading"
                             [label]="'comment.submit' | translate"
                             [size]="'small'"
                             [icon]="'done'"
                             [rank]="'secondary'"
                             [title]="'comment.add' | translate">
                </lumi-button>
            </div>
        </div>
    </div>
    <ng-container *ngFor="let comment of comments">
        <complex-list-item class="{{comment.isNew?'animated fadeIn':''}}"
                           [style.color]="comment.isDeleted?AppSettings.getColor('$selection-gray'):''"
                           [item]="comment.listItem"
                           [smallTitle]="true"
                           [readonly]="disabled()"
                           (onMenuItemClick)="onCommentAction($event, comment)">
            <div *ngIf="comment.message != ''; else noMessage" class="form-log-message pt-1" [innerText]="comment.message"></div>
            <ng-template #noMessage>
                <div class="form-log-message no-message pt-1">{{'log.nomessage' | translate}}</div>
            </ng-template>
        </complex-list-item>
    </ng-container>
    <div *ngIf="disabled() != null && comments.length <= 0">
        {{'comment.nomessages' | translate}}
    </div>
</div>
