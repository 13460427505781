import {DashboardRow} from "./dashboard-row";

/**
 * Created by Christiaan on 16/03/2017.
 */
export class Dashboard
{
    id:number
    title:string
    code:string


    /*@Type(() => Widget)
    widgets:Widget[]*/

    /*@Type(() => DashboardRow)*/
     rows:DashboardRow[]
}
