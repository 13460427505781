import {Component, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import {MapIconOption} from './map-icon-selector.interface';

@Component({
    selector: 'map-icon-selector',
    templateUrl: './map-icon-selector.component.html'
})
export class MapIconSelectorComponent implements OnInit {
    @Input() disabled: boolean;
    @Input() readonly: boolean;
    @Input() mapIconOptions: MapIconOption[] = [];
    @Input() selectedMapIcon: MapIconOption;
    @Output() onMapIconSelect: EventEmitter<MapIconOption> = new EventEmitter();
    
    public showDropdown: any = false;
    
    constructor() {
    }
    
    ngOnInit() {
    }
    
    public toggleDropDown($event: MouseEvent): void {
        $event.preventDefault();
        $event.stopImmediatePropagation();
        this.showDropdown = !this.showDropdown;
    }
    
    public handleClickOption($event: MouseEvent, option: MapIconOption): void {
        this.selectedMapIcon = option;
        this.onMapIconSelect.emit(option);
    }
    
    public closeDropdown(): void {
        this.showDropdown = false;
    }
}
