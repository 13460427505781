import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {HTTPService} from '../../../shared/services/http/http.service';
import {LoginService} from '../login.service';
import {GlobalModel} from '../../../shared/services/state/global.model';
import {Router} from '@angular/router';
import {RequestFailure} from '../../../shared/services/http/request-failure';
import {HTTPError} from '../../../shared/services/http/http-error';
import {AreaalService} from '../../../shared/services/areaal/areaal.service';
import {LuminizerRoutes} from '../../../shared/interfaces/routes';
import {User} from "../user";

@Component({
    selector: 'two-factor-auth-component',
    templateUrl: 'two-factor-auth.component.html',
    styleUrls: ['two-factor-auth.component.scss']
})
export class TwoFactorAuthComponent implements OnInit {
    @ViewChild('twoFactorAuthInputField', {static: false}) twoFactorAuthInputField: ElementRef;

    twoFactorError: boolean = false;
    twoFactorErrorText: string = '';
    mobileMode: boolean = false;
    public flippingLanguage:boolean = false;
    private checkingLoginStatus:boolean = false;
    public _user:User = null;
    public disableSubmitButton:boolean = false;

    public rememberMe:boolean = true;
    public authenticationMethod:string = '';
    private sendEmailUrl: string = 'v2/2fa_mail';
    public timeTillEmailResend:number = 0;
    private static secondsToWaitForEmailResend: number = 30;

    constructor(public httpService: HTTPService, private model: GlobalModel, private cd: ChangeDetectorRef, private router: Router, private areaalService: AreaalService, private loginService: LoginService) {
        const userAgent = navigator.userAgent;

        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(userAgent)) {
            this.mobileMode = true;
        }
    }

    ngOnInit() {
        //Call once on loading so details about the 2fa method are loaded in.
        this.initiateTwoFactorAuthentication();
    }

    private initiateTwoFactorAuthentication():void{
        //Get 2fa details from server
        this.httpService.doPostRequest('v2/2fa_check', {}, (callback) => {
            if (callback.user.twoFactorInProgress) {
                this.authenticationMethod = callback.user.twoFactorCurrentMethod
                this.sendEmailUrl = callback.user.twoFactorUrl
                this.cd.detectChanges();

                // php autpmatically sends email when option is preferred as first
                if(this.authenticationMethod === 'email'){
                   this.sendAuthenticationEmail(false);
                }
                //Set listeners
                const inputField:HTMLInputElement = document.querySelector('[autocomplete=one-time-code]');
                inputField.addEventListener('input', () => {
                    if(inputField.value.toString().length <= 6){
                        this.setActiveFieldPosition(inputField.selectionStart)
                    }
                });
                inputField.addEventListener('paste', () => {
                    let totalFieldLength:number = inputField.value.toString().length;
                    if(totalFieldLength > 6){
                        totalFieldLength = 6
                    }
                    this.setActiveFieldPosition(totalFieldLength)
                });
            }
        }, () => {}, () => {}, false, true);
    }

    public sendAuthenticationEmail(sendEmail = true):void{
        if(this.timeTillEmailResend === 0){
            this.timeTillEmailResend = TwoFactorAuthComponent.secondsToWaitForEmailResend;

            //Send notice to backend to send email
            if (sendEmail) {
                this.httpService.doPostRequest(this.sendEmailUrl, {}, () => {
                }, () => {
                }, () => {
                }, false, true);
            }
            //Set timer before the email can be sent again
            let countdownTimer = setInterval(()=>{
                this.timeTillEmailResend--;
                if(this.timeTillEmailResend === 0){
                    clearInterval(countdownTimer)
                }
                this.cd.detectChanges()
            }, 1000)
        }
    }

    private setActiveFieldPosition(actualPosition:number):void{
        const position = (actualPosition === 6) ? 5 : actualPosition
        const inputField:HTMLInputElement = document.querySelector('[autocomplete=one-time-code]');
        inputField.style.setProperty('--_otp-digit', String(position))

        if(actualPosition === 6){
            this.handleClickLogin()
        }
    }

    public getLogoPath(): string {
        if(this.model.isXmasMode()){
            return 'assets/img/luminizerlogo-xmas.png';
        } else {
            return '/assets/img/luminizerlogo.png';
        }
    }

    public handleFlippingLanguage(flippingLanguage:boolean){
        this.flippingLanguage = flippingLanguage;
    }

    public isLoginVisible(user:User):boolean{
        return !this.checkingLoginStatus && !this.loginService.isLoggedInLocally(user) && !this.flippingLanguage;
    }

    public handleKeyDown(e: KeyboardEvent) {
        if (e.key == 'Enter') {
            this.handleClickLogin();
        }
    }

    public handleClickLogin() {
        let authCode: number = null;
        authCode = this.twoFactorAuthInputField.nativeElement.value;
        this.disableSubmitButton = true;

        const rememberMePost:number = this.rememberMe ? 1 : 0;
        let postValues = {_auth_code: authCode, _trusted: rememberMePost};
        this.httpService.doPostRequest('v2/2fa_check', postValues, (callback) => {
            if (callback.user.twoFactorInProgress) {
                this.twoFactorAuthInputField.nativeElement.value = '';
                this.setActiveFieldPosition(0)
                this.twoFactorError = true;
                this.twoFactorErrorText = callback.user.authenticationError;
                this.disableSubmitButton = false;
                this.cd.detectChanges();
                this.twoFactorAuthInputField.nativeElement.focus();
            }
            else {
                this.areaalService.processAreaalData(callback, '');
                this.router.navigate([LuminizerRoutes.DASHBOARD_PAGE]);
            }
        }, (failure: RequestFailure) => {
            // The username is not valid or other failure
            // failCallBack(failure);
        }, (error: HTTPError) => {
            // errorCallBack(error);
        }, false, true);
    }

    public handleCancel(event: MouseEvent) {
        this.loginService.logout(false, () => {
        }, () => {
        });
    }
}
