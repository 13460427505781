
import {Injectable, NgZone, Renderer2} from "@angular/core";
import {LoggerService} from "../logger/logger.service";

@Injectable() export class TooltipService {

    public static readonly TOOLTIP_MODE_CLICK:string = "click";
    public static readonly TOOLTIP_MODE_HOVER:string = "hover";
    public static readonly TOOLTIP_MODE_MANUAL:string = "manual";

    public static readonly PLACEMENT_BOTTOM:string = "bottom";
    public static readonly PLACEMENT_TOP:string = "top";
    public static readonly PLACEMENT_LEFT:string = "left";
    public static readonly PLACEMENT_RIGHT:string = "right";

    constructor(private zone:NgZone, protected logger:LoggerService) {}

    public destroyToolTip(element:any)
    {
        if (element && element.nativeElement) {
            let selection:any = jQuery(element.nativeElement);
            selection.tooltip('dispose');// destroy, allow for new content
        }
    }

    public createToolTip(renderer:Renderer2, element:any, content:string, tooltipPlacement:string = TooltipService.PLACEMENT_BOTTOM, tooltipMode:string = "click", tooltipClass:string = "", alwaysOnTop:boolean = false)
    {
        // Remove existing tooltips on this element
        this.destroyToolTip(element);

        if (element && element.nativeElement) {

            this.zone.runOutsideAngular(() => {
                this.destroyToolTip(element);

                let selection:any = jQuery(element.nativeElement);

                renderer.setAttribute(element.nativeElement, 'data-toggle', 'tooltip');
                renderer.setAttribute(element.nativeElement, 'data-placement', tooltipPlacement);
                renderer.setAttribute(element.nativeElement, 'title', content);
                renderer.setAttribute(element.nativeElement, 'container', 'form-component');

                let htmlElement: string = '<div class="tooltip top-most"><div class="tooltip-arrow"></div><div class="tooltip-inner"></div></div>';

                if (!alwaysOnTop) {
                    htmlElement = htmlElement.replace('top-most', '');
                }

                if (tooltipClass != "") {
                    htmlElement = htmlElement.replace('tooltip-arrow', 'tooltip-arrow ' + tooltipClass);
                    htmlElement = htmlElement.replace('tooltip-inner', 'tooltip-inner ' + tooltipClass);
                }

                let config = {
                    html: true,
                    animation: false,
                    trigger: tooltipMode,
                    template: htmlElement,
                    constraints: [
                        {
                            to: 'scrollParent',
                            attachment: 'together',
                            pin: ["left", "right"]
                        }
                    ]
                };

                selection.tooltip(config);
            });
        } else {
            this.logger.log('[TooltipService]' + 'create error - provided element is not of type ElementRef');
        }
    }

    public hideAllTooltips():void
    {
        let selection:any = jQuery(".tooltip");
        selection.tooltip("hide");
    }

    public showTooltip(element:any)
    {
        if (element && element.nativeElement) {
            let selection: any = jQuery(element.nativeElement);
            selection.tooltip('show');
        } else {
            this.logger.log('[TooltipService]' + 'show error - provided element is not of type ElementRef');
        }
    }

    public createAndShowTooltip(renderer:Renderer2, element:any, content:string, tooltipPlacement:string = TooltipService.PLACEMENT_BOTTOM, tooltipMode:string = TooltipService.TOOLTIP_MODE_MANUAL, tooltipClass:string = "", alwaysOnTop:boolean = false)
    {
        this.createToolTip(renderer, element, content, tooltipPlacement, tooltipMode, tooltipClass, alwaysOnTop);
        this.showTooltip(element);
    }
}
