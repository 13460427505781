import {Injectable} from '@angular/core';
import {CellData, DataType, HeaderData, RowData, TableData} from './baseTable.interface';
import {TableCell, TableColumn, TableRow} from '../../../map-table/map-table.interface';

@Injectable()
export class BaseTableService {
    constructor() {
    }
    
    public createTableData(tableRows: TableRow[], tableColumns: TableColumn[]): TableData {
        let headers: HeaderData[] = this.createHeaderData(tableColumns);
        let rows: RowData[] = this.createRowData(tableRows, tableColumns);
        return {
            headers: [...headers],
            rows: [...rows]
        };
    }
    
    public createHeaderData(tableColumns: TableColumn[]): HeaderData[] {
        return tableColumns.map((tableColumn: TableColumn, index: number) => {
            return <HeaderData>{
                columnId: tableColumn.id ? tableColumn.id : index,
                label: tableColumn.label,
                code: tableColumn.code,
                type: tableColumn.type,
                bold: tableColumn.bold,
                sorting: tableColumn.sorting,
                sortDirection: tableColumn.sortDirection,
                isVisible: tableColumn.type === DataType.ACTION || tableColumn.type === DataType.ID ? false : tableColumn.visible
            };
        });
    }
    
    public createRowData(tableRows: TableRow[], tableColumns: TableColumn[]): RowData[] {
        const idColumnIndex = tableColumns.findIndex(header => header.type === DataType.ID);
        
        return tableRows.map((tableRow: TableRow, index: number) => {
            return <RowData>{
                uniqueId: tableRow[idColumnIndex] ? tableRow[idColumnIndex].label : index,
                cells: [...tableRow].map((cell: TableCell, _index: number) => {
                    return this.createCellData(cell, tableColumns[_index]);
                })
            };
        });
    }
    
    private createCellData(cell: TableCell, header: TableColumn): CellData {
        return {
            dataType: header.type as DataType,
            label: cell.label,
            children: cell.children
        };
    }
}
