<default-popup-wrapper-component [globalPopup]="globalPopup" [title]="'Legenda' | translate" (onClose)="closePopup($event)" style="max-width: 700px">
    <hr class="my-0">
    <div class="map-option-label-maptype mt-2"><b>{{'Bewerkingen' | translate}}</b>
        <ng-container *ngIf="!mobileMode">
            <ul class="pl-3" style="zoom: 80%">
                <li *ngIf="!allowSingleSelect && !allowMultiSelect && !allowMarkerDrag && !allowCreateMarker">{{'legend.noedit' | translate}}</li>

                <!--                        OLD STYLE LEGEND ITEM-->
                <!--                        <li *ngIf="allowMultiSelect">{{'legend.click' | translate}}-->
                <!--                            <div class="align-right">-->
                <!--                                <b>{{'legend.clickitem' | translate}}</b>-->
                <!--                            </div>-->
                <!--                        </li>-->

                <div *ngIf="allowMultiSelect" class="d-flex">
                    <div class="w-50 d-flex flex-column">
                        <div class="d-flex align-items-center">{{'legend.click' | translate}}</div>
                    </div>
                    <div class="w-50 d-flex flex-column">
                        <div class="d-flex align-items-center"><b>{{'legend.clickitem' | translate}}</b></div>
                    </div>
                </div>

                <div *ngIf="!allowMultiSelect && allowSingleSelect" class="d-flex">
                    <div class="w-50 d-flex flex-column">
                        <div class="d-flex align-items-center">{{'legend.cmdtoggle' | translate}}</div>
                    </div>
                    <div class="w-50 d-flex flex-column">
                        <div class="d-flex align-items-center"><keyboard-button-icon></keyboard-button-icon> + <b>{{'legend.clickitem' | translate}}</b></div>
                    </div>
                </div>

                <div *ngIf="allowMultiSelect" class="d-flex">
                    <div class="w-50 d-flex flex-column">
                        <div class="d-flex align-items-center">{{'legend.cmdclick' | translate}}</div>
                    </div>
                    <div class="w-50 d-flex flex-column">
                        <div class="d-flex align-items-center"><keyboard-button-icon></keyboard-button-icon> + <b>{{'legend.clickitem' | translate}}</b></div>
                    </div>
                </div>

                <div *ngIf="allowMultiSelect" class="d-flex">
                    <div class="w-50 d-flex flex-column">
                        <div class="d-flex align-items-center">{{'legend.shift' | translate}}</div>
                    </div>
                    <div class="w-50 d-flex flex-column">
                        <div class="d-flex align-items-center"><keyboard-button-icon type="SHIFT"></keyboard-button-icon> + <b>{{'legend.dragmap' | translate}}</b></div>
                    </div>
                </div>

                <div *ngIf="allowMultiSelect" class="d-flex">
                    <div class="w-50 d-flex flex-column">
                        <div class="d-flex align-items-center">{{'legend.shiftcombine' | translate}}</div>
                    </div>
                    <div class="w-50 d-flex flex-column">
                        <div class="d-flex align-items-center"><keyboard-button-icon></keyboard-button-icon> + <keyboard-button-icon type="SHIFT"></keyboard-button-icon> + <b>{{'legend.dragmap' | translate}}</b></div>
                    </div>
                </div>

                <div *ngIf="allowMultiSelect" class="d-flex">
                    <div class="w-50 d-flex flex-column">
                        <div class="d-flex align-items-center">{{'legend.ctrla' | translate}}</div>
                    </div>
                    <div class="w-50 d-flex flex-column">
                        <div class="d-flex align-items-center"><keyboard-button-icon></keyboard-button-icon> + <b><keyboard-button-icon type="A"></keyboard-button-icon></b></div>
                    </div>
                </div>

                <div *ngIf="allowMultiSelect" class="d-flex">
                    <div class="w-50 d-flex flex-column">
                        <div class="d-flex align-items-center">{{'legend.deselectall' | translate}}</div>
                    </div>
                    <div class="w-50 d-flex flex-column">
                        <div class="d-flex align-items-center"><keyboard-button-icon></keyboard-button-icon> + <b><keyboard-button-icon type="D"></keyboard-button-icon></b></div>
                    </div>
                </div>

                <div *ngIf="allowMarkerDrag" class="d-flex">
                    <div class="w-50 d-flex flex-column">
                        <div class="d-flex align-items-center">{{((allowEasyMarkerDrag?'legend.easydrag':'legend.drag') | translate)}}</div>
                    </div>
                    <div class="w-50 d-flex flex-column">
                        <div *ngIf="allowEasyMarkerDrag" class="align-right">
                            <div class="d-flex align-items-center"><b>{{'legend.dragmap' | translate}}</b></div>
                        </div>
                        <div *ngIf="!allowEasyMarkerDrag" class="align-right">
                            <div class="d-flex align-items-center"><keyboard-button-icon type="ALT"></keyboard-button-icon> + <b>{{'legend.dragmap' | translate}}</b></div>
                        </div>
                    </div>
                </div>

                <div *ngIf="allowCreateMarker" class="d-flex">
                    <div class="w-50 d-flex flex-column">
                        <div class="d-flex align-items-center">{{'legend.create' | translate}}</div>
                    </div>
                    <div class="w-50 d-flex flex-column">
                        <div class="d-flex align-items-center"><b>{{'legend.holdmap' | translate}}</b></div>
                    </div>
                </div>

                <div class="d-flex">
                    <div class="w-50 d-flex flex-column">
                        <div class="d-flex align-items-center">{{'legend.print' | translate}}</div>
                    </div>
                    <div class="w-50 d-flex flex-column">
                        <div class="align-right">
                            <div class="d-flex align-items-center"><keyboard-button-icon></keyboard-button-icon> + <keyboard-button-icon type="SHIFT"></keyboard-button-icon> + <keyboard-button-icon type="P"></keyboard-button-icon></div>
                        </div>
                    </div>
                </div>
            </ul>
        </ng-container>
        <ng-container *ngIf="mobileMode">
            <ul class="pl-3" style="zoom: 80%">
                <!--                        <li *ngIf="!allowSingleSelect && !allowMarkerDrag && !allowCreateMarker" [innerHTML]="'legend.noedit' | translate"></li>-->
                <li *ngIf="!allowSingleSelect && !allowMarkerDrag && !allowCreateMarker">{{'legend.noedit' | translate}}</li>
                <!--                        <li *ngIf="allowSingleSelect" [innerHTML]="'legend.tap' | translate"></li>-->
                <li *ngIf="allowSingleSelect">{{'legend.tap' | translate}}
                    <div class="align-right">
                        <b>tap item.</b>
                    </div>
                </li>
                <!--                        <li *ngIf="allowCreateMarker" [innerHTML]="'legend.mobilecreate' | translate"></li>-->
                <li *ngIf="allowCreateMarker">{{'legend.mobilecreate' | translate}}
                    <div class="align-right">
                        <b>{{'legend.tapholdmap' | translate}}</b>
                    </div>
                </li>
                <!--                        <li *ngIf="allowMarkerDrag" [innerHTML]="(allowEasyMarkerDrag?'legend.easydrag':'legend.mobilemove') | translate"></li>-->
                <li *ngIf="allowMarkerDrag">{{(allowEasyMarkerDrag?'legend.easydrag':'legend.mobilemove') | translate}}
                    <div class="align-right">
                        <b>{{'legend.taphold' | translate}} + {{'legend.dragitem' | translate}}</b>
                    </div>
                </li>
            </ul>
        </ng-container>
    </div>
    <div class="map-option-label-maptype"><b>{{'Iconen' | translate}}</b>
        <ul class="pl-3" style="zoom: 80%">
            <!--<li><a href="/information/icon-legend" target="_blank">{{'Bekijk legenda' | translate}}</a></li>-->
            <!--                    <li *ngIf="!useCustomIconColors" (click)="handleClickShowIcons($event)"><a href="" >{{'Bekijk legenda' | translate}}</a></li>-->
            <icon-legend-component *ngIf="!useCustomIconColors" [iconSet]="iconSet"></icon-legend-component>
            <li *ngIf="useCustomIconColors">{{'legend.customicons' | translate}}</li>
        </ul>
    </div>
</default-popup-wrapper-component>
