<div class="d-block mt-1 w-100">
        <div class="d-flex">
                <div class="form-label-part">
                        <label class="cursor-default custom-checkbox custom-control m-0 pl-0 pr-2" >
                                <span class="custom-control-description checkbox-description">{{'workorder.ondergronds' | translate}}</span>
                        </label>
                </div>
                <div class="md-form input-group p-0 m-0">
                        <span *ngIf="!config.hasKabelBon && !isCreated" class="form-link-anchor m-0" style="font-size: 1rem;"
                              (click)="handleClickCreateStedinOrder()">{{ 'workorder.createstedinorder' | translate }}</span>
                        <span *ngIf="config.hasKabelBon" class="form-link-anchor m-0" style="font-size: 1rem;"
                              (click)="handleClickViewStedinOrder()"> {{ config.externalId }}</span>
                        <span *ngIf="isCreated" class="m-0" style="font-size: 1rem;">{{ 'workorder.createdstedinorder' | translate }}</span>
                </div>
        </div>
</div>