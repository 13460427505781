export enum LuminizerRoutes {
    INITIAL_PAGE = 'dashboard',
    LOGIN_PAGE = 'login',
    PASSWORD_FORGET_PAGE = 'password-forget',
    PASSWORD_NEW_PAGE = 'password-change',
    USER_INVITE_PAGE = 'user-invite',
    TWO_FACTOR_AUTH_PAGE = 'two-factor-auth',
    FORBIDDEN_PAGE = 'forbidden',
    DASHBOARD_PAGE = 'dashboard',
    ASSETS_PAGE = 'assets',
    USER_MANAGEMENT_PAGE = 'user-management',
    CONTROL_PAGE = 'control',
    INFORMATION_PAGE = 'information',
    DEVICE_MANAGEMENT_PAGE = 'device-management',
    REPORT_MALFUNCTION_PAGE = 'report-malfunction',
    DEVICE_MALFUNCTION_PAGE = 'device-malfunction',
    PROJECTS_CREATE_PAGE = 'projects-create',
    PROJECTS_MANAGE_PAGE = 'projects-manage',
    PUBLIC_PORTAL_PAGE = 'public-portal',
    WORK_ORDERS_PAGE = 'work-orders',
    STEDIN_ORDERS_PAGE = 'stedin-orders',
    REPORT_GENERIC_PAGE = 'report-generic',
    SETTINGS_PAGE = 'settings',
    USER_SETTINGS_PAGE = 'user-settings',
    AUTH_SETTINGS_PAGE = 'auth-settings',
    AREA_SETTINGS_PAGE = 'area-settings',
    IMPORT_PAGE = 'data-import',
    EXPORT_PAGE = 'export-wizard',
    LOAD_AREAAL_PAGE = 'load-areaal',
    USER_PROFILE_PAGE = 'user-profile',
    MASTER_DATA_PAGE = 'master-data',
    CALAMITY_CONTROL_PAGE = 'calamity-control',
    REPORTING_PAGE = 'analysis-report',
    REPORTING_PAGE_ALIAS = 'reports',
    MECHANIC = 'mechanic',
    NOTIFICATIONS = 'notifications',
    CONTENT_PAGES = 'content-pages',
    GRID = 'grid'
}
