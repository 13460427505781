import {Injectable} from '@angular/core';
import {HTTPService} from '../../../services/http/http.service';
import {HTTPError} from '../../../services/http/http-error';
import {GlobalAlertService} from '../../../../wrapper/global-alert/global-alert.service';
import {RequestFailure} from '../../../services/http/request-failure';
import {BasicMapItem} from './map-item-importer/map-item-importer.interface';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {HttpService2} from '../../../services/http-service-2.0/http2.0.service';
import {LoggerService} from "../../../services/logger/logger.service";

@Injectable()
export class MapItemService {
    constructor(
        protected httpService: HTTPService,
        protected httpService2: HttpService2,
        protected globalAlertService: GlobalAlertService,
        protected logger:LoggerService
    ) {
    }

    public getBushItems(url: string, successCallBack?: (formData: any) => any, failCallBack?: (failure: RequestFailure) => any): void {
        this.httpService.doGetRequest(url,
            (json: any) => {
                successCallBack(json);
            }, (failure: RequestFailure) => {
                failCallBack(failure);
            }, (error: HTTPError) => {
                this.logger.log('[FormDataService] ERROR get bushitem tree');
            },
            true
        );
    }

    // TODO: als deze drie functies zo blijven, merge ze dan met elkaar
    public getBaseObjectDeleteEffects(url: string, baseObjectId: number, successCallBack?: (json: any) => any, failCallBack?: (failure: RequestFailure) => any, errorCallBack?: (error: HTTPError) => any) {
        let postValues = {baseObjectId: baseObjectId, action: 'check'};

        this.httpService.doFormPostRequest(url, postValues,
            (json: any) => {
                // Let component know request is send
                successCallBack(json);
            }, (failure: RequestFailure) => {
                // The username is not valid or other failure
                failCallBack(failure);
            }, (error: HTTPError) => {
                errorCallBack(error);
            },
            true
        );
    }

    public deleteBaseObject(url: string, baseObjectId: number, successCallBack?: (json: any) => any, failCallBack?: (failure: RequestFailure) => any, errorCallBack?: (error: HTTPError) => any) {
        let postValues = {baseObjectId: baseObjectId, action: 'delete'};

        this.httpService.doFormPostRequest(url, postValues,
            (json: any) => {
                // Let component know request is send
                successCallBack(json);
            }, (failure: RequestFailure) => {
                // The username is not valid or other failure
                failCallBack(failure);
            }, (error: HTTPError) => {
                errorCallBack(error);
            },
            true
        );
    }

    public archiveBaseObject(url: string, baseObjectId: number, successCallBack?: (json: any) => any, failCallBack?: (failure: RequestFailure) => any, errorCallBack?: (error: HTTPError) => any) {
        let postValues = {baseObjectId: baseObjectId, action: 'archive'};

        this.httpService.doFormPostRequest(url, postValues,
            (json: any) => {
                // Let component know request is send
                successCallBack(json);
            }, (failure: RequestFailure) => {
                // The username is not valid or other failure
                failCallBack(failure);
            }, (error: HTTPError) => {
                errorCallBack(error);
            },
            true
        );
    }
    
    public createMapItem(projectBaseObjectId: number, locations: BasicMapItem[]): Observable<void> {
        const postValues: JSON = JSON.parse(JSON.stringify({
            locations: locations.map(mapItem => {
                return {
                    mastCode: mapItem.code,
                    latitude: mapItem.lat,
                    longitude: mapItem.lon
                };
            })
        }));
        
        return this.httpService2.doFormPostRequest(
            `projects-manage/project/${projectBaseObjectId}/add`,
            postValues
        ).pipe(
            map(() => {return; })
        );
    }
}
