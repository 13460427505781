import {Injectable} from '@angular/core';
import {
    HttpEvent,
    HttpEventType,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponseBase
} from '@angular/common/http';
import {tap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {GlobalAlertService} from '../../wrapper/global-alert/global-alert.service';
import {GlobalPopupType} from '../../wrapper/global-alert/global-popup';

@Injectable({
    providedIn: 'root'
})
export class VersionCheckInterceptor implements HttpInterceptor {
    private readonly VERSION_HEADER_NAME: string = 'frontendversion';
    private currentVersion: string = null;
    private showPopup = true;
    
    constructor(
        private globalAlertService: GlobalAlertService
    ) {
    }
    
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(tap((event: HttpEvent<any>) => {
            if (event.type === HttpEventType.Sent || this.globalAlertService.isPopupActiveByType(GlobalPopupType.REFRESH_REQUEST)) {
                return;
            }

            const response: HttpResponseBase = event as HttpResponseBase;

            if(response.headers.get(this.VERSION_HEADER_NAME) === null) {
                return;
            }

            if (this.currentVersion === null) {
                this.currentVersion = response.headers.get(this.VERSION_HEADER_NAME);
                return;
            }

            if (this.showPopup && response.headers.get(this.VERSION_HEADER_NAME) !== this.currentVersion) {
                this.globalAlertService.addPopupRequestRefresh((buttonCode: string, remindAgain: boolean) => {
                    this.showPopup = true;
                }, () => {
                    return this.reload();
                });
            }
        }));
    }
    
    private reload() {
        localStorage.clear();
        window.location.reload();
    }
}
