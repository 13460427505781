<div class="{{cell.bold?'table-cell-bold':'table-cell'}}" title="{{cell.label}}" [ngSwitch]="cell.dataType">
    <ng-container *ngSwitchCase="DATA_TYPE.ICON">
        <img alt="{{cell.label}}" src="{{getImage(cell.label)}}"/>
    </ng-container>
    <ng-container *ngSwitchCase="DATA_TYPE.COLOR_ICON">
        <p class="tree-node-color" [style.background-color]="cell.label"></p>
    </ng-container>
    <ng-container *ngSwitchCase="DATA_TYPE.DATETIME">
        <span>{{cell.label}}</span>
    </ng-container>
    <div *ngSwitchCase="DATA_TYPE.COMPLEX">
        <span *ngIf="cell.label">{{cell.label}}</span>
        <ng-container *ngFor="let child of cell.children; let index = index">
            <ng-container *ngIf="index < (cell.label ? maxCellLines - 1 : maxCellLines)">
                <div [ngSwitch]="child.dataType">
                    <ng-container *ngSwitchCase="DATA_TYPE.CHECK_ACTIVITY">
                        <check-activity-cell-child [cellChild]="child"></check-activity-cell-child>
                    </ng-container>
                    <ng-container *ngSwitchDefault>
                        <span style="margin-left: 0.2rem; font-size: small">{{child.label}}</span>
                    </ng-container>
                </div>
            </ng-container>
        </ng-container>
        <div *ngIf="cell.children.length > (cell.label ? maxCellLines - 1 : maxCellLines)">
            <span style="margin-left: 0.2rem; font-size: x-small">+ {{cell.children.length - (cell.label ? maxCellLines - 1 : maxCellLines)}} meer</span>
        </div>
    </div>
    <ng-container *ngSwitchDefault>
        <span>{{cell.label | truncate:cell.truncate:maxCellCharacters}}</span>
        <span *ngIf="cell.truncate" (click)="toggleTruncation($event,cell)">...</span>
    </ng-container>
</div>
