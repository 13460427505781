export interface TableData {
    headers: HeaderData[];
    rows: RowData[];
}

export interface HeaderData {
    columnId: string | number;
    label: string;
    code: string;
    type: DataType;
    isVisible: boolean;
    bold: boolean;
    sorting?: boolean;
    sortDirection?: number;
    columnRank: number;
}

export enum DataType {
    ID = 'id',
    DATETIME = 'datetime',
    DATE = 'date',
    NUMBER = 'number',
    STRING = 'string',
    INTEGER = 'integer',
    ACTION = 'action',
    COMPLEX = 'complex',
    CHECK_ACTIVITY = 'check_activity',
    ICON = 'icon',
    COLOR_ICON = 'color-icon'
}

export interface RowData {
    uniqueId: string | number;
    cells: CellData[];
}

export interface CellData {
    label?: string;
    dataType: DataType;
    children?: CellChild[]
}

export interface CellChild {
    dataType: DataType;
    label: string;
}

export interface Header extends HeaderData {
    sortDirection: TableSortDirection;
}

export enum TableSortDirection {
    SORT_DIRECTION_UNSET = -1,
    SORT_DIRECTION_ASC = 1,
    SORT_DIRECTION_DESC = 0
}

export interface Row extends RowData {
    cells: Cell[];
    isVisible: boolean;
    isSelected: boolean;
    isHighlighted: boolean;
}

export interface Cell extends CellData {
    columnId: string | number;
    bold: boolean;
    truncate: boolean;
    isVisible: boolean;
    columnRank: number;
}

export interface TableSorting {
    columnId: string | number;
    sortDirection: TableSortDirection;
}

export interface TableOptions {
    allowDeleteRow?: boolean;
    maxRows?: number;
    allowMultiLineInRow?: boolean;
    mobileMode?: boolean;
    parentWidthPercentage?: number;
    clearHeader?: boolean;
    canActivateRows?: boolean;
    boldAllowed?: boolean;
}

export enum ExportType {
    'CSV' = 'CSV',
    'XLSX' = 'XLSX',
    'CLIPBOARD' = 'CLIPBOARD'
}

export enum RowClasses {
    'INACTIVE' = 'table-row',
    'INACTIVE_SELECTED' = 'table-row-selected',
    'INACTIVE_SELECTABLE' = 'table-row-selectable',
    'ACTIVE' = 'table-row active',
    'ACTIVE_SELECTED' = 'table-row active',
    'ACTIVE_SELECTABLE' = 'table-row-selectable active'
}

