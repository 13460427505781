import {
    AfterViewInit, ChangeDetectorRef,
    Component,
    Renderer2,
} from '@angular/core';
import {FormInputComponent} from '../form-input/form-input.component';
import {ValidationConstraintService} from '../../services/validation-constraint.service';
import {TooltipService} from '../../../../services/tooltip/tooltip.service';
import {Router} from '@angular/router';
import {TranslateService} from '../../../../services/translate/translate.service';
import {GlobalAlertService} from '../../../../../wrapper/global-alert/global-alert.service';
import {LoggerService} from "../../../../services/logger/logger.service";
import {GlobalModel} from '../../../../services/state/global.model';

@Component({
    selector: 'form-link',
    template: `
        <ng-container [formGroup]="group">
            <form-component-label [batchUpdateMode]="batchUpdateMode" [config]="config" [included]="included"
                                  (onClickInclude)="handleClickInclude($event)"></form-component-label>
            <div class="md-form input-group p-0 m-0" [title]="(isDownload?('formlink.downloadtooltip'|translate:[getFileName()]):('formlink.tooltip'| translate:[anchorUrl]))">
                <ng-container *ngIf="!previewFormUrl">
                    <a href="{{anchorUrl}}" class="form-link-anchor align-self-center {{isDownloading?'form-link-downloading':''}}"
                       (click)="handleClickAnchor($event)" download>
                        {{isDownloading ? ts.translate('formlink.downloading') : anchorLabel}}</a>
                </ng-container>
                <ng-container *ngIf="previewFormUrl">
                    <a class="form-link-anchor form-link-anchor-force-color align-self-center"
                       (click)="handleClickFormPreview()">
                        {{anchorLabel}}
                    </a>
                    <a href="#" class="{{isDownloading?'form-link-downloading':''}}"
                       (click)="handleClickAnchor($event)" title="{{anchorLabel}}" download>
                        <i class="material-icons">open_in_browser</i>
                    </a>
                </ng-container>
            </div>
        </ng-container>
    `,
})
export class FormLinkComponent extends FormInputComponent implements AfterViewInit {
    anchorLabel: string = '';
    anchorUrl: string = '';
    previewFormUrl: string = '';
    isDownload: boolean = false;
    isDownloading: boolean = false;
    private openInNewWindow: boolean = false;
    mapItemBushPreviewDropDownData: any;

    public constructor(public renderer: Renderer2, public validationConstraintService: ValidationConstraintService, public tooltipService: TooltipService, private router: Router, public ts: TranslateService, public globalAlertService: GlobalAlertService, protected logger:LoggerService, public model:GlobalModel, public cd:ChangeDetectorRef) {
        super(renderer, validationConstraintService, tooltipService, globalAlertService, ts, logger, model);
    }

    ngAfterViewInit(): void {
        if (this.config && this.config.attr) {
            this.anchorLabel = <string>this.config.attr.anchorLabel;
            this.anchorUrl = <string>this.config.attr.anchorUrl;
            this.isDownload = <boolean>this.config.attr.isDownload;
            this.openInNewWindow = <boolean>this.config.attr.openInNewWindow;
            this.previewFormUrl = <string>this.config.attr.previewFormUrl;
            this.mapItemBushPreviewDropDownData = this.config.attr.dropDownData;
        }
    }

    getFileName(): string {
        //Extract the filename from the full path
        return this.anchorUrl.substr(this.anchorUrl.lastIndexOf('/') + 1);
    }

    handleClickAnchor(e: Event) {
        if (!this.isDownload) {
            //Prevent default a href behaviour
            e.preventDefault();
            //Navigate

            if (this.openInNewWindow) {
                window.open(this.anchorUrl, '_blank');
            }
            else {
                if(this.config.attr.selectRootNode != undefined){
                    this.router.navigate([this.anchorUrl], {queryParams: {selectRootNode:this.config.attr.selectRootNode}});
                } else {
                    this.router.navigateByUrl(this.anchorUrl);
                }
            }
        }
        else {
            if (this.isDownloading) {
                e.preventDefault();
            }
            else {
                this.isDownloading = true;
            }
        }
    }

    public handleClickFormPreview() {
        this.globalAlertService.addPopupViewFormPreview(
            this.previewFormUrl,
            this.mapItemBushPreviewDropDownData,
            (buttonCode: any, response: any) => {

            }, () => {}
        )
    }
}
