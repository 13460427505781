/**
 * Created by Christiaan on 23/03/2017.
 */

import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {AppSettings} from "../../app.settings";
import {Subscription, timer} from "rxjs";
import {TranslateService} from "../../shared/services/translate/translate.service";
import {GlobalModel} from "../../shared/services/state/global.model";
import {LuminizerRoutes} from '../../shared/interfaces/routes';
import {LoggerService} from "../../shared/services/logger/logger.service";

@Component ({
    selector: 'forbidden-component',
    template: `

     <div class="login-background">
        <div class="login-container">
            <div class="login-filler"></div>
            <div class="forbidden-form-container">
                <div class="forbidden-form">
                    <table>
                        <tbody>
                            <tr>
                                <td><i class="forbidden-icon material-icons md-80">error_outline</i></td>
                                <td>
                                    <h1>{{title}}</h1>
                                    <p>{{subTitle}}</p>
                                    <p><br>{{getRedirectText(secondsUntilRedirect)}}<span class="cursor-pointer" (click)="handleClickSkip()"><i><br>{{'Of klik hier om direct terug gaan' | translate}}</i></span></p>
                                </td>
                            </tr>
                        </tbody>
                     </table>
                </div>
            </div>
        </div>
        <!--<footer-component [isSimpleFooter]="true"></footer-component>-->
    </div>
`
})
export class ForbiddenComponent implements OnInit, OnDestroy{

    private static readonly REDIRECT_SECONDS:number = 10; //Redirect after n seconds

    public title:string;
    public subTitle:string;
    public secondsUntilRedirect:number;
    private subscription:Subscription;
    private subChangeLanguage:Subscription;

    constructor(private router:Router, private cd:ChangeDetectorRef, private ts:TranslateService, private model:GlobalModel, protected logger:LoggerService)
    {
        this.secondsUntilRedirect = ForbiddenComponent.REDIRECT_SECONDS;

        this.subChangeLanguage = this.model.changeLanguage.subscribe( () => {
            this.title = ts.translate('forbidden.title');
            this.subTitle = ts.translate('forbidden.description');
        });
    }

    ngOnInit(){
        let navigateTimer:any = timer(1000,1000);
        this.subscription = navigateTimer.subscribe((t:any)=>{
            this.secondsUntilRedirect--;
            this.cd.markForCheck();

            if (this.secondsUntilRedirect <= 0){
                this.navigateAway();
            }
        });
    }

    public getRedirectText(secondsLeft:number):string{
        return this.ts.translate('redirect.message', [String(secondsLeft)]); //U wordt automatisch doorverwezen naar de begin-pagina over {{secondsUntilRedirect}} seconden.
    }

    public handleClickSkip():void{
        this.navigateAway();
    }

    private navigateAway():void
    {
        this.secondsUntilRedirect = 0;
        this.subscription.unsubscribe();
        this.logger.log("[PageNotFoundComponent] " + "Redirecting to initial page...");
        this.router.navigate([LuminizerRoutes.INITIAL_PAGE]);
    }

    ngOnDestroy(){
        this.subscription.unsubscribe();
    }

}
