<div *ngIf="!globalModel.mobileMode.value && !buttonOnly"
     fileDropzone
     (filesDropped)="onFilesDropped($event)"
     class="{{isDisabled? 'fa-dropzone-disabled':'fa-dropzone'}}">
    <i class="fa-dropzone-icon">file_upload</i>
    <p>{{(multiInput? 'attachment.dropzone.multi':'attachment.dropzone.single') | translate}}</p>
    <p>{{'attachment.dropzone.or' | translate}}</p>
    <lumi-button (onClick)="selectFiles($event)"
                 [disabled]="isDisabled"
                 [size]="'small'"
                 [rank]="'secondary'"
                 [label]="getButtonLabel()"
                 [icon]="'file_upload'">
    </lumi-button>
</div>
<lumi-button *ngIf="globalModel.mobileMode.value || buttonOnly"
             (onClick)="selectFiles($event)"
             [disabled]="isDisabled"
             [size]="'small'"
             [rank]="'secondary'"
             [label]="getButtonLabel()"
             [icon]="'file_upload'">
</lumi-button>
<div #toolTipAnchor></div>
<input class="d-none"
       #fileInput
       [multiple]="multiInput"
       type="file"
       data-max-size="12"
       [disabled]="isDisabled"
       accept="{{fileTypesString}}"
       (change)="onFilesSelected()">
