import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    HostBinding,
    Input,
    OnInit,
    Output,
    Renderer2,
    ViewChild
} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {AbstractFormFieldComponent} from '../../abstract/abstract-form-field.component';
import {GlobalAlertService} from '../../../../../../wrapper/global-alert/global-alert.service';
import {ValidationConstraintService} from '../../../services/validation-constraint.service';
import {TooltipService} from '../../../../../services/tooltip/tooltip.service';
import {GlobalModel} from '../../../../../services/state/global.model';
import {TranslateService} from '../../../../../services/translate/translate.service';
import Utils from '../../../../../utils/utils';
import { FormEvent } from '../../../containers/form/form.interface';
import {LumiSelectOption} from '../../../../commonUI/select/lumi-select/lumi-select.interface';
import {LumiSelectComponent} from '../../../../commonUI/select/lumi-select/lumi-select.component';
import {LoggerService} from "../../../../../services/logger/logger.service";

@Component({
    selector: 'form-multi-select',
    templateUrl: './form-multi-select.component.html'
})
export class FormMultiSelectComponent extends AbstractFormFieldComponent implements OnInit {
    private static readonly NUM_OPTIONS_FOR_SHOW_FILTER: number = 10;

    @HostBinding('class') hostClasses = 'd-flex mt-1';
    @Output() onComponentEvent: EventEmitter<any> = new EventEmitter();
    @Output() onChange: EventEmitter<any> = new EventEmitter();
    @Input() invalidControlsErrors: any;
    @Input() dropdownSizeLarge: boolean = false;
    @Input() singleLineDropdown: boolean = false;
    @Input() sortSelection: boolean = true;
    @ViewChild('editDropDown', {static: false}) editDropDown: any;
    @ViewChild('lumiSelectComponent', {static: false}) lumiSelectComponent: LumiSelectComponent;

    public config: any = {};
    public group: UntypedFormGroup;
    public readOnly: boolean;
    public componentId = Utils.getRandomNumber(0, 99999999); // Generate a random id to keep multiple of these multi select components apart
    public selectedOptions: any[] = [];
    public editTypes: any[] = [{value: 'ADD', label: 'modifier.add'}, {value: 'REMOVE', label: 'modifier.remove'}, {value: 'CLEAR', label: 'modifier.clear'}, {value: 'REPLACE', label: 'modifier.replace'}];

    constructor(
        private globalAlertService: GlobalAlertService,
        public cd: ChangeDetectorRef,
        public renderer: Renderer2,
        public validationConstraintService: ValidationConstraintService,
        public tooltipService: TooltipService,
        private model: GlobalModel,
        private trans: TranslateService,
        protected logger:LoggerService
    ) {
        super(renderer, validationConstraintService, tooltipService, logger);

        // Fill with fake group, to support this component outside of a form
        this.group =   new UntypedFormGroup({
            fake1: new UntypedFormControl('')
        });
    }
    
    public isDisabled(): boolean {
        return super.disabled() == '';
    }
    
    public showOptionFilter(): boolean {
        return this.config.options && this.config.options.length >= FormMultiSelectComponent.NUM_OPTIONS_FOR_SHOW_FILTER;
    }
    
    public handleChangeEditType(event: any): void {
        this.updateFormValue();
    }

    public getSelectedIdsAsArray(): any[] {
        let result: any[] = [];

        for (let i = 0; i < this.selectedOptions.length; i++) {
            result.push(this.selectedOptions[i].id);
        }

        return result;
    }

    private updateFormValue(): void {
        if (this.group && this.group.get(this.config.name)) {

            this.group.get(this.config.name).patchValue(this.getSelectedIdsAsArray());

            let editControl: any = this.group.get('modifier');

            if (this.batchUpdateMode) {
                if (editControl == null) {
                    this.group.addControl('modifier', new UntypedFormControl(''));
                    editControl = this.group.get('modifier');
                }

                editControl.patchValue(this.getModifier());
            } else {
                if (editControl != null) {
                    this.group.removeControl('modifier');
                }
            }
        }
        this.onChange.emit();
    }

    ngOnInit() {
        this.extractSelectedItems();

        this.updateFormValue();
    }

    // MArk objects from the initialvalue as selected in this component
    // TODO: deze opruimen, hijs nogal lelijk en ineffiecient. Meestal gaat het om zeer weinig items dus het heeft lage prio qua performance
    public extractSelectedItems(): void {
        if (this.config && this.config.initialValue && this.config.initialValue instanceof Array) {
            this.selectedOptions = [];
            this.config.options.forEach((option: any) => {
                option.checked = false;
            });
            this.config.initialValue.forEach((selectedOptionId: any) => {
                this.config.options.forEach((option: any) => {
                    if (selectedOptionId == option.id) {
                        option.checked = true;
                        this.selectedOptions.push(option);
                    }
                });
            });
        } else {
            this.logger.log('[FormMultiSelectComponent] ' + 'No valid initial value for multi select component');
        }
    }

    private getModifier(): string {

        // Safety for element not loaded
        let modifier: string = '';

        // Get the value of the dropdown
        if (this.editDropDown && this.editDropDown.nativeElement) {
            modifier = this.editDropDown.nativeElement.value;
        }

        return modifier;
    }
    
    public handleAdd(): void {
        if (this.config && this.config.attr && this.config.attr.creationHref && this.disabled() == null) {

            let itemText: string = this.trans.translate('Onbekend');

            if (this.config.label) {
                itemText = this.config.label;
            }

            this.globalAlertService.addPopupCreateDropdown(
                this.trans.translate('Item toevoegen aan: ') + itemText,
                '',
                this.config.attr.creationHref,
                (buttonCode: any, response: any) => {
                    // User pressed OK and call succeeded
                    this.logger.log('[FormMultiSelectComponent] ' + 'Gebruiker heeft op OK gedrukt, response: ', response);
                    if (response) {
                        let action: string = FormEvent.ADD_SUCCESS;
                        this.onComponentEvent.emit({event: action, data: {itemType: itemText}});
    
                        // Update dropdown with new value. add to selected items and update the form
                        let newOption: any = {id: response.id, name: response.name, checked: true};
                        this.config.options.push(newOption);
                        this.selectedOptions.push(newOption);
                        this.updateFormValue();
    
                        this.cd.detectChanges();
                    }
                },
                () => {
                    this.logger.log('[FormMultiSelectComponent] ' + 'Gebruiker heeft op ANNULEREN of kruisje gedrukt');
                }
            );
        }
    }
    
    public onOptionsSelect(selectedOptions: LumiSelectOption[]): void {
        if (this.config?.options?.length > 0 && selectedOptions?.length >= 0) {
            this.selectedOptions = selectedOptions;
            this.updateFormValue();
        }
    }
    
    public deselectOption(id: number|string): void {
        if (this.selectedOptions.some(option => option.id === id)) {
            this.lumiSelectComponent.removeChoice(null, this.selectedOptions.find(option => option.id === id))
        }
    }
}
