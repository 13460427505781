import {ChangeDetectorRef, Component, HostBinding} from "@angular/core";
import {UntypedFormGroup} from "@angular/forms";
import {Router} from "@angular/router";
import {TreeMapFormComponent} from "../../../tree-map-form/tree-map-form.component";
import {GlobalEvent} from "../../../../interfaces/global-event";
import {GlobalModel} from "../../../../services/state/global.model";

@Component({
    selector: 'form-anchor',
    template: `
    <div class="dynamic-field form-anchor" [formGroup]="group">
        <ng-container *ngIf="getText() && getHref()">
            <a (click)="onClick($event)" href="{{getHref()}}" class="{{getClassNames()}}">
                <!--Keep on same line to prevent whitespace char-->
                <i *ngIf="hasIcon()" class="material-icons">{{config.attr.anchor.icon}}</i>
                <img *ngIf="hasImage()" src="{{getImage()}}"><span class="anchor-span">{{getText()}}</span>
            </a>
        </ng-container>
    </div>
    `
})

export class FormAnchorComponent
{
    config:any;
    group:UntypedFormGroup;
    readOnly:boolean;
    invalidControlsErrors:any;

    constructor(private cd:ChangeDetectorRef, private router:Router, private model:GlobalModel) {}

    getHref() {
        if (this.config.attr && this.config.attr.anchor && this.config.attr.anchor.href) {
            return this.config.attr.anchor.href;
        }
        return null;
    }

    getText() {
        if (this.config.attr && this.config.attr.anchor && this.config.attr.anchor.text)
        {
            return this.config.attr.anchor.text;
        }
        return null;
    }

    onClick(event: any)
    {
        event.preventDefault(); // prevent default behaviour of an anchor click (navigate out of the page into an 'other' page)

        if (this.config && this.config.attr && this.config.attr.loadAsForm){
            this.model.onGlobalEvent.next(new GlobalEvent(GlobalEvent.EVENT_LOAD_FORM_REQUEST, {url:this.getHref()}));
        }else{
            this.router.navigate([this.getHref()], {queryParams: {state:TreeMapFormComponent.STATE_FORM, origin:'null'}, queryParamsHandling: "merge" });
        }
    }

    hasIcon():boolean{
        return (this.config.attr && this.config.attr.anchor && this.config.attr.anchor.icon != "");
    }

    public getClassNames():string
    {
        let result = 'btn btn-md btn-outline-form btn-block mb-1 ';

        if (this.config && this.config.attr) {
         result += (this.config.attr.active?'active ':'');
        }

        return result;
    }

    // TODO: W, O, W, WAAAAWW. Een perfect Erikisme
    /*getClassNames()
    {
        let result = 'btn btn-md btn-outline-form btn-block mb-1 ';

        /!*if (this.config.attr && this.config.attr.class) {
            result += this.config.attr.class + ' '
        }*!/

        if (this.config && this.config.attr) {
         result += (this.config.attr.active?'active ':'');
        }

        switch (this.selected) {
            case true:
                result += 'loading ';
                break;
        }

        this.classNames = result;
        return result;
    }*/

/*    getIcon():string
    {
        if (this.hasIcon()) {
            return this.config.attr.icon;
        }
        return "";
    }*/

    // getToolTip():string{
    //     if (this.config.attr && this.config.attr.toolTip){
    //         return this.config.attr.toolTip;
    //     }
    //     return "";
    // }

/*    setUpHostClasses() {
        if (this.config.attr && this.config.attr.hostClasses) {
            this.hostClasses = this.config.attr.hostClasses;
        }
    }*/

    hasImage() {
        return (this.config.attr && this.config.attr.image && this.config.attr.image != "");
    }

    getImage() {
        return this.config.attr.image;
    }
}
