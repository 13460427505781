<div class="key-value">
    <div class="kv-key {{truncateKey? 'text-truncate':''}}">
        <i [title]="key">
            {{key}}:
        </i>
    </div>
    <div class="kv-value" *ngIf="value" [title]="value">
        {{value}}
    </div>
    <div class="kv-value" *ngIf="!value">
        <ng-content></ng-content>
    </div>
</div>
