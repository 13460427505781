/**
 * Created by Christiaan on 07/04/2017.
 */

import {Directive, NgZone, Output, EventEmitter} from '@angular/core';

@Directive({
    selector: '[globalKeyupOutsideAngular]'
})

export class GlobalKeypressOutsideAngularDirective
{
    private static readonly EVENT_KEY_PRESS:string = "keypress";

    @Output('globalKeyupOutsideAngular') keyupHandler: EventEmitter<any> = new EventEmitter();

    constructor(private ngZone:NgZone)
    {
        this.ngZone.runOutsideAngular(() => {
            window.addEventListener(GlobalKeypressOutsideAngularDirective.EVENT_KEY_PRESS, (event:any)=> {
                this.keyupHandler.emit(event);
            })
        });
    }
}
