/**
 * Created by Christiaan on 13/04/2017.
 */
import { NgModule }      from '@angular/core';
import { CommonModule }  from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';

import {WidgetComponent} from "./widget.component";
import {SmallWidgetComponent} from "./small-widget.component";
import {MapWidgetComponent} from "./map-widget.component";
import {MapModule} from "../../../shared/components/map/map.module";
import {TableWidgetComponent} from "./table-widget.component";
import {FeedWidgetComponent} from "./feed-widget/feed-widget.component";
import {CommonUIModule} from "../../../shared/components/commonUI/common-ui.module";
import {CommonDirectivesModule} from "../../../shared/directives/common/common-directives.module";
import {StaticWidgetComponent} from "./static-widget.component";
import {CommonPipesModule} from "../../../shared/pipes/common-pipes.module";
import {MapTableWidgetComponent} from "./map-table-widget.component";
import {BaseTableModule} from '../../../shared/components/table/shared/baseTable/baseTable.module';
import {MapTableModule} from '../../../shared/components/map-table/map-table.module';
import {BaseWidgetComponent} from './base-widget.component';

@NgModule({
    //This is a shared module for all widget components
    imports: [
        CommonModule, RouterModule, ReactiveFormsModule, MapModule, CommonUIModule, CommonDirectivesModule, CommonPipesModule, BaseTableModule, MapTableModule
    ],
    exports: [WidgetComponent, SmallWidgetComponent, MapWidgetComponent, TableWidgetComponent, FeedWidgetComponent, StaticWidgetComponent, MapTableWidgetComponent],
    declarations: [BaseWidgetComponent, WidgetComponent, SmallWidgetComponent, MapWidgetComponent, TableWidgetComponent, FeedWidgetComponent, StaticWidgetComponent, MapTableWidgetComponent]
})

export class WidgetModule {}
