import {ChangeDetectorRef, Component, HostBinding, Renderer2} from '@angular/core';
import 'moment/locale/nl';
import {FormInputComponent} from "./form-input.component";
import {ValidationConstraintService} from "../../services/validation-constraint.service";
import {TooltipService} from "../../../../services/tooltip/tooltip.service";
import {GlobalAlertService} from "../../../../../wrapper/global-alert/global-alert.service";
import {TranslateService} from "../../../../services/translate/translate.service";
import {LoggerService} from "../../../../services/logger/logger.service";
import {GlobalModel} from '../../../../services/state/global.model';

@Component({
    selector: 'form-input',
    template: `
    <ng-container [formGroup]="group">
        <!--<label class="m-0 pr-2 form-label-part" for="{{config.name}}">{{ config.label }}<required-field [visible]="config.required"></required-field></label>-->

        <form-component-label [batchUpdateMode]="batchUpdateMode" [config]="config" [included]="included" (onClickInclude)="handleClickInclude($event)"></form-component-label>
        
        <div class="md-form input-group p-0 m-0">
            <ng-container *ngIf="hasInputGroupAddonBefore()">
                <span class="input-group-addon">{{ config.attr.inputGroup.addOn.text }}</span>
            </ng-container>
            <!--class="{{getClassNames()}}"-->
            <input
                    #currentFormField
                    [disableControl]="isControlDisabled()"
                    name="{{config.name}}"
                    type="number"
                    step="1"
                    [attr.placeholder]="config.placeholder"
                    [formControlName]="config.name"
                    [(ngModel)]="initialValue"
                    min="{{config.attr?.min}}"
                    max="{{config.attr?.max}}"
            >
            <!--TODO: dit wordt te breed voor sommige schermen-->
            <!--<ng-container *ngIf="hasInputGroupAddonAfter()">
                <span class="input-group-addon">{{ config.attr.inputGroup.addOn.text }}</span>
            </ng-container>-->
        </div>
    </ng-container>
    `
})

export class FormIntegerInputComponent extends FormInputComponent {

    @HostBinding('class') hostClasses = 'd-flex';

    // This class has a front-end number input field, so there is a first way to
    // validate the input, by disallowing alphabetic characters and using the 'step' attribute.
    // We can not lean only on front-end input validation, so the back-end will also validate the
    // input, to see if it is a number (the default Symfony 'IntegerType' behavior is to round an input number to an integer)

    constructor(public renderer:Renderer2, public validationConstraintService:ValidationConstraintService, public tooltipService:TooltipService, public globalAlertService:GlobalAlertService, public ts:TranslateService, protected logger:LoggerService, public model:GlobalModel, public cd:ChangeDetectorRef) {
        super(renderer, validationConstraintService, tooltipService, globalAlertService, ts, logger, model);
    }
}
