import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    ViewChild
} from '@angular/core';
import {TranslateService} from '../../../../services/translate/translate.service';
import {DataType} from '../../../table/shared/baseTable/baseTable.interface';
import {FilterCommand} from 'src/app/shared/components/table/filterableTable/filter.interface';
import {IMyDate, IMySingleDateModel} from 'angular-mydatepicker';
import {DateInputComponent} from '../../../commonUI/input/date-input/date-input.component';
import {LumiSelectOption} from '../../../commonUI/select/lumi-select/lumi-select.interface';
import {LoggerService} from "../../../../services/logger/logger.service";

@Component ({
    selector: 'table-filter-component',
    template: `
        <div class="d-flex dynamic-form">
            <div *ngIf="showLabel" class="d-flex align-items-center form-label-part">
                <div *ngIf="!hideClearButton" class="cursor-pointer filter-close-button mr-2"
                     (click)="handleClickDelete($event)">
                    <i class="material-icons p-0" style="font-size: 14px; padding: 0.1rem;">clear</i>
                </div>
                <div class="" style="font-size:0.8rem;"><i>{{tableField.label}}</i></div>
            </div>
            <div class="w-100 d-flex" style="vertical-align: middle;">
                <div *ngIf="!showLabel && !hideClearButton"
                     class="cursor-pointer filter-close-button mr-2" (click)="handleClickDelete($event)">
                    <i class="material-icons p-0" style="font-size: 14px; padding: 0.1rem;">clear</i>
                </div>
                <div class="w-100 d-flex align-items-center">
                    <lumi-select *ngIf="commandOptions?.length > 0"
                                 class="w-100 mr-2"
                                 [options]="commandOptions"
                                 [selectedOptions]="getSelectedCommandOption()"
                                 [showOptionFilter]="commandOptions?.length > 10"
                                 (onOptionsSelect)="handleChangeCommand($event)">
                    </lumi-select>
                    <div *ngIf="!typeRequiresDateFields() && commandRequiresInputField()" class="d-flex w-100">
                        <input (paste)="handlePaste($event)"
                               [style.width]="commandRequiresSecondInputField()?'50%':'100%'"
                               [style.visibility]="commandRequiresInputField()?'visible':'hidden'" #filterValue
                               (change)="handleChangeValue($event)" placeholder="{{getPlaceholderByCommand()}}"
                               type="text"
                               [value]="tableField && tableField.filter && tableField.filter.values && tableField.filter.values.length > 0? tableField.filter.values[0] : ''">
                        <input style="width:50%;" class="ml-1 {{commandRequiresSecondInputField()?'':'hidden'}}"
                               #secondFilterValue (change)="handleChangeValue($event)"
                               placeholder="{{getPlaceholderByCommand(true)}}" type="text"
                               [value]="tableField && tableField.filter? tableField.filter.values[1] : ''">
                    </div>
                    <div *ngIf="typeRequiresDateFields()" class="d-flex w-100">
                        <date-input #firstFilterDate
                                    [inputType]="'date'"
                                    [initialValue]="initialDate1"
                                    (onDateChanged)="handleDate1Change($event)"
                                    [style.width]="commandRequiresSecondInputField()?'50%':'100%'"
                                    [style.visibility]="commandRequiresInputField()?'visible':'hidden'">
                        </date-input>
                        <date-input #secondFilterDate
                                    [inputType]="'date'"
                                    [initialValue]="initialDate2"
                                    (onDateChanged)="handleDate2Change($event)"
                                    [style.width]="'50%'"
                                    [style.display]="commandRequiresSecondInputField()?'flex':'none'"
                                    [style.margin-left]="'0.25rem'"
                                    [style.visibility]="commandRequiresInputField()?'visible':'hidden'">
                        </date-input>
                    </div>
                </div>
            </div>
        </div>
    `
})

export class TableFilterComponent implements AfterViewInit {
    @Input()
    set tableFields(data: any) {
        if (data) {
            this._tableFields = data;

            this.logger.log('[TableFilterComponent] ' + 'set table fields: ' , data);

            this._sortedTableFields = this.sortByProperty(this._tableFields, 'label', true);
            this.logger.log('[TableFilterComponent] ' + 'sortedfields: ' , this._sortedTableFields);
        }
    }
    get tableFields(): any {
        return this._tableFields;
    }

    @Input()
    set tableField(data: any) {
        this._tableField = data;
        if (this.isFirstFieldSet) {
            this.updateDatePickers();
            this.isFirstFieldSet = false;
        }
    }
    get tableField(): any {
        return this._tableField;
    }

    @ViewChild('filterValue', {static: false}) filterValue: ElementRef;
    @ViewChild('firstFilterDate', {static: false}) firstFilterDate: DateInputComponent;
    @ViewChild('secondFilterValue', {static: false}) secondFilterValue: ElementRef;
    @ViewChild('secondFilterDate', {static: false}) secondFilterDate: DateInputComponent;
    @ViewChild('filterField', {static: false}) filterField: ElementRef;

    @Input() hideClearButton: boolean = false;
    @Input() showLabel: boolean = true;
    private isFirstFieldSet = true;

    @Output() onFilterChange: EventEmitter<any> = new EventEmitter<any>();
    @Output() onDeleteFilter: EventEmitter<any> = new EventEmitter<any>();

    private _tableFields: any = [];
    public _sortedTableFields: any = [];
    private _tableField: any = {};
    private filterCommand;
    
    public date1: IMySingleDateModel;
    public initialDate1: IMyDate = null;
    public date2: IMySingleDateModel;
    public initialDate2: IMyDate = null;
    
    public commandOptions: LumiSelectOption[] = [];
    
    public stringCommands: any = [
        {label: this.ts.translate('gelijk aan'), code: FilterCommand.EQUALS},
        {label: this.ts.translate('niet gelijk aan'), code: FilterCommand.NOT_EQUALS},
        {label: this.ts.translate('is leeg'), code: FilterCommand.EMPTY},
        {label: this.ts.translate('is niet leeg'), code: FilterCommand.NOT_EMPTY},
        {label: this.ts.translate('bevat'), code: FilterCommand.CONTAINS},
        {label: this.ts.translate('bevat niet'), code: FilterCommand.NOT_CONTAINS},
        {label: this.ts.translate('zit in reeks'), code: FilterCommand.IN_SET},
        {label: this.ts.translate('zit niet in reeks'), code: FilterCommand.NOT_IN_SET},
    ];
    
    public dateTimeCommands: any = [
        {label: this.ts.translate('voor'), code: FilterCommand.SMALLER},
        {label: this.ts.translate('voor of op'), code: FilterCommand.SMALLER_OR_EQUAL},
        {label: this.ts.translate('op'), code: FilterCommand.EQUALS},
        {label: this.ts.translate('niet op'), code: FilterCommand.NOT_EQUALS},
        {label: this.ts.translate('na of op'), code: FilterCommand.BIGGER_OR_EQUAL},
        {label: this.ts.translate('na'), code: FilterCommand.BIGGER},
        {label: this.ts.translate('tussen'), code: FilterCommand.BETWEEN},
        {label: this.ts.translate('niet tussen'), code: FilterCommand.NOT_BETWEEN},
        {label: this.ts.translate('is leeg'), code: FilterCommand.EMPTY},
        {label: this.ts.translate('is niet leeg'), code: FilterCommand.NOT_EMPTY},
        {label: this.ts.translate('afgelopen x dagen'), code: FilterCommand.PAST_X_DAYS},
        {label: this.ts.translate('voor vandaag'), code: FilterCommand.PAST_DAYS},
        {label: this.ts.translate('vandaag of afgelopen x dagen'), code: FilterCommand.TODAY_OR_PAST_X_DAYS},
        {label: this.ts.translate('vandaag of eerder'), code: FilterCommand.TODAY_OR_PAST_DAYS},
        {label: this.ts.translate('vandaag'), code:  FilterCommand.TODAY},
        {label: this.ts.translate('vandaag of toekomstige x dagen'), code: FilterCommand.TODAY_OR_FUTURE_X_DAYS},
        {label: this.ts.translate('vandaag of in de toekomst'), code: FilterCommand.TODAY_OR_FUTURE_DAYS},
        {label: this.ts.translate('in de toekomst'), code: FilterCommand.FUTURE_DAYS}
    ];
    
    public integerCommands: any = [
        {label: this.ts.translate('kleiner dan'), code: FilterCommand.SMALLER},
        {label: this.ts.translate('kleiner of gelijk aan'), code: FilterCommand.SMALLER_OR_EQUAL},
        {label: this.ts.translate('gelijk aan'), code: FilterCommand.EQUALS},
        {label: this.ts.translate('niet gelijk aan'), code: FilterCommand.NOT_EQUALS},
        {label: this.ts.translate('groter of gelijk aan'), code: FilterCommand.BIGGER_OR_EQUAL},
        {label: this.ts.translate('groter dan'), code: FilterCommand.BIGGER},
        {label: this.ts.translate('tussen'), code: FilterCommand.BETWEEN},
        {label: this.ts.translate('niet tussen'), code: FilterCommand.NOT_BETWEEN},
        {label: this.ts.translate('is leeg'), code: FilterCommand.EMPTY},
        {label: this.ts.translate('is niet leeg'), code: FilterCommand.NOT_EMPTY},
        {label: this.ts.translate('zit in reeks'), code: FilterCommand.IN_SET},
        {label: this.ts.translate('zit niet in reeks'), code: FilterCommand.NOT_IN_SET},
    ];

    constructor(private cd: ChangeDetectorRef, private ts: TranslateService, protected logger:LoggerService) {}

    public getSelectedFilter(): any {
        let matchingField: any = this.getSelectedTableField();

        // Build values from inputs
        let values: any = [];
        let command = this.filterCommand;

        if (this.commandRequiresInputField()) {
            // Take the datefields and convert them to the same format as the php: an ISO string
            if ((matchingField.type === DataType.DATETIME || matchingField.type === DataType.DATE) && (command !==  FilterCommand.PAST_X_DAYS && command !==  FilterCommand.TODAY_OR_PAST_X_DAYS && command !==  FilterCommand.TODAY && command !==  FilterCommand.TODAY_OR_FUTURE_X_DAYS && command !==  FilterCommand.FUTURE_X_DAYS)) {
                if (this.date1 && this.date1.jsDate) {
                    values.push(this.date1.jsDate.toISOString());
                } else {
                    // No selected date, pick today;
                    let d = new Date();
                    values.push(d.toISOString());
                }
                if (this.commandRequiresSecondInputField()) {
                    if (this.date2 && this.date2.jsDate) {
                        values.push(this.date2.jsDate.toISOString());
                    } else {
                        // No selected date, pick today;
                        let d = new Date();
                        values.push(d.toISOString());
                    }
                }
            } else {
                // Or take the normal input fields
                values.push(this.filterValue.nativeElement.value);

                if (this.commandRequiresSecondInputField()) {
                    values.push(this.secondFilterValue.nativeElement.value);
                }
            }
        }
        
        // Clean int values. Convert strings and make 0 of NaN;
        if ((matchingField.type == DataType.INTEGER && (command !=  FilterCommand.IN_SET && command !=  FilterCommand.NOT_IN_SET)) || (command ==  FilterCommand.PAST_X_DAYS || command ==  FilterCommand.TODAY_OR_PAST_X_DAYS || command ==  FilterCommand.TODAY_OR_FUTURE_X_DAYS && command ==  FilterCommand.FUTURE_X_DAYS )) {
            for (let i = 0; i < values.length; i++) {
                values[i] = Number(values[i]);
                if (Number.isNaN(values[i])) {
                    values[i] = 0;
                }
            }

            // When no values are given, default to zero
            if (values.length <= 0) {
                values.push(0);
            }
        }

        this.logger.log('[TableFilterComponent] ' + 'get selected filter values: ', values);

        // Extract all input
        return {label: matchingField.label, field: matchingField.code, type: matchingField.type, command: command, values: values};
    }

    // Get selected field. This can be from a drop down with multiple choices, or a pre-set single value
    public getSelectedTableField(): any {
        let matchingField: any = {};

        if (this.tableFields && this.filterField) {
            let filterFieldValue: string = this.filterField.nativeElement.value;
            for (let i = 0; i < this.tableFields.length; i++) {
                if (this.tableFields[i].code == filterFieldValue) {
                    matchingField = this.tableFields[i];
                    break;
                }
            }
        } else if (this._tableField) {
            matchingField = this._tableField;
        } else {
            matchingField = {};
        }

        return matchingField;
    }
    
    public getPlaceholderByCommand(isSecondField: boolean = false): string {
        if (!this.filterCommand) {
            return '';
        }

        let command: string = this.filterCommand;

        if (command ==  FilterCommand.IN_SET || command ==  FilterCommand.NOT_IN_SET) {
            return this.ts.translate('filter.set');
        } else if (command ==  FilterCommand.BETWEEN || command ==  FilterCommand.NOT_BETWEEN) {
            return this.ts.translate('filter.between', [(isSecondField ? '2' : '1')]);
        } else {
            return this.ts.translate('filter.value');
        }
    }

    // Handle pasting list or other values in the filterbox, when using the IN SET commands
    public handlePaste(e: any): void {
        let command = this.filterCommand;
        if (command ==  FilterCommand.IN_SET || command ==  FilterCommand.NOT_IN_SET) {

            // Stop from normal paste
            e.preventDefault();

            // NOTE: this is to be able to past an Excel list (without comma's) directly in the field
            //  The field will be filled with comma separated values

            // Get the data from the clipboard
            let clipboardData: string = e.clipboardData.getData('text/plain');
            clipboardData = clipboardData.trim();

            // Create an array by whitespace
            let dataArray: any = clipboardData.split(/[\r\n]+/);

            // Merge the array, but comma separated this time
            let normalizedString: string = '';
            for (let j = 0; j < dataArray.length; j++) {
                normalizedString += ((j > 0 ? ', ' : '') + dataArray[j].trim());
            }

            // Replace all double comma's
            normalizedString = normalizedString.replace(new RegExp(',,', 'g'), ',');

            // Store the new caret position
            let newCaretPosition: number = this.filterValue.nativeElement.selectionStart + normalizedString.length;

            // Add to existing string (Some people past value per value. If you want a clean input, clear it manually yourself)
            this.filterValue.nativeElement.value = this.filterValue.nativeElement.value.substring(0, this.filterValue.nativeElement.selectionStart) + normalizedString + this.filterValue.nativeElement.value.substring(this.filterValue.nativeElement.selectionEnd);

            // Set caret position to directly after pasted text
            this.filterValue.nativeElement.setSelectionRange(newCaretPosition , newCaretPosition);

            this.dispatchChangeEvent();
        }
    }
    
    public typeRequiresDateFields(): boolean {
        if (!this.filterCommand) {
            return false;
        }

        let type = this.getSelectedTableField().type;
        let command: string = this.filterCommand;

        return ((type === DataType.DATETIME || type === DataType.DATE) && (
            command !==  FilterCommand.PAST_X_DAYS &&
            command !==  FilterCommand.PAST_DAYS &&
            command !==  FilterCommand.TODAY_OR_PAST_X_DAYS &&
            command !==  FilterCommand.TODAY_OR_PAST_DAYS &&
            command !==  FilterCommand.TODAY &&
            command !==  FilterCommand.TODAY_OR_FUTURE_X_DAYS &&
            command !==  FilterCommand.TODAY_OR_FUTURE_DAYS &&
            command !==  FilterCommand.FUTURE_X_DAYS &&
            command !==  FilterCommand.FUTURE_DAYS
        ));
    }
    
    
    public commandRequiresInputField(): boolean {
        if (!this.filterCommand) {
            return false;
        }

        let command: string = this.filterCommand;
        return !(
            command ==  FilterCommand.EMPTY ||
            command ==  FilterCommand.NOT_EMPTY ||
            command ==  FilterCommand.TODAY ||
            command ==  FilterCommand.PAST_DAYS ||
            command ==  FilterCommand.TODAY_OR_PAST_DAYS ||
            command ==  FilterCommand.TODAY_OR_FUTURE_DAYS ||
            command ==  FilterCommand.FUTURE_DAYS
        );
    }
    
    public commandRequiresSecondInputField(): boolean {
        if (!this.filterCommand) {
            return false;
        }

        let command: string = this.filterCommand;
        return (command ==  FilterCommand.BETWEEN || command ==  FilterCommand.NOT_BETWEEN);
    }

    private dispatchChangeEvent(): void {
        this.onFilterChange.emit({data: {selectedFilter: this.getSelectedFilter()}});
    }
    
    public handleChangeCommand(event: LumiSelectOption[]): void {
        this.filterCommand = this.getCommandsSet().find(command => command.code === event[0].id).code;
        // Delay to give page time to render, before calling getSelectedFilter()
        setTimeout(() => {
            this.dispatchChangeEvent();
            this.cd.detectChanges();
        });
    }
    
    public handleChangeValue(event: any): void {
        this.dispatchChangeEvent();
    }
    
    public handleDate1Change(isoString: string): void {
        const date = new Date(isoString);
        this.date1 = {
            date: {
                year: date.getFullYear(),
                month: date.getMonth() + 1,
                day: date.getDate()
            },
            jsDate: date
        };
        this.dispatchChangeEvent();
    }
    
    public handleDate2Change(isoString: string): void {
        const date = new Date(isoString);
        this.date2 = {
            date: {
                year: date.getFullYear(),
                month: date.getMonth() + 1,
                day: date.getDate()
            },
            jsDate: date
        };
        this.dispatchChangeEvent();
    }

    public handleClickDelete(event: any): void {
        this.onDeleteFilter.emit({data: {selectedFilter: this.getSelectedFilter()}});
    }

    // Update the datepickers with the values from the tablefield
    // TODO: 2 issues:
    //  1: this._tableField.type is altijd datetime.
    //  2: Er wordt altijd een date picker getoond, ook voor velden die wel een datum/tijd moeten zijn. Het tonen van datum/tijd is makkelijk aan te passen maar tijd wordt niet mee opgeslagen dus hier moet ook nog het e.e.a. aangepast worden.
    //  Voor nu voor gekozen om huidige situatie in stand te houden namelijk overal date tonen.
    private updateDatePickers(): void {
        if (this._tableField && this._tableField.filter && (this._tableField.type === DataType.DATETIME || this._tableField.type === DataType.DATE) && this._tableField.filter.values) {
            if (this._tableField.filter.values.length > 0) {
                if (this.commandRequiresInputField() && this.firstFilterDate) {
                    let date = new Date(Date.parse(this._tableField.filter.values[0]));
                    this.initialDate1 = {
                        year: date.getFullYear(),
                        month: date.getMonth() + 1,
                        day: date.getDate()
                    };
                    this.date1 = {
                        date: this.initialDate1,
                        jsDate: date
                    };
                }
            }
            if (this._tableField.filter.values.length > 1) {
                if (this.commandRequiresSecondInputField() && this.secondFilterDate) {
                    let date = new Date(Date.parse(this._tableField.filter.values[1]));
                    this.initialDate2 = {
                        year: date.getFullYear(),
                        month: date.getMonth() + 1,
                        day: date.getDate()
                    };
                    this.date2 = {
                        date: this.initialDate2,
                        jsDate: date
                    };
                }
            }
        }
    }

    private sortByProperty(array: any[], propertyName: string, ascending: boolean): any {
        return array.sort(
            function (a, b) {
                return sortByString(a[propertyName], b[propertyName], ascending);
                function sortByString(stringA: string, stringB: string, ascending: boolean) {
                    if (stringA < stringB) {
                        return ascending ? -1 : 1;
                    }
                    if (stringA > stringB) {
                        return ascending ? 1 : -1;
                    }
                    return 0;
                }
            });
    }

    ngAfterViewInit(): void {
        this.setCommandOptions();
        this.filterCommand = this._tableField?.filter?.command || this.getCommandsSet()[0].code;
        // wait for tablefields to get set
        setTimeout( () => {
            this.updateDatePickers();
        });
        this.cd.detectChanges();
    }
    
    private setCommandOptions(): void {
        this.commandOptions = this.getCommandsSet().map(this.commandToSelectOption);
    }
    
    getSelectedCommandOption(): LumiSelectOption[] {
        return this.getCommandsSet()
            .filter(command => command.code === this.filterCommand)
            .map(this.commandToSelectOption);
    }
    
    private commandToSelectOption(command): LumiSelectOption {
        return {
            id: command.code,
            name: command.label
        };
    }
    
    private getCommandsSet() {
        switch (this.getSelectedTableField().type) {
            case DataType.ICON:
            case DataType.COLOR_ICON:
            case DataType.COMPLEX:
            case DataType.STRING: {
                return this.stringCommands;
            }
            case DataType.DATE:
            case DataType.DATETIME: {
                return this.dateTimeCommands;
            }
            case DataType.INTEGER: {
                return this.integerCommands;
            }
            default: {
                return [];
            }
        }
    }
}
