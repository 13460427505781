import {ChangeDetectorRef, Component, EventEmitter, OnDestroy, Output} from '@angular/core'
import {GlobalModel} from "../../../shared/services/state/global.model";
import {MenuItem} from "../menu-item/menu-item";
/*import {Subscription} from "rxjs/Subscription";*/
import { Subscription } from 'rxjs';
import {DynamicMenuItemComponent} from "../menu-item/dynamic-menu-item.component";

@Component({
    selector: "right-side-menu-component",
    template:
        `        
        <dynamic-menu-item-component [expandDirection]="EXPAND_DIRECTION_RIGHT" [menuItems]="menuItems" [level]="0" (menuAction)="handleMenuAction($event)"></dynamic-menu-item-component>
    `,
})

export class RightSideMenuComponent implements OnDestroy{

    @Output('menuAction') menuActionEvent: EventEmitter<any> = new EventEmitter();

    private subRightSideMenuItems:Subscription;
    public menuItems:MenuItem[] = [];

    constructor(private model:GlobalModel, private cd:ChangeDetectorRef)
    {
        this.subRightSideMenuItems = this.model.rightSideMenuItems.subscribe((value:MenuItem[]) => {

            //TODO: remove wanneer permanent item beschikbaar is
            /*if (value.length > 0){
                let tempMenuItem:MenuItem = new MenuItem();
                tempMenuItem.id = null;
                tempMenuItem.label = "Importeren";
                tempMenuItem.code = "import";
                tempMenuItem.type = "DefaultMenuItem";
                tempMenuItem.uri = "/" + AppSettings.IMPORT_PAGE;
                tempMenuItem.icon = null;
                tempMenuItem.children = [];
                value[0].children.push(tempMenuItem);
                //--------------------------------------------------
            }*/

            this.menuItems = value;
            this.cd.markForCheck();
        });
    }

    public handleMenuAction(event:any){
        this.menuActionEvent.emit(event);
    }

    ngOnDestroy (){
        this.subRightSideMenuItems.unsubscribe();
    }

    get EXPAND_DIRECTION_RIGHT(){
        return DynamicMenuItemComponent.EXPAND_DIRECTION_RIGHT;
    }
}
