import {Component, Input, OnInit} from '@angular/core';
import {InteractiveTableService} from '../../components/interactive-table/interactive-table.service';
import {IInteractiveTable} from '../../components/interactive-table/interactive-table.interface';
import {TranslateService} from '../../../../services/translate/translate.service';
import {GlobalModel} from "../../../../services/state/global.model";
import {GuiChildAltConfig} from "../../gui.interface";
import {ButtonCode, PopupResult, PopupResultCode} from "../../../../../wrapper/global-alert/global-popup";
import {GlobalAlertService} from "../../../../../wrapper/global-alert/global-alert.service";
import {take} from "rxjs/operators";
import {ExportOptions} from "../../../../../modules/dashboard/widgets/widget";
import {
    Cell,
    DataType,
    ExportType,
    HeaderData,
    Row,
    TableData
} from "../../../table/shared/baseTable/baseTable.interface";
import {BaseTableExportService} from "../../../table/shared/baseTable/baseTableExport.service";

@Component({
    selector: 'interactive-table',
    templateUrl: './interactive-table.component.html',
    styleUrls: ['./interactive-table.component.scss']
})

export class InteractiveTableComponent implements OnInit {
    @Input('config') config: GuiChildAltConfig;
    disabled: boolean = false;

    showLoading:boolean = false;
    exportOptions: ExportOptions[] = [
        {name: 'excel', label: 'Excel (.xlsx)', show: true},
        {name: 'clipboard', label: 'Clipboard', show: true},
    ];
    showExportOptions: boolean = true;
    exportTableData:TableData = {
        headers: null,
        rows: null
    }
    dataTypes = [];
    dataIds = [];
    dataVisible = [];
    tableData:IInteractiveTable = {
        table: {
            settings: {
                createUrl: null,
                deleteUrl: null,
                editUrl: null
            },
            headers:[{
                id: null,
                label: null,
                type: null,
                visible: null,
                sort: null
            }],
            rows: [[{
                label: null
            }]]
        }
    };

    constructor(private interactiveTableService: InteractiveTableService, public ts: TranslateService, public model: GlobalModel, private alertService: GlobalAlertService,public exportService: BaseTableExportService) {
    }

    ngOnInit(): void {
        this.getTableRows();
        this.disabled = this.config.attr.disabled == undefined ? false : this.config.attr.disabled;
    }

    public enableActionButton(actionUrl:string):boolean{
        if(this.disabled){
            return false;
        }
        if(actionUrl === null){
            return false;
        }

        return true;
    }

    public deleteTableRow(rowId:number):void{
        this.alertService.addPopup(this.ts.translate('interactivetable.popupdeletetitle'), this.ts.translate('interactivetable.popupdeletelabel'),
            [{
                    label: this.ts.translate('Annuleren'),
                    code: ButtonCode.ANNULEREN,
                    isPrimary: true,
                }, {
                    label: this.ts.translate('Verwijderen'), code: ButtonCode.DELETE,
                    callback: () => {
                        this.interactiveTableService.deleteTableRow(this.config.attr.url_prefix, rowId)
                            .subscribe((tableItem) => {
                                if(tableItem){
                                    this.getTableRows();
                                }
                            }).add(() => {
                        });
                    },
                    isPrimary: false,
                },
            ], () => {
            });
    }

    public editTableRow(rowId:number):void{
        const saveUrl = this.config.attr.url_prefix+this.tableData.table.settings.editUrl+'/'+rowId;
        this.interactiveTableService.editTableRow(this.config.attr.url_prefix,rowId)
            .subscribe((result) => {
                if(result.schema){
                    this.alertService.openPopupBasicForm(
                        result,
                        saveUrl,
                        this.ts.translate('interactivetable.popupedittitle'),
                        this.ts.translate('interactivetable.popupeditlabel')
                    ).pipe(
                        take(2)
                    ).subscribe((popupResult: PopupResult) => {
                        if (popupResult?.code === PopupResultCode.OK) {
                            this.getTableRows();
                        }
                    });
                }
            });
    }

    public createTableRow():void{
        const saveUrl = this.config.attr.url_prefix+this.tableData.table.settings.createUrl;
        this.interactiveTableService.newTableRow(this.config.attr.url_prefix)
            .subscribe((result) => {
                if(result.schema){
                    this.alertService.openPopupBasicForm(
                        result,
                        saveUrl,
                        this.ts.translate('interactivetable.popuptitle'),
                        this.ts.translate('interactivetable.popuplabel')
                    ).pipe(
                        take(2)
                    ).subscribe((popupResult: PopupResult) => {
                        if (popupResult?.code === PopupResultCode.OK) {
                            this.getTableRows();
                        }
                    });
                }
            });
    }

    public getTableRows():void{
        this.toggleLoading();
        this.interactiveTableService.getTableRows(this.config.attr.url_prefix)
            .subscribe((tableItems) => {
                if(tableItems){
                    tableItems.table.headers.forEach(_x => {
                        _x.sort = 'asc';
                    })
                    this.tableData.table = tableItems.table;
                    this.setDataForRows();
                    this.buildTableForExport();
                }
            }).add(() => {
                this.toggleLoading();
        });
    }

    private buildTableForExport(){
        let headerList = [];
        let rowList = [];
        let cellList = [];
        this.tableData.table.headers.forEach(_x => {
            headerList.push({
                type: _x.type,
                label: _x.label,
                code: null,
                bold: null,
                columnId: null,
                columnRank: null,
                isVisible: true
            })
        })

        this.tableData.table.rows.forEach(_x => {
            cellList = [];
            _x.forEach((_y, index) => {
                cellList.push({
                    label: _y.label,
                    children: null,
                    dataType: this.getDataType(index),
                    isVisible: true
                })
            });

            rowList.push({
                cells:cellList,
                uniqueId: cellList[0].label,
                isVisible: true,
                isSelected: false,
                isHighlighted: false
            })
        })

        this.exportTableData = {
            headers: headerList,
            rows: rowList
        }
    }

    handleExportClick(option: string) {
        switch (option) {
            case 'excel':
                this.handleExportToExcel();
                break;
            case 'clipboard':
                this.handleCopyToClipboard();
                break;
            default:
                this.handleExportToExcel();
        }
    }

    private handleExportToExcel(): void {
        this.exportTable(ExportType.XLSX);
    }

    private handleCopyToClipboard(): void {
        this.exportTable(ExportType.CLIPBOARD);
    }

    public exportTable(exportType: ExportType) {
        const rows = this.exportTableData.rows;
        rows.forEach(row => row.cells.sort((cellA: Cell, cellB: Cell) => {
            return cellA.columnRank - cellB.columnRank;
        }));
        switch (exportType) {
            case ExportType.XLSX:
                this.exportService.exportToExcel(<any>this.exportTableData.headers, <any>rows);
                break;
            case ExportType.CLIPBOARD:
                this.exportService.copyToClipboard(<any>this.exportTableData.headers, <any>rows);
                break;
        }
    }

    private setDataForRows(){
        this.tableData.table.headers.forEach(_x => {
            this.dataTypes.push(_x.type);
            this.dataIds.push(_x.id);
            this.dataVisible.push(_x.visible);
        });
    }

    public sortTable(colIndex, colSort) {
        this.resetSortOrder();
        if(colSort == 'asc'){ // Sort descending
            this.tableData.table.headers[colIndex].sort = 'desc';
            this.tableData.table.rows.sort((a, b) => a[colIndex].label < b[colIndex].label ? 1 : a[colIndex].label > b[colIndex].label ? -1 : 0)
        } else { //Sort ascending
            this.tableData.table.headers[colIndex].sort = 'asc';
            this.tableData.table.rows.sort((a, b) => a[colIndex].label > b[colIndex].label ? 1 : a[colIndex].label < b[colIndex].label ? -1 : 0)
        }

    }

    private resetSortOrder(){
        this.tableData.table.headers.forEach((_x, colIndex) => {
            _x.sort = 'asc';
            this.tableData.table.rows.sort((a, b) => a[colIndex].label < b[colIndex].label ? 1 : a[colIndex].label > b[colIndex].label ? -1 : 0)
        })
    }

    public getDataType(columnIndex){
        return this.dataTypes[columnIndex];
    }
    public getDataId(columnIndex){
        return this.dataIds[columnIndex];
    }
    public getDataVisible(columnIndex){
        return this.dataVisible[columnIndex];
    }

    public toggleLoading(): void{
        this.showLoading = this.showLoading ? false : true;
    }

}
