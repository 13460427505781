import {
    AfterViewInit,
    ChangeDetectorRef,
    Component, EventEmitter,
    HostBinding,
    OnDestroy,
    Output,
    Renderer2,
    ViewChild
} from "@angular/core";
import {ValidationConstraintService} from "../../services/validation-constraint.service";
import {FormInputComponent} from "../form-input/form-input.component";
import {GlobalAlertService} from "../../../../../wrapper/global-alert/global-alert.service";
import {TooltipService} from "../../../../services/tooltip/tooltip.service";
import {FormDataService} from "../../services/form-data.service";
import {GlobalModel} from "../../../../services/state/global.model";
import {TranslateService} from "../../../../services/translate/translate.service";
import {Subscription} from "rxjs";
import {DimmingService} from "../../../../../modules/control/dimming/dimming.service";
import {TinyGraphComponent} from "../form-graph/tiny-graph.component";
import {FormComponent} from "../../containers/form/form.component";
import Utils from "../../../../utils/utils";
import {AuthorizationService} from "../../../../services/authorization/authorization.service";
import {FormEvent} from '../../containers/form/form.interface';
import {LoggerService} from "../../../../services/logger/logger.service";

@Component({
    selector: 'dimming_scheme_select',
    template: `
        <ng-container [formGroup]="group">
            <div class="d-flex flex-column dimming-scheme-selector">
                <div class="d-flex flex-row px-2" style="margin-top: 0.3rem;">
                    <div *ngFor="let day of dayData" (click)="handleClickDay($event, day)" [title]="day.day" class="px-1 dimming-scheme-selector-day-block {{isDisabled()?'cursor-disabled':'cursor-pointer'}} unselectable-content {{isSelectedDay(day)?'selected-day':''}}">
                        {{mobileMode?day.shortDay:day.shortDay}}
                    </div>
                </div>
                <div [style.visibility]="currentDays.length > 0?'visible':'hidden'" class="dynamic-form px-2" style="">
                    <div class="d-flex mt-1 ">
                        <div class="d-flex w-100 align-items-center">
                            <!--<i class="material-icons p-0 pr-2">equalizer</i>-->
                            <select #dropDown [attr.disabled]="auth.allowSetDimmingScheme()?disabled():''" class="form-control p-0 m-0" style="background: white" (change)="handleSelectScheme($event)">
                                <option *ngIf="multipleOptionsActive" [selected]="multipleOptionsActive" [value]="null">{{'dimmingSchemes.multiple' | translate}}</option>
                                <option *ngFor="let option of dropDownData" [selected]="isSelectedOption(option.id)" [value]="option.id">{{option.name}}</option>
                            </select>

                            <div class="ml-2 card {{(disabled() == null && auth.allowSetDimmingScheme())?'cursor-pointer':'d-none'}}" (click)="handleAddScheme($event)" [title]="'Maak een nieuw item aan' | translate">
                                <i class="p-2 material-icons " style="font-size: 20px">add</i>
                            </div>
                            
                        </div>
                    </div>
                </div>
                <div [style.visibility]="currentDays.length > 0?'visible':'hidden'" class="d-flex flex-row align-items-center mb-2 {{(disabled() == null && auth.allowEditDimmingScheme())?'cursor-pointer':'cursor-disabled'}}" [title]="(auth.allowEditDimmingScheme()?'dimmingscheme.edit':'dimmingscheme.noedit') | translate">
                    <tiny-graph class="" #graph (click)="handleClickGraph($event)" [graphHeight]="100"></tiny-graph>
                </div>
            </div>
        </ng-container>
    `
})

export class DimmingSchemeSelectComponent extends FormInputComponent implements AfterViewInit, OnDestroy
{
    @Output() onComponentEvent: EventEmitter<any> = new EventEmitter();
    @HostBinding('class') hostClasses = 'd-flex w-100 flex-column';
    @ViewChild('graph', {static: false}) tinyGraph:TinyGraphComponent;
    @ViewChild('dropDown', {static: false}) dropDown:any;

    public static readonly GRAPH_PATH:string = 'control-form/dimgroup/scheme/graph/get/';

    public mobileMode:boolean = false;
    private subMobileMode: Subscription = null;

    public dayData:any;
    public dropDownData:any;
    public currentDays:any[] = [0];
    private lastSelectedDay:number = 0;
    public multipleOptionsActive:boolean = false;
    
    constructor(public renderer:Renderer2, public validationConstraintService:ValidationConstraintService, public ts:TranslateService, public tooltipService:TooltipService, public globalAlertService: GlobalAlertService, private dimmingService:DimmingService, private formDataService:FormDataService, public cd:ChangeDetectorRef, public model:GlobalModel, public auth:AuthorizationService, protected logger:LoggerService)
    {
        super(renderer, validationConstraintService, tooltipService, globalAlertService, ts, logger, model);

        this.subMobileMode = this.model.mobileMode.subscribe((value:boolean) => {

            //Don't forget to unsubscribe
            this.mobileMode = value;
        });

        this.currentDays = this.model.lastSelectedDimmingSchemeDays;
        if (this.currentDays.length > 0){
            this.lastSelectedDay = this.currentDays[0];
        }
        
        this.multipleOptionsActive = this.isMulitpleOptionsActive();
    }

    public isDisabled(){
        return super.disabled() != null;
    }

    //Check if mulitple different dimming schemes are selected. Multiple schemes who are the same return false.
    private isMulitpleOptionsActive():boolean
    {
        if (this.dayData && this.currentDays && this.currentDays.length > 1) {

            let defaultDimmingSchemeId:number = this.dayData[this.currentDays[0]].dimmingScheme;

            for(let i = 1; i < this.currentDays.length; i++) {
                if (this.dayData[this.currentDays[i]].dimmingScheme != defaultDimmingSchemeId){
                    return true;
                }
            }
        }

        return false;
    }

    //Check if an dimmingscheme-option is the selected dimmingscheme
    public isSelectedOption(dimmingSchemeId:number):boolean
    {
        if (this.dayData && this.currentDays && this.currentDays.length > 0) {
            if ((this.currentDays.length == 1 || !this.multipleOptionsActive) && dimmingSchemeId == this.dayData[this.currentDays[0]].dimmingScheme) {
                return true;
            }
        }

        return false;
    }

    public handleClickDay(event:MouseEvent, day:any):void{

        if (this.isDisabled()){
            return;
        }

        let newId:number = day.id -1;

        if (event.shiftKey){

            //Select all days from the last selected day to the new selected day
            let min:number = Math.min(this.lastSelectedDay, newId);
            let max:number = Math.max(this.lastSelectedDay, newId);

            for(let i = min; i <= max; i++)
            {
                if (this.currentDays.indexOf(i) > -1) {
                    // bestaat al, voeg niet toe
                }else{
                    this.currentDays.push(i);
                }
            }

        }else {
            //Handle ctrl click
            if (event.ctrlKey || event.metaKey) {

                //Voeg day.id toe aan array, of als hij er al is, verwijder hem
                let dayIndex:number = this.currentDays.indexOf(newId);
                if (dayIndex > -1) {
                    this.currentDays.splice(dayIndex, 1);
                }else{
                    this.currentDays.push(newId);
                }
            }else{
                this.currentDays = [newId];
            }
        }

        this.lastSelectedDay = newId;
        this.model.lastSelectedDimmingSchemeDays = this.currentDays;
        
        //Update the view
        this.updateView();
    }

    // Check if a day is selected
    public isSelectedDay(day:any):boolean
    {
        for(let i = 0; i < this.currentDays.length; i++) {
            if (this.dayData[this.currentDays[i]] == day){
                return true;
            }
        }

        return false;
    }

    //Link to scheme edit on click
    public handleClickGraph(event:MouseEvent):void
    {
        if (this.isDisabled() || !this.auth.allowEditDimmingScheme()) {
            return;
        }

        let selectedSchemeId:number = this.getSelectedDimmingSchemeId();
        let selectedChannel:number = this.tinyGraph.getSelectedChannel();

        if (selectedSchemeId > -1){
            this.onComponentEvent.emit({event: FormEvent.CLICK_GRAPH, data: {dimmingSchemeId: selectedSchemeId, channel: selectedChannel}})
        }
    }

    //Get the selected dimmingscheme via the element
    private getSelectedDimmingSchemeId():number
    {
        return this.dropDown.nativeElement.value != "null" && this.dropDown.nativeElement.value != null && this.dropDown.nativeElement.value != ""?parseInt(this.dropDown.nativeElement.value):-1;
    }

    private getGraphData():void
    {
        setTimeout(() =>
        {
            let selectedSchemeId:number = this.getSelectedDimmingSchemeId();

            //Get graph or clear graph when no dimmingscheme is selected
            if (selectedSchemeId < 0){
                this.tinyGraph.clearGraphData();
            }else{
                this.tinyGraph.updateGraphData(DimmingSchemeSelectComponent.GRAPH_PATH + selectedSchemeId);
            }

        }, 100);
    }

    public handleSelectScheme(event:any):void
    {
        if (event.target && event.target.value > -1)
        {
            // Get the selected scheme and apply it to all selected days
            let newDimScheme:number = parseInt(event.target.value);
            for(let i = 0; i < this.currentDays.length; i++) {
                this.dayData[this.currentDays[i]].dimmingScheme = newDimScheme;
            }

            // Prepare the form for submit
            this.group.get(this.config.name).patchValue(this.dayData);
            
            // Update the view
            this.updateView();
        }
    }

    public handleAddScheme(event:MouseEvent):void
    {
        if (!this.auth.allowSetDimmingScheme()){return}

        let creationHref:string = "control-form/dimgroup/scheme/create";
        let label:string = "dimschema's";

        this.globalAlertService.addPopupCreateDropdown(this.ts.translate("Item toevoegen aan: ") + label, "", creationHref, (buttonCode:any, response:any) => {

            //User pressed OK and call succeeded
            this.logger.log("[DimmingSchemeSelectComponent] " + "Gebruiker heeft op OK gedrukt, response:", response);
            if (response)
            {
                //Update dropdown with new value. add to selected items and update the form
                let newOption:any = {id:response.id, name:response.name, checked:true};
                this.dropDownData.push(newOption);

                //Set the option for all selected days and reload the graph
                for(let i = 0; i < this.currentDays.length; i++) {
                    this.dayData[this.currentDays[i]].dimmingScheme = newOption.id;
                }

                //Patch the form, to be ready for a submit
                this.group.get(this.config.name).patchValue(this.dayData);

                //Update the view
                this.updateView();
            }
        }, () => {
            this.logger.log("[DimmingSchemeSelectComponent] " + "Gebruiker heeft op ANNULEREN of kruisje gedrukt");
        });
    }

    //Update the view
    private updateView():void
    {
        this.multipleOptionsActive = this.isMulitpleOptionsActive();
        this.getGraphData();
        this.cd.detectChanges();
    }
    
    ngAfterViewInit()
    {
        // Use the data from the config to create the options and day-names, and draw the first graph
        if (this.config && this.config.attr){
            this.dayData = this.config.attr.dimmingSchemes;

            for (let i = this.dayData.length - 1; i >= 0; i--) {
                this.dayData[i].shortDay = Utils.capitalizeFirstLetter(this.dayData[i].day.substr(0, 3));

                // Haxortje for Dutch days
                if (this.dayData[i].shortDay == "Maa"){
                    this.dayData[i].shortDay = "Ma"
                }

                this.dayData[i].superShortDay = Utils.capitalizeFirstLetter(this.dayData[i].day.substr(0, 1));
            }

            this.dropDownData = this.config.attr.dropdown;
            this.updateView();
        }
    }

    ngOnDestroy()
    {
        if (this.subMobileMode){
            this.subMobileMode.unsubscribe();
        }
    }

    ngOnInit() {}

    get GRAPH_PATH():string{
        return DimmingSchemeSelectComponent.GRAPH_PATH;
    }
}
