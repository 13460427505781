/**
 * Created by Christiaan on 13/03/2017.
 */
import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, NgZone, ViewChild} from '@angular/core';
import {GlobalModel} from "../../shared/services/state/global.model";
import {MapTableComponent} from "../../shared/components/map-table/map-table.component";
import {ActivatedRoute, Router} from "@angular/router";
import {MapTableService} from "../../shared/components/map-table/map-table.service";
import {GlobalAlertService} from "../../wrapper/global-alert/global-alert.service";
import {HTTPService} from "../../shared/services/http/http.service";
import {FormDataService} from "../../shared/components/form/services/form-data.service";
import {AuthorizationService} from "../../shared/services/authorization/authorization.service";
import {TranslateService} from "../../shared/services/translate/translate.service";
import {TreeMapFormComponent} from "../../shared/components/tree-map-form/tree-map-form.component";
import {SectionFormComponent} from "../../shared/components/form/components/section-form.component";
import {StorageService} from "../../shared/services/storage/storage.service";
import {TableOptionsService} from "../../shared/components/table/table-options.service";
import { FormEvent } from 'src/app/shared/components/form/containers/form/form.interface';
import {AreaalService} from '../../shared/services/areaal/areaal.service';
import {GlobalStateService} from "../../shared/services/state/global-state.service";
import {LoggerService} from "../../shared/services/logger/logger.service";

@Component ({
    selector: 'public-portal-component',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: '../../shared/components/tree-map-form/tree-map-form.component.html'
})
export class PublicPortalComponent extends TreeMapFormComponent implements AfterViewInit {

    public static readonly FORM_URL: string = "/public-portal/view";
    public static readonly MODULE_PATH_PUBLIC_PORTAL: string = "public-portal";

    @ViewChild('mapTableComponent', {static: false}) mapTableComponent: MapTableComponent;
    @ViewChild('formComponent', {static: false}) formComponent: SectionFormComponent;
    @ViewChild('dragContainer', {static: false}) dragContainer: any;

    constructor(
        public model: GlobalModel,
        protected mapTableService: MapTableService,
        protected elementRef: ElementRef,
        protected cd: ChangeDetectorRef,
        protected ngZone: NgZone,
        protected activatedRoute: ActivatedRoute,
        private router: Router,
        protected globalAlertService:GlobalAlertService,
        protected httpService:HTTPService,
        protected formDataService:FormDataService,
        public auth:AuthorizationService,
        public ts:TranslateService,
        protected storage:StorageService,
        protected tableOptionsService:TableOptionsService,
        protected areaalService: AreaalService,
        protected globalStateService: GlobalStateService,
        protected logger:LoggerService
    ) {
        super(cd, activatedRoute, httpService, router, model, mapTableService, storage, tableOptionsService, auth, formDataService, ngZone, activatedRoute, globalAlertService, areaalService, globalStateService, ts, logger);
    
        this.setTreeMapFormSettings(
            'publicPortal_1',
            'publicPortal_2',
            ['pp-mast'],
            PublicPortalComponent.MODULE_PATH_PUBLIC_PORTAL,
            [],
            PublicPortalComponent.FORM_URL,
            '',
            FormDataService.FORM_URL_PUBLIC_PORTAL,
            this.model.publicPortalSelectedItems,
            this.model.publicPortalFormData,
            this.model.publicPortalFormCollapsed,
            this.model.publicPortalTreeCollapsed,
            this.model.publicPortalMapItems,
            this.model.publicPortalAutoLoadedMapItems,
            this.model.publicPortalAutoLoadedTableItems,
            this.model.publicPortalTableItems,
            this.model.publicPortalFilterString,
            this.model.publicPortalTree,
            {
                isBasicMode: this.isBasicMode,
                allowCreateMarker: true,
                allowMultiSelect: false,
                allowMarkerDrag: false
            }
        );
        
        // Never show the form for PP
        this.hideForm = true;
    }

    ngAfterViewInit():void
    {
        this.viewInit();
    }

   /* private handleMapLongPress(e:any):void
    {
        this.globalAlertService.addPopupCreatePublicReport('public-portal/new-report', 'public-portal/save-report', (buttonCode:any, response:any) =>
        {
            // Show success popup
            this.globalAlertService.addPopup(this.ts.translate('publicportal.reportsuccesstitle'), this.ts.translate('publicportal.reportsuccesslabel', [this.model.currentAreaal.value.label]), [{label:this.ts.translate("Ok"), code:"OK", isPrimary:true}], () => {});

            // Refresh the map icon
            if (response && response.schema && response.schema.base_object_id){
                this.refreshMapTableItems([response.schema.base_object_id]);
            }

        }, () => {}, e.position.lat(), e.position.lng());
    }*/

    handleComponentEvent(eventData: any):void
    {
        switch (eventData.event) {
            case FormEvent.CLEAR_SELECTION:
            case FormEvent.CANCEL:
                const polygonSelect = this.mapTableComponent.mapComponent.polygonSelect
                if (polygonSelect.polyIsActive()) {
                    polygonSelect.closePolygonSelect()
                }
                this.clearSelectedItems();
                break;
            case FormEvent.SAVE_SUCCESS:
                this.refreshMapTableItems(this.getSelectedItemIds());
                break;
        }
    }

    // Overwrite super class
    handleTableMapSelectionChange(selectedItems: any)
    {
        super.handleTableMapSelectionChange(selectedItems, (params: any) => {

            let firstItem: any = selectedItems[0];
            let firstItemId: any = selectedItems[0].baseObjects[0].id;

            this.globalAlertService.addPopupCreatePublicReport('public-portal/new-report/' + firstItemId, 'public-portal/save-report/' + firstItemId, (buttonCode: any, response: any) => {
                // Show success popup
                this.globalAlertService.addPopup(this.ts.translate('publicportal.reportsuccesstitle'), this.ts.translate('publicportal.reportsuccesslabel', [this.model.currentAreaal.value.label]), [{
                    label: this.ts.translate('Ok'),
                    code: 'OK',
                    isPrimary: true,
                }], () => {
                });

                // Refresh the map icon
                if (response && response.schema && response.schema.base_object_id) {
                    this.refreshMapTableItems([response.schema.base_object_id]);
                }

            }, () => {
            }, firstItem.lat, firstItem.lng, firstItemId);

            this.router.navigate([this.MODULE_FORM_URL, this.model.publicPortalArea.value, params], {queryParamsHandling: "merge"});
        });
    }

    // Overwrites super class
    public clearSelectedItems()
    {
        super.clearSelectedItems([this.MODULE_FORM_URL, this.model.publicPortalArea.value, 0]);
    }

    // Overwrites super class
    protected clearVisibleItems():void
    {
        super.clearVisibleItems([this.MODULE_FORM_URL, this.model.publicPortalArea.value, 0])
    }
}
