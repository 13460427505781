/**
 * Created by Christiaan on 01/03/2017.
 */
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component, ElementRef,
    OnDestroy,

} from '@angular/core';

import {GlobalAlertService} from '../global-alert.service';
import {ButtonCode, GlobalPopup} from '../global-popup';
import {TranslateService} from '../../../shared/services/translate/translate.service';
import {Subscription} from 'rxjs';
import {GlobalModel} from '../../../shared/services/state/global.model';
import {AbstractBasePopupComponent} from './abstract-base-popup.component';
import {TooltipService} from '../../../shared/services/tooltip/tooltip.service';
import {LoggerService} from "../../../shared/services/logger/logger.service";

@Component({
    selector: 'global-popup-set-override-component',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <default-popup-wrapper-component [globalPopup]="globalPopup" [title]="'override.set' | translate"
                                         (onClose)="closePopup($event)">
            <div class="d-flex flex-column">
                <div class="d-flex flex-row">
                    <div class="px-2 py-2 dimgroup-override-tab {{showOverrideOptions?'active':''}}"
                         (click)="handleClickTab(1)">{{'override.create' | translate}}</div>
                    <div class="px-2 py-2 dimgroup-override-tab {{showOverrideOptions?'':'active'}}"
                         (click)="handleClickTab(0)">{{'override.release' | translate}}</div>
                </div>
                <div class="dimgroup-override-selection-block p-2 d-flex" style="min-height: 200px">
                    <div class="d-flex flex-column w-100" *ngIf="!showOverrideOptions">
                        <div class="pb-2">{{'override.release.description' | translate}}</div>
                        <div class="ml-auto mt-auto align-self-center">
                            <lumi-button (onClick)="handlePopupAction($event,globalPopup,{code:BUTTON_CODE_DELETE, callback:globalPopup.callbackSuccess})"
                                         [label]="'override.release.full' | translate"
                                         [size]="'large'"
                                         [rank]="'secondary'">
                            </lumi-button>
                        </div>
                    </div>
                    <div class="d-flex flex-column w-100" *ngIf="showOverrideOptions">
                        <div class="pb-2">{{'override.create.description' | translate}}</div>
                        <div class="d-flex flex-row mb-2 dynamic-form">
                            <label class="form-label-part"
                                   style="font-weight:400;">{{'override.lightlevel' | translate }}</label>
                            <div class="range-field d-flex w-100" style="align-items:center">
                                <input [attr.disabled]="showOverrideOptions?null:''" type="range"
                                       [attr.step]="1" [attr.min]="0" [attr.max]="100" [attr.value]="overrideLevel"
                                       (input)="onFormInput($event)">
                            </div>
                            <div class="range-value-feedback p-1 align-self-center">{{ overrideLevel }}</div>
                        </div>
                        <div class="ml-auto mt-auto align-self-center">
                            <lumi-button (onClick)="handlePopupAction($event,globalPopup,{code:BUTTON_CODE_OK, callback:globalPopup.callbackSuccess})"
                                         [label]="'override.create.full' | translate"
                                         [size]="'large'"
                                         [rank]="'secondary'">
                            </lumi-button>
                        </div>
                    </div>
                </div>
            </div>
        </default-popup-wrapper-component>
    `,
})
export class GlobalPopupSetOverrideComponent extends AbstractBasePopupComponent implements OnDestroy {
    overrideLevel: number = 100;
    private mobileMode: boolean = false;
    private readonly subMobileMode: Subscription = null;
    showOverrideOptions: boolean = true;

    public constructor(protected cd: ChangeDetectorRef, protected elementRef: ElementRef, protected globalAlertService: GlobalAlertService, protected ts: TranslateService, private model: GlobalModel, protected tooltipService: TooltipService, protected logger:LoggerService) {
        super(cd, globalAlertService, elementRef, ts, tooltipService, logger);

        this.subMobileMode = this.model.mobileMode.subscribe((value: boolean) => {
            //Don't forget to unsubscribe
            this.mobileMode = value;
        });
    }

    handleClickTab(id: number): void {
        this.showOverrideOptions = id != 0;
    }

    handlePopupAction(event: MouseEvent, alert: GlobalPopup, button: any): void {
        //Hide alert and perform callback of button
        this.onPopupAction.emit({
            event: event,
            alert: alert,
            button: button,
            data: {overrideLevel: this.overrideLevel},
        });
    }

    closePopup(event: any): void {
        this.doDefaultCloseHandling(event, false);
    }

    onFormInput(event: any): void {
        this.overrideLevel = event.target.valueAsNumber;
    }

    ngOnDestroy() {
        if (this.subMobileMode) {
            this.subMobileMode.unsubscribe();
        }
    }

    get DEFAULT_BLUE_BUTTON_CLASSES(): string {
        return GlobalAlertService.DEFAULT_BLUE_BUTTON_CLASSES;
    }

    get BUTTON_CODE_OK(): string {
        return ButtonCode.OK;
    }

    get BUTTON_CODE_DELETE(): string {
        return ButtonCode.DELETE;
    }
}
