import {Directive, EventEmitter, HostBinding, HostListener, Output} from '@angular/core';

@Directive({
    selector: '[fileDropzone]'
})
export class FileDropzoneDirective {
    @HostBinding('class.fileover') fileOver: boolean;
    @Output() private filesDropped: EventEmitter<FileList> = new EventEmitter<FileList>();
    
    constructor() {
    }
    
    
    @HostListener('dragover', ['$event'])
    public onDragOver(event) {
        this.preventDefault(event);
        this.fileOver = true;
    }
    
    @HostListener('dragleave', ['$event'])
    public onDragLeave(event) {
        this.preventDefault(event);
        this.fileOver = false;
    }
    
    @HostListener('drop', ['$event'])
    public onDrop(event) {
        this.preventDefault(event);
        this.fileOver = false;
        const files: FileList = event.dataTransfer.files;
        if (files.length > 0) {
            this.filesDropped.emit(files);
        }
    }
    
    private preventDefault(event: Event) {
        event.preventDefault();
        event.stopPropagation();
    }
    
}
