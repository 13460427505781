import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {DropdownMenuItem, DropdownMenuItemEvent} from './menu-dropdown.interface';
import Utils from '../../../../utils/utils';

@Component({
    selector: 'menu-dropdown',
    templateUrl: './menu-dropdown.component.html'
})
export class MenuDropdownComponent implements OnInit {
    @Input() menuTitle: string = null;
    @Input() menuItems: DropdownMenuItem[] = [];
    @Input() buttonSizeSmall: boolean = false;
    @Input() buttonColor: string = '';
    @Input() leftDropdown: boolean = false;
    @Input() fitcontent: boolean = false;
    @Output() onMenuItemClick: EventEmitter<DropdownMenuItemEvent> = new EventEmitter<DropdownMenuItemEvent>();
    @ViewChild('menuButton', {static: false}) menuButton: ElementRef<HTMLDivElement>;
    @ViewChild('menuItemsContainer', {static: false}) menuItemsContainer: ElementRef<HTMLDivElement>;
    
    public showMenu: boolean = false;
    public menuItemElements: Element[] = [];
    private preventKeyUpEvent: boolean = false;
    private focussedMenuItem: Element;
    
    constructor() {
    }
    
    ngOnInit(): void {
    }
    
    public handleClickMenu($event: MouseEvent | KeyboardEvent): void {
        Utils.preventDefault($event);
        this.showMenu = !this.showMenu;
        if (this.showMenu) {
            this.setMenuItemElementsArray();
            if ($event.type !== 'click') {
                this.focusMenuItem(this.menuItemElements[0]);
            }
        }
    }
    
    public setMenuItemElementsArray(): void {
        this.menuItemElements = [];
        Array.from(this.menuItemsContainer.nativeElement.children).forEach(element => this.searchRecursiveForItems(element));
    }
    
    public handleMenuItemClick($event: MouseEvent, item: DropdownMenuItem): void {
        Utils.preventDefault($event);
        this.onMenuItemClick.emit({$event: $event, item: item});
        this.closeMenu();
    }
    
    public closeMenu(): void {
        this.showMenu = false;
    }
    
    public getButtonClass(): string {
        return this.buttonSizeSmall ? 'menu-dropdown-small' : 'menu-dropdown';
    }
    
    public onKeyUpMenuButton($event: KeyboardEvent) {
        if (this.preventKeyUpEvent) {
            this.preventKeyUpEvent = false;
            Utils.preventDefault($event);
            return;
        }
        if (!Utils.hasFocus(this.menuButton.nativeElement)) {
            return;
        }
        if ($event.key === 'Enter' && !this.showMenu) {
            this.handleClickMenu($event);
        }
        if ($event.key === 'Escape') {
            Utils.preventDefault($event);
            Utils.removeAllFocus();
        }
    }
    
    public onkeyDownMenu($event: KeyboardEvent) {
        if (!this.showMenu) {
            return;
        }
        const currentFocussedIndex: number = this.menuItemElements.indexOf(this.focussedMenuItem);
        
        if ($event.key === 'ArrowDown' || $event.key === 'ArrowRight') {
            Utils.preventDefault($event);
            if (currentFocussedIndex === this.menuItemElements.length - 1) {
                this.focusMenuItem(this.menuItemElements[0]);
            } else {
                this.focusMenuItem(this.menuItemElements[currentFocussedIndex + 1]);
            }
        }
        
        if ($event.key === 'ArrowUp' || $event.key === 'ArrowLeft') {
            Utils.preventDefault($event);
            if (currentFocussedIndex === 0) {
                this.focusMenuItem(this.menuItemElements[this.menuItemElements.length - 1]);
            } else {
                this.focusMenuItem(this.menuItemElements[currentFocussedIndex - 1]);
            }
        }
        
        if ($event.key === 'Enter') {
            Utils.preventDefault($event);
            this.focussedMenuItem.dispatchEvent(new Event('click', {bubbles: true}));
            this.menuButton.nativeElement.focus();
            this.preventKeyUpEvent = true;
        }
        
        if ($event.key === 'Escape') {
            Utils.preventDefault($event);
            this.closeMenu();
            this.menuButton.nativeElement.focus();
            this.preventKeyUpEvent = true;
        }
        
        if ($event.key === 'Tab') {
            this.closeMenu();
        }
    }
    
    private searchRecursiveForItems(element: Element): void {
        if (!element) {
            return;
        }
        if (element.attributes['menuItem']) {
            this.menuItemElements.push(element);
        }
        if (element.children?.length > 0) {
            Array.from(element.children).forEach(_element => this.searchRecursiveForItems(_element));
        }
    }
    
    private focusMenuItem(item: Element): void {
        if (!item) {
            return;
        }
        this.focussedMenuItem = item;
        this.menuItemElements.forEach(_item => _item.classList.remove('focussed'));
        item.classList.add('focussed');
    }
}
