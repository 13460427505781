/**
 * Created by Christiaan on 16/03/2017.
 */

import {Injectable} from '@angular/core';
import {HTTPService} from "../../shared/services/http/http.service";
import {RequestFailure} from "../../shared/services/http/request-failure";
import {HTTPError} from "../../shared/services/http/http-error";
import {LoggerService} from "../../shared/services/logger/logger.service";

@Injectable() export class UserService {

    public static readonly REQUEST_CHANGE_PASSWORD_PATH:string = "user/password/forgot";
    public static readonly CHANGE_PASSWORD_PATH:string = "user/password/change";

    constructor(private httpService:HTTPService, protected logger:LoggerService) {}

    public getUserIdParam():string{
        return this.httpService.getQueryParam("userId");
    }

    public getTokenParam():string{
        return this.httpService.getQueryParam("token");
    }

    public getStatusParam():string{
        return this.httpService.getQueryParam("status");
    }

    public requestPasswordChange(username:string, successCallBack?:() => any, failCallBack?:(failure:RequestFailure) => any)
    {
        let path:string = UserService.REQUEST_CHANGE_PASSWORD_PATH;
        //Build the post values
        let postValues:any = {forgotPassword:{'userName': username}};
        //this.httpService.appendFormNameToJSON(postValues, "forgotPassword");

        this.httpService.doPostRequest(path, postValues,
            (json:any)=>{

                this.logger.log("[UserService] " + "change password success response");

                //Let component know request is send
                successCallBack();
            }, (failure:RequestFailure)=>{

                //The username is not valid or other failure
                failCallBack(failure);
            },
            (error:HTTPError)=>{},
            false);
    }

    //TODO: opruimen
    public changePassword(userId:string, token:string, newPassword:string, repeatedPassword:string, successCallBack?:() => any, failCallBack?:(failure:RequestFailure) => any)
    {
        let path:string = UserService.CHANGE_PASSWORD_PATH;

        //Build the post values
        let postValues:any = {changePassword:{'user': userId, 'token':token, "newPassword":newPassword, "repeatedPassword":repeatedPassword}};
        //this.httpService.appendFormNameToJSON(postValues, "changePassword");

        this.httpService.doPostRequest(path, postValues,
            (json:any)=>{

                this.logger.log("[UserService] " + "change password success response");

                //Let component know request is send
                successCallBack();
            }, (failure:RequestFailure)=>{

                //The username is not valid or other failure
                failCallBack(failure);
            },
            (error:HTTPError)=>{},
            false);
    }
}
