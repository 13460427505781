import { Pipe, PipeTransform } from '@angular/core';
import {TranslateService} from '../services/translate/translate.service';

@Pipe({
  name: 'humanDate'
})
export class HumanDatePipe implements PipeTransform {

    constructor(private ts:TranslateService) {
    }

    transform(dateTime: string): unknown {
        if (!dateTime) {
            return `-`;
        }

        // Create dates
        const lastComm = new Date(dateTime)
        const today = new Date()
        const diffInDays = Math.floor((today.getTime() - lastComm.getTime()) / (1000 * 3600 * 24)); // In days
        const diffInSeconds = Math.floor((today.getTime() - lastComm.getTime()) / 1000); // In seconds

        // Add 0 before hour and minutes, if its between 0 and 9
        let hours = lastComm.getHours().toString();
        let minutes = lastComm.getMinutes().toString();
        if (lastComm.getHours() >= 0 && lastComm.getHours() <= 9) {
            hours = '0'+lastComm.getHours();
        }
        if (lastComm.getMinutes() >= 0 && lastComm.getMinutes() <= 9) {
            minutes = '0'+lastComm.getMinutes();
        }

        // Check if its today, yesterday, in a week or longer ago
        if (diffInDays === 0) {
            if (diffInSeconds <= 60) {
                return `${diffInSeconds} ${this.ts.translate('secondsago')}`
            } else if (diffInSeconds > 60 && diffInSeconds <= 3600) {
                return `${Math.floor(diffInSeconds / 60)} ${this.ts.translate('minutesago')}`
            } else {
                return `${Math.floor(diffInSeconds / 3600)} ${this.ts.translate('hoursago')}`
            }
        } else if (diffInDays === 1) {
            return `${this.ts.translate('gisteren')} ${hours}:${minutes}`
        } else if (diffInDays >= 2 && diffInDays <= 7) {
            return `${this.ts.translate(new Intl.DateTimeFormat('nl-NL', {weekday:'long'}).format(lastComm))} ${hours}:${minutes}`
        } else {
            return `${lastComm.getDate()}-${lastComm.getMonth()+1}-${lastComm.getFullYear()} ${hours}:${minutes}`
        }
    }

}
