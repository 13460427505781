/**
 * Created by Christiaan on 07/04/2017.
 */

import {Directive, NgZone, Output, EventEmitter, ElementRef} from '@angular/core';

@Directive({
    selector: '[inputOutsideAngular]'
})

export class InputOutsideAngularDirective
{
    private static readonly EVENT_INPUT:string = "input";

    @Output('inputOutsideAngular') inputHandler: EventEmitter<any> = new EventEmitter();

    constructor(private elementRef: ElementRef, private ngZone:NgZone)
    {
        this.ngZone.runOutsideAngular(() => {
            this.elementRef.nativeElement.addEventListener(InputOutsideAngularDirective.EVENT_INPUT, (event:any)=> {
                this.inputHandler.emit(event);
            });
        });
    }
}
