import {AfterViewInit, Component, EventEmitter, Input, OnDestroy} from "@angular/core";
import {Router} from "@angular/router";
import {MapItemService} from "../../../map/map-item/map-item.service";
import {RequestFailure} from "../../../../services/http/request-failure";
import {HTTPError} from "../../../../services/http/http-error";
import {GlobalAlertService} from "../../../../../wrapper/global-alert/global-alert.service";
import {GlobalModel} from "../../../../services/state/global.model";
import { Subscription } from 'rxjs';
import MapIconGenerator from "../../../../utils/map-icon-generator";
import {TranslateService} from "../../../../services/translate/translate.service";
import Utils from "../../../../utils/utils";
import {GlobalEvent} from "../../../../interfaces/global-event";
import {TreeMapFormComponent} from "../../../tree-map-form/tree-map-form.component";
import {ButtonCode} from '../../../../../wrapper/global-alert/global-popup';
import {IBushData, IBushDataNode,IBushDataNodeWorkorder} from './form-bush-item-tree.interface';
import {LoggerService} from "../../../../services/logger/logger.service";


@Component({
    selector: 'form-bush-item-tree-node',
    templateUrl: 'form-bush-item-tree-node.component.html',
    styleUrls: ['./bush-item-tree-node.component.scss']
})

export class FormBushItemTreeNodeComponent implements OnDestroy
{
    @Input('nodes') nodes:IBushDataNode[];
    @Input('parentNode') parentNode:IBushData = null;
    @Input('currentBaseObject') currentBaseObject:number = 0;
    @Input('baseHref') baseHref:string = "";
    @Input('workorders') workorders:IBushDataNodeWorkorder[];
    protected subOnGlobalEvent: Subscription = null;
    public selectedNode:IBushDataNode;

    constructor(private router:Router, private mapItemService:MapItemService, private globalAlertService:GlobalAlertService, private model:GlobalModel, private ts:TranslateService, protected logger:LoggerService) {
        this.subOnGlobalEvent = this.model.onGlobalEvent.subscribe((event: GlobalEvent) => {
            switch (event.type) {
                case GlobalEvent.EVENT_REMOVE_BASEOBJECT_SUCCESS:
                    if(event.data.baseObjectId === this.currentBaseObject){ //Item selected, navigate to main item to avoid not found error.
                        this.navigateToMainNode();
                    }
                    break;
                case GlobalEvent.EVENT_REMOVE_BASEOBJECT:
                    if(this.selectedNode){
                        this.handleNodeRemove(this.selectedNode);
                    }
                    break;
                case GlobalEvent.EVENT_BUSH_NAVIGATE_TO_MAIN:
                    this.navigateToMainNode();
                    break;
            }
        });
    }

    ngOnDestroy(){
        if (this.subOnGlobalEvent){
            this.subOnGlobalEvent.unsubscribe();
        }
    }

    public mainNode():IBushDataNode{
        return this.nodes[0];
    }

    public childNodes(parentNode:IBushDataNode):IBushDataNode[]{
        let childNodeArray = [];

        //Parent
        if(parentNode.children.length > 0){ //Check if there is a next node. If not, enable add
            parentNode.canAddNode = false;
        } else {
            parentNode.canAddNode = true;
        }
        childNodeArray.push(parentNode);

        //Child 1
        if(parentNode.children.length > 0){
            if(parentNode.children[0].children.length > 0){ //Check if there is a next node. If not, enable add
                parentNode.children[0].canAddNode = false;
            } else {
                parentNode.children[0].canAddNode = true;
            }
            childNodeArray.push(parentNode.children[0]);

            //Child 2
            if(parentNode.children[0].children.length > 0){
                parentNode.children[0].children[0].canAddNode = false;
                childNodeArray.push(parentNode.children[0].children[0]);
            }
        }
        return childNodeArray;
    }

    public getImage(node:IBushDataNode|IBushDataNodeWorkorder):string {
        let result = "";
        if (node && node.icon){
            result = MapIconGenerator.ICON_BASE_PATH + MapIconGenerator.ICON_PATH_SIZE_16 + node.icon;
            result += ".png";
        }
        return result;
    }

    public isSelectedItem(node:IBushDataNode):boolean {
        if(node.baseObjectId == this.currentBaseObject){
            this.selectedNode = node;
            return true;
        }
        return false;
    }

    public getHref(node:IBushDataNode):string {
        let result = "";
        if (node && node.baseObjectId){
            result = this.baseHref  + node.baseObjectId;
        }
        return result;
    }

    public handleClickNode(node:IBushDataNode):void {
        this.router.navigate([this.getHref(node)], {queryParams: {state:TreeMapFormComponent.STATE_FORM, origin:'null'}, queryParamsHandling: "merge", skipLocationChange: true });
        if(node.baseObjectId === this.mainNode().baseObjectId){
            this.model.onGlobalEvent.next(new GlobalEvent(GlobalEvent.EVENT_REFRESH_FORM, {referenceId: node.baseObjectId}));
        }
    }

    public navigateToMainNode(){
        this.router.navigate([this.baseHref  + this.mainNode().baseObjectId], {skipLocationChange: true});
    }

    public navigateToWorkorder(wordOrderUrl:string){
        this.router.navigate([wordOrderUrl]);
    }

    private doesParentHaveOlc(array:IBushDataNode[]){
        return array.find(_a => {
            if(_a.children && _a.children.length > 0) {
                return _a.objectType === 'OLC' ? true : this.doesParentHaveOlc(_a.children);
            } else {
                return _a.objectType === 'OLC';
            }
        })
    }

    private isChildSelected(array:IBushDataNode[]){
        return array.find(_a => {
            if(_a.children && _a.children.length > 0) {
                return this.isSelectedItem(_a) ? true : this.isChildSelected(_a.children);
            } else {
                return this.isSelectedItem(_a);
            }
        })
    }

    public handleNodeCreate(node:IBushDataNode):void {
        if (node && node.newUrl){
            this.globalAlertService.addPopupCreateBaseObject("", "", node.newUrl,  node.newUrl.replace("new", "create"), (buttonCode:any, response:any) => {
                if(response && response.url){
                    this.router.navigateByUrl(response.url);
                }
            }, () => {
                this.logger.log("[FormBushItemTreeNodeComponent] " + "Gebruiker heeft op ANNULEREN of kruisje gedrukt");
            });
        }
    }

    public handleNodeRemove(node:IBushDataNode) {
        this.logger.log("[FormBushItemTreeNodeComponent] " + "remove", node);
        if(node && node.deleteUrl){
            this.mapItemService.getBaseObjectDeleteEffects(node.deleteUrl, node.baseObjectId,
                (json:any) => {
                    //Effects successfully received, trigger popup
                    this.globalAlertService.addPopupDeleteBaseObject( Utils.capitalizeFirstLetter(this.ts.translate("baseobject.delete", [node.objectType.toLowerCase(), node.label])), json,
                        [
                            {label:this.ts.translate("Annuleren"), code:ButtonCode.ANNULEREN, isPrimary:true},
                            {label:this.ts.translate('Archiveren'), code:ButtonCode.ARCHIVE, callback:() => {
                                    this.mapItemService.archiveBaseObject(node.deleteUrl, node.baseObjectId,
                                        (json:any) => {
                                            this.logger.log("[FormBushItemTreeNodeComponent] " + "Object gearchiveerd: ", node);
                                            this.globalAlertService.addAlertSuccess(this.ts.translate('treenode.archivetitle'), this.ts.translate('treenode.archivetext' ), '');
                                            if(node.baseObjectId === this.currentBaseObject || this.isChildSelected(node.children)){ //Item selected, navigate to main item to avoid not found error.
                                                this.navigateToMainNode();
                                            }
                                            this.model.onGlobalEvent.next(new GlobalEvent(GlobalEvent.EVENT_REMOVE_BASEOBJECT_SUCCESS, {baseObjectId:node.baseObjectId}));

                                        },
                                        (failure:RequestFailure) => {},
                                        (error:HTTPError) => {},
                                    )
                                }, isPrimary:false},
                            {label:this.ts.translate('Verwijderen'), code:'DELETE', callback:() => {
                                    this.mapItemService.deleteBaseObject(node.deleteUrl, node.baseObjectId,
                                        (json:any) => {
                                            this.logger.log("[FormBushItemTreeNodeComponent] " + "Object verwijderd: ", node);
                                            this.globalAlertService.addAlertSuccess(this.ts.translate('treenode.deletetitle'), this.ts.translate('treenode.deletetext' ), '');
                                            if(node.baseObjectId === this.currentBaseObject || this.isChildSelected(node.children)){ //Item selected, navigate to main item to avoid not found error.
                                                this.navigateToMainNode();
                                            }
                                            this.model.onGlobalEvent.next(new GlobalEvent(GlobalEvent.EVENT_REMOVE_BASEOBJECT_SUCCESS, {baseObjectId:node.baseObjectId}));
                                        },
                                        (failure:RequestFailure) => {},
                                        (error:HTTPError) => {},
                                    )
                                }, isPrimary:false}], () => {
                            //Close popup, do nothing
                        });
                },
                (failure:RequestFailure) => {
                    //User can't help this failure, do nothing
                },
                (error:HTTPError) => {
                    //HTTP error is auto reported, do nothing
                },
            )
        }
    }
}
