<div class="status-progress">
    <div #scrollContainer class="sp-container" *ngIf="statusItems && statusItems.length > 0"
         (mouseout)="hideInfoWindow()">
        <ng-container *ngFor="let status of statusItems; let index = index">
            <div class="{{(status.isActive || status.isFinished)? (disabled ? 'sp-line-done-disabled':'sp-line-done') : 'sp-line'}}"
                 *ngIf="index > 0">
            </div>
            <div #progressItem
                 id="{{status.id}}"
                 class="sp-item"
                 [style.margin-left]="index === 0? 0:''"
                 (mouseover)="setShowInfoWindowForStatus($event, status)">
                <div class="sp-subIcon-wrapper" *ngIf="status.isActive && subStatuses && subStatuses?.length > 0">
                    <div class="sp-subIcon-row">
                        <div class="sp-subIcon" [ngStyle]="getBorderColor(subStatuses[0])">
                            <i *ngIf="subStatuses.length < 2" [ngStyle]="getColor(subStatuses[0])">{{subStatuses[0].icon}}</i>
                            <span *ngIf="subStatuses.length > 1">{{subStatuses.length}}</span>
                        </div>
                    </div>
                </div>
                <div [ngClass]="getProgressItemClass(status)"
                     (touchstart)="onTouchStart($event, status)"
                     (touchend)="onTouchEnd($event, status)"
                     (click)="handleStatusClick($event, status)">
                    <div class="sp-icon-mark"></div>
                </div>
                <div class="sp-label">
                    <span>{{status.label}}</span>
                </div>
                <div *ngIf="getShowInfoWindowForStatus(status)"
                     class="sp-info-wrapper"
                     [ngStyle]="getInfoWindowPosition(status)">
                    <div class="sp-info-window" [ngStyle]="{width: INFO_WINDOW_WIDTH + 'px'}">
                        <div class="sp-info-row"
                             *ngIf="(!status.additionalStatusInfo || status.additionalStatusInfo.length < 1) || (subStatuses && subStatuses.length < 1)">
                            <strong>{{'statusProgress.statusItem.noInfo' | translate}}</strong>
                        </div>
                        <ng-container *ngFor="let infoItem of status.additionalStatusInfo">
                            <div class="sp-info-row">
                                <key-value class="w-100" [value]="infoItem.value" [key]="infoItem.label"></key-value>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</div>
