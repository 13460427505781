import {Injectable} from '@angular/core';
import {HttpService2} from '../../../shared/services/http-service-2.0/http2.0.service';
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {ILoginNotifyInterface} from "./login-notify.interface";

@Injectable()
export class LoginNotifyService {
    constructor(private httpService2: HttpService2) {}

    public getNotificationMessages(language:string): Observable<ILoginNotifyInterface>{
        return this.httpService2.doGetRequest('public/login-notifications/list?lang='+language, false, false, true).pipe(
            map(result => <ILoginNotifyInterface>result)
        );
    }
}
