<div class="check-activity" *ngIf="activity" (click)="hideTooltipsAndMenus()">
    <div class="ca-header" #header>
        <div class="ca-header-icon">
            <activity-status-icon #checkMark
                                  (keydown)="onCheckKeyDown($event)"
                                  [readonly]="readonly"
                                  [hasAttention]="activity.attention"
                                  (onClick)="onToggleStatusDone($event)"
                                  [isDone]="activity.done">
            </activity-status-icon>
        </div>
        <div class="ca-header-block">
            <div class="ca-header-loader">
                <div class="form-attachment-loader {{(isLoading || activity.isLoading)?'':'d-none'}}">
                    <div class="ca-progress">
                        <div class="indeterminate"></div>
                    </div>
                </div>
            </div>
            <div class="ca-header-data">
                <div class="ca-header-name" [title]="activity.name">
                    <span>{{activity.name}}</span>
                </div>
                <div class="ca-header-buttons">
                    <lumi-button *ngIf="!showMenuButton()"
                                 [icon]="'info'"
                                 [size]="'medium'"
                                 [rank]="'input'"
                                 [title]="showAdditionalInfo ?
                                    ('check-activity.info.title.hide' | translate) :
                                    ('check-activity.info.title.show' | translate)"
                                 (onClick)="toggleAdditionalInfo()"
                                 [ngClass]="showAdditionalInfo ? 'ca-icon-button-active':'ca-icon-button'">
                    </lumi-button>
                    <lumi-button *ngIf="showDeleteButton() && !showMenuButton()"
                                 (onClick)="delete()"
                                 [size]="'medium'"
                                 [icon]="'delete'"
                                 [rank]="'input'"
                                 [title]="'check-activity.delete.title' | translate">
                    </lumi-button>
                    <lumi-button *ngIf="showDeleteButton() && !showMenuButton()"
                                 (onClick)="edit()"
                                 [size]="'medium'"
                                 [icon]="'edit'"
                                 [rank]="'input'"
                                 [title]="'check-activity.edit.title' | translate">
                    </lumi-button>
                    <menu-dropdown *ngIf="showMenuButton()"
                                   [buttonSizeSmall]="true"
                                   [menuTitle]="'check-activity.menu' | translate">
                        <a menuItem (click)="toggleAdditionalInfo()"
                           [title]="showAdditionalInfo ?
                                    ('check-activity.info.title.hide' | translate) :
                                    ('check-activity.info.title.show' | translate)"
                           class="md-item">
                            <i>info</i>
                            {{showAdditionalInfo ?
                                ('check-activity.info.title.hide' | translate) :
                                ('check-activity.info.title.show' | translate)
                            }}
                        </a>
                        <a menuItem (click)="edit()"
                           [title]="'check-activity.edit.title' | translate"
                           class="md-item">
                            <i>edit</i>
                            {{'check-activity.edit.title' | translate}}
                        </a>
                        <a menuItem (click)="delete()"
                           [title]="'check-activity.delete.title' | translate"
                           class="md-item">
                            <i>delete</i>
                            {{'check-activity.delete.title' | translate}}
                        </a>
                    </menu-dropdown>
                    <lumi-button [icon]="'flag'"
                                 [size]="'medium'"
                                 [rank]="'input'"
                                 [title]="'check-activity.flag.title' | translate"
                                 [disabled]="readonly"
                                 (onClick)="onToggleAttention()"
                                 [ngClass]="getFlagButtonClass()">
                    </lumi-button>
                </div>
            </div>
        </div>
    </div>
    <div class="ca-content">
        <key-value [key]="'check-activity.objectCode' | translate"
                   [value]="activity.objectCode?activity.objectCode:activity.name">
        </key-value>
        <key-value *ngIf="activity.combinationName"
                   [key]="'check-activity.combinationName' | translate"
                   [value]="activity.combinationName">
        </key-value>
        <key-value *ngIf="activity.description"
                   [key]="'check-activity.description' | translate"
                   [value]="activity.description">
        </key-value>
        <div *ngIf="showAdditionalInfo" class="ca-additional-info">
            <key-value [key]="'check-activity.created' | translate"
                       [value]="activity.additionalInfo.created">
            </key-value>
            <key-value [key]="'check-activity.creator' | translate"
                       [value]="activity.additionalInfo.creator">
            </key-value>
            <key-value [key]="'check-activity.updated' | translate"
                       [value]="activity.additionalInfo.updated">
            </key-value>
        </div>
        <form [formGroup]="form">
            <div *ngIf="activity.inputs && activity.inputs.length > 0">
                <ng-container *ngFor="let input of activity.inputs">
                    <div class="ca-input-row {{(input.activityInputType === ACTIVITY_INPUT_TYPE.UPLOAD || input.activityInputType === ACTIVITY_INPUT_TYPE.CHECKBOX)?'upload':''}}">
                        <div class="ca-label-column {{(input.activityInputType === ACTIVITY_INPUT_TYPE.UPLOAD || input.activityInputType === ACTIVITY_INPUT_TYPE.CHECKBOX)?'upload':''}}">
                            <span [title]="input.label">
                                {{input.label}}{{input.required ? '*' : ''}}
                            </span>
                        </div>
                        <div class="ca-value-column {{(input.activityInputType === ACTIVITY_INPUT_TYPE.UPLOAD || input.activityInputType === ACTIVITY_INPUT_TYPE.CHECKBOX)?'upload':''}}">
                            <ng-container *ngIf="input.activityInputType === ACTIVITY_INPUT_TYPE.STRING">
                                <label class="ca-input-field" *ngIf="!input.textArea">
                                    <input #inputField
                                           name="{{input.fieldName}}"
                                           [formControlName]="input.fieldName"
                                           type="text"
                                           (change)="setValue(input)">
                                    <lumi-button *ngIf="!readonly && input.showScanner"
                                                 [disabled]="!isControlEnabled(input)"
                                                 (onClick)="handleClickScanner($event, input)"
                                                 [size]="'small'"
                                                 [icon]="'videocam'"
                                                 [rank]="'tertiary'"
                                                 [title]="'scanner.tooltip' | translate">
                                    </lumi-button>
                                </label>
                                <div class="ca-text-area" *ngIf="input.textArea">
                                <textarea name="{{input.fieldName}}"
                                          [disabled]="!isControlEnabled(input)"
                                          [formControlName]="input.fieldName"
                                          (change)="setValue(input)"
                                          [maxLength]="NOTE_MAX_LENGTH">
                                </textarea>
                                </div>
                            </ng-container>
                            <div *ngIf="input.activityInputType === ACTIVITY_INPUT_TYPE.SELECT && input.options?.length > 0">
                                <lumi-select [options]="input.options"
                                             [selectedOptions]="getSelectedOptions(input)"
                                             [readonly]="readonly"
                                             [disabled]="!isControlEnabled(input)"
                                             [showOptionFilter]="input.options.length > 10"
                                             (onOptionsSelect)="setInputValue($event, input)">
                                </lumi-select>
                            </div>
                            <ng-container *ngIf="input.activityInputType === ACTIVITY_INPUT_TYPE.NUMBER">
                                <label class="ca-input-field">
                                    <input #inputField
                                           name="{{input.fieldName}}"
                                           [formControlName]="input.fieldName"
                                           type="number"
                                           (change)="setValue(input)">
                                </label>
                            </ng-container>
                            <ng-container *ngIf="input.activityInputType === ACTIVITY_INPUT_TYPE.CHECKBOX">
                                <lumi-checkbox #inputField
                                               [formControlName]="input.fieldName"
                                               [isDisabled]="!isControlEnabled(input)"
                                               [small]="true"
                                               [right]="true"
                                               (change)="checkboxChanged(input, $event)"
                                               [value]="input.value[0]">
                                </lumi-checkbox>
                            </ng-container>
                            <ng-container *ngIf="input.activityInputType === ACTIVITY_INPUT_TYPE.UPLOAD">
                                <i *ngIf="input.value !== ''" class="material-icons p-0 pr-1">done</i>
                                <file-selector #fileSelector
                                               [buttonOnly]="true"
                                               [noText]="true"
                                               [isDisabled]="isUploadedOrDisabled(input)"
                                               (onChanges)="onFilesSelected($event, input)">
                                </file-selector>
                                <lumi-checkbox #inputField
                                               [formControlName]="input.fieldName"
                                               [isDisabled]="!isControlEnabled(input)"
                                               [small]="true"
                                               [right]="true"
                                               [value]="input.value[0]"
                                               class="d-none">
                                </lumi-checkbox>
                                <i *ngIf="isControlEnabled(input)" class="material-icons md-18 p-0 cursor-pointer" (click)="clearFileSelect(input)">clear</i>
                            </ng-container>
                        </div>
                    </div>
                </ng-container>
            </div>
            <div class="ca-input-row" *ngIf="activity.attention">
                <div class="ca-label-column">
                    <span [title]="'check-activity.note' | translate">
                        {{'check-activity.note' | translate}}
                    </span>
                </div>
                <div class="ca-value-column">
                    <div class="ca-text-area">
                    <textarea [formControlName]="'note'"
                              (change)="setNote()"
                              [maxLength]="NOTE_MAX_LENGTH">
                    </textarea>
                        <lumi-button *ngIf="!readonly"
                                     (onClick)="save()"
                                     [size]="'small'"
                                     [icon]="'save'"
                                     [rank]="'tertiary'"
                                     [title]="'check-activity.save.title' | translate">
                        </lumi-button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
