/**
 * Created by Christiaan on 01/03/2017.
 */
import {
    AfterViewInit,
    ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, Renderer2, ViewChild
} from '@angular/core';

import {GlobalAlertService} from '../../global-alert.service';
import {GlobalPopup} from '../../global-popup';
import {TooltipService} from '../../../../shared/services/tooltip/tooltip.service';
import {TranslateService} from '../../../../shared/services/translate/translate.service';
import {Subscription} from 'rxjs';
import Utils from '../../../../shared/utils/utils';
import {GlobalModel} from '../../../../shared/services/state/global.model';
import {AbstractBasePopupComponent} from '../abstract-base-popup.component';
import {LoggerService} from "../../../../shared/services/logger/logger.service";

@Component ({
    selector: 'global-popup-map-legend-component',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: 'global-popup-map-legend.component.html'
})

export class GlobalPopupMapLegendComponent extends AbstractBasePopupComponent implements AfterViewInit {
    public allowSingleSelect: boolean = false;
    public allowMultiSelect: boolean = false;
    public allowMarkerDrag: boolean = false;
    public allowEasyMarkerDrag: boolean = false;
    public allowCreateMarker: boolean = false;
    public useCustomIconColors: boolean = false;
    public iconSet: 'default'|'mechanic'|'smartdevices' = 'default';

    private subMobileMode: Subscription = null;
    public mobileMode: boolean = null;

    constructor(
        protected tooltipService: TooltipService,
        protected elementRef: ElementRef,
        private renderer: Renderer2,
        private model: GlobalModel,
        protected cd: ChangeDetectorRef,
        protected ts: TranslateService,
        protected globalAlertService: GlobalAlertService,
        protected logger:LoggerService
    ) {
        super(cd, globalAlertService, elementRef, ts, tooltipService, logger);
    }

    ngAfterViewInit() {
        let popupData: any = this.globalPopup.data;

        this.allowSingleSelect = popupData.allowSingleSelect;
        this.allowMultiSelect = popupData.allowMultiSelect;
        this.allowMarkerDrag = popupData.allowMarkerDrag;
        this.allowEasyMarkerDrag = popupData.allowEasyMarkerDrag;
        this.allowCreateMarker = popupData.allowCreateMarker;
        this.useCustomIconColors = popupData.useCustomIconColors;
        this.iconSet = popupData.iconSet;

        this.subMobileMode = this.model.mobileMode.subscribe((value: boolean) => {

            // Don't forget to unsubscribe
            this.mobileMode = value;
            this.cd.detectChanges(); // Mark for change was not enough here
        });
    }
    
    public getShortcutKeyForOS(): string {
        if (Utils.isMacintosh()) {
            return '[CMD]';
        } else {
            return '[CTRL]';
        }
    }

    handlePopupAction(event: MouseEvent, alert: GlobalPopup, button: any): void {
        // Hide alert and perform callback of button
        this.onPopupAction.emit({event: event, alert: alert, button: button});
    }

    public closePopup(event: any): void {
        this.doDefaultCloseHandling(event, false);
    }

    ngOnDestroy() {
        if (this.subMobileMode) {
            this.subMobileMode.unsubscribe();
        }
    }
}
