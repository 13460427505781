import {
    AfterViewInit,
    ChangeDetectorRef,
    Component, EventEmitter,
    HostBinding,
    Input,
    OnDestroy, Output,
    Renderer2,
    ViewChild
} from "@angular/core";
import {ValidationConstraintService} from "../../services/validation-constraint.service";
import {GoogleChartComponent} from "../../../commonUI/googleChart/google-chart.component";
import {GlobalAlertService} from "../../../../../wrapper/global-alert/global-alert.service";
import {TooltipService} from "../../../../services/tooltip/tooltip.service";
import {FormDataService} from "../../services/form-data.service";
import { Subscription } from 'rxjs';
import {GlobalModel} from "../../../../services/state/global.model";
import {TranslateService} from "../../../../services/translate/translate.service";
import Utils from "../../../../utils/utils";
import {AbstractFormFieldComponent} from "../abstract/abstract-form-field.component";
import {GlobalEvent} from "../../../../interfaces/global-event";
import {AppSettings} from '../../../../../app.settings';
import {LoggerService} from "../../../../services/logger/logger.service";

@Component({
    selector: 'tiny-graph',
    template: `
        <ng-container>
            <div #graphContainer (resize)="onResize($event)" [ngStyle]="{'min-height': graphHeight + 'px'}">
                <google-chart *ngIf="chartData && chartData.cols && chartData.cols.length > 1" [hidden]="!chartData || chartData.rows.length <= 0" (globalResizeOutsideAngular)="onResize($event)" #googleChart="GoogleChartDirective" [chartId]="chartId" [chartOptions]="chartOptions" [chartType]="chartType"></google-chart>
                <div class="form-graph-no-data" [hidden]="(chartData && chartData.rows.length > 0)">
                    <div class="chart-widget-no-data" [style.height]="graphHeight + 'px'">{{isLoading || neverLoaded?'':'Geen informatie beschikbaar' | translate}}</div>
                </div>
            </div>
            <div *ngIf="dataTables && !hideChannelSelector && (alwaysShowChannelSelector || dataTables.length > 1)" class="d-flex align-items-center pt-1" >
                <div class="d-flex ml-3" [title]="'dimmingscheme.channelstitle' | translate" >
                    <ng-container *ngFor="let dataTable of dataTables; let channelIndex = index">
                        <div (click)="handleClickChannel($event, channelIndex)" class="ml-1 px-2 py-0 dimming-channel-block {{channelIndex == currentChannel?'selected-block':''}}">{{getChannelName(channelIndex)}}</div>
                    </ng-container>
                        <div *ngIf="showChannelEditButtons && dataTables.length < MAX_CHANNELS" [title]="'dimmingscheme.addchannelstitle' | translate" (click)="handleClickAddChannel($event)" class="ml-1 px-2 py-0 dimming-channel-block">+</div>
                        <div *ngIf="showChannelEditButtons && dataTables.length > 1" [title]="'dimmingscheme.removechannelstitle' | translate" (click)="handleClickRemoveChannel($event)" class="ml-1 px-2 py-0 dimming-channel-block">-</div>
                </div>
            </div>
        </ng-container>
    `
})

export class TinyGraphComponent extends AbstractFormFieldComponent implements AfterViewInit, OnDestroy
{
    @HostBinding('class') hostClasses = 'd-flex w-100 flex-column';

    @Input('dataUrl') dataUrl = "";
    @Input('graphHeight') graphHeight = 300;
    @Input('hideChannelSelector') hideChannelSelector:boolean = false;
    @Input('alwaysShowChannelSelector') alwaysShowChannelSelector:boolean = false;
    @Input('showChannelEditButtons') showChannelEditButtons:boolean = false;

    @Output() onChangeChannel: EventEmitter<any> = new EventEmitter<any>();
    @Output() onAddChannel: EventEmitter<any> = new EventEmitter<any>();
    @Output() onRemoveChannel: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild('googleChart', {static: false}) private chartElement: any;
    @ViewChild('graphContainer', {static: false}) private graphContainer: any;

    // TODO: moet deze uniek zijn voor elke chart?
    public chartId = "tinyChart";
    private dataCallCancelled:boolean = false;

    private readonly DEFAULT_LABEL_COLOR: string = AppSettings.getColor('$color_grey-default-label');
    public static readonly  MAX_CHANNELS:number = 10;
    
    public neverLoaded:boolean = true;
    public isLoading:boolean = false;
    public dataTables:any;
    public currentChannel:number = 0;
    
    public chartData: any = null;
    public chartType: string;
    public chartOptions: any = {
        height: 1,
        backgroundColor: {fill: 'transparent'},
        fontName: 'Roboto',
        hAxis: {
            textStyle: {
                color: this.DEFAULT_LABEL_COLOR
            },
            titleTextStyle: {
                color: this.DEFAULT_LABEL_COLOR
            }
        },
        vAxis: {
            textStyle: {
                color: this.DEFAULT_LABEL_COLOR
            },
            titleTextStyle: {
                color: this.DEFAULT_LABEL_COLOR
            }
        },
        legend: {
            textStyle: {
                color: this.DEFAULT_LABEL_COLOR
            },
            position: 'bottom',
            alignment: 'start'
        },
        animation: {
            duration: 0,
            easing: 'linear',
            startup: false
        }
    };

    private subMobileMode:Subscription;
    private subOnFormResize:Subscription;
    private subOnGlobalEvent:Subscription;
    private mobileMode:boolean = false;

    constructor(public renderer:Renderer2, public validationConstraintService:ValidationConstraintService, public ts:TranslateService, public tooltipService:TooltipService, private globalAlertService: GlobalAlertService, private formDataService:FormDataService, private cd:ChangeDetectorRef, private model:GlobalModel, protected logger:LoggerService)
    {
        super(renderer, validationConstraintService, tooltipService, logger);

        this.chartId = "tinyGraph" + Utils.getRandomNumber(0, 100000000);

        // When a form is resized, redraw the chart
        this.subOnFormResize = this.model.onFormResize.subscribe( (unused:string) => {
            this.redrawChart();
        });

        this.subOnGlobalEvent = this.model.onGlobalEvent.subscribe((event:GlobalEvent) => {
            if (event.type == GlobalEvent.EVENT_EXPAND_FORM_GROUP){
                if (event.data.expanded == true){
                    //The graph might have been resized while being collapsed. Redraw the graph to fit the form.
                    setTimeout( () => {
                        this.redrawChart();
                    });
                }
            }
        });
    }

    ngAfterViewInit()
    {
        this.subMobileMode = this.model.mobileMode.subscribe((value:boolean) => {

            //Don't forget to unsubscribe
            this.mobileMode = value;
            this.cd.detectChanges(); //Mark for change was not enough here
        });

        this.setChartOptions();

        this.updateGraphData(this.dataUrl);
    }

    public updateGraphData(dataUrl:string, successCallBack?:() => any):void
    {
        if (dataUrl && dataUrl != ""){
            this.getGraphData(dataUrl, successCallBack);
        }//else{
         //   this.globalAlertService.addAlert(this.ts.translate("Formulier incompleet"), this.ts.translate("Geen URL meegegeven"), this.ts.translate("De graph verwacht een URL, maar die is niet meegegeven"));
        //}
    }

    public getChannelName(index:number):string
    {
        //65 == A
        return String.fromCharCode(65 + index);
    }

    public clearGraphData():void
    {
        this.chartData = null;
        this.dataCallCancelled = true;
        this.cd.markForCheck();
    }
    
    public handleClickAddChannel(event:MouseEvent):void{
        this.dataTables.push([{}]);
        this.onAddChannel.emit({newChannel: this.dataTables.length - 1});
    }
    
    public handleClickRemoveChannel(event:MouseEvent):void{
        this.dataTables.splice(this.currentChannel, 1);
        this.onRemoveChannel.emit({removedChannel: this.currentChannel});
    }
    
    public handleClickChannel(event:MouseEvent, channelIndex:number):void
    {
        this.logger.log("[TinyGraphComponent] " + "next channel, currentChannel: " + this.currentChannel);
        event.stopImmediatePropagation();

        this.currentChannel = channelIndex;
        this.applyDataTable(this.dataTables[channelIndex]);
        this.onChangeChannel.emit({currentChannel: channelIndex});
    }

    public getSelectedChannel():number{
        return this.currentChannel;
    }

    public setSelectedChannel(channel:number):void{
        this.logger.log("[TinyGraphComponent] " + "set selected channel: " + channel);
        this.currentChannel = channel;
        this.cd.detectChanges();
    }

    private getGraphData(url:string, successCallBack?:() => any)
    {
        if (url == ""){
            this.logger.log("[TinyGraphComponent] " + "WARNING: No valid url for linegraph");
            return;
        }

        this.neverLoaded = false;
        this.isLoading = true;
        this.dataCallCancelled = false;

        this.formDataService.getTinyGraphData(url,
            (json: any) => {
                this.isLoading = false;
                //this.currentChannel = 0;

                if (json.datatables && !this.dataCallCancelled){
                    this.dataTables = json.datatables;

                    if (this.currentChannel > this.dataTables.length - 1)
                    {
                        this.currentChannel = 0;
                        this.logger.log("[TinyGraphComponent] " + "reset currentchannel to 0");
                    }
                    
                    this.applyDataTable(json.datatables[this.currentChannel]);
                }

                if (successCallBack){
                    successCallBack();
                }
            }
        );
    }

    public applyDataTable(dataTable:any):void
    {
        if (!this.dataTables){
            return;
        }

        this.dataTables[this.currentChannel] = dataTable;

        this.chartData = dataTable;

        this.cd.markForCheck();

        //Wait until view is up to date
        //TODO: dit kan vast mooier. Eerst moet chartdata in de view verwerkt zijn, en daarna wil percentage-height/width niet werken op google chart, dus is een nieuwe redraw nodig als de eerste chart staat
        this.logger.log("[TinyGraphComponent] " + "redraw after graphdata");

        this.redrawChart();
        setTimeout(() => {
            this.redrawChart()
        }, 0);
    }

    //TODO: aanpassen als er meerdere charttypes zijn
    private setChartOptions():void
    {
        //Set graph type
        this.chartType = GoogleChartComponent.TYPE_AREACHART;

        //Apply google chart defaults
        GoogleChartComponent.setDefaultChartOptions(this.chartType, this.chartOptions, this.globalAlertService);

        //Set component specific chartOptions
        this.chartOptions.height = this.graphHeight;

    }

    private redrawChart()
    {
        //Only draw if the element is present
        if (this.chartElement) {
            this.chartElement.drawGraph(this.chartData, this.graphContainer.nativeElement.offsetWidth);
        }
    }
    
    public onResize(event: Event)
    {
        this.redrawChart();
    }

    ngOnDestroy() {

        super.ngOnDestroy();

        // nullify the chart element explicitly
        this.chartElement = null;

        if (this.subMobileMode) {
            this.subMobileMode.unsubscribe();
        }
        if (this.subOnFormResize) {
            this.subOnFormResize.unsubscribe();
        }
    }

    get MAX_CHANNELS():number{
        return TinyGraphComponent.MAX_CHANNELS;
    }
}
