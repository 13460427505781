import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Cell, DataType} from '../baseTable.interface';
import MapIconGenerator from '../../../../../utils/map-icon-generator';

@Component({
    selector: 'table-cell',
    templateUrl: './table-cell.component.html'
})
export class TableCellComponent implements OnInit {
    @Output() onTruncateClick: EventEmitter<Cell> = new EventEmitter<Cell>();
    @Input() cell: Cell;
    @Input() maxCellCharacters: number;
    @Input() maxCellLines: number;
    @Input() selected: boolean;

    public readonly DATA_TYPE = DataType;

    constructor() {
    }

    ngOnInit() {
    }

    public toggleTruncation($event: MouseEvent, cell: Cell) {
        // overlapping click events
        $event.stopPropagation();
        this.onTruncateClick.emit(cell);
    }

    public getImage(icon: string, includeSize: boolean = true): string {
        return MapIconGenerator.ICON_BASE_PATH + (includeSize ? MapIconGenerator.ICON_PATH_SIZE_16 : '') + icon + '.png';
    }
}
