import {ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Input, OnChanges, OnDestroy, Renderer2, SimpleChanges} from '@angular/core';
import 'moment/locale/nl';
import {AbstractFormFieldComponent} from '../abstract/abstract-form-field.component';
import {ValidationConstraintService} from '../../services/validation-constraint.service';
import {TooltipService} from '../../../../services/tooltip/tooltip.service';
import {GlobalAlertService} from '../../../../../wrapper/global-alert/global-alert.service';
import {TranslateService} from '../../../../services/translate/translate.service';
import Utils from '../../../../utils/utils';
import {LoggerService} from "../../../../services/logger/logger.service";
import {GlobalEvent} from '../../../../interfaces/global-event';
import {GlobalModel} from '../../../../services/state/global.model';
import {Subscription} from 'rxjs';
import {formatDate} from '@angular/common';

@Component({
    selector: 'form-input',
    template: `
        <ng-container [formGroup]="group">

            <div class="d-block w-100">
                <div class="d-flex">
                    <form-component-label [batchUpdateMode]="batchUpdateMode" [config]="config" [included]="included"
                                  (onClickInclude)="handleClickInclude($event)"></form-component-label>

                    <div class="md-form input-group p-0 m-0">
                        <ng-container *ngIf="hasInputGroupAddonBefore()">
                            <span class="input-group-addon">{{ config.attr?.inputGroup?.addOn?.text }}</span>
                        </ng-container>
                        <div class="w-100 {{isControlReadOnly()?'d-none':''}}">

                            <ng-container *ngIf="!config.attr || !config.attr.marks">
                                <input
                                        #currentFormField
                                        initialFocus
                                        maxlength="{{config.attr?.maxLength || DEFAULT_MAX_LENGTH}}"
                                        [initialFocusOn]="config.initialFocus"
                                        [disableControl]="isControlDisabled()"
                                        [attr.placeholder]="config.placeholder"
                                        [formControlName]="config.name"
                                        [(ngModel)]="initialValue"
                                        name="{{config.name}}"
                                        type="text"
                                >
                            </ng-container>
                            <ng-container *ngIf="config.attr && config.attr.marks">
                                <ng-container *ngIf="(config.attr.marks.length === 0 ||
                                       config.attr.marks[0].type !== 'translate' ||
                                       (config.attr.marks[0].type === 'translate' && !hasHtml(initialValue)))">
                                    <input
                                            #currentFormField
                                            initialFocus
                                            maxlength="{{config.attr?.maxLength || DEFAULT_MAX_LENGTH}}"
                                            [initialFocusOn]="config.initialFocus"
                                            [disableControl]="isControlDisabled()"
                                            [attr.placeholder]="config.placeholder"
                                            [formControlName]="config.name"
                                            [(ngModel)]="initialValue"
                                            name="{{config.name}}"
                                            type="text"
                                            [attr.id]="'field-'+randomFieldId+'_'+config.label"
                                            [attr.class]="'__'+config.label+'__'"
                                    >
                                </ng-container>
                                <ng-container *ngIf="(config.attr.marks.length > 0 && config.attr.marks[0].type === 'translate' && hasHtml(initialValue))">
                                    <editor
                                            [formControlName]="config.name"
                                            [(ngModel)]="initialValue"
                                            [attr.id]="'field-'+randomFieldId+'_'+config.label"
                                            [attr.class]="'__'+config.label+'__ is-editor'"
                                            apiKey="ny2c3xxsnkhnbph3l420pxo6h81wfvcfxyq449sbnztsnne3"
                                            [init]="{
                                        selector: 'textarea#editor_'+config.label,
                                        content_style: 'body{overflow-y:auto !important;overflow-x:hidden !important;max-height:400px !important;}',
                                        menubar: false,
                                        toolbar: 'styles | bold italic link numlist bullist code',
                                        plugins: 'lists anchor link code'}"
                                    >
                                    </editor>
                                </ng-container>
                            </ng-container>
                            
                            
                        </div>

                        <div class="{{!isControlReadOnly()?'d-none':''}} form-read-only-label">{{initialValue}}</div>
                        <ng-container *ngIf="hasInputGroupAddonAfter()">
                            <span class="input-group-addon">{{ config.attr?.inputGroup?.addOn?.text }}</span>
                        </ng-container>

                    <!--            <ng-container *ngIf="hasSyncStatus()">-->
                    <!--                <span><i class="material-icons unselectable-content" [title]="getStatusText(config.attr.syncStatus)">{{getStatusIcon(config.attr.syncStatus)}}</i></span>-->
                    <!--            </ng-container>-->
    
                        <ng-container *ngIf="hasStatusIcons()">
                            <span *ngFor="let mark of config.attr.marks">
                                <i *ngIf="mark.isClickable" class="material-icons cursor-pointer" (click)="handleMarksClick(mark.type)" [title]="mark.toolTip">{{mark.icon}}</i>
                                <i *ngIf="!mark.isClickable" class="material-icons unselectable-content" [title]="mark.toolTip">{{mark.icon}}</i>
                            </span>
                        </ng-container>

                        <ng-container *ngIf="allowScannerInput()">
                            <div class="pl-2">
                                <div class="card {{!isControlDisabled()?'cursor-pointer':'d-none'}}"
                                     (click)="handleClickScanner($event)" [title]="'scanner.tooltip' | translate">
                                    <i class="p-2 material-icons " style="font-size: 20px">videocam</i>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
                
                <div class="d-flex" *ngIf="config && config.attr && config.attr.fillSuggestion">
                    <div style="width: 55%"></div>
                    <div class="form-read-only-label w-100">
                        <i class="material-icons" style="font-size: small; padding: 0;">
                            subdirectory_arrow_right
                        </i>
                        <i style="font-size: small">Suggestie: </i>
                        <i (click)="acceptSuggestion()" class="form-link-anchor">
                            {{config.attr.fillSuggestion}}
                        </i>
                    </div>
                </div>
            </div>
        </ng-container>
    `,
})
export class FormInputComponent extends AbstractFormFieldComponent implements OnDestroy{
    @HostBinding('class') hostClasses = 'd-flex mt-1';
    public randomFieldId = this.randomId()
    protected subscriptions: Subscription[] = [];

    // private static readonly SYNC_STATUS_DEFAULT:string = 'factory_default';
    // private static readonly SYNC_STATUS_IN_SYNC:string = 'in_sync';
    // private static readonly SYNC_STATUS_SYNC_PENDING:string = 'sync_pending';
    public readonly DEFAULT_MAX_LENGTH: number = 255;

    constructor(public renderer: Renderer2, public validationConstraintService: ValidationConstraintService, public tooltipService: TooltipService, public globalAlertService: GlobalAlertService, public ts: TranslateService, protected logger:LoggerService, public model:GlobalModel) {
        super(renderer, validationConstraintService, tooltipService, logger);
    }


    // protected hasSyncStatus()
    // {
    //     return (this.config && this.config.attr && this.config.attr.syncStatus);
    // }

    hasStatusIcons() {
        return (this.config && this.config.attr && this.config.attr.marks && this.config.attr.marks.length > 0);
    }

    hasHtml(str:string):boolean{
        return /<\/?[a-z][\s\S]*>/i.test(str)
    }

    allowScannerInput() {
        return (this.config && this.config.attr && this.config.attr.allowScanner);
    }

    //Marks can be used to trigger an action with the field value. An event is triggered, so it can be picked up throughout the application
    public handleMarksClick(marksType:string){
        this.model.onGlobalEvent.next(new GlobalEvent(GlobalEvent.EVENT_FORM_MARKS_CLICK, {type:marksType,targetFieldId:'field-'+this.randomFieldId+'_'+this.config.label,targetLanguage:this.config.label}));
    }

    // private getStatusIcon(syncStatus:string):string{
    //
    //
    //     let result:string = "";
    //
    //     switch (syncStatus){
    //         case FormInputComponent.SYNC_STATUS_DEFAULT:
    //         case FormInputComponent.SYNC_STATUS_IN_SYNC:
    //             result = "check";
    //             break;
    //         case FormInputComponent.SYNC_STATUS_SYNC_PENDING:
    //             result = "sync";
    //             break;
    //     }
    //
    //     return result;
    // }

    handleClickScanner(e: MouseEvent): void {
        if (Utils.getInternetExplorerVersion() > 0) {
            this.globalAlertService.addAlertNoIESupport(this.ts.translate('alert.noiesupportsubtitle'), this.ts.translate('alert.noiesupportlabel'));
            return;
        }

        this.globalAlertService.addPopupBarCodeScanner(this.ts.translate('scanner.scan'), (buttonCode: any, response: any) => {
            if (response && response.scanResult && response.scanResult != '') {
                this.group.get(this.config.name).patchValue(response.scanResult);
                //this.config.initialValue = response.scanResult;
            }
        }, () => {
        });
    }

    //TODO: vertalingen
    //TODO: wat voor teksten moeten hier komen?
    //private getStatusText(syncStatus:string):string{
    //
    //     let result:string = "Onbekend";
    //
    //     switch (syncStatus){
    //         case FormInputComponent.SYNC_STATUS_DEFAULT:
    //         case FormInputComponent.SYNC_STATUS_IN_SYNC:
    //             result = "Gesynchroniseerd";
    //             break;
    //         case FormInputComponent.SYNC_STATUS_SYNC_PENDING:
    //             result = "Aan het synchroniseren...";
    //             break;
    //     }
    //
    //     return result;
    // }

    // TODO: ooit de naamgeving aanpassen
    protected hasInputGroupAddon(): boolean {
        return this.config && this.config.attr && this.config.attr.inputGroup && this.config.attr.inputGroup.addOn && this.config.attr.inputGroup.addOn.text && this.config.attr.inputGroup.addOn.position && true;
    }

    hasInputGroupAddonBefore(): boolean {
        return this.hasInputGroupAddon() && this.config.attr.inputGroup.addOn.position == 'before';
    }

    hasInputGroupAddonAfter(): boolean {
        return this.hasInputGroupAddon() && this.config.attr.inputGroup.addOn.position == 'after';
    }
    
    public acceptSuggestion() {
        this.group.get(this.config.name).patchValue(this.config.attr.fillSuggestion);
    }

    public randomId(): number {
        return Math.floor(Math.random() * 999999999)
    }

    ngOnDestroy() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    protected readonly formatDate = formatDate;
}
