/**
 * Created by Christiaan on 01/03/2017.
 */
import {
    AfterViewInit,
    ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, Renderer2, ViewChild
} from '@angular/core';

import {GlobalAlertService} from "../global-alert.service";
import {ButtonCode, GlobalPopup} from '../global-popup';
import {TooltipService} from "../../../shared/services/tooltip/tooltip.service";
import {TranslateService} from "../../../shared/services/translate/translate.service";
import {AbstractBasePopupComponent} from './abstract-base-popup.component';
import {LoggerService} from "../../../shared/services/logger/logger.service";

@Component ({
    selector: 'global-popup-basic-input-component',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <default-popup-wrapper-component [componentRef]="this" [globalPopup]="globalPopup" [title]="globalPopup.title" [description]="globalPopup.label" (onClose)="closePopup($event)">
            <input #inputText class="" title="{{globalPopup.label}}" type="text" formControlName="dropDownText" initialFocus>
        </default-popup-wrapper-component>
    `
})

export class GlobalPopupBasicInputComponent extends AbstractBasePopupComponent implements AfterViewInit
{
    @ViewChild("inputText", {static: false}) inputText:any = "";

    public constructor(private renderer:Renderer2, protected tooltipService:TooltipService, protected cd:ChangeDetectorRef, protected globalAlertService:GlobalAlertService, protected elementRef:ElementRef, protected ts:TranslateService, protected logger:LoggerService) {
        super(cd, globalAlertService, elementRef, ts, tooltipService, logger);
    }

    ngAfterViewInit()
    {
        let popupData: any = this.globalPopup.data;
        if (popupData && popupData.preFill && popupData.preFill != '') {
            this.inputText.nativeElement.value = popupData.preFill;
        }
    }

    handlePopupAction(event: MouseEvent, alert: GlobalPopup, button: any): void {
        this.tooltipService.destroyToolTip(this.inputText);

        if (button.code == ButtonCode.OK) {
            let input:string = this.inputText.nativeElement.value;
            if (input == ""){
                this.tooltipService.createAndShowTooltip(
                    this.renderer,
                    this.inputText,
                    this.ts.translate("popupinput.validation"), //JSON.stringify(this.validationConstraints),
                    TooltipService.PLACEMENT_TOP, TooltipService.TOOLTIP_MODE_MANUAL, "", true
                );
            }else{
                this.onPopupAction.emit({event:event, alert:alert, button:button, data:{input:input}});
            }
        }else{
            //Hide alert and perform callback of button
            this.onPopupAction.emit({event:event, alert:alert, button:button});
        }
    }
    
    public closePopup(event:any):void
    {
        this.doDefaultCloseHandling(event, false);
    }
}
