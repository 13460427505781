export interface MapTableSettings {
    allowSingleSelect?: boolean;
    allowMultiSelect?: boolean;
    allowCreateMarker?: boolean;
    allowMixedView?: boolean;
    allowAutoLoadMarkers?: boolean;
    allowMarkerDrag?: boolean;
    useCustomIconColors?: boolean;
    iconSet?: 'default'|'mechanic'|'smartdevices';
    isBasicMode?: boolean;
    showMapTableLoaders?: boolean;
    hideMap?: boolean;
    showReportInfoText?: boolean;
    showSearchBar?: boolean;
    allowMultiLineInRow?: boolean;
}

export interface TreeSettings {
    hideActionIcon?: boolean;
    unfoldFolderClick?: boolean;
    childNodeIcon?: string;
    nodeActionIcon?: string;
    nodeActionTitleKey?: string;
}

export enum TreeMapFormEvent {
    TREE_NODE_SELECTED = 'tree-node-selected',
    FILTER_RESET = 'filter-reset',
}
