import {AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, ViewChild} from '@angular/core';
import {AbstractBasePopupComponent} from '../abstract-base-popup.component';
import {GlobalAlertService} from '../../global-alert.service';
import {TranslateService} from '../../../../shared/services/translate/translate.service';
import {TooltipService} from '../../../../shared/services/tooltip/tooltip.service';
import {ButtonInterface, GlobalPopup, PopupResultCode} from '../../global-popup';
import {FormComponent} from '../../../../shared/components/form/containers/form/form.component';
import {FormDataService} from '../../../../shared/components/form/services/form-data.service';
import {
    FormEvent,
    LumiFormEvent
} from '../../../../shared/components/form/containers/form/form.interface';
import {take} from 'rxjs/operators';
import {LoggerService} from "../../../../shared/services/logger/logger.service";

@Component({
    selector: 'global-popup-basic-form-component',
    templateUrl: './global-popup-basic-form.component.html'
})
export class GlobalPopupBasicFormComponent extends AbstractBasePopupComponent implements AfterViewInit {
    @ViewChild('form', {static: false}) form: FormComponent;
    
    @Input() globalPopup: GlobalPopup = null;
    
    private formSaveUrl: string;
    
    constructor(
        protected cd: ChangeDetectorRef,
        protected globalAlertService: GlobalAlertService,
        protected elementRef: ElementRef,
        protected ts: TranslateService,
        protected tooltipService: TooltipService,
        private formDataService: FormDataService,
        protected logger:LoggerService
    ) {
        super(cd, globalAlertService, elementRef, ts, tooltipService, logger);
    }
    
    ngAfterViewInit() {
        this.submitButton = this.getSubmitButton();
        this.formData = this.globalPopup.form;
        this.formSaveUrl = this.globalPopup.saveUrl;
        this.isLoading = false;
        this.cd.detectChanges();
    }
    
    public closePopup(event: MouseEvent): void {
        this.doDefaultCloseHandling(event, true);
    }
    
    public handleComponentEvent($event: LumiFormEvent) {
        if ($event.event === FormEvent.SAVE) {
            this.submitFormData($event.data.formData);
        }
    }
    
    public handlePopupAction(event: MouseEvent, alert: GlobalPopup, button: ButtonInterface) {
        if (button === this.submitButton) {
            this.form.handleComponentEvent({event: FormEvent.SAVE, data: null});
        } else {
            this.globalPopup.result.next({code: PopupResultCode.CANCEL});
            this.onPopupAction.emit({event: event, alert: alert, button: button});
        }
    }
    
    private submitFormData(formData: any): void {
        if (this.formSaveUrl && formData) {
            this.handleSubmitForm();
    
            this.formDataService.setFormData2(formData, this.getFormName(), this.formSaveUrl).pipe(
                take(1)
            ).subscribe(() => {
                this.globalPopup.result.next({code: PopupResultCode.OK});
                this.onPopupAction.emit({event: null, alert: this.globalPopup, button: this.submitButton, data: {}});
            });
        } else {
            this.globalPopup.result.next({code: PopupResultCode.OK});
            this.onPopupAction.emit({event: null, alert: this.globalPopup, button: this.submitButton, data: {}});
        }
    }
}
