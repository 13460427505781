import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from "@angular/core";
import {GlobalModel} from "../../services/state/global.model";
import {UserRoles} from "../../interfaces/user.interface";
import {StorageService} from "../../services/storage/storage.service";
import {ILocalizationElement} from "./localization.interface";
import {LocalizationService} from "./localization.service";
import {Subscription} from "rxjs";
import {GlobalAlertService} from "../../../wrapper/global-alert/global-alert.service";
import {User} from "../../../wrapper/login/user";
import {TranslateService} from "../../services/translate/translate.service";
import {GlobalEvent} from '../../interfaces/global-event';
import Utils from '../../utils/utils';

@Component({
    selector: 'localization-component',
    templateUrl: './localization.component.html',
    styleUrls: ['./localization.component.scss']
})

export class LocalizationComponent implements OnInit, OnDestroy {
    protected subscriptions: Subscription[] = [];
    public showPanel:boolean = false;
    public translationModeEnabled:boolean = false;
    public panelLocation: 'left'|'right' = 'left';
    private canEditLocalizationItem:boolean = true;
    public localizationList:ILocalizationElement[] = [];
    @Input() currentActiveFilterQuery:string;

    constructor(private model:GlobalModel,private storage: StorageService, private localizationService: LocalizationService, private cd:ChangeDetectorRef, private globalAlertService:GlobalAlertService, public ts:TranslateService) {
        this.storage.getBooleanValue(StorageService.KEY_TRANSLATION_MODE, (value: boolean) => {
            this.translationModeEnabled = value;
        });
    }

    ngOnInit() {
        this.subscriptions.push(this.model.localizationElements.subscribe((value: ILocalizationElement[]) => {
            if (value) {
                this.localizationList = value;
                this.cd.detectChanges()
            }
        }));
        this.subscriptions.push(this.model.user.subscribe((value: User) => {
            if (value && this.hasUserEngineerRole()) {
                this.showPanel = this.translationModeEnabled
            }
        }));
    }

    public openLocalizationItem(localizationKey:string):void{
        if(this.canEditLocalizationItem){
            this.canEditLocalizationItem = false;
            if(this.hasUserEngineerRole()){ //Double check. Important that a non-service engineer cannot edit this.
                this.globalAlertService.addPopupLocalization(localizationKey, () => {
                    this.canEditLocalizationItem = true
                }, () => {
                    this.canEditLocalizationItem = true
                })
            } else {
                this.globalAlertService.addAlert('You are not a service engineer', '', 'Only service engineers can edit this.')
            }
        }
    }

    //Search
    public doSearch(){
        const searchQuery = this.currentActiveFilterQuery;
        this.localizationList.map(_localization => {
            if(_localization.value.toLowerCase().includes(searchQuery.toLowerCase()) || _localization.key.toLowerCase().includes(searchQuery.toLowerCase())){
                _localization.visible = true;
            } else {
                _localization.visible = false;
            }
        })
    }

    //Clear search query
    public clearSearchQuery():void {
        this.currentActiveFilterQuery = ''
        this.doSearch()
    }

    public hasSearchQuery():boolean {
        if(this.currentActiveFilterQuery == '' || this.currentActiveFilterQuery == null || typeof this.currentActiveFilterQuery == 'undefined') {
            return false
        }
        return true;
    }

    public changeLocation():void{
        this.panelLocation = this.panelLocation === 'left' ? 'right' : 'left'
    }

    public clearLocalizationList(){
        this.model.localizationElements.next([])
    }

    private hasUserEngineerRole(): boolean {
        return this.model.getUserRoleCodes().some(role => role === UserRoles.ENGINEER);
    }

    ngOnDestroy() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }
}
