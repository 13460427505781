import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import Utils from '../../../../utils/utils';

@Component({
    selector: 'lumi-switch',
    templateUrl: './lumi-switch.component.html'
})
export class LumiSwitchComponent implements OnInit, OnChanges {
    @Input() initialValue: boolean;
    @Input() disabled: boolean;
    @Input() id: string;
    @Output() onSwitch: EventEmitter<boolean> = new EventEmitter<boolean>();
    
    public currentValue;
    
    constructor() {
    }
    
    ngOnInit(): void {
    }
    
    ngOnChanges() {
        this.currentValue = this.initialValue;
    }
    
    public onChange() {
        this.currentValue = !this.currentValue;
        this.onSwitch.emit(this.currentValue);
    }
    
    public onClick($event: MouseEvent) {
        Utils.preventDefault($event)
    }
}
