/**
 * Created by Christiaan on 01/03/2017.
 */
import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, ViewChild} from '@angular/core';
import {GlobalAlertService} from '../global-alert.service';
import {GlobalPopup} from '../global-popup';
import {RequestFailure} from '../../../shared/services/http/request-failure';
import {FormDataService} from '../../../shared/components/form/services/form-data.service';
import {GlobalModel} from '../../../shared/services/state/global.model';
import {FormComponent} from '../../../shared/components/form/containers/form/form.component';
import {TranslateService} from '../../../shared/services/translate/translate.service';
import {AbstractBasePopupComponent} from './abstract-base-popup.component';
import {TooltipService} from '../../../shared/services/tooltip/tooltip.service';
import {FormSelectComponent} from '../../../shared/components/form/components/form-select/form-select.component';
import { FormEvent } from '../../../shared/components/form/containers/form/form.interface';
import {ConfigType} from '../../../shared/components/form/components/field/fieldDirective.interface';
import {Weekprijs} from '../../../shared/components/form/components/weekprijs/form-weekprijs.interface';
import {LoggerService} from "../../../shared/services/logger/logger.service";

@Component ({
    selector: 'global-popup-create-weekprijs-component',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <default-popup-wrapper-component [componentRef]="this"
                                         [disableButtons]="readOnly"
                                         [hideButtons]="isLoading"
                                         [globalPopup]="globalPopup"
                                         [title]="ts.translate(weekprijsDropDownData &&
                                         weekprijsDropDownData.length > 0?'weekprijs.createtitle':'weekprijs.updatetitle')"
                                         (onClose)="closePopup($event)">

            <ng-container *ngIf="weekprijsDropDownData && weekprijsDropDownData.length > 0">
                <div class="dynamic-form mb-3">
                    <div class="d-flex mt-1 ">
                        <div class="d-flex w-100">
                            <form-select #weekprijsDropDown [weekprijs]="true" [Config]="dropDownConfig" *ngIf="dropDownConfig" (onComponentEvent)="handleChangeWeekprijs()"></form-select>
                        </div>
                    </div>
                </div>
            </ng-container>

            <div *ngIf="isLoading" class="py-3 d-flex justify-content-center">
                <!--<div class="pr-3" style="line-height: 32px;height: 32px;">{{'Gegevens ophalen...' | translate}}</div>-->
                <div class="loader"></div>
            </div>

            <ng-container *ngIf="formData && config">
                <form-component #form
                                [config]="config"
                                [name]="getFormName()"
                                [validationConstraints]="validationConstraints"
                                [invalidControlsErrors]="invalidControlsErrors"
                                [readOnly]="readOnly"
                                [formIsSubmitted]="formIsSubmitted"
                                (onComponentEvent)="handleComponentEvent($event)"
                ></form-component>
                
            </ng-container>
        </default-popup-wrapper-component>
    `
})

export class GlobalPopupCreateWeekprijsComponent extends AbstractBasePopupComponent implements AfterViewInit {
    @Input() requestUrl: string = '';
    @Input() submitUrl: string = '';
    @Input() termijnstaatId: number = -1;

    @ViewChild('form', {static: false}) form: any;
    @ViewChild('weekprijsDropDown', {static: false}) weekprijsDropDown: FormSelectComponent;

    weekprijsDropDownData: any[];
    dropDownConfig: any;

    public constructor(protected cd: ChangeDetectorRef, protected globalAlertService: GlobalAlertService,
                       protected formDataService: FormDataService, protected elementRef: ElementRef, private model: GlobalModel,
                       public ts: TranslateService, protected tooltipService: TooltipService, protected logger:LoggerService) {
        super(cd, globalAlertService, elementRef, ts, tooltipService, logger);
    }

    ngAfterViewInit() {
        // Retrieve map item id from the popup data
        this.requestUrl = this.globalPopup.data.requestUrl;
        this.submitUrl = this.globalPopup.data.submitUrl;
        this.termijnstaatId = this.globalPopup.data.termijnstaatId;

        // Update specification dropdowndata, and trigger view refresh
        this.weekprijsDropDownData = this.globalPopup.data.weekprijsDropDownData;
        if (this.weekprijsDropDownData) {
            this.setDropdownConfig();
        }
        this.submitButton = this.getSubmitButton();
        this.cd.detectChanges();

        setTimeout(() => {
            this.requestFormData();
        });
    }

    handlePopupAction(event: MouseEvent, alert: GlobalPopup, button: any): void {
        // callback for submit button comes from this form, not from outside this component
        if (button === this.submitButton) {
            this.form.handleComponentEvent({event: FormEvent.SAVE, data: {}});
            // this.form.submitForm();
        } else {
            // Hide alert and perform callback of button
            this.onPopupAction.emit({event: event, alert: alert, button: button});
        }
    }

    handleComponentEvent(eventData: any): void {
        if (eventData.event === FormEvent.SAVE) {
            this.submitFormData(eventData.data.formData);
        }
    }

    private submitFormData(form: any): void {
        this.handleSubmitForm();

        let url: string = this.submitUrl + this.getWeekprijsTypeId() + '/' + this.termijnstaatId;

        // Single edit mode
        if (!this.weekprijsDropDownData) {
            url = this.submitUrl;
        }

        this.formDataService.setFormData(form, this.getFormName(), url,
            (formData: any) => {
                // this.formData = formData;
                this.handleSubmitResponse();

                // Hide alert and perform success callback via submit button
                this.onPopupAction.emit({event: null, alert: this.globalPopup, button: this.submitButton, data: formData});
            },
            (failure: RequestFailure) => {
                this.logger.log('[GlobalPopupCreateBaseObjectComponent] ' + 'Handle failure response: ', failure);
                this.handleSubmitResponse(failure.formErrors);
            },
            () => {
                this.handleSubmitResponse(null, true);
            }
        );
    }

    closePopup(event: any): void {
        this.doDefaultCloseHandling(event, true);
    }

    handleChangeWeekprijs(): void {
        this.formData = null;
        this.requestFormData();
    }

    private getWeekprijsTypeId(): string {

        // Safety for element not loaded
        let weekprijs: string = 'no_id';

        // Get the value of the dropdown
        if (this.weekprijsDropDown) {
            weekprijs = this.weekprijsDropDown.getSelectedIdsAsArray().id;
        }

        return weekprijs;
    }

    public requestFormData(): void {
        this.isLoading = true;

        let url: string = this.requestUrl + this.getWeekprijsTypeId() + '/' + this.termijnstaatId;

        // Single edit mode
        if (!this.weekprijsDropDown) {
            url = this.requestUrl;
        }

        this.formDataService.getFormData(url,
            (formData: any) => {
                this.formData = formData;
                this.isLoading = false;
                this.cd.detectChanges();
            },
            () => {
                this.closePopup(null);
            },
            () => {
                this.closePopup(null);
            }
        );
    }

    private setDropdownConfig() {
        this.dropDownConfig = {
            label: this.ts.translate('weekprijs.dropdown'),
            disabled: false,
            attr: {
                'batch-update': false,
                nullable: false
            },
            type: ConfigType.select,
            options: this.weekprijsDropDownData.map(row => { return {id: row.id, name: row.label}; }),
            name: 'createWeekprijsPopup',
            required: false
        };
    }
}
