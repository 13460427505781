import {Component, Input, OnInit} from '@angular/core';
import {ProgressBarItem, ProgressBarStyle} from './progress-bar.interface';

@Component({
  selector: 'progress-bar',
  templateUrl: './progress-bar.component.html'
})
export class ProgressBarComponent implements OnInit {
  @Input() items: ProgressBarItem[] = [];

  constructor() { }

  ngOnInit() {
  }
  
  public getProgressDoneStyle(): ProgressBarStyle {
    const hasAttentionActivities = this.items.some(item => {
      return item.hasAttention;
    });
    return {
      'border-top-left-radius': '1rem',
      'border-bottom-left-radius': '1rem',
      'border-top-right-radius': hasAttentionActivities ? '' : '1rem',
      'border-bottom-right-radius': hasAttentionActivities ? '' : '1rem',
      width: `${this.getDonePercentage()}%`
    };
  }
  
  public getProgressAttentionDoneStyle(): ProgressBarStyle {
    const hasDoneActivities = this.items.some(item => {
      return item.isDone && !item.hasAttention;
    });
    const hasAttentionTodoActivities = this.items.some(item => {
      return !item.isDone && item.hasAttention;
    });
    return {
      'border-top-left-radius': hasDoneActivities ? '' : '1rem',
      'border-bottom-left-radius': hasDoneActivities ? '' : '1rem',
      'border-top-right-radius': hasAttentionTodoActivities ? '' : '1rem',
      'border-bottom-right-radius': hasAttentionTodoActivities ? '' : '1rem',
      width: `${this.getAttentionDonePercentage()}%`
    };
  }
  
  public getProgressAttentionTodoStyle(): ProgressBarStyle {
    const hasDoneActivities = this.items.some(item => {
      return item.isDone;
    });
    return {
      'border-top-left-radius': hasDoneActivities ? '' : '1rem',
      'border-bottom-left-radius': hasDoneActivities ? '' : '1rem',
      'border-top-right-radius': '1rem',
      'border-bottom-right-radius': '1rem',
      width: `${this.getAttentionTodoPercentage()}%`
    };
  }
  
  private getDonePercentage(): number {
    const totalLength: number = this.items.length;
    const partLength: number = this.items.filter(item => {
      return item.isDone && !item.hasAttention;
    }).length;
    return partLength / totalLength * 100;
  }
  
  private getAttentionDonePercentage(): number {
    const totalLength: number = this.items.length;
    const partLength: number = this.items.filter(item => {
      return item.isDone && item.hasAttention;
    }).length;
    return partLength / totalLength * 100;
  }
  
  private getAttentionTodoPercentage(): number {
    const totalLength: number = this.items.length;
    const partLength: number = this.items.filter(item => {
      return !item.isDone && item.hasAttention;
    }).length;
    return partLength / totalLength * 100;
  }

}
