import { NgControl } from '@angular/forms';
import {Directive, Input} from '@angular/core';

@Directive({
    selector: '[disableControl]'
})
export class DisableControlDirective {

    @Input() set disableControl(disable: boolean) {
        // By disabeling the control, the control is no longer send to the backend when submitting the form
        setTimeout(() => {
            this.ngControl.control[disable ? 'disable' : 'enable']();
        });
    }

    constructor( private ngControl: NgControl ) {
    }
}
