import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component, ElementRef,
} from '@angular/core';
import {GlobalAlertService} from "../../global-alert.service";
import {GlobalPopup} from "../../global-popup";
import {TranslateService} from "../../../../shared/services/translate/translate.service";
import {AbstractBasePopupComponent} from "../abstract-base-popup.component";
import {TooltipService} from "../../../../shared/services/tooltip/tooltip.service";
import {LoggerService} from "../../../../shared/services/logger/logger.service";
import {GlobalModel} from '../../../../shared/services/state/global.model';

@Component ({
    selector: 'global-popup-first-login-component',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: 'global-popup-first-login.component.html'
})

export class GlobalPopupFirstLoginComponent extends AbstractBasePopupComponent implements AfterViewInit {
    public constructor(protected cd:ChangeDetectorRef, protected elementRef:ElementRef, protected globalAlertService:GlobalAlertService, public ts:TranslateService, protected tooltipService:TooltipService, protected logger:LoggerService, public model:GlobalModel) {
        super(cd, globalAlertService, elementRef, ts, tooltipService, logger);
    }

    ngAfterViewInit() {
        this.isLoading = false;
        this.cd.detectChanges();
    }

    public closePopup(event:any):void{
        this.doDefaultCloseHandling(event, false);
    }

    handlePopupAction(event: MouseEvent, alert: GlobalPopup, button: any): void {
        //Hide alert and perform callback of button
        this.onPopupAction.emit({event:event, alert:alert, button:button});
    }
}

