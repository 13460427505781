<!-- this creates a google map on the page with the given lat/lng from -->
<!-- the component as the initial center of the map:  -->
<div class="map-component-container" (globalKeydownOutsideAngular)="handleKeyDown($event)"
     (globalKeyupOutsideAngular)="handleKeyUp($event)" (globalMouseupOutsideAngular)="handleMouseUp()"
     (globalFocusOutsideAngular)="handleWindowFocus()" (click)="handleClickMap($event)" (contextmenu)="showRightClickMenu($event)">
  <div class="map-streetview-container">
    <div class="streetview-container" [style.height]="showStreetView?'50%':'100%'">
      <div #mapContainer id="mapContainer" class="map-component" (globalResizeOutsideAngular)="onResize($event)"></div>
      <div *ngIf="_isLoading && !lockOnLocation && showLoading" class="map-component-info-container">
        <div class="loading-form-small">
          <!--<div>{{'Gegevens ophalen...' | translate}}</div>-->
          <div class="loader"></div>
        </div>
      </div>
      <div *ngIf="showNoItemsWarning && !isBasicMode && !lockOnLocation && markers.length <=0 && !_isLoading"
           class="map-component-info-container">
        <div class="loading-form">
          <div class="map-component-no-marker">{{'Geen items om weer te geven' | translate}}</div>
        </div>
      </div>
    </div>
    <streetview-core-component #streetviewComponent class="streetview-container"
                               (onClose)="handleCloseStreetview()"
                               [streetViewMapType] = "streetViewMap"
                               [cyclomediaSettings] = "cyclomediaSettings"
                               [style.display]="showStreetView?'block':'none'"
                               [style.margin-top]="mapContainer.offsetHeight + 'px'"></streetview-core-component>
  </div>
  <div class="card map-component-options-container map-button">
    <div class="map-component-options-button waves-effect waves-light" (click)="handleShowOptions()"
         title="{{'map.settings' | translate}}">
      <i class="material-icons">layers</i>
    </div>
    <div class="{{isBasicMode?'d-none':''}} map-component-options-button waves-effect waves-light"
         (click)="handleClickFocus()" title="{{'map.focus' | translate}}">
      <i class="material-icons">filter_center_focus</i>
    </div>
    <div *ngIf="polygonSelect.polyIsActive()" class="map-component-options-button waves-effect waves-light"
         (click)="polygonSelect.closePolygonSelect()">
      <i class="material-icons">clear</i>
    </div>
  </div>
  <div class="card map-component-location-container map-button"
       *ngIf="allowLocation || allowAutoLoadMarkers || allowSearch">
    <div *ngIf="allowSearch" class="h-100" style="position: relative">
      <div class="map-component-options-button waves-effect waves-light" (click)="handleClickSearch()"
           title="{{'Zoek naar locatie' | translate}}">
        <i class="material-icons" [style.background]="searchExpanded?AppSettings.getColor('$selection-blue'):'white'"
           [style.color]="searchExpanded?'white':'inherit'">search</i>
      </div>
      <div class="map-search-box z-depth-1 justify-content-center {{!searchExpanded?'hidden-search':'d-flex'}}">
        <input #mapSearchInput class="" style="height: 1.8rem; width: 97%" type="text"
               placeholder="{{'Zoek naar locatie' | translate}}">
      </div>
    </div>
    <div *ngIf="allowLocation" class="map-component-options-button waves-effect waves-light"
         (click)="handleClickLocation()" title="{{'Focus op huidige locatie' | translate}}">
      <i class="material-icons" [style.background]="lockOnLocation? AppSettings.getColor('$selection-blue'):'white'"
         [style.color]="lockOnLocation?'white':'inherit'">my_location</i>
    </div>
    <div *ngIf="allowAutoLoadMarkers && !isBasicMode"
         class="map-component-options-button waves-effect waves-light"
         (click)="handleClickAutoLoadMarkers()" title="{{'mapbutton.boundingbox' | translate}}">
      <i class="material-icons" [style.background]="autoLoadMarkers?AppSettings.getColor('$selection-blue'):'white'"
         [style.color]="autoLoadMarkers?'white':'inherit'">texture</i>
    </div>
  </div>
  <div class="{{isBasicMode?'d-none':''}} card map-component-legend-container map-button" [style.bottom]="showStreetView?'50%':''">
    <div class="map-component-options-button waves-effect waves-light" (click)="handleShowLegend()"
         title="{{'Legenda' | translate}}">
      <i class="material-icons">help</i>
    </div>
  </div>
</div>

<!-- Show right click menu -->
<div #rightClickMenu class="right-click-menu" style="position: fixed; display: none">
  <ul class="mb-0">
    <li *ngIf="readableLatLng" (click)="copyToClipboard()">Latitude:{{readableLatLng.lat}}, Longitude:{{readableLatLng.lng}}</li>
    <li (click)="measureDistance()">{{'measuredistance.title' | translate}}</li>
    <li (click)="removeMeasureDistance()">{{'measuredistance.remove' | translate}}</li>
  </ul>
</div>

<!-- Show clipboard success message -->
<div *ngIf="showMapInfo" @shortFade class="d-flex clipboard-success">
  <p>{{showMapInfoText | translate}}</p>
</div>