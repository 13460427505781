import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input, OnDestroy, OnInit,
    Output,
    ViewChild
} from '@angular/core';
import {TranslateService} from '../../../services/translate/translate.service';
import {GlobalAlertService} from '../../../../wrapper/global-alert/global-alert.service';
import {TableSortDirection} from '../shared/baseTable/baseTable.interface';
import {FormMultiSelectComponent} from '../../form/components/form-select/form-multi-select/form-multi-select.component';
import {TableOptionsField} from './table-options';
import {
    ChangeFilterEvent,
    ChangeSortingEvent,
    ColumnPickerConfig,
    ColumnPickerOption,
    ConfigType,
    SelectorColumn,
    SortingConfig,
    SortingOption
} from './tableColumnSelector.interface';
import {Subscription} from 'rxjs';
import {GlobalModel} from '../../../services/state/global.model';
import {FilterPopupData} from '../../map-table/map-table.interface';

@Component({
    selector: 'table-column-selector',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: 'tableColumnSelector.component.html'
})
export class TableColumnSelectorComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('columnPicker', {static: false}) columnPicker: FormMultiSelectComponent;
    @ViewChild('tableBody', {static: false}) tableBody: ElementRef;
    
    @Output() onChanges: EventEmitter<null> = new EventEmitter<null>();
    
    @Input() set tableFields(tableFields: TableOptionsField[]) {
        this._tableFields = tableFields.sort(
            (fieldA: TableOptionsField, fieldB: TableOptionsField) => fieldA.columnRank - fieldB.columnRank
        );
        if (this.viewInit) {
            this.setData();
        }
    }
    @Input() allowAddFilters: boolean;
    @Input() allowToggleOnMap: boolean;
    @Input() allowSorting: boolean;
    
    public visibleColumns: SelectorColumn[] = [];
    public viewInit: boolean = false;
    public maxLabelChars: number = 25;
    public truncateLabel: boolean;
    public hideTableBody: boolean = false;
    public mobileMode: boolean;
    
    private _tableFields: TableOptionsField[];
    private configOptions: ColumnPickerOption[] = [];
    private sortingDirectionOptions: SortingOption[] = [{
        id: TableSortDirection.SORT_DIRECTION_ASC,
        name: this.translateService.translate('Ascending'),
        checked: false
    }, {
        id: TableSortDirection.SORT_DIRECTION_DESC,
        name: this.translateService.translate('Descending'),
        checked: false
    }, {
        id: TableSortDirection.SORT_DIRECTION_UNSET,
        name: '-',
        checked: false
    }];
    private isDragging: boolean = false;
    private startNewDragEvent: boolean = true;
    private fakeTableBody: HTMLElement;
    private draggingRow: SelectorColumn;
    private draggingRowHTMLElementIndex: number;
    private draggableElement: HTMLTableRowElement;
    private placeholder: HTMLDivElement;
    private mousePosition: { x: number; y: number };
    private subscriptions: Subscription[] = [];

    constructor(
        private model: GlobalModel,
        private changeDetectorRef: ChangeDetectorRef,
        private translateService: TranslateService,
        private globalAlertService: GlobalAlertService
    ) {
    }
    
    ngOnInit(): void {
        this.subscriptions.push(this.model.mobileMode.subscribe(isMobileMode => this.mobileMode = isMobileMode));
    }
    
    ngOnDestroy() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }
    
    ngAfterViewInit(): void {
        this.viewInit = true;
        this.truncateLabel = this.allowAddFilters;
        this.setData();
        this.updateView();
    }
    
    public getResultingTableFields(): TableOptionsField[] {
        return [...this._tableFields.map(tableOptionsField => {
            if (this.visibleColumns.some(column => column.id === tableOptionsField.code)) {
                const correspondingColumn: SelectorColumn = this.visibleColumns.find(column => column.id === tableOptionsField.code);
                tableOptionsField.columnRank = correspondingColumn.rank;
                tableOptionsField.filter = correspondingColumn.filter;
                tableOptionsField.sortDirection = correspondingColumn.sortDirection;
                tableOptionsField.sorting = correspondingColumn.sortDirection !== TableSortDirection.SORT_DIRECTION_UNSET;
                tableOptionsField.inMouseOver = correspondingColumn.onMap;
                tableOptionsField.visible = true;
            } else {
                tableOptionsField.sorting = false;
                tableOptionsField.visible = false;
            }
            
            return tableOptionsField;
        })];
    }
    
    public rankUp(column: SelectorColumn): void {
        this.onChanges.emit();
        const clickedColumn: SelectorColumn = this.visibleColumns.find(_column => _column.id === column.id);
        this.visibleColumns.forEach(_column => {
            if (_column.rank === clickedColumn.rank - 1) {
                _column.rank++;
            }
        });
        clickedColumn.rank--;
        this.highLightRow(column);
        this.updateView();
    }
    
    public rankDown(column: SelectorColumn): void {
        this.onChanges.emit();
        const clickedColumn: SelectorColumn = this.visibleColumns.find(_column => _column.id === column.id);
        this.visibleColumns.forEach(_column => {
            if (_column.rank === clickedColumn.rank + 1) {
                _column.rank--;
            }
        });
        clickedColumn.rank++;
        this.highLightRow(column);
        this.updateView();
    }
    
    public getSortingConfigForColumn(column: SelectorColumn): SortingConfig {
        return {
            attr: {
                'batch-update': false,
                nullable: false
            },
            type: ConfigType.SINGLE,
            options: this.getSortingOptions(column.sortDirection),
            name: column.id,
            initialValue: column.sortDirection,
            required: false
        };
    }
    
    public toggleOnMap(column: SelectorColumn): void {
        this.onChanges.emit();
        column.onMap = !column.onMap;
    }
    
    public addFilter(column: SelectorColumn): void {
        this.onChanges.emit();
        this.openFilterPopup(column);
    }
    
    public handleChangeFilter($event: ChangeFilterEvent, column: SelectorColumn): void {
        this.onChanges.emit();
        column.filter = $event.data.selectedFilter;
        this.resetTruncation();
        this.updateView();
    }
    
    public handleDeleteFilter(column: SelectorColumn): void {
        this.onChanges.emit();
        column.filter = null;
    }
    
    public setSorting($event: ChangeSortingEvent): void {
        this.visibleColumns.find(column => String(column.id) === String($event.data.name))
            .sortDirection = $event.data.selectedOption;
        
        if ($event.data.selectedOption !== TableSortDirection.SORT_DIRECTION_UNSET) {
            this.visibleColumns.forEach(column => {
                if (String(column.id) !== String($event.data.name)) {
                    column.sortDirection = TableSortDirection.SORT_DIRECTION_UNSET;
                }
            });
        }
        
        this.updateView();
    }
    
    public getTableFieldForColumn(column: SelectorColumn): TableOptionsField {
        const tableOptionsField: TableOptionsField = JSON.parse(JSON.stringify(
            this._tableFields.find(field => field.code === column.code)
        ));
        tableOptionsField.filter = column.filter;
        return tableOptionsField;
    }
    
    public handleChangeColumns(): void {
        setTimeout(() => {
            this.onChanges.emit();
            const selectedColumns: string[] = this.columnPicker.getSelectedIdsAsArray();
            this.visibleColumns.forEach(column => {
                if (selectedColumns.some(columnCode => column.code === columnCode)) {
                    return;
                }
                this.removeColumn(column);
            });
            selectedColumns.forEach(columnCode => {
                if (this.visibleColumns.some(column => column.code === columnCode)) {
                    return;
                }
                const fieldToAdd: TableOptionsField = this._tableFields.find(field => field.code === columnCode);
                this.addColumn(fieldToAdd, true);
            });
            this.updateView();
        }, 100);
    }
    
    public resetTruncation(): void {
        if (this.truncateLabel) {
            this.visibleColumns.forEach(column => column.truncateLabel = column.label.length > this.maxLabelChars);
        }
    }
    
    public toggleTruncation(): void {
        this.visibleColumns.forEach(
            column => column.truncateLabel = (column.label.length > this.maxLabelChars) ? !column.truncateLabel : false
        );
    }
    
    public onRemoveColumn(column: SelectorColumn) {
        this.onChanges.emit();
        const correspondingOption: ColumnPickerOption = this.columnPicker.config.options.find(option => option.id === column.id);
        this.columnPicker.deselectOption(correspondingOption.id);
        this.removeColumn(column);
    }
    
    public getRowStyle(column: SelectorColumn) {
        return column.highlighted ? 'cst-row-highlighted' : 'cst-row-normal';
    }
    
    public onDragStart($event: any, row: SelectorColumn) {
        // activate dragging
        this.isDragging = true;
        
        // remember the row and index of the corresponding html element
        this.draggingRow = row;
        const originalRow = $event.target.parentNode.parentNode.parentNode.parentNode;
        this.draggingRowHTMLElementIndex = [].slice.call(this.tableBody.nativeElement.querySelectorAll('tr')).indexOf(originalRow);
        
        // set the mouse position
        this.mousePosition = {
            x: $event.clientX, // || $event.touches[0].clientX,
            y: $event.clientY // || $event.touches[0].clientY
        };
    }
    
    // @HostListener('window:touchmove', ['$event'])
    @HostListener('window:mousemove', ['$event'])
    public onDrag($event: any): void {
        $event.stopImmediatePropagation();
        $event.preventDefault();
        if (this.isDragging) {
            // only do this part the first time the drag is activated
            if (this.startNewDragEvent) {
                this.startNewDragEvent = false;
                
                this.cloneTableBodyAndHideOriginal();
                this.createDraggableElementAndPlaceholder();
            }
            const eventXPosition = $event.clientX; // || $event.touches[0].clientX;
            const eventYPosition = $event.clientY; // || $event.touches[0].clientY;
            // Set new position for dragging element
            if (this.draggableElement) {
                this.draggableElement.style.position = 'absolute';
                this.draggableElement.style.top = `${this.draggableElement.offsetTop + eventYPosition - this.mousePosition.y}px`;
                this.draggableElement.style.left = `${this.draggableElement.offsetLeft + eventXPosition - this.mousePosition.x}px`;
            }
            
            // Reassign the new position of the mouse
            this.mousePosition.x = eventXPosition;
            this.mousePosition.y = eventYPosition;
            
            this.adjustPlaceHolderPosition();
        }
    }
    
    // @HostListener('window:touchend', ['$event'])
    @HostListener('window:mouseup', ['$event'])
    public onDragEnd(): void {
        const rowBeingMovedIndex = this.visibleColumns.indexOf(this.draggingRow);
        let endRowIndex = rowBeingMovedIndex;
        
        // clean up created elements
        if (this.placeholder && this.placeholder.parentNode) {
            this.placeholder.parentNode.removeChild(this.placeholder);
        }
        if (this.draggableElement && this.draggableElement.parentNode) {
            endRowIndex = [].slice.call(this.fakeTableBody.children).indexOf(this.draggableElement);
            this.draggableElement.parentNode.removeChild(this.draggableElement);
        }
        if (this.fakeTableBody && this.fakeTableBody.parentNode) {
            this.fakeTableBody.parentNode.removeChild(this.fakeTableBody);
        }
        
        // reset check variables
        this.isDragging = false;
        this.startNewDragEvent = true;
        this.hideTableBody = false;
        
        // update the rank
        if (endRowIndex > rowBeingMovedIndex) {
            for (let i = 0; i < endRowIndex - rowBeingMovedIndex; i++) {
                this.rankDown(this.draggingRow);
            }
        } else {
            for (let i = 0; i < rowBeingMovedIndex - endRowIndex; i++) {
                this.rankUp(this.draggingRow);
            }
        }
    }
    
    private getSortingOptions(sortDirection: TableSortDirection): SortingOption[] {
        return [...this.sortingDirectionOptions.map(option => {
            option.checked = option.id === sortDirection;
            return option;
        })];
    }
    
    private openFilterPopup(column: SelectorColumn): void {
        const tableOptionsField = JSON.parse(JSON.stringify(this.getTableFieldForColumn(column)));
        // Create a new popup with the fields
        this.globalAlertService.addPopupAddTableFilter(
            [tableOptionsField],
            tableOptionsField,
            (event: string, data: FilterPopupData) => {
                column.filter = {command: data.filter.command, values: data.filter.values};
                this.updateView();
            },
            () => {
            },
        );
    }
    
    private updateView(): void {
        this.visibleColumns = [...this.visibleColumns.sort(
            (columnA: SelectorColumn, columnB: SelectorColumn) => columnA.rank - columnB.rank
        )];
        if (this.visibleColumns.length > 0 &&
            this.visibleColumns.every(column => column.sortDirection === TableSortDirection.SORT_DIRECTION_UNSET)
        ) {
            this.visibleColumns.forEach(column => {
                column.sortDirection = this._tableFields.find(field => field.code === column.code).sortDirection;
            });
        }
        this.changeDetectorRef.detectChanges();
    }
    
    private removeColumn(column: SelectorColumn) {
        const clickedColumn: SelectorColumn = this.visibleColumns.find(_column => _column.id === column.id);
        const clickedColumnIndex: number = this.visibleColumns.findIndex(_column => _column.id === column.id);
        this.visibleColumns.forEach(_column => {
            if (_column.rank > clickedColumn.rank) {
                _column.rank--;
            }
        });
        this.visibleColumns.splice(clickedColumnIndex, 1);
        this.updateView();
    }
    
    private setData(): void {
        this.visibleColumns = [];
        let selectedOptions: string[] = [];
        this.configOptions = [];
        
        this._tableFields.forEach((field: TableOptionsField) => {
            this.configOptions.push({id: field.code, name: field.label, disabled: !field.removable});
            
            if (field.visible === undefined) {
                field.visible = field.default;
            }
            
            if (field.visible) {
                this.addColumn(field);
                selectedOptions.push(field.code);
            }
        });
        
        this.columnPicker.config = <ColumnPickerConfig>{
            label: this.translateService.translate('columns.fortable'),
            disabled: false,
            attr: {
                'batch-update': false,
                infoText: this.translateService.translate('filter.pickcolumn')
            },
            type: 'multi_select',
            options: this.configOptions,
            name: 'groeps',
            initialValue: selectedOptions,
            required: false
        };
        this.columnPicker.extractSelectedItems();
        this.updateView();
    }
    
    private addColumn(field: TableOptionsField, highlight: boolean = false): void {
        if (field.inMouseOver === undefined) {
            field.inMouseOver = field.default;
        }
        if (field.sortDirection === undefined) {
            field.sortDirection = TableSortDirection.SORT_DIRECTION_UNSET;
        }
        if (field.sorting === undefined) {
            field.sorting = false;
            field.sortDirection = TableSortDirection.SORT_DIRECTION_UNSET;
        }
        
        if (!field.columnRank) {
            let previousRank = 0;
            while (this.visibleColumns.some(column => column.rank === previousRank + 1)) {
                previousRank++;
            }
            field.columnRank = previousRank + 1;
        }
        
        this.visibleColumns.push({
            truncateLabel: this.truncateLabel ? field.label.length > this.maxLabelChars : false,
            label: field.label,
            code: field.code,
            id: field.code,
            rank: field.columnRank,
            onMap: field.inMouseOver,
            sortDirection: field.sortDirection,
            filter: field.filter,
            removable: field.removable,
            highlighted: false
        });
        
        if (this.visibleColumns.filter(column => column.rank === field.columnRank).length > 1) {
            this.visibleColumns.filter(column => column.rank >= field.columnRank && column.code !== field.code)
                .forEach(column => column.rank++);
        }
        this.resetTruncation();
        if (highlight) {
            this.highLightRow(this.visibleColumns.find(column => column.id === field.code));
        }
    }
    
    private highLightRow(column: SelectorColumn) {
        column.highlighted = true;
        setTimeout(() => {
            column.highlighted = false;
            this.changeDetectorRef.detectChanges();
        });
    }
    
    private cloneTableBodyAndHideOriginal(): void {
        // Create fake table body
        this.fakeTableBody = document.createElement('tbody');
        this.fakeTableBody.classList.add('cst-unselectable');
    
        // Insert it before the table
        this.tableBody.nativeElement.parentNode.insertBefore(this.fakeTableBody, this.tableBody.nativeElement);
        
        // Hide the original table body
        this.hideTableBody = true;
        
        // clone the rows
        this.tableBody.nativeElement.querySelectorAll('tr').forEach((row: HTMLTableRowElement) => {
            const newRow = document.createElement('tr');
            newRow.classList.add('cst-row-normal');
            newRow.classList.add('cst-fake-row');
            
            // clone the cells
            const cells = [].slice.call(row.children);
            cells.forEach(function (cell) {
                const newCell = cell.cloneNode(true);
                newRow.appendChild(newCell);
            });
            
            // add rows to the fake table body
            this.fakeTableBody.appendChild(newRow);
        });
    }
    
    private createDraggableElementAndPlaceholder(): void {
        // create a draggable clone of the row to drag
        this.draggableElement = [].slice.call(this.fakeTableBody.children)[this.draggingRowHTMLElementIndex];
        this.draggableElement.classList.add('cst-dragging', 'cst-row-normal');
        
        // create a placeholder in the fake table body for where the draggable element is missing
        this.placeholder = document.createElement('tr');
        this.placeholder.classList.add('cst-row-placeholder');
        this.placeholder.appendChild(document.createElement('td'));
        this.placeholder.appendChild(document.createElement('td'));
        this.placeholder.appendChild(document.createElement('td'));
        if (this.allowAddFilters) {
            this.placeholder.appendChild(document.createElement('td'));
        }
        if (this.allowSorting) {
            this.placeholder.appendChild(document.createElement('td'));
        }
        if (this.allowToggleOnMap) {
            this.placeholder.appendChild(document.createElement('td'));
        }
        this.draggableElement.parentNode.insertBefore(this.placeholder, this.draggableElement.nextSibling);
        this.placeholder.style.height = `${this.draggableElement.offsetHeight}px`;
    }
    
    private adjustPlaceHolderPosition(): void {
        // adjust the position of the placeholder in the fake table body
        // note; the draggable element is html-wise directly above placeholder
        const elementAbovePlaceHolder = this.draggableElement.previousElementSibling;
        const elementBelowPlaceholder = this.placeholder.nextElementSibling;
        
        // The dragging element is above the element above the placeholder
        // User moves the dragging element to the top
        if (elementAbovePlaceHolder && this.isAbove(this.draggableElement, elementAbovePlaceHolder)) {
            this.swapElementPositions(this.placeholder, this.draggableElement);
            this.swapElementPositions(this.placeholder, elementAbovePlaceHolder);
            return;
        }
        
        // The dragging element is below the element below the placeholder
        // User moves the dragging element to the bottom
        if (elementBelowPlaceholder && this.isAbove(elementBelowPlaceholder, this.draggableElement)) {
            this.swapElementPositions(elementBelowPlaceholder, this.placeholder);
            this.swapElementPositions(elementBelowPlaceholder, this.draggableElement);
        }
    }
    
    private swapElementPositions(nodeA, nodeB): void {
        const parentA = nodeA.parentNode;
        const siblingA = nodeA.nextSibling === nodeB ? nodeA : nodeA.nextSibling;
        
        // Move `nodeA` to before the `nodeB`
        nodeB.parentNode.insertBefore(nodeA, nodeB);
        
        // Move `nodeB` to before the sibling of `nodeA`
        parentA.insertBefore(nodeB, siblingA);
    }
    
    private isAbove(nodeA, nodeB): boolean {
        const rectA = nodeA.getBoundingClientRect();
        const rectB = nodeB.getBoundingClientRect();
        
        return (rectA.top + rectA.height / 2 < rectB.top + rectB.height / 2);
    }
}
