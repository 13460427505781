/**
 * Created by Christiaan on 01/03/2017.
 */
import {
    AfterViewInit,
    ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, ViewChild
} from '@angular/core';
import {GlobalAlertService} from '../global-alert.service';
import {ButtonCode, GlobalPopup} from '../global-popup';
import {TranslateService} from '../../../shared/services/translate/translate.service';
import {AbstractBasePopupComponent} from './abstract-base-popup.component';
import {TooltipService} from '../../../shared/services/tooltip/tooltip.service';
import {LoggerService} from "../../../shared/services/logger/logger.service";

@Component ({
    selector: 'global-popup-add-table-filter-component',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <default-popup-wrapper-component [componentRef]="this" [globalPopup]="globalPopup"
                                         [title]="'filter.createtitle' | translate"
                                         [description]="'filter.createexplain' | translate"
                                         (onClose)="closePopup($event)">
            <table-filter-component #filterComponent
                                    *ngIf="selectedField && tableFields"
                                    [hideClearButton]="true"
                                    [tableField]="selectedField">
            </table-filter-component>
        </default-popup-wrapper-component>
    `
})

export class GlobalPopupAddTableFilterComponent extends AbstractBasePopupComponent implements AfterViewInit {
    @ViewChild('filterComponent', {static: false}) filterComponent: any;

    public tableFields: any;
    public selectedField: any;

    public constructor(
        protected cd: ChangeDetectorRef,
        protected globalAlertService: GlobalAlertService,
        protected elementRef: ElementRef,
        protected ts: TranslateService,
        protected tooltipService: TooltipService,
        protected logger:LoggerService
    ) {
        super(cd, globalAlertService, elementRef, ts, tooltipService, logger);
    }

    ngAfterViewInit() {
        this.tableFields = this.globalPopup.data.tableFields;
        this.selectedField = this.globalPopup.data.selectedField;
        this.cd.detectChanges();
    }

    handlePopupAction(event: MouseEvent, alert: GlobalPopup, button: any): void {
        let filterData: any = {};

        if ((button.code === ButtonCode.OK) || (button.code === ButtonCode.DELETE)) {
            filterData = this.filterComponent.getSelectedFilter();
        }

        // Hide alert and perform callback of button
        this.onPopupAction.emit({
            event: event,
            alert: alert,
            button: button,
            data: {
                filter: filterData,
                deleteFilter: button.code === ButtonCode.DELETE
            }
        });
    }
    
    public closePopup(event: any): void {
        this.doDefaultCloseHandling(event, false);
    }
}
