import {NgModule} from '@angular/core';
import {LocalizationComponent} from "./localization.component";
import {CommonModule} from "@angular/common";
import {LocalizationService} from "./localization.service";
import { FormsModule } from '@angular/forms';


@NgModule({
    imports: [
        FormsModule,
        CommonModule
    ],
    exports: [
        LocalizationComponent
    ],
    declarations: [
        LocalizationComponent
    ],
    providers: [
        LocalizationService
    ]
})

export class LocalizationModule {
}
