import {Component, Renderer2} from '@angular/core';
import {TranslateService} from '../../../../services/translate/translate.service';
import {ValidationConstraintService} from '../../services/validation-constraint.service';
import {TooltipService} from '../../../../services/tooltip/tooltip.service';
import {GlobalAlertService} from '../../../../../wrapper/global-alert/global-alert.service';
import {AbstractFormFieldComponent} from '../abstract/abstract-form-field.component';
import {LoggerService} from "../../../../services/logger/logger.service";

@Component({
  selector: 'form-timeline',
  templateUrl: './form-timeline.component.html',
})
export class FormTimelineComponent extends AbstractFormFieldComponent {

  constructor(public renderer: Renderer2, public validationConstraintService: ValidationConstraintService, public tooltipService: TooltipService, public ts: TranslateService, public globalAlertService: GlobalAlertService, protected logger:LoggerService) {
      super(renderer, validationConstraintService, tooltipService, logger)
  }

  ngOnInit(): void {
      this.logger.log('[FormTimelineComponent] on init :)', this.config);
  }

}
