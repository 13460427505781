<div class="map-item-importer">
    <h1>
        {{'mapItemCreate.title' | translate}}
    </h1>

    <div class="mb-3">
        <h2>
            {{'mapItemCreate.coords' | translate}}
        </h2>
        <div>
            <input class="with-gap"
                   id="WGS"
                   type="radio"
                   name="WGSCoords"
                   (change)="onCoordsChange()"
                   [(ngModel)]="WGSCoords"
                   [value]="true">
            <label for="WGS">
                {{'mapItemCreate.coords.wgs' | translate}}
            </label>
        </div>
        <div>
            <input class="with-gap"
                   type="radio"
                   id="RD"
                   name="WGSCoords"
                   (change)="onCoordsChange()"
                   [(ngModel)]="WGSCoords"
                   [value]="false">
            <label for="RD">
                {{'mapItemCreate.coords.rd' | translate}}
            </label>
        </div>
    </div>

    <div class="mb-3" #fileSelector>
        <h2>
            {{('mapItemCreate.upload.' + (this.WGSCoords ? 'wgs':'rd')) | translate}}
        </h2>
        <file-selector [isDisabled]="isDisabled"
                       [fileTypesString]="'.csv'"
                       (onChanges)="convertToJson($event)">
        </file-selector>
    </div>
    <form [formGroup]="form" class="dynamic-form mb-3" *ngIf="form">
        <h2>
            {{'mapItemCreate.input' | translate}}
        </h2>
        <div class="mii-input">
            <form-component-label class="form-label-part"
                                  [config]="{
                                      label:'mapItemCreate.input.code' | translate,
                                      required: 'true'
                                  }">
            </form-component-label>
            <div class="md-form input-group p-0 m-0">
                <input #inputField
                       name="code"
                       type="text"
                       [formControlName]="'code'">
            </div>
        </div>
        <div class="mii-input">
            <form-component-label class="form-label-part"
                                  [config]="{
                                      label:('mapItemCreate.input.' + (this.WGSCoords ? 'lat':'x')) | translate,
                                      required: 'true'
                                  }">
            </form-component-label>
            <input #inputField
                   name="{{LAT_X_CODE}}"
                   type="text"
                   [pattern]="coordPattern"
                   [formControlName]="LAT_X_CODE">
        </div>
        <div class="mb-1 mii-input">
            <form-component-label class="form-label-part"
                                  [config]="{
                                      label:('mapItemCreate.input.' + (this.WGSCoords ? 'lon':'y')) | translate,
                                      required: 'true'
                                  }">
            </form-component-label>
            <input #inputField
                   name="{{LON_Y_CODE}}"
                   type="text"
                   [pattern]="coordPattern"
                   [formControlName]="LON_Y_CODE">
        </div>
        <div class="d-flex">
            <lumi-button style="margin-left: auto"
                         (onClick)="addLocation($event)"
                         [rank]="'tertiary'"
                         [icon]="'add'"
                         [label]="'Voeg locatie toe'">
            </lumi-button>
        </div>
    </form>

    <div class="mb-3">
        <div class="top-bar-container">
            <h2>
                {{'mapItemCreate.newLocations' | translate}}
            </h2>
            <ul class="navbar-nav ml-auto nav-flex-icons map-table-view-toggle tab-bar">
                <li class="nav-item" (click)="setShowMap(false)">
                    <a class="nav-link">
                        <i class="material-icons map-table-view-toggle-icon waves-effect waves-light {{!showMap ?'active':''}}">
                            reorder
                        </i>
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" (click)="setShowMap(true)">
                        <i class="material-icons map-table-view-toggle-icon waves-effect waves-light {{showMap ?'active':''}}">
                            photo
                        </i>
                    </a>
                </li>
            </ul>
        </div>
        <div class="table-component-container" [hidden]="showMap">
            <base-table-component
                #baseTableComponent
                *ngIf="tableData"
                [tableOptions]="{
                    allowDeleteRow: true
                }"
                [isLoading]="isLoading"
                [tableData]="tableData"
                (onRowDelete)="deleteRow($event)">
            </base-table-component>
        </div>
        <div class="mii-map-container" [hidden]="!showMap">
            <map-core-component #mapComponent
                                [isBasicMode]="true"
                                [disableClusterer]="true"
                                [optionsBasicMode]="true"
                                [expandSearchOnInit]="false"
                                class="mii-map">
            </map-core-component>
        </div>
        <span>{{tableData.rows.length}} {{'mapItemCreate.newLocations.count' | translate}}</span>
    </div>
</div>
