import {AppSettings} from "../../app.settings";
import {LoggerService} from "../services/logger/logger.service";

/**
 * Created by Christiaan on 10/03/2017.
 */

// Lekker handige huis tuin en keuken functietjes waarmee je heel wat moois kan maken
export default class Utils {

    public static getAngularVersion():string
    {
        if (document.querySelector('my-app')) {
            return document.querySelector('my-app').getAttribute('ng-version');
        }

        return '';
    }

    public static hasFocus(element: Element): boolean {
        return document.activeElement === element;
    }
    public static removeAllFocus(): void {
        (<HTMLElement>document.activeElement).blur();
    }
    public static isBody(element: Element): boolean {
        return document.body === element;
    }
    public static preventDefault($event: MouseEvent | KeyboardEvent | Event): void {
        if ($event) {
            $event.preventDefault();
            $event.stopPropagation();
        }
    }

    //Download uri encoded, possibly base64 encoded, string as file, also for IE
    public static downloadAsFile(base64:boolean, fileData:any, fileName:string, querySelector:string = 'div'):void
    {
        //if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            //TODO: contentType is leeg, maakt dat uit?
            //NOTE: lees meer over deze functie op https://stackoverflow.com/questions/16245767/creating-a-blob-from-a-base64-string-in-javascript

            //TODO: Heeft alles normaal 'altijd' een comma?
            let commaIndex:number = fileData.indexOf(',');
            if (commaIndex > -1){

                let fileType:string = fileData.substr(0, commaIndex);

                //The data part of the fileData (excluding the filetype) is uri encoded base 64. Start with URI decoding the data.
                fileData = fileData.substr(commaIndex + 1);
                fileData = decodeURIComponent(fileData);

                //Use to log filedata. Data with whitespace is invalid
                //this.logger.log("[Utils] " + "fileData: |" + fileData + "|");

                let blobData:any = base64 ? Utils.b64toBlob(fileData) : new Blob([fileData], {type: fileType});

                let uriContent = URL.createObjectURL(blobData);
                let link = document.createElement('a');
                link.setAttribute('href', uriContent);
                link.setAttribute('download', fileName);
                link.classList.add('d-none');
                let event = new MouseEvent('click');
                link.dispatchEvent(event);
            }

       // } else {
            //Chrome and Firefox can handle uri encoded base64 directly as input for an a href
            //this.logger.log("[Utils] " + "downloadAsFile: Normal browser detected");

            //NOTE: om omgezet te worden naar een file, moet iig het header stukje niet base64 encoded zijn
            //NOTE: bestandend die hier langs komen zijn dus niet helemaal base64
            /*let a = document.createElement('a');
            a.classList.add('d-none');
            a.href = (fileData);
            a.download = fileName;
            //a.target = '_blank';
            document.querySelector(querySelector).appendChild(a);
            a.click();*/
        //}
    }

    // "?state=0&origin=dingen" becomes: {state:"0", origin:"null"}
    // NOTE: expects a query string that includes the '?'
    public static queryStringToJSON(queryString:string):any
    {
        let pairs:string[] = queryString.slice(1).split('&');

        let result:any = {};
        let part:string[];
        pairs.forEach((pair:string) => {
            part = pair.split('=');
            result[part[0]] = decodeURIComponent(part[1] || '');
        });

        return JSON.parse(JSON.stringify(result));
    }

    public static isEscapeKey(event:KeyboardEvent):boolean {
        return (event.key == "Escape" || event.keyCode == 27 || event.code == "Escape");
    }

    public static isIDevice(){
        return !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
    }

    public static isMacintosh() {
        return navigator.platform.indexOf('Mac') > -1
    }

    public static isWindows() {
        return navigator.platform.indexOf('Win') > -1
    }

    //Return IE version, or -1 when not IE
    // TODO: wat returned dit voor Edge? zet bij deze comments
    public static getInternetExplorerVersion():number
    {
        if (AppSettings.ALWAYS_USE_IE11){
            return 11;
        }

        let rv:number = -1;
        if (navigator.appName == 'Microsoft Internet Explorer')
        {
            let ua = navigator.userAgent;
            let re = new RegExp("MSIE ([0-9]{1,}[\\.0-9]{0,})");
            if (re.exec(ua) != null){
                rv = parseFloat( RegExp.$1 );
            }
        }
        else if (navigator.appName == 'Netscape')
        {
            let ua = navigator.userAgent;
            let re  = new RegExp("Trident/.*rv:([0-9]{1,}[\\.0-9]{0,})");
            if (re.exec(ua) != null){
                rv = parseFloat( RegExp.$1 );
            }
        }
        return rv;
    }

    public static capitalizeFirstLetter(text:string):string
    {
        return text.charAt(0).toUpperCase() + text.slice(1);
    }

    public static getLoggableObject(obj:any):any
    {
        return JSON.parse(JSON.stringify(obj))
    }

    public static getRandomNumber(min:number, max:number):number
    {
        return Math.floor((Math.random() * max) + min);
    }

    public static isNumberKeycode(keyCode:number):boolean
    {
        return keyCode >= 48 && keyCode <= 57 || keyCode >= 96 && keyCode <= 105;
    }

    public static escapeRegExp(regex:string):string {
        return regex.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, "\\$1");
    }

    public static degreesToRadians(deg:any):number{
        return deg * (Math.PI / 180);
    }

    public static radiansToDegrees(rad:any):number{
        return rad / (Math.PI / 180);
    }

    public static formatNumberWithLeadingZero(number:number):string
    {
        if (number < 10){
            return '0'+ number;
        }else{
            return number.toString();
        }
    }

    // Return value if between min & max, or return the min or max if outside the min & max values
    public static getWithinMinMax(value:number, min:number, max:number):number
    {
        value = Math.min(value, max);
        value = Math.max(value, min);

        return value;
    }

    // Execute a function and measure how many milliseconds the function took to execute.
    public static measureFunctionTime(resultString:string, functionToMeasure:Function):string
    {
        let startTime:any = new Date();
        functionToMeasure();
        return resultString + ((new Date() as any) - startTime) + " ms"
    }

    // Check if, and where, an element is out of the viewport
    public static isOutOfViewport(elem:any):any{

        // Get element's bounding
        let bounding:any = elem.getBoundingClientRect();

        // Check if it's out of the viewport on each side
        let result:any = {};
        result.outOfTop = bounding.top < 0;
        result.outOfLeft = bounding.left < 0;
        result.outOfBottom = bounding.bottom > (window.innerHeight || document.documentElement.clientHeight);
        result.outOfRight = bounding.right > (window.innerWidth || document.documentElement.clientWidth);
        result.outOfViewport = result.outOfTop || result.outOfLeft || result.outOfBottom || result.outOfRight;

        result.pixelsOutOfRight = bounding.right - (window.innerWidth?window.innerWidth:document.documentElement.clientWidth);
        result.elemWidth = elem.clientWidth;
        result.elemHeight = elem.clientHeight;

        return result;
    };

    // Translate a string to a very basic unique hash
    public static simpleHash(str:string):number
    {
        let hash:number = 0;

        for (let i:number = 0; i < str.length; i++)
        {
            hash = hash + (i * ((str.charCodeAt(i))));
        }

        return hash;
    }

    // Convert lat/lon to google mercator coordinates
    public static latLonToMercator(lat:number, lon:number)
    {
        let rMajor:number = 6378137; //Equatorial Radius, WGS84
        let shift:number  = Math.PI * rMajor;
        let x:number      = lon * shift / 180;
        let y:number      = Math.log(Math.tan((90 + lat) * Math.PI / 360)) / (Math.PI / 180);
        y = y * shift / 180;

        return [x, y];
    }

    // Convert lat/lon to Rijksdriehoekscoördinaten
    public static wgs2rd_2(lat: number, lon: number):number[] {

        let X0: number = 155000;
        let Y0: number = 463000;
        let phi0: number = 52.15517440;
        let lam0: number = 5.38720621;

        let Rp:number[] = [0, 1, 2, 0, 1, 3, 1, 0, 2];
        let Rq:number[] = [1, 1, 1, 3, 0, 1, 3, 2, 3];
        let Rpq:number[] = [190094.945, -11832.228, -114.221, -32.391, -0.705, -2.340, -0.608, -0.008, 0.148];

        let Sp:number[] = [1, 0, 2, 1, 3, 0, 2, 1, 0, 1];
        let Sq:number[] = [0, 2, 0, 2, 0, 1, 2, 1, 4, 4];
        let Spq:number[] = [309056.544, 3638.893, 73.077, -157.984, 59.788, 0.433, -6.439, -0.032, 0.092, -0.054];

        let dPhi: number = 0.36 * ( lat - phi0 );
        let dLam: number = 0.36 * ( lon - lam0 );

        let X: number = 0;
        let Y: number = 0;

        for (let r: number = 0; r < Rpq.length; r++) {
            X = X + ( Rpq[r] * Math.pow(dPhi, Rp[r]) * Math.pow(dLam, Rq[r]));
        }

        X = X0 + X;

        for (let s: number = 0; s < Spq.length; s++) {
            Y = Y + ( Spq[s] * Math.pow(dPhi, Sp[s]) * Math.pow(dLam, Sq[s]));
        }

        Y = Y0 + Y;

        return [Y, X];
    }

    public static rd2wgs_2(x:number, y:number)
    {
        let X0: number = 155000;
        let Y0: number = 463000;
        let phi0: number = 52.15517440;
        let lam0: number = 5.38720621;

        //def fromRdToWgs(self, coords):

        let Kp = [0, 2, 0, 2, 0, 2, 1, 4, 2, 4, 1];
        let Kq = [1, 0, 2, 1, 3, 2, 0, 0, 3, 1, 1];
        let Kpq = [3235.65389, -32.58297, -0.24750, -0.84978, -0.06550, -0.01709, -0.00738, 0.00530, -0.00039, 0.00033, -0.00012];

        let Lp = [1, 1, 1, 3, 1, 3, 0, 3, 1, 0, 2, 5];
        let Lq = [0, 1, 2, 0, 3, 1, 1, 2, 4, 2, 0, 0];
        let Lpq = [5260.52916, 105.94684, 2.45656, -0.81885, 0.05594, -0.05607, 0.01199, -0.00256, 0.00128, 0.00022, -0.00022, 0.00026];

        let dX: number = 0.00001 * ( x - X0 );
        let dY: number = 0.00001 * ( y - Y0 );

        let phi: number = 0;
        let lam: number = 0;

        for (let k: number = 0; k < Kpq.length; k++) {
            phi += Kpq[k] * Math.pow(dX, Kp[k]) * Math.pow(dY, Kq[k]);
        }

        phi = phi0 + (phi / 3600);

        for (let l: number = 0; l < Lpq.length; l++) {
            lam += Lpq[l] * Math.pow(dX, Lp[l]) * Math.pow(dY, Lq[l]);
        }

        lam = lam0 + (lam / 3600);
    
        return [phi, lam]
    }

    // Based on: https://stackoverflow.com/a/46814952/283851
    // Based on: https://gist.github.com/mindplay-dk/72f47c1a570e870a375bd3dbcb9328fb

    /**
     * Create a Base64 Image URL, with rotation applied to compensate for EXIF orientation, if needed.
     *
     * Set a new orientation tot the image
     *
     * Images without exif return null (they dont need their orientation overwritten or image rotated)
     *
     * Optionally resize to a smaller maximum width - to improve performance for larger image thumbnails.
     */
    public static getRotatedImageUrl(file: File, newOrientation:number, maxWidth: number|undefined, quality:number): Promise<string> {
        return this.readOrientation(file, newOrientation).then( (fileInfo:any) => this.applyRotation(fileInfo, maxWidth || 999999, quality));
    }

    /**
     * @returns EXIF orientation value (or undefined)
     *     // Return image orientation 1 to 8
     // http://sylvana.net/jpegcrop/exif_orientation.html
     */
    private static readOrientation = (file: File, newOrientation:number) => new Promise<any|undefined>(resolve => {
        const reader = new FileReader();

        reader.onload = () => resolve((() => {
            const view = new DataView(/** @type {ArrayBuffer} */ (reader.result) as ArrayBuffer);

            if (view.getUint16(0, false) != 0xFFD8) {
                return null;
            }

            // NOTE: Search only the beginning of the file. Heb nu maar wat gekozen
            const length = 64 * 1024; //view.byteLength;

            let offset = 2;

            while (offset < length) {
                const marker = view.getUint16(offset, false);

                offset += 2;

                if (marker == 0xFFE1) {
                    offset += 2;

                    if (view.getUint32(offset, false) != 0x45786966) {
                        return null;
                    }

                    offset += 6;

                    const little = view.getUint16(offset, false) == 0x4949;

                    offset += view.getUint32(offset + 4, little);

                    const tags = view.getUint16(offset, little);

                    offset += 2;

                    for (let i = 0; i < tags; i++) {
                        if (view.getUint16(offset + (i * 12), little) == 0x0112) {
                            let orientation:number = view.getUint16(offset + (i * 12) + 8, little);

                            if (newOrientation >= 1 && newOrientation <= 8){
                                view.setUint16(offset + (i * 12) + 8, newOrientation, true);
                            }

                            return {fileData:reader.result as ArrayBuffer, orientation:orientation};
                        }
                    }



                } else if ((marker & 0xFF00) != 0xFF00) {
                    break;
                } else {
                    offset += view.getUint16(offset, false);
                }
            }

            // No orientation found
            return null;
        })());

        //reader.readAsArrayBuffer(file.slice(0, 64 * 1024));
        reader.readAsArrayBuffer(file);
    });

    /**
     * @returns Base64 Image URL (with rotation applied to compensate for orientation, if any)
     *   1        2       3      4         5            6           7          8

     888888  888888      88  88      8888888888  88                  88  8888888888
     88          88      88  88      88  88      88  88          88  88      88  88
     8888      8888    8888  8888    88          8888888888  8888888888          88
     88          88      88  88
     88          88  888888  888888

     */
    private static applyRotation = (fileInfo:any, maxWidth: number, quality:number) => new Promise<string>(resolve => {

        // When no fileinfo is found, it might not even be an image
        if (!fileInfo){
            resolve(null);
        }

        let orientation:number = fileInfo.orientation;
        if (orientation < 1) {
            orientation = 1;
        }
        
        //const reader = new FileReader();

        //reader.onload = () => {
            //const url = reader.result as string;

            const image = new Image();

            image.onload = () => {
                const canvas = document.createElement("canvas");
                const context = canvas.getContext("2d")!;

                let { width, height } = image;

                const [outputWidth, outputHeight] = orientation >= 5 && orientation <= 8
                    ? [height, width]
                    : [width, height];

                // Pick the largest side of the picture and scale so it fits the max width/height
                let scale:number;
                if (outputWidth > outputHeight) {
                    scale = outputWidth > maxWidth ? maxWidth / outputWidth : 1;
                }else{
                    scale = outputHeight > maxWidth ? maxWidth / outputHeight : 1;
                }

                width = Math.floor(width * scale);
                height = Math.floor(height * scale);

                // to rotate rectangular image, we need enough space so square canvas is used
                const wh = Math.max(width, height);

                // set proper canvas dimensions before transform & export
                canvas.width = wh;
                canvas.height = wh;

                // for some transformations output image will be aligned to the right of square canvas
                let rightAligned = false;
                let topAligned = false;
                // transform context before drawing image
                switch (orientation) {
                    case 2:
                        context.transform(-1, 0, 0, 1, wh, 0);
                        rightAligned = true;
                        break;
                    case 3:
                        context.transform(-1, 0, 0, -1, wh, wh);
                        rightAligned = true;
                        topAligned = true;
                        break;
                    case 4:
                        context.transform(1, 0, 0, -1, 0, wh);
                        break;
                    case 5:
                        context.transform(0, 1, 1, 0, 0, 0);
                        break;
                    case 6:
                        context.transform(0, 1, -1, 0, wh, 0);
                        rightAligned = true;
                        break;
                    case 7:
                        context.transform(0, -1, -1, 0, wh, wh);
                        rightAligned = true;
                        break;
                    case 8:
                        context.transform(0, -1, 1, 0, 0, wh);
                        break;
                    default:
                        break;
                }

                context.drawImage(image, 0, 0, width, height);

                // copy rotated image to output dimensions and export it
                const canvas2 = document.createElement("canvas");
                canvas2.width = Math.floor(outputWidth * scale);
                canvas2.height = Math.floor(outputHeight * scale);
                const ctx2 = canvas2.getContext("2d");
                const sx = rightAligned ? canvas.width - canvas2.width : 0;
                const sy = topAligned ? canvas.height - canvas2.height : 0;
                ctx2.drawImage(canvas, sx, sy, canvas2.width, canvas2.height, 0, 0, canvas2.width, canvas2.height);

                // NOTE: export base64. mimetype bepaalt compressie. De quality loopt van 0 tot 1 (voor compressable images zoals jpeg)
                resolve(canvas2.toDataURL("image/jpeg", quality));
            };

            //image.src = url;

        let mimeType:string = 'image/jpeg';
        const blob = new Blob([fileInfo.fileData], {type: mimeType});
        //const blob = new Blob([fileInfo.fileData]);
        image.src = URL.createObjectURL(blob);
       // };

        //reader.readAsDataURL(file);
    });
    
    public static b64toBlob(b64Data:any, contentType:string = '', sliceSize:number = 512) {
        
        let byteCharacters;
        
        byteCharacters = atob(b64Data);
        
        const byteArrays = [];
        
        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);
            
            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            
            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }
        
        const blob = new Blob(byteArrays, {type: contentType});
        return blob;
    }


    public static compareTwoStrings(first:string, second:string):number {
        first = first.replace(/\s+/g, '')
        second = second.replace(/\s+/g, '')

        if (first === second) { return 1; } // identical or empty
        if (first.length < 2 || second.length < 2) { return 0; } // if either is a 0-letter or 1-letter string

        let firstBigrams = new Map();
        for (let i = 0; i < first.length - 1; i++) {
            const bigram = first.substring(i, i + 2);
            const count = firstBigrams.has(bigram)
                ? firstBigrams.get(bigram) + 1
                : 1;

            firstBigrams.set(bigram, count);
        }

        let intersectionSize = 0;
        for (let i = 0; i < second.length - 1; i++) {
            const bigram = second.substring(i, i + 2);
            const count = firstBigrams.has(bigram)
                ? firstBigrams.get(bigram)
                : 0;

            if (count > 0) {
                firstBigrams.set(bigram, count - 1);
                intersectionSize++;
            }
        }

        return (2.0 * intersectionSize) / (first.length + second.length - 2);
    }

    public static findBestMatch(mainString:string, targetStrings:string[]):IBestMatch {
        if (!Utils.areArgsValid(mainString, targetStrings)) { throw new Error('Bad arguments: First argument should be a string, second should be an array of strings'); }

        const ratings:IRating[] = [];
        let bestMatchIndex = 0;

        for (let i = 0; i < targetStrings.length; i++) {
            const currentTargetString = targetStrings[i];
            const currentRating = Utils.compareTwoStrings(mainString, currentTargetString)
            ratings.push({title: currentTargetString, rating: currentRating})
            if (currentRating > ratings[bestMatchIndex].rating) {
                bestMatchIndex = i
            }
        }


        const bestMatch = ratings[bestMatchIndex]

        return { ratings: ratings, bestMatch: bestMatch, bestMatchIndex: bestMatchIndex };
    }

    public static findRelatedArticles(sourceArticleTitle:string,articleList:IArticleList[]):IBestMatch{
        const ratings:IRating[] = [];
        let bestMatchIndex = 0;

        articleList.map((_article, index) => {
            const currentRating = Utils.compareTwoStrings(sourceArticleTitle, _article.title)
            ratings.push({articleId: _article.articleId, title: _article.title, rating: currentRating})
            if (currentRating > ratings[bestMatchIndex].rating) {
                bestMatchIndex = index
            }
        })

        const bestMatch = ratings[bestMatchIndex]

        return { ratings: ratings, bestMatch: bestMatch, bestMatchIndex: bestMatchIndex };
    }

    private static areArgsValid(mainString:string, targetStrings:string[]):boolean {
        if (typeof mainString !== 'string') { return false; }
        if (!Array.isArray(targetStrings)) { return false; }
        if (!targetStrings.length) { return false; }
        return !targetStrings.find(function (s) {
            return typeof s !== 'string'
        });

    }
}

export interface IRating {
    articleId?:number,
    title: string,
    rating: number,
}

export interface IBestMatch {
    ratings: IRating[],
    bestMatch: IRating,
    bestMatchIndex: number
}

export interface IArticleList {
    title:string,
    articleId?:number
}
