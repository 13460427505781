<div class="d-flex mt-1 flex-column w-100" [formGroup]="group">
    <div *ngFor="let child of config.attr.mapServerList" class="d-block w-100 mt-1">
        <map-server-settings
                [serverSettings]="child"
                (onDelete)="handleClickDelete(child)"
                (onEdit)="handleClickEdit(child)"
                (onSave)="handleClickSave(child, $event)">
        </map-server-settings>
    </div>
</div>
