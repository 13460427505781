import {Component, Input} from "@angular/core";

@Component({
    selector: 'required-field',
    template: `
        <ng-container *ngIf="visible"><span class="required" title="{{'Dit veld is verplicht' | translate}}" ></span></ng-container>
    `
})

export class RequiredComponent {

    @Input("visible") visible: boolean = true;

}
