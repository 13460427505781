import {MapCoreV2Component} from "../map-core-V2.component"
import {MapItem} from "./map-item";
import {drawingStates, IParallelHeading, ISelectedBaseObjectIds, mapItemTypes} from "./map-manager.interface";
import {LoggerService} from "../../../services/logger/logger.service";

export default class MapHelperManagerService {
    public highlightedMapItems: MapItem[] = [];

    constructor(private mapCoreV2:MapCoreV2Component, protected logger:LoggerService){
    }

    public handleSnapAndParallelPoints():void{
        this.setSnapPointRadiusByZoom()
        if(this.mapCoreV2.map.getZoom() >= this.mapCoreV2.mapSettings.MINIMUM_ZOOM_LEVEL_TO_TRIGGER_GRID_FUNCTIONS){
            //Set snappable points and headers to drawing manager
            this.mapCoreV2.mapDrawingManagerService.snappablePoints = this.mapCoreV2.mapLayerManagerService.getSnappablePoints()
            this.logger.log('[Grid][MapHelperManagerService] ' + 'Number of snappable points: '+this.mapCoreV2.mapDrawingManagerService.snappablePoints.length)

            this.clearSnappedPolylines()
            this.mapCoreV2.mapDrawingManagerService.parallelHeadings = this.mapCoreV2.mapLayerManagerService.getParallelHeadings()
            this.logger.log('[Grid][MapHelperManagerService] ' + 'Number of parallel headings: '+this.mapCoreV2.mapDrawingManagerService.parallelHeadings.length)
        }
    }

    public clearSnappedPolylines():void{
        this.mapCoreV2.mapDrawingManagerService.snappedPolylines.map(_snappedPolyline =>{
            _snappedPolyline.lineSegmentPoly.setMap(null)
        });
        this.mapCoreV2.mapDrawingManagerService.snappedPolylines = [];
    }

    public handleSuperZoom(zoomLevel:number = null):void{
        //Check if zoom level is higher than the super zoom level start. If so, enable super zoom.
        const zoomLevelToCheck = (zoomLevel !== null) ? zoomLevel : this.mapCoreV2.map.getZoom()
        if(zoomLevelToCheck < this.mapCoreV2.mapSettings.ZOOM_LEVEL_TO_ENTER_SUPER_ZOOM){
            this.disableSuperZoom()
            this.setGoogleMapsScaleControl(true)
        } else {
            this.enableSuperZoom()
            this.setGoogleMapsScaleControl(false)
        }
    }

    public handleAnnotations(zoomLevel:number = null):void{
        //Check if zoom level is higher than the annotation info window start. If so, show annotations.
        const zoomLevelToCheck = (zoomLevel !== null) ? zoomLevel : this.mapCoreV2.map.getZoom()
        if(zoomLevelToCheck < this.mapCoreV2.mapSettings.MINIMUM_ZOOM_LEVEL_TO_TRIGGER_GRID_FUNCTIONS){
            this.mapCoreV2.mapLayerManagerService.toggleAnnotations(false)
        } else {
            this.mapCoreV2.mapLayerManagerService.toggleAnnotations(true)
        }
    }

    public selectMultipleMapItems(mapItem: MapItem){
        this.logger.log('[Grid][MapHelperManagerService] ' + 'Select multiple map items')
        const mapItemBaseObjectId = mapItem.getBaseObjectId().toString()
        const selectedBaseObjectIds:ISelectedBaseObjectIds[] = this.mapCoreV2.model.assetsSelectedItems.value;
        const newBaseObjectIdList:ISelectedBaseObjectIds[] = selectedBaseObjectIds;
        const indexOfBaseObjectIdInSelectedArray = selectedBaseObjectIds.map(_baseObjectId => _baseObjectId.baseObjectId).indexOf(mapItemBaseObjectId);
        const indexOfBaseObjectIdInHighlightedArray = this.highlightedMapItems.map(_mapItem => _mapItem.getBaseObjectId().toString()).indexOf(mapItemBaseObjectId);

        if(indexOfBaseObjectIdInSelectedArray !== -1){
            //Is selected, remove from array and deselect
            this.logger.log('[Grid][MapHelperManagerService] ' + 'Clicked map item is already selected, deselect')
            newBaseObjectIdList.splice(indexOfBaseObjectIdInSelectedArray, 1);
            this.highlightedMapItems.splice(indexOfBaseObjectIdInHighlightedArray,1);
            mapItem.highlight(false);
            mapItem.setSelected(false)
        } else {
            //Not selected, add to array and highlight
            this.logger.log('[Grid][MapHelperManagerService] ' + 'Clicked map item is not yet selected, select')
            newBaseObjectIdList.push({baseObjectId:mapItemBaseObjectId})
            this.highlightedMapItems.push(mapItem);
            mapItem.highlight(true);
            mapItem.setSelected(true)
        }
        //Submit new list of baseObjectIds
        this.mapCoreV2.model.assetsSelectedItems.next(newBaseObjectIdList);

        //Create custom list to be able to emit to old code
        const selectedPolylines: any[] = []
        newBaseObjectIdList.map(_baseObjectId => {
            selectedPolylines.push(<any>
                //Make a new selectionobject
                {
                    dataRef: {baseObjectId: _baseObjectId.baseObjectId},
                    baseObjectId: _baseObjectId.baseObjectId,
                },
            );
        })
        //Emit to old V1 code
        this.mapCoreV2.onSelectMarkersEmit(selectedPolylines, true)
    }


    public selectSingleMapItem(mapItem: MapItem){
        this.logger.log('[Grid][MapHelperManagerService] ' + 'Select single map item')
        //Remove highlight and deselect all highlighted items
        this.highlightedMapItems.forEach((_mapItem: MapItem) => {
            _mapItem.highlight(false)
            _mapItem.setSelected(false)
        });
        this.highlightedMapItems = [];

        //Highlight and select clicked mapitem
        mapItem.highlight(true);
        mapItem.setSelected(true)
        this.highlightedMapItems.push(mapItem);

        //Close edit mode for previous selected item
        if(this.mapCoreV2.isGridModeActive){
            this.closeEditModeMapItem()
        }

        //Set current selectedMapItem so it can be made editable if button is clicked
        this.mapCoreV2.selectedMapItem = mapItem;

        //Create custom list to be able to emit to old code
        const selectedPolyline: any = [{
            dataRef: {baseObjectId: mapItem.getBaseObjectId()},
            baseObjectId: mapItem.getBaseObjectId(),
        }]
        //Emit to old V1 code
        this.mapCoreV2.onSelectMarkersEmit(selectedPolyline, false)
    }

    public onMapitemClick(mapItem: MapItem, event, clickedFromInfoWindow:boolean = false): void {
        if(this.mapCoreV2.isGridModeActive){
            //Grid mode active
            if(this.mapCoreV2.mapItemEditPath !== null){
                //Adding or removing a point from the current edit item
                this.setState(drawingStates.DRAW_EDIT)
            }
            if(mapItem.googlePolyline && this.isInEditMode()){
                this.setDrawingType(mapItemTypes.POLYLINE)
            }
            if(!this.isInEditMode()){
                //Disable edit toolbar if enabled
                this.mapCoreV2.mapUIManagerService.toggleEditOptionsToolbar(false)
                //Remove active class for edit button
                this.mapCoreV2.mapUIManagerService.toggleEditButtonActive(false)
                //Enable edit button
                this.mapCoreV2.mapUIManagerService.toggleEditButtonEnabled(true)

                if(this.mapCoreV2.mapEventManagerService.isCtrlKeyActive){ //Multi select
                    this.selectMultipleMapItems(mapItem);
                } else { //Single select
                    this.selectSingleMapItem(mapItem);
                }
            } else {
                if(this.mapCoreV2.mapItemEditPath === 'add'){ //Add point
                    this.mapCoreV2.mapDrawingManagerService.addPointToPolyline(event)
                } else if(this.mapCoreV2.mapItemEditPath === 'remove'){ //Remove point
                    this.mapCoreV2.mapDrawingManagerService.removePointFromPolyline(event)
                }
            }
        } else {
            //Grid mode not active
            let continueItemSelect:boolean = false;
            //In mobile mode, open infoWindow if not clicked from link inside infoWindow
            if(this.mapCoreV2.isMobileMode()){
                if(!clickedFromInfoWindow){
                    mapItem.showInfoWindow(event)
                    continueItemSelect = false
                } else {
                    mapItem.hideInfoWindow()
                    continueItemSelect = true
                }
            } else {
                continueItemSelect = true;
            }

            if(continueItemSelect){
                if(this.mapCoreV2.mapEventManagerService.isCtrlKeyActive){ //Multi select
                    this.selectMultipleMapItems(mapItem);
                } else { //Single select
                    this.selectSingleMapItem(mapItem);
                }
            }
        }
    }

    public onAnnotationItemClick(mapItem: MapItem): void{
        mapItem.setSelected(true)
        mapItem.highlight(true)
        //Create custom list to be able to emit to old code
        const selectedAnnotation: any = [{
            dataRef: {baseObjectId: mapItem.getBaseObjectId()},
            baseObjectId: mapItem.getBaseObjectId(),
        }]
        //Emit to old V1 code
        this.mapCoreV2.onSelectMarkersEmit(selectedAnnotation, false)
    }

    public onCableItemClick(baseObjectId: number): void{
        //Create custom list to be able to emit to old code
        const selectedCable: any = [{
            dataRef: {baseObjectId: baseObjectId},
            baseObjectId: baseObjectId,
        }]
        //Emit to old V1 code
        this.mapCoreV2.onSelectMarkersEmit(selectedCable, false)
    }

    public isLocationOnEdgeMeter(polyLine, point: google.maps.LatLng, meters, curHeading) {
        const offsetPoint = google.maps.geometry.spherical.computeOffset(point, meters, curHeading)
        const toleranceLng = Math.abs(point.lng() - offsetPoint.lng());
        const toleranceLat = Math.abs(point.lat() - offsetPoint.lat());
        return google.maps.geometry.poly.isLocationOnEdge(point, polyLine, (toleranceLng + toleranceLat) / 2)
    }

    public getSnappablePoint(latLng: google.maps.LatLng) {
        const minSnapDistance = this.getDistanceToMapItemByZoom()
        let closestPoint = null;
        let closestDist = null;

        this.logger.log('[Grid][MapHelperManagerService] ' + 'Get snappable points, searching in '+ this.mapCoreV2.mapDrawingManagerService.snappablePoints.length + ' snappable points')
        this.mapCoreV2.mapDrawingManagerService.snappablePoints.map(_snappablePoint => {
            const distanceToMouse = google.maps.geometry.spherical.computeDistanceBetween(latLng, _snappablePoint.latLng);

            //Only look at points within snappable distance
            if (distanceToMouse < minSnapDistance) {
                if (closestDist === null) {
                    closestPoint = _snappablePoint;
                    closestDist = distanceToMouse;
                } else if (distanceToMouse < closestDist) {
                    closestPoint = _snappablePoint;
                    closestDist = distanceToMouse;
                }
            }
        })

        return closestPoint;
    }

    public getSnappableHeading(startingPosition, latLng: google.maps.LatLng) {
        let closestHeading: IParallelHeading = null;
        let closestHeadingDeviation = this.mapCoreV2.mapSettings.PARALLEL_HEADING_DEVIATION;
        const minSnapHeading = this.mapCoreV2.mapSettings.PARALLEL_MINIMAL_SNAP_HEADING;

        this.logger.log('[Grid][MapHelperManagerService] ' + 'Get parallel heading, searching in '+ this.mapCoreV2.mapDrawingManagerService.parallelHeadings.length + ' parallel headings')
        const cursorHeading = google.maps.geometry.spherical.computeHeading(startingPosition, latLng);
        this.mapCoreV2.mapDrawingManagerService.parallelHeadings.map(_parallelHeading => {
            //Parallel heading is not on edge
            if (!this.isLocationOnEdgeMeter(_parallelHeading.lineSegmentPoly, latLng, this.getParallelDistanceSnapping(), cursorHeading)) {
                return
            }
            const deviation = Math.abs(cursorHeading - _parallelHeading.heading);
            if (deviation < minSnapHeading) {
                if (closestHeading === null || deviation < closestHeadingDeviation) {
                    //Set parallel head as closest heading
                    closestHeading = _parallelHeading
                    closestHeadingDeviation = deviation
                }
            }
        })

        return closestHeading
    }

    public getPointOnLineSegment(latLng: google.maps.LatLng) {
        let closestHeading: IParallelHeading = null;

        this.mapCoreV2.mapDrawingManagerService.parallelHeadings.map(_parallelHeading => {
            if (this.isLocationOnEdgeMeter(_parallelHeading.lineSegmentPoly, latLng, this.getDistanceToLineByZoom(), _parallelHeading.heading)) {
                //Set parallel head as closest heading
                closestHeading = _parallelHeading;
            }
        })
        this.logger.log('[Grid][MapHelperManagerService] ' + 'Get point on line segment')

        return closestHeading
    }


    public updateCurrentPolylineLength() {
        if (this.mapCoreV2.polylineDrawingHead.getPath().getArray().length > 1) {
            //Update the polyline length when drawing has started
            const polyLength = google.maps.geometry.spherical.computeLength(this.mapCoreV2.polylineDrawingHead.getPath()).toFixed(2);
            this.mapCoreV2.mapUIManagerService.updatePolylineLength(Number(polyLength))
        }
    }

    public openEditModeMapItem(): void {
        this.logger.log('[Grid][MapHelperManagerService] ' + 'Open edit mode for map item')
        this.mapCoreV2.selectedMapItem.setEditable(true)
        this.mapCoreV2.selectedMapItem.highlight(false)
    }

    public closeEditModeMapItem():void{
        this.logger.log('[Grid][MapHelperManagerService] ' + 'Close edit mode for map item')
        if(this.mapCoreV2.selectedMapItem !== null){
            this.mapCoreV2.selectedMapItem.setEditable(false)
        }
        this.setState(drawingStates.DRAW_NEW)
    }

    public latLngPositionForFixedLength(startPosition: google.maps.LatLng, heading: number): google.maps.LatLng {
        const fixedLength = this.mapCoreV2.isInSuperZoom ? (this.mapCoreV2.polylineDrawingHeadFixedLength / 100) : this.mapCoreV2.polylineDrawingHeadFixedLength //If superzoom, use cm instead of meters
        return google.maps.geometry.spherical.computeOffset(startPosition, fixedLength, heading)
    }

    public isInDrawingMode() {
        return this.mapCoreV2.activeDrawingType !== null ? true : false;
    }

    public isInPolylineAlterMode(){
        return this.mapCoreV2.mapItemEditPath !== null ? true : false
    }

    public isInEditMode(){
        return (this.mapCoreV2.drawState !== drawingStates.DRAW_EDIT && this.mapCoreV2.drawState !== drawingStates.DRAW_EDIT_HEAD) ? false : true
    }

    public setState(state: drawingStates): void {
        this.logger.log('[Grid][MapHelperManagerService] ' + 'Set new state: '+state)
        this.mapCoreV2.drawState = state;
    }

    public setDrawingType(drawingType: mapItemTypes | null) {
        this.logger.log('[Grid][MapHelperManagerService] ' + 'Set new drawing type: '+drawingType)
        this.mapCoreV2.activeDrawingType = drawingType;
        if (drawingType !== null) {
            //Disable click for mapItems
            this.mapCoreV2.mapLayerManagerService.setAllMapItemsClickable(false)
            //Disable poi's for the map
            this.mapCoreV2.setPOIVisible(false)
            //Set draggable cursor to crosshair
            this.setGoogleMapsDraggableCursor('crosshair')

            if(!this.mapCoreV2.mapHelperManagerService.isInEditMode()){
                this.mapCoreV2.mapUIManagerService.toggleDrawingOptionsToolbar(true)
                //Close mapitem edit mode should is have been set
                this.mapCoreV2.mapUIManagerService.toggleEditOptionsToolbar(false);
                //Disable edit button
                this.mapCoreV2.mapUIManagerService.toggleEditButtonEnabled(false)
                //Hide polyline
                this.mapCoreV2.mapUIManagerService.showHideLinedrawControls();
                this.mapCoreV2.markerDrawing.setMap(null)
                this.setState(drawingStates.DRAW_NEW)
            } else {
                this.mapCoreV2.mapUIManagerService.toggleDrawingOptionsToolbar(false)
                //Close mapitem edit mode should is have been set
                this.mapCoreV2.mapUIManagerService.toggleEditOptionsToolbar(true);
                //Disable edit button
                this.mapCoreV2.mapUIManagerService.toggleEditButtonEnabled(false)
                //Hide polyline
                this.mapCoreV2.mapUIManagerService.showHideLinedrawControls();
                this.mapCoreV2.markerDrawing.setMap(null)
            }
        } else {
            //Enable click for mapItems again
            this.mapCoreV2.mapLayerManagerService.setAllMapItemsClickable(true)
            //Set back the original poi setting
            this.mapCoreV2.setPOIVisible(this.mapCoreV2.originalSelectedPOIVisible)
            //Set draggable cursor to default (empty = default)
            this.setGoogleMapsDraggableCursor('')
        }
    }

    public showHighlightPoint(LatLng) {
        if(this.mapCoreV2.activeDrawingType !== mapItemTypes.JOINT){
            this.mapCoreV2.mapDrawingManagerService.highlightPoint.setCenter(LatLng)
            this.mapCoreV2.mapDrawingManagerService.highlightPoint.setMap(this.mapCoreV2.map)
        }
    }

    public hideHighlightPoint() {
        this.mapCoreV2.mapDrawingManagerService.highlightPoint.setMap(null)
    }

    public setSelectedLayerId(layerId:number):void{
        this.logger.log('[Grid][MapHelperManagerService] ' + 'Set selected layer id: '+layerId)
        this.mapCoreV2.mapDrawingManagerService.selectedLayerId = layerId
    }
    public getSelectedLayerId():number{
        return this.mapCoreV2.mapDrawingManagerService.selectedLayerId
    }
    public getSelectedLayerName():string{
        let layerName:string = '';
        this.mapCoreV2.mapLayerManagerService.layers.map(_layer => {
            if(_layer.layerId === this.getSelectedLayerId()){
                layerName = _layer.layerName
            }
        })
        return layerName;
    }
    public getLayerName(layerId:number):string{
        let layerName:string = this.mapCoreV2.ts.translate('grid.infoWindow.unknown');
        this.mapCoreV2.mapLayerManagerService.layers.map(_layer => {
            if(_layer.layerId === layerId){
                layerName = _layer.layerName
            }
        })
        return layerName;
    }
    public setSelectedStyleId(styleId:number):void{
        this.logger.log('[Grid][MapHelperManagerService] ' + 'Set selected style id: '+styleId)
        this.mapCoreV2.mapDrawingManagerService.selectedStyleId = styleId
    }
    public getSelectedStyleId():number{
        return this.mapCoreV2.mapDrawingManagerService.selectedStyleId
    }
    public getSelectedStyleName():string{
        let styleName:string = '';
        this.mapCoreV2.model.mapItemStyles.value.map(_style => {
            if(_style.id === this.getSelectedStyleId()){
                styleName = _style.name
            }
        })
        return styleName;
    }
    public getStyleName(styleId:number):string{
        let styleName:string = this.mapCoreV2.ts.translate('grid.infoWindow.unknown');
        this.mapCoreV2.model.mapItemStyles.value.map(_style => {
            if(_style.id === styleId){
                styleName = _style.name
            }
        })
        return styleName;
    }


    public highlightPolyline(highlight: boolean, path?: google.maps.LatLng[], strokeWeight?: number, zIndex?: number): void {
        if (highlight) {
            this.mapCoreV2.highlightLine.set('strokeWeight', Number(strokeWeight) + this.mapCoreV2.mapSettings.HIGHLIGHT_LINE_WEIGHT_ADDITION)
            this.mapCoreV2.highlightLine.set('zIndex', zIndex - 1)
            this.mapCoreV2.highlightLine.setPath(path)
            this.mapCoreV2.highlightLine.setMap(this.mapCoreV2.map);
        } else {
            this.mapCoreV2.highlightLine.setMap(null);
        }
    }

    public getDistanceToMapItemByZoom(): number {
        let distanceToMapItem = this.mapCoreV2.mapSettings.DEFAULT_DISTANCE_TO_MAP_ITEM_TRIGGER;
        this.mapCoreV2.mapSettings.DISTANCE_TO_MAP_ITEM_TRIGGER.map(column => {
            if (column[0] == this.mapCoreV2.map.getZoom()) {
                distanceToMapItem = column[1];
            }
        })
        this.logger.log('[Grid][MapHelperManagerService] ' + 'Distance to map item by zoom set to: '+distanceToMapItem)
        return distanceToMapItem;
    }

    public getDistanceToLineByZoom(): number {
        let distanceToLine = this.mapCoreV2.mapSettings.DEFAULT_DISTANCE_TO_LINE_TRIGGER;
        this.mapCoreV2.mapSettings.DISTANCE_TO_LINE_TRIGGER.map(column => {
            if (column[0] == this.mapCoreV2.map.getZoom()) {
                distanceToLine = column[1];
            }
        })
        this.logger.log('[Grid][MapHelperManagerService] ' + 'Distance to line by zoom set to: '+distanceToLine)
        return distanceToLine;
    }

    public getParallelDistanceSnapping(): number {
        let distanceToLine = this.mapCoreV2.mapSettings.DEFAULT_PARALLEL_DISTANCE_TO_LINE_TRIGGER;
        this.mapCoreV2.mapSettings.DISTANCE_PARALLEL_TO_LINE_TRIGGER.map(column => {
            if (column[0] == this.mapCoreV2.map.getZoom()) {
                distanceToLine = column[1];
            }
        })
        this.logger.log('[Grid][MapHelperManagerService] ' + 'Parallel distance to line by zoom set to: '+distanceToLine)
        return distanceToLine;
    }

    public setSnapPointRadiusByZoom():void {
        let radiusToSet:number = this.mapCoreV2.mapSettings.DEFAULT_SNAP_HIGHLIGHT_POINT_SIZE;
        this.mapCoreV2.mapSettings.SNAP_HIGHLIGHT_POINT_SIZE.map(column => {
            if (column[0] == this.mapCoreV2.map.getZoom()) {
                radiusToSet = column[1]
            }
        })
        this.logger.log('[Grid][MapHelperManagerService] ' + 'Snap point radius by zoom set to: '+radiusToSet)
        this.mapCoreV2.mapDrawingManagerService.highlightPoint.setRadius(radiusToSet);
    }

    public mutateTotalSelectedItems(mutation:number):void{
        //Keep track of total selected items to enable or disable edit button since the edit button can only be enabled if a single line has been selected
        this.mapCoreV2.totalSelectedItems = this.mapCoreV2.totalSelectedItems + mutation
        this.logger.log('[Grid][MapHelperManagerService] ' + 'Total selected items mutation: '+mutation+ '. Total selected items: '+this.mapCoreV2.totalSelectedItems)
        if(this.mapCoreV2.isGridModeActive){
            if(this.mapCoreV2.totalSelectedItems === 1){
                this.mapCoreV2.mapUIManagerService.toggleEditButtonEnabled(true)
            } else {
                this.mapCoreV2.mapUIManagerService.toggleEditButtonEnabled(false)
            }
        }
    }

    public randomId(): number {
        return Math.floor(Math.random() * 9999)
    }

    private enableSuperZoom(){
        this.logger.log('[Grid][MapHelperManagerService] ' + 'Enter super zoom')
        this.mapCoreV2.map.setOptions({mapTypeId: 'superzoom'})
        this.mapCoreV2.isInSuperZoom = true;
        this.mapCoreV2.mapUIManagerService.setLengthIndicator(this.mapCoreV2.ts.translate('grid.centimeterLengthIndicator'));
    }

    private disableSuperZoom(){
        this.logger.log('[Grid][MapHelperManagerService] ' + 'Exit super zoom')
        this.mapCoreV2.map.setOptions({mapTypeId: this.mapCoreV2.originalSelectedMapTypeId})
        this.mapCoreV2.isInSuperZoom = false;
        this.mapCoreV2.mapUIManagerService.setLengthIndicator(this.mapCoreV2.ts.translate('grid.meterLengthIndicator'));
    }

    public zoomToLevel(zoomLevel:number, focusOnMouseCenter:boolean):void{
        if(this.mapCoreV2.mousePosition.isMouseOnCanvas){
            this.handleSuperZoom(zoomLevel)
            this.mapCoreV2.map.setZoom(zoomLevel)

            if(focusOnMouseCenter){
                this.mapCoreV2.map.setCenter(this.mapCoreV2.mousePosition.currentMouseLatLng)
            }
        }
    }

    public setGoogleMapsDraggableCursor(cursor:string):void{
        this.mapCoreV2.map.setOptions({
            draggableCursor: cursor
        })
    }

    public setGoogleMapsScaleControl(showScale:boolean):void{
        this.mapCoreV2.map.setOptions({
            scaleControl: showScale
        })
    }
}
