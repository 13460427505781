<div style="display: flow-root" *ngIf="specifications?.length > 0">
    <div class="d-flex float-left" *ngIf="termijnstaatFinishedText">
        <div class="form-log-container">
            {{termijnstaatFinishedText}}
        </div>
    </div>
    <div class="d-flex float-right">
        <form-component-label [config]="{label:'specification.total' | translate}">
        </form-component-label>
        <div class="md-form input-group p-0 m-0">
            {{total | currency:'EUR'}}
        </div>
    </div>
</div>
<ng-container *ngFor="let specification of specifications">
    <complex-list-item class="{{specification.isNew?'animated fadeIn':''}}"
                       [style.color]="specification.isDeleted?AppSettings.getColor('$selection-gray'):''"
                       [item]="specification.listItem"
                       [readonly]="disabled()"
                       (onMenuItemClick)="onSpecificationAction($event, specification)">
        <key-value [key]="'specification.amount' | translate"
                   [value]="specification.amount">
        </key-value>
        <key-value [key]="'specification.price' | translate"
                   [value]="specification.price | currency:'EUR'">
        </key-value>
        <key-value  *ngIf="specification?.description"
                    [key]="'specification.description' | translate"
                    [value]="specification.description">
        </key-value>

        <div class="form-attachment-loader {{specification.isLoading?'':'d-none'}}">
            <div class="progress">
                <div class="indeterminate"></div>
            </div>
        </div>
    </complex-list-item>
</ng-container>
<div class="form-log-container">
    <div *ngIf="!specifications || specifications.length <= 0">
        {{'specification.nospecifications' | translate}}
    </div>
</div>
