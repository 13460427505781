import {Injectable} from '@angular/core';
import {DataType, Header, Row} from './baseTable.interface';
import {CheckActivityCellChild} from './table-cell/check-activity-cell-child/check-activity-cell-child.interface';
import {ExportService} from '../../../../services/export/export.service';
import {LoggerService} from "../../../../services/logger/logger.service";

@Injectable()
export class BaseTableExportService{
    constructor(private exportService: ExportService, protected logger:LoggerService) {
    }

    public exportToCsv(headers: Header[], rows: Row[], universal = true): void {
        let csvFile: Blob;
        let downloadLink: HTMLAnchorElement;
        let tableData: string;

        // Get all visible table data
        if (universal) {
            // Follow universal CVS rules
            tableData = this.getPlainTableData(headers, rows, ',', '\n', true);
        } else {
            // Do it the Excel way
            tableData = 'sep=' + '\n' + this.getPlainTableData(headers, rows, ';', '\n', true);
        }

        // CSV file
        csvFile = new Blob([tableData], {type: 'text/csv'});

        // Download link
        downloadLink = document.createElement('a');

        // File name
        downloadLink.download = 'table_export_' + this.exportService.getExportFileNamePostfix() + '.csv';

        // Create a link to the file
        downloadLink.href = window.URL.createObjectURL(csvFile);

        // Hide download link
        downloadLink.style.display = 'none';

        // Add the link to DOM
        document.body.appendChild(downloadLink);

        // Click download link
        downloadLink.click();
    }

    public exportToExcel(headers: Header[], rows: Row[]): void {
        let tableData: string = this.getPlainTableData(headers, rows, '</td><td>', '</td></tr><tr><td>', false);
        this.exportService.exportToExcel(tableData);
    }

    public copyToClipboard(headers: Header[], rows: Row[]): void {
        // Evade IDE error
        let nav: Navigator = navigator;
        let clipboardData = this.getPlainTableData(headers, rows);

        if (nav.clipboard) {
            this.logger.log('[TableComponent] ' + 'copy via new clipboard API');

            nav.clipboard.writeText(clipboardData).then(
                () => {
                    /* clipboard successfully set */
                },
                () => {
                    /* clipboard write failed */
                },
            );
        } else {
            this.logger.log('[TableComponent] ' + 'Fallback copy');
            // Solution for browser problem here: https:// hackernoon.com/copying-text-to-clipboard-with-javascript-df4d4988697f
            let el = document.createElement('textarea');
            el.value = this.getPlainTableData(headers, rows);
            el.setAttribute('readonly', '');
            el.style.position = 'absolute';
            el.style.left = '-9999px';
            document.body.appendChild(el);
            el.select();
            document.execCommand('copy');
            document.body.removeChild(el);
        }
    }

    // Get the data in a way it's representable for the clipboard (columns separated, rows on new line)
    private getPlainTableData(headers: Header[], rows: Row[], columnSeparator = '\t', rowSeparator = '\n', escapeQuotes = false): string {

        let result = '';

        if (headers) {
            for (let i = 0; i < headers.length; i++) {
                if (headers[i].isVisible) {
                    if (result !== '') {
                        result += columnSeparator;
                    }
                    result += headers[i].label;
                }
            }

            // For every row
            for (let i = 0; i < rows.length; i++) {

                // When not removed by a filter on this row
                if (rows[i].isVisible) {

                    // Add a new line for every new row
                    result += rowSeparator;

                    // For every cell
                    for (let j = 0; j < rows[i].cells.length; j++) {
                        if (rows[i].cells[j].isVisible) {
                            let label = rows[i].cells[j].label;
                            // Add a cell and tab
                            if (rows[i].cells[j].dataType === DataType.COMPLEX) {
                                label = rows[i].cells[j].children.reduce((string, child, index) => {
                                    let childLabel = child.label;
                                    if (child.dataType === DataType.CHECK_ACTIVITY) {
                                        let checkActivityChild: CheckActivityCellChild = child as CheckActivityCellChild;
                                        childLabel = (checkActivityChild.isDone ? '\u2713' : '\u25CB') + (checkActivityChild.hasAttention ? '! ' : ' ') + child.label;
                                    }
                                    return string + (index > 0 ? ', ' : '') + childLabel;
                                }, (label && label !== 'undefined') ? label + ': ' : '');
                            }

                            // When chars need CSV escaping and text contains ' or ;
                            if (escapeQuotes && (String(label)
                                .includes('"') || String(label)
                                .includes('\n') || String(label)
                                .includes('\t') || String(label)
                                .includes(columnSeparator))
                            ) {
                                // Translate: test "123"   To: "test ""123"""   (outer quotes + double quotes as escape for CSV files)
                                result += '"' + label.replace(/"/g, '""') + '"';
                            } else {
                                result += label;
                            }

                            if (j < rows[i].cells.length - 1) {
                                result += columnSeparator;
                            }
                        }
                    }
                }
            }
        }
        return result;
    }
}
