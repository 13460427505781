import {ChangeDetectorRef, Component, HostBinding, Renderer2} from "@angular/core";
import {ValidationConstraintService} from "../../services/validation-constraint.service";
import {GlobalAlertService} from "../../../../../wrapper/global-alert/global-alert.service";
import {TooltipService} from "../../../../services/tooltip/tooltip.service";
import {FormDataService} from "../../services/form-data.service";
import {Router} from "@angular/router";
import {HTTPService} from "../../../../services/http/http.service";
import {TranslateService} from "../../../../services/translate/translate.service";
import {FormInputComponent} from "../form-input/form-input.component";
import {LoggerService} from "../../../../services/logger/logger.service";
import {GlobalModel} from '../../../../services/state/global.model';

@Component({
    selector: 'form-auth-table',
    template: `
            <label for="{{config.name}}">{{ config.label }}</label>
            
            <div class="w-100 mx-auto" style="overflow-x: auto;">
                <div class="auth-table-component-container">
                    <table *ngIf="tableData" class="table table-hover auth-table" style="width:inherit; min-width: 500px">
                        <thead>
                            <tr>
                                <th scope="col" class="auth-table-header"></th>
                                <th *ngFor="let column of columnData" scope="col" class="auth-table-header">{{column.name}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngFor="let module of tableData.modules">
                                <tr>
                                    <th (click)="handleClickExpandModule($event, module)" class="auth-table-header pl-0 main-row" [attr.colspan]="columnData.length + 1">
                                        <i class="material-icons expand-button mr-2 px-2 py-0">{{module.expanded?'expand_more':'keyboard_arrow_right'}}</i>
                                        {{module.label}}</th>
                                </tr>
                                <ng-container *ngIf="module.expanded">
                                <ng-container *ngFor="let form of module.forms">
                                    <tr>
                                        <th class="auth-table-header tabbed-row" [attr.colspan]="'unset'">{{form.name}}</th>
                                        <ng-container *ngFor="let role of form.roles">
                                            <td style="font-size:12px" title="{{getTooltip(form.name, role.name)}}">
                                                <select class="select" [(ngModel)]='role.authorization'>
                                                    <option [value]="'N'">{{'-'}}</option>
                                                    <option [value]="'R'">{{'R'}}</option>
                                                    <option [value]="'RW'">{{'RW'}}</option>
                                                </select>
                                            </td>
                                        </ng-container>
                                    </tr>
                                </ng-container>
                            </ng-container>
                            </ng-container>
                        </tbody>        
                    </table>
                </div>
            </div>
    `
})

export class AuthFormTableComponent extends FormInputComponent
{
    @HostBinding('class') hostClasses = 'd-flex flex-column';

    public config: any;

    private isLoading:boolean = false;
    public tableData:any;
    public columnData:any;

    constructor(public renderer:Renderer2, public cd:ChangeDetectorRef, public httpService:HTTPService, public validationConstraintService:ValidationConstraintService,
                public tooltipService:TooltipService, public globalAlertService: GlobalAlertService, private formDataService:FormDataService, private router:Router, public ts:TranslateService, protected logger:LoggerService, public model:GlobalModel)
    {
        super(renderer, validationConstraintService, tooltipService, globalAlertService, ts, logger, model);
    }

    ngOnInit()
    {
        if (this.config.attr && this.config.attr.url){
            this.getTableData(this.config.attr.url);
        }else{
            this.globalAlertService.addAlert(this.ts.translate("Formulier incompleet"), this.ts.translate("Geen URL meegegeven"), this.ts.translate("De tabel verwacht een URL, maar die is niet meegegeven"));
        }
    }
    
    public getTooltip(form:string, rol:string):string{
        return this.ts.translate('auth.form.tooltip', [form, rol]);
    }
    
    public handleClickExpandModule(event:any, module:any):void{
        module.expanded = !module.expanded;
    }

    private getTableData(url:string)
    {
        this.isLoading = true;

        this.formDataService.getTableData(url,
            (json: any) => {

                this.isLoading = false;
                this.tableData = json;

                if (this.tableData && this.tableData.modules){
                    this.columnData = this.tableData.modules[0].forms[0].roles;
                }

                this.group.controls[this.config.name].patchValue(this.tableData);

                this.cd.detectChanges();
            }
        );
    }
}
