<div class="full-height-wrapper">
    <div class="container">
        <div class="row">
            <div class="col-12 p-2 notifications-container">
                <div *ngFor="let notification of model.notificationList;let i = index" [attr.id]="'notification-'+notification.notificationId">
                    <div class="status-card">
                        <div class="notify-badge" *ngIf="notification.isNew"></div>
                        <div class="statusbar state-{{notification.status}}"></div>
                        <div class="inner">
                            <div class="d-flex justify-content-start align-items-top">
                                <div>
                                    <div class="image text d-flex align-items-center justify-content-center state-{{notification.status}}">
                                        {{notification.date | date : 'dd':'':'nl-NL'}}<br />{{getMonthNameShort(notification.date)}}
                                    </div>
                                </div>
                                <div class="w-100 info">
                                    <h6 [attr.aria-expanded]="activeNotificationId == notification.notificationId ? true : false"
                                        [routerLink]="'/notifications/'+notification.notificationId">
                                        {{notification.title}}
                                    </h6>
                                    <div class="date">{{notification.date | date : 'MMM dd, YYYY HH:mm':'':'nl-NL'}}</div>
                                    <div class="notification-content" [ngClass]="activeNotificationId == notification.notificationId ? 'collapsed show' : 'collapse'" [attr.id]="'message-'+i" [innerHTML]="notification.message"></div>
                                </div>
                                <div class="arrow ml-auto"
                                     [attr.aria-expanded]="activeNotificationId == notification.notificationId ? true : false"
                                     [routerLink]="'/notifications/'+notification.notificationId">
                                    <i class="material-icons">keyboard_arrow_down</i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="model.notificationList.length === 0">
                    {{'notifications.nonotifications' | translate}}
                </div>
            </div>
        </div>
    </div>
</div>