import {MapCoreV2Component} from "../map-core-V2.component";
import {drawingStates, mapItemTypes} from "./map-manager.interface";
import {LoggerService} from "../../../services/logger/logger.service";

export default class MapUIManagerService {
    constructor(private mapCoreV2: MapCoreV2Component, protected logger:LoggerService) {
    }

    public initBuildUi(): void {
        //this.logger.log('[Grid][MapUIManagerService] ' + 'Initialize ui manager')
        //Build the UI
        this.buildMainControls();
        this.buildDrawingControls();
        this.buildMapItemEditControls();
        this.buildZoomPrefixControls();
        this.mapCoreV2.mapLayerManagerService.createFocusLayer();
    }

    public destroyUi():void{
        //this.logger.log('[Grid][MapUIManagerService] ' + 'Destroy ui')
        //Remove the control bars from Google maps
        this.mapCoreV2.map.controls[google.maps.ControlPosition.TOP_CENTER].clear()
        this.mapCoreV2.map.controls[google.maps.ControlPosition.BOTTOM_CENTER].clear()
        this.mapCoreV2.map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].clear()
    }

    private buildMainControls() {
        //this.logger.log('[Grid][MapUIManagerService] ' + 'Build main control bar')
        const polylineDropdownId = this.mapCoreV2.mapHelperManagerService.randomId();
        const layersDropdownId = this.mapCoreV2.mapHelperManagerService.randomId();
        //Create HTML
        let outerDiv: HTMLDivElement = document.createElement('div');
        outerDiv.innerHTML = `
            <div class="map-drawing-manager">
                <div class="main-controls-container controls-container">
                    <div class="logo d-flex justify-content-center mb-2">
                        <img src="../../../../assets/img/logo-luminizer-grid.png" alt="${this.mapCoreV2.ts.translate('grid.ui.gridname')}" title="${this.mapCoreV2.ts.translate('grid.ui.gridname')}" class="img-fluid" />
                    </div>
                    <div class="dropdown">
                        <button type="button" class="map-drawing-button main-item draw_polyline_dropdown" id="${polylineDropdownId}" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <div class="nolink">
                                <i class="material-icons">timeline</i>
                                <i class="material-icons dropdown-arrow">arrow_drop_down</i>
                            </div>
                        </button>
                        <div class="dropdown-menu drawing-manager drawing-manager-container" aria-labelledby="${polylineDropdownId}">
                            <div class="dropdown-item-container drawing-selection-controls">
                                <div class="dropdown-item-container drawing-selection-container-selected-set-outer hide-control">
                                    <div class="drawing-selection-container-selected-set dropdown-item-container hide-control"><span class="drawing-selection-set-inner"></span></div>
                                </div>
                                <div class="drawing-selection-container-close dropdown-item-container hide-control"><i class="material-icons drawing-selection-container-closebtn">close</i></div>
                            </div>
                            <div class="drawing-selection-container available-layers dropdown-item-container prevent-close">
                                <div class="block dropdown-item-container label-item">${this.mapCoreV2.ts.translate('grid.label.linelayer')}:</div>
                                ${this.returnAvailableLayersAsButtons()}
                            </div>
                            <div class="drawing-selection-container available-styles dropdown-item-container">
                                <div class="block dropdown-item-container label-item">${this.mapCoreV2.ts.translate('grid.label.linestyle')}:</div>
                                ${this.returnAvailableStylesAsButtons()}
                            </div>
                            <button type="button" title="${this.mapCoreV2.ts.translate('grid.ui.drawLine')}" class="single-button dropdown-item function_drawline prevent-close" attr-value="POLYLINE">${this.mapCoreV2.ts.translate('grid.ui.drawLine')}</button>
                            <button type="button" title="${this.mapCoreV2.ts.translate('grid.ui.drawAnnotation')}" class="single-button dropdown-item function_draw" attr-value="ANNOTATION">${this.mapCoreV2.ts.translate('grid.ui.drawAnnotation')}</button>
                            <button type="button" title="${this.mapCoreV2.ts.translate('grid.ui.drawMof')}" class="single-button dropdown-item function_draw" attr-value="JOINT">${this.mapCoreV2.ts.translate('grid.ui.drawMof')}</button>
                            <button type="button" title="${this.mapCoreV2.ts.translate('grid.ui.drawPoint')}" class="single-button dropdown-item function_draw" attr-value="MARKER">${this.mapCoreV2.ts.translate('grid.ui.drawPoint')}</button>
                        </div>
                    </div>
                    <button type="button" title="${this.mapCoreV2.ts.translate('grid.ui.toggleEditMode')}" class="map-drawing-button function_mapitemedit" disabled="true">
                        <i class="material-icons">drive_file_rename_outline</i>
                    </button>
                    <button type="button" title="${this.mapCoreV2.ts.translate('grid.ui.toggleFocusMode')}" class="map-drawing-button function_focus"><i class="material-icons">blur_on</i></button>
                    <button type="button" title="${this.mapCoreV2.ts.translate('grid.ui.toggleInfoWindows')}" class="map-drawing-button function_infowindows active"><i class="material-icons">chat_bubble</i></button>
                    <div class="dropdown">
                        <button type="button" title="${this.mapCoreV2.ts.translate('grid.ui.showLayers')}" class="map-drawing-button last-child layers_dropdown" id="${layersDropdownId}" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="material-icons">layers</i>
                        </button>
                        <div class="dropdown-menu prevent-close drawing-manager" aria-labelledby="${layersDropdownId}">
                            ${this.returnAvailableLayers()}
                        </div>
                    </div>
                </div>
            </div>
        `;
        //Set HTML to Google maps controls
        this.mapCoreV2.map.controls[google.maps.ControlPosition.TOP_CENTER].push(outerDiv);
        //Create UI events on the HTML
        this.mapCoreV2.mapEventManagerService.setUIEvents(outerDiv, 'main');
    }

    private buildDrawingControls() {
        //this.logger.log('[Grid][MapUIManagerService] ' + 'Build drawing control bar')
        //Create HTML
        let outerDiv: HTMLDivElement = document.createElement('div');
        outerDiv.innerHTML = `
            <div class="map-drawing-manager">
                <div class="drawing-controls-container controls-container">
                    <div class="info-item info-item-long latlng-position">
                        <div class="info-value text-left"><span class="info-value-inner">lat <span class="info-lat">0</span><br />lng <span class="info-lng">0</span></span></div>
                    </div>
                    <div class="info-item polyline-length">
                        <div class="remove-fixed-length"><i class="material-icons">highlight_remove</i></div>
                        <div class="info-value"><span class="info-value-inner info-value-inner-newline" contenteditable="true">0</span> <span class="info-value-indicator">${this.mapCoreV2.ts.translate('grid.meterLengthIndicator')}</span></div>
                        <i class="material-icons rotate-90 info-value-inner-newline-icon">height</i>
                    </div>
                    <button type="button" title="${this.mapCoreV2.ts.translate('grid.ui.toggleParallel')}" class="map-drawing-button function_parallel active">
                        <i class="material-icons">stacked_line_chart</i>
                    </button>
                    <button type="button" title="${this.mapCoreV2.ts.translate('grid.ui.toggleSnappable')}" class="map-drawing-button function_snappable active">
                        <i class="material-icons">insights</i>
                    </button>
                    <button type="button" title="${this.mapCoreV2.ts.translate('grid.ui.save')}" class="function_save" disabled><i class="material-icons">check</i></button>
                    <button type="button" title="${this.mapCoreV2.ts.translate('grid.ui.cancel')}" class="function_cancel last-child" disabled><i class="material-icons">close</i></button>
                </div>
            </div>
        `;
        //Set HTML to Google maps controls
        this.mapCoreV2.map.controls[google.maps.ControlPosition.BOTTOM_CENTER].push(outerDiv);
        //Create UI events on the HTML
        this.mapCoreV2.mapEventManagerService.setUIEvents(outerDiv, 'draw');
    }

    private buildMapItemEditControls() {
        //this.logger.log('[Grid][MapUIManagerService] ' + 'Build edit control bar')
        const settingsDropdownId = this.mapCoreV2.mapHelperManagerService.randomId();
        //Create HTML
        let outerDiv: HTMLDivElement = document.createElement('div');
        outerDiv.innerHTML = `
            <div class="map-drawing-manager">
                <div class="mapitem-edit-controls-container controls-container">
                    <div class="info-item polyline-length">
                        <div class="remove-fixed-length"><i class="material-icons">highlight_remove</i></div>
                        <div class="info-value"><span class="info-value-inner info-value-inner-editline" contenteditable="true">0</span> <span class="info-value-indicator">${this.mapCoreV2.ts.translate('grid.meterLengthIndicator')}</span></div>
                        <i class="material-icons rotate-90 info-value-inner-newline-icon">height</i>
                    </div>
                    <button type="button" title="${this.mapCoreV2.ts.translate('grid.addPoint')}" class="function_addpoint">
                        <span class="combined-icon">
                            <i class="material-icons">timeline</i>
                            <i class="material-icons">add_circle</i>
                        </span>
                    </button>
                    <button type="button" title="${this.mapCoreV2.ts.translate('grid.removePoint')}" class="function_removepoint">
                        <span class="combined-icon">
                            <i class="material-icons">timeline</i>
                            <i class="material-icons">remove_circle</i>
                        </span>
                    </button>
                    <button type="button" title="${this.mapCoreV2.ts.translate('grid.ui.toggleParallel')}" class="map-drawing-button function_parallel active">
                        <i class="material-icons">stacked_line_chart</i>
                    </button>
                    <button type="button" title="${this.mapCoreV2.ts.translate('grid.ui.toggleSnappable')}" class="map-drawing-button function_snappable active">
                        <i class="material-icons">insights</i>
                    </button>
                    <button type="button" title="${this.mapCoreV2.ts.translate('grid.ui.save')}" class="function_save"><i class="material-icons">check</i></button>
                    <button type="button" title="${this.mapCoreV2.ts.translate('grid.ui.cancel')}" class="function_cancel last-child"><i class="material-icons">close</i></button>
                </div>
            </div>
        `;
        //Set HTML to Google maps controls
        this.mapCoreV2.map.controls[google.maps.ControlPosition.BOTTOM_CENTER].push(outerDiv);
        //Create UI events on the HTML
        this.mapCoreV2.mapEventManagerService.setUIEvents(outerDiv, 'edit');
    }

    private buildZoomPrefixControls() {
        //this.logger.log('[Grid][MapUIManagerService] ' + 'Build zoom prefix controls')
        //Create HTML
        let outerDiv: HTMLDivElement = document.createElement('div');
        outerDiv.innerHTML = `
            <div class="zoom-prefix-container">
                ${this.returnAvailableZoomPrefixButtons()}
            </div>
        `;
        //Set HTML to Google maps controls
        this.mapCoreV2.map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(outerDiv);
        //Create UI events on the HTML
        this.mapCoreV2.mapEventManagerService.setUIEvents(outerDiv, 'zoom');
    }

    private returnAvailableLayers(): string {
        //Get the available layers, sort then by name asc, return html dropdown list
        let htmlOuput = '';

        const layers = this.mapCoreV2.mapLayerManagerService.layers.sort((a, b) => {
            if (a.layerName < b.layerName) {
                return -1;
            }
            if (a.layerName > b.layerName) {
                return 1;
            }
            return 0;
        })

        layers.forEach((_layer) => {
            htmlOuput += `
                <div class="dropdown-item-container d-flex justify-content-between align-items-center">
                    <div class="dropdown-item-label">${_layer.layerName}</div>
                    <div class="dropdown-item-options d-flex">
                        <button type="button" title="${this.mapCoreV2.ts.translate('grid.ui.toggleVisible')}" class="map-drawing-button dropdown-item active option_visible" attr-value="${_layer.layerId}"><i class="material-icons">remove_red_eye</i></button>
                    </div>
                </div>
            `;
        });

        return htmlOuput;
    }

    private returnAvailableLayersAsButtons(): string {
        //Get the available layers, sort then by name asc, return html button list
        let htmlOuput = '';
        const layers = this.mapCoreV2.mapLayerManagerService.layers.sort((a, b) => {
            if (a.layerName < b.layerName) {
                return -1;
            }
            if (a.layerName > b.layerName) {
                return 1;
            }
            return 0;
        })

        layers.forEach((_layer) => {
            if(_layer.layerId !== this.mapCoreV2.mapSettings.V1_MARKER_LAYER_HARDCODED_ID && _layer.layerId !== this.mapCoreV2.mapSettings.ANNOTATION_LAYER_HARDCODED_ID) {
                htmlOuput += `
                    <div class="block function_layer_select dropdown-item-container" attr-value="${_layer.layerId}">${_layer.layerName}</div>
                `;
            }
        });

        return htmlOuput;
    }

    private returnAvailableStylesAsButtons(): string {
        //Get the available styles, sort then by name asc, return html button list
        let htmlOuput = '';
        const styles = this.mapCoreV2.model.mapItemStyles.value.sort((a, b) => {
            if (a.name < b.name) {
                return -1;
            }
            if (a.name > b.name) {
                return 1;
            }
            return 0;
        })

        styles.forEach((_style) => {
            htmlOuput += `
                <div class="block function_style_select dropdown-item-container" attr-value="${_style.id}">${_style.name}</div>
            `;
        });

        return htmlOuput;
    }

    public returnAvailableZoomPrefixButtons(): string {
        //Get the available zoom prefixes
        let htmlOuput = '';
        const zoomPrefixButtons = this.mapCoreV2.mapSettings.ZOOM_PREFIX_BUTTONS.sort((a, b) => {
            if (a[0] < b[0]) {
                return 1;
            }
            if (a[0] > b[0]) {
                return -1;
            }
            return 0;
        })

        zoomPrefixButtons.map(column => {
            htmlOuput += `
                <div class="prefix-zoom-button">
                    <button type="button" class="map-drawing-button function_zoom_prefix" attr-value="${column[0]}">
                        ${column[1]}
                    </button>
                </div>
            `;
        })
        return htmlOuput;
    }

    public showHideLinedrawControls() {
        if (this.mapCoreV2.activeDrawingType === mapItemTypes.POLYLINE) {
            //this.logger.log('[Grid][MapUIManagerService] ' + 'Show polyline draw controls')
            //Show polyline length
            this.togglePolylineLengthOption(true)
            //Show add point button
            this.toggleAddPointButton(true)
            //Show remove point button
            this.toggleRemovePointButton(true)
            //Show parallel button
            this.toggleParallelButton(true)
            //Show snappable button
            this.toggleSnappableButton(true)
            //Hide lat lng position
            this.toggleLatLngPosition(false)
        } else {
            //this.logger.log('[Grid][MapUIManagerService] ' + 'Hide polyline draw controls')
            //Hide polyline length
            this.togglePolylineLengthOption(false)
            //Hide add point button
            this.toggleAddPointButton(false)
            //Hide remove point button
            this.toggleRemovePointButton(false)
            //Hide parallel button
            this.toggleParallelButton(false)
            //Hide snappable button
            this.toggleSnappableButton(false)
            //Show lat lng position
            this.toggleLatLngPosition(true)
        }
        this.repositionGoogleMapsControlsToCenter('drawing-controls-container')
    }
    public updatePolylineLength(polylineLength: number): void {
        //this.logger.log('[Grid][MapUIManagerService] ' + 'Update polyline length: '+polylineLength)
        if (this.mapCoreV2.polylineDrawingHeadFixedLength <= 0) {
            let classToUse: string = 'info-value-inner-newline';
            if (this.mapCoreV2.drawState === drawingStates.DRAW_EDIT_HEAD) {
                classToUse = 'info-value-inner-editline';
            }
            if(this.mapCoreV2.isInSuperZoom){
                polylineLength = Number((polylineLength * 100).toFixed(2))
            }
            this.setInnerHtml(classToUse,polylineLength.toString())
        }
    }
    public openMapItemEditSelectionMode(): void {
        //this.logger.log('[Grid][MapUIManagerService] ' + 'Open map item edit selection mode')
        //Handle the close drawing mode
        this.closeDrawingModeUi();
        //Disable add new map item button
        this.toggleNewMapItemButtonEnabled(false)
        //Disable layers button
        this.toggleLayerButtonEnabled(false)
    }
    public closeDrawingModeUi(): void {
        //this.logger.log('[Grid][MapUIManagerService] ' + 'Close drawing mode ui')
        //Hide Drawing options toolbar
        this.toggleDrawingOptionsToolbar(false)
        //Remove active class for new map item button
        this.toggleNewMapItemButtonActive(false)
        //Disable save button
        this.toggleSaveButtonEnabled(false)
        //Disable cancel button
        this.toggleCancelButtonEnabled(false)
        //Reset polyline length
        this.removeFixedLineLength()
        //Disable edit button
        this.toggleEditButtonEnabled(false)
        //Set lat lng to zero
        this.removeLatLngInfo()
    }
    public closeMapItemEditSelectionMode(): void {
        //this.logger.log('[Grid][MapUIManagerService] ' + 'Close map item edit selection mode')
        //Enable new mapitem button
        this.toggleNewMapItemButtonEnabled(true)
        //Enable layers button
        this.toggleLayerButtonEnabled(true)
        //Enable edit button
        this.toggleEditButtonEnabled(true)
        //Remove active class for edit button
        this.toggleEditButtonActive(false)
        //Close edit toolbar
        this.toggleEditOptionsToolbar(false)
        //Reset polyline length
        this.removeFixedLineLength()
    }
    public removeFixedLineLength() {
        //this.logger.log('[Grid][MapUIManagerService] ' + 'Remove fixed line length')
        //Set fixed length var to 0
        this.mapCoreV2.polylineDrawingHeadFixedLength = 0;
        //Set html text to 0 for both add new line and edit line toolbars
        this.setInnerHtml('info-value-inner-newline','0')
        this.setInnerHtml('info-value-inner-editline','0')
        //Hide clear button
        this.toggleFixedLineLengthClearButton(false)
    }

    public enableQuicklinkLayerStyle():void{
        //When a layer and style has been selected, set the values as a quick link
        if(this.mapCoreV2.mapHelperManagerService.getSelectedLayerName() != '' && this.mapCoreV2.mapHelperManagerService.getSelectedStyleName() != ''){
            const presetText:string = this.mapCoreV2.mapHelperManagerService.getSelectedLayerName()+' - '+this.mapCoreV2.mapHelperManagerService.getSelectedStyleName()
            this.setInnerHtml('drawing-selection-set-inner', presetText)
            this.removeClassForElement('drawing-selection-container-selected-set', 'hide-control')
        }
    }

    public clickAddPointButtonUi(makeActive:boolean):void{
        if(makeActive){
            this.removeClassForElement('function_removepoint','active')
            this.setClassForElement('function_addpoint','active')
        } else {
            this.removeClassForElement('function_addpoint','active')
        }
    }

    public clickRemovePointButtonUi(makeActive:boolean):void{
        if(makeActive){
            this.removeClassForElement('function_addpoint','active')
            this.setClassForElement('function_removepoint','active')
        } else {
            this.removeClassForElement('function_removepoint','active')
        }
    }

    public repositionDrawingControlsToCenter():void{
        this.repositionGoogleMapsControlsToCenter('drawing-controls-container')
    }

    public toggleDrawingOptionsToolbar(show:boolean):void{
        if(show){
            this.setClassForElement('drawing-controls-container', 'visible')
            this.repositionGoogleMapsControlsToCenter('drawing-controls-container')
        } else {
            this.removeClassForElement('drawing-controls-container', 'visible')
        }
    }

    public toggleEditOptionsToolbar(show:boolean):void{
        if(show){
            this.setClassForElement('mapitem-edit-controls-container', 'visible')
            this.repositionGoogleMapsControlsToCenter('mapitem-edit-controls-container')
        } else {
            this.removeClassForElement('mapitem-edit-controls-container', 'visible')
        }
    }

    public togglePolylineLengthOption(show:boolean):void{
        if(show){
            this.removeClassForElement('polyline-length', 'hide-control')
        } else {
            this.setClassForElement('polyline-length', 'hide-control')
        }
    }

    public toggleAddPointButton(show:boolean):void{
        if(show){
            this.removeClassForElement('function_addpoint', 'hide-control')
        } else {
            this.setClassForElement('function_addpoint', 'hide-control')
        }
    }

    public toggleRemovePointButton(show:boolean):void{
        if(show){
            this.removeClassForElement('function_removepoint', 'hide-control')
        } else {
            this.setClassForElement('function_removepoint', 'hide-control')
        }
    }

    public toggleParallelButton(show:boolean):void{
        if(show){
            this.removeClassForElement('function_parallel', 'hide-control')
        } else {
            this.setClassForElement('function_parallel', 'hide-control')
        }
    }

    public toggleSnappableButton(show:boolean):void{
        if(show){
            this.removeClassForElement('function_snappable', 'hide-control')
        } else {
            this.setClassForElement('function_snappable', 'hide-control')
        }
    }

    public toggleLatLngPosition(show:boolean):void{
        if(show){
            this.removeClassForElement('latlng-position', 'hide-control')
        } else {
            this.setClassForElement('latlng-position', 'hide-control')
        }
    }

    public toggleDrawLineCloseIcon(show:boolean):void{
        if(show){
            this.removeClassForElement('drawing-selection-container-close', 'hide-control')
        } else {
            this.setClassForElement('drawing-selection-container-close', 'hide-control')
        }
    }

    public toggleSelectedLayerStyleContainer(show:boolean):void{
        if(show){
            this.removeClassForElement('drawing-selection-container-selected-set-outer', 'hide-control')
        } else {
            this.setClassForElement('drawing-selection-container-selected-set-outer', 'hide-control')
        }
    }

    public toggleSelectedLayerStyleButton(show:boolean):void{
        if(show && this.mapCoreV2.mapHelperManagerService.getSelectedLayerName() != '' && this.mapCoreV2.mapHelperManagerService.getSelectedStyleName() != ''){
            this.removeClassForElement('drawing-selection-container-selected-set', 'hide-control')
        } else {
            this.setClassForElement('drawing-selection-container-selected-set', 'hide-control')
        }
    }

    public toggleNewMapItemButtonActive(active:boolean):void{
        if(active){
            this.setClassForElement('function_draw', 'active')
        } else {
            this.removeClassForElement('function_draw', 'active')
        }
    }

    public toggleEditButtonActive(active:boolean):void{
        if(active){
            this.setClassForElement('function_mapitemedit', 'active')
        } else {
            this.removeClassForElement('function_mapitemedit', 'active')
        }
    }

    public toggleEditButtonEnabled(enabled:boolean):void{
        if(enabled){
            this.buttonEnable('function_mapitemedit')
        } else {
            this.buttonDisable('function_mapitemedit')
        }
    }

    public toggleLayerButtonEnabled(enabled:boolean):void{
        if(enabled){
            this.buttonEnable('layers_dropdown')
        } else {
            this.buttonDisable('layers_dropdown')
        }
    }

    public toggleNewMapItemButtonEnabled(enabled:boolean):void{
        if(enabled){
            this.buttonEnable('draw_polyline_dropdown')
        } else {
            this.buttonDisable('draw_polyline_dropdown')
        }
    }

    public toggleSaveButtonEnabled(enabled:boolean):void{
        if(enabled){
            this.buttonEnable('function_save')
        } else {
            this.buttonDisable('function_save')
        }
    }

    public toggleCancelButtonEnabled(enabled:boolean):void{
        if(enabled){
            this.buttonEnable('function_cancel')
        } else {
            this.buttonDisable('function_cancel')
        }
    }

    public toggleFixedLineLengthClearButton(show:boolean):void{
        if(show){
            this.setClassForElement('remove-fixed-length', 'visible')
        } else {
            this.removeClassForElement('remove-fixed-length', 'visible')
        }
    }

    public removeActiveClass(classToFind:string):void{
        this.removeClassForElement(classToFind, 'active')
    }

    public addActiveClass(classToFind:string):void{
        this.setClassForElement(classToFind, 'active')
    }

    public toggleActiveClass(element: Element) {
        let itemClasslist = element.classList;
        itemClasslist.contains('active') ? itemClasslist.remove('active') : itemClasslist.add('active')
    }

    public setLengthIndicator(indicator: string){
        let foundClasses = document.getElementsByClassName('info-value-indicator');
        for (let i = 0; i < foundClasses.length; i++) {
            foundClasses[i].innerHTML = indicator
        }
    }

    private buttonEnable(className: string):void{
        //this.logger.log('[Grid][MapUIManagerService] ' + 'Enable button with class name: '+className)
        let element = document.getElementsByClassName(className)[0]
        element.removeAttribute('disabled');
    }
    private buttonDisable(className: string):void{
        //this.logger.log('[Grid][MapUIManagerService] ' + 'Disable button with class name: '+className)
        let element = document.getElementsByClassName(className)[0]
        element.setAttribute('disabled', 'true');
    }
    private setClassForElement(classToFind: string, classToSet:string):void {
        //this.logger.log('[Grid][MapUIManagerService] ' + 'Set class name: '+classToSet+', on element with class name: '+classToFind)
        let foundClasses = document.getElementsByClassName(classToFind);
        for (let i = 0; i < foundClasses.length; i++) {
            foundClasses[i].classList.add(classToSet)
        }
    }
    private removeClassForElement(classToFind: string, classToRemove:string):void {
        //this.logger.log('[Grid][MapUIManagerService] ' + 'Remove class name: '+classToRemove+', on element with class name: '+classToFind)
        let foundClasses = document.getElementsByClassName(classToFind);
        for (let i = 0; i < foundClasses.length; i++) {
            foundClasses[i].classList.remove(classToRemove)
        }
    }
    private setInnerHtml(classToFind: string, valueToWrite:string):void{
        //this.logger.log('[Grid][MapUIManagerService] ' + 'Set html on element with class name: '+classToFind)
        const element = document.getElementsByClassName(classToFind)[0];
        if(typeof element !== 'undefined'){
            element.innerHTML = valueToWrite;
        }
    }

    private repositionGoogleMapsControlsToCenter(className:string):void{
        //this.logger.log('[Grid][MapUIManagerService] ' + 'Reposition GoogleMaps controls to center of map')
        //Google maps automatically positions the controls to the center but only when the used has dragged the map. Because we change the controls layout dynamically, we need to center the controls after changing it.
        const element = document.getElementsByClassName(className)[0].parentElement.parentElement;
        const mapWidth = document.getElementById('mapContainer').offsetWidth;
        const offsetLeftForCenterPosition = (mapWidth / 2) - (element.offsetWidth / 2)
        element.style.left = offsetLeftForCenterPosition+'px';
    }

    public setLatLngInfo(latLng: google.maps.LatLng):void{
        //this.logger.log('[Grid][MapUIManagerService] ' + 'Set lat lng info')
        this.toggleLatLngPosition(true)
        this.setInnerHtml('info-lat',latLng.lat().toString())
        this.setInnerHtml('info-lng',latLng.lng().toString())
    }
    private removeLatLngInfo():void{
        //this.logger.log('[Grid][MapUIManagerService] ' + 'Remove lat lng info')
        this.setInnerHtml('info-lat','0')
        this.setInnerHtml('info-lng','0')
    }
}
