import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'key-value',
    templateUrl: './key-value.component.html'
})
export class KeyValueComponent implements OnInit {
    @Input() key: string;
    @Input() truncateKey: boolean = true;
    @Input() value: string|number;
    
    constructor() {
    }
    
    ngOnInit(): void {
    }
    
}
