import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CommonPipesModule} from '../../../pipes/common-pipes.module';
import {FilterableTableModule} from '../filterableTable/filterableTable.module';
import {GeoDataManagementTableComponent} from './geoDataManagementTable.component';
import {BaseTableModule} from '../shared/baseTable/baseTable.module';

@NgModule({
    imports: [CommonPipesModule, CommonModule, BaseTableModule, FilterableTableModule],
    exports: [GeoDataManagementTableComponent],
    declarations: [
        GeoDataManagementTableComponent
    ]
})
export class GeoDataManagementTableModule {
}
