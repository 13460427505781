import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ListItem, ListItemMenuItemEvent} from './complex-list-item.interface';
import {DropdownMenuItemEvent} from '../../dropdown/menu-dropdown/menu-dropdown.interface';
import {AppSettings} from '../../../../../app.settings';

@Component({
    selector: 'complex-list-item',
    templateUrl: './complex-list-item.component.html',
})
export class ComplexListItemComponent implements OnInit {
    @Input() item: ListItem;
    @Input() smallTitle: boolean = false;
    @Input() readonly: boolean = false;
    @Input() fancy: boolean = false;
    @Input() iconColor: string = '';
    @Output() public onMenuItemClick: EventEmitter<ListItemMenuItemEvent> = new EventEmitter();
    public AppSettings = AppSettings;
    
    constructor() {
    }
    
    ngOnInit(): void {
    }
    
    public handleMenuItemClick($event: DropdownMenuItemEvent): void {
        this.onMenuItemClick.emit({
            itemId: this.item.id,
            action: $event.item.action
        });
    }
}
