import {Component, EventEmitter, OnInit, Output, Renderer2} from '@angular/core';
import {AbstractFormFieldComponent} from '../abstract/abstract-form-field.component';
import {ValidationConstraintService} from '../../services/validation-constraint.service';
import {TooltipService} from '../../../../services/tooltip/tooltip.service';
import {ProjectsFileManagerConfig} from './form-file-manager.interface';
import {ConfigType} from '../field/fieldDirective.interface';
import {LoggerService} from "../../../../services/logger/logger.service";

@Component({
    selector: 'form-file-manager',
    templateUrl: './form-file-manager.component.html'
})
export class FormFileManagerComponent extends AbstractFormFieldComponent implements OnInit {
    @Output() onComponentEvent: EventEmitter<any> = new EventEmitter();
    public config: ProjectsFileManagerConfig;
    
    constructor(
        public renderer: Renderer2,
        public validationConstraintService: ValidationConstraintService,
        private toolTipService: TooltipService,
        protected logger:LoggerService
    ) {
        super(
            renderer,
            validationConstraintService,
            toolTipService,
            logger
        );
    }
    
    ngOnInit() {
        this.config = {
            type: ConfigType.file_manager,
            disabled: false,
            name: 'naam',
            label: 'label',
            attr: {}
        };
    }
    
}
