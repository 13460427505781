/**
 * Created by Christiaan on 16/03/2017.
 */

import {Injectable, OnDestroy} from '@angular/core';
import {HTTPService} from "../http/http.service";
import {GlobalModel} from "../state/global.model";
import {GlobalStateService} from "../state/global-state.service";
import {RequestFailure} from "../http/request-failure";
import {HTTPError} from "../http/http-error";
import {AppSettings} from "../../../app.settings";
import {Router} from "@angular/router";
import {GlobalAlertService} from "../../../wrapper/global-alert/global-alert.service";
import {DynamicMenuItemComponent} from "../../../dynamic-menu/menu/menu-item/dynamic-menu-item.component";
import {TranslateService} from "../translate/translate.service";
import {LuminizerRoutes} from '../../interfaces/routes';
import {catchError, flatMap, map, mergeMap, switchMap, take, toArray} from 'rxjs/operators';
import {MapServerService} from '../../components/map/map-server/map-server.service';
import {asapScheduler, forkJoin, from, scheduled, Subscription, throwError} from 'rxjs';
import {AsapScheduler} from 'rxjs/internal/scheduler/AsapScheduler';
import {StorageService} from "../storage/storage.service";
import {ReportOption} from "../../../modules/analysisReport/reporting.interface";
import {LoggerService} from "../logger/logger.service";

@Injectable() export class AreaalService implements OnDestroy {
    private subscriptions: Subscription[] = [];

    constructor(private model:GlobalModel, private router:Router, private httpService:HTTPService, private globalAlertService:GlobalAlertService, private ts:TranslateService, private mapServerService:MapServerService, private globalStateService:GlobalStateService, private storageService:StorageService, protected logger:LoggerService) {}


    // Pass null to navigateTo to not navigate at all
    public loadAreaal(areaalId:number, showLoadingScreen:boolean, successCallBack?:(json:any) => any, failCallBack?:(failure:RequestFailure) => any, navigateTo: string = LuminizerRoutes.INITIAL_PAGE) {
        //TODO: eigenlijk moet het dashboard gelijk zichtbaar worden (ivm flashapp) maar dan wordt het oude areaal nog ingeladen want het wisselen is nog niet klaar
        if(showLoadingScreen) {
            this.navigateToLoadingPage();
        }

        //Clear global state on areaal switch
        this.globalStateService.clearEntireFormState();

        //Only change areaal when all previous calls have finished
        this.subscriptions.push(this.httpService.pendingCallPaths.subscribe((x) => {
            if(x.length === 0){
                this.subscriptions.forEach(subscription => subscription.unsubscribe()); //Unsubscribe to prevent loop
                this.httpService.doGetRequest(
                    AppSettings.SWITCH_AREAAL_PATH + "/" + areaalId + '/',
                    (json:any, url:string) => {
                        if (json) {
                            this.model.clearAreaData();
                            this.processAreaalData(json, url);

                            if (navigateTo && navigateTo != ""){
                                this.navigateTo(navigateTo);
                            }
                            successCallBack(json);
                        }else{
                            this.globalAlertService.addAlertEmptyResponse(url);
                        }
                    }, (failure:RequestFailure) => {
                        //Failed to load areaal, navigate to initialpage
                        this.navigateToInitialPage();
                        failCallBack(failure)
                    }, (error:HTTPError) => {
                        //Error is handled in httpService
                    }
                );
            }
        }));
    }

    //To have one place to interpret the areaal data. Can be received from login or switchareaal call
    public processAreaalData(json:any, calledUrl:string){
        if (json.areas) {
            //this.model.arealen.next(plainToClass(Areaal, json.areas) as any);
            this.model.arealen.next(json.areas);
            this.logger.log("[AreaalService] " + "Succesvol arealen opgehaald: " + this.model.arealen.value.length);
        }else{
            this.logger.log("[AreaalService] " + "ERROR: Status is success, maar geen arealen meegestuurd");
            this.globalAlertService.addAlertEmptyResponse(calledUrl);
        }

        if (json.authorizations){

            //TODO: DEZE MOETEN ER WEER UIT
            //HAXOR FAKE AUTH SETTINGS
            //-------------------------------------------------
            /*json.authorizations.push(
                {
                    "module": "auth-settings",
                    "action": ["show"]
                }
            );*/
            /*if (json.rightSideMenuItems.length>0){
                json.rightSideMenuItems[0].children.push({
                    "code": "auth-settings",
                    "label": "Gebruikersrechten",
                    "id": 999,
                    "type": "DefaultMenuItem",
                    "uri": "settings\/auth",
                    "icon": null,
                    "children": []
                });
            }*/
            //-------------------------------------------------

            this.model.authorisations.next(json.authorizations);
        }else{
            //auth is allowed to be empty. Although you will not have any access.
        }

        //TODO: tijdelijk voor menu overgang
        /*if (json.newMenuStyle != undefined){
            this.model.newMenuStyle.next(json.newMenuStyle);
        }
*/
        if (json.recentAreas) {
            //this.model.recentArealen.next(plainToClass(Areaal, json.recentAreas) as any);
            this.model.recentArealen.next(json.recentAreas);
        }else{
            //recentAreas is allowed to be empty (e.g in case of a new user)
        }

        if (json.area) {
            // this.model.currentAreaal.next(plainToClass(Areaal, json.area) as any);
            this.model.currentAreaal.next(json.area);
            this.mapServerService.getMapServersWithLayers(json.area.id).pipe(
                take(1),
            ).subscribe(
                (servers) => {
                    servers.sort((a, b) => {
                        return a.id - b.id;
                    });
                    json.area.wmsData = (json?.area?.wmsData || []).map(_wmsData => {return {id: _wmsData.id || -1, label:_wmsData.label, layers:_wmsData.layers || [], url:_wmsData.url, active:true, disabled:false}})
                    json.area.wmsData = json.area.wmsData.concat(servers.map(server => {
                        if (server.layers) {
                            return {id: server.id, label:server.name,layers:server.layers.map(layer => {return {value:layer.id, label:layer.name}}),url:server.url, active:server.active, disabled:server.disabled}
                        } else {
                            return {id: server.id, label:server.name, layers:[], url:server.url, active:server.active, disabled:server.disabled}
                        }
                    }))
                    this.model.currentAreaal.next(json.area);
                },
                (error) => {
                    this.logger.log('[AreaalService] map server error:', error);
                }
            )
        }else{
            this.globalAlertService.addAlertEmptyResponse(calledUrl);
        }

        //TODO: user manual path would be refactored to be contained in a specific configuration json object?
        if (json.userManualPath) {
            this.model.userManualPath = json.userManualPath;
        }else{
            this.globalAlertService.addAlertEmptyResponse(calledUrl);
        }

        if (json.trees) {
            //When the trees are empty (e.g. for Stedin), store an empty tree array to prevent errors later on
            if (json.trees.length == 1 && json.trees[0] == null){
                this.model.currentTrees.next([]);
            }else{

                // Validate data:
                if (json.trees[0].hasOwnProperty('module')){
                    // This is goooood;
                }else{
                    // This is bad. The trees dont have the module property. So the trees belong to no module.
                    this.globalAlertService.addAlert(this.ts.translate("Error"), this.ts.translate("Data fout"), this.ts.translate("Het is voor de huidige tree(s) niet duidelijk aan welke module hij is gelinked. De module property wordt niet meegestuurd op de trees."));
                }

                this.model.currentTrees.next(json.trees);
            }
        } else {
            this.globalAlertService.addAlertEmptyResponse(calledUrl);
        }

        // Main vs. right side menu: a temporary solution until the flash items disappear from the menu.
        // ultimately the menu items would best be called at once, simply via the mainMenu,
        // BUT as we are not going to load flash menu items from the database but via the existing XML-config,
        // this is the temporary solution: send mainMenuItems and rightSideMenuItems as separate properties.
        // This is a relatively low impact solution.
        this.handleMainMenuItems(json, calledUrl);
        this.handleRightSideMenuItems(json, calledUrl);
        this.handlesearchGroupItems(json, calledUrl);
        this.handleMapItemLayers(json,calledUrl);
        this.handleMapItemStyles(json,calledUrl);

        if (json.user) {
            //this.model.user.next((User, json.user) as any);
            this.model.user.next(json.user);
            //NOTE: the firstlogin message is moved to this location because the firstLogin property is cleared in the backend not only when logging in, but also when switching area. So now, the frontend implementation matches the backend.
        }else{
            this.logger.log("[AreaalService] " + "ERROR: Status is success, maar geen user meegestuurd");
            this.globalAlertService.addAlertEmptyResponse(calledUrl);
        }
    }

    private navigateTo(uri: string)
    {
        this.logger.log("[AreaalService] " + "Areaal switch complete. Navigate to url: " + uri);
    
        //this.router.navigate([uri], {queryParams: {}});
        this.router.navigateByUrl(uri);
    }

    private navigateToInitialPage(){
        //Auto-route to dashboardpage
        //You don't know if the next areaal also grants this user access to the same page, so redirect to a page available for all users/areaals
        this.router.navigate([LuminizerRoutes.INITIAL_PAGE], {queryParams: {}});
    }

    private navigateToLoadingPage(){
        this.router.navigate([LuminizerRoutes.LOAD_AREAAL_PAGE], {queryParams: {}});
    }

    private handleMainMenuItems(json: any, calledUrl: string)
    {
        if (json.mainMenuItems) {


            //For development haxor menu
            //-------------------------
            /*let fakeAssetItem:any = {
                "code": "assets",
                "label": "Assets",
                "id": 999,
                "type": "DefaultMenuItem",
                "uri": "assets",
                "icon": null,
                "children": []
            };

            json.mainMenuItems.splice(1, 0, fakeAssetItem);*/
            //-------------------------


            //this.model.mainMenuItems.next(plainToClass(MenuItem, json.mainMenuItems) as any);
            this.model.mainMenuItems.next(json.mainMenuItems);

            let dashboardFound:boolean = false;

            //Dashboard items are wrapped in the menu, so use this workaround to get them out
            for (let menuItem of this.model.mainMenuItems.value) {
                //For now, the item with type static DynamicMenuItemComponent.ITEM_TYPE_DASHBOARD represents the list of dashboards
                if (menuItem.type == DynamicMenuItemComponent.ITEM_TYPE_DASHBOARD){
                    if (menuItem.children.length == 0){
                        //No subitems, so no dashboard
                    }else {
                        dashboardFound = true;
                        this.model.dashboardItems = menuItem.children;
                        //this.model.dashboardItems = plainToClass(MenuItem, menuItem.children) as any;
                    }
                    break;
                }
            }

            if (!dashboardFound && !this.model.publicPortalMode.value) {
                this.globalAlertService.addAlert(this.ts.translate("Geen dashboard"), "", this.ts.translate("Het menu-item met code 'dashboards' bevat geen lijst met dashboards. Iedere gebruiker moet minimaal één dashboard tot zijn beschikking krijgen. De startpagina kan nu niet worden weergegeven."));
            }

        }else{
            this.globalAlertService.addAlertEmptyResponse(calledUrl);
        }
    }

    private handlesearchGroupItems(json: any, calledUrl: string) {
        if (json.searchGroups) {
            this.model.searchGroups.next(json.searchGroups);
        }else{
            this.globalAlertService.addAlertEmptyResponse(calledUrl);
        }
    }

    private handleMapItemLayers(json: any, calledUrl:string){
        if (json.mapItemLayers) {
            this.model.mapItemLayers.next(json.mapItemLayers);
        }else{
            this.globalAlertService.addAlertEmptyResponse(calledUrl);
        }
    }

    private handleMapItemStyles(json: any, calledUrl:string){
        if (json.mapItemStyles) {
            this.model.mapItemStyles.next(json.mapItemStyles);
        }else{
            this.globalAlertService.addAlertEmptyResponse(calledUrl);
        }
    }

    private handleRightSideMenuItems(json: any, calledUrl: string) {
        if (json.rightSideMenuItems) {
            //this.model.rightSideMenuItems.next(plainToClass(MenuItem, json.rightSideMenuItems) as any);
            this.model.rightSideMenuItems.next(json.rightSideMenuItems);
        }else{
            this.globalAlertService.addAlertEmptyResponse(calledUrl);
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }
}
