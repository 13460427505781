import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as Sentry from '@sentry/angular-ivy'
import {BrowserTracing} from '@sentry/tracing'

Sentry.init({
  environment: environment.sentry_env,
  release: environment.bamboo_build_nr,
  dsn: (environment.sentry_env === 'local') ? undefined : 'https://445c96bc6ddd478f8fdc9cf85c339cb0@o353009.ingest.sentry.io/5302000',
  // TryCatch has to be configured to disable XMLHttpRequest wrapping, as we are going to handle
  // http module exceptions manually in Angular's ErrorHandler and we don't want it to capture the same error twice.
  // Please note that TryCatch configuration requires at least @sentry/browser v5.16.0.
  integrations: [
    new Sentry.Integrations.TryCatch({XMLHttpRequest: false}),
    new BrowserTracing({
      routingInstrumentation: Sentry.routingInstrumentation
    })
  ],
  tracesSampleRate: 0.2,
  denyUrls: [
    /maps-api-v3/,
  ],
  ignoreErrors: [
    'null is not an object (evaluating \'this.getMap().getBounds().getSouthWest\')',
    'Collapse is transitioning',
    'Cannot read property \'getSouthWest\' of null',
    'this.getMap(...).getBounds(...) is null',
    'this.getMap().getBounds() is null',
    'undefined is not an object (evaluating \'z.elements.length\')',
    'undefined is not an object (evaluating \'a.elements.length\')',
    'ViewDestroyedError: Attempt to use a destroyed view: detectChanges',
    '~#!#~282~#!#~drawScreenQuad_: Error accessing canvas.~#!#~',
    '~#!#~277~#!#~drawScreenQuad_: Error accessing canvas.~#!#~',
    '~#!#~274~#!#~drawScreenQuad_: Error accessing canvas.~#!#~',
    '~#!#~273~#!#~drawScreenQuad_: Error accessing canvas.~#!#~',
    'getSouthWest',
    'Not enough columns given to draw the requested chart',
    'Non-Error exception captured with keys: error, headers, message, name, ok',
    'Cannot read properties of undefined (reading \'nativeElement\')',
    'ChunkLoadError: Loading chunk',
    'Http failure response for request=GetCapabilities&service=WMS'
  ],
});

// NOTE: nog even bewaren totdat prodmode zeker weten goed werkt
/*let pattern:RegExp = /luminizer.nl/g;
let location:string = window.location.origin;
if (location && location.match(pattern) && location.match(pattern).length > 0) {
  enableProdMode();
  //this.logger.log("[Main] " + "Production mode enabled");
}*/

// NOTE: use --prod=true on the command line to build a prod-build
if (environment.production) {
  enableProdMode();
}


platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
