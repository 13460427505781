<default-popup-wrapper-component [componentRef]="this"
                                 [showLoader]="isLoading"
                                 [globalPopup]="globalPopup"
                                 [title]="globalPopup.title"
                                 [description]="globalPopup.label"
                                 (onClose)="closePopup($event)">
    <ng-container *ngIf="formData && config">
        <form-component #form
                        [config]="config"
                        [name]="getFormName()"
                        [validationConstraints]="validationConstraints"
                        [invalidControlsErrors]="invalidControlsErrors"
                        [readOnly]="readOnly"
                        [formIsSubmitted]="formIsSubmitted"
                        (onComponentEvent)="handleComponentEvent($event)">
        </form-component>
    </ng-container>
</default-popup-wrapper-component>
