import {ChangeDetectorRef, Component, EventEmitter, HostBinding, Input, OnInit, Output, Renderer2} from "@angular/core";
import {AbstractFormFieldComponent} from "../../abstract/abstract-form-field.component";
import {ValidationConstraintService} from "../../../services/validation-constraint.service";
import {TooltipService} from "../../../../../services/tooltip/tooltip.service";
import {AppSettings} from "../../../../../../app.settings";
import {LoggerService} from "../../../../../services/logger/logger.service";

@Component({
    selector: 'form-advanced-color-picker',
    templateUrl: 'form-advanced-color-picker.component.html'
})
export class FormAdvancedColorPickerComponent extends AbstractFormFieldComponent implements OnInit {
    @HostBinding('class') hostClasses = 'd-flex mt-1';
    @Output() onComponentEvent: EventEmitter<any> = new EventEmitter();
    @Input() invalidControlsErrors: any;

    public selectedColor:string = AppSettings.ADVANCED_COLOR_PICKER_DEFAULT_OPTIONS[0];
    public availableColorPallet: string[] = AppSettings.ADVANCED_COLOR_PICKER_DEFAULT_OPTIONS;
    private defaultColorPallet: string[] = AppSettings.ADVANCED_COLOR_PICKER_DEFAULT_OPTIONS;

    constructor(public cd:ChangeDetectorRef, public renderer: Renderer2, public validationConstraintService: ValidationConstraintService, public tooltipService: TooltipService, protected logger:LoggerService) {
        super(renderer, validationConstraintService, tooltipService, logger);
    }

    ngOnInit() {
        if (this.config && this.config.initialValue) {
            this.selectedColor = this.config.initialValue.toString();
            this.selectColor()
        }
    }

    public selectColor():void {
        this.selectedColor = this.selectedColor.toUpperCase()
        if(this.availableColorPallet.indexOf(this.selectedColor) === -1){
            this.availableColorPallet = structuredClone(this.defaultColorPallet); //Reset pallet to default list
            this.availableColorPallet.unshift(this.selectedColor);
        }
        this.updateFormValue();
    }


    private updateFormValue():void {
        if (this.selectedColor !== ''){
            this.group.get(this.config.name).patchValue(this.selectedColor);
        }else{
            this.group.get(this.config.name).patchValue("");
        }
        if (this.isControlDisabled()){
            this.group.get(this.config.name)['disable']();
        }
    }
}
