/**
 * Created by Christiaan on 13/03/2017.
 */
import {LOCALE_ID, NgModule} from '@angular/core';
import { CommonModule }  from '@angular/common';

import { MapCoreComponent }  from './map-core.component';
import {StreetviewCoreComponent} from './streetview-core.component';
import {CommonDirectivesModule} from '../../directives/common/common-directives.module';
import {CommonPipesModule} from '../../pipes/common-pipes.module';
import {MapItemImporterComponent} from './map-item/map-item-importer/map-item-importer.component';
import {BaseTableModule} from '../table/shared/baseTable/baseTable.module';
import {FileModule} from '../file/file.module';
import {CommonUIModule} from '../commonUI/common-ui.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { MapServerSettingsComponent } from './map-server/map-server-settings/map-server-settings.component';
import {TranslateService} from "../../services/translate/translate.service";
import {MapCoreV2Service} from "./map-core-V2.service";

@NgModule({
    imports: [
        CommonModule,
        CommonDirectivesModule,
        CommonPipesModule,
        BaseTableModule,
        FileModule,
        CommonUIModule,
        FormsModule,
        ReactiveFormsModule
    ],
    exports: [
        MapCoreComponent,
        StreetviewCoreComponent,
        MapItemImporterComponent,
        MapServerSettingsComponent
    ],
    declarations: [
        MapCoreComponent,
        StreetviewCoreComponent,
        MapItemImporterComponent,
        MapServerSettingsComponent
    ],
    providers: [
        MapCoreV2Service
    ]
})

export class MapModule { }
