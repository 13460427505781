import {ChangeDetectorRef, Component, HostListener, Renderer2} from '@angular/core';
import {FormInputComponent} from './form-input.component';
import {TooltipService} from '../../../../services/tooltip/tooltip.service';
import {TranslateService} from '../../../../services/translate/translate.service';
import {PasswordGeneratorService} from '../../../../services/authorization/password-generator.service';
import {ValidationConstraintService} from '../../services/validation-constraint.service';
import {GlobalAlertService} from '../../../../../wrapper/global-alert/global-alert.service';
import {LoggerService} from "../../../../services/logger/logger.service";
import {GlobalModel} from '../../../../services/state/global.model';

@Component({
    selector: 'form-password-input',
    providers: [PasswordGeneratorService],
    template: `
        <ng-container
                [formGroup]="group"
        >
            <div class="w-100 position-relative">
                <div class="d-flex w-100 position-relative">
                    <label class="m-0 pr-2 form-label-part" for="{{config.name}}">{{ config.label }}
                        <required-field [visible]="config.required"></required-field>
                    </label>
                    <!--class="{{getClassNames()}}"-->
                    <input
                            #currentFormField
                            [disableControl]="isControlDisabled()"
                            name="{{config.name}}"
                            type="password"
                            [attr.placeholder]="config.placeholder"
                            [formControlName]="config.name"
                            [(ngModel)]="initialValue"
                            class="m-0"
                            autocomplete="{{getAutocomplete()}}"
                    >
                    <i id="generatorButton" *ngIf="config && config.attr && config.attr.enableGenerator" class="material-icons cursor-pointer" style="position: absolute; right: 0">vpn_key</i>
                </div>
                <!-- Generate password popup -->
                <div id="generator" *ngIf="generatorService.showGenerator && config && config.attr && config.attr.enableGenerator" [title]="'Password generator'" class="password-generate-container w-100 mx-auto" style="right: 0">
                    <div class="result-container">
                        <span id="result">{{'generatepass.result' | translate}}</span>
                        <lumi-button (onClick)="generatorService.handleClipboard()"
                                     [rank]="'secondary'"
                                     [icon]="'content_paste'">
                        </lumi-button>
                    </div>
                    <div class="settings">
                        <div class="setting">
                            <label>{{'generatepass.length' | translate}}: {{generatorService.passwordLength}}</label>
                            <input (input)="handleChangePasswordLength($event)" type="range" id="length" min="8" max="24" value="10"/>
                        </div>
                        <!-- Settings for generator, can be turned on/off so you can choose to use them in the generator -->
                        <!--                                    <div class="setting">-->
                        <!--                                        <label>{{'generatepass.uppercase' | translate}}</label>-->
                        <!--                                        <input type="checkbox" id="uppercase" checked disabled/>-->
                        <!--                                    </div>-->
                        <!--                                    <div class="setting">-->
                        <!--                                        <label>{{'generatepass.lowercase' | translate}}</label>-->
                        <!--                                        <input type="checkbox" id="lowercase" checked disabled/>-->
                        <!--                                    </div>-->
                        <div class="setting">
                            <label>{{'generatepass.numbers' | translate}}</label>
                            <input (change)="handleClickNumbers()" type="checkbox" id="numbers" [checked]="generatorService.numbersChecked" [disabled]="generatorService.numbersDisabled"/>
                        </div>
                        <div class="setting">
                            <label>{{'generatepass.symbols' | translate}}</label>
                            <input (change)="handleClickSymbols()" type="checkbox" id="symbols" [checked]="generatorService.symbolsChecked" [disabled]="generatorService.symbolsDisabled"/>
                        </div>
                    </div>
                    <lumi-button (onClick)="generatorService.handleGeneratePassword()"
                                 [rank]="'secondary'"
                                 [fullWidth]="true"
                                 [label]="'generatepass.button' | translate">
                    </lumi-button>
                </div>
                <!--  End of password generator -->
            </div>
        </ng-container>
    `,
})
export class FormPasswordInputComponent extends FormInputComponent {
    @HostListener('document:mousedown', ['$event'])
        onGlobalClick(event): void {
            let generator = event.target.closest('#generator');
            if (event.target.id === 'generatorButton') {
                this.generatorService.showGenerator = !this.generatorService.showGenerator;
            } else if (!generator) {
                this.generatorService.showGenerator = false
            }
        }

    constructor(public renderer: Renderer2, public validationConstraintService: ValidationConstraintService, public tooltipService: TooltipService, public globalAlertService: GlobalAlertService, public ts: TranslateService, public generatorService: PasswordGeneratorService, public cd:ChangeDetectorRef, protected logger:LoggerService, public model:GlobalModel){
        super(renderer, validationConstraintService, tooltipService, globalAlertService, ts, logger, model)

    }

    protected showValidationConstraints(validationConstraints: any, placement: string) {
        super.showValidationConstraints(validationConstraints, TooltipService.PLACEMENT_TOP);
    }

    getAutocomplete(): string {
        return (this.config.attr && typeof this.config.attr.autocomplete === 'string')
            ? this.config.attr.autocomplete
            : 'on';
    }
    
    public handleChangePasswordLength(e:any):void {
        this.generatorService.handleChangePasswordLength(e);
        this.cd.detectChanges();
    }
    
    public handleClickNumbers():void {
        this.generatorService.handleClickNumbers();
        this.cd.detectChanges();
    }
    
    public handleClickSymbols():void {
        this.generatorService.handleClickSymbols();
        this.cd.detectChanges();
    }
}
