import {Injectable} from "@angular/core";
import {UntypedFormControl, Validators} from "@angular/forms";
import {TranslateService} from "../../../services/translate/translate.service";
import {LoggerService} from "../../../services/logger/logger.service";

@Injectable()
export class ValidationConstraintService
{
    constructor(private ts:TranslateService, protected logger:LoggerService) {}

    public getValidatorsForControl(control: any):any[]
    {
        //this.logger.log('[ValidationConstraintService] map validations for', control.type, control.label);

        let validators:any = [];

        if (control.required == true) {
            validators.push(Validators.required);
        }

        if (control.type === "date_text" || control.type === "date_time_text" || control.type === "time"){
            validators.push(this.nullValidator);
        }

        if (control.type === 'email') {
            validators.push(Validators.email);
        }

        if (control.minLength) {
            validators.push(Validators.minLength(control.minLength))
        }

        if (control.maxLength) {
            validators.push(Validators.maxLength(control.maxLength))
        }

        return validators;
    }

    //Custom validator (for now used for date-time objects to fix issues with MyDatePicker)
    //(Werkt beter dan de Validators.nullValidator)
    private nullValidator(control: UntypedFormControl):any
    {
        let value = control.value;

        if (value === null){
            return {nullValue:true}
        }else{
            return null;
        }
    }

    //Translate error to user friendly message
    public getErrorMessageForControlErrors(errors:any):string
    {
        this.logger.log("[ValidationConstraintService] " + "errors: ", errors);

        let result:string = '';

        if (errors) {
            for (let error of errors) {
                //this.logger.log("[ValidationConstraintService] " + "error: ", error);

                if (error){
                    if (error.required) {
                        result += this.ts.translate('Dit veld is verplicht. ');
                    }
                    if (error.maxlength && error.maxlength.requiredLength) {
                        result += this.ts.translate("validation.max", [error.maxlength.requiredLength]); //"'Dit veld mag maximaal ' + error.maxlength.requiredLength + ' tekens bevatten. ';
                    }
                    if (error.minlength && error.minlength.requiredLength) {
                        result += this.ts.translate("validation.min", [error.minlength.requiredLength]); //'Dit veld moet minstens ' + error.minlength.requiredLength + ' tekens bevatten. ';
                    }
                    if (error.email) {
                        result +=  this.ts.translate('Dit is geen geldig e-mailadres. ');
                    }
                    if (error.nullValue) {
                        result +=  this.ts.translate('Dit is geen geldige waarde.');
                    }
                }else{
                    this.logger.log("[ValidationConstraintService] " + "error is null");
                    result += this.ts.translate('Onbekende error');
                }
            }
        }

        //this.logger.log("[ValidationConstraintService] " + 'full error result:', result);

        return result;
    }

    //Translate validation constraint to friendly message
    public getErrorMessageForValidationConstraints(validationConstraints:any):string
    {
        let result:string = '';

        for(let constraint of validationConstraints) {
            if (constraint.message) {
                result += constraint.message + ' '; // validation constraints from the back-end. should have the colon (.) in the message itself
            }
        }

        return result;
    }

    //TODO: deze functie wordt veel te vaak uitgevoerd
    public getControlErrors(form:any, data:any, invalidControlsErrors:any[] = [], checkIsDirty:boolean = true):any[]
    {
        //TODO: kan je hier niet gewoon over alle controls van de form loopen zonder die data?
        if (data) {
            for (let fieldKey in data) {
                const control = form.get(fieldKey);

                //Check if control is changed and invalid
                //Skip the dirty-check in some cases
                if (control && (!checkIsDirty || checkIsDirty && control.dirty) && control.valid === false){

                    //Go deeper recursively
                    this.getControlErrors(form.get(fieldKey), data[fieldKey], invalidControlsErrors);

                    //Store the found errors for this control. The reasons why it is invalid
                    invalidControlsErrors[fieldKey] = control.errors;

                    // For debugging
                    //this.logger.log("[ValidationConstraintService] " + "validation error op control: ", control);
                }
            }
        }

        return invalidControlsErrors;
    }
}
