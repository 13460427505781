/**
 * Created by Christiaan on 13/03/2017.
 */
import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Router} from "@angular/router";
import {RequestFailure} from "../../../shared/services/http/request-failure";
import {UntypedFormGroup, UntypedFormBuilder, Validators} from '@angular/forms';
import {HTTPService} from "../../../shared/services/http/http.service";
import {AppSettings} from "../../../app.settings";
import {UserService} from "../user.service";
import {TranslateService} from "../../../shared/services/translate/translate.service";
import {Subscription} from "rxjs";
import {GlobalModel} from "../../../shared/services/state/global.model";
import {LuminizerRoutes} from '../../../shared/interfaces/routes';
import {User} from "../user";
import {LoginService} from "../login.service";
import {LoggerService} from "../../../shared/services/logger/logger.service";

@Component ({
    selector: 'password-forget-component',
    templateUrl: 'password-forget.component.html'
})
export class PasswordForgetComponent implements OnInit, OnDestroy{

    @ViewChild('username', {static: false}) usernameInput:any;

    public passwordChangeForm:UntypedFormGroup;
    public failure:RequestFailure;
    private subChangeLanguage:Subscription;
    public flippingLanguage:boolean = false;
    private checkingLoginStatus:boolean = false;
    private firstTimeVisible:boolean = true;
    public _user:User = null;
    
    public formTitle:string = "";
    public formSubTitle:string = "";
    public formUsername:string = "";
    public formSubmit:string = "";
    public formLogin:string = "";

    public disableSubmitButton:boolean = false;

    constructor(public httpService:HTTPService, private loginService:LoginService, private formBuilder:UntypedFormBuilder, private model:GlobalModel, private router:Router, private userService:UserService, private ts:TranslateService, private cd:ChangeDetectorRef, protected logger:LoggerService)
    {
        this.createForm();
    }

    ngOnInit(){
        this.subChangeLanguage = this.model.changeLanguage.subscribe( () =>
        {
            this.formTitle = this.ts.translate('Wachtwoord vergeten');
            this.formSubTitle = this.ts.translate('passwordforget.title');
            this.formUsername = this.ts.translate('Gebruikersnaam');
            this.formSubmit = this.ts.translate('passwordforget.submit');
            this.formLogin = this.ts.translate('passwordforget.login');

            this.cd.detectChanges();
        })

    }

    getLogoPath(): string {
        if(this.model.isXmasMode()){
            return 'assets/img/luminizerlogo-xmas.png';
        } else {
            return '/assets/img/luminizerlogo.png';
        }
    }

    public handleFlippingLanguage(flippingLanguage:boolean){
        this.flippingLanguage = flippingLanguage;
    }

    ngOnDestroy(): void
    {
        if (this.subChangeLanguage){
            this.subChangeLanguage.unsubscribe();
        }
    }

    public isLoginVisible(user:User):boolean{

        if (!this.checkingLoginStatus && !this.loginService.isLoggedInLocally(user) && !this.flippingLanguage){

            //TODO: iets mooiers maken hiervoor. Door de animatie die in het loginscherm zit is het element niet beschikbaar
            //Daarvoor wordt die timer ingezet
            if (this.firstTimeVisible){
                this.firstTimeVisible = false;

                setTimeout(() => {
                    if (this.usernameInput) {
                        this.usernameInput.nativeElement.focus();
                    }
                }, 500);
            }

            return true;
        }else{
            return false;
        }
    }

    private createForm():void
    {
        this.passwordChangeForm = this.formBuilder.group({
            username: ['', Validators.required]
        })
    }

    public handleClickSend(event:MouseEvent)
    {
        this.failure = null;
        this.disableSubmitButton = true;

        this.userService.requestPasswordChange(this.passwordChangeForm.value.username,
            () => {
                this.logger.log("[PasswordForgetComponent] " + "Change request succes");

                //TODO: dit moet als response uit de call komen
                let info:RequestFailure = new RequestFailure();
                info.title = this.ts.translate("passwordforget.emailsend.title");
                info.message = this.ts.translate("passwordforget.emailsend.message"); //"Volg de link in de e-mail om uw wachtwoord aan te passen.";
                this.failure = info;
                this.disableSubmitButton = false;
                this.cd.detectChanges();

            },
            (failure:RequestFailure) => {

                //App won't end up here. There is no failure for get-token or login
                this.logger.log("[PasswordForgetComponent] " + "Login failed" + failure);

                this.usernameInput.nativeElement.focus();
                this.failure = failure;
                this.disableSubmitButton = false;
                this.cd.detectChanges();

            }
        );
    }
    
    public handleCancel(event:MouseEvent)
    {
        this.router.navigate([LuminizerRoutes.LOGIN_PAGE]);
    }
    
    public handleKeyDown($event: any) {
        if ($event.keyCode === 13) {
            $event.preventDefault();
            this.handleClickSend($event);
        }
    }
}
