import {FilterCommand} from '../filterableTable/filter.interface';

/**
 * Created by Christiaan on 10/05/2017.
 */
export interface TableOptionsFilter {
    command?: FilterCommand;
    values?: string[]
}

export interface TableOptionsField {
    label?: string
    code: string
    filter: TableOptionsFilter|null
    inMouseOver?: boolean
    default: boolean
    columnIndex?: number
    removable?: boolean
    normValues?: string[]
    type?: string
    sorting: boolean
    sortDirection: number
    visible?: boolean
    columnId?: string|number
    columnRank?: number
}

export class TableOptions {
    //Table columns filter settings
    public tableFields: TableOptionsField[] = [];

    //Show archived items or not
    public showArchived: boolean = false;
}

export interface TableOptionsSet {
    id: number|string;
    name: string;
    type?:string;
    isPreferred?: boolean;
}
