
//http://blog.canberraphotography.com.au/googlewms/wms.js

import Utils from "./utils";

declare var google: any;

//TODO: Een gedeelte hiervan zit al in Utils. Omzetten en testen
export default class WMSUtils {

    private pixelOrigin_:any;
    private pixelsPerLonDegree_:any;
    private pixelsPerLonRadian_:any;

    private bound(value:any, opt_min:any, opt_max:any):number
    {
        if (opt_min != null) { value = Math.max(value, opt_min); }
        if (opt_max != null) { value = Math.min(value, opt_max); }
        return value;
    }

    constructor() {
        let MERCATOR_RANGE = 256;
        this.pixelOrigin_ = new google.maps.Point(
            MERCATOR_RANGE / 2, MERCATOR_RANGE / 2);
        this.pixelsPerLonDegree_ = MERCATOR_RANGE / 360;
        this.pixelsPerLonRadian_ = MERCATOR_RANGE / (2 * Math.PI);
    };

    public fromLatLngToPoint (latLng:any, opt_point:any):any {

        let point = opt_point || new google.maps.Point(0, 0);

        let origin = this.pixelOrigin_;
        point.x = origin.x + latLng.lng() * this.pixelsPerLonDegree_;
        // NOTE(appleton): Truncating to 0.9999 effectively limits latitude to
        // 89.189.  This is about a third of a tile past the edge of the world tile.
        let siny:number = this.bound(Math.sin(Utils.degreesToRadians(latLng.lat())), -0.9999, 0.9999);
        point.y = origin.y + 0.5 * Math.log((1 + siny) / (1 - siny)) * -this.pixelsPerLonRadian_;
        return point;
    };

    public fromDivPixelToLatLng(pixel:any, zoom:any):any
    {
        let origin = this.pixelOrigin_;
        let scale = Math.pow(2, zoom);
        let lng = (pixel.x / scale - origin.x) / this.pixelsPerLonDegree_;
        let latRadians = (pixel.y / scale - origin.y) / -this.pixelsPerLonRadian_;
        let lat:number = Utils.radiansToDegrees(2 * Math.atan(Math.exp(latRadians)) - Math.PI / 2);

        return new google.maps.LatLng(lat, lng);
    };

    public fromDivPixelToSphericalMercator(pixel:any, zoom:any):any
    {
        let coord:any = this.fromDivPixelToLatLng(pixel, zoom);

        let r:number = 6378137.0;
        let x:number = r * Utils.degreesToRadians(coord.lng());
        let latRad:number = Utils.degreesToRadians(coord.lat());
        let y:number = (r/2) * Math.log((1+Math.sin(latRad))/ (1-Math.sin(latRad)));

        return new google.maps.Point(x,y);
    };
}
