import {ChangeDetectorRef, Component, Input, OnInit, ViewChild} from '@angular/core';
import {WidgetService} from './widget.service';
import {RequestFailure} from '../../../shared/services/http/request-failure';
import {ExportOptions, Widget} from './widget';
import 'moment/locale/nl';
import {Router} from '@angular/router';
import {FormDataService} from '../../../shared/components/form/services/form-data.service';
import {BaseTableComponent} from '../../../shared/components/table/shared/baseTable/baseTable.component';
import {
    Cell, CellData,
    DataType,
    ExportType,
    HeaderData,
    RowData,
    TableData
} from '../../../shared/components/table/shared/baseTable/baseTable.interface';
import {TableColumn, TableRow} from '../../../shared/components/map-table/map-table.interface';
import {BaseTableService} from '../../../shared/components/table/shared/baseTable/baseTableService';
import {LoggerService} from "../../../shared/services/logger/logger.service";

@Component({
    selector: 'table-widget-component',
    template: `
        <div class="card widget-panel default-dropdown">
            <div class="widget-title d-flex">
                {{widget.title}}
                <menu-dropdown class="ml-auto mr-0" [menuTitle]="'menu.exportto' | translate">
                    <ng-container *ngFor="let option of exportOptions">
                        <div menuItem *ngIf="option.show" class="form-setting-option md-focusable"
                             (click)="handleExportClick(option.name)"
                             title="{{'menu.exportexceltitle' | translate}}">{{option.label}}</div>
                    </ng-container>
                </menu-dropdown>
            </div>
            <div class="table-widget-container">
                <base-table-component
                        *ngIf="tableData"
                        #baseTableComponent
                        [tableOptions]="{
                            mobileMode: false,
                            clearHeader: true,
                            canActivateRows: canActivateRows
                        }"
                        [isLoading]="isLoading"
                        [tableData]="tableData"
                        (onRowAction)="handleClickTableRow($event)">
                </base-table-component>
            </div>

            <div *ngIf="!isLoading && (widget.description || widget.showRowCount)"
                 class="widget-subtext-container d-flex align-items-center py-1">

                <div><i class="material-icons md-18 widget-subtext-icon py-0 ">info_outline</i></div>
                <div class="d-flex justify-content-between w-100 flex-wrap">
                    <div *ngIf="widget.description">{{widget.description}}</div>
                    <div *ngIf="widget.showRowCount">
                        {{this.baseTableComponent.getVisibleRows().length + this.baseTableComponent.getHiddenRows().length}} {{'Items' | translate}}
                    </div>
                </div>
            </div>
        </div>
    `,
})
export class TableWidgetComponent implements OnInit {
    @Input('widget') public widget: Widget;
    public tableData: TableData = {
        headers: [],
        rows: []
    };
    public isLoading: boolean = true;
    public exportOptions: ExportOptions[] = [];
    public canActivateRows: boolean = false;
    @ViewChild('baseTableComponent', {static: false}) public baseTableComponent: BaseTableComponent;
    private showExportOptions: boolean = true;
    
    /*    Example tabledata: private tableData_old:any = [
            [{label:"Werkordernummer"}, {label:"Gebruiker"}, {label:"Datum"}, {label:"Bericht"}],
            [{label:"1234"}, {label:"Jan"}, {label:"19-04-2017"}, {label:"Lamp stuk deed het niet oeps"}],
            [{label:"12323444"}, {label:"Piet"}, {label:"18-04-2017"},
            {label:"Lang bericht test 123 lsdlala lalalalalalalala sdlkf meer meer meer tekst jajajaj lalalalal oke Lang
            bericht test 123 lsdlala lalalalalalalala sdlkf meer meer meer tekst jajajaj lalalalal oke Lang bericht test
            123 lsdlala lalalalalalalala sdlkf meer meer meer tekst jajajaj lalalalal oke Lang bericht test 123 lsdlala
            lalalalalalalala sdlkf meer meer meer tekst jajajaj lalalalal oke"}],
            [{label:"12323666"}, {label:"Klaas"}, {label:"17-04-2017"}, {label:"test123"}]
        ]*/
    
    constructor(
        private widgetService: WidgetService,
        private cd: ChangeDetectorRef,
        private router: Router,
        private baseTableService: BaseTableService,
        protected logger:LoggerService
    ) {
    }
    
    ngOnInit() {
        if (!this.widget.exportOptions) {
            this.exportOptions = [
                {name: 'excel', label: 'Excel (.xlsx)', show: true},
                {name: 'csv', label: 'Csv (.csv)', show: true},
                {name: 'clipboard', label: 'Clipboard', show: true},
            ];
        } else {
            this.exportOptions = this.widget.exportOptions;
        }
        
        if (this.widget.showExportOptions === false) {
            this.showExportOptions = this.widget.showExportOptions;
        }
        
        this.isLoading = true;
        this.widgetService.getWidgetData(this.widget.id, '',
            (json: TableWidgetData) => {
                this.isLoading = false;
                this.widget.title = json.title ? json.title : this.widget.title;
                
                // TODO: json to widget kan efficienter via transform class
                this.logger.log('[TableWidgetComponent] ' + 'set table data: ', json.table);
                
                this.setTableData(json.table);
                this.widget.description = json.description;
                
                // Search for showRowCount in settings
                if (json.settings && json.settings.showRowCount) {
                    this.widget.showRowCount = json.settings.showRowCount;
                } else {
                    this.widget.showRowCount = false;
                }
                this.cd.markForCheck();
            },
            (failure: RequestFailure) => {
            
            },
        );
        
        this.cd.markForCheck();
    }
    
    handleExportClick(option: string) {
        switch (option) {
            case 'excel':
                this.handleExportToExcel();
                break;
            case 'csv':
                this.handleExportToCsv();
                break;
            case 'clipboard':
                this.handleCopyToClipboard();
                break;
            default:
                this.handleExportToExcel();
        }
    }
    
    handleClickTableRow(clickData: RowData[]): void {
        const clickDataIndex = this.tableData.headers.findIndex(header => header.type === DataType.ACTION);
        const clickActionCell = clickData[0].cells[clickDataIndex];
        
        this.logger.log('[TableWidgetComponent] handleClickTableRow: '+clickActionCell.label, clickData);
        
        if (this.hasClickAction(clickActionCell)) {
            this.logger.log('[TableWidgetComponent] ' + 'clickaction executed: ' + clickActionCell.label);
            this.router
                .navigateByUrl(clickActionCell.label + '?origin=' + FormDataService.ORIGIN_WIDGET)
                .catch(err => this.logger.log(err));
        }
    }
    
    public hasClickAction(clickActionCell: CellData): boolean {
        return clickActionCell && clickActionCell.label && clickActionCell.label !== '';
    }
    
    private handleExportToExcel(): void {
        this.baseTableComponent.exportTable(ExportType.XLSX);
    }
    
    private handleExportToCsv(): void {
        this.baseTableComponent.exportTable(ExportType.CSV);
    }
    
    private handleCopyToClipboard(): void {
        this.baseTableComponent.exportTable(ExportType.CLIPBOARD);
    }
    
    private setTableData(
        tableData: (TableRow | TableColumn[])[]
    ) {
        if (tableData.length < 1) {
            return;
        }
        this.tableData = this.baseTableService.createTableData(
            <TableRow[]>tableData.slice(1, tableData.length),
            <TableColumn[]>tableData[0]
        );
        const clickDataIndex = this.tableData.headers.findIndex(header => header.type === DataType.ACTION);
        this.canActivateRows = this.tableData.rows.some(
            row => row.cells[clickDataIndex] && row.cells[clickDataIndex].label && row.cells[clickDataIndex].label !== ''
        );
        setTimeout(() => {
            this.baseTableComponent.updateTable();
        });
    }
}

export interface TableWidgetData {
    table: (TableRow | TableColumn[])[]
    title: string
    description: string
    settings: {
        showRowCount: boolean
    }
}
