/**
 * Created by Jasper on 28/11/2019.
 */
import {
    ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, NgZone, Output
} from '@angular/core';
import {GlobalModel} from '../../../services/state/global.model';
import Utils from '../../../utils/utils';

@Component({
    selector: 'keyboard-button-icon',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template:`        
        <div class="keyboard-icon" [innerHTML]="getShortcutKey()"></div>
    `
})

export class KeyboardButtonIcon {

    // Hier je declaraties aan variabelen
    @Input() type:String;

    constructor(private cd: ChangeDetectorRef) {}

    public getShortcutKey():string
    {
        if(!this.type){
            if (Utils.isMacintosh()){
                return 'CMD'
            }else{
                return 'CTRL'
            }
        } else {
            return this.type.toString();
        }
    }

}
