/**
 * Created by Jasper Kuiper on 08/04/2021.
 */

import {Injectable} from '@angular/core';

@Injectable()
export class PasswordGeneratorService {

    public showGenerator:boolean = false;
    public passwordLength:number;
    public numbersChecked:boolean;
    public numbersDisabled:boolean;
    public symbolsChecked:boolean;
    public symbolsDisabled:boolean;

    public handleClickNumbers():void {
        if(this.symbolsChecked && !this.symbolsDisabled){
            this.symbolsDisabled = true;
        } else if (this.symbolsChecked && this.symbolsDisabled){
            this.symbolsDisabled = false;
        }
        this.numbersChecked = !this.numbersChecked;
    }

    public handleClickSymbols():void {
        if(this.numbersChecked && !this.numbersDisabled){
            this.numbersDisabled = true;
        } else if (this.numbersChecked && this.numbersDisabled){
            this.numbersDisabled = false;
        }
        this.symbolsChecked = !this.symbolsChecked;
    }

    public handleShowGenerator():void {
        this.passwordLength = 10;
        this.numbersChecked = true;
        this.numbersDisabled = true;
        this.symbolsChecked = false;
        this.symbolsDisabled = false;
        this.showGenerator = !this.showGenerator;
    }

    public handleChangePasswordLength(e:any) {
        this.passwordLength = e.target.value;
    }

    public handleGeneratePassword() {
        'use strict';
        const resultEl = document.getElementById('result'),
            lengthEl = <HTMLInputElement>document.getElementById('length'),
            // See template, settings for generator. Eg. if you turn on uppercase, put hasUpper to uppercaseEl.checked instead of 'true'
            // uppercaseEl = <HTMLInputElement>document.getElementById('uppercase'),
            // lowercaseEl = <HTMLInputElement>document.getElementById('lowercase'),
            numbersEl = <HTMLInputElement>document.getElementById('numbers'),
            symbolsEl = <HTMLInputElement>document.getElementById('symbols');

        const randomFunc = {
            lower: this.getRandomLower,
            upper: this.getRandomUpper,
            number: this.getRandomNumber,
            symbol: this.getRandomSymbol,
        };

        const length = +lengthEl.value,
            hasLower = true,
            hasUpper = true,
            hasNumber = numbersEl.checked,
            hasSymbol = symbolsEl.checked;

        resultEl.innerText = generatePassword(
            hasLower,
            hasUpper,
            hasNumber,
            hasSymbol,
            length
        );

        function generatePassword(lower, upper, number, symbol, length){
            let generatedPassword = '';
            const typesCount = lower + upper + number + symbol,
                typesArr = [{ lower }, { upper }, { number }, { symbol }].filter(
                    (item) => Object.values(item)[0]
                );
            if (typesCount === 0) {
                return '';
            }

            for (let i = 0; i < length; i += typesCount) {
                typesArr.forEach((type) => {
                    const funcName = Object.keys(type)[0];
                    generatedPassword += randomFunc[funcName]();
                });
            }

            return generatedPassword.slice(0, length);
        }
    }

    public handleClipboard():void {
        const resultEl = document.getElementById('result');
        const textarea = document.createElement('textarea'),
            password = resultEl.innerText;

        if (!password) { return; }

        textarea.value = password;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand('copy');
        textarea.remove();
    }
    
    private getRandomLower() {
        return String.fromCharCode(Math.floor(Math.random() * 26) + 97);
    }

    private getRandomUpper() {
        return String.fromCharCode(Math.floor(Math.random() * 26) + 65);
    }

    private getRandomNumber() {
        return String.fromCharCode(Math.floor(Math.random() * 10) + 48);
    }

    private getRandomSymbol() {
        const symbols = '!@#$%*&(){}[]=<>/,.';
        return symbols[Math.floor(Math.random() * symbols.length)];
    }

}
