/**
 * Created by Christiaan on 01/03/2017.
 */
import {
    AfterViewInit,
    ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, ViewChild,
} from '@angular/core';

import {GlobalAlertService} from '../global-alert.service';
import {ButtonCode, GlobalPopup} from '../global-popup';
import {HTTPError} from '../../../shared/services/http/http-error';
import {RequestFailure} from '../../../shared/services/http/request-failure';
import {FormDataService} from '../../../shared/components/form/services/form-data.service';
import {GlobalModel} from '../../../shared/services/state/global.model';
import {FormComponent} from '../../../shared/components/form/containers/form/form.component';
import {AuthorizationService} from '../../../shared/services/authorization/authorization.service';
import {TranslateService} from '../../../shared/services/translate/translate.service';
import {DefaultPopupWrapperComponent} from './default-popup-wrapper/default-popup-wrapper.component';
import {AbstractBasePopupComponent} from './abstract-base-popup.component';
import {TooltipService} from '../../../shared/services/tooltip/tooltip.service';
import { FormEvent } from '../../../shared/components/form/containers/form/form.interface';
import {LoggerService} from "../../../shared/services/logger/logger.service";

@Component({
    selector: 'global-popup-create-msb-melding-component',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <default-popup-wrapper-component #wrapper [componentRef]="this"
                                         [disableButtonsOverride]="disableButtonsOverride" [globalPopup]="globalPopup"
                                         [showLoader]="isLoading" (onClose)="closePopup($event)">
            <ng-container *ngIf="formData && config">
                <form-component #form
                                [config]="config"
                                [name]="getFormName()"
                                [validationConstraints]="validationConstraints"
                                [invalidControlsErrors]="invalidControlsErrors"
                                [readOnly]="readOnly"
                                [formIsSubmitted]="formIsSubmitted"
                                (onComponentEvent)="handleComponentEvent($event)">
                </form-component>
            </ng-container>
        </default-popup-wrapper-component>
    `,
})
export class GlobalPopUpCreateMsbMeldingComponent extends AbstractBasePopupComponent implements AfterViewInit {
    private static readonly FORM_URL: string = 'report-malfunction/msb-melding/pair/';

    @Input() itemId: number = 0;
    @ViewChild('form', {static: false}) form: any;
    @ViewChild('wrapper', {static: false}) wrapper: DefaultPopupWrapperComponent;

    disableButtonsOverride: any = (button: any) => {
        return (this.config && this.config.disabled && (button.code !== ButtonCode.ANNULEREN)) ||
            (this.form && this.form.form && this.form.form.status === 'INVALID' && button.code !== ButtonCode.ANNULEREN);
    };

    public constructor(protected cd: ChangeDetectorRef, protected globalAlertService: GlobalAlertService,
                       private auth: AuthorizationService, protected formDataService: FormDataService,
                       protected elementRef: ElementRef, private model: GlobalModel,
                       protected ts: TranslateService, protected tooltipService: TooltipService, protected logger:LoggerService) {
        super(cd, globalAlertService, elementRef, ts, tooltipService, logger);
    }

    ngAfterViewInit() {

        // Retrieve map item id from the popup data
        this.itemId = this.globalPopup.data.mapItemId;

        let popupData: any = this.globalPopup.data;
        if (popupData && popupData.lat && popupData.lng) {
            this.model.formLocationComponentPosition = {lat: popupData.lat, lng: popupData.lng};
        }
        else {
            this.model.formLocationComponentPosition = null;
        }

        this.submitButton = this.getSubmitButton();

        this.requestFormData();
    }

    handlePopupAction(event: MouseEvent, alert: GlobalPopup, button: any): void {
        // callback for submit button comes from this form, not from outside this component
        if (button === this.submitButton) {
            this.form.handleComponentEvent({event: FormEvent.SAVE, data: {}});
            // this.form.submitForm();
        }
        else {
            // Hide alert and perform callback of button
            this.onPopupAction.emit({event: event, alert: alert, button: button});
        }
    }

    handleComponentEvent(eventData: any): void {

        if (eventData.event == FormEvent.SAVE) {
            this.submitFormData(eventData.data.formData);
        }
    }

    private submitFormData(form: any): void {
        if (this.itemId === 0) {
            this.logger.log('[GlobalAlertMoveItemComponent] ' + 'ERROR: trying to submit the form, but it is not linked to an item');
            return;
        }

        this.handleSubmitForm();

        this.formDataService.setFormData(form, this.getFormName(), GlobalPopUpCreateMsbMeldingComponent.FORM_URL + 'update/' + this.itemId,
            (formData: any) => {
                // this.formData = formData;
                this.handleSubmitResponse();
                // Hide alert and perform success callback via submit button
                this.onPopupAction.emit({
                    event: null,
                    alert: this.globalPopup,
                    button: this.submitButton,
                    data: formData,
                });
                if (['stedin',
                    'brandt_overdag',
                    'hele_straat_uit'].includes(formData.schema.storingsCode.toLowerCase())) {
                    this.globalAlertService.addPopupCreateGenericReport(
                    'report-generic/new-report-for-msb-melding',
                    'report-generic/save-report-for-msb-melding/' + formData.schema.msbMeldingAssetId,
                    (buttonCode: any, response: any) => {
                        // Show success alert
                        this.globalAlertService.addAlertSuccess(
                            this.ts.translate('reportgeneric.reportsuccesstitle'),
                            this.ts.translate('reportgeneric.reportsuccesssubtitle'),
                            this.ts.translate('reportgeneric.reportsuccesslabel',
                                [this.model.currentAreaal.value.label]));

                        // Refresh the map icon
                        if (response && response.schema && response.schema.msbMeldingAssetId) {
                            this.onComponentEvent.emit({
                                event: FormEvent.MSB_MELDING_RELOAD_FORM,
                                data: {msbMeldingAssetId: response.schema.msbMeldingAssetId}
                            });
                        } else {
                            this.logger.log('[ReportGenericComponent] ' + 'ERROR: no base_object_id given');
                        }
                    }, () => {
                    }, formData.schema.latitude, formData.schema.longitude, formData.schema.msbMeldingAssetId);
                }
            },
            (failure: RequestFailure) => {
                this.handleSubmitResponse(failure.formErrors);
            },
            (error: HTTPError) => {
                this.handleSubmitResponse(null, true);
            },
        );

    }

    closePopup(event: any): void {
        this.doDefaultCloseHandling(event, false);
    }

    public requestFormData(): void {
        this.formDataService.getFormData(GlobalPopUpCreateMsbMeldingComponent.FORM_URL + 'get/' + this.itemId,
            (formData: any) => {

                // TODO: pas hier de latlng van de popup.data toe op de formData, als beschikbaar
                // En update dus de kaart en de locatie enzo

                // setTimeout(() => {
                this.formData = formData;
                this.isLoading = false;


                this.cd.detectChanges();
                this.wrapper.runChangeDetection();
                // });
            },
            () => {
                this.closePopup(null);
            },
            () => {
                this.closePopup(null);
            },
        );
    }
}
