<ng-container *ngFor="let log of logs">
    <complex-list-item [item]="log.listItem"
                       [fancy]="false"
                       [smallTitle]="true">
        <div *ngIf="log.message != ''; else noMessage" class="form-log-message pt-1">{{log.message}}</div>
        <ng-template #noMessage>
            <div class="form-log-message no-message pt-1">{{'log.nomessage' | translate}}</div>
        </ng-template>
    </complex-list-item>
</ng-container>
<div class="" *ngIf="!logs || logs.length <= 0">
    {{'logs.nologs' | translate}}
</div>
