import {inject, Injectable} from '@angular/core';
import {HTTPError} from './http-error';
import {AppSettings} from '../../../app.settings';
import {RequestError} from './request-error';
import {RequestFailure} from './request-failure';
import Utils from '../../utils/utils';
import {GlobalModel} from '../state/global.model';
import {GlobalAlertService} from '../../../wrapper/global-alert/global-alert.service';
import {ActivatedRoute} from '@angular/router';
import {BehaviorSubject} from 'rxjs';
import {TranslateService} from '../translate/translate.service';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {GlobalEvent} from '../../interfaces/global-event';
import * as Sentry from '@sentry/angular-ivy';
import {LoggerService} from "../logger/logger.service";

@Injectable()
export class HTTPService {
    //Matches with JSend status codes
    //https://labs.omniti.com/labs/jsend
    private static readonly RESPONSE_STATUS_SUCCESS: string = 'success';
    private static readonly RESPONSE_STATUS_FAIL: string = 'fail';
    private static readonly RESPONSE_STATUS_ERROR: string = 'error';

    private headersGet: HttpHeaders;
    private headersPost: HttpHeaders = new HttpHeaders({'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'});
    private headersPostForm: HttpHeaders = new HttpHeaders({'Content-Type': 'application/json'});

    // TODO: deze waarde is nu public, maar eigenlijk mag hij van buitenaf niet geset worden. Dit is nu gedaan zodat de binding werkt.
    //  Zet hem om naar obserable of andere oplossing

    // TODO: dit lijkt verkeerd geimplementeerd. is _pendingCallPaths niet gewoon pendingCallPaths.value?. En hoort dit niet in het globalmodel?
    public pendingCallPaths: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
    private _pendingCallPaths: string[] = [];
    private outOfSession: boolean = false;

    constructor(private http: HttpClient, private model: GlobalModel, private globalAlertService: GlobalAlertService, private activatedRoute: ActivatedRoute, private ts: TranslateService, protected logger:LoggerService) {
        // TODO: deze stonden ooit in de init (die niet werd uitgevoerd)
        //  tot nu toe waren de headers voor de get call undefined
        //  Dus ooit moeten die nog wel echt geset worden (zal nu wel reverten naar een geschikte default)
        //Init headers
        //this.headersGet = new HttpHeaders();
        //this.headersGet.append('Content-Type', 'application/json');

        //this.defaultHeaders.append('Content-Type', 'application/x-www-form-urlencoded'); //not for json?
        //this.defaultHeaders.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8')
        //this.defaultHeaders.append('Content-Type', 'application/json');
        //this.defaultHeaders.append('Content-Type', 'multipart/form-data');
        //this.headersPost = new Headers({ 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' });

    }

    public getQueryParam(param: string): string {
        let result = '';

        if (this.activatedRoute.snapshot.queryParams[param]) {
            result = this.activatedRoute.snapshot.queryParams[param];
        }

        return result;
    }

    public getPendingCalls() {
        return this._pendingCallPaths;
    }

    //TODO: iedereen die hier gebruik van maakt zou eigenlijk ook via observable moeten werken en dan changedetection triggeren
    public hasPendingCall(): boolean {
        return this._pendingCallPaths.length > 0;
    }

    //Check is a request for a callpath is currently pending
    //Example usage: httpService.isPendingCallPath([LoginService.ISLOGGEDIN_PATH, LoginService.LOGIN_PATH])"
    public isPendingCallPath(callPaths: string[]): boolean {

        for (let pendingCallPath of this._pendingCallPaths) {
            for (let callPath of callPaths) {
                //TODO: dit kan mooier (met een type gekoppeld aan een call), maar werkt voor nu
                if (pendingCallPath.indexOf(callPath) != -1) {
                    //if (pendingCallPath == callPath){
                    return true;
                }
            }
        }

        return false;
    }

    public removePendingCall(callPath: string) {
        let foundIndex: number = this._pendingCallPaths.indexOf(callPath, 0);

        if (foundIndex > -1) {
            this._pendingCallPaths.splice(foundIndex, 1);
            this.logger.log('[HTTPService] ' + 'Call ' + callPath + ' removed. Left: [' + this._pendingCallPaths + ']');
        }

        this.pendingCallPaths.next(this._pendingCallPaths);
    }

    public addPendingCall(callPath: string) {
        this._pendingCallPaths.push(callPath);
        this.pendingCallPaths.next(this._pendingCallPaths);
        this.logger.log('[HTTPService] ' + 'Pending calls: ' + this._pendingCallPaths);
    }

    private getURL(path: string, sendAreaId: boolean) {
        if (sendAreaId) {
            return AppSettings.getBaseAngularUrl(path) + 'a/' + this.model.currentAreaal.getValue().id + '/' + path;
        }
        else {
            return AppSettings.getBaseAngularUrl(path) + path;
        }
    }

    public doGetRequest(path: string, successCallBack?: (json: any, url?: string) => any, failureCallBack?: (failure: RequestFailure) => any, errorCallBack?: (error: HTTPError) => any, sendAreaId: boolean = true, usePathAsFullUrl?: boolean) {
        //Create a new var, this is async, so "this" cant be used or you might refer to a changed value
        let url: string = usePathAsFullUrl ? path : this.getURL(path, sendAreaId);
        this.logger.log('[HTTPService] ', url);
        this.addPendingCall(path);

        // TODO: de headers zijn hier undefined, maar vallen waarschijnlijk terug naar een geschikte default. Zie notes in de constructor.
        //  je zou ze ook leeg kunnen laten hier waarschijnlijk
        this.http.get(url, {
            headers: this.headersGet,
        }).subscribe(
            success => {
                //TODO: heeft een get ooit waardes? for now, empty postvalues
                this.handleHTTPSuccess(url, path, success, '', successCallBack, failureCallBack, errorCallBack);
            },
            error => {
                this.handleHTTPError(path, error, errorCallBack);
            },
            () => {
                //Is not executed in case of an error (e.g. 404)
                this.logger.log('[HTTPService] ' + 'Call finished [' + Utils.getLoggableObject(url) + ']');
            });
    }

    //Generates a string like //"username=test&password=123" from a JSON-Object
    private convertJSONToParams(json: any): HttpParams {

        /*var str = [];
        for (var key in obj) {
            if (obj.hasOwnProperty(key)) {
                str.push(encodeURIComponent(key) + "=" + encodeURIComponent(obj[key]))
                this.logger.log(key + " -> " + obj[key]);
            }
        }
        return str.join("&");*/

        let params: HttpParams = new HttpParams();

        params = this.convertJSONToParamsRecursive(params, json, '', 0, true);

        return params; //.toString();
    }

    //Output:
    // param                    "value0
    // objectArray[prop1][0]    "value1"
    // objectArray[prop2][0]    "value2"
    // objectArray[prop1][1]    "value3"
    // objectArray[prop2][1]    "value4"
    private convertJSONToParamsRecursive(params: HttpParams, json: any, prefix: string, arrayIndex: number, firstLevel: boolean): HttpParams {
        //TODO: kan je niet beter append gebruiken ipv set
        let jsonValue: any;

        for (let key in json) {
            jsonValue = json[key];
            if (jsonValue instanceof Array) {
                jsonValue.forEach((item: any, index: number) => {
                    if (item instanceof Object) {
                        params = this.convertJSONToParamsRecursive(params, item, prefix + '[' + key + ']', index, false);
                    }
                    else {
                        params = params.set(prefix + '[' + key + ']' + '[' + index + ']', item);
                    }
                });
            }
            else if (jsonValue instanceof Object) {
                if (firstLevel) {
                    params = this.convertJSONToParamsRecursive(params, jsonValue, prefix + key, -1, false);
                }
                else {
                    params = this.convertJSONToParamsRecursive(params, jsonValue, prefix + '[' + key + ']', -1, false);
                }
            }
            else {
                if (firstLevel) {
                    params = params.set(prefix + key, jsonValue);
                }
                else if (arrayIndex != -1) {
                    params = params.set(prefix + '[' + arrayIndex + ']' + '[' + key + ']', jsonValue);
                }
                else {
                    params = params.set(prefix + '[' + key + ']', jsonValue);
                }
            }
        }

        return params;
    }

    //The actual post request
    private doBasicPostRequest(headers: HttpHeaders, body: HttpParams, path: string, postValues: any, successCallBack?: (json: any, url?: string) => any, failureCallBack?: (failure: RequestFailure) => any, errorCallBack?: (error: HTTPError) => any, sendAreaId?: boolean, usePathAsFullUrl?: boolean) {
        //Create a new var, this is async, so "this" cant be used or you might refer to a changed value

        let url: string = usePathAsFullUrl ? path : this.getURL(path, sendAreaId);
        this.addPendingCall(path);

        this.http.post(url, body, {
            headers: headers,
        })//.map((response: HttpResponse) => {

            //Map the response
            //return response.json();

            //TODO: naast map ook nog catch implementeren voor het afvangen van fouten? Of wordt dat bij de observable hieronder al voldoende afgevangen
            //catch doet hetzelfde als error dus niet perse nodig, je kan nog wel data parsen oid

            //        })
            .subscribe(
                success => {
                    this.handleHTTPSuccess(url, path, success, postValues, successCallBack, failureCallBack, errorCallBack);
                },
                error => {
                    this.handleHTTPError(path, error, errorCallBack, postValues);
                },
                () => {
                    //Is not executed in case of an error (e.g. 404)
                    this.logger.log('[HTTPService] ' + 'Call finished [' + Utils.getLoggableObject(url) + ']');
                });
    }

    //A default post request with form body
    public doPostRequest(path: string, postValues: any, successCallBack?: (json: any, url?: string) => any, failureCallBack?: (failure: RequestFailure) => any, errorCallBack?: (error: HTTPError) => any, sendAreaId: boolean = true, usePathAsFullUrl?: boolean) {
        let body: HttpParams = this.convertJSONToParams(postValues);

        this.doBasicPostRequest(this.headersPost, body, path, postValues, successCallBack, failureCallBack, errorCallBack, sendAreaId, usePathAsFullUrl);
    }

    //A default post request with json body
    public doFormPostRequest(path: string, postValues: any, successCallBack?: (json: any, url?: string) => any, failureCallBack?: (failure: RequestFailure) => any, errorCallBack?: (error: HTTPError) => any, sendAreaId: boolean = true) {
        this.doBasicPostRequest(this.headersPostForm, postValues, path, postValues, successCallBack, failureCallBack, errorCallBack, sendAreaId);
    }

    private handleHTTPSuccess(url: string, path: string, success: any, postValues: any, successCallBack: (json: any, url: string) => any, failureCallBack: (failure: RequestFailure) => any, errorCallBack: (error: HTTPError) => any) {
        this.removePendingCall(path);

        if (success != null) {
            if (success.status != null) {
                switch (success.status) {
                    case HTTPService.RESPONSE_STATUS_SUCCESS: {
                        //Response is in json, not mapped jet. Use it as you please
                        if (successCallBack) {
                            successCallBack(this.ts.processTranslationFromHTTP(success.data), url);
                        }
                        break;
                    }
                    case HTTPService.RESPONSE_STATUS_FAIL: {

                        //Extract error from failure or form errors
                        let failure: RequestFailure;

                        if (success.data.failure) {
                            //let classObject:any = plainToClass(RequestFailure, success.data.failure);
                            let classObject: any = success.data.failure;
                            failure = classObject as RequestFailure;

                            if (failure.displayAsAlert) {
                                this.globalAlertService.addAlertFailure(failure);
                            }
                            else if (failure.displayAsPopup) {
                                this.globalAlertService.addPopup(failure.title, failure.message, [{
                                    label: this.ts.translate('Ok'),
                                    code: 'OK',
                                    isPrimary: true,
                                }], () => {
                                });
                            }
                        }
                        else if (success.data.formErrors) {
                            failure = new RequestFailure();
                            failure.formErrors = success.data.formErrors;
                        }
                        else {
                            this.globalAlertService.addAlertEmptyResponse(url);
                        }

                        if (failureCallBack) {
                            failureCallBack(failure);
                        }
                        break;
                    }
                    case HTTPService.RESPONSE_STATUS_ERROR: {
                        if (success.data.error) {
                            this.handleErrorStatus(success.data.error, errorCallBack);
                        }
                        else {
                            this.globalAlertService.addAlertEmptyResponse(url);
                        }
                        break;
                    }
                    default: {
                        this.logger.error('[HTTPService] ' + 'Unknown status with response. URL: ' + url + ' Status: ' + status);
                        break;
                    }
                }
            }
            else {
                this.handleNoStatusError(url, postValues, success);
            }
        }
        else {
            this.logger.log('[HTTPService] ' + 'No jsondata received for call: ' + url);
        }
    }

    //An error on the server that is handled with a correctly generated error response
    private handleErrorStatus(json: any, errorCallBack: (error: HTTPError) => any) {
        //let classObject:any = plainToClass(RequestError, json);
        let error: RequestError = json as RequestError;

        this.globalAlertService.addAlert(GlobalAlertService.ALERT_TITLE_ERROR, error.title, error.message);

        if (errorCallBack) {
            // TODO: echte errors erros krijgen een HTTPError, maar deze error wordt zonder http-fout gegenereerd in de backend, van het type RequestError
            errorCallBack(null);
        }
    }

    private handleNoStatusError(url: string, postValues: any, jsonResponse: any) {
        this.logger.error('[HTTPService] ' + 'ERROR: No status given with response. No valid JSend.');
        this.logger.error('[HTTPService] ' + 'URL: [' + url + '] \nPOSTVALUES: ', postValues);
        this.logger.error('[HTTPService] ' + 'URL: [' + url + '] \nRESPONSE: ', jsonResponse);

        this.globalAlertService.addAlert(GlobalAlertService.ALERT_TITLE_ERROR, 'No valid JSend', 'No status was given with the response. The response is not formed according the JSend specifications.<br>' + 'URL: ' + url);
    }

    //A HTTP error without handling on the server
    //Executed when a 404/403/500/ect. error occurs
    public handleHTTPError(path: string, error: HTTPError, errorCallBack: (error: HTTPError) => any, postValues: any = null) {
        let alertTitle = GlobalAlertService.ALERT_TITLE_HTTP_ERROR;
        let helpText: string;
        let skipAlert = false;

        //TODO: Werk verder uit voor andere statussen
        //TODO: minder technische taal voor de eindgebruiker, deze begrijpt de bewoordingen waarschijnlijk niet
        //TODO: Nederlands maken van de foutmeldingen

        if (error.status) {
            switch (error.status.toString()) {
                case '0':
                    helpText = this.ts.translate('httperror.0'); //"The server can't be reached, or the request timed-out. Check your internet connection";
                    break;
                case '403':
                    alertTitle = GlobalAlertService.ALERT_TITLE_HTTP_FORBIDDEN;
                    helpText = this.ts.translate('httperror.403'); //"Het is niet toegestaan om deze actie uit te voeren";
                    //A bit ugly, but the initial calls of the application trigger an error without being a actual error. So don't show a message in those cases
                    if ([AppSettings.GET_LOGIN_TOKEN_PATH, AppSettings.CHECK_LOGIN_PATH, AppSettings.LOGIN_PATH].indexOf(path) != -1) {
                        skipAlert = true;
                    }
                    break;
                case '404':
                    helpText = this.ts.translate('httperror.404'); //"The URL can't be reached. The path might be incorrect or there is no routing defined on the server";
                    break;
                case '500':
                    helpText = this.ts.translate('httperror.500'); //"A script is causing an error on the server";
                    break;
                case '503':
                    helpText = this.ts.translate('httperror.503'); //"The service is unavailable. The webserver is under maintenance, disabled or overloaded";
                    break;
                default:
                    helpText = this.ts.translate('httperror.unknown'); //"Unknown error";
                    break;
            }
        }
        else {
            //Weird case in which the error object is empty, status is 200, but still seen as an error (not just empty response)
            helpText = 'httperror.ok'; //"Status OK received but the call is still recognized as an error. Check the response for more details.";
            error.status = '200';
            error.statusText = 'OK';
            error.url = path;
        }

        //Show alert popup
        if (!skipAlert && !this.outOfSession) {
            if (error.status.toString() == '0') {
                //Separate handling for 0-response, since not all values are available in that case
                this.globalAlertService.addAlert(alertTitle, '0 - No response', 'URL: ' + path + '<br>' + helpText);
            }
            if (error.status.toString() == '403' && error.url.match(/get-token/)) {
                this.outOfSession = true;

                // TODO: deze url is niet dynamisch. Op de link klikken in een volgend venster zorgt voor het inloggen+refreshen van het vorige.
                // this.globalAlertService.addAlert("Uw sessie is verlopen", "", "De data kon niet opgehaald worden omdat uw sessie is verlopen. <a href='" + window.location.href + "'>Log opnieuw in</a>");

                this.model.onGlobalEvent.next(new GlobalEvent(GlobalEvent.EVENT_OUT_OF_SESSION, {}));

                // window.open(window.location.origin, '_self');
                // Reopen the app on the base url whe if the received HTTP status code is 403 (forbidden)
                // (one could also choose the current window.location.href, but in case the previously accessed url
                // theoretically could itself return a 403 response again and then a login -> kick -> login loop would ensue!).
                // Either the user tried some action that was previously allowed
                // or the session is not valid anymore (no calls to back-end for too long), this last one will be the most occuring.
            }
            else if (helpText === 'httperror.ok') {
                this.globalAlertService.addAlert(alertTitle, 'Geen internet',  'URL: <span class=\'break-all\'>' +
                    error.url + '</span><br>' + 'Probeer opnieuw', error.error, GlobalAlertService.ALERT_ICON_SUCCESS, 1200);
                Sentry.captureException(error.error);
                this.logger.log(error.error);
            } else {

                this.globalAlertService.addAlertHTTPError(alertTitle, error.status + ' - ' + error.statusText, 'URL: <span class=\'break-all\'>' + error.url + '</span><br>' + helpText, error.error);
            }
        }

        //Remove from call-list
        this.removePendingCall(path);

        //Trigger a callback if there is one
        if (errorCallBack) {
            errorCallBack(error);
        }

        //Log the error in the console
        this.logger.log('----------------------------------------------');
        this.logger.log('HTTP ERROR ' + error.status + ' - ' + error.statusText);
        this.logger.log();
        this.logger.log(error);
        this.logger.log('----------------------------------------------');
    }
}
