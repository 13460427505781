/**
 * Created by Christiaan on 16/03/2017.
 */

import {Injectable} from '@angular/core';

import {HTTPService} from "../../../shared/services/http/http.service";
import {GlobalModel} from "../../../shared/services/state/global.model";
import {RequestFailure} from "../../../shared/services/http/request-failure";
import {HTTPError} from "../../../shared/services/http/http-error";
import {GlobalAlertService} from "../../../wrapper/global-alert/global-alert.service";
import {Router} from "@angular/router";
import {FormDataService} from "../../../shared/components/form/services/form-data.service";
import {ExportService} from '../../../shared/services/export/export.service';
import {HeaderData, RowData} from './widget.interface';

@Injectable() export class WidgetService { //extends HTTPService{

    public static readonly GET_WIDGET_DATA_PATH:string = "dashboard/widget/get" //"http://localhost/dashboard/1a" //"dashboard/get";

    constructor(private model:GlobalModel, private httpService:HTTPService, private globalAlertService:GlobalAlertService, private router:Router, private exportService: ExportService) {}

    public getWidgetData(id:number, params:string, successCallBack?:(json:any) => any, failCallBack?:(failure:RequestFailure) => any):any{

        this.httpService.doGetRequest(

            WidgetService.GET_WIDGET_DATA_PATH + "/" + id + params,
            (json:any, url:string) => {

                //this.logger.log("[WidgetService] " + "json: " + JSON.stringify(json));

                if(json) {
                    successCallBack(json);
                }else{
                    this.globalAlertService.addAlertEmptyResponse(url);
                }

            }, (failure:RequestFailure) => {

                failCallBack(failure)

            }, (error:HTTPError) => {
                //Error is handled in httpService
            });
    }

    public handleClickItem(url:string)
    {
        this.router.navigateByUrl(url + "?origin=" + FormDataService.ORIGIN_WIDGET);
    }

    public exportToExcel(headers: HeaderData[], rows: RowData[], columnChart:boolean = false): void {
        let tableData: string = this.getPlainTableData(headers, rows, '</td><td>', '</td></tr><tr><td>', false, columnChart);
        this.exportService.exportToExcel(tableData);
    }

    private getPlainTableData(headers: HeaderData[], rows: RowData[], columnSeparator = '\t', rowSeparator = '\n', escapeQuotes = false, columnChart:boolean = false): string {

        let result = '';

        if (headers) {
            for (let i = 0; i < headers.length; i++) {
                if (result !== '') {
                    result += columnSeparator;
                }
                result += headers[i].label;
            }

            // For every row
            for (let i = 0; i < rows.length; i++) {
                // Add a new line for every new row
                result += rowSeparator;

                // For every cell
                for (let j = 0; j < rows[i].c.length; j++) {
                    let label = rows[i].c[j].v;
                    result += label;

                    if (j < rows[i].c.length - 1) {
                        result += columnSeparator;
                    }
                }
            }
        }
        return result;
    }

}
