import {ChangeDetectorRef, Component, EventEmitter, Output, Renderer2} from '@angular/core';
import {AbstractFormFieldComponent} from '../abstract/abstract-form-field.component';
import {ValidationConstraintService} from '../../services/validation-constraint.service';
import {TooltipService} from '../../../../services/tooltip/tooltip.service';
import {GlobalModel} from '../../../../services/state/global.model';
import {FormDataService} from '../../services/form-data.service';
import {GlobalAlertService} from '../../../../../wrapper/global-alert/global-alert.service';
import {TranslateService} from '../../../../services/translate/translate.service';
import {FormEvent} from '../../containers/form/form.interface';
import {ListItem, ListItemMenuItemEvent} from '../../../commonUI/list/complex-list-item/complex-list-item.interface';
import { AppSettings } from 'src/app/app.settings';
import {
    Specification,
    WorkSpecificationConfig
} from './form-work-specification.interface';
import {ButtonCode} from '../../../../../wrapper/global-alert/global-popup';
import {LoggerService} from "../../../../services/logger/logger.service";

@Component({
    selector: 'form-work-specification',
    templateUrl: './form-work-specification.component.html'
})

export class FormWorkSpecificationComponent extends AbstractFormFieldComponent {
    
    @Output() onComponentEvent: EventEmitter<any> = new EventEmitter();
    
    public specifications: Specification[] = [];
    
    public AppSettings = AppSettings;
    public total: number;
    public config: WorkSpecificationConfig;
    public termijnstaatFinishedText: string;
    
    constructor(
        public renderer: Renderer2,
        public validationConstraintService: ValidationConstraintService,
        public tooltipService: TooltipService,
        private model: GlobalModel,
        private formDataService: FormDataService,
        private cd: ChangeDetectorRef,
        private globalAlertService: GlobalAlertService,
        public ts: TranslateService,
        protected logger:LoggerService
    ) {
        super(renderer, validationConstraintService, tooltipService, logger);
    }
    
    ngOnInit() {
        // Set the logs for the view
        if (this.config && this.config.attr) {
            if (this.config.attr.specifications) {
                this.specifications = this.config.attr.specifications.map(specification => {
                    specification.listItem = this.getListItem(specification);
                    return specification;
                });
                this.total = this.specifications.reduce((cumulative: number, specification: Specification) => {
                    cumulative = cumulative + specification.amount * specification.price;
                    return cumulative;
                }, 0);
                if (this.config.attr.termijnstaatFinishedText) {
                    this.termijnstaatFinishedText = this.config.attr.termijnstaatFinishedText;
                }
            }
        }
    }
    
    public isDeletable(specification: any): boolean {
        return specification.deleteUrl && specification.deleteUrl != '';
    }
    
    public isUpdatable(specification: any): boolean {
        return specification.editUrl && specification.editUrl != '';
    }
    
    public handleClickUpdateSpecification(event: MouseEvent, specification: any): void {
        let baseObjectId: number = this.rootConfig.base_object_id;
        
        this.globalAlertService.addPopupCreateWorkSpecification(baseObjectId, specification.editUrl, specification.saveEditUrl, null,
            () => {
                this.onComponentEvent.emit({
                    event: FormEvent.WORK_SPECIFICATION_CREATE_SUCCESS,
                    data: {referenceId: baseObjectId}
                });
            }, () => {
                // Move is canceled, or failed. Do nothing
            });
    }
    
    public handleClickDeleteSpecification(event: MouseEvent, specification: any): void {
        this.globalAlertService.addPopup(this.ts.translate('specification.deletetitle'), this.ts.translate('specification.delete'),
            [
                {
                    label: this.ts.translate('Annuleren'),
                    code: ButtonCode.ANNULEREN,
                    isPrimary: true
                },
                {
                    label: this.ts.translate('Verwijderen'), code: ButtonCode.DELETE,
                    callback: () => {
                        
                        // Show grayed-out specification until delete call finished
                        specification.isDeleted = true;
                        this.cd.detectChanges();
                        
                        this.formDataService.deleteWorkSpecification(specification.deleteUrl,
                            () => {
                                // Remove the old specification, client side
                                let deleteIndex: number = this.specifications.indexOf(specification);
                                
                                if (deleteIndex > -1) {
                                    this.specifications.splice(deleteIndex, 1);
                                    this.cd.detectChanges();
                                }
                                
                                this.onComponentEvent.emit({
                                    event: FormEvent.WORK_SPECIFICATION_DELETE_SUCCESS,
                                    data: {}
                                });
                            }, () => {
                                specification.isDeleted = false;
                                this.cd.detectChanges();
                            }, () => {
                                specification.isDeleted = false;
                                this.cd.detectChanges();
                            });
                    },
                    isPrimary: false
                }, ], () => {
            });
    }
    
    private getListItem(specification: Specification): ListItem {
        const item: ListItem = {
            id: JSON.stringify(specification),
            title: specification.code !== '' ? specification.code : this.ts.translate('specification.unknown'),
            icon: 'note_add',
            menuTitle: this.ts.translate('specification.menu'),
            menuItems: [],
            created: specification.created
        };
        if (this.isUpdatable(specification)) {
            item.menuItems.push({
                label: this.ts.translate('menuItem.edit'),
                icon: 'edit',
                action: 'edit'
            });
        }
        if (this.isDeletable(specification)) {
            item.menuItems.push({
                label: this.ts.translate('menuItem.delete'),
                icon: 'delete',
                action: 'delete'
            });
        }
        return item;
    }
    
    public onSpecificationAction($event: ListItemMenuItemEvent, specification: Specification): void {
        switch ($event.action) {
            case 'edit':
                this.handleClickUpdateSpecification(null, specification);
                break;
            case 'delete':
                this.handleClickDeleteSpecification(null, specification);
        }
    }
}
