import {Component, Input, OnInit} from '@angular/core';
import {SubStatusItem} from './sub-statuses.interface';
import {AppSettings} from '../../../../app.settings';

@Component({
    selector: 'sub-statuses',
    templateUrl: './sub-statuses.component.html'
})
export class SubStatusesComponent implements OnInit {
    @Input() public subStatuses: SubStatusItem[];
    @Input() public disabled: boolean = false;
    
    constructor() {
    }
    
    ngOnInit() {
    }
    
    public getColor(subStatus: SubStatusItem): { color: string } {
        if (!subStatus.color) {
            return {color: 'grey'};
        }
        if (this.disabled) {
            return {color: AppSettings.getColor('$color_darker-grey')};
        }
        return {color: subStatus.color};
    }
    
    public getBorderColor(subStatus: SubStatusItem): { 'border-color': string } {
        if (!subStatus.color || this.disabled) {
            return {'border-color': 'grey'};
        }
        if (this.disabled) {
            return {'border-color': AppSettings.getColor('$color_darker-grey')};
        }
        return {'border-color': subStatus.color};
    }
}
