import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    HostBinding,
    OnDestroy,
    Output,
    Renderer2, ViewChild,
} from '@angular/core';
import {ValidationConstraintService} from '../../services/validation-constraint.service';
import {FormInputComponent} from '../form-input/form-input.component';
import {GlobalAlertService} from '../../../../../wrapper/global-alert/global-alert.service';
import {TooltipService} from '../../../../services/tooltip/tooltip.service';
import {FormDataService} from '../../services/form-data.service';
import {GlobalModel} from '../../../../services/state/global.model';
import {TranslateService} from '../../../../services/translate/translate.service';
import {Subscription} from 'rxjs';
import * as moment from 'moment/moment';
import 'moment/locale/nl';
import {RequestFailure} from '../../../../services/http/request-failure';
import {HTTPError} from '../../../../services/http/http-error';
import {DimmingService} from '../../../../../modules/control/dimming/dimming.service';
import {AuthorizationService} from '../../../../services/authorization/authorization.service';
import {TinyGraphComponent} from '../form-graph/tiny-graph.component';
import { FormEvent } from '../../containers/form/form.interface';
import {ButtonCode} from '../../../../../wrapper/global-alert/global-popup';
import {LoggerService} from "../../../../services/logger/logger.service";

@Component({
    selector: 'dimming_exceptions',
    template: `
        <ng-container [formGroup]="group">
            <div class="d-flex flex-column" style="">
                <label *ngIf="exceptions.length <= 0">{{'exception.none' | translate}}</label>
                <ng-container *ngFor="let exception of exceptions">
                    <div class="d-flex flex-column p-2 mb-2 dimming-exception"
                         (mouseover)="handleMouseOver($event,exception)"
                         (mouseleave)="handleMouseOut($event,exception)">
                        <div class="{{(disabled() == null)?'cursor-pointer':'cursor-disabled'}}"
                             style="position: absolute; right:0; top:0;" title="{{'Item verwijderen' | translate}}"
                             *ngIf="(exception.isHovered || mobileMode) && exception.deleteUrl && auth.allowCreateException()">
                            <i class="material-icons p-2" style="font-size: 14px; padding: 0.1rem;"
                               (click)="handleExceptionRemove($event,exception)">clear</i>
                        </div>
                        <label class="dimming-exception-title" style="padding-left:2rem;"
                               title="{{'exception.name' | translate}}">{{exception.name}}</label>
                        <div class="d-flex flex-row">
                            <div class="d-flex flex-column col-6 p-0">
                                <label class="d-flex align-items-center pb-2 m-0"
                                       title="{{'exception.date' | translate}}"><i class="material-icons p-0 pr-2">date_range</i>{{exception.startDate}}
                                    t/m {{exception.endDate}}</label>
                                <label class="d-flex align-items-center m-0 break-long-words"
                                       title="{{'exception.scheme' | translate}}"><i class="material-icons p-0 pr-2">equalizer</i>{{exception.dimScheme}}
                                </label>
                            </div>
                            <div class="{{(disabled() == null && auth.allowEditDimmingScheme())?'cursor-pointer':'cursor-disabled'}} col-6 p-0"
                                 [title]="(auth.allowEditDimmingScheme()?'dimmingscheme.edit':'dimmingscheme.noedit') | translate"
                                 style="width: 100px; margin-top:-0.3rem;">
                                <tiny-graph #graph [graphHeight]="100"
                                            [dataUrl]="GRAPH_PATH + exception.dimSchemeId + '/exception'"
                                            (click)="handleClickGraph($event,exception)"></tiny-graph>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </ng-container>
    `,
})

export class DimmingExceptionsComponent extends FormInputComponent implements AfterViewInit, OnDestroy {
    @HostBinding('class') hostClasses = 'd-flex w-100 flex-column';
    @Output() onComponentEvent: EventEmitter<any> = new EventEmitter();
    @ViewChild('graph', {static: false}) tinyGraph: TinyGraphComponent;

    public static readonly GRAPH_PATH: string = 'control-form/dimgroup/scheme/graph/get/';

    private dataUrl: string = '';
    private isLoading: boolean = true;
    exceptions: DimmingExceptionInterface[] = [];
    private dimSchemes: any = [];

    mobileMode: boolean = false;
    private readonly subMobileMode: Subscription = null;

    constructor(public renderer: Renderer2, public validationConstraintService: ValidationConstraintService, public ts: TranslateService, public tooltipService: TooltipService, public globalAlertService: GlobalAlertService, private dimmingService: DimmingService, private formDataService: FormDataService, public cd: ChangeDetectorRef, public model: GlobalModel, public auth: AuthorizationService, protected logger:LoggerService) {
        super(renderer, validationConstraintService, tooltipService, globalAlertService, ts, logger, model);

        this.subMobileMode = this.model.mobileMode.subscribe((value: boolean) => {

            //Don't forget to unsubscribe
            this.mobileMode = value;
        });
    }

    private isDisabled() {
        return super.disabled() != null;
    }

    handleResize(event: any) {
        this.logger.log('[DimmingExceptionsComponent] ' + 'RESIZE event: ', event);
    }

    handleClickGraph(event: MouseEvent, exception: any): void {
        if (this.isDisabled() || !this.auth.allowEditDimmingScheme()) {
            return;
        }

        let selectedChannel: number = this.tinyGraph.getSelectedChannel();
        this.onComponentEvent.emit({
            event: FormEvent.CLICK_GRAPH,
            data: {dimmingSchemeId: exception.dimSchemeId, channel: selectedChannel},
        });
    }

    // TODO: check of dit met opzet is gedaan om AbstractFormFieldComponent-ngafterviewinit the overwriten of hier gewoon perongelijk staat
    ngAfterViewInit() {
    }

    ngOnDestroy() {
        if (this.subMobileMode) {
            this.subMobileMode.unsubscribe();
        }
    }

    ngOnInit() {
        if (this.config.attr && this.config.attr.url) {
            this.dataUrl = '' + this.config.attr.url;
            this.getData(this.dataUrl);
        }
        else {
            this.globalAlertService.addAlert(this.ts.translate('Formulier incompleet'), this.ts.translate('Geen URL meegegeven'), this.ts.translate('Het component verwacht een URL, maar die is niet meegegeven'));
        }
    }

    private getData(url: string) {
        if (url == '') {
            this.logger.log('[DimmingExceptionsComponent] ' + 'WARNING: No valid url for dimming exceptions');
            return;
        }

        this.isLoading = true;

        this.formDataService.getGraphData(url,
            (json: any) => {
                this.isLoading = false;

                //Format date op de uitzondering
                json.exceptions.forEach((exception: any) => {
                    let startDate: any = moment(exception.startDate, 'D-M-YYYY HH:mm:ss');
                    let endDate: any = moment(exception.endDate, 'D-M-YYYY HH:mm:ss');

                    if (startDate.isValid()) {
                        exception.startDate = startDate.format('DD-MM-YYYY');
                    }
                    if (endDate.isValid()) {
                        exception.endDate = endDate.format('DD-MM-YYYY');
                    }
                });

                this.exceptions = json.exceptions;
                this.dimSchemes = json.dimSchemes;

                this.cd.markForCheck();
            },
        );
    }

    // Handle mouse over this element.
    handleMouseOver(event: MouseEvent, exception: any) {
        exception.isHovered = true;
    }

    handleMouseOut(even: MouseEvent, exception: any) {
        exception.isHovered = false;
    }

    handleExceptionRemove(event: MouseEvent, exception: any) {
        if (this.isDisabled()) {
            return;
        }

        if (exception && exception.deleteUrl) {
            this.globalAlertService.addPopup(this.ts.translate('exception.removetitle'), this.ts.translate('exception.remove', [exception.name]), [{
                label: this.ts.translate('Annuleren'),
                code: ButtonCode.ANNULEREN,
                isPrimary: true,
            },
                {
                    label: this.ts.translate('Verwijderen'), code: 'DELETE',
                    callback: () => {
                        this.dimmingService.deleteDimmingException(exception.deleteUrl,
                            (json: any) => {
                                this.globalAlertService.addAlertSuccess(this.ts.translate('dimmingexception.deletetitle'), this.ts.translate('dimmingexception.savetext'), '');
                                //Remove exceptions locally
                                let index = this.exceptions.indexOf(exception, 0);
                                if (index > -1) {
                                    this.exceptions.splice(index, 1);
                                }
                            },
                            (failure: RequestFailure) => {
                            },
                            (error: HTTPError) => {
                            },
                        );
                    }, isPrimary: false,
                }], () => {
            });
        }
    }

    get GRAPH_PATH(): string {
        return DimmingExceptionsComponent.GRAPH_PATH;
    }
}

export interface DimmingExceptionInterface {
    name: string
    isHovered: boolean
    deleteUrl: string
    startDate: string
    endDate: string
    dimScheme: string
    dimSchemeId: number
}
