import {Injectable, OnInit} from '@angular/core';
import Utils from "../../utils/utils";
import {GlobalModel} from '../state/global.model';
import {HttpClient} from "@angular/common/http";
import {AuthorizationService} from '../authorization/authorization.service';
import {LoggerService} from "../logger/logger.service";
import {AppSettings} from "../../../app.settings";
import {StorageService} from "../storage/storage.service";
import {ILocalizationElement} from "../../components/localization/localization.interface";
import {UserRoles} from "../../interfaces/user.interface";
import {BasicHttpGetResult, BasicHttpPostResult, HttpResult} from "../http-service-2.0/http.interface";
import {firstValueFrom} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class TranslateService {

    public static readonly LANGUAGES_EN:string = "en";
    public static readonly LANGUAGES_NL:string = "nl";
    public static readonly LANGUAGES_DE:string = "de";
    public static readonly LANGUAGES_FR:string = "fr";
    public static readonly LANGUAGES_SL:string = "sl";

    public static readonly DEFAULT_LANGUAGE:string = TranslateService.LANGUAGES_NL;

    public static readonly LANGUAGES:any = [
        {value:TranslateService.LANGUAGES_EN, label:"English", locale: 'en-US'},
        {value:TranslateService.LANGUAGES_NL, label:"Nederlands", locale: 'nl-NL'},
        {value:TranslateService.LANGUAGES_DE, label:"Deutsch", locale: 'de-DE'},
        {value:TranslateService.LANGUAGES_FR, label:"Français (Beta)", locale: 'fr-FR'}
    ];
    public static LOCALE: string = 'en-US';

    private language: string = "";
    private translations: any = {};

    private translationModeEnabled:boolean = false;


    // TODO: eigenlijk moet de standaard http service hier worden gebruikt, maar die is nog niet beschikbaar om geinjecteerd te worden in deze service
    //  Waarschijnlijk doordat translationservice ook weer wordt gebruikt in httpservice
    constructor(private model: GlobalModel, private http: HttpClient, private auth: AuthorizationService, protected logger:LoggerService, private storage:StorageService) {
        this.storage.getBooleanValue(StorageService.KEY_TRANSLATION_MODE, (value: boolean) => {
            this.translationModeEnabled = value;
        });
    }

    private getTranslationUrl(language:string):string{
        // NOTE: for things like firstlogin message, cache busting on version number isn't enough. Always bust the language cache for now
        return AppSettings.getBaseUrl()+'services/translations/angular/' + language
    }

    public async setLanguage(language: string): Promise<void> {
        this.language = language;

        // set locale, after this.language is set
        if (TranslateService.LANGUAGES.some(_language => _language.value === this.language)) {
            TranslateService.LOCALE = TranslateService.LANGUAGES.find(_language => _language.value === this.language).locale;
        }
        // will always overwrite translations with new translations from server
        await this.requestTranslations();
    }

    public async initTranslations():Promise<void> {
        if (this.language === "") {
            this.translations = {};
            const value: string = localStorage.getItem(StorageService.KEY_SELECTED_LANGUAGE);
            if (value) {
                this.language = value;
            } else {
                this.language = TranslateService.DEFAULT_LANGUAGE;
            }

            // set locale, after this.language is set
            if (TranslateService.LANGUAGES.some(_language => _language.value === this.language)) {
                TranslateService.LOCALE = TranslateService.LANGUAGES.find(_language => _language.value === this.language).locale;
            }
        }

        if (Object.keys(this.translations).length === 0) {
            // will always overwrite translations with new translations from server
            await this.requestTranslations();
        } else {
            // translations were already loaded, skip loading and do nothing
        }
    }

    private async requestTranslations(): Promise<void> {
        await firstValueFrom(this.http.get(this.getTranslationUrl(this.language))).then(
            success => {
                this.translations = success;

                // The translations are loaded, trigger the language update across the app
                this.model.changeLanguage.next(this.language);
            },
            error => {
                this.logger.log("[TranslateService] error when loading translations: " + this.language);
                this.logger.log(error);
            });
    }

    public getLanguage():string{
        return this.language != ""?this.language:TranslateService.DEFAULT_LANGUAGE;
    }

    public translate(key: string, params?: any[]): string {
        let translateResult: string = this.translations[key];

        if (translateResult == undefined || translateResult == "") {
            translateResult = "//" + key;
        }

        if (params && params.length > 0) {
            for (let i = 0; i < params.length; i++) {
                translateResult = translateResult.replace(new RegExp(Utils.escapeRegExp('$$' + i), 'g'), params[i]);
            }
        }
        this.logger.log("[TranslateService] " + "Tanslate key: "+key);
        this.addToLocalizationElementList(key, translateResult);
        return translateResult;
    }

    private addToLocalizationElementList(translationKey:string, translationValue:string):void{
        //Only add keys that do not exist yet and only add if translation mode is on
        if(this.model.localizationElements.value.findIndex(x => x.key === translationKey) === -1 && this.translationModeEnabled){
            this.model.localizationElements.value.unshift({key: translationKey, value: translationValue, visible: true})
        }
    }

    private findTranslationMatch(stringToUse:string):any[]{
        return stringToUse.match(/\__.+?\___/g);
    }

    public processTranslationFromHTTP(httpResultData:BasicHttpPostResult | BasicHttpGetResult):BasicHttpPostResult | BasicHttpGetResult{
        //Only do something when in translation mode, otherwise just return the given httpResultData
        if(this.translationModeEnabled){
            let httpResultDataString:string = JSON.stringify(httpResultData)
            const translationMatch = this.findTranslationMatch(httpResultDataString)

            if(translationMatch !== null){
                translationMatch.map(_translation => {
                    const splitTranslation = _translation.split('---');
                    const translationKey = splitTranslation[0].slice(2) //Remove first 2 __
                    const translationValue = splitTranslation[1].slice(0,-3) //Remove last 3 ___

                    this.addToLocalizationElementList(translationKey,translationValue)

                    httpResultDataString = httpResultDataString.replace(_translation,translationValue)
                })
            }
            return JSON.parse(httpResultDataString);
        } else {
            return httpResultData;
        }
    }
}
