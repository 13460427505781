import {BaseFormConfig, FormGroupConfig} from '../../components/field/fieldDirective.interface';

export enum FormEvent {
    SCROLL_TO_SECTION = 'scroll-to-section',
    CHANGE_SELECT = 'change-select',
    SAVE = 'save',
    SAVE_SUCCESS = 'save-success',
    ADD_SUCCESS = 'add-success',
    MSB_MELDING_RELOAD_FORM = 'msb-melding-reload-form',
    MSB_MELDING_DELETE_SUCCESS = 'msb-melding-delete-success',
    MSB_MELDING_PAIR_EXISTING_WORKORDER = 'msb_melding_pair_existing_workorder',
    MSB_MELDING_PAIR_NEW_WORKORDER = 'msb_melding_pair_new_workorder',
    EXTERNAL_MALFUNCTION_REPORT_RELOAD_FORM = 'external_malfunction_report_reload-form',
    EXTERNAL_MALFUNCTION_REPORT_PAIR_EXISTING_WORKORDER = 'external_malfunction_report_pair_existing_workorder',
    EXTERNAL_MALFUNCTION_REPORT_PAIR_NEW_WORKORDER = 'external_malfunction_report_pair_new_workorder',
    WERKBON_SUGGESTION_CREATE_NEW_WORKORDER = 'werkbon_suggestion_create_new_workorder',
    COMMENT_ADD_SUCCESS = 'comment-add-success',
    ATTACHMENT_ADD_SUCCESS = 'attachment-add-success',
    COMMENT_DELETE_SUCCESS = 'comment-delete-success',
    ATTACHMENT_DELETE_SUCCESS = 'attachment-delete-success',
    CANCEL = 'cancel',
    BACK = 'back',
    DELETE_BASEOBJECT = 'delete-baseobject',
    DELETE_UNDERGROUND_BASEOBJECT = 'delete-underground-baseobject',
    DELETE_MSB_ASSET = 'delete-msb-asset',
    GENERATE_MAPS_WITH_GROUPS = 'generate-maps-with-groups',
    SEGMENT_DELETE = 'delete-segment',
    SEGMENT_DELETE_SUCCESS = 'delete-segment_success',
    DIMGROUP_DELETE = 'delete-dimgroup',
    PROJECT_DELETE = 'delete-project',
    WORKORDER_DELETE = 'delete-bon',
    SYSTEMGROUP_DELETE = 'delete-systemgroup',
    DIMGROUP_DELETE_SUCCESS = 'delete-dimgroup-success',
    SYSTEMGROUP_DELETE_SUCCESS = 'delete-systemgroup-success',
    PERFECTVIEW_PAIRED_SUCCESS = 'perfectview-paired-success',
    MSB_MELDING_PAIRED_SUCCESS = 'msb-melding-paired-success',
    EXTERNAL_MALFUNCTION_REPORT_PAIRED_SUCCESS = 'external-malfunction-report-paired-succes',
    SYSTEMGROUP_SAVE_SUCCESS = 'save-systemgroup-success',
    DIMGROUP_SAVE_SUCCESS = 'save-dimgroup-success',
    PROJECT_SAVE_SUCCESS = 'save-project-success',
    PROJECT_DELETE_SUCCESS = 'delete-project-success',
    MOVE_MAPITEM = 'move-mapitem',
    MOVE_MAPITEM_SUCCESS = 'move-mapitem-success',
    COPY_BASEOBJECT = 'copy-baseobject',
    COPY_BASEOBJECT_SUCCESS = 'copy-baseobject-success',
    BATCH_DELETE_SUCCESS = 'batch-delete-success',
    BATCH_DELETE = 'batch-delete',
    BATCH_SAVE = 'batch-update',
    BATCH_SAVE_SUCCESS = 'batch-update-success',
    SAVE_ADDITIONAL_REPORT = 'save-additional-report',
    DIMGROUP_SAVE = 'update-dimgroup',
    PROJECT_SAVE = 'update-project',
    SYSTEMGROUP_SAVE = 'update-systemgroup',
    CLEAR_SELECTION = 'clear-selection',
    EXCEPTION_CREATE = 'exception-create',
    EXCEPTION_CREATE_SUCCESS = 'exception-create-success',
    SCHEME_EDIT = 'dimming-scheme-edit',
    CLICK_GRAPH = 'click-graph',
    CLOSE_SCHEME_EDITOR = 'close-scheme-editor',
    SET_DIMGROUP_OVERRIDE = 'set-dimgroup-override',
    SET_DIMGROUP_OVERRIDE_SUCCESS = 'set-dimgroup-override-success',
    WORK_PREPARATION_CREATE = 'create-work-preparation',
    WORK_SPECIFICATION_CREATE = 'create-work-specification',
    WEEKPRIJS_CREATE = 'create-weekprijs',

    GENERATE_DAMAGE_REPORT = 'generate-damage-report',
    CREATE_MAP_ITEMS = 'create-map-items',
    
    WORK_ACTIVITY_CREATE = 'create-work-activity',
    CHECK_ACTIVITY_CREATE = 'create-check-activity',
    
    WORK_PREPARATION_CREATE_SUCCESS = 'work-preparation-create-success',
    WORK_PREPARATION_DELETE_SUCCESS = 'work-preparation-delete-success',
    WORK_ACTIVITY_CREATE_SUCCESS = 'work-activity-create-success',
    WORK_ACTIVITY_DELETE_SUCCESS = 'work-activity-delete-success',
    WEEKPRIJS_CREATE_SUCCESS = 'create-weekprijs-create-success',
    WEEKPRIJS_DELETE_SUCCESS = 'create-weekprijs-delete-success',

    ACTIVITY_CREATE_SUCCESS = 'activity-create-success',
    ACTIVITY_DELETE_SUCCESS = 'activity-delete-success',
    ACTIVITY_SAVE_SUCCESS = 'activity-save-success',
    
    WORK_SPECIFICATION_CREATE_SUCCESS = 'work-specification-create-success',
    WORK_SPECIFICATION_DELETE_SUCCESS = 'work-specification-delete-success',
    
    WORK_PREPARATION_UPDATE_SUCCESS = 'work-preparation-update-success',
    WORKORDER_DELETE_SUCCESS = 'workorder-delete-success',
    ATTACHMENT_PREVIEWED = 'attachment-previewed',
    ATTACHMENT_CLEARED = 'attachment-cleared',
    DELETE_USER = 'delete-user',
    DELETE_USER_SUCCESS = 'delete-user-success',
    MERGE = 'merge',
    MERGE_SUCCESS = 'merge-success',
    DELETE_MASTERDATA = 'delete-masterdata',
    DELETE_MASTERDATA_SUCCESS = 'delete-masterdata-success',
    
    NAVIGATE = 'navigate',

    CREATE_MAP_SERVER = 'add-map-server',

    CREATE_AREAAL = 'create-areaal',
}

export interface LumiFormEvent {
    event: FormEvent;
    data: LumiFormEventData;
}

export interface LumiFormEventData {
    formData: any;
    referenceId?: number;
}

export interface LumiForm {
    schema: BaseFormConfig;
}
