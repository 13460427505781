import {Component, OnInit, Renderer2} from '@angular/core';
import {AbstractFormFieldComponent} from '../abstract/abstract-form-field.component';
import {ValidationConstraintService} from '../../services/validation-constraint.service';
import {TooltipService} from '../../../../services/tooltip/tooltip.service';
import {LoggerService} from "../../../../services/logger/logger.service";

@Component({
  selector: 'form-dummy',
  templateUrl: './form-dummy.component.html'
})
export class FormDummyComponent extends AbstractFormFieldComponent implements OnInit {

  constructor(
      public renderer: Renderer2,
      public validationConstraintService: ValidationConstraintService,
      private toolTipService: TooltipService,
      protected logger:LoggerService
  ) {
    super(
        renderer,
        validationConstraintService,
        toolTipService,
        logger
    );
  }

  ngOnInit() {
  }

}
