<div class="localization-panel {{panelLocation}}" *ngIf="showPanel">
    <div class="localization-panel-inner">
        <div class="d-flex justify-content-between">
            <div>
                <h3>Localization</h3>
            </div>
            <div>
                <i class="material-icons" (click)="clearLocalizationList()">delete</i>
                <i class="material-icons" *ngIf="panelLocation == 'left'" (click)="changeLocation()">switch_left</i>
                <i class="material-icons" *ngIf="panelLocation == 'right'" (click)="changeLocation()">switch_right</i>
            </div>
        </div>
        <div class="localization-search">
            <div class="input-container">
                <input type="search" class="localization-search-field" (keyup.enter)="doSearch()" [(ngModel)]="currentActiveFilterQuery" placeholder="{{ts.translate('treesearch.placeholder')}}" />
                <div class="localization-search-buttons">
                    <i class="material-icons localization-search-clear-btn" (click)="clearSearchQuery()" [ngClass]="{'active':hasSearchQuery()}">close</i>
                    <i class="material-icons" (click)="doSearch()">search</i>
                </div>
            </div>
        </div>
        <div class="available-localizations">
            <ng-container *ngFor="let localizationItem of localizationList" >
                <div class="localization-item" *ngIf="localizationItem.visible" (click)="openLocalizationItem(localizationItem.key)">
                    {{localizationItem.value}} <span>({{localizationItem.key}})</span>
                </div>
            </ng-container>
        </div>
    </div>
</div>