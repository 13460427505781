import {Injectable} from '@angular/core';
import {HttpService2} from '../../../services/http-service-2.0/http2.0.service';
import {Observable, of} from 'rxjs';
import {FileManagerFolder} from './file-manager-folder/file-manager-folder.interface';
import {map, take} from 'rxjs/operators';
import {GetReportResult} from '../../../../modules/analysisReport/reporting.interface';
import Utils from '../../../utils/utils';

@Injectable({
    providedIn: 'root'
})
export class FileManagerService {
    private folders: FileManagerFolder[] = [{
        id: 2,
        name: '2. Definitief ontwerp',
        files: [{
            id: 21,
            name: 'Definitief ontwerp.pdf',
            creator: 'IB Den Haag',
            dateCreated: '12 maart 2021',
            size: '50kb',
            type: 'pdf'
        }]
    },
        {
            id: 1,
            name: '1. Voor ontwerp',
            files: [{
                id: 11,
                name: 'Voor ontwerp v1.pdf',
                creator: 'IB Den Haag',
                dateCreated: '2 februari 2021',
                size: '50kb',
                type: 'pdf'
            }, {
                id: 12,
                name: 'Voor ontwerp v2.pdf',
                creator: 'IB Den Haag',
                dateCreated: '3 februari 2021',
                size: '50kb',
                type: 'pdf'
            }, {
                id: 13,
                name: 'Voor ontwerp v3.pdf',
                creator: 'IB Den Haag',
                dateCreated: '4 februari 2021',
                size: '50kb',
                type: 'pdf'
            }, {
                id: 14,
                name: 'Voor ontwerp v4.pdf',
                creator: 'IB Den Haag',
                dateCreated: '5 februari 2021',
                size: '50kb',
                type: 'pdf'
            }]
        }, {
            id: 3,
            name: '3. Offerte',
            files: [{
                id: 31,
                name: 'offerte_project_202100212.pdf',
                creator: 'PCO Infra',
                dateCreated: 'vandaag 09:00',
                size: '50kb',
                type: 'pdf'
            }]
        }, {
            id: 4,
            name: '4. Opdracht',
            files: []
        }, {
            id: 5,
            name: '5. Uitvoering',
            files: []
        }, {
            id: 6,
            name: '6. Oplevering',
            files: []
        }
    ]
    
    constructor(private httpService: HttpService2) {
    }
    
    public getFolders(): Observable<FileManagerFolder[]> {
        // todo: httpCall
        return of(this.folders);
    }
    
    public addFolder(folderName: string): Observable<FileManagerFolder[]> {
        // todo: httpCall
        let lastId: number = 0;
        this.folders.forEach(folder => {
            if (folder.id >= lastId) {
                lastId = folder.id;
            }
        });
        this.folders.push({
            id: lastId + 1,
            name: folderName,
            files: []
        });
        return of(this.folders);
    }
    
    public removeFolder(id: number): Observable<FileManagerFolder[]> {
        // todo: httpCall
        if (this.folders.some(folder => folder.id === id)) {
            const index = this.folders.findIndex(folder => folder.id === id);
            this.folders.splice(index, 1);
            return of(this.folders);
        }
    }
    
    public editFolder(id: number, folderName: string): Observable<FileManagerFolder[]> {
        // todo: httpCall
        if (this.folders.some(folder => folder.id === id)) {
            this.folders.find(folder => folder.id === id).name = folderName;
            return of(this.folders);
        }
    }
    
    public addFilesToFolder(folderId: number, fileList: FileList): Observable<FileManagerFolder[]> {
        // todo: httpCall
        if (this.folders.some(_folder => _folder.id === folderId)) {
            let lastId: number = 0;
            const folder = this.folders.find(_folder => _folder.id === folderId);
            folder.files.forEach(_file => {
                if (_file.id >= lastId) {
                    lastId = _file.id;
                }
            });
            
            Array.from(fileList).forEach(file => {
                folder.files.push({
                    id: lastId + 1,
                    name: file.name,
                    size: file.size + 'bytes',
                    type: file.type,
                    dateCreated: 'today',
                    creator: 'creator'
                });
                lastId++;
            });
            
            return of(this.folders);
        }
    }
    
    public deleteFiles(ids: number[]): Observable<FileManagerFolder[]> {
        // todo: httpCall
        ids.forEach(id => {
            if (this.folders.some(_folder => _folder.files.some(file => file.id === id))) {
                const folder = this.folders.find(_folder => _folder.files.some(file => file.id === id));
                const index = folder.files.findIndex(file => file.id === id);
                folder.files.splice(index, 1);
            }
        });
    
        return of(this.folders);
    }
    
    public downloadFile(id: number): Observable<void> {
        return this.getFile(id).pipe(take(1)).pipe(
            map((result: any) => {
                if (result.file && result.fileName) {
                    Utils.downloadAsFile(true, 'data:application/pdf;base64,' + result.file, result.fileName);
                }
            })
        );
    }
    
    private getFile(id: number): Observable<any> { // todo: typing
        // todo: httpCall
        return of();
    }
}
