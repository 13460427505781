import {Component, Renderer2} from '@angular/core';
import {AbstractFormFieldComponent} from '../form/components/abstract/abstract-form-field.component';
import {ValidationConstraintService} from '../form/services/validation-constraint.service';
import {TooltipService} from '../../services/tooltip/tooltip.service';
import {TranslateService} from '../../services/translate/translate.service';
import {GlobalAlertService} from '../../../wrapper/global-alert/global-alert.service';
import {GuiInterface} from './gui.interface';
import {GuiService} from './gui.service';
import {GlobalModel} from "../../services/state/global.model";
import {LoggerService} from "../../services/logger/logger.service";

@Component({
  selector: 'app-gui-form',
  templateUrl: './gui-form.component.html'
})
export class GuiFormComponent extends AbstractFormFieldComponent{

    public guiType = GuiInterface;

    constructor(
        public renderer: Renderer2,
        public validationConstraintService: ValidationConstraintService,
        public tooltipService: TooltipService,
        public ts: TranslateService,
        public globalAlertService: GlobalAlertService,
        public guiService:GuiService,
        private model: GlobalModel,
        protected logger:LoggerService
    ) {
        super(renderer, validationConstraintService, tooltipService, logger)
    }

    ngOnInit(): void {
    }
}
