import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {catchError, finalize, map} from 'rxjs/operators';
import {Observable, throwError} from 'rxjs';
import {BasicHttpGetResult, BasicHttpPostResult, FormPostResult, HttpResult, HttpStatus} from './http.interface';
import {GlobalModel} from '../state/global.model';
import {HTTPError} from '../http/http-error';
import {RequestError} from '../http/request-error';
import {AppSettings} from '../../../app.settings';
import {GlobalEvent} from '../../interfaces/global-event';
import Utils from '../../utils/utils';
import {HTTPService} from '../http/http.service';
import {GlobalAlertService} from '../../../wrapper/global-alert/global-alert.service';
import {RequestFailure} from '../http/request-failure';
import {TranslateService} from '../translate/translate.service';
import {LoggerService} from "../logger/logger.service";

@Injectable({
    providedIn: 'root'
})
export class HttpService2 {
    private outOfSession: boolean = false;
    private headersPostForm: HttpHeaders = new HttpHeaders({'Content-Type': 'application/json'});
    
    constructor(
        private httpClient: HttpClient,
        private model: GlobalModel,
        private httpService: HTTPService,
        private ts: TranslateService,
        private globalAlertService: GlobalAlertService,
        protected logger:LoggerService
    ) {
    }
    
    public doGetRequest(
        path: string,
        sendAreaId: boolean = true,
        usePathAsFullUrl?: boolean,
        availableBeforeLogin? :boolean
    ): Observable<BasicHttpGetResult> {
        // Create a new var, this is async, so "this" cant be used or you might refer to a changed value
        let url: string = usePathAsFullUrl ? path : (availableBeforeLogin ? AppSettings.getBaseUrl() + path : this.getURL(path, sendAreaId));
        this.httpService.addPendingCall(path);
        
        return this.httpClient.get<HttpResult>(url).pipe(
            catchError(error => {
                this.handleHTTPError(path, error);
                throw error;
            }),
            map((result: HttpResult) => {
                return <BasicHttpGetResult>this.mapHTTPSuccess(url, result);
            }),
            finalize(() => {
                this.httpService.removePendingCall(path);
                this.handleCallComplete(url);
            })
        );
    }

    public doExternalGetRequest(
        path: string,
        responseType:any = 'json'
    ): Observable<HttpResult|ArrayBuffer> {
        this.httpService.addPendingCall(path);

        return this.httpClient.get<HttpResult|ArrayBuffer>(path, {responseType: responseType}).pipe(
            finalize(() => {
                this.httpService.removePendingCall(path);
                this.handleCallComplete(path);
            })
        );
    }
    
    public doPostRequest(
        path: string,
        postValues: JSON,
        sendAreaId: boolean = true
    ): Observable<FormPostResult> {
        let body: HttpParams = this.convertJSONToParams(postValues);
        
        return this.doBasicPostRequest(
            this.headersPostForm,
            body,
            path,
            postValues,
            sendAreaId
        );
    }
    
    public doFormPostRequest(
        path: string,
        postValues: JSON,
        sendAreaId: boolean = true
    ): Observable<FormPostResult> {
        return this.doBasicPostRequest(
            this.headersPostForm,
            postValues,
            path,
            postValues,
            sendAreaId
        );
    }
    
    private doBasicPostRequest(
        headers: HttpHeaders,
        body: HttpParams | JSON,
        path: string,
        postValues: JSON,
        sendAreaId?: boolean,
        usePathAsFullUrl?: boolean
    ): Observable<BasicHttpPostResult> {
        const url: string = usePathAsFullUrl ? path : this.getURL(path, sendAreaId);
        this.httpService.addPendingCall(path);
        
        return this.httpClient.post<HttpResult>(url, body, {headers: headers}).pipe(
            catchError(error => {
                this.handleHTTPError(path, error, postValues);
                throw error;
            }),
            map((result: HttpResult) => {
                return <BasicHttpPostResult>this.mapHTTPSuccess(url, result, postValues);
            }),
            finalize(() => {
                this.httpService.removePendingCall(path);
                this.handleCallComplete(url);
            })
        );
    }
    
    private getURL(path: string, sendAreaId: boolean): any {
        if (sendAreaId) {
            return AppSettings.getBaseAngularUrl(path) + 'a/' + this.model.currentAreaal.getValue().id + '/' + path;
        } else {
            return AppSettings.getBaseAngularUrl(path) + path;
        }
    }


    
    private mapHTTPSuccess(url: string, httpResult: HttpResult, postValues?: JSON): BasicHttpPostResult | BasicHttpGetResult {
        let result: BasicHttpPostResult | BasicHttpGetResult;
        
        if (httpResult != null) {
            if (httpResult.status != null) {
                switch (httpResult.status) {
                    case HttpStatus.SUCCESS: {
                        // Response is in json, not mapped jet. Use it as you please
                        result = this.ts.processTranslationFromHTTP(httpResult.data);
                        break;
                    }
                    case HttpStatus.FAIL: {
                        // Extract error from failure or form errors
                        let failure: RequestFailure;
                        
                        if (httpResult.data.failure) {
                            failure = httpResult.data.failure;
                            
                            if (failure.displayAsAlert) {
                                this.globalAlertService.addAlertFailure(failure);
                            } else if (failure.displayAsPopup) {
                                this.globalAlertService.addPopup(failure.title, failure.message, [{
                                    label: this.ts.translate('Ok'),
                                    code: 'OK',
                                    isPrimary: true,
                                }], () => {
                                });
                            }
                        } else if (httpResult.data.formErrors) {
                            failure = new RequestFailure();
                            failure.formErrors = httpResult.data.formErrors;
                        } else {
                            this.globalAlertService.addAlertEmptyResponse(url);
                        }
                        
                        throw failure;
                    }
                    case HttpStatus.ERROR: {
                        let error: RequestError;
                        if (httpResult.data.error) {
                            error = httpResult.data.error as RequestError;
                            this.globalAlertService.addAlert(GlobalAlertService.ALERT_TITLE_ERROR, error.title, error.message);
                            
                        } else {
                            this.globalAlertService.addAlertEmptyResponse(url);
                        }
                        throw error;
                    }
                    default: {
                        this.handleUnknownStatusError(httpResult.status, url);
                        break;
                    }
                }
            } else {
                this.handleNoStatusError(url, postValues, httpResult);
            }
        } else {
            this.handleNoJson(url);
        }
        
        return result;
    }
    
    private handleHTTPError(path: string, error: HTTPError, postValues: JSON = null) {
        let alertTitle = GlobalAlertService.ALERT_TITLE_HTTP_ERROR;
        let helpText: string;
        let skipAlert = false;
        
        // TODO: Werk verder uit voor andere statussen
        // TODO: minder technische taal voor de eindgebruiker, deze begrijpt de bewoordingen waarschijnlijk niet
        // TODO: Nederlands maken van de foutmeldingen
        
        if (error.status) {
            switch (error.status.toString()) {
                case '0':
                    helpText = this.ts.translate('httperror.0');
                    // "The server can't be reached, or the request timed-out. Check your internet connection";
                    break;
                case '403':
                    alertTitle = GlobalAlertService.ALERT_TITLE_HTTP_FORBIDDEN;
                    helpText = this.ts.translate('httperror.403');
                    // "Het is niet toegestaan om deze actie uit te voeren";
                    // A bit ugly, but the initial calls of the application trigger an error without being a actual error.
                    // So don't show a message in those cases
                    if ([AppSettings.GET_LOGIN_TOKEN_PATH, AppSettings.CHECK_LOGIN_PATH, AppSettings.LOGIN_PATH].indexOf(path) !== -1) {
                        skipAlert = true;
                    }
                    break;
                case '404':
                    helpText = this.ts.translate('httperror.404');
                    // "The URL can't be reached. The path might be incorrect or there is no routing defined on the server";
                    break;
                case '500':
                    helpText = this.ts.translate('httperror.500');
                    // "A script is causing an error on the server";
                    break;
                case '503':
                    helpText = this.ts.translate('httperror.503');
                    // "The service is unavailable. The webserver is under maintenance, disabled or overloaded";
                    break;
                default:
                    helpText = this.ts.translate('httperror.unknown');
                    // "Unknown error";
                    break;
            }
        } else {
            // Weird case in which the error object is empty, status is 200, but still seen as an error (not just empty response)
            helpText = 'httperror.ok';
            // "Status OK received but the call is still recognized as an error. Check the response for more details.";
            error.status = '200';
            error.statusText = 'OK';
            error.url = path;
        }
        
        // Show alert popup
        if (!skipAlert && !this.outOfSession) {
            if (error.status.toString() === '0') {
                // Separate handling for 0-response, since not all values are available in that case
                this.globalAlertService.addAlert(alertTitle, '0 - No response', 'URL: ' + path + '<br>' + helpText);
            }
            if (error.status.toString() === '403' && error.url.match(/get-token/)) {
                this.outOfSession = true;
                
                // TODO: deze url is niet dynamisch. Op de link klikken in een volgend venster zorgt voor het
                //  inloggen+refreshen van het vorige.
                // this.globalAlertService.addAlert(
                // "Uw sessie is verlopen",
                // "",
                // "De data kon niet opgehaald worden omdat uw sessie is verlopen. <a href='" + window.location.href
                // + "'>Log opnieuw in</a>"
                // );
                
                this.model.onGlobalEvent.next(new GlobalEvent(GlobalEvent.EVENT_OUT_OF_SESSION, {}));
                
                // window.open(window.location.origin, '_self');
                // Reopen the app on the base url whe if the received HTTP status code is 403 (forbidden)
                // (one could also choose the current window.location.href,
                // but in case the previously accessed url theoretically could itself return a 403 response again and
                // then a login -> kick -> login loop would ensue!).
                // Either the user tried some action that was previously allowed
                // or the session is not valid anymore (no calls to back-end for too long), this last one will be the most occuring.
            } else {
                this.globalAlertService.addAlertHTTPError(
                    alertTitle,
                    error.status + ' - ' + error.statusText,
                    'URL: <span class=\'break-all\'>' + error.url + '</span><br>' + helpText,
                    error.error
                );
            }
        }
        
        // Log the error in the console
        this.logger.log('----------------------------------------------');
        this.logger.log('HTTP ERROR ' + error.status + ' - ' + error.statusText);
        this.logger.log();
        this.logger.log(error);
        this.logger.log('----------------------------------------------');
    }
    
    private handleCallComplete(url: string) {
        this.logger.log('[HTTPService] ' + 'Call finished [' + Utils.getLoggableObject(url) + ']');
    }
    
    private handleNoStatusError(url: string, postValues: JSON, httpResult: HttpResult) {
        this.logger.error('[HTTPService] ' + 'ERROR: No status given with response. No valid JSend.');
        this.logger.error('[HTTPService] ' + 'URL: [' + url + '] \nPOSTVALUES: ', postValues );
        this.logger.error('[HTTPService] ' + 'URL: [' + url + '] \nRESPONSE: ', httpResult);
        
        this.globalAlertService.addAlert(
            GlobalAlertService.ALERT_TITLE_ERROR,
            'No valid JSend',
            'No status was given with the response. The response is not formed according the JSend specifications.<br>' + 'URL: ' + url
        );
    }
    
    private handleUnknownStatusError(status: string, url: string) {
        this.logger.error('[HTTPService] ' + 'Unknown status with response. URL: ' + url + ' Status: ' + status);
    }
    
    private handleNoJson(url: string) {
        this.logger.log('[HTTPService] ' + 'No jsondata received for call: ' + url);
    }
    
    private convertJSONToParams(json: any): HttpParams {
        let params: HttpParams = new HttpParams();
        
        params = this.convertJSONToParamsRecursive(params, json, '', 0, true);
        
        return params; // .toString();
    }
    
    private convertJSONToParamsRecursive(
        params: HttpParams,
        json: any,
        prefix: string,
        arrayIndex: number,
        firstLevel: boolean
    ): HttpParams {
        let jsonValue: any;
        
        for (let key in json) {
            jsonValue = json[key];
            if (jsonValue instanceof Array) {
                jsonValue.forEach((item: any, index: number) => {
                    if (item instanceof Object) {
                        params = this.convertJSONToParamsRecursive(params, item, prefix + '[' + key + ']', index, false);
                    } else {
                        params = params.set(prefix + '[' + key + ']' + '[' + index + ']', item);
                    }
                });
            } else if (jsonValue instanceof Object) {
                if (firstLevel) {
                    params = this.convertJSONToParamsRecursive(params, jsonValue, prefix + key, -1, false);
                } else {
                    params = this.convertJSONToParamsRecursive(params, jsonValue, prefix + '[' + key + ']', -1, false);
                }
            } else {
                if (firstLevel) {
                    params = params.set(prefix + key, jsonValue);
                } else if (arrayIndex !== -1) {
                    params = params.set(prefix + '[' + arrayIndex + ']' + '[' + key + ']', jsonValue);
                } else {
                    params = params.set(prefix + '[' + key + ']', jsonValue);
                }
            }
        }
        
        return params;
    }
    
}
