import {HTTPService} from "../../../services/http/http.service";
import {GlobalAlertService} from "../../../../wrapper/global-alert/global-alert.service";
import {Injectable} from "@angular/core";
import {RequestFailure} from "../../../services/http/request-failure";
import {HTTPError} from "../../../services/http/http-error";

@Injectable() export class SettingsService {

    constructor(protected httpService:HTTPService, protected globalAlertService:GlobalAlertService) {}

    // TODO split up
    public getFormDataForUriFragment(uriFragment:string, model:any, successCallBack?:() => any, failCallBack?:(failure:RequestFailure) => any, errorCallBack?:(error:HTTPError) => any, areaSpecific?:boolean):void
    {
        this.httpService.doGetRequest(uriFragment + '/get',
            (json:any, url:string) => {
                model.next(null);
                setTimeout(() => {
                    model.next(json);
                    successCallBack();
                });
            }, (failure:RequestFailure) => {
                failCallBack(failure)
            }, (error:HTTPError) => {
                errorCallBack(error);
            }, areaSpecific // we extract the area id from the get request URL if true
        );
    }

    public setFormDataForUriFragment(uriFragment:string, form:any, formName:string, model:any, successCallBack?:() => any, failCallBack?:(failure:RequestFailure) => any, errorCallBack?:(error:HTTPError) => any, areaSpecific?:boolean):void
    {
        //TODO: wat de hel
        if (typeof areaSpecific === 'undefined') {
            areaSpecific = false;
        }

        let fullForm = {[formName]:form};

        this.httpService.doFormPostRequest(
            uriFragment + '/update',
            fullForm,
            (json:any) => {
                model.next(null);
                setTimeout(() => {
                    model.next(json);
                    successCallBack();
                });
            }, (failure:RequestFailure) => {
                failCallBack(failure);
            }, (error:HTTPError) => {
                errorCallBack(error);
            },
            areaSpecific
        );
    }
}
