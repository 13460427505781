import {ChangeDetectorRef, Component, EventEmitter, Output, Renderer2, ViewChild} from '@angular/core';
import {AbstractFormFieldComponent} from '../abstract/abstract-form-field.component';
import {ValidationConstraintService} from '../../services/validation-constraint.service';
import {TooltipService} from '../../../../services/tooltip/tooltip.service';
import {GlobalModel} from '../../../../services/state/global.model';
import {FormDataService} from '../../services/form-data.service';
import {GlobalAlertService} from '../../../../../wrapper/global-alert/global-alert.service';
import {TranslateService} from '../../../../services/translate/translate.service';
import {AppSettings} from '../../../../../app.settings';
import { FormEvent } from '../../containers/form/form.interface';
import {LuminizerRoutes} from '../../../../interfaces/routes';
import {
    Preparation,
    WorkPreparationConfig,
    WorkPreparationStatusDropdownOption
} from './form-work-preparation.interface';
import {ListItem, ListItemMenuItemEvent} from '../../../commonUI/list/complex-list-item/complex-list-item.interface';
import {LumiSelectOption} from '../../../commonUI/select/lumi-select/lumi-select.interface';
import {ButtonCode} from '../../../../../wrapper/global-alert/global-popup';
import {Weekprijs} from '../weekprijs/form-weekprijs.interface';
import {LoggerService} from "../../../../services/logger/logger.service";

@Component({
    selector: 'form-work-preparation',
    templateUrl: './form-work-preparation.component.html'
})
export class FormWorkPreparationComponent extends AbstractFormFieldComponent {

    private static readonly STATE_AFGEKEURD: string = 'AFGEKEURD';
    private static readonly STATE_GOEDGEKEURD: string = 'GOEDGEKEURD';
    private static readonly STATE_IN_BEHANDELING: string = 'IN_BEHANDELING';
    private static readonly STATE_AANGEVRAAGD: string = 'AANGEVRAAGD';

    @ViewChild('statusDropDown', {static: false}) statusDropDown: any;
    @ViewChild('messageInput', {static: false}) messageInput: any;
    @Output() onComponentEvent: EventEmitter<any> = new EventEmitter();
    
    public config: WorkPreparationConfig;
    public preparations: Preparation[] = [];
    public statusOptions: LumiSelectOption[];
    public setRepairDate: boolean = true;
    
    public AppSettings = AppSettings;

    constructor(
        public renderer: Renderer2,
        public validationConstraintService: ValidationConstraintService,
        public tooltipService: TooltipService,
        private model: GlobalModel,
        private formDataService: FormDataService,
        private cd: ChangeDetectorRef,
        private globalAlertService: GlobalAlertService,
        public ts: TranslateService,
        protected logger:LoggerService
    ) {
        super(renderer, validationConstraintService, tooltipService, logger);

        // Change behaviour for Stedin.
        // Normally you would pass this state to this component, but the components are created on the fly
        if (this.model.currentModule.value == LuminizerRoutes.STEDIN_ORDERS_PAGE) {
            this.setRepairDate = false;
        }
    }

    ngOnInit() {
        // Set the logs for the view
        if (this.config && this.config.attr) {
            if (this.config.attr.preparations) {
                this.preparations = this.config.attr.preparations.map(preparation => {
                    preparation.listItem = this.getListItem(preparation);
                    return preparation;
                });
            }
            if (this.config.attr.statusDropDown) {
                this.statusOptions = this.config.attr.statusDropDown.map(option => {
                    return {
                        id: option.id,
                        name: option.label
                    };
                });
            }
        }
    }
    
    public getColorByCode(code: string): string {
        let color: string;
        switch (code) {
            case FormWorkPreparationComponent.STATE_AANGEVRAAGD:
                color = AppSettings.COLOR_LIGHT_GREY;
                break;
            case FormWorkPreparationComponent.STATE_GOEDGEKEURD:
                color = AppSettings.COLOR_GREEN;
                break;
            case FormWorkPreparationComponent.STATE_AFGEKEURD:
                color = AppSettings.COLOR_RED;
                break;
            case FormWorkPreparationComponent.STATE_IN_BEHANDELING:
                color = AppSettings.COLOR_ORANGE;
                break;
            default:
                color = AppSettings.COLOR_LIGHT_GREY;
        }
        return `#${color}`;
    }
    
    public getStatusById(id: number): any {
        let result: any;

        for (let i = 0; i < this.statusOptions.length; i++) {
            if (this.statusOptions[i].id == id) {
                result = this.statusOptions[i];
                break;
            }
        }

        return result;
    }


    public isDeletable(preparation: any): boolean {
        return preparation.deleteUrl && preparation.deleteUrl !== '';
    }
    public handleClickUpdatePreparation(event: MouseEvent, preparation: Preparation): void {
        let preparationId: number = preparation.id;

        if (preparation.status === 2 || !preparation.editUrl) {
            return;
        }

        this.globalAlertService.addPopupEditWorkPreparation(preparationId, preparation.editUrl, preparation.saveEditUrl,
            null,
            () => {
                this.onComponentEvent.emit({
                    event: FormEvent.WORK_PREPARATION_UPDATE_SUCCESS,
                    data: {preparationId: preparationId}
                });
            }, () => {
                // Move is canceled, or failed. Do nothing
            });
    }
    
    public handleClickDeletePreparation(event: MouseEvent, preparation: any): void {
        this.globalAlertService.addPopup(this.ts.translate('preparation.deletetitle'), this.ts.translate('preparation.delete'),
            [{label: this.ts.translate('Annuleren'), code: ButtonCode.ANNULEREN, isPrimary: true},
            {label: this.ts.translate('Verwijderen'), code: ButtonCode.DELETE,
                callback: () => {

                    // Show grayed-out preparation until delete call finished
                    preparation.isDeleted = true;
                    this.cd.detectChanges();

                    this.formDataService.deleteWorkPreparation(preparation.deleteUrl,
                        () => {
                            // Remove the old preparation, client side
                            let deleteIndex: number = this.preparations.indexOf(preparation);

                            if (deleteIndex > -1) {
                                this.preparations.splice(deleteIndex, 1);
                                this.cd.detectChanges();
                            }

                            this.onComponentEvent.emit({event: FormEvent.WORK_PREPARATION_DELETE_SUCCESS});
                        }, () => {
                            preparation.isDeleted = false;
                            this.cd.detectChanges();
                        }, () => {
                            preparation.isDeleted = false;
                            this.cd.detectChanges();
                        });
                },
                isPrimary: false}, ], () => {});
    }
    public isUpdatable(preparation: Preparation): boolean {
        return preparation.editUrl && preparation.editUrl !== '';
    }
    
    private getListItem(preparation: Preparation): ListItem {
        const item: ListItem = {
            id: JSON.stringify(preparation),
            title: preparation.type !== '' ? preparation.type : this.ts.translate('preparation.unknown'),
            icon: 'update',
            menuTitle: this.ts.translate('preparation.menu'),
            menuItems: [],
            created: preparation.created,
            creator: preparation.creator
        };
        if (this.isUpdatable(preparation)) {
            item.menuItems.push({
                label: this.ts.translate('menuItem.edit'),
                icon: 'edit',
                action: 'edit'
            });
        }
        if (this.isDeletable(preparation)) {
            item.menuItems.push({
                label: this.ts.translate('menuItem.delete'),
                icon: 'delete',
                action: 'delete'
            });
        }
        return item;
    }
    
    public onPreparationAction($event: ListItemMenuItemEvent, preparation: Preparation): void {
        switch ($event.action) {
            case 'edit':
                this.handleClickUpdatePreparation(null, preparation);
                break;
            case 'delete':
                this.handleClickDeletePreparation(null, preparation);
        }
    }
    
    public getSelectedOptions(preparation: Preparation): LumiSelectOption[] {
        if (this.statusOptions.some(option => option.id === preparation.status)) {
            return [this.statusOptions.find(option => option.id === preparation.status)];
        } else {
            return [];
        }
    }
}
