/**
 * Created by Christiaan on 13/04/2017.
 */
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TreeComponent} from './tree/tree.component';
import {TreeNodeComponent} from './tree/tree-node.component';
import {TreeSearchComponent} from './tree/tree-search.component';
import {TabBarComponent} from './tabBar/tab-bar.component';
import {GoogleChartComponent} from './googleChart/google-chart.component';
import {CommonDirectivesModule} from '../../directives/common/common-directives.module';
import {HelpIconComponent} from './help-icon/help-icon.component';
import {CommonPipesModule} from '../../pipes/common-pipes.module';
import {SliderComponent} from './slider/slider.component';
import {KeyboardButtonIcon} from './icon/keyboard-button-icon.component';
import {VideoPlayerComponent} from './video/video-player.component';
import { LumiButtonComponent } from './button/lumi-button/lumi-button.component';
import {FormComponentLabelComponent} from './input/label/form-component-label.component';
import {RequiredComponent} from './input/label/required.component';
import { SimpleTreeComponent } from './tree/simple-tree/simple-tree.component';
import { SimpleTreeNodeComponent } from './tree/simple-tree/simple-tree-node/simple-tree-node.component';
import { DateInputComponent } from './input/date-input/date-input.component';
import { LumiSelectComponent } from './select/lumi-select/lumi-select.component';
import { ComplexListItemComponent } from './list/complex-list-item/complex-list-item.component';
import { KeyValueComponent } from './key-value/key-value.component';
import { MenuDropdownComponent } from './dropdown/menu-dropdown/menu-dropdown.component';
import { MenuItemDirective } from './dropdown/menu-dropdown/menu-item.directive';
import { LumiCheckboxComponent } from './input/lumi-checkbox/lumi-checkbox.component';
import { LumiSwitchComponent } from './input/lumi-switch/lumi-switch.component';
import {OwlDateTimeModule} from "ng-pick-datetime-ex";

@NgModule({
    // This is a shared module for all small common UI components
    imports: [
        CommonModule,
        RouterModule,
        ReactiveFormsModule,
        CommonDirectivesModule,
        CommonPipesModule,
        FormsModule,
        OwlDateTimeModule
    ],
    exports: [
        TabBarComponent,
        TreeComponent,
        GoogleChartComponent,
        HelpIconComponent,
        SliderComponent,
        KeyboardButtonIcon,
        VideoPlayerComponent,
        LumiButtonComponent,
        RequiredComponent,
        FormComponentLabelComponent,
        SimpleTreeComponent,
        DateInputComponent,
        LumiSelectComponent,
        ComplexListItemComponent,
        KeyValueComponent,
        MenuDropdownComponent,
        MenuItemDirective,
        LumiCheckboxComponent,
        LumiSwitchComponent
    ],
    declarations: [
        TabBarComponent,
        TreeComponent,
        TreeNodeComponent,
        TreeSearchComponent,
        GoogleChartComponent,
        HelpIconComponent,
        SliderComponent,
        KeyboardButtonIcon,
        VideoPlayerComponent,
        LumiButtonComponent,
        RequiredComponent,
        FormComponentLabelComponent,
        SimpleTreeComponent,
        SimpleTreeNodeComponent,
        DateInputComponent,
        LumiSelectComponent,
        ComplexListItemComponent,
        KeyValueComponent,
        MenuDropdownComponent,
        MenuItemDirective,
        LumiCheckboxComponent,
        LumiSwitchComponent
    ]
})

export class CommonUIModule {
}
