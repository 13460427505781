import {Injectable} from '@angular/core';
import {HTTPService} from "../../../shared/services/http/http.service";
import {RequestFailure} from "../../../shared/services/http/request-failure";
import {HTTPError} from "../../../shared/services/http/http-error";
import {ISearchGroups} from "./menu-search.interface";
import {LoggerService} from "../../../shared/services/logger/logger.service";


@Injectable() export class MenuSearchService {
    constructor(private httpService:HTTPService, protected logger:LoggerService) {}

    public performSearch(keyword:string, activeSearchGroup:ISearchGroups,successCallBack?:(results:any) => any, failCallBack?:(failure:RequestFailure) => any){
        this.httpService.doPostRequest('menu/search', {searchText: keyword, searchGroup: activeSearchGroup.searchGroup, totalResults: 60},
            (json:any)=>{

                this.logger.log("[MenuSearchService] " + "search succes response");

                //Let component know request is send
                successCallBack(json);
            }, (failure:RequestFailure)=>{
                failCallBack(failure);
            },
            (error:HTTPError)=>{},
            true);
    }
}
