import {RequestFailure} from '../http/request-failure';

export interface HttpResult {
    message?: string;
    data?: BasicHttpPostResult | BasicHttpGetResult;
    status: HttpStatus;
}

export interface BasicHttpGetResult {
    failure?: RequestFailure;
    formErrors?: any;
    error?: any;
}

export interface BasicHttpPostResult {
    message?: string;
    failure: RequestFailure;
    formErrors: any;
    error: any;
}

export interface FormPostResult extends BasicHttpPostResult {}

export enum HttpStatus {
    SUCCESS = 'success',
    FAIL = 'fail',
    ERROR = 'error'
}
