import {
    AfterViewInit,
    ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, ViewChild
} from '@angular/core';

import {GlobalAlertService} from "../global-alert.service";
import {GlobalPopup} from "../global-popup";
import {HTTPError} from "../../../shared/services/http/http-error";
import {RequestFailure} from "../../../shared/services/http/request-failure";
import {FormDataService} from "../../../shared/components/form/services/form-data.service";
import {GlobalModel} from "../../../shared/services/state/global.model";
import {FormComponent} from "../../../shared/components/form/containers/form/form.component";
import {TranslateService} from "../../../shared/services/translate/translate.service";
import {AbstractBasePopupComponent} from "./abstract-base-popup.component";
import {TooltipService} from "../../../shared/services/tooltip/tooltip.service";
import { FormEvent } from '../../../shared/components/form/containers/form/form.interface';
import {LoggerService} from "../../../shared/services/logger/logger.service";

@Component ({
    selector: 'global-popup-create-drawingitem-component',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <default-popup-wrapper-component [componentRef]="this" [disableButtons]="readOnly" [hideButtons]="isLoading" [globalPopup]="globalPopup" [title]="'Item aanmaken' | translate" (onClose)="closePopup($event)">

            <div class="dynamic-form mb-3">
                <div class="d-flex mt-1 ">
                    <label class="m-0 form-label-part" for="entityDropDown">
                        {{'Kies type:' | translate}}
                    </label>
                    <div class="d-flex w-100">
                        <select #entityDropDown name="entityDropDown" [disabled]="isLoading" class="form-control p-0 m-0" (change)="handleChangeEntity($event)">
                            <ng-container *ngFor="let data of entityDropdownData">
                                <option value="{{data.value}}">{{data.label}}</option>
                            </ng-container>
                        </select>
                    </div>
                </div>
            </div>
            
            <div *ngIf="isLoading" class="py-3 d-flex justify-content-center">
                <!--<div class="pr-3" style="line-height: 32px;height: 32px;">{{'Gegevens ophalen...' | translate}}</div>-->
                <div class="loader"></div>
            </div>
    
            <ng-container *ngIf="formData && config">
                <form-component #form
                        [config]="config"
                        [name]="getFormName()"
                        [validationConstraints]="validationConstraints"
                        [invalidControlsErrors]="invalidControlsErrors"
                        [readOnly]="readOnly"
                        [formIsSubmitted]="formIsSubmitted"
                        (onComponentEvent)="handleComponentEvent($event)"></form-component>
            </ng-container>
        </default-popup-wrapper-component>
    `
})

export class GlobalPopupCreateDrawingItemComponent extends AbstractBasePopupComponent implements AfterViewInit
{

    @Input() requestUrl:string = "";
    @Input() submitUrl:string = "";

    @ViewChild('form', {static: false}) form:any;
    @ViewChild('entityDropDown', {static: false}) entityDropDown:any;

    private geometry:any;

    public entityDropdownData:any[]; // = [{value:'mast', label:this.ts.translate("Mast")}, {value:'schakelkast', label:this.ts.translate('Schakelkast')}];

    public constructor(protected cd:ChangeDetectorRef, protected globalAlertService:GlobalAlertService, protected formDataService:FormDataService, protected elementRef:ElementRef, private model:GlobalModel, protected ts:TranslateService, protected tooltipService:TooltipService, protected logger:LoggerService) {
        super(cd, globalAlertService, elementRef, ts, tooltipService, logger);
    }

    ngAfterViewInit() {

        //Retrieve map item id from the popup data
        this.requestUrl = this.globalPopup.data.requestUrl;
        this.submitUrl = this.globalPopup.data.submitUrl;

        let popupData: any = this.globalPopup.data;

        this.geometry = popupData.geometry;
        this.logger.log("[GlobalPopupCreateMapItemComponent] " + "FILL GEOMETRY DATA: ", this.geometry);
        this.logger.log("[GlobalPopupCreateMapItemComponent] " + "FILL DROPDOWN DATA: ", popupData.entityDropdownData);

        //Update entity dropdowndata, and trigger view refresh
        this.entityDropdownData = popupData.entityDropdownData;
        this.cd.detectChanges();

        if (popupData && popupData.lat && popupData.lng) {
            this.model.formLocationComponentPosition = {lat: popupData.lat, lng: popupData.lng};
        } else {
            this.model.formLocationComponentPosition = null;
        }

        this.submitButton = this.getSubmitButton();

        setTimeout(() => {
            this.requestFormData();
        });
    }

    handlePopupAction(event: MouseEvent, alert: GlobalPopup, button: any): void {
        //callback for submit button comes from this form, not from outside this component
        if (button == this.submitButton){
            this.form.handleComponentEvent({event:FormEvent.SAVE, data:{}});
            //this.form.submitForm();
        }else{
            //Hide alert and perform callback of button
            this.onPopupAction.emit({event:event, alert:alert, button:button});
        }
    }

    public handleComponentEvent(eventData:any):void{
        if (eventData.event == FormEvent.SAVE){
            eventData.data.formData.geometry = this.geometry;
            this.submitFormData(eventData.data.formData);
        }
    }

    private submitFormData(form: any):void
    {
        this.handleSubmitForm();

        this.formDataService.setFormData(form, this.getFormName(), this.submitUrl + this.getEntity(),
            (formData:any) => {
                //this.formData = formData;
                this.handleSubmitResponse();

                //Hide alert and perform success callback via submit button
                this.onPopupAction.emit({event:null, alert:this.globalPopup, button:this.submitButton, data:formData}); //, data:{lat:form.location.latitude, lng:form.location.longitude}});
            },
            (failure: RequestFailure) => {
                this.logger.log("[GlobalPopupCreateBaseObjectComponent] " + "Handle failure response: ", failure);
                this.handleSubmitResponse(failure.formErrors);
            },
            (error:HTTPError) => {
                this.handleSubmitResponse(null, true);
            }
        );
    }

    public closePopup(event:any):void
    {
        this.doDefaultCloseHandling(event, true);
    }

    public handleChangeEntity(event:any){
        this.formData = null;
        this.requestFormData();
    }

    private getEntity():string{

        //Safety for element not loaded
        let entity:string = "mast";

        //Get the value of the dropdown
        if (this.entityDropDown && this.entityDropDown.nativeElement){
            entity = this.entityDropDown.nativeElement.value;
        }

        return entity;
    }

    public requestFormData():void
    {
        this.isLoading = true;

        this.formDataService.getFormData(this.requestUrl + this.getEntity(),
            (formData:any) => {

                //TODO: pas hier de latlng van de popup.data toe op de formData, als beschikbaar
                //En update dus de kaart en de locatie enzo

                //setTimeout(() => {
                this.formData = formData;
                this.isLoading = false;
                this.cd.detectChanges();
                //});
            },
            () => {
                this.closePopup(null);
            },
            () => {
                this.closePopup(null);
            }
        );
    }
}
