import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {FileManagerFolder} from './file-manager-folder/file-manager-folder.interface';
import {ButtonRank} from '../../commonUI/button/lumi-button/lumi-button.interface';
import {FileManagerService} from './file-manager.service';
import {take} from 'rxjs/operators';

@Component({
    selector: 'file-manager',
    templateUrl: './file-manager.component.html'
})
export class FileManagerComponent implements OnInit {
    @ViewChild('folderNameInput', {static: false}) folderNameInput: ElementRef<HTMLInputElement>;
    @Input() public folders: FileManagerFolder[];
    @Input() public readonly: boolean;
    @Input() public disabled: boolean;
    public isAddingFolder: boolean;
    public BUTTON_RANK = ButtonRank;
    
    constructor(
        private fileManagerService: FileManagerService
    ) {
    }
    
    ngOnInit() {
        this.fileManagerService.getFolders().pipe(
            take(1)
        ).subscribe(result => {
            this.setFolders(result);
        });
    }
    
    public addNewFolder() {
        this.isAddingFolder = true;
    }
    
    public saveNewFolder() {
        this.fileManagerService.addFolder(this.folderNameInput.nativeElement.value).pipe(
            take(1)
        ).subscribe(result => {
            this.isAddingFolder = false;
            this.setFolders(result);
        });
    }
    
    public cancelAddNewFolder() {
        this.isAddingFolder = false;
    }
    
    private setFolders(folders: FileManagerFolder[]): void {
        this.folders = folders;
        this.folders.sort((a, b) => {
            return a.name < b.name ? -1 : 1;
        });
    }
    
    public editFolder(name: string, id: number) {
        this.fileManagerService.editFolder(id, name).pipe(
            take(1)
        ).subscribe(result => {
            this.setFolders(result);
        });
        
    }
    
    public deleteFolder(id: number) {
        this.fileManagerService.removeFolder(id).pipe(
            take(1)
        ).subscribe(result => {
            this.setFolders(result);
        });
    }
    
    public addFilesToFolder($event: FileList, id: number) {
        this.fileManagerService.addFilesToFolder(id, $event).pipe(
            take(1)
        ).subscribe(result => {
            this.setFolders(result);
        });
    }
    
    public downloadFiles(ids: number[]) {
        ids.forEach(id => {
            this.fileManagerService.downloadFile(id);
        });
    }
    
    public deleteFiles(ids: number[]) {
        this.fileManagerService.deleteFiles(ids);
    }
}
