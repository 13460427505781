<div class="d-flex multi-select-drop-down-container {{readonly?'read-only':disabled?'disabled':''}}"
     style="min-height: calc(2.25rem + 2px)"
     (click)="toggleDropDown($event)"
     (globalClickOutsideAngular)="closeDropdown()">
    <div *ngIf="selectedMapIcon" class="d-flex align-items-center w-100">
        <div class="map-icon-picker-selected-option-container {{readonly?'read-only':''}} w-100 d-flex align-items-center justify-content-center"
             title="{{selectedMapIcon.name}}">
            <img src="{{selectedMapIcon.imgPath}}" alt="{{selectedMapIcon.name}}">
        </div>
    </div>
    <div *ngIf="!disabled && !readonly" style="margin-left:auto;">
        <div class="cursor-pointer h-100 d-flex align-items-center form-select-arrow-container unselectable-content"
             [style.width]="'fit-content'">
            <i style="left:-1px; top:8px; font-size:18px"
               class="p-0  material-icons">arrow_drop_down</i>
        </div>
    </div>
</div>
<div class="" style="position: relative">
    <div class="{{!showDropdown?'d-none':'d-flex'}} flex-row flex-wrap w-100 color-picker-option-list">
        <ng-container *ngFor="let option of mapIconOptions;">
            <div class="d-flex align-items-center cursor-pointer" (click)="handleClickOption($event, option)">
                <div class="map-icon-picker-option-container d-flex align-items-center justify-content-center"
                     title="{{option.name}}">
                    <img src="{{option.imgPath}}" alt="{{option.name}}">
                </div>
            </div>
        </ng-container>
    </div>
</div>
