<login-header (flippingLanguage)="handleFlippingLanguage($event)"></login-header>
<div class="login-background">
    <div *ngIf="(isLoginVisible(_user) && authenticationMethod !== '') else loading_content" class="login-container">
        <div class="login-container-inner d-flex align-items-center justify-content-between">
            <div class="login-notify-outer">
                <login-notify></login-notify>
            </div>
            <div class="loading-form-container"></div>
            <div class="login-container-right d-flex align-items-center">
                <div class="login-form two-factor-auth-container">
                    <h2 class="login-title pb-2">{{'2fa.title' | translate}}</h2>
                    <p class="mb-4 text-center">{{'2fa.enter-code' | translate}}</p>
                    <div class="input-group two-factor-auth-input-container d-flex">
                        <input #twoFactorAuthInputField
                            type="text"
                            autofocus="autofocus"
                            inputmode="numeric"
                            autocomplete="one-time-code"
                            data-lpignore="true"
                            maxlength="6"
                            pattern="\d{6}"
                            [ngClass]="{'processing' : disableSubmitButton}"
                            [disabled]="disableSubmitButton"
                            (keydown)="handleKeyDown($event)">
                    </div>
                    <p *ngIf="twoFactorError" class="mt-1 ml-2 text-center" style="color:red">{{twoFactorErrorText}}</p>
                    <div class="w-100 mt-3">
                        <p [ngClass]="{'email-link-disabled': timeTillEmailResend > 0}">
                            <span *ngIf="authenticationMethod === 'authenticator' && timeTillEmailResend === 0" [innerHTML]="'2fa.no-app' | translate"></span>
                            <span *ngIf="authenticationMethod === 'sms' && timeTillEmailResend === 0" [innerHTML]="'2fa.no-sms' | translate"></span>
                            <span class="email-link" *ngIf="timeTillEmailResend === 0" (click)="sendAuthenticationEmail()">
                                <span *ngIf="authenticationMethod === 'authenticator' || authenticationMethod === 'sms'" [innerHTML]="'2fa.send-email' | translate"></span>
                                <span *ngIf="authenticationMethod === 'email'" [innerHTML]="'2fa.resend-email' | translate"></span>
                            </span>
                        </p>
                        <div class="countdown" *ngIf="timeTillEmailResend > 0" [innerHTML]="'2fa.email-has-been-send' | translate:[timeTillEmailResend]"></div>
                    </div>
                    <div class="login-btn-container">
                        <p><input type="checkbox" id="remember-me" [(ngModel)]="rememberMe"> <label for="remember-me">{{'2fa.remember-me' | translate}}</label></p>
                        <lumi-button (onClick)="handleClickLogin()"
                            [disabled]="disableSubmitButton"
                            [rank]="'secondary'"
                            [size]="'large'"
                            [fullWidth]="true"
                            [label]="'Log in' | translate">
                        </lumi-button>
                    </div>
                    <div class="login-pass-forget d-flex"><a class="" (click)="handleCancel($event)">{{'Terug'}}</a></div>
                </div>
            </div>
        </div>
        <login-footer></login-footer>
    </div>
    <ng-template #loading_content>
        <div class="loading-filler"></div>
        <div class="loading-form-container d-flex">
            <div class="px-5 py-3 loading-form w-100 d-flex justify-content-center align-items-center flex-column" style="max-width: 30rem;">
                <img class="mx-auto img-fluid d-flex pb-4" style="display: none" src="{{getLogoPath()}}">
                <div class="loader"></div>
            </div>
        </div>
    </ng-template>
</div>
