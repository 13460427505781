<default-popup-wrapper-component [componentRef]="this" [globalPopup]="globalPopup"
                                 [displayMode]="POPUP_DISPLAY_MODE.LARGE"
                                 [title]="'filter.advanced' | translate" (onClose)="closePopup($event)">

    <div class="dynamic-form" style="">
        <div>{{'optionset.pickpreset' | translate}}</div>
        <div class="d-flex flex-row mt-1 align-items-center">
            <lumi-select *ngIf="tableOptionSets?.length > 0"
                         [style.width]="'100%'"
                         [options]="tableOptionSets"
                         [selectedOptions]="getSelectedTableOption()"
                         [showOptionFilter]="tableOptionSets?.length > 10"
                         (onOptionsSelect)="handleSelectOptionSet($event)">
            </lumi-select>

            <div *ngIf="!isCurrentTableOptions()" class="pl-1">
                <div id="userHighlightId" class="nav-item btn-group dropdown">
                    <div id="navbarDropdownMenuLink3" data-toggle="dropdown" class="card cursor-pointer"
                         [title]="'optionset.options' | translate">
                        <i class="p-2 material-icons " style="font-size: 20px">edit</i>
                    </div>
                    <div class="dropdown-menu dropdown dropdown-menu-right" data-toggle="dropdown">
                        <div class='{{liClasses}}'>
                            <a (click)="handleRenameOptionSet()"
                               class='{{aClasses}}'>{{'optionset.rename' | translate}}</a>
                        </div>
                        <div class='{{liClasses}}'>
                            <a (click)="handleDeleteOptionSet()"
                               class='{{aClasses}}'>{{'optionset.delete' | translate}}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="table-options-settings-container {{loadingSet?'no-pointer-events':''}}">
        <div *ngIf="auth.allowShowArchived()" class="dynamic-form mb-4">
            <h2>{{'filter.archive' | translate}}</h2>
            <div class="d-flex">
                <form-component-label
                        [config]="{
                            label:ts.translate('filter.archived'),
                            attr:{infoText:ts.translate('filter.archivedinfo')}
                        }">
                </form-component-label>
                <label class="custom-control m-0 mb-1 ml-1 custom-checkbox w-100">
                    <input type="checkbox" class="custom-control-input"
                           checked="{{tempShowArchived?'checked':''}}" (change)="handleChangeArchived()"/>
                    <span class="custom-control-indicator" style="min-width:25px; min-height:25px;"></span>
                </label>
            </div>
        </div>

        <div class="dynamic-form">
            <table-column-selector #columnSelectorComponent
                                   [allowAddFilters]="showFilterOptions"
                                   [allowToggleOnMap]="!hideMap"
                                   [allowSorting]="true"
                                   [tableFields]="tableFields"
                                   (onChanges)="onColumnChange()">
            </table-column-selector>
        </div>

        <div class="d-flex pt-2">
            <div class="d-flex w-100" *ngIf="!isCurrentTableOptions()">
                <i class="material-icons md-dark p-0 pr-1 md-18">
                    star
                </i>
                <form-component-label
                        style="width: inherit;"
                        [config]="{
                            label:ts.translate('optionset.preferred'),
                            attr:{infoText:ts.translate('optionset.preferredinfo')}
                        }">
                </form-component-label>
                <label class="custom-control m-0 mb-1 ml-1 custom-checkbox w-100" style="margin-top: -5px!important;">
                    <input type="checkbox"
                           class="custom-control-input"
                           checked="{{tempIsPreferred?'checked':''}}"
                           (change)="handleChangePreferred()"/>
                    <span class="custom-control-indicator" style="min-width:25px; min-height:25px;"></span>
                </label>
            </div>
            <div class="w-100 d-flex justify-content-end">
                <ng-container *ngFor="let button of secondaryButtons">
                    <div class="form-link-anchor {{isCurrentTableOptions() && button.code === BUTTON_CODE_SAVE?'disabled':''}} pr-2"
                         (click)="handlePopupAction($event, globalPopup, button)">{{button.label}}</div>
                </ng-container>
            </div>
        </div>
    </div>
</default-popup-wrapper-component>
