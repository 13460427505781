import {ChangeDetectorRef, Component, EventEmitter, HostBinding, OnDestroy, OnInit, Output} from "@angular/core";
import {UntypedFormGroup} from "@angular/forms";
import {AuthorizationService} from "../../../../services/authorization/authorization.service";
import {GlobalModel} from "../../../../services/state/global.model";
import { Subscription } from 'rxjs';
import {HTTPService} from "../../../../services/http/http.service";
import {GlobalAlertService} from "../../../../../wrapper/global-alert/global-alert.service";
import {TranslateService} from "../../../../services/translate/translate.service";
import { FormEvent } from '../../containers/form/form.interface';
import {LoggerService} from "../../../../services/logger/logger.service";

@Component({
    selector: 'inline-form-button',
    template: `
        <ng-container [formGroup]="group">
            <form-component-label style="visibility: hidden" [config]="config"></form-component-label>
            <div class="md-form input-group p-0 m-0 w-100">
                <lumi-button class="w-100"
                             (onClick)="handleClick()"
                             [disabled]="isDisabled()"
                             [label]="config.label"
                             [fullWidth]="true"
                             [icon]="getIcon()"
                             [title]="getToolTip()">
                </lumi-button>
            </div>
        </ng-container>
    `
})

export class FormButtonInlineComponent implements OnDestroy, OnInit{

    @HostBinding('class') hostClasses = ''; //set later

    @Output() onComponentEvent: EventEmitter<any> = new EventEmitter();

    group:UntypedFormGroup;
    readOnly:boolean;
    invalidControlsErrors:any;

    private _config:any;
    private _subMobileMode:Subscription;
    private _mobileMode:Boolean = false;

    public constructor(private auth:AuthorizationService, private model:GlobalModel, private cd:ChangeDetectorRef, private httpService:HTTPService, private globalAlert:GlobalAlertService, private ts:TranslateService, protected logger:LoggerService){}

    ngOnInit():void
    {
        this._subMobileMode = this.model.mobileMode.subscribe((value:boolean) => {
            //Don't forget to unsubscribe
            this._mobileMode = value;
            this.cd.detectChanges(); //Mark for change was not enough here
        });
    }

    ngOnDestroy():void
    {
        if (this._subMobileMode){
            this._subMobileMode.unsubscribe();
        }
    }

    set config(value:any)
    {
        this._config = value;

        if (this.isCancelButton()){
            this.hostClasses = 'cancel-button-container d-flex';
        }else{
            this.hostClasses = "default-button-container d-flex";
        }
    }

    get config():any
    {
        return this._config;
    }

    private isVisible():boolean
    {
        let result:boolean = true;

        if (this.isCancelButton() && this._mobileMode){
            result = false;
        }

        return result;
    }

    public isDisabled(): boolean
    {
        let disabled: boolean = false;

        if (this.readOnly === true){
            disabled = true;
        }else if (this.readOnly === false) {
            disabled = false;
        }

        if (this.config.disabled === true) {
            disabled = true;
        }

        if (this.config.disabledByFormError) {
            disabled = true;
        }

        if (this.config && this.config.attr){
            // Disable if no delete autorisatie.
            // TODO: Kan ook verplaatst worden naar de backend. De knop als disabled doorsturen als je geen delete rechten hebt
            let action: string = this.config.attr.action;
            if (
                (action === FormEvent.DELETE_BASEOBJECT && !this.auth.allowRemoveBaseObject()) ||
                (action === FormEvent.SEGMENT_DELETE && !this.auth.allowRemoveBaseObject()) ||
                (action === FormEvent.COPY_BASEOBJECT && !this.auth.allowCopyBaseObject()) ||
                (action === FormEvent.EXCEPTION_CREATE && !this.auth.allowCreateException()) ||
                (action === FormEvent.SCHEME_EDIT && !this.auth.allowEditDimmingScheme()) ||
                (action === FormEvent.SET_DIMGROUP_OVERRIDE && !this.auth.allowSetOverride()) ||
                (action === FormEvent.DIMGROUP_DELETE && !this.auth.allowDeleteDimGroup())
            ){
                disabled = true;
            }
        }

        if (this.config && this.config.attr && this.config.attr.action === FormEvent.MOVE_MAPITEM){
            disabled = false;
        }

        return disabled;
    }

    public handleClick()
    {
        this.logger.log('[FormButtonInlineComponent] ' + 'handle click form button', this.group);

        let url = '';
        if (this.config.attr && this.config.attr.url){
            url = this.config.attr.url;
        }
        if (this.config.attr && this.config.attr.action && this.config.attr.action === 'CREATE_PV_STORING') {
            this.globalAlert.addPopupCreatePVStoring(this.config.attr.mapItemId,
                () => {
                    // Object is successfully paired
                    this.onComponentEvent.emit({event: FormEvent.PERFECTVIEW_PAIRED_SUCCESS, data: {}});
                    this.onComponentEvent.emit({event: FormEvent.CANCEL, data: {}});
                }, () => {
                }, -1, -1);
        } else if (this.config.attr && this.config.attr.action && this.config.attr.action === 'CREATE_MSB_MELDING') {
            this.globalAlert.addPopupCreateMsbMelding(this.config.attr.mapItemId,
                () => {
                    // Object is successfully paired
                    this.onComponentEvent.emit({event: FormEvent.MSB_MELDING_PAIRED_SUCCESS, data: {}});
                    this.onComponentEvent.emit({event: FormEvent.CANCEL, data: {}});
                }, () => {
                }, -1, -1);
        }  else if (this.config.attr && this.config.attr.action &&
            this.config.attr.action === 'CREATE_WORKORDER_FROM_EXTERNAL_MALFUNCTION_REPORT') {
            this.globalAlert.addPopupCreateWorkorderFromExternalMalfunctionReport(this.config.attr.mapItemId,
                () => {
                    // Object is successfully paired
                    this.onComponentEvent.emit({event: FormEvent.EXTERNAL_MALFUNCTION_REPORT_PAIRED_SUCCESS, data: {}});
                    this.onComponentEvent.emit({event: FormEvent.CANCEL, data: {}});
                }, () => {
                }, -1, -1);
        } else if (this.config.attr && this.config.attr.action && this.config.attr.action === 'EXTERNAL_MALFUNCTION_REPORT_CHANGE_STATE') {
            this.globalAlert.addPopupExternalMalfunctionReportChangeState(this.config.attr.statusCode, this.config.attr.externalMalfunctionReportId,
                () => {
                    // Object is successfully paired
                    this.onComponentEvent.emit({event: FormEvent.EXTERNAL_MALFUNCTION_REPORT_PAIRED_SUCCESS, data: {}});
                    this.onComponentEvent.emit({event: FormEvent.CANCEL, data: {}});
                }, () => {
                }, -1, -1);
        } else if (this.config.attr && this.config.attr.action && this.config.attr.action === 'DEVICE_STATE_CREATE_WORKORDER') {
            this.globalAlert.addPopupWerkbonSuggestionCreateNewWorkorder(this.config.attr.werkbonSuggestionId,
                () => {
                    // Object is successfully paired
                    this.onComponentEvent.emit({event: FormEvent.EXTERNAL_MALFUNCTION_REPORT_PAIRED_SUCCESS, data: {}});
                    this.onComponentEvent.emit({event: FormEvent.CANCEL, data: {}});
                }, () => {
                }, -1, -1);
        }else if (this.config.attr && this.config.attr.action && this.config.attr.action === 'MSB_MELDING_CHANGE_STATE') {
            this.globalAlert.addPopupMeldingApiChangeState(this.config.attr.statusCode, this.config.attr.msbMeldingAssetId,
                () => {
                    // Object is successfully paired
                    this.onComponentEvent.emit({event: FormEvent.MSB_MELDING_PAIRED_SUCCESS, data: {}});
                    this.onComponentEvent.emit({event: FormEvent.CANCEL, data: {}});
                }, () => {
                }, -1, -1);
        } else if (this.config.attr && this.config.attr.action && this.config.attr.action === 'CREATE_ADDITIONAL_MSB_MELDING') {
            this.globalAlert.addPopupCreateAdditionalMSBMelding(this.config.attr.msbMeldingAssetId,
                () => {
                    // Object is successfully paired
                    this.onComponentEvent.emit({event: FormEvent.MSB_MELDING_PAIRED_SUCCESS, data: {}});
                    this.onComponentEvent.emit({event: FormEvent.CANCEL, data: {}});
                }, () => {
                });
        }
        if (this.config.attr && this.config.attr.action && this.config.attr.action === 'create-stedin-order-msb-melding') {
            this.globalAlert.addPopupCreateGenericReport(
                'report-generic/new-report-for-msb-melding',
                'report-generic/save-report-for-msb-melding/' + this.config.attr.msbMeldingAssetId,
                (buttonCode: any, response: any) => {
                    // Show success alert
                    this.globalAlert.addAlertSuccess(
                        this.ts.translate('reportgeneric.reportsuccesstitle'),
                        this.ts.translate('reportgeneric.reportsuccesssubtitle'),
                        this.ts.translate('reportgeneric.reportsuccesslabel',
                            [this.model.currentAreaal.value.label]));

                    // Refresh the map icon
                    if (response && response.schema && response.schema.msbMeldingAssetId) {
                        this.onComponentEvent.emit({event: FormEvent.MSB_MELDING_RELOAD_FORM,
                            data: { msbMeldingAssetId: response.schema.msbMeldingAssetId}});
                    }
                    else {
                        this.logger.log('[ReportGenericComponent] ' + 'ERROR: no base_object_id given');
                    }
                }, () => {
                }, this.config.attr.latitude, this.config.attr.longitude, this.config.attr.msbMeldingAssetId);
        }

        if (url !== ''){
            this.httpService.doPostRequest(url, this.group.value, (json:any) => {
                // wordt er een bericht met een message mee gestuurd (title, subtitle, text), toon dan deze!
                if (json.message && json.message.title && json.message.subtitle && json.message.text) {
                    this.globalAlert.addAlertSuccess(json.message.title, json.message.subtitle, json.message.text);
                } else {
                    this.globalAlert.addAlertSuccess(this.ts.translate('inlinebutton.success.title'), this.ts.translate('inlinebutton.success.subtitle'), this.ts.translate('inlinebutton.success.text'));
                }
                if (this.config.attr && this.config.attr.refresh && this.config.attr.refresh === true) {
                    // wordt nu alleen gebruikt bij perfectView, refresh data en hide form
                    this.onComponentEvent.emit({event: FormEvent.SAVE_SUCCESS, data: {}});
                    this.onComponentEvent.emit({event: FormEvent.CANCEL, data: {}});
                }
            }, () => {});
        }
    }
    
    private hasIcon():boolean{
        return (this.config.attr && this.config.attr.icon && this.config.attr.icon != "");
    }

    private isCancelButton():boolean {
        return (this.config.attr && this.config.attr.action && this._config.attr.action == FormEvent.CANCEL);
    }

    private getClassNames()
    {
        let result:string = "btn btn-primary btn-block waves-effect waves-light";

        if (this.hasIcon()){
            result += " p-0 ";
        }else{
            result += " p-2 ";
        }

        if (this.isCancelButton()){
            result += " cancel-button";
        }

        return result;
    }

    public getIcon():string
    {
        let result:string = "";

        if (this.hasIcon()) {
            result = this.config.attr.icon;
        }

        return result;
    }

    public getToolTip():string
    {
        let result = "";

        if (this.config.attr && this.config.attr.toolTip){
            result = this.config.attr.toolTip;
        }

        return result;
    }
}
