/**
 * Created by Christiaan on 01/03/2017.
 */
import {
    AfterViewInit,
    ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, ViewChild
} from '@angular/core';

import {GlobalAlertService} from "../global-alert.service";
import {ButtonCode, GlobalPopup} from '../global-popup';
import {AbstractBaseFormComponent} from "../../../shared/components/form/containers/form/abstract-base-form.component";
import {HTTPError} from "../../../shared/services/http/http-error";
import {RequestFailure} from "../../../shared/services/http/request-failure";
import {FormDataService} from "../../../shared/components/form/services/form-data.service";
import {GlobalModel} from "../../../shared/services/state/global.model";
import {FormComponent} from "../../../shared/components/form/containers/form/form.component";
import {AuthorizationService} from "../../../shared/services/authorization/authorization.service";
import {TranslateService} from "../../../shared/services/translate/translate.service";
import {AbstractBasePopupComponent} from './abstract-base-popup.component';
import {TooltipService} from "../../../shared/services/tooltip/tooltip.service";
import { FormEvent } from '../../../shared/components/form/containers/form/form.interface';
import {LoggerService} from "../../../shared/services/logger/logger.service";

@Component ({
    selector: 'global-popup-create-work-preparation-component',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <default-popup-wrapper-component [componentRef]="this" [disableButtons]="readOnly" [globalPopup]="globalPopup" [showLoader]="isLoading" (onClose)="closePopup($event)">
            <ng-container *ngIf="formData && config">
                <form-component #form
                                [config]="config"
                                [name]="getFormName()"
                                [validationConstraints]="validationConstraints"
                                [invalidControlsErrors]="invalidControlsErrors"
                                [readOnly]="readOnly"
                                [formIsSubmitted]="formIsSubmitted"
                                (onComponentEvent)="handleComponentEvent($event)"></form-component>
            </ng-container>
        </default-popup-wrapper-component>
    `
})

export class GlobalPopupCreateWorkPreparationComponent extends AbstractBasePopupComponent implements AfterViewInit
{
    private static readonly FORM_URL: string = 'components/preparation/';

    @Input() itemId:number = 0;
    @ViewChild('form', {static: false}) form:any;

    public constructor(protected cd: ChangeDetectorRef, protected globalAlertService: GlobalAlertService,
                       private auth: AuthorizationService, protected formDataService: FormDataService,
                       protected elementRef: ElementRef, private model: GlobalModel, protected ts: TranslateService,
                       protected tooltipService: TooltipService, protected logger:LoggerService) {
        super(cd, globalAlertService, elementRef, ts, tooltipService, logger);
    }

    ngAfterViewInit(){

        // Retrieve map item id from the popup data
        this.itemId = this.globalPopup.data.baseObjectId;

        this.submitButton = this.getSubmitButton();

        this.requestFormData();
    }

    handlePopupAction(event: MouseEvent, alert: GlobalPopup, button: any): void {
        // callback for submit button comes from this form, not from outside this component
        if (button === this.submitButton){
            this.form.handleComponentEvent({event: FormEvent.SAVE, data: {}});
            // this.form.submitForm();
        }else{
            // Hide alert and perform callback of button
            this.onPopupAction.emit({event: event, alert: alert, button: button});
        }
    }
    
    public handleComponentEvent(eventData: any): void
    {
        if (eventData.event === FormEvent.SAVE){
            this.submitFormData(eventData.data.formData);
        }
    }

    private submitFormData(form: any): void
    {
        if (this.itemId === 0){
            this.logger.log('[GlobalAlertMoveItemComponent] ' + 'ERROR: trying to submit the form, but it is not linked to an item');
            return;
        }

        this.handleSubmitForm();

        // tslint:disable-next-line:max-line-length
        this.formDataService.setFormData(form, this.getFormName(),
            GlobalPopupCreateWorkPreparationComponent.FORM_URL + 'new/' + this.itemId,
            (formData: any) => {
                // this.formData = formData;
                this.handleSubmitResponse();
                // Hide alert and perform success callback via submit button
                this.onPopupAction.emit({event: null, alert: this.globalPopup, button: this.submitButton, data:{}});
            },
            (failure: RequestFailure) => {
                this.handleSubmitResponse(failure.formErrors);
            },
            (error: HTTPError) => {
                this.handleSubmitResponse(null, true);
            }
        );
    }

    public closePopup(event: any): void
    {
        this.doDefaultCloseHandling(event, true);
    }

    public requestFormData(): void
    {
        this.formDataService.getFormData(GlobalPopupCreateWorkPreparationComponent.FORM_URL + 'new/' + this.itemId,
            (formData: any) => {
                this.formData = formData;
                this.isLoading = false;
                this.cd.detectChanges();
            },
            () => {
                this.closePopup(null);
            },
            () => {
                this.closePopup(null);
            }
        );
    }

    get BUTTON_CODE_ANNULEREN(){
        return ButtonCode.ANNULEREN;
    }
}
