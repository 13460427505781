<div class="asset-bush" *ngIf="nodes.length > 0">
    <div class="main">
        <div class="d-flex flex-row">
            <div class="status-card action-btn add {{isSelectedItem(mainNode())?'active':''}}">
                <div class="statusbar {{mainNode().icon}}"></div>
                <div class="inner d-flex flex-row" (click)="handleClickNode(mainNode())">
                    <div class="image highlight align-self-center">
                        <img [attr.src]="getImage(mainNode())" />
                    </div>
                    <div class="info">
                        <h6>{{mainNode().objectType}}</h6>
                    </div>
                </div>
               <div class="status-card-line line-b dot-start" (click)="handleNodeCreate(mainNode())"></div>
            </div>
            <div class="status-card" *ngFor="let workorder of workorders">
                <div class="statusbar {{workorder.icon}}"></div>
                <div class="inner d-flex flex-row" (click)="navigateToWorkorder(workorder.formUrl)">
                    <div class="image highlight align-self-center">
                        <img [attr.src]="getImage(workorder)" />
                    </div>
                    <div class="info">
                        <h6>{{'Werkorder' | translate}}</h6>
                        {{workorder.label}}
                    </div>
                </div>
                <div class="status-card-line line-l dot-start dot-end"></div>
            </div>
        </div>
    </div>
    <div class="sub" *ngIf="mainNode().children.length > 0">
        <div class="d-flex flex-row" *ngFor="let parentNode of mainNode().children">
            <div class="parent">
                <div class="status-card action-btn add remove {{isSelectedItem(parentNode)?'active':''}}">
                    <div class="statusbar">
                        <span *ngIf="parentNode.deleteUrl" (click)="handleNodeRemove(parentNode)"></span>
                    </div>
                    <div class="inner d-flex flex-row" (click)="handleClickNode(parentNode)">
                        <div class="image align-self-center">
                            <img [attr.src]="getImage(parentNode)" />
                        </div>
                        <div class="info">
                            <h6>{{parentNode.objectType}}</h6>
                            {{parentNode.label}}
                        </div>
                    </div>
                    <div class="status-card-line line-r dot-start {{parentNode.children.length > 0 ? 'dot-end' : 'no-line'}}" (click)="handleNodeCreate(parentNode)"></div>
                </div>
            </div>
            <div class="children" *ngIf="parentNode.children.length > 0">
                <div class="d-flex flex-row" *ngFor="let childGroupNode of parentNode.children">
                    <div class="status-card action-btn remove {{childNode.canAddNode?'add':''}} {{isSelectedItem(childNode)?'active':''}}" *ngFor="let childNode of childNodes(childGroupNode)" [attr.title]="childNode.label">
                        <div class="statusbar" [ngClass]="childNode.objectType == 'OLC' ? childNode.icon : ''">
                            <span *ngIf="childNode.deleteUrl" (click)="handleNodeRemove(childNode)"></span>
                        </div>
                        <div class="inner d-flex flex-row" (click)="handleClickNode(childNode)">
                            <div class="image align-self-center">
                                <img [attr.src]="getImage(childNode)" />
                            </div>
                            <div class="info">
                                {{childNode.objectType}}
                            </div>
                        </div>
                        <div class="status-card-line no-line line-r dot-start" *ngIf="childNode.canAddNode" (click)="handleNodeCreate(childNode)"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>