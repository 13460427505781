import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'duration'
})
export class DurationPipe implements PipeTransform {

    private readonly SECONDS_IN_A_DAY:number = 86400;
    private readonly SECONDS_IN_AN_HOUR:number = 3600;
    private readonly SECONDS_IN_A_MINUTE:number = 60;

    transform(total: number, inputFormat:'ms'|'s'|'h' = 'ms'): unknown {
        if (!total) {
            return `0h 0m`;
        }

        let totalSeconds = total;
        switch (inputFormat) {
            case 'ms':
                totalSeconds = total / 1000;
                break;
            case 's':
                break;
            case 'h':
                totalSeconds = total * this.SECONDS_IN_AN_HOUR;
                break;
        }
        const days = Math.floor(totalSeconds / this.SECONDS_IN_A_DAY);
        const hours = Math.floor((totalSeconds - days * this.SECONDS_IN_A_DAY) / this.SECONDS_IN_AN_HOUR);
        const minutes = Math.round((totalSeconds - days * this.SECONDS_IN_A_DAY - hours * this.SECONDS_IN_AN_HOUR) / this.SECONDS_IN_A_MINUTE)
        const daysString = ':'
        return `${days < 1?'':days + 'd'} ${hours}h ${minutes}m`;
    }

}
