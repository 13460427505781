import {ChangeDetectorRef, Component, HostBinding, Renderer2} from "@angular/core";
import {ValidationConstraintService} from "../../services/validation-constraint.service";
import {GlobalAlertService} from "../../../../../wrapper/global-alert/global-alert.service";
import {TooltipService} from "../../../../services/tooltip/tooltip.service";
import {FormDataService} from "../../services/form-data.service";
import {Router} from "@angular/router";
import {HTTPService} from "../../../../services/http/http.service";
import {TranslateService} from "../../../../services/translate/translate.service";
import {FormInputComponent} from "../form-input/form-input.component";
import {LoggerService} from "../../../../services/logger/logger.service";
import {GlobalModel} from '../../../../services/state/global.model';

@Component({
    selector: 'auth-field-table',
    template: `        
            <label for="{{config.name}}">{{ config.label }}</label>

            <div class="w-100 mx-auto" style="overflow-x: auto;">
                <div class="auth-table-component-container" style="padding-right: 0;">
                    <table  *ngIf="tableData" class="table auth-table table-hover" style="width: inherit;">
                        <thead>
                            <tr class="auth-table-header-basic">
                                <th scope="col" class="">{{'Zichtbaarheid' | translate}}</th>
                                <th scope="col" class="">{{'Label' | translate}}</th>
                                <th scope="col" style="min-width:150px;" class="">{{'Alternatief label' | translate}}</th>
                            </tr>
                        </thead>
                        
                        <tbody>
                            <ng-container *ngFor="let module of tableData.modules">
                                
                                <ng-container *ngFor="let form of module.forms">
                                    <tr>
                                        <td (click)="handleClickExpandForm($event, form)" class="auth-table-header pl-0 main-row" colspan='3' style="font-size:12px">
                                            <i class="material-icons expand-button mr-2 px-2 py-0">{{form.expanded?'expand_more':'keyboard_arrow_right'}}</i>
                                            {{module.name + ' - ' + form.name}}
                                        </td>
                                    </tr>

                                    <ng-container *ngIf="form.expanded">
                                    <ng-container *ngFor="let field of form.fields">
                                        <tr>
                                            <td class="auth-field-table-checkbox-IE-fix" style="font-size:12px; padding-top:0.6rem;">
                                                <label class="custom-control m-0 ml-1 custom-checkbox">
                                                    <input type="checkbox" class="custom-control-input" checked="{{field.visible == true?'checked':''}}" (change)="field.visible = !field.visible"/>
                                                    <span class="custom-control-indicator"></span>
                                                </label>
                                            </td>

                                            <td class="auth-table-header tabbed-row" [style.padding-top]="'0.77rem'" [style.color]="field.visible == true?'unset':GREYED_OUT_COLOR">
                                                {{field.label}}
                                            </td>

                                            <td style="font-size:12px;">
                                                <input class="" placeholder="" type="text" [disabled]="field.visible != true" [(ngModel)]="field.label_override">
                                            </td>
                                        </tr>
                                    </ng-container>
                               </ng-container>
                            </ng-container>
                            </ng-container>
                        </tbody>
                    </table>
                </div>
            </div>
    `
})

export class AuthFieldTableComponent extends FormInputComponent
{
    @HostBinding('class') hostClasses = 'd-flex flex-column';

    public config: any;

    private isLoading:boolean = false;
    public tableData:any;

    constructor(public renderer:Renderer2, public cd:ChangeDetectorRef, public httpService:HTTPService, public validationConstraintService:ValidationConstraintService,
                public tooltipService:TooltipService, public globalAlertService: GlobalAlertService, private formDataService:FormDataService, private router:Router, public ts:TranslateService, protected logger:LoggerService, public model:GlobalModel)
    {
        super(renderer, validationConstraintService, tooltipService, globalAlertService, ts, logger, model);
    }

    ngOnInit()
    {
        if (this.config.attr && this.config.attr.url){
            this.getTableData(this.config.attr.url);
        }else{
            this.globalAlertService.addAlert(this.ts.translate("Formulier incompleet"), this.ts.translate("Geen URL meegegeven"), this.ts.translate("De tabel verwacht een URL, maar die is niet meegegeven"));
        }
    }
    
    public handleClickExpandForm(event:any, form:any):void{
        form.expanded = !form.expanded;
    }

    private getTableData(url:string)
    {
        this.isLoading = true;

        this.formDataService.getTableData(url,
            (json: any) => {
                this.isLoading = false;
                this.tableData = json;

                this.group.controls[this.config.name].patchValue(this.tableData);

                this.cd.detectChanges();
            }
        );
    }

    get GREYED_OUT_COLOR(){
        return '#919191';
    }
}
