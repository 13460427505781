<div (click)="handleClickMenu($event)"
     (globalClickOutsideAngular)="closeMenu()"
     [ngClass]="getButtonClass()"
     tabindex="0"
     #menuButton
     (keyup)="onKeyUpMenuButton($event)">
    <a class="md-button"
       title="opties">
        <i [style.color]="buttonColor">settings</i>
    </a>
    <div class="md-menu {{showMenu?'d-block':'d-none'}} {{fitcontent?'min-width-max-content':''}}"
         #menuItemsContainer
         (globalKeydownOutsideAngular)="onkeyDownMenu($event)"
         [style.left]="leftDropdown? 0:'auto'"
         [style.right]="leftDropdown? 'auto':0">
        <h6 class="md-header">{{menuTitle}}</h6>
        <ng-container *ngFor="let menuItem of menuItems">
            <a (click)="handleMenuItemClick($event, menuItem)"
               [title]="menuItem.label"
               menuItem
               class="md-item">
                <i>{{menuItem.icon}}</i>
                {{menuItem.label}}
            </a>
        </ng-container>
        <ng-content></ng-content>
    </div>
</div>
