import {TableSortDirection} from '../shared/baseTable/baseTable.interface';
import {TableOptionsFilter} from './table-options';

export interface SelectorColumn {
    highlighted: boolean;
    truncateLabel: boolean;
    label: string;
    code: string;
    id: string;
    rank: number;
    onMap: boolean;
    sortDirection: TableSortDirection;
    removable: boolean;
    filter: TableOptionsFilter;
}

export interface SortingConfig {
    attr: SortingConfigAttributes;
    type: ConfigType;
    options: SortingOption[];
    name: string;
    initialValue: TableSortDirection;
    required: boolean
}

export interface SortingOption {
    id: TableSortDirection;
    name: string;
    checked: boolean;
}

export interface ColumnPickerConfig {
    label: string;
    disabled: boolean;
    attr: ColumnPickerConfigAttributes;
    type: ConfigType;
    options: ColumnPickerOption[];
    name: string;
    initialValue: string[];
    required: boolean;
}

export interface ColumnPickerConfigAttributes {
    'batch-update': boolean;
    infoText: string;
}

export interface SortingConfigAttributes {
    'batch-update': boolean;
    nullable: boolean;
}

export interface ColumnPickerOption {
    id: string|number;
    name: string;
    checked?: boolean;
    disabled?: boolean;
}

export interface ChangeFilterEvent {
    data: ChangeFilterEventData
}

export interface ChangeFilterEventData {
    selectedFilter: TableOptionsFilter
}

export interface ChangeSortingEvent {
    data: ChangeSortingEventData
}

export interface ChangeSortingEventData {
    selectedOption: TableSortDirection
    name: string;
}

export enum ConfigType {
    MULTI = 'multi_select',
    SINGLE = 'select'
}
