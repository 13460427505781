import {Injectable} from '@angular/core';

import {GlobalModel} from '../state/global.model';
import {Router} from '@angular/router';
import {GlobalAlertService} from '../../../wrapper/global-alert/global-alert.service';
import {TranslateService} from '../translate/translate.service';
import {fromEvent, merge, Observable, Subject, Subscription, timer} from 'rxjs';
import {StorageService} from '../storage/storage.service';
import Timer = NodeJS.Timer;
import {ButtonCode} from '../../../wrapper/global-alert/global-popup';

@Injectable()
export class TimerService {

    public idleTimerSeconds: number = 1800; // 1800 = 30 min
    public idleTimerPopupSeconds: number = 60; // 60 = 1min
    public returnUrl: string | null = null;

    private interval: any;
    private idle$: Observable<Event>;
    private timer$: Subscription;
    private timeOutMilliSeconds: number;
    private idleSubscription: Subscription;
    private expired$: Subject<boolean> = new Subject<boolean>();

    constructor(
        private model: GlobalModel,
        private router: Router,
        private ts: TranslateService,
        private globalAlertService: GlobalAlertService,
        private storage: StorageService
    ) {}

    public startIdleTimer() {
        this.startWatching(this.idleTimerSeconds).subscribe((isTimedOut: boolean) => {
            if (isTimedOut) {
                const urlTree = this.router.parseUrl(this.router.url);
                urlTree.queryParams = {};
                this.returnUrl = urlTree.toString();
                this.storage.setValue(StorageService.KEY_IDLE_RETURN_URL, this.returnUrl);
                let timeLeft: number = this.idleTimerPopupSeconds;
                this.interval = setInterval(() => {
                    if (timeLeft > 0) {
                        timeLeft--;
                    } else {
                        timeLeft = this.idleTimerPopupSeconds;
                        this.model.idleLogout.next(true);
                    }
                }, 1000);

                this.globalAlertService.addPopupTimer(this.ts.translate('idletimer.popuptitle'), '', this.idleTimerPopupSeconds, [
                    {
                        label: this.ts.translate('idletimer.popupbutton'),
                        code: ButtonCode.ANNULEREN,
                        isPrimary: true,
                    },
                ], () => {
                    clearInterval(this.interval);
                });
            }
        });
    }

    public startWatching(timeOutSeconds): Observable<boolean> {
        this.idle$ = merge(
            fromEvent(document, 'click'),
            fromEvent(document, 'keypress'),
            fromEvent(document, 'mousedown'),
            fromEvent(document, 'mousewheel'),

            // fromEvent(document, 'DOMMouseScroll'),
            // fromEvent(document, 'mousemove'),
            // fromEvent(window, 'mousemove'),
            // fromEvent(document, 'MSPointerMove'),
            // fromEvent(window, 'resize'),
            // fromEvent(document, 'touchmove'),
        );

        this.timeOutMilliSeconds = timeOutSeconds * 1000;

        this.idleSubscription = this.idle$.subscribe(() => {
            this.resetTimer();
        });

        this.startTimer();

        return this.expired$;
    }

    private startTimer() {
        if (this.timer$ === undefined || this.timer$.closed == true) {
            this.timer$ = timer(this.timeOutMilliSeconds, this.timeOutMilliSeconds).subscribe(() => {
                this.expired$.next(true);
            });
        } else {
            this.timer$.unsubscribe();
        }
    }

    public resetTimer() {
        if (this.timer$ && this.timer$.closed == false) {
            this.timer$.unsubscribe();
            this.startTimer();
        } else {
            this.startTimer();
        }
    }

    public stopTimer() {
        if (this.timer$ && this.timer$.closed === false) {
            this.timer$.unsubscribe();
            this.idleSubscription.unsubscribe();
            this.expired$.next(false);
            if (<boolean>this.timer$.closed === true) {
                this.resetTimer();
            }
        }
    }
}
