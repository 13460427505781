import {Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild} from '@angular/core';
import {GlobalModel} from '../../../../services/state/global.model';
import {FileManagerFile} from './file-manager-folder.interface';
import {ButtonRank} from '../../../commonUI/button/lumi-button/lumi-button.interface';

@Component({
    selector: 'file-manager-folder',
    templateUrl: './file-manager-folder.component.html'
})
export class FileManagerFolderComponent implements OnInit {
    @ViewChild('folderNameInput', {static: false}) folderNameInput: ElementRef<HTMLInputElement>;
    @Input() folderName: string;
    @Input() files: FileManagerFile[];
    @Input() readOnly: boolean = false;
    @Input() disabled: boolean = false;
    @Output() onFolderEdit: EventEmitter<string> = new EventEmitter<string>();
    @Output() onFolderDelete: EventEmitter<number> = new EventEmitter<number>();
    @Output() onFilesAdd: EventEmitter<FileList> = new EventEmitter<FileList>();
    @Output() onFilesDelete: EventEmitter<number[]> = new EventEmitter<number[]>();
    @Output() onFilesDownload: EventEmitter<number[]> = new EventEmitter<number[]>();
    
    public expanded: boolean = false;
    public showFileSelector: boolean = false;
    public selectedFiles: FileManagerFile[] = [];
    public showFolderMenu: boolean = false;
    public editingFolder: boolean = false;
    public BUTTON_RANK = ButtonRank;
    private lastCLickedFile: any = null;
    
    constructor(
        public globalModel: GlobalModel
    ) {
    }
    
    ngOnInit() {
    }
    
    public toggleExpandFolder() {
        this.expanded = !this.expanded;
    }
    
    public openFileSelector($event: MouseEvent): void {
        this.preventDefault($event);
        this.showFileSelector = true;
        this.closeMenuDropdowns();
    }
    
    public closeFileSelector($event: MouseEvent): void {
        this.preventDefault($event);
        this.showFileSelector = false;
        this.closeMenuDropdowns();
    }
    
    @HostListener('dragover', ['$event'])
    public onDragOver($event): void {
        this.preventDefault($event);
        this.expanded = true;
        this.showFileSelector = true;
        this.closeMenuDropdowns();
    }
    
    @HostListener('window:click', ['$event'])
    public destroyToolTips(): void {
        this.closeMenuDropdowns();
    }
    
    public addFiles($event: FileList): void {
        this.onFilesAdd.emit($event);
    }
    
    public closeFileInfo(): void {
        this.selectedFiles = [];
        this.closeMenuDropdowns();
    }
    
    public selectFile(event: MouseEvent, file: FileManagerFile): void {
        this.preventDefault(event);
        this.closeMenuDropdowns();
        
        if (event.shiftKey && this.lastCLickedFile && !this.readOnly) {
            const lastClickedFileIndex = this.files.findIndex(_file => _file.id === this.lastCLickedFile.id);
            const currentFileIndex = this.files.findIndex(_file => _file.id === file.id);
            
            this.selectedFiles = this.files.slice(
                Math.min(lastClickedFileIndex, currentFileIndex), Math.max(lastClickedFileIndex, currentFileIndex) + 1
            );
        } else if (event.ctrlKey || event.metaKey && !this.readOnly) {
            if (this.selectedFiles.some(_file => _file.id === file.id)) {
                const selectedFileIndex = this.selectedFiles.findIndex(_file => _file.id === file.id);
                this.selectedFiles.splice(selectedFileIndex, 1);
            } else {
                this.selectedFiles.push(file);
            }
        } else {
            this.selectedFiles = [file];
        }
        
        this.lastCLickedFile = file;
    }
    
    public toggleShowFolderMenu($event: MouseEvent): void {
        this.preventDefault($event);
        this.showFolderMenu = !this.showFolderMenu;
    }
    
    public closeMenuDropdowns(): void {
        this.showFolderMenu = false;
    }
    
    public preventDefault(event: Event): void {
        event.preventDefault();
        event.stopPropagation();
    }
    
    public isFileSelected(file: FileManagerFile): boolean {
        return this.selectedFiles.some(_file => file.id === _file.id);
    }
    
    public deleteFolder($event: MouseEvent) {
        if (this.files.length > 0) {
            // todo: add 'are you sure?' popup
        } else {
            this.onFolderDelete.emit();
        }
    }
    
    public editFolder($event: MouseEvent) {
        this.preventDefault($event);
        this.closeMenuDropdowns();
        this.editingFolder = true;
    }
    
    public saveFolder($event: MouseEvent) {
        this.preventDefault($event);
        this.onFolderEdit.emit(this.folderNameInput.nativeElement.value);
        this.editingFolder = false;
    }
    
    public cancelEditFolder($event: MouseEvent) {
        this.preventDefault($event);
        this.editingFolder = false;
    }
    
    public download($event: MouseEvent) {
        this.onFilesDownload.emit(this.selectedFiles.map(file => file.id));
    }
    
    public deleteFile($event: MouseEvent) {
        this.onFilesDelete.emit(this.selectedFiles.map(file => file.id));
        this.selectedFiles = [];
    }
}
