import {Component, EventEmitter, OnDestroy, Output, Renderer2} from "@angular/core";
import {ValidationConstraintService} from "../../services/validation-constraint.service";
import {TooltipService} from "../../../../services/tooltip/tooltip.service";
import {GlobalModel} from "../../../../services/state/global.model";
import 'moment/locale/nl';
import {AbstractFormFieldComponent} from "../abstract/abstract-form-field.component";
import { Subscription } from 'rxjs';
import {TranslateService} from "../../../../services/translate/translate.service";
import { FormEvent } from '../../containers/form/form.interface';
import {LoggerService} from "../../../../services/logger/logger.service";

@Component({
    selector: 'form-item-count',
    template: `
        <ng-container [formGroup]="group" >
            <label title="{{getIdsAsString()}}" class="has-white-space">{{getSelectedItemText(selectedItems.length)}}</label>
        </ng-container>
    `
})

export class FormItemCountComponent extends AbstractFormFieldComponent implements OnDestroy
{
    @Output() onComponentEvent: EventEmitter<any> = new EventEmitter();
    public selectedItems:any;

    private subSelectedItems: Subscription = null;

    constructor(public renderer:Renderer2, public validationConstraintService:ValidationConstraintService, public tooltipService:TooltipService, private model:GlobalModel, private ts:TranslateService, protected logger:LoggerService)
    {
        super(renderer, validationConstraintService, tooltipService, logger);

        //TODO: dit zou mooier zijn dynamisch, maar voor nu voldoet dit
        this.subSelectedItems = this.model.currentSelectedItems.subscribe((selectedItems: any[]) => {
            //Don't forget to unsubscribe

            //This will be triggered upon entering this module (thus, setting its selected state)
            //Also triggered by new url+param
            this.selectedItems = selectedItems;
        });
    }
    
    public getSelectedItemText(selectedItems:number):string
    {
        if (this.config && this.config.attr && this.config.attr.multipleEntities){
            return this.ts.translate('batchupdate.description', [selectedItems]);
        }else{
            return this.ts.translate('batchupdate.description.short', [selectedItems]);
        }
    }

    //TODO: eigenlijk moeten dit labels zijn, maar die informatie is hier niet beschikbaar. Je zou kunnen proberen te mappen op mapitems?
    public getIdsAsString()
    {
        let result:string = "Items: ";

        if (this.selectedItems){
            for(let i = 0; i < this.selectedItems.length; i++) {
                result += this.selectedItems[i].baseObjectId;
                result += (i < this.selectedItems.length - 1?", ":"");
            }
        }

        return result
    }

    //Let parents know deselection is needed
    private handleClickDeselect(){
        this.onComponentEvent.emit({event:FormEvent.CLEAR_SELECTION, data:{}})
    }

    ngOnDestroy(){
        if (this.subSelectedItems){
            this.subSelectedItems.unsubscribe();
        }
    }
}
