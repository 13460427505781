import {Injectable} from '@angular/core'
import {Cell, DataType, Header, Row, TableSortDirection, TableSorting} from './baseTable.interface'
import Utils from '../../../../utils/utils'
import {LoggerService} from "../../../../services/logger/logger.service";

@Injectable()
export class SortService {
    private activeSorting: TableSorting = null;
    
    constructor(protected logger: LoggerService) {
    }
    
    public getActiveSorting(): TableSorting {
        return this.activeSorting;
    }
    
    public sortByColumn(rows: Row[], columnId: string | number, headers: Header[], direction?: TableSortDirection, firstSort = false): void {
        headers.forEach( (header) => {
            if (header.columnId === columnId && header.code === 'icon' && firstSort) {
                columnId = Number(columnId) + 1;
            }
            if (header.isVisible && firstSort) {
                direction = TableSortDirection.SORT_DIRECTION_DESC;
            }
        })
        this.logger.log(Utils.measureFunctionTime('[SortService] Column sorted in: ', () => {
            this.updateHeaderSortDirections(headers, columnId, direction);
            this.sortRowsByColumn(rows, columnId, headers);
            this.setActiveSorting(headers, columnId);
        }));
    }
    
    private setActiveSorting(headers: Header[], columnId: string | number): void {
        this.activeSorting = {
            columnId: columnId,
            sortDirection: this.getSortDirectionByColumnID(headers, columnId)
        };
    }
    
    private updateHeaderSortDirections(headers: Header[], columnId: string | number, direction?: TableSortDirection): void {
        headers.forEach((header: Header) => {
            if (header.columnId === columnId) {
                if (direction === undefined) {
                    switch (header.sortDirection) {
                        case TableSortDirection.SORT_DIRECTION_ASC :
                            header.sortDirection = TableSortDirection.SORT_DIRECTION_DESC;
                            break;
                        default:
                            header.sortDirection = TableSortDirection.SORT_DIRECTION_ASC;
                            break;
                    }
                } else {
                    header.sortDirection = direction;
                }
            } else {
                header.sortDirection = TableSortDirection.SORT_DIRECTION_UNSET;
            }
        });
    }
    
    private sortRowsByColumn(rows: Row[], columnId: string | number, headers: Header[]): void {
        const isAscending: boolean = this.getSortDirectionByColumnID(headers, columnId) === TableSortDirection.SORT_DIRECTION_ASC;
        
        rows.sort((rowA: Row, rowB: Row) => {
            const cellA: Cell = this.getCellInRowByColumnID(rowA, columnId);
            const cellB: Cell = this.getCellInRowByColumnID(rowB, columnId);
            let cellDataType = cellA?.dataType;
            let cellLabelA: string = cellA?.label;
            let cellLabelB: string = cellB?.label;
            
            if (cellDataType === DataType.COMPLEX) {
                cellDataType = DataType.STRING;
                cellLabelA = cellA?.children.reduce((string, cellChild) => {
                    return string + cellChild.label;
                }, '');
                cellLabelB = cellB?.children.reduce((string, cellChild) => {
                    return string + cellChild.label;
                }, '');
            }
            
            if (cellDataType === DataType.DATETIME || cellDataType === DataType.DATE) {
                return this.compareDateTimeStrings(cellLabelA, cellLabelB, isAscending);
            }
            
            if (cellDataType === DataType.NUMBER) {
                return this.compareNumbers(Number(cellLabelA), Number(cellLabelB), isAscending);
            }
            
            return this.compareStrings(cellLabelA, cellLabelB, isAscending);
        });
    }
    
    private getSortDirectionByColumnID(headers: Header[], columnId: string | number): TableSortDirection {
        if (headers.some((header: Header) => header.columnId === columnId)) {
            return headers.find((header: Header) => header.columnId === columnId).sortDirection;
        } else {
            return TableSortDirection.SORT_DIRECTION_ASC;
        }
    }
    
    private getCellInRowByColumnID(row: Row, columnId: string | number): Cell {
        return row.cells.find((cell: Cell) => cell.columnId === columnId);
    }
    
    private compareDateTimeStrings(dateTimeStringA, dateTimeStringB, isAscending): number {
        return this.compareStrings(
            this.getComparableDateTimeString(dateTimeStringA),
            this.getComparableDateTimeString(dateTimeStringB),
            isAscending
        );
    }
    
    private getComparableDateTimeString(dateTimeString: string) {
        let dateTimeList = dateTimeString.split(' ');
        let result: string;

        // First handle a possible date, if any, at least something with two `-` else skip
        let dateList = dateTimeList[0].split('-');
        if (dateList.length === 3) {  // Houston we have a date
            result = dateList[2] + dateList[1] + dateList[0];
        } else {  // No date found, dont sort by this shit
            result = '';
        }

        // Next try to add time, if any
        if (dateTimeList.length > 1) {
            // At least something with one `:` else skip
            let timeList = dateTimeList[1].split(':');
            if (timeList.length > 1) {
                result += timeList.join('');
            }
        }

        return result;
    }
    
    private compareStrings(stringA: string, stringB: string, isAscending: boolean): number {
        if (stringA < stringB) {
            return isAscending ? -1 : 1;
        }
        if (stringA > stringB) {
            return isAscending ? 1 : -1;
        }
        return 0;
    }
    
    private compareNumbers(numberA: number, numberB: number, isAscending: boolean) {
        if (numberA < numberB) {
            return isAscending ? -1 : 1;
        }
        if (numberA > numberB) {
            return isAscending ? 1 : -1;
        }
        return 0;
    }
}
