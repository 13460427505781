<label class="w-100 d-flex custom-control {{right?'mr-0':''}} {{isDisabled? 'disabled':''}} custom-checkbox pl-0 w-100"
       [style.margin-bottom]="small? '0':''">
    <input [checked]="value"
           (change)="onInputChange()"
           type="checkbox"
           disabled="{{isDisabled? 'disabled':''}}"
           class="custom-control-input"
    />
    <span tabindex="0"
          #checkbox
          (keydownOutsideAngular)="onKeyDown($event)"
          (globalKeyupOutsideAngular)="onKeyUp($event)"
          class="custom-control-indicator"
          [style.width]="!small? '25px':''"
          [style.height]="!small? '25px':''"
          [style.min-width]="!small? '25px':''"
          style="position: relative;">
    </span>
    <span class="pl-1 mr-auto d-flex align-items-center" *ngIf="label">{{label}}</span>
</label>
