import { NgModule }       from '@angular/core';
import { CommonModule }   from '@angular/common';
import { FormsModule }    from '@angular/forms';
import {DynamicMenuItemComponent} from "./menu/menu-item/dynamic-menu-item.component";
import {MenuComponent} from "./menu/menu.component";
import {DashboardComponent} from "../modules/dashboard/dashboard.component";
import {DashboardModule} from "../modules/dashboard/dashboard.module";
import {RouterModule} from "@angular/router";
import {RightSideMenuComponent} from "./menu/right-side-menu/right-side-menu.component";
import {CommonDirectivesModule} from "../shared/directives/common/common-directives.module";
import {MenuSearchComponent} from "./menu/menu-search/menu-search.component";
import {MenuSearchMobileComponent} from "./menu/menu-search/menu-search-mobile.component";
import {CommonPipesModule} from "../shared/pipes/common-pipes.module";
import {NotificationsSidebarComponent} from '../modules/notifications/notifications-sidebar.component';
import {HistoryComponent} from './menu/menu-history/history.component';

@NgModule({
    imports: [
        CommonModule,
        CommonDirectivesModule,
        FormsModule,
        RouterModule,
        DashboardModule,
        CommonPipesModule
    ],
    declarations: [
        MenuComponent,
        RightSideMenuComponent,
        DynamicMenuItemComponent,
        MenuSearchComponent,
        MenuSearchMobileComponent,
        NotificationsSidebarComponent,
        HistoryComponent
    ],
    exports: [
        MenuComponent
    ],
    providers: []
})

export class DynamicMenuModule {}
