import {ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output} from '@angular/core';
import {Form, UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {ValidationConstraintService} from '../../services/validation-constraint.service';
import {ChangeableComponent} from '../../../changeable/changeable.component';
import {Subscription} from 'rxjs';
import {GlobalEvent} from '../../../../interfaces/global-event';
import {GlobalModel} from '../../../../services/state/global.model';
import {FormFieldConfigInterface} from '../../components/abstract/abstract-form-field.component';
import {FormEvent, LumiFormEvent, LumiFormEventData} from './form.interface';
import {FormGroupConfig} from '../../components/field/fieldDirective.interface';

@Component({
    selector: 'form-component',
    template: `
        <form name="{{name}}" class="dynamic-form form {{ name }}" [formGroup]="form" (input)="onFormInput()">
            <!-- Top most form group, the base of the form content -->
            <form-group-component
                    [config]="_config"
                    [rootConfig]="_config"
                    [level]="0"
                    [form]="form"
                    [label]="label"
                    [buttonsOnly]="buttonsOnly"
                    [name]="name"
                    [readOnly]="readOnly"
                    [invalidControlsErrors]="invalidControlsErrors"
                    [validationConstraints]="validationConstraints"
                    [formIsSubmitted]="formIsSubmitted"
                    (onComponentEvent)="handleComponentEvent($event)">
            </form-group-component>
        </form>
    `,
})

export class FormComponent extends ChangeableComponent {
    @Input() name: string;
    @Input() label: string;
    @Input() form: UntypedFormGroup;
    public _config: FormFieldConfigInterface | FormGroupConfig;
    @Input() set config(value: FormFieldConfigInterface) {
        if (!this.model.mobileMode.value){
            if (FormComponent.isTypeString(value)) {
                value.initialFocus = true;
            } else if (FormComponent.hasChildren(value)) {
                if (FormComponent.hasChildrenOfTypeString(value)) {
                    value.children.filter(FormComponent.isTypeString)[0].initialFocus = true;
                } else if (value.children.some(FormComponent.hasChildren)) {
                    if (value.children.some(FormComponent.hasChildrenOfTypeString)) {
                        value.children.filter(FormComponent.hasChildren)
                            .filter(FormComponent.hasChildrenOfTypeString)[0].children
                            .filter(FormComponent.isTypeString)[0].initialFocus = true;
                    }
                }
            }
        }
        this._config = value;
    }
    @Input() validationConstraints: any;
    @Input() invalidControlsErrors: any;
    @Input() readOnly: boolean;
    @Input() buttonsOnly: boolean = false;
    @Input() formIsSubmitted: boolean;

    @Output() onComponentEvent: EventEmitter<LumiFormEvent> = new EventEmitter<LumiFormEvent>();

    private subFormValueChanges: Subscription;

    constructor(private formBuilder: UntypedFormBuilder, private validationConstraintService: ValidationConstraintService, private changeDetector: ChangeDetectorRef, protected elementRef: ElementRef, protected model: GlobalModel) {
        super(elementRef);
    }

    private static hasChildrenOfTypeString(config: FormFieldConfigInterface):boolean {
        return config.children && config.children.some(FormComponent.isTypeString);
    }

    private static hasChildren(config: FormFieldConfigInterface):boolean {
        return !!config.children;
    }

    private static isTypeString(config: FormFieldConfigInterface):boolean {
        return config.type === 'string';
    }

    public handleComponentEvent(eventData: LumiFormEvent) {
        if (!eventData.data) {
            eventData.data = {} as LumiFormEventData;
        }

        // Trigger an event to update all form values (handy for custom form components that need to rebuild the form control values)
        this.model.onGlobalEvent.next(new GlobalEvent(GlobalEvent.EVENT_PRE_READ_FORM, {}));

        // Read the form data
        eventData.data.formData = this.form.value;

        //Pass the itemid with the event data
        if (this._config) {
            //NOTE: Old forms didn't have baseobjectid in the schema. New forms do
            //When all forms have the new id, the old referenceID can be removed.
            if (this._config.base_object_id) {
                eventData.data.referenceId = this._config.base_object_id;
            }
            else if (this._config.referenceId) {
                eventData.data.referenceId = this._config.referenceId;
            }
        }

        //TODO: Dit is gevoelig voor fouten, maar de events zijn weer nodig voor specifieke form actions na het opsla event. Dit stukje is alleen maar zodat formerrors worden weergegeven na een form-opsla-actie.
        if (eventData.event == FormEvent.SAVE || eventData.event == FormEvent.BATCH_SAVE || eventData.event == FormEvent.DIMGROUP_SAVE) {
            this.formIsSubmitted = true;
        }

        this.onComponentEvent.emit(eventData);
    }

    ngOnInit() {
        this.form = this.createGroup();

        if (this.form) {
            this.subFormValueChanges = this.form.valueChanges.subscribe(data => this.onFormValueChanged(data));
        }
    }

    ngAfterViewChecked() {

        //TODO: deze regel wordt (te) vaak uitgevoerd. Is dat wel echt nodig?
        this.changeDetector.detectChanges();
    }

    //Create the form model
    private createGroup(): UntypedFormGroup {
        let groupObject: any = this.recurseCreateGroup(this._config.children, {});
        return this.formBuilder.group(groupObject);
    }

    private recurseCreateGroup(configurations: any, groupObject: any): any {
        for (let configuration of configurations) {

            groupObject[configuration.name] = {};

            if (configuration.children) {
                groupObject[configuration.name] = new UntypedFormGroup(this.recurseCreateGroup(configuration.children, groupObject[configuration.name]));
            }
            else {
                groupObject[configuration.name] = this.formBuilder.control('', this.validationConstraintService.getValidatorsForControl(configuration));
            }
        }

        return groupObject;
    }

    //Is triggered for every value change on the form controls > often
    private onFormValueChanged(data?: any): void {
        //Check for validation errors, even if the fields are untouched
        //Store the current form errors
        this.invalidControlsErrors = this.validationConstraintService.getControlErrors(this.form, data, [], false);

        //Check if the submitbutton can be enabled or not
        //Disable submit buttons
        let hasErrors: boolean = (this.invalidControlsErrors && Object.keys(this.invalidControlsErrors).length > 0);
        this.recursivelyDisableSubmitButtons(this._config.children, !hasErrors);
    }

    //TODO: dit loopt over heel de config, elke keer als er iets aangepast wordt. Kan vast efficienter?
    private recursivelyDisableSubmitButtons(config: any, enabled: boolean) {
        for (let field of config) {

            if (field.type && field.type == 'button' && (field.name == 'save' || field.name == 'submit')) {
                field.disabledByFormError = !enabled;
            }

            if (field.children && field.children.length > 0) {
                this.recursivelyDisableSubmitButtons(field.children, enabled);
            }
        }
    }

    //TODO: wat is het verschil met value change hierboven? Hij zit wel nét anders in de lifecycle
    onFormInput() {
        this.formIsSubmitted = false;
        this.validationConstraints = null;
    }

    ngOnDestroy() {
        this.subFormValueChanges.unsubscribe();
    }
}
