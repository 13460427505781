/**
 * Created by Christiaan on 16/03/2017.
 */

import {Injectable} from '@angular/core';
import {GlobalModel} from '../../services/state/global.model';
import {HTTPService} from '../../services/http/http.service';
import {GlobalAlertService} from '../../../wrapper/global-alert/global-alert.service';
import {RequestFailure} from '../../services/http/request-failure';
import {MapItem} from '../map/map-item/map-item';
import {TableOptions, TableOptionsField} from '../table/tableColumnSelector/table-options';
import {AuthorizationService} from '../../services/authorization/authorization.service';
import {TreeNodeLMX} from '../commonUI/tree/tree-node-lmx';


@Injectable({
    providedIn: 'root'
})
export class MapTableService {
    public static readonly MAP_ITEM_PATH = 'mapitem/list/';
    public static readonly TABLE_ITEMS_PATH = 'tableitem/list/';
    // public static readonly MOVE_PATH = 'mapitem/move/';
    private static readonly BBOX: string = 'BOUNDING_BOX';

    constructor(private httpService: HTTPService, private model: GlobalModel, private globalAlertService: GlobalAlertService, private auth: AuthorizationService) {
    }

    public getMapItemsById(baseObjects: (number|string)[], references: TreeNodeLMX[], treeCode: String, tableOptions: TableOptions, objectTypes: string[], modulePath: string, successCallBack?: (mapItems: MapItem[]) => void, failCallBack?: (failure: RequestFailure) => void): void {
        let postValues: any = {
            tree: {
                'code': treeCode,
                'references': references,
                'objectTypes': objectTypes,
                baseObjectIds: baseObjects.toString(),
            }, showArchived: (this.auth.allowShowArchived() && tableOptions.showArchived),
        };

        this.httpService.doPostRequest(MapTableService.MAP_ITEM_PATH + modulePath, postValues,
            (json: any, url: string) => {
                if (json && json.mapItems) {
                    successCallBack(json.mapItems);
                }
                else {
                    this.globalAlertService.addAlertEmptyResponse(url);
                    successCallBack([]);
                }
            }, (failure: RequestFailure) => {
                failCallBack(failure);
            },
        );
    }

    public getTableItemsById(baseObjects: (number|string)[], references: TreeNodeLMX[], treeCode: String, tableOptions: TableOptions, objectTypes: string[], modulePath: string, successCallBack?: (tableItems: MapItem[]) => void, failCallBack?: (failure: RequestFailure) => void): void {
        const postValues = {
            tree: {
                'code': treeCode,
                'references': references,
                baseObjectIds: baseObjects.toString(),
                'extraFields': this.getTableFieldCodes(tableOptions.tableFields),
                'objectTypes': objectTypes,
            }, showArchived: (this.auth.allowShowArchived() && tableOptions.showArchived),
        };

        this.httpService.doPostRequest(MapTableService.TABLE_ITEMS_PATH + modulePath, postValues,
            (json: any, url: string) => {
                if (json && json.tableItems) {
                    successCallBack(json.tableItems);
                }
                else {
                    this.globalAlertService.addAlertEmptyResponse(url);
                    successCallBack([]);
                }
            }, (failure: RequestFailure) => {
                failCallBack(failure);
            },
        );
    }

    public getMapItemsInBoundingBox(bounds: google.maps.LatLngBounds, tableOptions: TableOptions, objectTypes: string[], modulePath: string, successCallBack?: (mapItems: MapItem[]) => void): void {
        let postValues: any = {
            tree: {
                code: MapTableService.BBOX,
                bounds: bounds.toJSON(),
                objectTypes: objectTypes,
                showArchived: (this.auth.allowShowArchived() && tableOptions.showArchived),
            },
        };

        this.httpService.doPostRequest(
            MapTableService.MAP_ITEM_PATH + modulePath,
            postValues,
            (json: any, url: string) => {
                if (json && json.mapItems) {
                    successCallBack(json.mapItems);
                }
                else {
                    this.globalAlertService.addAlertEmptyResponse(url);
                    successCallBack([]);
                }
            }, () => {
                //failCallBack(failure)
            },
        );
    }

    public getTableItemsInBoundingBox(bounds: google.maps.LatLngBounds, tableOptions: TableOptions, objectTypes: string[], modulePath: string, successCallBack?: (tableItems: MapItem[]) => void): void {
        let postValues: any = {
            tree: {
                code: MapTableService.BBOX,
                'extraFields': this.getTableFieldCodes(tableOptions.tableFields),
                bounds: bounds.toJSON(),
                objectTypes: objectTypes,
            }, showArchived: (this.auth.allowShowArchived() && tableOptions.showArchived),
        };

        this.httpService.doPostRequest(
            MapTableService.TABLE_ITEMS_PATH + modulePath,
            postValues,
            (json: any, url: string) => {
                if (json && json.tableItems) {
                    successCallBack(json.tableItems);
                }
                else {
                    this.globalAlertService.addAlertEmptyResponse(url);
                    successCallBack([]);
                }
            }, () => {
                //failCallBack(failure)
            },
        );
    }

    public getMapItems(treeCode: String, references: TreeNodeLMX[], tableOptions: TableOptions, objectTypes: string[], modulePath: string, successCallBack?: (mapItems: MapItem[]) => void, failCallBack?: (failure: RequestFailure) => void): void {
        let postValues: any = {
            tree: {'code': treeCode, 'references': references, 'objectTypes': objectTypes},
            showArchived: (this.auth.allowShowArchived() && tableOptions.showArchived),
        };
        this.httpService.doPostRequest(
            MapTableService.MAP_ITEM_PATH + modulePath,
            postValues,
            (json: any, url: string) => {
                if (json && json.mapItems) {
                    //this.model.currentMapItems.next(plainToClass(MapItem, json.mapItems) as any);
                    successCallBack(json.mapItems); //plainToClass(MapItem, json.mapItems) as any);
                }
                else {
                    this.globalAlertService.addAlertEmptyResponse(url);
                    successCallBack([]);
                }
            }, (failure: RequestFailure) => {
                failCallBack(failure);
            },
        );
    }

    private getTableFieldCodes(tableFields: TableOptionsField[]): string[] {
        const fieldArray: string[] = [];

        tableFields.forEach((field) => {

            //If no explicit visibility, use default yes/no.
            if ((field.visible == undefined && field.default) || field.visible) {
                fieldArray.push(field.code);
            }
        });

        return fieldArray;
    }

    public getTableItems(treeCode: String, tableOptions: TableOptions, references: TreeNodeLMX[], objectTypes: string[], modulePath: string, successCallBack?: (tableItems: { tableItems?: MapItem[], sorting?: number }) => void, failCallBack?: (failure: RequestFailure) => void): void {
        let postValues: any = {
            tree: {
                'code': treeCode,
                'extraFields': this.getTableFieldCodes(tableOptions.tableFields),
                'references': references,
                'objectTypes': objectTypes,
            }, showArchived: (this.auth.allowShowArchived() && tableOptions.showArchived),
        };
        this.httpService.doPostRequest(
            MapTableService.TABLE_ITEMS_PATH + modulePath,
            postValues,
            (json: any, url: string) => {
                if (json && json.tableItems) {
                    successCallBack({tableItems: json.tableItems, sorting: json.sorting});
                }
                else {
                    this.globalAlertService.addAlertEmptyResponse(url);
                    successCallBack({});
                }
            }, (failure: RequestFailure) => {
                failCallBack(failure);
            },
        );
    }

    get MAP_ITEM_PATH(): string {
        return MapTableService.MAP_ITEM_PATH;
    }

    get TABLE_ITEMS_PATH(): string {
        return MapTableService.TABLE_ITEMS_PATH;
    }
}
