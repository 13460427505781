import {ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import Utils from '../../../../utils/utils';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
    selector: 'lumi-checkbox',
    templateUrl: './lumi-checkbox.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: LumiCheckboxComponent
        }
    ]
})
export class LumiCheckboxComponent implements OnInit, ControlValueAccessor {
    @Input() checkedString: string;
    @Input() label: string;
    @Input() value: string|boolean;
    @Input() isDisabled: boolean = false;
    @Input() small: boolean = false;
    @Input() right: boolean = false;
    @ViewChild('checkbox', {static: false}) checkbox: ElementRef<HTMLSpanElement>;
    
    private touched = false;
    
    constructor(public cd: ChangeDetectorRef) {}
    
    ngOnInit(): void {
    }
    
    public onKeyDown($event: any) {
        if (!Utils.hasFocus(this.checkbox.nativeElement)) {
            return;
        }
        
        if ($event.key === 'Enter') {
            this.onInputChange();
        }
    }
    
    public onKeyUp($event: any) {
        if (!Utils.hasFocus(this.checkbox.nativeElement)) {
            return;
        }
        
        if ($event.key === 'Escape') {
            Utils.preventDefault($event);
            Utils.removeAllFocus();
        }
    }
    
    writeValue(value: string|boolean): void {
        this.value = value;
    }
    
    onChange = (value) => {};
    
    registerOnChange(onChange: any): void {
        this.onChange = onChange;
    }
    
    onTouched = () => {};
    
    registerOnTouched(onTouched: any): void {
        this.onTouched = onTouched;
    }
    
    markAsTouched(): void {
        if (!this.touched) {
            this.onTouched();
            this.touched = true;
        }
    }
    
    setDisabledState(disabled: boolean): void {
        this.isDisabled = disabled;
    }
    
    public onInputChange(): void {
        if (this.isDisabled) {
            return;
        }
        this.markAsTouched();
        if (this.checkedString) {
            this.value = (this.value !== '') ? '' : this.checkedString;
        } else {
            this.value = !this.value;
        }
        this.onChange(this.value);
    }
}
