import {Directive, ElementRef, Input, NgZone, OnInit} from '@angular/core';
import {ChangeableComponent} from "../../changeable/changeable.component";
import {AppSettings} from "../../../../app.settings";
import {GlobalAlertService} from "../../../../wrapper/global-alert/global-alert.service";
import {LoggerService} from "../../../services/logger/logger.service";

declare var google:any;

@Directive({
    selector: 'google-chart',
    exportAs: 'GoogleChartDirective'
})
export class GoogleChartComponent implements OnInit {

    public _element:any;
    private chartData:any;
    private wrapper:any;
    private oldColors:any[];

    private chart:any;
    private columnRow:any;
    private selectedItem:any;
    private options:any;

    public static readonly TYPE_BARCHART:string ='BarChart';
    public static readonly TYPE_PIECHART:string ='PieChart';
    public static readonly TYPE_LINECHART:string ='LineChart';
    public static readonly TYPE_COLUMNCHART:string ='ColumnChart';
    public static readonly TYPE_STEPPEDAREACHART:string ='SteppedAreaChart';
    public static readonly TYPE_AREACHART:string ='AreaChart';
    public static readonly TYPE_COMBO_CHART:string ='ComboChart';
    public static readonly TYPE_SCATTER:string ='ScatterChart';
    public static readonly TYPE_CANDLESTICKCHART:string ='CandlestickChart';

    private static readonly DEFAULT_LABEL_COLOR: string = AppSettings.getColor('$color_grey-default-label');

    @Input('chartId') public chartId:string;
    @Input('chartType') public chartType:string;
    @Input('chartOptions') public chartOptions: any;

    constructor(public element: ElementRef,  private zone:NgZone, protected elementRef:ElementRef, private globalAlertService: GlobalAlertService, protected logger:LoggerService)
    {
        // super(elementRef);
        this._element = this.element.nativeElement;
    }

    ngOnInit()
    {
        this._element.id = this.chartId;

        this.logger.log("[GoogleChartComponent] " + "start loading chartId: " + this.chartId);
        google.charts.load('current', {'packages':['corechart'],
            callback: () => {
                this.logger.log("[GoogleChartComponent] " + "done loading chartid " + this.chartId);
                //There is no data yet, so no need to draw
            }
        });

    }

    public drawGraph(chartData:Object, graphWidth:number = -1)
    {
        if (!chartData){
            return;
        }

        this.chartData = chartData;

        this.logger.log("[GoogleChartComponent] " + "draw graph chartid " + this.chartId);

        //Check if element exists to prevent error
        if (document.getElementById(this.chartId)) {
            this.zone.runOutsideAngular(() => {
                google.charts.load('current', {'packages':['corechart']});

                //In some case auto-width of graph isn't working well, so here you can overwrite it
                if (graphWidth > -1) {
                    this.logger.log("[GoogleChartComponent] " + "Explicit width set for this graph: " + graphWidth);
                    this.chartOptions.width = graphWidth;
                }

                if (google?.visualization) {
                    this.wrapper = new google.visualization.ChartWrapper({
                        chartType: this.chartType,
                        dataTable: chartData,
                        options: this.chartOptions || {},
                        containerId: this.chartId
                    });
                }

                this.wrapper.draw();

                // Define array for colors, get current options of chart and put current colors in array
                this.oldColors = [];
                this.options = this.wrapper.getOptions();
                if(this.options.colors){
                    this.oldColors = this.options.colors;
                } else {
                    Object.keys(this.options.slices).forEach((key,index) => {
                        this.oldColors.push(this.options.slices[index].color);
                    });
                }

                // Add event listener that triggers on ready of chart draw
                google.visualization.events.addListener(this.wrapper, 'ready', () => {this.chartReady()});

                // Add event listener that triggers on click of item (line, slice etc) / legend item
                google.visualization.events.addListener(this.wrapper ,'select', () => {this.clickLegend()});
            });
        }else{
            this.logger.log("[GoogleChartComponent] " + "No redraw, element doesn't exist");
        }

    }

    private clickLegend():void
    {
        let type:any = this.chartType == GoogleChartComponent.TYPE_PIECHART? 'slices':'series';
        // Get selected item on click
        this.selectedItem = this.chart.getSelection()[0];
        if (this.selectedItem) {
            // If current item row is null, then use column for future code. And other way around.
            this.columnRow = this.selectedItem.row == null? 'column':'row';
            let columnRowCheck:any = this.selectedItem.row == null? 'row':'column';
            if (!this.selectedItem[columnRowCheck] || !(this.selectedItem['row'] && this.selectedItem['column'])) {
                // Check if current chart options already has slices / series in object. if it exists but empty. if it has an item, but item is empty
                if (!this.options[type] || this.options[type].length == 0 || !this.options[type][0]) {
                    this.options[type] = [];
                    // Make items in series / slices for amount of colors defined. Could be nicer, but this works so far. to do: check for actual amount of data?
                    for (let i:number = 0; i <= this.options.colors.length+1; i++) {
                        this.options[type][i] = {};
                    }
                }

                let itemIndex:number = this.selectedItem[this.columnRow]- (this.columnRow == 'column'?1:0);
                let item:any = this.options[type][itemIndex];

                // If color exists and it's transparent then make it visible again with it's old color. else: make item transparent.
                let itemColor = 'transparent'
                let itemTextColorClick = '#FFFFFF'

                if(type === 'slices'){ //Do not make transparent for piecharts to prevent it 'disappearing'
                    itemColor = '#F2F2F2'
                    itemTextColorClick = '#878787'
                }

                if (item && item.color == itemColor) {
                    item.color = this.oldColors[this.selectedItem[this.columnRow]- (this.columnRow == 'column'?1:0)];
                    item.pointsVisible = true;
                    item.textStyle = {color:'#FFFFFF'}
                } else {
                    if (!item){
                        item = {};
                        this.options[type][itemIndex] = item;
                    }
                    item.color = itemColor;
                    item.textStyle = {color:itemTextColorClick}
                    item.pointsVisible = false;
                    item.targetAxisIndex = 0;
                }

                // draw the wrapper again
                this.wrapper.draw();
            }
        }
    }

    private chartReady():void
    {
        this.chart = this.wrapper.getChart();
        let labels = [];
        let labelsType = this.chartType == GoogleChartComponent.TYPE_PIECHART? 'rows' : 'cols' ;
        // get current labels and put in an array for future use
        if (Object.keys(this.chartData).length !== 0){
            for(let i:number = 0; i < this.chartData[labelsType].length; i++){
                if(this.chartType == GoogleChartComponent.TYPE_PIECHART){
                    labels.push(this.chartData[labelsType][i].c[0].v);
                } else {
                    labels.push(this.chartData[labelsType][i].id);
                }
            }
        }

        for(let i:number = 0; i < this.oldColors.length; i++)
        {
            this.oldColors[i] = this.oldColors[i].replace('#', '');
        }

        // timeout for all charts except piechart. uses jquery to search inside SVG for necessary legend elements to make their old color after turning entire item transparent.
        setTimeout(() => {
            for(let j=0; j<labels.length; j++){
                $('#'+this.chart.container.id+' svg > g:first-of-type g[column-id="'+labels[j]+'"] rect').css({'fill': '#'+this.oldColors[j- (this.chartType == GoogleChartComponent.TYPE_PIECHART?0:1)]});
                $('#'+this.chart.container.id+' svg > g:first-of-type g[column-id="'+labels[j]+'"] circle').css({'fill': '#'+this.oldColors[j- (this.chartType == GoogleChartComponent.TYPE_PIECHART?0:1)]});
                $('#'+this.chart.container.id+' svg > g:first-of-type g[column-id="'+labels[j]+'"] path').css({'stroke': '#'+this.oldColors[j- (this.chartType == GoogleChartComponent.TYPE_PIECHART?0:1)], 'stroke-width':2});
            }
        }, this.chartType == GoogleChartComponent.TYPE_PIECHART? 0 : 2000);
    }

    //The default settings for all chart types are defines here. You can use them as a base and override them later
    public static setDefaultChartOptions(chartType:any, chartOptions:any, globalAlertService:GlobalAlertService):void
    {
        //Setting for chart-types
        switch (chartType){
            case GoogleChartComponent.TYPE_AREACHART:
                chartOptions["chartArea"] = {left: '8%', top: '10%', width: '87%', height: '75%'};
                chartOptions["pointSize"] = 2;
                chartOptions["bar"] = {groupWidth: "50%"};
                chartOptions["vAxis"] = {
                    viewWindow: {min: 0, max: 100},
                    viewWindowMode: 'explicit',
                    textStyle: {color: this.DEFAULT_LABEL_COLOR},
                    titleTextStyle: {color: this.DEFAULT_LABEL_COLOR}
                };
                chartOptions["colors"] = [AppSettings.COLOR_BLUE, AppSettings.COLOR_GREEN, AppSettings.COLOR_PURPLE]
                //Hide legend en h-axis
                chartOptions['legend'] = {position:'none'}; //'none'; //fix for string to object problem

                chartOptions["hAxis"] = {
                    format: 'HH:mm',
                    gridlines: {color: this.DEFAULT_LABEL_COLOR, count: 4}
                };
                break;
            case GoogleChartComponent.TYPE_PIECHART:
                chartOptions["pieHole"] = 0.4;
                chartOptions["pieSliceText"] = 'percentage';
                chartOptions["chartArea"] = {left: '5%', top: '5%', width: '90%', height: '80%'};
                chartOptions["slices"] = {
                    0: {color: AppSettings.COLOR_YELLOW},
                    1: {color: AppSettings.COLOR_GREEN},
                    2: {color: AppSettings.COLOR_RED},
                    3: {color: AppSettings.COLOR_BLUE},
                    4: {color: AppSettings.COLOR_PURPLE},
                    5: {color: AppSettings.COLOR_GREY},
                    6: {color: AppSettings.COLOR_TURQUUOISE}
                };
                break;
            case GoogleChartComponent.TYPE_LINECHART:
                chartOptions["chartArea"] = {left: '15%', top: '10%', width: '75%', height: '70%'};
                chartOptions["pointSize"] = 4;
                chartOptions["bar"] = {groupWidth: "50%"};
                chartOptions["hAxis"] = {
                    viewWindow: {min: 0},
                    viewWindowMode: 'explicit',
                    textStyle: {color: this.DEFAULT_LABEL_COLOR},
                    titleTextStyle: {color: this.DEFAULT_LABEL_COLOR}
                };
                chartOptions["vAxis"] = {
                    viewWindow: {min: 0},
                    viewWindowMode: 'explicit',
                    textStyle: {color: this.DEFAULT_LABEL_COLOR},
                    titleTextStyle: {color: this.DEFAULT_LABEL_COLOR}
                };
                chartOptions["colors"] = [AppSettings.COLOR_BLUE, AppSettings.COLOR_GREEN, AppSettings.COLOR_PURPLE,
                    AppSettings.COLOR_GREY, AppSettings.COLOR_MAGENTA, AppSettings.COLOR_YELLOW, AppSettings.COLOR_MAGENTA]
                break;
            case GoogleChartComponent.TYPE_COLUMNCHART:
                chartOptions["chartArea"] = {left: '9%', top: '10%', width: '82%', height: '70%'};
                chartOptions["bar"] = {groupWidth: "50%"};
                chartOptions["colors"] = [AppSettings.COLOR_BLUE, AppSettings.COLOR_GREEN, AppSettings.COLOR_YELLOW, AppSettings.COLOR_RED, AppSettings.COLOR_PURPLE, AppSettings.COLOR_GREY, AppSettings.COLOR_TURQUUOISE];
                break;
            case GoogleChartComponent.TYPE_BARCHART:
                chartOptions["chartArea"] = {left: '15%', top: '10%', width: '75%', height: '70%'};
                chartOptions["bar"] = {groupWidth: "50%"};
                chartOptions["colors"] = [AppSettings.COLOR_BLUE];
                break;
            case GoogleChartComponent.TYPE_COMBO_CHART:
                chartOptions["chartArea"] = {left: '9%', top: '10%', width: '80%', height: '70%'}; //Size is set for duo axis chart, not single
                chartOptions["seriesType"] = 'bars';
                chartOptions["pointSize"] = 4;
                chartOptions["vAxes"] =
                    {0: {viewWindowMode:'explicit',
                            viewWindow:{
                                min:0
                            },
                            gridlines: {color: 'transparent'},
                        },
                        1: {gridlines: {color: 'transparent'},
                            format:"#%"}
                    };
                chartOptions["series"] = {0: {targetAxisIndex:1, type:'line'}};
                chartOptions["colors"] = [AppSettings.COLOR_BLUE, AppSettings.COLOR_GREEN, AppSettings.COLOR_YELLOW, AppSettings.COLOR_RED, AppSettings.COLOR_PURPLE, AppSettings.COLOR_GREY, AppSettings.COLOR_TURQUUOISE];
                break;
            case GoogleChartComponent.TYPE_SCATTER:
                chartOptions["chartArea"] = {left: '9%', top: '10%', width: '82%', height: '70%'};
                chartOptions["bar"] = {groupWidth: "50%"};
                chartOptions["colors"] = [AppSettings.COLOR_BLUE, AppSettings.COLOR_GREEN, AppSettings.COLOR_YELLOW, AppSettings.COLOR_RED, AppSettings.COLOR_PURPLE, AppSettings.COLOR_GREY, AppSettings.COLOR_TURQUUOISE];
                break;
            case GoogleChartComponent.TYPE_CANDLESTICKCHART:
                chartOptions["chartArea"] = {left: '9%', top: '10%', width: '82%', height: '70%'};
                chartOptions["bar"] = {groupWidth: "100%"};
                chartOptions["colors"] = [AppSettings.COLOR_BLUE, AppSettings.COLOR_GREEN, AppSettings.COLOR_YELLOW, AppSettings.COLOR_RED, AppSettings.COLOR_PURPLE, AppSettings.COLOR_GREY, AppSettings.COLOR_TURQUUOISE];
                break;
            default:
                globalAlertService.addAlert(GlobalAlertService.ALERT_TITLE_ERROR, "Unknown chart type", "Chart type [" + chartType + "] is onbekend");
        }
    }
}
