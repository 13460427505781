import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {CommonDirectivesModule} from '../../directives/common/common-directives.module';
import {CommonPipesModule} from '../../pipes/common-pipes.module';
import {MapIconSelectorComponent} from './map-icon-selector/map-icon-selector.component';

@NgModule({
  imports: [
    CommonModule,
    CommonDirectivesModule,
    CommonPipesModule
  ],
  declarations: [
    MapIconSelectorComponent
  ],
  exports: [
    MapIconSelectorComponent
  ]
})
export class MapIconSelectorModule { }
