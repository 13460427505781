import {
    Directive
} from '@angular/core';

@Directive({
    selector: '[menuItem]'
})
export class MenuItemDirective {
    constructor() {}
}
