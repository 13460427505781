import {ChangeDetectorRef, Component, AfterViewInit} from '@angular/core';
import {NotificationsService} from './notifications.service';
import {TranslateService} from '../../shared/services/translate/translate.service';
import {NotificationsComponent} from './notifications.component';
import {ActivatedRoute} from "@angular/router";
import {GlobalModel} from "../../shared/services/state/global.model";
import {GlobalAlertService} from "../../wrapper/global-alert/global-alert.service";

@Component ({
    selector: 'notifications-sidebar-component',
    templateUrl: 'notifications-sidebar.component.html',
    providers: [NotificationsService]
})
export class NotificationsSidebarComponent extends NotificationsComponent implements AfterViewInit{
    public showSidebar: boolean = false;

    constructor(public notificationService:NotificationsService, public ts: TranslateService, protected activatedRoute: ActivatedRoute, public model:GlobalModel,private cd:ChangeDetectorRef,public globalAlertService:GlobalAlertService) {
        super(notificationService, ts, activatedRoute, model, globalAlertService);
    }

    ngAfterViewInit() {
        this.cd.detectChanges();
    }

    public toggleSidebar(notificationId:number = null):void{
        this.showSidebar = !this.showSidebar;
        if(notificationId !== null){
            const tmpSub = this.notificationService.changeIsNewState(notificationId).subscribe(()=>{});
            //Use timeout here because firefox would unsubscribe too fast and block the call
            setTimeout(()=>{
                tmpSub.unsubscribe()
            },2000)

            this.model.notificationList.find(_x => {
                return _x.notificationId === notificationId
            }).isNew = false
            this.model.hasNewNotifications = this.model.notificationList.filter(_x => _x.isNew).length > 0;
        }
    }
}
