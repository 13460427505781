/**
 * Created by Christiaan on 07/04/2017.
 */

import {Directive, NgZone, Output, EventEmitter, ElementRef} from '@angular/core';

@Directive({
    selector: '[keydownOutsideAngular]'
})

export class KeydownOutsideAngularDirective
{
    private static readonly EVENT_KEY_DOWN:string = "keydown";

    @Output('keydownOutsideAngular') keydownHandler: EventEmitter<any> = new EventEmitter();

    constructor(private elementRef: ElementRef, private ngZone:NgZone)
    {
        this.ngZone.runOutsideAngular(() => {
            this.elementRef.nativeElement.addEventListener(KeydownOutsideAngularDirective.EVENT_KEY_DOWN, (event:any)=> {
                this.keydownHandler.emit(event);
            })
        });
    }

    //Alternatieve aanpak via hostlistener:
    /*
    @HostListener('window:keydown', ['$event'])

    handleKeyDown(event: Event) {
        this.keydownHandler.emit(event);
    }
    */
}
