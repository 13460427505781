import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'truncate'})
export class TruncatePipe implements PipeTransform {
    public static maxCharacters: number = 25;
    
    transform(string: string, truncate?: boolean, maxChars?: number ): string {
        if (!truncate) {
            return string;
        }
        return string.slice(0, (maxChars ? Math.max(maxChars, 5) : TruncatePipe.maxCharacters) - 4);
    }
}
