<div style="display: flow-root" *ngIf="weekprijzen?.length > 0">
    <div class="d-flex float-right">
        <form-component-label [config]="{label:'weekprijs.total' | translate}">
        </form-component-label>
        <div class="md-form input-group p-0 m-0">
            {{total | currency:'EUR'}}
        </div>
    </div>
</div>
<ng-container *ngFor="let weekprijs of weekprijzen">
    <complex-list-item class="{{weekprijs.isNew?'animated fadeIn':''}}"
                       [style.color]="weekprijs.isDeleted?AppSettings.getColor('$selection-gray'):''"
                       [item]="weekprijs.listItem"
                       [readonly]="disabled()"
                       (onMenuItemClick)="onWeekprijsAction($event, weekprijs)">
        <key-value [key]="'weekprijs.weekprijsAantal' | translate"
                   [value]="weekprijs.weekprijsAantal ? weekprijs.weekprijsAantal : '0'">
        </key-value>
        <key-value [key]="'weekprijs.weekprijsBedrag' | translate"
                   [value]="weekprijs.weekprijsBedrag | currency:'EUR'">
        </key-value>
        <key-value  *ngIf="weekprijs?.weekprijsOmschrijving"
                    [key]="'weekprijs.weekprijsOmschrijving' | translate"
                    [value]="weekprijs.weekprijsOmschrijving">
        </key-value>

        <div class="form-attachment-loader {{weekprijs.isLoading?'':'d-none'}}">
            <div class="progress">
                <div class="indeterminate"></div>
            </div>
        </div>
    </complex-list-item>
</ng-container>
<div class="form-log-container">
    <div *ngIf="!weekprijzen || weekprijzen.length <= 0">
        {{'weekprijs.noweekprijzen' | translate}}
    </div>
</div>
