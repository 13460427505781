<div class="check-activities">
    <progress-bar *ngIf="checkActivities?.length > 0" [items]="getProgressBarItems()"></progress-bar>
    <ng-container *ngFor="let activity of checkActivities">
        <check-activity
            #checkActivityComponent
            [activity]="activity"
            [baseObjectId]="config.base_object_id"
            [isLoading]="isLoading"
            [canDelete]="isDeletable(activity)"
            [canEdit]="isUpdatable(activity)"
            [readonly]="config.disabled || activity.readonly"
            (onDelete)="deleteActivity(activity)"
            (onSave)="saveActivity(activity)"
            (onEdit)="updateActivity(activity)">
        </check-activity>
    </ng-container>
    <div class="form-log-container">
        <div *ngIf="!checkActivities || checkActivities.length <= 0">
            {{'check-activity.noactivities' | translate}}
        </div>
    </div>
</div>
