import {Component, OnInit, Input, ViewChild, ChangeDetectorRef, OnDestroy} from '@angular/core';
import {WidgetService} from "./widget.service";
import {RequestFailure} from "../../../shared/services/http/request-failure";
import {Widget} from "./widget";
import {GlobalAlertService} from "../../../wrapper/global-alert/global-alert.service";
import {AppSettings} from "../../../app.settings";
import {GlobalModel} from "../../../shared/services/state/global.model";
import {TranslateService} from "../../../shared/services/translate/translate.service";

@Component({
    selector: 'static-widget-component',
    template: `
        <div class="d-flex justify-content-center" (globalClickOutsideAngular)="handleClickWindow($event)">
            <div class="col-xxl-6 col-xl-9 col-12 d-flex align-items-center flex-direction-column px-0 no-mobile-padding">
                <div class="col-12 pb-0 px-0 no-mobile-padding">
                    <div class="z-depth-1 static-widget-panel card">
                        <div class="small-widget-title-container yellow-background yellow-shadow">
                            <i class="material-icons md-light md-24 widget-icon">star</i>
                        </div>
                        <div class="pb-0 pt-3 small-widget-title"><h1>{{'Welkom bij Luminizer' | translate}} {{getVersionNumber()}}!</h1></div>
                        <div class="d-flex row static-widget-title pt-2 pb-2 pl-3 pr-2">
                            <div class="pb-0 pt-2 pl-0 text-left col-12 col-md-4 static-widget-info-block">
                                <div class="">
                                    <!--TODO: waardes uit functies halen. Aan de andere kant, de waardes horen uberhaupt nooit leeg te kunnen zijn, dan zijn er wel grotere problemen dan alleen deze tekstjes-->  
                                    <h2>{{'Areaal:' | translate}} <b> {{ model.currentAreaal.getValue().label }}</b></h2>
                                    <p>{{'GEBRUIKER:' | translate}}<b> {{ model.user.getValue().username }}</b></p>
                                    <p>{{'ROL:' | translate}}<b> {{ model.getUserRoleLabels().join(", ") }}</b></p>
                                    <p>{{'AREALEN:' | translate}}<b> {{ getAreasAvailableText(model.arealen.getValue().length) }}</b></p>
                                </div>
                            </div>
                            
                            <div class="col-md-8 col-12 static-widget-map pt-2 pb-2 pr-0 pl-0">
                                <map-core-component [showNoItemsWarning]="false"></map-core-component>
                            </div>
                        </div>
                    </div>

                <div class="pb-3"></div>
                
                <div class="z-depth-1 static-widget-panel card">
                    
                    <div class="small-widget-title-container grey-background black-shadow">
                        <i class="material-icons md-light md-24 widget-icon">navigation</i>
                    </div>
                    
                    <ng-container>
                        <div class="pb-2 pt-3 small-widget-title"><h1>{{'widget.navigate.title' | translate}}</h1></div>
                        <div class="p-3 static-widget-title">
                            {{'widget.navigate.subtext' | translate}}
                        </div>
                        <div class="text-left p-3">
                            <div class="d-flex">
                                <div class="col-6">
                                    <div class="intro-widget-row" >
                                        <p class="d-flex" (click)="handleMouseDownMenuPart($event, '#areaalHighlightId')">
                                            <i class="material-icons">search</i>
                                            <b>{{'1: Areaal' | translate}}</b>
                                        </p>
                                        <p [innerHTML]="'introwidget.area' | translate"> </p>
                                    </div>
                                    <div class="intro-widget-row">
                                        <p class="d-flex" (click)="handleMouseDownMenuPart($event, 'dynamic-menu-item-component li:first')">
                                            <i class="material-icons">search</i>
                                            <b>{{'2: Dashboard' | translate}}</b>
                                        </p>
                                        <p [innerHTML]="'introwidget.dashboard' | translate"> </p>
                                    </div>
                                    <div class="intro-widget-row">
                                        <p class="d-flex" (click)="handleMouseDownMenuPart($event, '#itemsHighlightId')">
                                            <i class="material-icons">search</i>
                                            <b>{{'3: Menuopties' | translate}}</b></p>
                                        <p [innerHTML]="'introwidget.menuoptions' | translate"> </p>
                                    </div>
                                    <div class="intro-widget-row">
                                        <span class="d-block d-xl-none">
                                            <p class="d-flex" (click)="handleMouseDownMenuPart($event, '#searchHighlightIdMobile')">
                                            <i class="material-icons">search</i>
                                            <b>{{'4: Search' | translate}}</b></p>
                                        </span>
                                        <span class="d-none d-xl-block">
                                            <p class="d-flex" (click)="handleMouseDownMenuPart($event, '#searchHighlightId')">
                                            <i class="material-icons">search</i>
                                            <b>{{'4: Search' | translate}}</b></p>
                                        </span>
                                        <p [innerHTML]="'introwidget.search' | translate"> </p>
                                    </div>
                                    <div class="intro-widget-row">
                                        <p class="d-flex" (click)="handleMouseDownMenuPart($event, '#userHighlightId')">
                                            <i class="material-icons">search</i>
                                            <b>{{'5: Support' | translate}}</b></p>
                                        <p [innerHTML]="'introwidget.support' | translate"> </p>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="intro-widget-row">
                                        <p class="d-flex" (click)="handleMouseDownMenuPart($event, '#settingsHighlightId')">
                                            <i class="material-icons">search</i>
                                            <b>{{'6: Instellingen' | translate}}</b></p>
                                        <p [innerHTML]="'introwidget.settings' | translate"> </p>
                                    </div>
                                    <div class="intro-widget-row">
                                        <p class="d-flex" (click)="handleMouseDownMenuPart($event, '#userHighlightId')">
                                            <i class="material-icons">search</i>
                                            <b>{{'7: Gebruiker' | translate}}</b></p>
                                        <p [innerHTML]="'introwidget.user' | translate"> </p>
                                    </div>
                                    <div class="intro-widget-row">
                                        <span class="d-block d-xl-none">
                                            <p class="d-flex" (click)="handleMouseDownMenuPart($event, '#notificationMobileHighlightId', true)">
                                            <i class="material-icons">search</i>
                                            <b>{{'8: Notificaties' | translate}}</b></p>
                                        </span>
                                        <span class="d-none d-xl-block">
                                            <p class="d-flex" (click)="handleMouseDownMenuPart($event, '#notificationHighlightId')">
                                            <i class="material-icons">search</i>
                                            <b>{{'8: Notificaties' | translate}}</b></p>
                                        </span>
                                        <p [innerHTML]="'introwidget.notifications' | translate"> </p>
                                    </div>
                                    <div class="intro-widget-row">
                                        <span class="d-block d-xl-none">
                                            <p class="d-flex" (click)="handleMouseDownMenuPart($event, '#historyMobileHighlightId', true)">
                                            <i class="material-icons">search</i>
                                            <b>{{'9: Geschiedenis' | translate}}</b></p>
                                        </span>
                                        <span class="d-none d-xl-block">
                                            <p class="d-flex" (click)="handleMouseDownMenuPart($event, '#historyHighlightId')">
                                            <i class="material-icons">search</i>
                                            <b>{{'9: Geschiedenis' | translate}}</b></p>
                                        </span>
                                        <p [innerHTML]="'introwidget.history' | translate"> </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
                </div>
            </div>
        </div>
`
})

export class StaticWidgetComponent implements OnInit, OnDestroy
{
    @Input('widget') public widget:Widget;

    private isLoading = true;
    private menuItemHighlighted:boolean = false;

    constructor(private widgetService:WidgetService, private globalAlertService:GlobalAlertService , private cd:ChangeDetectorRef, public model:GlobalModel, private ts:TranslateService) {}

    ngOnInit()
    {
        this.widgetService.getWidgetData(this.widget.id, "",
            (json:any) => {
                this.widget.value = json.value;
                this.widget.description = json.description;

                this.widget.iconClass = "";
                this.widget.iconName = "grade";

                this.cd.markForCheck();
            },
            (failure:RequestFailure) => {}
        );

        this.cd.markForCheck();
    }
    
    public handleClickWindow(e: Event):void{
        this.removeHighlight();
    }

    public handleMouseDownMenuPart(e:Event, highlightId:string, doNotOpenMenu?:boolean):void
    {
        if (!this.menuItemHighlighted){

            //Expand the menu
            if(!doNotOpenMenu){
                let element:any = $('menu-component .collapse');
                element.collapse('show');
            }

            //Highlight the matching element
            $(highlightId).addClass('super-highlight');

            // Scroll to the top of the page, animated
            $('body, html').animate({
                scrollTop: 0
            });

            //By-pass the global click
            setTimeout(() => {
                this.menuItemHighlighted = true;
            });
        }
    }

    private removeHighlight():void
    {
        if (this.menuItemHighlighted){
            $('.super-highlight').removeClass('super-highlight');
            this.menuItemHighlighted = false;
        }
    }

    public getAreasAvailableText(numAreas:number):string
    {
        return this.ts.translate("areasavailable", [numAreas]);
    }

    public getVersionNumber()
    {
        return AppSettings.VERSION_ARRAY[0] + "." + AppSettings.VERSION_ARRAY[1];
    }

    private getMenuImage():string{
        return "assets/img/luminizer_menu_" + this.ts.getLanguage().toLowerCase() + ".png";
    }

    ngOnDestroy() {
        this.removeHighlight();
    }
}
