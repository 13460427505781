import {ChangeDetectorRef, Component, HostBinding, NgZone, OnDestroy, Renderer2} from '@angular/core';
import {ValidationConstraintService} from "../../services/validation-constraint.service";
import {GlobalAlertService} from "../../../../../wrapper/global-alert/global-alert.service";
import {TooltipService} from "../../../../services/tooltip/tooltip.service";
import {FormDataService} from "../../services/form-data.service";
import {Router} from "@angular/router";
import {HTTPService} from "../../../../services/http/http.service";
import {TranslateService} from "../../../../services/translate/translate.service";
import {FormInputComponent} from "../form-input/form-input.component";
import {Subscription} from "rxjs";
import {GlobalModel} from "../../../../services/state/global.model";
import {LoggerService} from "../../../../services/logger/logger.service";

@Component({
    selector: 'auth-action-table',
    template: `
        <label for="{{config.name}}">{{ config.label }}</label>

        <div class="w-100 mx-auto" style="overflow-x: auto;" (globalMousedownOutsideAngular)="handleMouseDown()" (globalMouseupOutsideAngular)="handleMouseUp()">
            <div class="auth-table-component-container" style="padding-right: 5rem;">
                <table  *ngIf="tableData" class="table table-hover auth-table checkboxes" style="width: inherit; min-width:500px">
                    <thead>
                        <tr>
                            <th scope="col" class="auth-table-header"></th>
                            <th  *ngFor="let column of columnData" scope="col" class="auth-table-header">{{column.name}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let module of tableData.modules">
                            <tr>
                                <th (click)="handleClickExpandModule($event, module)" style="vertical-align: middle" class="auth-table-header pl-0 main-row {{hasModuleActions(module)?'cursor-pointer':''}}">
                                    <div *ngIf="!hasModuleActions(module)" class="mr-2" style="display:inline-block;width: 31px"></div>
                                    <i *ngIf="hasModuleActions(module)"  class="material-icons expand-button mr-2 px-2 py-0">{{module.expanded?'expand_more':'keyboard_arrow_right'}}</i>
                                    {{module.label}}
                                </th>
                                <ng-container *ngFor="let role of module.actions[0].roles">
                                    <td class="auth-show-action" style="font-size:12px; vertical-align: middle;" title="{{getTooltip(module.actions[0].name, role.name)}}" (click)="handleClickCheckbox(role)" (mouseoverOutsideAngular)="handleMouseOver(role)">
                                        <label class="checkmark-div {{role.authorized?'div-checked':'div-unchecked'}}">
                                            <div class="custom-control-input check"></div>
                                            <!--                                                <input type="checkbox" class="custom-control-input" checked="{{role.authorized == true?'checked':''}}" (change)="role.authorized = !role.authorized" />-->
                                            <!--                                                <span class="custom-control-indicator"></span>-->
                                        </label>
                                    </td>
                                </ng-container>
                            </tr>
                            
                            <ng-container *ngIf="module.expanded">
                                <ng-container *ngFor="let action of module.actions">
                                    <tr class="{{isShowAction(action)?'hidden':''}}" [title]="action.description">
                                        <th class="auth-table-header tabbed-row" [attr.colspan]="'unset'">{{action.descriptionName}}</th>
                                        <ng-container *ngFor="let role of action.roles">
                                            <td class="{{isModuleShowActive(module, action, role)?'':'inactive'}}" style="font-size:12px; vertical-align: middle;" title="{{getTooltip(action.name, role.name)}}" (click)="handleClickCheckbox(role)" (mouseoverOutsideAngular)="handleMouseOver(role)">
                                                <label class="checkmark-div {{role.authorized?'div-checked':'div-unchecked'}}">
                                                    <div class="custom-control-input check"></div>
    <!--                                                <input type="checkbox" class="custom-control-input" checked="{{role.authorized == true?'checked':''}}" (change)="role.authorized = !role.authorized" />-->
    <!--                                                <span class="custom-control-indicator"></span>-->
                                                </label>
                                            </td>
                                        </ng-container>
                                    </tr>
                                </ng-container>
                            </ng-container>
                            
                        </ng-container>
                    </tbody>
                </table>
            </div>
        </div>
    `
})

export class AuthActionTableComponent extends FormInputComponent implements OnDestroy
{
    @HostBinding('class') hostClasses = 'd-flex flex-column';

    private static readonly MODULE_ACTION_SHOW:string = 'show';

    public config: any;

    private mobileMode:boolean = false;
    private subMobileMode:Subscription = null;
    private isLoading:boolean = false;
    public tableData:any;
    public columnData:any;
    private quickSelectionMode:any = false;

    constructor(public model:GlobalModel, public renderer:Renderer2, public cd:ChangeDetectorRef, public httpService:HTTPService, public validationConstraintService:ValidationConstraintService,
                public tooltipService:TooltipService, public globalAlertService: GlobalAlertService, private formDataService:FormDataService, private router:Router, public ts:TranslateService, private ngZone:NgZone, protected logger:LoggerService)
    {
        super(renderer, validationConstraintService, tooltipService, globalAlertService, ts, logger, model);
    }

    ngOnInit()
    {
        this.subMobileMode = this.model.mobileMode.subscribe((value:boolean) => {
            this.mobileMode = value;
            this.cd.detectChanges();
        });

        if (this.config.attr && this.config.attr.url){
            this.getTableData(this.config.attr.url);
        }else{
            this.globalAlertService.addAlert(this.ts.translate("Formulier incompleet"), this.ts.translate("Geen URL meegegeven"), this.ts.translate("De tabel verwacht een URL, maar die is niet meegegeven"));
        }
    }

    // Search for the show action for this module, if active for the role, or current action is show, or no actions given, return true
    public isModuleShowActive(module:any, action:any, role:any):boolean
    {
        if (module.actions && action.name != AuthActionTableComponent.MODULE_ACTION_SHOW){
            for(let i = 0; i < module.actions.length; i++) {
                if (module.actions[i].name == AuthActionTableComponent.MODULE_ACTION_SHOW){
                    if (module.actions[i].roles){
                        for(let j = 0; j < module.actions[i].roles.length; j++) {
                            if (module.actions[i].roles[j].name == role.name && module.actions[i].roles[j].authorized){
                                // Matching & active role found
                                return true;
                                break;
                            }
                        }
                    }

                    // There is a show, but no matching role
                    return false;
                    break;
                }
            }
        }

        // No actions or the current action is the show-row
        return true;
    }

    // Return true if more than 1 (show) action is present
    public hasModuleActions(module:any):boolean{
        return module.actions && module.actions.length > 1;
    }
    
    public isShowAction(action:any):boolean
    {
        return action.name == AuthActionTableComponent.MODULE_ACTION_SHOW;
    }
    
    public handleClickExpandModule(event:any, module:any):void{
        module.expanded = !module.expanded;
    }
    
    public handleClickCheckbox(role:any)
    {
        role.authorized = !role.authorized;
        this.cd.detectChanges();
    }
    
    public handleMouseDown(){
        if (!this.mobileMode){
            this.quickSelectionMode = true;
        }
    }
    
    public handleMouseUp(){
        if (!this.mobileMode) {
            this.quickSelectionMode = false;
            this.cd.detectChanges();
        }
    }
    
    public handleMouseOver(box:any){
        if (!this.mobileMode && this.quickSelectionMode) {

            // Mechanism to only detect changes when a checkbox value is changed
            let checkBoxValueChanged:boolean = false;
            if (!box.authorized){
                checkBoxValueChanged = true;
            }

            // Check the box
            box.authorized = true;

            // Detect changes if necessary
            if (checkBoxValueChanged){
                this.cd.detectChanges();
            }
        }
    }
    
    public getTooltip(form:string, rol:string):string
    {
        return this.ts.translate('auth.action.tooltip', [form, rol]);
    }

    private getTableData(url:string)
    {
        this.isLoading = true;

        this.formDataService.getTableData(url,
            (json: any) => {

                this.isLoading = false;
                this.tableData = json;

                if (this.tableData && this.tableData.modules){
                    this.columnData = this.tableData.modules[0].actions[0].roles;
                }

                // Move the show-action to the top of the module actions for each module
                for(let i = 0; i < this.tableData.modules.length; i++) {
                    for(let j = 0; j < this.tableData.modules[i].actions.length; j++) {
                        if (this.tableData.modules[i].actions[j].name == AuthActionTableComponent.MODULE_ACTION_SHOW){
                            // cut the show action, take item 0, add to top of array
                            this.tableData.modules[i].actions.unshift(this.tableData.modules[i].actions.splice(j, 1)[0]);
                        }
                    }
                }

                this.group.controls[this.config.name].patchValue(this.tableData);

                this.cd.detectChanges();
            }
        );
    }

    ngOnDestroy() {
        if (this.subMobileMode){
            this.subMobileMode.unsubscribe();
        }
    }
}
