/**
 * Created by Christiaan on 01/03/2017.
 */
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output,} from '@angular/core';

import {GlobalAlertService} from '../global-alert.service';
import {ButtonCode, ButtonInterface, GlobalPopup, PopupActionEvent} from '../global-popup';
import {AbstractBaseFormComponent} from '../../../shared/components/form/containers/form/abstract-base-form.component';
import {TranslateService} from '../../../shared/services/translate/translate.service';
import {TooltipService} from '../../../shared/services/tooltip/tooltip.service';
import {LoggerService} from "../../../shared/services/logger/logger.service";

@Component({
    selector: '',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
    `,
})
export class AbstractBasePopupComponent extends AbstractBaseFormComponent {
    @Input() globalPopup: GlobalPopup = null;
    @Output('onPopupAction') onPopupAction: EventEmitter<PopupActionEvent> = new EventEmitter();

    isLoading: boolean = true;
    protected submitButton: ButtonInterface = null;

    public constructor(protected cd: ChangeDetectorRef, protected globalAlertService: GlobalAlertService,
                       protected elementRef: ElementRef, protected ts: TranslateService, protected tooltipService: TooltipService, protected logger:LoggerService) {
        super(cd, globalAlertService, elementRef, ts, logger);
    }

    // Default handling for closing a popup
    protected doDefaultCloseHandling(e: MouseEvent, showCloseWarning: boolean): void {
        // Close all tooltips. Is needed for form-popups
        this.tooltipService.hideAllTooltips();

        if (showCloseWarning) {
            this.globalAlertService.addPopup(this.ts.translate('popup.close'), this.ts.translate('popup.closewarning'), [
                {
                    label: this.ts.translate('Annuleren'),
                    code: ButtonCode.ANNULEREN,
                    isPrimary: true,
                },
                {
                    label: this.ts.translate('Doorgaan'), code: ButtonCode.OK,
                    callback: () => {
                        this.handlePopupAction(e, this.globalPopup, {
                            callback: this.globalPopup.callbackClose,
                            code: ButtonCode.CLOSE,
                        });
                    }, isPrimary: false,
                },
            ], () => {
            });
        }
        else {
            this.handlePopupAction(e, this.globalPopup, {
                callback: this.globalPopup.callbackClose,
                code: ButtonCode.CLOSE,
            });
        }
    }

    // Try to get the submit button by button role. Return null if none is found
    protected getSubmitButton(): ButtonInterface|null {
        let result: ButtonInterface = null;
        const popupButtons = this.globalPopup.buttonArray;
        if (popupButtons) {
            for (let button of popupButtons) {
                if (button.role && button.role == GlobalAlertService.BUTTON_ROLE_SUBMIT) {
                    result = button;
                    break;
                }
            }
        }

        return result;
    }

    handlePopupAction(event: MouseEvent, alert: GlobalPopup, button: ButtonInterface): void {
        // Overwrite this method
    }
}

