import {
    AfterViewInit,
    ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, ViewChild
} from '@angular/core';

import {GlobalAlertService} from '../global-alert.service';
import {GlobalPopup} from '../global-popup';
import {HTTPError} from '../../../shared/services/http/http-error';
import {RequestFailure} from '../../../shared/services/http/request-failure';
import {FormDataService} from '../../../shared/components/form/services/form-data.service';
import {GlobalModel} from '../../../shared/services/state/global.model';
import {FormComponent} from '../../../shared/components/form/containers/form/form.component';
import {TranslateService} from '../../../shared/services/translate/translate.service';
import {AbstractBasePopupComponent} from './abstract-base-popup.component';
import {TooltipService} from '../../../shared/services/tooltip/tooltip.service';
import { FormEvent } from '../../../shared/components/form/containers/form/form.interface';
import {LumiSelectOption} from '../../../shared/components/commonUI/select/lumi-select/lumi-select.interface';
import {LoggerService} from "../../../shared/services/logger/logger.service";

@Component ({
    'selector': 'global-popup-form-preview-component',
    'changeDetection': ChangeDetectionStrategy.OnPush,
    'template': `
        <default-popup-wrapper-component [componentRef]="this"
                                         [disableButtons]="true"
                                         [globalPopup]="globalPopup"
                                         [showLoader]="isLoading"
                                         [hideButtons]="true"
                                         (onClose)="closePopup($event)">
            <ng-container *ngIf="mapItemBushDropDownData && mapItemBushDropDownData.length > 1">
                <div class="dynamic-form mb-3">
                    <div class="d-flex mt-1 ">
                        <label class="m-0 form-label-part" for="activityDropDown">
                            {{'Selecteer asset:' | translate}}
                        </label>
                        <div class="d-flex w-100">
                            <lumi-select *ngIf="mapItemBushDropDownData?.length > 0"
                                         class="w-100"
                                         [options]="mapItemBushDropDownData"
                                         [selectedOptions]="getSelectedOptions()"
                                         [disabled]="isLoading"
                                         [showOptionFilter]="mapItemBushDropDownData.length > 10"
                                         (onOptionsSelect)="handleChangeMapItemTree($event)">
                            </lumi-select>
                        </div>
                    </div>
                </div>
            </ng-container>
            
            <div class="pr-0">
                <div *ngIf="formData && config">
                    <form-component #form
                            [config]="config"
                            [label]="formData.schema.label"
                            [name]="getFormName()"
                            [validationConstraints]="validationConstraints"
                            [invalidControlsErrors]="invalidControlsErrors"
                            [readOnly]="true"
                            [formIsSubmitted]="formIsSubmitted">
                    </form-component>
                </div>
            </div>
        </default-popup-wrapper-component>
    `
})

export class GlobalPopupFormPreviewComponent extends AbstractBasePopupComponent implements AfterViewInit
{
    @Input() requestUrl: string = '';
    @Input() submitUrl: string = '';

    @ViewChild('form', {'static': false}) form: any;
    // tslint:disable-next-line:max-line-length
    public mapItemBushDropDownData: LumiSelectOption[] = []; // = [{value:'mast', label:this.ts.translate("Mast")}, {value:'schakelkast', label:this.ts.translate('Schakelkast')}];

    private selectedOptionId: number | string;
    private selectedOptionUrl: string;
    // tslint:disable-next-line:max-line-length
    public constructor(protected cd: ChangeDetectorRef,
                       protected globalAlertService: GlobalAlertService,
                       protected formDataService: FormDataService,
                       protected elementRef: ElementRef,
                       private model: GlobalModel,
                       protected ts: TranslateService,
                       protected tooltipService: TooltipService, protected logger:LoggerService) {
        super(cd, globalAlertService, elementRef, ts, tooltipService, logger);
    }

    ngAfterViewInit() {
        this.requestUrl = this.globalPopup.data.requestUrl;
        // Update activity dropdowndata, and trigger view refresh
        if (this.globalPopup?.data?.mapItemBushDropDownData?.length > 0) {
            this.mapItemBushDropDownData = this.globalPopup.data.mapItemBushDropDownData.map(dropdownItem => {
                return {
                    id: dropdownItem.id,
                    name: dropdownItem.label,
                    url: dropdownItem.url
                };
            });
            this.selectedOptionId = this.mapItemBushDropDownData[0].id;
            this.selectedOptionUrl = this.mapItemBushDropDownData[0].url;
        }
        this.cd.detectChanges();

        setTimeout(() => {
            this.requestFormData();
        });
    }

    handlePopupAction(event: MouseEvent, alert: GlobalPopup, button: any): void {
        if (button === this.submitButton){
            this.form.handleComponentEvent({'event': FormEvent.SAVE, 'data': {}});
        }else{
            this.onPopupAction.emit({'event': event, 'alert': alert, 'button': button});
        }
    }

    public closePopup(event: any): void
    {
        this.doDefaultCloseHandling(event, false);
    }

    public requestFormData(): void
    {

        this.formDataService.getFormData(this.selectedOptionUrl,
            (formData: any) => {

                // TODO: pas hier de latlng van de popup.data toe op de formData, als beschikbaar
                // En update dus de kaart en de locatie enzo

                // setTimeout(() => {
                    this.formData = formData;
                    this.isLoading = false;
                    this.cd.detectChanges();
                // });
            },
            () => {
                this.closePopup(null);
            },
            () => {
                this.closePopup(null);
            },
            true
        );
    }

    public handleChangeMapItemTree($event: LumiSelectOption[]): void {
        this.selectedOptionId = $event[0].id;
        this.selectedOptionUrl = $event[0].url;
        this.formData = null;
        this.requestFormData();
    }
    public getSelectedOptions() {
        return this.mapItemBushDropDownData.filter(item => item.id === this.selectedOptionId);
    }
}
