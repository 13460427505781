import {NgModule} from '@angular/core';
import {TableColumnSelectorComponent} from './tableColumnSelector.component';
import {CommonModule} from '@angular/common';
import {CommonPipesModule} from '../../../pipes/common-pipes.module';
import {LumiFormModule} from '../../form/lumi-form.module';
import {BaseTableModule} from '../shared/baseTable/baseTable.module';
import {CommonUIModule} from '../../commonUI/common-ui.module';

@NgModule({
    exports: [TableColumnSelectorComponent],
    imports: [
        CommonModule,
        CommonPipesModule,
        LumiFormModule,
        BaseTableModule,
        CommonUIModule
    ],
    declarations: [
        TableColumnSelectorComponent
    ]
})
export class TableColumnSelectorModule {
    constructor() {
    }
}
