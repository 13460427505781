import {AfterViewInit, Component, ElementRef, Input, OnDestroy, Renderer2} from "@angular/core";
import { Subscription } from 'rxjs';
import {TooltipService} from "../../../services/tooltip/tooltip.service";
import {GlobalModel} from "../../../services/state/global.model";
/**
 * Created by Christiaan on 16/10/2017.
 */
@Component ({
    selector: 'help-icon-component',
    template: `
        <div class="form-label-icon-container">
            <i class="material-icons unselectable-content form-label-icon">help_outline </i>
        </div>
    `})

export class HelpIconComponent implements AfterViewInit, OnDestroy
{
    private static readonly TOOLTIP_CLASS:string = "help-icon-tooltip";

    @Input() set tooltipText(text:string)
    {
        // Change the tooltip when the text changes
        this._toolTipText = text;

        setTimeout( () => {
            this.addToolTip(this.tooltipMode);
        });
    }

    @Input("tooltipMode") tooltipMode:string = TooltipService.TOOLTIP_MODE_CLICK;
    @Input("tooltipPlacement") tooltipPlacement:string = "bottom left";

    private subMobileMode:Subscription;
    private mobileMode: boolean = false;
    private _toolTipText:string = '';

    constructor(private tooltipService:TooltipService, private elementRef:ElementRef, private renderer:Renderer2, private model:GlobalModel){}

    ngAfterViewInit() {
        this.subMobileMode = this.model.mobileMode.subscribe((value:boolean) => {
            //Don't forget to unsubscribe

            this.mobileMode = value;

            //Change the tooltipmode when the mobile mode changes
            this.tooltipMode = this.mobileMode?TooltipService.TOOLTIP_MODE_CLICK:TooltipService.TOOLTIP_MODE_HOVER;
            this.addToolTip(this.tooltipMode);
        });
    }

    private addToolTip(tooltipMode:string)
    {
        this.tooltipService.createToolTip(this.renderer, this.elementRef, this._toolTipText, this.tooltipPlacement, tooltipMode, HelpIconComponent.TOOLTIP_CLASS);
    }

    ngOnDestroy()
    {
        if (this.subMobileMode){
            this.subMobileMode.unsubscribe();
        }

        this.tooltipService.destroyToolTip(this.elementRef);
    }
}
