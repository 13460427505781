/**
 * Created by Christiaan on 01/03/2017.
 */
import {
    AfterViewInit,
    ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, Renderer2, ViewChild
} from '@angular/core';

import {GlobalPopup} from "../global-popup";
import {FormDataService} from "../../../shared/components/form/services/form-data.service";
import {RequestFailure} from "../../../shared/services/http/request-failure";
import {TooltipService} from "../../../shared/services/tooltip/tooltip.service";
import {TranslateService} from "../../../shared/services/translate/translate.service";
import {GlobalAlertService} from '../global-alert.service';
import {AbstractBasePopupComponent} from "./abstract-base-popup.component";
import {HTTPError} from '../../../shared/services/http/http-error';
import {FormEvent} from '../../../shared/components/form/containers/form/form.interface';
import {LoggerService} from "../../../shared/services/logger/logger.service";

@Component ({
    selector: 'global-popup-create-masterdata-component',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <default-popup-wrapper-component [componentRef]="this" [disableButtons]="formDisabled" [globalPopup]="globalPopup" [title]="globalPopup.title" (onClose)="closePopup($event)">
            <div class="my-4"></div>
            <ng-container *ngIf="formData && config">
                <form-component #form
                                [config]="config"
                                [name]="getFormName()"
                                [validationConstraints]="validationConstraints"
                                [invalidControlsErrors]="invalidControlsErrors"
                                [readOnly]="readOnly"
                                [formIsSubmitted]="formIsSubmitted"
                                (onComponentEvent)="handleComponentEvent($event)"></form-component>
            </ng-container>
        </default-popup-wrapper-component>
    `
})

export class GlobalPopupCreateMasterdataComponent extends AbstractBasePopupComponent implements AfterViewInit
{
    @ViewChild('form', {static: false}) form:any;

    @Input() submitUrl:string = "";
    @Input() requestUrl:string = "";

    public formDisabled:boolean = false;
    private entity:string = '';

    constructor(private formDataService:FormDataService, protected cd:ChangeDetectorRef, protected tooltipService:TooltipService, private renderer:Renderer2, protected ts:TranslateService, protected globalAlertService:GlobalAlertService, protected elementRef:ElementRef, protected logger:LoggerService) {
        super(cd, globalAlertService, elementRef, ts, tooltipService, logger);
    }

    ngAfterViewInit()
    {
        this.submitButton = this.getSubmitButton();
        this.submitUrl = this.globalPopup.data.path;

        // Get data type from tree
        this.entity = 'mastType';

        setTimeout(() => {
            this.requestFormData();
        });
    }
    
    public handleComponentEvent(eventData:any):void{
        if (eventData.event == FormEvent.SAVE){
            this.submitFormData(eventData.data.formData);
        }
    }

    private submitFormData(form: any):void
    {
        this.handleSubmitForm();

        this.formDataService.setFormData(form, this.getFormName(), this.submitUrl,
            (formData:any) => {
                //this.formData = formData;
                this.handleSubmitResponse();

                //Hide alert and perform success callback via submit button
                this.onPopupAction.emit({event:null, alert:this.globalPopup, button:this.submitButton, data:formData}); //, data:{lat:form.location.latitude, lng:form.location.longitude}});
            },
            (failure: RequestFailure) => {
                this.logger.log("[GlobalPopupCreateBaseObjectComponent] " + "Handle failure response: ", failure);
                this.handleSubmitResponse(failure.formErrors);
            },
            (error:HTTPError) => {
                this.handleSubmitResponse(null, true);
            }
        );
    }

    private getEntity():string{

        //Safety for element not loaded
        let entity:string = "mastType";

        //Get the value of the dropdown
        if (this.entity){
            entity = this.entity;
        }

        return entity;
    }

    public closePopup(event:any):void
    {
        this.doDefaultCloseHandling(event, false);
    }

    handlePopupAction(event: MouseEvent, alert: GlobalPopup, button: any): void {
        //callback for submit button comes from this form, not from outside this component
        if (button == this.submitButton){
            this.form.handleComponentEvent({event:FormEvent.SAVE, data:{}});
            //this.form.submitForm();
        }else{
            //Hide alert and perform callback of button
            this.onPopupAction.emit({event:event, alert:alert, button:button});
        }
    }

    public requestFormData():void
    {
        this.isLoading = true;

        this.entity = this.globalPopup.data.masterDataType;

        this.requestUrl = 'master-data/new/';

        this.formDataService.getFormData(this.requestUrl + this.getEntity(),
            (formData:any) => {
                this.logger.log("[GlobalPopupCreateDropdownComponent] ", formData);
                //setTimeout(() => {
                this.formData = formData;
                this.isLoading = false;
                this.cd.detectChanges();
                //});
            },
            () => {
                this.closePopup(null);
            },
            () => {
                this.closePopup(null);
            }
        );
    }
}
