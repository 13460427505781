<li class="nav-item dropdown history-container" [ngClass]="isMobile ? 'd-block d-xl-none' : 'd-none d-xl-block'" [attr.id]="isMobile ? 'historyMobileHighlightId' : 'historyHighlightId'">
    <a class="nav-link waves-effect waves-light menu_icon" id="historyDropdownMenu"
       data-toggle="dropdown">
        <i class="material-icons md-light menu-icon">history</i>
    </a>
    <div class="dropdown-menu dropdown-secondary dropdown-menu-right" aria-labelledby="historyDropdownMenu" data-dropdown-in="fadeIn" data-dropdown-out="fadeOut">
        <a *ngFor="let historyItem of model.historyList"
           (click)="clickHistoryItem(historyItem)"
           class="luminizer-main-menu-drop-down-item waves-effect waves-light">{{historyItem.title}}</a>
        <div class="no-results" *ngIf="model.historyList.length === 0">{{'history.noresults' | translate}}</div>
    </div>
</li>