<button type="button"
        #button
        (keyup)="onKeyUp($event)"
        [ngClass]="getClass()"
        [ngStyle]="getStyle()"
        [style.width]="!fullWidth? '-moz-fit-content':''"
        [title]="title ? title: label"
        [disabled]="disabled"
        (click)="emitClick($event)">
    <i *ngIf="icon && icon !== ''"
       [ngClass]="getIconClass()">
        {{icon}}
    </i>
    <span *ngIf="label && label !== ''">
        {{label}}
    </span>
</button>
