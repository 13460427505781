<div class="d-flex mt-1 flex-column w-100 form-timeline" [formGroup]="group">
    <div *ngFor="let child of config.attr.statusList; let i = index" class="d-block w-100 mt-1">
        <timeline
                [config]="child" [bar]="i>=1" [nextDate]="config.attr.statusList[i+1]?.created">
        </timeline>
    </div>
    <div *ngIf="config.attr?.statusList?.length <= 0">
        {{'logs.nologs' | translate}}
    </div>
</div>
