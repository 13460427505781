import {Injectable} from '@angular/core';
import {HttpService2} from '../../../shared/services/http-service-2.0/http2.0.service';
import {Observable} from "rxjs";
import {IBaseObjectName} from './history.interface';
import {LoggerService} from "../../../shared/services/logger/logger.service";

@Injectable() export class HistoryService {
    constructor(protected httpService2: HttpService2, protected logger:LoggerService) {}

    public getBaseObjectName(baseObjectId:string):Observable<IBaseObjectName> {
        this.logger.log('[HistoryService] - Get name for baseObjectId');
        return <Observable<IBaseObjectName>>this.httpService2.doGetRequest(`menu/history/${baseObjectId}`,true);
    }
}
