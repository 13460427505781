<div class="check-activity-cell-child">
    <div class="cacc-icon">
        <activity-status-icon [isDone]="cellChild.isDone"
                              [small]="true"
                              [readonly]="true"
                              [hasAttention]="cellChild.hasAttention">
        </activity-status-icon>
    </div>
    <span >{{cellChild.label}}</span>
</div>
