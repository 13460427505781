import {NgModule} from '@angular/core';
import {BaseTableComponent} from './baseTable.component';
import {CommonModule} from '@angular/common';
import {CommonPipesModule} from '../../../../pipes/common-pipes.module';
import {BaseTableExportService} from './baseTableExport.service';
import { TableCellComponent } from './table-cell/table-cell.component';
import {CheckActivitiesModule} from '../../../check-activities/check-activities.module';
import {BaseTableService} from './baseTableService';
import {CheckActivityCellChildComponent} from './table-cell/check-activity-cell-child/check-activity-cell-child.component';

@NgModule({
    imports: [CommonPipesModule, CommonModule, CheckActivitiesModule],
    exports: [
        BaseTableComponent,
        TableCellComponent
    ],
    declarations: [
        BaseTableComponent,
        TableCellComponent,
        CheckActivityCellChildComponent
    ],
    providers: [
        BaseTableExportService,
        BaseTableService
    ]
})
export class BaseTableModule {
}
