import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TranslateService} from '../../../../services/translate/translate.service';
import {FormDataService} from '../../../form/services/form-data.service';
import {GlobalModel} from '../../../../services/state/global.model';
import {LumiSelectOption} from '../../../commonUI/select/lumi-select/lumi-select.interface';
import {finalize, take} from 'rxjs/operators';
import {BasicHttpGetResult} from '../../../../services/http-service-2.0/http.interface';
import {ListItem, ListItemMenuItemEvent} from '../../../commonUI/list/complex-list-item/complex-list-item.interface';
import {MapServerService} from '../map-server.service';
import {MapServerLayer} from '../map-server.interface';
import {FormMapServerValueInterface} from '../../../form/components/form-map-server/form-map-server.interface';
import {StorageService} from '../../../../services/storage/storage.service';
import {LoggerService} from "../../../../services/logger/logger.service";

@Component({
  selector: 'map-server-settings',
  templateUrl: './map-server-settings.component.html',
})
export class MapServerSettingsComponent implements OnInit {
    @Input() serverSettings: FormMapServerValueInterface;
    @Output() onDelete: EventEmitter<any> = new EventEmitter();
    @Output() onEdit: EventEmitter<any> = new EventEmitter();
    @Output() onSave: EventEmitter<MapServerLayer[]> = new EventEmitter();

    public mapLayers:LumiSelectOption[] = [];
    public selectedMapLayers:LumiSelectOption[] = [];

    public isLoading:boolean = false;
    public isLoadingError:boolean = false;
    public listItem:ListItem = null;
    public errorMessage:string = '';

    public constructor(public ts: TranslateService, public formService:FormDataService, protected model:GlobalModel, private mapServerService:MapServerService, private storage:StorageService, protected logger:LoggerService) {}

    ngOnInit(): void {
        if(this.serverSettings?.url && this.serverSettings.id && this.serverSettings.name){
            this.setMapLayers(this.serverSettings)
            this.listItem = {
                id:this.serverSettings.id,
                title:this.serverSettings.name,
                icon: 'layers',
                menuItems:[
                    {
                        label: this.ts.translate('mapserver.edittitle'),
                        icon: 'edit',
                        action: 'edit'
                    },
                    {
                        label: this.ts.translate('mapserver.deletetitle'),
                        icon: 'delete',
                        action: 'delete'
                    }
                ]
            }
        }
    }

    public handleMenuItemClick(e:ListItemMenuItemEvent): void {
        if (e.action === 'edit') {
            this.handleClickEdit()
        } else if (e.action === 'delete') {
            this.handleClickDelete()
        }
    }

    public handleOptionSelect(e:LumiSelectOption[]): void {
        this.selectedMapLayers = e;
    }

    public setMapLayers(server:FormMapServerValueInterface): void {
        this.isLoading = true
        this.mapServerService.getMapServerLayers(server).pipe(
            take(1)
        ).subscribe(layers => {
            this.setSelectedMapLayers(this.serverSettings)
            this.mapLayers = layers;
        }, (error) => {
            if (error.url) {
                if (this.serverSettings.url?.substr(0, error.url.indexOf('?'))  === error.url?.substr(0, error.url.indexOf('?'))) {
                    this.storage.setValue(`${StorageService.KEY_MAP_SINGLE_WMS_ENABLED}${server.id}`, false);
                    this.isLoading = false;
                    this.isLoadingError = true;
                    this.errorMessage = this.ts.translate('mapserver.errordefault');
                    // Check if already disabled, otherwise disable the server connection
                    if(!this.serverSettings.disabled) {
                        let deleteUrl = 'settings/area/disable-server/' + server.id;
                        this.formService.disableMapServer(deleteUrl).pipe(
                            take(1),
                        ).subscribe((response) => {
                            if(this.model.currentAreaal.getValue().wmsData !== null){
                                const index = this.model.currentAreaal.getValue().wmsData.findIndex(wmsData => wmsData.id === server.id)
                                this.model.currentAreaal.getValue().wmsData[index].disabled = response as boolean;
                            }
                        }, (error) => {
                            this.logger.log('[MapServerSettingsComponent] error disabling map server: ', error);
                        })
                    }
                }
            } else {
                this.logger.log('[MapServerSettingsComponent] error without URL available ', error);
            }
        });
    }

    public setSelectedMapLayers(server:FormMapServerValueInterface): void {
        this.formService.getSelectedMapServerLayers('settings/area/get-server-layers/' + server.id).pipe(
            take(1),
            finalize(() => {this.isLoading = false; this.isLoadingError = false})
        ).subscribe(
            (selectedLayers:BasicHttpGetResult) => {
                this.getSelectedOptions(selectedLayers as (number | string)[])
            },
            (error) => {
                this.logger.log('[MapServerSettingsComponent] ', error);
            }
        )
    }

    public getSelectedOptions(savedMapLayers:(number | string)[]): void {
        let selectedMapLayers = [];
        let mapLayersCopy = [...this.mapLayers];
        if(savedMapLayers !== null) {
            savedMapLayers.forEach((savedLayer) => {
                if (!mapLayersCopy.some(_layer => _layer.id === savedLayer)) {
                    selectedMapLayers.push({id:savedLayer,name:`${savedLayer} (${this.ts.translate('unavailable')})`});
                    this.mapLayers.push({id:savedLayer,name:`${savedLayer} (${this.ts.translate('unavailable')})`});
                } else {
                    selectedMapLayers.push(mapLayersCopy.find(_layer => _layer.id === savedLayer));
                    let index:number = mapLayersCopy.findIndex(_layer => _layer.id === savedLayer);
                    mapLayersCopy.splice(index,1);
                }
            })
        }
        this.selectedMapLayers = selectedMapLayers;
    }

    handleClickSave(): void {
        const layers = this.selectedMapLayers.map(option => <MapServerLayer> option)
        this.onSave.emit(layers)
    }

    handleClickEdit(): void {
        this.onEdit.emit()
    }

    handleClickDelete(): void {
        this.onDelete.emit()
    }
}
