<ng-container [formGroup]="group">
    <div class="w-100 d-flex mt-1">
        <form-component-label [batchUpdateMode]="batchUpdateMode" [config]="config" [included]="included"
                              (onClickInclude)="handleClickInclude($event)"></form-component-label>

        <div class="d-flex w-100" >
            <div class="d-flex flex-column w-100" #currentFormField>
                <map-icon-selector [selectedMapIcon]="selectedOption"
                                   [mapIconOptions]="mapIconOptions"
                                   [readonly]="isControlReadOnly()"
                                   [disabled]="isControlDisabled()"
                                   (onMapIconSelect)="onMapIconSelect($event)">
                </map-icon-selector>
            </div>
        </div>
    </div>
</ng-container>
