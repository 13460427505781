/**
 * Created by Christiaan on 01/03/2017.
 */
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    OnInit,
    Renderer2,
    ViewChild,
} from '@angular/core';

import {GlobalPopup} from '../global-popup';
import {FormDataService} from '../../../shared/components/form/services/form-data.service';
import {TooltipService} from '../../../shared/services/tooltip/tooltip.service';
import {TranslateService} from '../../../shared/services/translate/translate.service';
import {GlobalAlertService} from '../global-alert.service';
import {AbstractBasePopupComponent} from './abstract-base-popup.component';
import {
    FormSelectComponent,
    FormSelectConfig,
    FormSelectOption
} from '../../../shared/components/form/components/form-select/form-select.component';
import {ConfigType} from '../../../shared/components/form/components/field/fieldDirective.interface';
import {LoggerService} from "../../../shared/services/logger/logger.service";

@Component({
    selector: 'global-popup-merge-dropdown-component',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <default-popup-wrapper-component [componentRef]="this" [disableButtons]="formDisabled"
                                         [globalPopup]="globalPopup" [title]="globalPopup.title"
                                         [description]="'masterdata.linkmergeinfo' | translate: [this.valueToReplace.name, this.valueToReplace.attr.dataUsage]"
                                         (onClose)="closePopup($event)">
            <div class="dynamic-form">
                <form-select #dropDown [Config]="dropDownConfig" *ngIf="dropDownConfig"></form-select>
            </div>
        </default-popup-wrapper-component>
    `,
})

export class GlobalPopupMergeDropdownComponent extends AbstractBasePopupComponent implements AfterViewInit, OnInit {
    @ViewChild('dropDownText', {static: false}) dropDownText: any = '';
    @ViewChild('dropDown', {static: false}) dropDown: FormSelectComponent;
    
    formDisabled: boolean = false;
    arrayItems: FormSelectOption[] = [];
    valueToReplace: PopupMergeOptionInterface
    
    public dropDownConfig: FormSelectConfig;
    
    constructor(
        private formDataService: FormDataService,
        protected elementRef: ElementRef,
        protected cd: ChangeDetectorRef,
        protected tooltipService: TooltipService,
        private renderer: Renderer2,
        protected ts: TranslateService,
        protected globalAlertService: GlobalAlertService,
        protected logger:LoggerService
    ) {
        super(cd, globalAlertService, elementRef, ts, tooltipService, logger);
    }
    
    ngOnInit() {
        this.valueToReplace = this.globalPopup.data.valueToReplace
    }
    
    ngAfterViewInit() {
        this.arrayItems = [...this.globalPopup.data.options.map((item: PopupMergeOptionInterface) => {
            return {
                name: `${item.name} | ${item.attr.dataUsage} ${this.ts.translate('masterdata.data-usage-title')}`,
                id: item.id
            }
        })];
        this.arrayItems.splice(this.arrayItems.map(function (item: any) {
            return item.id;
        }).indexOf(this.valueToReplace.id), 1);
        
        this.setDropdownConfig();
        
        this.submitButton = this.getSubmitButton();
        this.cd.detectChanges();
    }
    
    closePopup(event: any): void {
        this.doDefaultCloseHandling(event, false);
    }
    
    handlePopupAction(event: MouseEvent, alert: GlobalPopup, button: any): void {
        this.tooltipService.destroyToolTip(this.dropDownText);
        
        // callback for submit button comes from this form, not from outside this component
        if (button === this.submitButton) {
            this.submitValue();
        } else {
            // Hide alert and perform callback of button
            this.onPopupAction.emit({event: event, alert: alert, button: button});
        }
        
        // this.onPopupAction.emit({event:event, alert:alert, button:button, data:{input:this.dropDownText.nativeElement.value}});
    }
    
    private getOptionById(id: number): any {
        let match: any = null;
        let option: any;
        
        for (let i = 0; i <= this.globalPopup.data.options.length - 1; i++) {
            option = this.globalPopup.data.options[i];
            if (option.id === id) {
                match = option;
                break;
            }
        }
        
        return match;
    }
    
    private submitValue(): void {
        if (!this.formDisabled) {
            let selectedId: number = this.dropDown.getSelectedIdsAsArray().id;
            let valueToKeep: any = this.getOptionById(selectedId);
            
            this.formDisabled = true;
            
            // Execute call
            this.formDataService.mergeDropDown(this.globalPopup.data.path, this.valueToReplace.id, selectedId, (response: any) => {
                this.globalAlertService.addAlertSuccess(
                    this.ts.translate('masterdata.mergetitlesuccess'),
                    this.ts.translate('masterdata.mergesubmitsuccess', [
                        this.valueToReplace.attr.dataUsage,
                        this.valueToReplace.name,
                        valueToKeep.name
                    ]),
                    ''
                );
                
                this.formDisabled = false;
                this.cd.detectChanges();
                
                // Hide alert and perform success callback via submit button
                this.onPopupAction.emit({
                    event: null,
                    alert: this.globalPopup,
                    button: this.submitButton,
                    data: response,
                });
                
            }, () => {
                this.tooltipService.createAndShowTooltip(
                    this.renderer,
                    this.dropDownText,
                    this.ts.translate('Geef een geldige en unieke waarde op'), // JSON.stringify(this.validationConstraints),
                    TooltipService.PLACEMENT_TOP, TooltipService.TOOLTIP_MODE_MANUAL, '', true,
                );
                
                this.formDisabled = false;
                this.cd.detectChanges();
            }, () => {
                this.tooltipService.createAndShowTooltip(
                    this.renderer,
                    this.dropDownText,
                    this.ts.translate('Er ging iets mis met het aanmaken. Item is niet aangemaakt.'),
                    // JSON.stringify(this.validationConstraints),
                    TooltipService.PLACEMENT_TOP, TooltipService.TOOLTIP_MODE_MANUAL, '', true,
                );
                
                this.formDisabled = false;
                this.cd.detectChanges();
            });
        }
    }
    
    private setDropdownConfig() {
        this.dropDownConfig = {
            label: this.ts.translate('masterdata.merge.popup.dropdown.label'),
            disabled: false,
            attr: {
                'batch-update': false,
                nullable: false
            },
            type: ConfigType.select,
            options: this.arrayItems,
            name: 'mergeMasterDataPopup',
            required: false
        };
    }
}

export interface PopupMergeOptionInterface {
    id: string | number
    name: string
    attr?: { dataUsage?: number }
}

