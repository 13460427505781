import {Component} from '@angular/core';
import {AppSettings} from "../../../app.settings";

@Component ({
    selector: 'login-footer',
    templateUrl: 'login-footer.component.html'
})

export class LoginFooterComponent {
    constructor(){}
}
