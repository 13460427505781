import {
    AfterViewInit,
    Component,
    ElementRef,
    HostListener,
    OnDestroy,
    OnInit,
    QueryList,
    Renderer2,
    ViewChild,
    ViewChildren
} from '@angular/core';
import {DataType, RowData, TableData} from '../../../table/shared/baseTable/baseTable.interface';
import {BaseTableComponent} from '../../../table/shared/baseTable/baseTable.component';
import {AbstractControl, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators} from '@angular/forms';
import {TranslateService} from '../../../../services/translate/translate.service';
import {TooltipService} from '../../../../services/tooltip/tooltip.service';
import {BasicMapItem} from './map-item-importer.interface';
import Utils from '../../../../utils/utils';
import {MapCoreComponent} from '../../map-core.component';
import {MapItem} from '../map-item';
import {GlobalAlertService} from '../../../../../wrapper/global-alert/global-alert.service';
import {ButtonCode} from '../../../../../wrapper/global-alert/global-popup';

@Component({
    selector: 'map-item-importer',
    templateUrl: './map-item-importer.component.html'
})
export class MapItemImporterComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('baseTableComponent', {static: false}) baseTableComponent: BaseTableComponent;
    @ViewChild('mapComponent', {static: false}) mapComponent: MapCoreComponent;
    @ViewChild('fileSelector', {static: false}) fileSelector: ElementRef<HTMLElement>;
    @ViewChildren('inputField') inputElements: QueryList<ElementRef<HTMLInputElement>>;
    
    public form: UntypedFormGroup;
    public WGSCoords: boolean = true;
    public coordPattern: RegExp = /^\d+(\.?\d+)+$/;
    public readonly LAT_X_CODE = 'lat/x';
    public readonly LON_Y_CODE = 'lon/y';
    public tableData: TableData = {
        headers: [{
            columnId: 1,
            label: this.translateService.translate('mapItemCreate.input.code'),
            code: 'code',
            type: DataType.STRING,
            isVisible: true,
            bold: false,
            columnRank: 1
        }, {
            columnId: 2,
            label: this.translateService.translate('mapItemCreate.input.lat'),
            code: this.LAT_X_CODE,
            type: DataType.INTEGER,
            isVisible: true,
            bold: false,
            columnRank: 2
        }, {
            columnId: 3,
            label: this.translateService.translate('mapItemCreate.input.lon'),
            code: this.LON_Y_CODE,
            type: DataType.INTEGER,
            isVisible: true,
            bold: false,
            columnRank: 3
        }],
        rows: []
    };
    public isLoading: boolean;
    public isDisabled: any;
    private tooltipElements: ElementRef[] = [];
    private nextUniqueId: number = 1;
    private filesWithSkippedCodes: string[] = [];
    private skippedCodes: string[] = [];
    public showMap: boolean = false;
    
    constructor(
        private renderer: Renderer2,
        private translateService: TranslateService,
        private tooltipService: TooltipService,
        private globalAlertService: GlobalAlertService
    ) {
    }
    
    ngOnInit(): void {
        this.initForm();
    }
    
    ngAfterViewInit(): void {
        this.baseTableComponent.updateTable();
    }
    
    ngOnDestroy(): void {
        this.hideTooltips();
    }
    
    public getNewMapItems(): BasicMapItem[] {
        if (this.tableData.rows.length < 1) {
            return null;
        }
        return this.tableData.rows.map(row => {
            if (this.WGSCoords) {
                return {
                    code: row.cells[0].label,
                    lat: Number(row.cells[1].label),
                    lon: Number(row.cells[2].label)
                };
            } else {
                const WGSArray = Utils.rd2wgs_2(
                    Number(row.cells[1].label),
                    Number(row.cells[2].label)
                );
                return {
                    code: row.cells[0].label,
                    lat: WGSArray[0],
                    lon: WGSArray[1]
                };
            }
        });
    }
    
    public convertToJson(fileList: FileList): void {
        this.filesWithSkippedCodes = [];
        this.skippedCodes = [];
        if (fileList && fileList.length > 0) {
            Array.from(fileList).forEach(file => {
                this.convertFromCSV(file);
            });
        }
    }
    
    public deleteRow(rowData: RowData): void {
        if (this.tableData.rows.some(row => row.uniqueId === rowData.uniqueId)) {
            const indexToDelete = this.tableData.rows.findIndex(row => row.uniqueId === rowData.uniqueId);
            this.tableData.rows.splice(indexToDelete, 1);
        }
        this.baseTableComponent.updateTable();
        this.updateMap();
    }
    
    public addLocation($event: MouseEvent): void {
        $event.preventDefault();
        $event.stopImmediatePropagation();
        
        this.form.updateValueAndValidity();
        if (this.form.valid) {
            this.tableData.rows.push({
                uniqueId: this.nextUniqueId,
                cells: [{
                    label: this.form.controls['code'].value,
                    dataType: DataType.STRING
                }, {
                    label: this.form.controls[this.LAT_X_CODE].value,
                    dataType: DataType.STRING
                }, {
                    label: this.form.controls[this.LON_Y_CODE].value,
                    dataType: DataType.STRING
                }]
            });
            this.nextUniqueId++;
            this.baseTableComponent.updateTable();
            this.updateMap();
            this.initForm();
        } else {
            this.showInputErrors();
        }
    }
    
    public onCoordsChange(): void {
        if (this.tableData.rows.length > 0) {
            this.globalAlertService.addPopup(
                this.translateService.translate('mapItemCreate.coords.warning.title'),
                this.translateService.translate('mapItemCreate.coords.warning.label'),
                [
                    {
                        label: this.translateService.translate('Annuleren'),
                        code: ButtonCode.ANNULEREN,
                        callback: () => {
                            this.WGSCoords = !this.WGSCoords;
                        },
                        isPrimary: false
                    },
                    {
                        label: this.translateService.translate('mapItemCreate.coords.warning.ok'),
                        code: ButtonCode.DELETE,
                        callback: () => this.updateAfterCoordsChange(),
                        isPrimary: true
                    }], () => {}
            );
        } else {
            this.updateAfterCoordsChange();
        }
    }
    
    private updateAfterCoordsChange(): void {
        this.tableData.headers.forEach(header => {
            if (header.code === this.LAT_X_CODE) {
                header.label = this.translateService.translate(`mapItemCreate.input.${this.WGSCoords ? 'lat' : 'x'}`);
            } else if (header.code === this.LON_Y_CODE) {
                header.label = this.translateService.translate(`mapItemCreate.input.${this.WGSCoords ? 'lon' : 'y'}`);
            }
        });
        this.tableData.rows = [];
        this.baseTableComponent.updateTable();
        this.updateMap();
    }
    
    @HostListener('window:click', ['$event'])
    private destroyToolTips(): void {
        this.hideTooltips();
    }
    
    private convertFromCSV(file: File): void {
        const reader: FileReader = new FileReader();
        reader.readAsText(file);
        reader.onload = (e) => {
            const res = reader.result as string; // This variable contains your file as text
            let lines = res.split('\n').slice(1, res.length)
                .map(line => {
                    line = line.replace(/' '/g, '');
                    line = line.replace(/;/g, ',');
                    return line;
                });
            lines = lines.filter(_x => _x.length > 0); //Filter out empty lines to avoid error

            if (lines.some(line => line.split(',').length > 3)) {
                const message = this.translateService.translate('mapItemCreate.upload.error.tooManyColumns', [file.name]);
                this.tooltipElements.push(this.fileSelector);
                this.tooltipService.createAndShowTooltip(
                    this.renderer,
                    this.fileSelector,
                    message
                );
                return;
            }
            if (lines.some(line => line.split(',').length < 3)) {
                const message = this.translateService.translate('mapItemCreate.upload.error.tooFewColumns', [file.name]);
                this.tooltipElements.push(this.fileSelector);
                this.tooltipService.createAndShowTooltip(
                    this.renderer,
                    this.fileSelector,
                    message
                );
                return;
            }
            lines.forEach((line) => {
                line = line.replace(' ', '').replace(';', ',');
                if (this.tableData.rows.some(row => {
                    return row.cells[0].label === line.split(',')[0];
                })) {
                    if (!this.filesWithSkippedCodes.some(fileName => fileName === file.name)) {
                        this.filesWithSkippedCodes.push(file.name);
                    }
                    this.skippedCodes.push(line.split(',')[0]);
                } else {
                    this.tableData.rows.push({
                        uniqueId: this.nextUniqueId,
                        cells: line.split(',').map(value => {
                            return {
                                label: value,
                                dataType: DataType.STRING
                            };
                        })
                    });
                    this.nextUniqueId++;
                }
            });
            this.baseTableComponent.updateTable();
            this.updateMap();
            if (this.filesWithSkippedCodes.length > 0 && this.skippedCodes.length > 0) {
                const message = this.translateService.translate(
                    'mapItemCreate.upload.error.codeExisting',
                    [this.filesWithSkippedCodes, this.skippedCodes]
                );
                this.tooltipElements.push(this.fileSelector);
                this.tooltipService.createAndShowTooltip(
                    this.renderer,
                    this.fileSelector,
                    message
                );
            }
        };
    }
    
    private initForm(): void {
        this.form = new UntypedFormGroup({});
        this.form.addControl('code', new UntypedFormControl('', {
            validators: [Validators.required, this.createCheckExistingValidator()]
        }));
        this.form.addControl(this.LAT_X_CODE, new UntypedFormControl('', Validators.required));
        this.form.addControl(this.LON_Y_CODE, new UntypedFormControl('', Validators.required));
    }
    
    private showInputErrors(): void {
        Object.values(this.form.controls).forEach(formControl => {
            // mark all controls as touched and dirty so that that the invalid visuals are triggered
            formControl.markAllAsTouched();
            formControl.markAsDirty();
            
            // show tooltip error
            if (!formControl.valid) {
                const formControlElement: ElementRef<HTMLInputElement> = this.inputElements.find(item => {
                    return item.nativeElement.getAttribute('name') === this.getControlName(formControl);
                });
                const firstError: string = Object.keys(formControl.errors)[0];
                const message = this.translateService.translate(`mapItemCreate.error.${firstError}`);
                
                this.tooltipElements.push(formControlElement);
                this.tooltipService.createAndShowTooltip(
                    this.renderer,
                    formControlElement,
                    message
                );
            }
        });
    }
    
    private getControlName(control: AbstractControl): string | null {
        const formGroup = control.parent.controls;
        return Object.keys(formGroup).find(name => control === formGroup[name]) || null;
    }
    
    private hideTooltips(): void {
        this.tooltipElements.forEach(element => this.tooltipService.destroyToolTip(element));
        Object.values(this.form.controls).forEach(formControl => {
            if (formControl.value === '') {
                formControl.markAsPristine();
                formControl.markAsUntouched();
            }
        });
    }
    
    private createCheckExistingValidator(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            const isExistingCode = this.tableData.rows.some(row => row.cells[0].label === control.value);
            return isExistingCode ? {existingCode: true} : null;
        };
    }
    
    public setShowMap(showMap: boolean): void {
        this.showMap = showMap;
        this.mapComponent.cd.detectChanges();
    }
    
    private updateMap(): void {
        let mapItems: MapItem[] = (this.getNewMapItems() || []).map((item, index) => {
            return {
                icon: '',
                lat: item.lat,
                lng: item.lon,
                id: `item${index + 1}`,
                label: item.code,
                baseObjects: [{
                    icon: '',
                    id: `bo${index + 1}`,
                    label: item.code,
                    lat: item.lat,
                    lng: item.lon
                }],
            };
        });
        setTimeout(() => {
            this.mapComponent.replaceMarkers(mapItems, this.showMap, true, true);
            this.mapComponent.cd.detectChanges();
        });
    }
}
