<ng-container [formGroup]="group">
    <form-component-label [batchUpdateMode]="batchUpdateMode"
                          [config]="config"
                          [included]="included"
                          (onClickInclude)="handleClickInclude($event)">
    </form-component-label>

    <div class="d-flex w-100 {{'id-' + componentId}}">

        <div class="d-flex flex-column w-100">

            <ng-container *ngIf="batchUpdateMode && !isDisabled()">
                <select #editDropDown class="form-control p-0 m-0 mb-1" style="height: 25px;" (change)="handleChangeEditType($event)">
                    <ng-container *ngFor="let types of editTypes">
                        <option value="{{types.value}}">{{types.label | translate}}</option>
                    </ng-container>
                </select>
            </ng-container>

            <!--TODO: disable all input for editType CLEAR during batchupdate (systeemgroepen batchupdate)-->
            <div class="d-flex flex-row w-100">
                <div class="d-flex flex-column w-100" #currentFormField>
                    <lumi-select #lumiSelectComponent *ngIf="this.config?.options?.length > 0"
                                 [multi]="true"
                                 [singleLineDropdown]="this.singleLineDropdown"
                                 [dropdownSizeLarge]="this.dropdownSizeLarge"
                                 [sortSelection]="this.sortSelection"
                                 [options]="this.config.options"
                                 [selectedOptions]="selectedOptions"
                                 [readonly]="isControlReadOnly()"
                                 [disabled]="isControlDisabled()"
                                 [showOptionFilter]="showOptionFilter()"
                                 (onOptionsSelect)="onOptionsSelect($event)">
                    </lumi-select>
                </div>

                <div class="{{(disabled() == null)?'pl-1': ''}} " *ngIf="config && config.attr && config.attr.creationHref"> <!--'disabled-add-button'-->
                    <div class="card {{(disabled() == null)?'cursor-pointer': 'd-none'}} h-100 justify-content-center" (click)="handleAdd()" [title]="'Maak een nieuw item aan' | translate">
                        <i class="p-2 material-icons " style="font-size: 20px">add</i>
                    </div>
                </div>

            </div>
        </div>
    </div>
</ng-container>
