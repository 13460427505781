import {Injectable, OnInit} from '@angular/core';
import {GlobalModel} from '../state/global.model';
import {AppSettings} from '../../../app.settings';
import {LuminizerRoutes} from '../../interfaces/routes';
import {UserRoles} from '../../interfaces/user.interface';
import {LoggerService} from "../logger/logger.service";

/**
 * Created by Christiaan on 24/03/2017.
 */

@Injectable()
export class AuthorizationService implements OnInit {

    //private fakeConfig = '{"modules": {"areaal": {"authorization":["ROLE_GEMEENTE", "ROLE_DB_ADMIN", "ROLE_AANNEMER"]}, "installaties":{"authorization":["ROLE_ADMIN", "ROLE_GEMEENTE"]}}}';
    //private fakeConfig = '{"modules": {"installaties":{"authorization":["ROLE_ADMIN", "ROLE_GEMEENTE"]}}}';

    //private jsonConfig:any;
    
    constructor(private model: GlobalModel, protected logger:LoggerService) {
        //this.jsonConfig = JSON.parse(this.fakeConfig);
    }

    /*    public loadConfig(json:any){
            //TODO: laad config in en sla hier op
        }

        public getMenuStructure():any{
            //TODO: return de zichtbare items in het menu voor deze user
        }*/

    /*    private getRoleCodesForModule(module:string):string[]{
            //TODO: haal deze uit de config voor de specifieke module waarvan de toegangsrollen worden opgevraagd
            this.logger.log("[AuthorizationService] " + "Getting roles from config for module [" + module + "]");

            //this.logger.log("[AuthorizationService] " + "this.jsonConfig.modules[module].authorization: " + this.jsonConfig.modules[module].authorization);
            return this.jsonConfig.modules[module].authorization

    /!*        if (module == "installaties"){
                return this.jsonConfig.modules.installaties.authorization;
                //return ["admin"];
            }else if (module == "areaal"){
                return ["gemeente"];
            }*!/
        }*/

    public hasModuleAuthorization(configModule: string): boolean {
        if (AppSettings.DISABLE_CLIENTSIDE_AUTHORISATION) {
            return true;
        }

        this.logger.log('[AuthorizationService] ' + 'configModule: ' + configModule);
        //Flash module can always be accessed, the authorisation is handled in the flash-app
        //initial page should be accessible for everyone, or else you will end in a forbidden/redirect-to-initial-page loop
        if (configModule == LuminizerRoutes.INITIAL_PAGE || configModule == LuminizerRoutes.INFORMATION_PAGE || configModule == LuminizerRoutes.USER_PROFILE_PAGE || configModule == LuminizerRoutes.DASHBOARD_PAGE || configModule == LuminizerRoutes.NOTIFICATIONS) {
            return true;
        }

        return this.allowActionForModule('show', configModule);
    }

    private allowActionForModule(action: string, module: string): boolean {
        if (AppSettings.ALLOW_ALL_AUTH_ACTIONS) {
            return true;
        }

        if (!module) {
            return false;
        }

        let allow: boolean = false;
        let auth: any = this.model.authorisations.getValue();
        let actions;

        auth.forEach((authItem: any) => {
            if (authItem.module.toLowerCase() == module.toLowerCase()) {
                actions = authItem.action;
                actions.forEach((moduleAction: string) => {
                    if (moduleAction.toLowerCase() == action.toLowerCase()) {
                        allow = true;
                    }
                });
            }
        });

        //this.logger.log("[AuthorizationService] " + "Allow action [" + action + '] for module [' + module + '] - ' + (allow?'YES':'NO'));

        return allow;
    }

    public setActiveModule(module: string): void {
        this.model.currentModule.next(module);
    }

    public allowRemoveBaseObject(): boolean {
        return this.allowActionForModule('delete_baseobject', this.model.currentModule.value);
    }

    public allowCreateGenericAsset(): boolean {
        return this.allowActionForModule('create_generic_asset', this.model.currentModule.value);
    }

    public allowCreateBaseObject(): boolean {
        return this.allowActionForModule('create_baseobject', this.model.currentModule.value);
    }

    public allowMoveMapItem(): boolean {
        return this.allowActionForModule('move_mapitem', this.model.currentModule.value);
    }

    public allowBatchUpdate(): boolean {
        return this.allowActionForModule('batchupdate', this.model.currentModule.value);
    }

    public allowCreateMapItem(): boolean {
        return this.allowActionForModule('create_mapitem', this.model.currentModule.value);
    }

    public allowCreateGenericDevice(): boolean {
        return this.allowActionForModule('create_generic_device', this.model.currentModule.value);
    }

    public allowCreateTPPLDevice(): boolean {
        return this.allowActionForModule('create_tppl_device', this.model.currentModule.value);
    }
    public allowCreateSammodeDevice(): boolean {
        return this.allowActionForModule('create_sammode_device', this.model.currentModule.value);
    }

    public allowCopyBaseObject(): boolean {
        return this.allowActionForModule('copy_mapitem', this.model.currentModule.value);
    }

    public allowCreateException(): boolean {
        return this.allowActionForModule('create_exception', this.model.currentModule.value);
    }

    public allowEditDimmingScheme(): boolean {
        return this.allowActionForModule('edit_dimmingscheme', this.model.currentModule.value);
    }

    public allowSetDimmingScheme(): boolean {
        return this.allowActionForModule('set_dimmingscheme', this.model.currentModule.value);
    }

    public allowSetOverride(): boolean {
        return this.allowActionForModule('set_override', this.model.currentModule.value);
    }

    public allowDeleteDimGroup(): boolean {
        return this.allowActionForModule('delete_dimgroup', this.model.currentModule.value);
    }

    public allowShowArchived(): boolean {
        return this.allowActionForModule('show_archived', this.model.currentModule.value);
    }

    public allowShowUncommissionedCount(): boolean {
        return this.allowActionForModule('show_uncommissioned_count', this.model.currentModule.value);
    }

    public allowHideOfflineCount(): boolean {
        return this.allowActionForModule('hide_offline_count', this.model.currentModule.value);
    }

    public allowHideTree(): boolean {
        return this.allowActionForModule('hide_tree', this.model.currentModule.value);
    }

    public allowHideForm(): boolean {
        return this.allowActionForModule('hide_form', this.model.currentModule.value);
    }

    public allowAutoRefresh(): boolean {
        return this.allowActionForModule('auto_refresh', this.model.currentModule.value);
    }

    public enableAutoRefreshByDefault(): boolean {
        return this.allowActionForModule('auto_refresh_default_on', this.model.currentModule.value);
    }

    public allowDeleteTimeRegistration(): boolean {
        return this.allowActionForModule('delete_time_registration', this.model.currentModule.value);
    }

    public enableAutoSelectTreeRoot(): boolean {
        return this.allowActionForModule('auto_select_tree_root', this.model.currentModule.value);
    }

    public enableOSMAsDefault(): boolean {
        return this.allowActionForModule('osm_as_default', this.model.currentModule.value);
    }

    //To enable the map as default. Will still be overwritten by cookie settings
    public forceMapAsDefault(): boolean {
        return this.allowActionForModule('force_map_as_default', this.model.currentModule.value);
    }

    public allowCreateUser(): boolean {
        return this.allowActionForModule('create_user', this.model.currentModule.value);
    }

    public allowInviteUser(): boolean {
        return this.allowActionForModule('invite_user', this.model.currentModule.value);
    }

    /*    public allowEditMasterData():boolean{
            return this.allowActionForModule("edit_masterdata", this.model.currentModule.value);
        }*/

    //Returns true when the user has Service Engineer as one of it's roles
    public allowShowDebug(): boolean {
        let userRoleCodes: string[] = this.model.getUserRoleCodes();
        for (let userRole of userRoleCodes) {
            //TODO:Get from config, not hard coded
            if (userRole == UserRoles.ENGINEER) {
                return true;
            }
        }
        return false;
    }

    ngOnInit(): any {
    }
}
