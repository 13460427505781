/**
 * Created by Christiaan on 01/03/2017.
 */
import {
    AfterViewInit,
    ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, ViewChild
} from '@angular/core';

import {ButtonCode, GlobalPopup} from '../global-popup';
import {ZXingScannerComponent} from "@zxing/ngx-scanner";
import {GlobalAlertService} from "../global-alert.service";
import {TranslateService} from "../../../shared/services/translate/translate.service";
import {TooltipService} from "../../../shared/services/tooltip/tooltip.service";
import {DefaultPopupWrapperComponent} from "./default-popup-wrapper/default-popup-wrapper.component";
import {AbstractBasePopupComponent} from './abstract-base-popup.component';
import {LoggerService} from "../../../shared/services/logger/logger.service";

@Component ({
    selector: 'global-popup-scanner-component',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <default-popup-wrapper-component #wrapper [componentRef]="this" [disableButtonsOverride]="disableButtonsOverride" [globalPopup]="globalPopup" [title]="globalPopup.title" [infoText]="ts.translate('scanner.description')" (onClose)="closePopup($event)">
            <div class="pr-2">
                <label *ngIf="isCameraUnavailable()" class="pr-2 mb-2">{{'scanner.nocamera' | translate}}</label>
                <div [hidden]="isCameraUnavailable()">
                    <!-- For testing barcodes. See response for format !-->
                    <!--<zxing-scanner #scanner class="test-class" [formats]="ALL_FORMATS" start="true" (scanComplete)="handleScanComplete($event)"></zxing-scanner>
                    -->
                    <zxing-scanner #scanner class="test-class" [formats]="ALL_FORMATS" [autostart]="true" (scanComplete)="handleScanComplete($event)"></zxing-scanner>
                    <div *ngIf="scanResult" class="scanner-scanresult">{{ scanResult }}</div>
                </div>
            </div>
        </default-popup-wrapper-component>
    `
})

export class GlobalPopupScannerComponent extends AbstractBasePopupComponent implements AfterViewInit
{
    @ViewChild('scanner', {static: false}) scanner: ZXingScannerComponent;
    @ViewChild('wrapper', {static: false}) wrapper: DefaultPopupWrapperComponent;

    private static  ALL_FORMATS:any[] = ['AZTEC', 'CODABAR', 'CODE_39', 'CODE_93', 'CODE_128', 'DATA_MATRIX', 'EAN_8', 'EAN_13', 'ITF', 'MAXICODE', 'PDF_417', 'QR_CODE', 'RSS_14', 'RSS_EXPANDED', 'UPC_A', 'UPC_E', 'UPC_EAN_EXTENSION'];

    private hasPermission: boolean = false;
    public scanResult: string = '';
    private availableDevices: MediaDeviceInfo[] = [];
    public disableButtonsOverride:any = (button:any) => {
        return ((button.code == ButtonCode.OK && !this.hasScanResult()) || (button.code == ButtonCode.SWAP_CAMERA && !this.hasMultipleCameras()))};

    private lastDeviceId:string = "";

    constructor(protected elementRef:ElementRef, protected cd:ChangeDetectorRef, protected globalAlertService:GlobalAlertService, public ts:TranslateService, protected tooltipService:TooltipService, protected logger:LoggerService) {
        super(cd, globalAlertService, elementRef, ts, tooltipService, logger);
    }

    ngAfterViewInit()
    {
        this.scanner.camerasFound.subscribe((devices: MediaDeviceInfo[]) => {
            this.logger.log("[GlobalPopupScannerComponent] " + "cameras found: ", devices);
            this.availableDevices = devices;

            // NOTE: als je ooit nog wilt checken of iets de front of de back camera is, dan wordt dat meestal toch gewoon met het label gechecked.
            //  Dus echt gewoon of 'back' || 'rear' ect er in voor komt. ala: const matcher = ({ label }) => /back|trás|rear|traseira|environment|ambiente/gi.test(label);
            //  Er zit geen property op de MediaDeviceInfo verder waaraan je dat kan afleiden
            //  Je zou hoogstens nog zelf een camera kunnen starten, via facingMode = environment kunnen instellen dat je de backcam wilt, dat id ophalen en het id vergelijken met de camera's die je hier binnen krijgt
            //  OF de gebruiker zelf altijd tussen al zn cameras kunnen laten kiezen.

            this.cd.detectChanges();
            this.wrapper.runChangeDetection();
        });

        this.scanner.permissionResponse.subscribe((value: boolean) => {
            this.logger.log("[GlobalPopupScannerComponent] " + "set permission: ", value);
            this.hasPermission = value;
            this.cd.detectChanges();
        });
    }

    private hasMultipleCameras():boolean
    {
        this.logger.log("[GlobalPopupScannerComponent] " + "multiple camera's: ", this.availableDevices);
        return this.availableDevices && this.availableDevices.length > 1;
    }
    
    public isCameraUnavailable():boolean
    {
        return !this.availableDevices || this.availableDevices.length < 0 || !this.hasPermission;
    }

    private hasScanResult():boolean
    {
        return this.scanResult != '';
    }
    
    public handleScanComplete(event:any):void{

        if (event){
            this.logger.log("[GlobalPopupScannerComponent] " + "scan complete event: ", event);

            let barcodeFormat:string = 'UNKNOWN';
            if (event.format >= 0 && event.format < GlobalPopupScannerComponent.ALL_FORMATS.length){
                barcodeFormat = GlobalPopupScannerComponent.ALL_FORMATS[event.format];
            }

            this.logger.log("[GlobalPopupScannerComponent] " + "barcodeFormat: " + barcodeFormat);

            /*
            For debug purposes:
            this.logger.log("[GlobalPopupScannerComponent] " + "text: " + event.text);
            this.logger.log("[GlobalPopupScannerComponent] " + "raw: " , event.rawBytes);
            this.logger.log("[GlobalPopupScannerComponent] " + "raw: " + event.rawBytes);*/

            this.scanResult = event.text;
            this.wrapper.runChangeDetection();
        }
    }

    /*private handleScanSuccess(resultString: string):void
    {
        this.logger.log("[GlobalPopupScannerComponent] " + "Scan result: ", resultString);
        this.scanResult = resultString;
    }*/

    public closePopup(event:any):void
    {
        this.doDefaultCloseHandling(event, false);
    }

    handlePopupAction(event: MouseEvent, alert: GlobalPopup, button: any): void {
        if (button.code == ButtonCode.OK) {
            this.onPopupAction.emit({event: event, alert: alert, button: button, data: {scanResult:this.scanResult}});
        }else if (button.code == ButtonCode.SWAP_CAMERA)
        {
            this.logger.log("[GlobalPopupScannerComponent] " + "Try swap camera");
            // At least 2 devices
            if (this.scanner && this.availableDevices && this.availableDevices.length >= 2){

                // NOTE: the lastdeviceid is a fix for a bug (in the scanner) that causes the scanner.device to be empty after switching to a new camera
                //  So the last device id is stored manually

                let currentDeviceId:string = this.scanner.device?this.scanner.device.deviceId:this.lastDeviceId;
                let nextDeviceIndex:number = -1;

                // Find the current camera
                for (let i = 0; i < this.availableDevices.length; i++) {
                    if (this.availableDevices[i].deviceId == currentDeviceId){
                        // Get the next index
                        nextDeviceIndex = (i == this.availableDevices.length -1?0:i+1);
                    }
                }

                // Activate next camera
                if (nextDeviceIndex != -1){
                    this.logger.log("[GlobalPopupScannerComponent] " + "Swapping to next camera: ", this.availableDevices[nextDeviceIndex]);
                    this.lastDeviceId = this.availableDevices[nextDeviceIndex].deviceId;
                    this.scanner.device = this.availableDevices[nextDeviceIndex];
                }
            }
        }else {
            //Hide alert and perform callback of button
            this.onPopupAction.emit({event:event, alert:alert, button:button});
        }
    }

    get ALL_FORMATS():any[]{
        return GlobalPopupScannerComponent.ALL_FORMATS;
    }

    get PLACEMENT_BOTTOM(): string {
        return TooltipService.PLACEMENT_BOTTOM;
    }
}
