/**
 * Created by Christiaan on 16/03/2017.
 */

import {Injectable} from '@angular/core';
import {GlobalModel} from '../../../services/state/global.model';
import {TreeNodeLMX} from './tree-node-lmx';
import {AppSettings} from '../../../../app.settings';
import {HTTPService} from '../../../services/http/http.service';
import {GlobalAlertService} from '../../../../wrapper/global-alert/global-alert.service';
import {RequestFailure} from '../../../services/http/request-failure';
import {HTTPError} from '../../../services/http/http-error';
import {TreeLMX} from './tree-lmx';
import {StorageService} from '../../../services/storage/storage.service';
import {BehaviorSubject, Subscription, timer} from 'rxjs';
import Utils from '../../../utils/utils';
import {LoggerService} from "../../../services/logger/logger.service";

@Injectable()
export class TreeService {

    public static readonly GET_TREE_PATH: string = 'tree/get'; // "tree/locality/segment";
    public static readonly TREE_SEARCH_PATH: string = 'tree/find-reference';
    private static readonly MULTI_SELECT_DELAY: number = 500; // ms

    // For shift selection of tree
    private secondToLastClickedNode: TreeNodeLMX;
    private lastClickedNode: TreeNodeLMX;

    private subHandleNodeSelect: Subscription = new Subscription();
    private indexCounter: number = 0;
    private nodeSelectTimer: any;

    constructor(private httpService: HTTPService, private model: GlobalModel, private globalAlertService: GlobalAlertService, private storageService: StorageService, protected logger:LoggerService) {
        this.nodeSelectTimer = timer(TreeService.MULTI_SELECT_DELAY, 0);
    }

    public executeTreeNodeURL(url: string, successCallBack: () => any): void {
        this.httpService.doGetRequest(url, (json: any) => {

            successCallBack();

            // TODO: Fail and error are unhandled right now, but no handling is needed for now
        }, () => {
        }, () => {
        }, false, true)
    }

    public getBaseObjectPositionInTree(baseobjectId: number, successCallBack?: (results: any) => any, failCallBack?: (failure: RequestFailure) => any) {
        let path: string = TreeService.TREE_SEARCH_PATH + '/' + baseobjectId;

        this.httpService.doGetRequest(path,
            (json: any) => {

                // Let component know request is send
                successCallBack(json);
            }, (failure: RequestFailure) => {

                // The username is not valid or other failure
                failCallBack(failure);
            },
            (error: HTTPError) => {
            },
            true);
    }

    // References is an array of objects each with a different tree code. This functions matches the current used tree, by tree code, with one of the references. That references get expanded
    public expandTreeForReferences(tree: TreeLMX, references: any[], doSelectNodes: boolean, doExpandNodes: boolean, scrollToSelectedNodes: boolean, checkForCode: boolean): any[] {

        // There are references for each tree-type, try to match current treecode on one of the reference-sets
        let matchingReferences: any[] = [];

        // When no references are given, just return zero matching references
        if (!references) {
            return matchingReferences;
        }

        if (checkForCode) {
            for (let i: number = 0; i < references.length; i++) {
                if (references[i].code == tree.code) {
                    // Match found, this is the correct reference for this tree type
                    matchingReferences.push(references[i]);
                    break;
                }
            }
        } else {
            matchingReferences = references;
        }

        // When selecting nodes, handle the root here
        if (doSelectNodes) {
            for (let i: number = 0; i < matchingReferences.length; i++) {
                if (matchingReferences[i] == undefined) {
                    // root node found, select it and break the loop
                    tree.treeNodes.selected = true;
                    break;
                }
            }
        }

        if (matchingReferences && matchingReferences.length > 0) {
            matchingReferences.forEach((reference: any) => {
                if (reference) {
                    delete reference.code;
                    this.logger.log('[TreeService] ' + 'Trying to expand tree: reference found for the tree currently in use');
                    this.logger.log('[TreeService] ' + 'Trying to expand tree for reference: ', reference);
                    if (!this.expandAndSelectTreeNodesForReference(tree.treeNodes, reference, doSelectNodes, doExpandNodes)) {
                        this.logger.log('[TreeService] ' + 'No matching node found for this reference');
                    } else if (scrollToSelectedNodes) {
                        // Wait for the DOM to have updated, then scroll to selected elements
                        setTimeout(() => {
                            let selectedNodes = $('.tree-node-component-selected');
                            if (selectedNodes.length > 0) {
                                // NOTE: https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollIntoView
                                // Use nearest to not-move at all when the item is already visible in the view
                                // selectedNodes[0].scrollIntoView({behavior: "smooth", block: "nearest", inline: "nearest"});
                                selectedNodes[0].scrollIntoView({behavior: 'smooth', block: 'center', inline: 'nearest'});
                            }
                        }, 250)
                    }
                } else {
                    this.logger.log('[TreeService] ' + 'Warning: Undefined reference given. Are all reference objects in the tree node data filled?');
                }
            });
        } else {
            this.logger.log('[TreeService] ' + 'Trying to expand tree for reference, but no match is found between possible references and the tree currently in use');
            this.logger.log('[TreeService] ' + 'Current tree code: ' + tree.code + ' Possible references: ', references);
        }

        // Return the found reference so the selectionchanged event can get triggered
        // Only works for 1 selection for now
        return matchingReferences; // [matchingReference];
    }

    // Recursively deselect children and grandchildren
    private expandAndSelectTreeNodesForReference(node: TreeNodeLMX, reference: any, doSelectNodes: boolean, doExpandNodes: boolean, matchFound: boolean = false): boolean {
        if (!node) {
            // When no tree is given, return nomatch directly
            this.logger.log('[TreeService] ' + 'No valid node given: ', node);
            return false;
        }

        for (let i: number = 0; i < node.children.length; i++) {

            let child: any = node.children[i];

            if (child.reference) {

                // TODO: dit is geen ideale manier van vergelijken. De volgorde speelt nu een rol
                // TODO: je kan zoiets doen, maar alleen als het echt nodig is, dit performt vast niet fantastisch: https://stackoverflow.com/questions/16167581/sort-object-properties-and-json-stringify

                if (JSON.stringify(child.reference) == JSON.stringify(reference)) {

                    this.logger.log('[TreeService] ' + 'Matching reference found, expanding node: ', child);

                    // When the passed nodes need to be selected
                    if (doSelectNodes) {
                        child.selected = true;
                    }

                    // When the passed nodes need to be expanded
                    if (doExpandNodes) {

                        // When doSelectNodes is on, only expand the (grand)parents of the selected item, not the item itself
                        // You can't ever collapse a single node through the UI, so it will remain marked as expanded forever
                        if (!doSelectNodes) {
                            child.expanded = true;
                        }

                        // Expand all folders above
                        this.expandGrandParents(child);
                    }

                    // Match found, exit this loop
                    matchFound = true;
                    break;
                }
            }

            // If no match found, try to look for it in the children
            if (!matchFound && child.children.length > 0) {

                // Recursive lookup
                matchFound = this.expandAndSelectTreeNodesForReference(child, reference, doSelectNodes, doExpandNodes, matchFound);

                // A match is found in the children, exit this loop
                if (matchFound) {
                    break;
                }
            }
        }

        // Pass match result to parent of recursive function
        return matchFound;
    }

    // Recursively expand parents and grandparents
    private expandGrandParents(node: TreeNodeLMX) {
        if (node.parent) {
            node.parent.expanded = true;
            this.expandGrandParents(node.parent);
        }
    }

    public getTreeNodes(treeId: number, module: string, counts: string[], invalidateCache: boolean = false, successCallBack: (tree: TreeLMX) => any, failCallBack?: (failure: RequestFailure) => any): void {
        let postValues: any = {treeSettings: {'module': module, 'counts': counts}};
        let path: string = TreeService.GET_TREE_PATH + '/' + treeId;
        if (invalidateCache) {
            path += '?invalidateCache=true';
        }

        this.httpService.doPostRequest(
            path,
            postValues,
            (json: any, url: string) => {

                if (json) {

                    this.logger.log(Utils.measureFunctionTime('[TreeService] Tree nodes build in ', () => {
                        let tempTree: TreeLMX = json;

                        if (AppSettings.AUTO_EXPAND_ROOT_NODE) {
                            tempTree.treeNodes.expanded = true;
                            this.expandSingleChildren(tempTree.treeNodes);
                        }

                        this.indexCounter = 0;
                        tempTree.treeNodes.level = 0;
                        this.mapChildrenToParentNode(tempTree.treeNodes);

                        successCallBack(tempTree);
                    }));

                } else {
                    this.globalAlertService.addAlertEmptyResponse(url);
                }

            }, (failure: RequestFailure) => {

                if (failCallBack) {
                    failCallBack(failure)
                } else {
                    this.logger.log('[TreeService] ' + 'Call failed, but no callback function');
                }

            }, (error: HTTPError) => {
                // Error is handled in httpService
            }
        );
    }

    // Expand children as long as they are single childs
    private expandSingleChildren(parentNode: TreeNodeLMX) {
        if (parentNode.children && parentNode.children.length == 1) {
            let singleChildNode: TreeNodeLMX = parentNode.children[0];
            singleChildNode.expanded = true;
            this.expandSingleChildren(singleChildNode);
        }
    }

    // Check if trees belong to the module. Remove everything that doesn't
    public filterTreesForModule(module: string, _currentTrees: any[]) {
        // Remove trees if needed. This may leave an empty array
        for (let i = 0; i < _currentTrees.length; i++) {

            // Each tree is part of one or more modules, check if the current module exists in the tree. If not, remove tree
            if (_currentTrees[i] && _currentTrees[i].module.indexOf(module) == -1) {
                _currentTrees.splice(i, 1);
                i--;
            }
        }
    }

    // Get the id of the last used tree, or take the first one available
    private getLastUsedTree(module: string): number {
        let result: number = -1; // Area without trees, like Stedin, or an area with only a segment tree that gets filtered out

        if (this.model.currentTrees.value) {

            // Copy the tree array. It will be filtered, so keep the original
            let _currentTrees: any[] = this.model.currentTrees.value.slice();

            this.filterTreesForModule(module, _currentTrees);

            // Array can contain a null element, check for it
            if (_currentTrees && _currentTrees[0]) {

                // If no match later on, the id of the first tree will be returned
                result = _currentTrees[0].id;

                // Check if present in storage
                this.storageService.getNumberValue(StorageService.KEY_SELECTED_TREE, (value: number) => {
                    // Check for match with dashboard items
                    _currentTrees.forEach((tree: any) => {
                        if (tree.id == value) {
                            result = value;
                        }
                    });

                });

                return result;
            }
        }

        return result;
    }

    public loadInitialTreeNodes(tree: BehaviorSubject<TreeLMX>, module: string, counts: string[], successCallBack?: () => any, failCallBack?: () => any): void {
        // Als je deze uitzet wordt de tree opnieuw binnen gehaald, maar dan ben je ook je vorige geselecteerde items kwijt. Als er toch gerefreshed moet worden dan moeten we daar even iets voor maken
        if (!tree.value) {
            this.logger.log('[TreeService] ' + 'There is no current tree, search for a last used tree and load it');
            // Create a fake current tree for now so there is an id to map to the 'selected tree tab bar'
            let treeId: number = this.getLastUsedTree(module);

            if (treeId != -1) {

                // Create a temp tree, with only a tree id
                let newTree: TreeLMX = new TreeLMX();
                newTree.id = treeId;
                tree.next(newTree);

                this.logger.log('[TreeService] ' + 'Loading tree with id: ' + newTree.id);

                // Request the treenodes from the server
                this.getTreeNodes(newTree.id, module, counts, false, (newTree: TreeLMX) => {
                    tree.next(newTree);
                    successCallBack();
                }, failCallBack);
            } else {
                this.logger.log('[TreeService] ' + '-------------------------------------');
                this.logger.log('[TreeService] ' + 'WARING: No trees present in this area');
                this.logger.log('[TreeService] ' + '-------------------------------------');
                // this.globalAlertService.addAlert(this.ts.translate("Error"), this.ts.translate("Geen trees beschikbaar"), this.ts.translate("Voor dit areaal zijn geen trees beschikbaar. Zonder trees werkt deze module niet naar behoren."));
                tree.next(null);
                failCallBack();
            }
        }
    }

    public handleClickNode(callbackFunction: Function): void {
        // Stop running timers
        this.subHandleNodeSelect.unsubscribe();

        // Start a new timer
        this.subHandleNodeSelect = this.nodeSelectTimer.subscribe((t: any) => {

            // Stop this timer
            this.subHandleNodeSelect.unsubscribe();

            // Perform action
            callbackFunction();
        });
    }

    // Connect parents and children/level/indexes in the model, for quick reference
    private mapChildrenToParentNode(parentNode: TreeNodeLMX) {
        let nextLevel: number = (parentNode.level + 1);

        parentNode.children.forEach((node) => {

            node.parent = parentNode;
            node.level = nextLevel;
            node.index = ++this.indexCounter;

            if (node.children && node.children.length > 0) {
                this.mapChildrenToParentNode(node);
            } else {
                node.children = [];
            }
        });
    }

    // Recursively deselect children and grandchildren
    public deselectGrandChildren(node: TreeNodeLMX) {
        node.children.forEach((child) => {
            child.selected = false;
            if (child.children.length > 0) {
                this.deselectGrandChildren(child)
            }
        });
    }

    // Recursively deselect parents and grandparents
    public deselectGrandParents(node: TreeNodeLMX) {
        if (node.parent) {
            node.parent.selected = false;
            this.deselectGrandParents(node.parent);
        }
    }

    // Bruteforce deselect all nodes
    public deselectAllNodes(tree: BehaviorSubject<TreeLMX>) {
        let tempTree: TreeLMX = tree.value;

        if (tempTree && tempTree.treeNodes) {
            tempTree.treeNodes.selected = false;
            this.deselectGrandChildren(tempTree.treeNodes);
            tree.next(tempTree);
        }
    }

    public storeNodeClick(node: TreeNodeLMX): void {
        this.secondToLastClickedNode = this.lastClickedNode;
        this.lastClickedNode = node;
    }

    public handleShiftClick(tree: BehaviorSubject<TreeLMX>) {

        let visibleTreeNodes: TreeNodeLMX[] = this.getAllVisibleNodes(tree);

        let startNode: number = Math.min(this.lastClickedNode.index, this.secondToLastClickedNode.index);
        let endNode: number = Math.max(this.lastClickedNode.index, this.secondToLastClickedNode.index);

        visibleTreeNodes.forEach((node) => {
            if (node.index >= startNode && node.index <= endNode) {
                node.selected = true;
                this.deselectGrandParents(node);
            }
        });
    }

    private getAllVisibleNodes(tree: BehaviorSubject<TreeLMX>): TreeNodeLMX[] {
        let results: TreeNodeLMX[] = [];
        results.push(tree.value.treeNodes);
        results.concat(this.getVisibleNodes(tree.value.treeNodes, results));

        return results;
    }

    private getVisibleNodes(node: TreeNodeLMX, results: TreeNodeLMX[]): TreeNodeLMX[] {
        if (node.children.length > 0 && node.expanded) {

            node.children.forEach((child) => {
                results.push(child);
                results.concat(this.getVisibleNodes(child, results));
            });
        }

        return results;
    }

    public getAllSelectedNodes(tree: BehaviorSubject<TreeLMX>): TreeNodeLMX[] {
        let results: TreeNodeLMX[] = [];

        results.concat(this.getSelectedNodes(tree.value.treeNodes, results));

        return results;
    }

    private getSelectedNodes(node: TreeNodeLMX, results: TreeNodeLMX[]): TreeNodeLMX[] {
        if (node.selected) {
            results.push(node);
        }

        if (node.children.length > 0) {
            node.children.forEach((child) => {
                results.concat(this.getSelectedNodes(child, results));
            });
        }

        return results;
    }

    public getAllExpandedNodes(tree: BehaviorSubject<TreeLMX>): TreeNodeLMX[] {
        let results: TreeNodeLMX[] = [];

        results.concat(this.getExpandedNodes(tree.value.treeNodes, results));

        return results;
    }

    private getExpandedNodes(node: TreeNodeLMX, results: TreeNodeLMX[]): TreeNodeLMX[] {
        if (node.expanded) {
            results.push(node);
        }

        if (node.children.length > 0) {
            node.children.forEach((child) => {
                results.concat(this.getExpandedNodes(child, results));
            });
        }

        return results;
    }
}
