<div class="complex-list-item"
     *ngIf="item"
     [style.border-top-left-radius]="item?.icon? '0.9rem':''">
    <div class="cli-header" [style.background-color]="fancy? '#b6e2b6':AppSettings.getColor('$color_light_grey')">
        <div class="cli-icon">
            <i [style]="iconColor? 'color: '+ iconColor + '!important;':''">{{item.icon}}</i>
        </div>
        <div class="cli-title" [title]="item.title" [style.font-size]="smallTitle? '0.8rem':'0.9rem'">
            <span>{{item.title}}</span>
        </div>
        <div class="cli-subtitle" [title]="item.subtitle" *ngIf="item.subtitle">
            <span class="w-100"><i>{{item.subtitle}}</i></span>
        </div>
        <div class="cli-buttons" *ngIf="item.menuItems?.length > 0 && !readonly">
            <menu-dropdown *ngIf="item.menuItems?.length > 1"
                           [menuItems]="item.menuItems"
                           [menuTitle]="item.menuTitle"
                           [buttonSizeSmall]="true"
                           (onMenuItemClick)="handleMenuItemClick($event)">
            </menu-dropdown>
            <div *ngIf="item.menuItems?.length === 1">
                <ng-container *ngFor="let menuItem of item.menuItems">
                    <lumi-button class="cli-button"
                                 [icon]="menuItem.icon"
                                 [size]="'medium'"
                                 [rank]="'input'"
                                 [title]="menuItem.label"
                                 (onClick)="handleMenuItemClick({$event: $event, item: menuItem})">
                    </lumi-button>
                </ng-container>
            </div>
        </div>
    </div>
    <div class="cli-content">
        <div class="float-right pr-2" style="font-size: x-small;position: absolute;right: 0;top: 2.6rem;">
            <i>{{item.creator}}</i>
            <span *ngIf="item.creator && item.created"> - </span>
            <i>{{item.created}}</i>
        </div>
        <div [style.margin-top]="(item.creator || item.created)? '1rem':''">
            <ng-content></ng-content>
        </div>
    </div>
</div>
