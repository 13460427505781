import {MapCoreV2Component} from "../map-core-V2.component";
import {drawingStates, IMapItemLayers, IParallelHeading, ISnappablePoint, mapItemTypes, IMapItemStyles} from "./map-manager.interface";
import {LoggerService} from "../../../services/logger/logger.service";


export default class MapDrawingManagerService {
    public snappablePoints: ISnappablePoint[] = [];
    public parallelHeadings: IParallelHeading[] = [];
    public highlightPoint: google.maps.Circle;
    public snappedPolylines:IParallelHeading[] = []
    public selectedLayerId: number = null
    public selectedStyleId: number = null

    constructor(private mapCoreV2: MapCoreV2Component, protected logger:LoggerService) {
        this.highlightPoint = new google.maps.Circle({
            fillColor: "#000000",
            fillOpacity: 1,
            radius: this.mapCoreV2.mapSettings.DEFAULT_SNAP_HIGHLIGHT_POINT_SIZE,
            clickable: false
        })
    }

    public initDrawingManager():void{
        this.logger.log('[Grid][MapDrawingManagerService] ' + 'Initialize drawing manager')
        this.mapCoreV2.mapHelperManagerService.setSnapPointRadiusByZoom()
    }

    public handleDrawClick(event): void {
        let lat;
        let lng;
        if (typeof event.latLng !== "undefined") {
            lat = event.latLng.lat()
            lng = event.latLng.lng()
        } else {
            lat = event.lat()
            lng = event.lng()
        }

        this.logger.log('[Grid][MapDrawingManagerService] ' + 'Draw click with active type: '+this.mapCoreV2.activeDrawingType+' and draw state: '+this.mapCoreV2.drawState)
        if (this.mapCoreV2.activeDrawingType === mapItemTypes.POLYLINE || this.mapCoreV2.activeDrawingType === mapItemTypes.ANNOTATION) {
            if (this.mapCoreV2.drawState === drawingStates.DRAW_NEW || this.mapCoreV2.drawState === drawingStates.DRAW_NEW_HEAD_SNAP || this.mapCoreV2.drawState === drawingStates.DRAW_EDIT) {
                //Set the head on the map
                this.mapCoreV2.polylineDrawingHead.setMap(this.mapCoreV2.map)

                //No drawn line yet so set first point on mouse position
                if(this.mapCoreV2.drawState !== drawingStates.DRAW_NEW_HEAD_SNAP){
                    this.mapCoreV2.polylineDrawingHead.setPath([{lat: lat, lng: lng}])
                }

                //Set new state so mouse move can take over
                if (this.mapCoreV2.drawState === drawingStates.DRAW_NEW || this.mapCoreV2.drawState === drawingStates.DRAW_NEW_HEAD_SNAP) {
                    this.mapCoreV2.mapHelperManagerService.setState(drawingStates.DRAW_NEW_HEAD)
                } else if (this.mapCoreV2.drawState === drawingStates.DRAW_EDIT) {
                    this.mapCoreV2.mapHelperManagerService.setState(drawingStates.DRAW_EDIT_HEAD)
                }
            } else if (this.mapCoreV2.drawState === drawingStates.DRAW_NEW_HEAD) { //Drawing a new line
                //Enable save button
                this.mapCoreV2.mapUIManagerService.toggleSaveButtonEnabled(true)
                //Enable cancel button
                this.mapCoreV2.mapUIManagerService.toggleCancelButtonEnabled(true)
                if (this.mapCoreV2.polylineDrawingTail === null) { //New polyline
                    this.logger.log('[Grid][MapDrawingManagerService] ' + 'Create new polyline tail')
                    this.mapCoreV2.polylineDrawingTail = new google.maps.Polyline({
                        path: [
                            this.mapCoreV2.polylineDrawingHead.getPath().getArray()[0],
                            this.mapCoreV2.polylineDrawingHead.getPath().getArray()[1]
                        ],
                        strokeWeight: this.mapCoreV2.polylineWeight,
                        strokeColor: this.mapCoreV2.polylineColor,
                        map: this.mapCoreV2.map,
                        editable: true,
                        clickable: false,
                        zIndex: this.mapCoreV2.mapSettings.ZINDEX_POLYLINE_DRAW_TAIL
                    });
                    //Double click event
                    this.mapCoreV2.mapEventManagerService.createGMListener('dblclick', this.mapCoreV2.polylineDrawingTail, (event) => {
                        if (this.mapCoreV2.mapHelperManagerService.isInDrawingMode()) {
                            this.logger.log('[Grid][MapDrawingManagerService] ' + 'Double click, finish new line')
                            this.closeDrawingMode(true);
                        }
                    })
                } else { //Existing polyline
                    this.logger.log('[Grid][MapDrawingManagerService] ' + 'Has existing polyline tail')
                    this.mapCoreV2.polylineDrawingTail.getPath().getArray().push(this.mapCoreV2.polylineDrawingHead.getPath().getArray()[1])
                    this.mapCoreV2.polylineDrawingTail.setPath(this.mapCoreV2.polylineDrawingTail.getPath().getArray());
                }
            } else if (this.mapCoreV2.drawState === drawingStates.DRAW_EDIT_HEAD) { //Editing an existing line
                if (this.mapCoreV2.mapItemEditPathVertexPoint === 0) { //Beginning of line
                    this.logger.log('[Grid][MapDrawingManagerService] ' + 'Edit beginning of polyline')
                    this.mapCoreV2.selectedMapItem.googlePolyline.getPath().getArray().unshift(this.mapCoreV2.polylineDrawingHead.getPath().getArray()[1])
                } else { //End of line
                    this.logger.log('[Grid][MapDrawingManagerService] ' + 'Edit end of polyline')
                    this.mapCoreV2.selectedMapItem.googlePolyline.getPath().getArray().push(this.mapCoreV2.polylineDrawingHead.getPath().getArray()[1])
                }
                this.mapCoreV2.polylineDrawingHead.setPath([{lat: lat, lng: lng}])
                this.mapCoreV2.polylineDrawingHead.setOptions({clickable:false})

                //Double click event
                this.mapCoreV2.mapEventManagerService.createGMListener('dblclick', this.mapCoreV2.selectedMapItem.googlePolyline, (event) => {
                    if (this.mapCoreV2.mapItemEditPath === 'add') {
                        this.logger.log('[Grid][MapDrawingManagerService] ' + 'Double click, finish edit line')
                        this.handleFinishEditMapItem(true);
                    }
                })
            }
        } else if (this.mapCoreV2.activeDrawingType === mapItemTypes.MARKER) {
            if (this.mapCoreV2.drawState === drawingStates.DRAW_NEW) {
                //Adding a new marker
                this.mapCoreV2.mapLayerManagerService.drawingLayer = {
                    mapItem: this.mapCoreV2.markerDrawing,
                    mapItemType: this.mapCoreV2.activeDrawingType
                }
                //Enable save button
                this.mapCoreV2.mapUIManagerService.toggleSaveButtonEnabled(true)
                //Enable cancel button
                this.mapCoreV2.mapUIManagerService.toggleCancelButtonEnabled(true)
                this.mapCoreV2.mapHelperManagerService.setState(drawingStates.DRAW_FINISH);
            }
        } else if (this.mapCoreV2.activeDrawingType === mapItemTypes.JOINT) {
            if (this.mapCoreV2.drawState === drawingStates.DRAW_NEW) {
                //Adding a new joint if it is snapped on a line
                if(this.mapCoreV2.jointDrawing.getMap() !== null){
                    this.mapCoreV2.mapLayerManagerService.drawingLayer = {
                        mapItem: this.mapCoreV2.jointDrawing,
                        mapItemType: this.mapCoreV2.activeDrawingType
                    }
                    //Enable save button
                    this.mapCoreV2.mapUIManagerService.toggleSaveButtonEnabled(true)
                    //Enable cancel button
                    this.mapCoreV2.mapUIManagerService.toggleCancelButtonEnabled(true)
                    this.mapCoreV2.mapHelperManagerService.setState(drawingStates.DRAW_FINISH);
                }
            }
        }
    }

    public handleDrawMousemove(event): void {
        let startingPosition = null;
        let mouseLatLng;
        if (typeof event.latLng !== "undefined") {
            mouseLatLng = event.latLng;
        } else {
            mouseLatLng = event;
        }

        //If selectedMapItem is not null then an existing line is edited, set the edit line as tail
        if(this.mapCoreV2.selectedMapItem !== null){
            this.mapCoreV2.polylineDrawingTail = this.mapCoreV2.selectedMapItem.googlePolyline
        }
        if (this.mapCoreV2.polylineDrawingTail === null) {
            //No tail exists, check if there is a head to get the starting position from, otherwise no starting position
            if (this.mapCoreV2.polylineDrawingHead.getPath().getArray().length > 0) {
                startingPosition = this.mapCoreV2.polylineDrawingHead.getPath().getArray()[0];
            } else {
                startingPosition = null;
            }
        } else if (typeof this.mapCoreV2.polylineDrawingTail.getPath().getArray().at(-1) !== 'undefined') {
            //There is a tail, get the starting position
            startingPosition = this.mapCoreV2.polylineDrawingTail.getPath().getArray().at(-1);
        } else {
            startingPosition = null;
        }

        //Set highlight line when there is a starting point to mouse
        if (startingPosition !== null) {
            this.mapCoreV2.polylineDrawingHead.setPath([startingPosition, mouseLatLng]);
            this.mapCoreV2.polylineDrawingHead.setOptions({
                editable: false
            });
        }

        //Fixed length
        if (startingPosition !== null && this.mapCoreV2.polylineDrawingHeadFixedLength) {
            this.logger.log('[Grid][MapDrawingManagerService] ' + 'Draw polyline with fixed length: '+this.mapCoreV2.polylineDrawingHeadFixedLength)
            const fixedLengthTargetLatLng = this.mapCoreV2.mapHelperManagerService.latLngPositionForFixedLength(startingPosition, google.maps.geometry.spherical.computeHeading(startingPosition, mouseLatLng))
            this.mapCoreV2.polylineDrawingHead.setPath([startingPosition, fixedLengthTargetLatLng]);
            return;
        }

        //Parallel headings
        if (startingPosition !== null && this.mapCoreV2.map.getZoom() >= this.mapCoreV2.mapSettings.MINIMUM_ZOOM_LEVEL_TO_TRIGGER_GRID_FUNCTIONS) {
            let closestHeading = null;
            if(this.mapCoreV2.map.getZoom() >= this.mapCoreV2.mapSettings.MINIMUM_ZOOM_LEVEL_TO_TRIGGER_GRID_FUNCTIONS){
                closestHeading = this.mapCoreV2.mapHelperManagerService.getPointOnLineSegment(mouseLatLng);
            }

            // const closestHeading = this.mapCoreV2.mapHelperManagerService.getSnappableHeading(startingPosition, mouseLatLng);
            if (closestHeading !== null) {
                this.logger.log('[Grid][MapDrawingManagerService] ' + 'Draw polyline parallel highlight active')
                this.mapCoreV2.mapHelperManagerService.highlightPolyline(true, closestHeading.mapItem.googlePolyline.getPath(), closestHeading.mapItem.googlePolyline.get('strokeWeight'), closestHeading.mapItem.googlePolyline.get('zIndex'))

                const distStartToMouse =  google.maps.geometry.spherical.computeDistanceBetween(closestHeading.startLatLng, mouseLatLng);
                const polylineEndpoint = google.maps.geometry.spherical.computeOffset(closestHeading.startLatLng, distStartToMouse, closestHeading.heading)
                this.mapCoreV2.polylineDrawingHead.setPath([
                    startingPosition,
                    polylineEndpoint
                ]);
            } else {
                this.logger.log('[Grid][MapDrawingManagerService] ' + 'Draw polyline parallel highlight disabled')
                this.mapCoreV2.mapHelperManagerService.highlightPolyline(false)
            }
        }

        //Snappable points
        if(this.mapCoreV2.activeDrawingType !== mapItemTypes.MARKER && this.mapCoreV2.activeDrawingType !== mapItemTypes.JOINT && this.mapCoreV2.map.getZoom() >= this.mapCoreV2.mapSettings.MINIMUM_ZOOM_LEVEL_TO_TRIGGER_GRID_FUNCTIONS){
            const snappablePoint = this.mapCoreV2.mapHelperManagerService.getSnappablePoint(mouseLatLng);
            if (snappablePoint != null) {
                this.logger.log('[Grid][MapDrawingManagerService] ' + 'Draw polyline snappable highlight active')
                if (startingPosition !== null && this.mapCoreV2.drawState !== drawingStates.DRAW_NEW_HEAD_SNAP) {
                    //Found snap point and started drawing
                    this.mapCoreV2.polylineDrawingHead.setPath([startingPosition, snappablePoint.latLng]);
                } else {
                    //Found snap point but has not started drawing yet
                    this.mapCoreV2.polylineDrawingHead.setPath([snappablePoint.latLng]);
                    this.mapCoreV2.mapHelperManagerService.setState(drawingStates.DRAW_NEW_HEAD_SNAP)
                }
                //Show a highlight on the snap point
                this.mapCoreV2.mapHelperManagerService.showHighlightPoint(snappablePoint.latLng);
            } else {
                //No snappable point, remove highlight, set path to mouse again
                this.logger.log('[Grid][MapDrawingManagerService] ' + 'Draw polyline snappable highlight disabled')
                this.mapCoreV2.mapHelperManagerService.hideHighlightPoint();
            }
        }

        //Marker or joint
        if ((this.mapCoreV2.activeDrawingType === mapItemTypes.MARKER || this.mapCoreV2.activeDrawingType === mapItemTypes.JOINT) && this.mapCoreV2.drawState === drawingStates.DRAW_NEW) {
            if(this.mapCoreV2.activeDrawingType === mapItemTypes.MARKER) {
                //If marker, set current mouse position so icon will follow
                this.mapCoreV2.markerDrawing.setPosition(mouseLatLng)
                this.mapCoreV2.markerDrawing.setMap(this.mapCoreV2.map)
            } else if(this.mapCoreV2.activeDrawingType === mapItemTypes.JOINT){
                //If joint, see if snapping on line
                let lineSegment = null;
                if(this.mapCoreV2.map.getZoom() >= this.mapCoreV2.mapSettings.MINIMUM_ZOOM_LEVEL_TO_TRIGGER_GRID_FUNCTIONS){
                    lineSegment = this.mapCoreV2.mapHelperManagerService.getPointOnLineSegment(mouseLatLng);
                }
                if (lineSegment != null) {
                    //Has found a snapping point on the line, set position to it and highlight the point
                    this.logger.log('[Grid][MapDrawingManagerService] ' + 'Draw joint snap to polyline active')
                    const distStartToMouse = google.maps.geometry.spherical.computeDistanceBetween(lineSegment.startLatLng, mouseLatLng);
                    const highlightLocation = google.maps.geometry.spherical.computeOffset(lineSegment.startLatLng, distStartToMouse, lineSegment.heading);
                    this.mapCoreV2.jointDrawing.setPosition(highlightLocation)
                    this.mapCoreV2.mapHelperManagerService.showHighlightPoint(highlightLocation);
                    this.mapCoreV2.jointDrawing.setMap(this.mapCoreV2.map)
                    this.mapCoreV2.jointDisabledDrawing.setMap(null)
                } else {
                    //Did not find snapping point, remove highlight and follow mouse
                    this.logger.log('[Grid][MapDrawingManagerService] ' + 'Draw joint snap to polyline disabled')
                    this.mapCoreV2.jointDisabledDrawing.setPosition(mouseLatLng)
                    this.mapCoreV2.mapHelperManagerService.hideHighlightPoint();
                    this.mapCoreV2.jointDisabledDrawing.setMap(this.mapCoreV2.map)
                    this.mapCoreV2.jointDrawing.setMap(null)
                }

            }
            //Set the current lat lng info in the UI
            this.mapCoreV2.mapUIManagerService.setLatLngInfo(mouseLatLng)
        }
        //Update polyline length in UI
        this.mapCoreV2.mapHelperManagerService.updateCurrentPolylineLength();
    }

    public revertLastAction():void{
        //When drawing or editing a line, using backspace will remove the previous line segment
        this.logger.log('[Grid][MapDrawingManagerService] ' + 'Remove last polyline point')
        const polyline = this.mapCoreV2.polylineDrawingTail === null ? this.mapCoreV2.selectedMapItem.googlePolyline : this.mapCoreV2.polylineDrawingTail;
        let headStart = null
        let newPath = null
        if(polyline.getPath().getArray().length > 1){
            if(this.mapCoreV2.mapItemEditPathVertexPoint === 0){ //Remove first point
                headStart = polyline.getPath().getArray().at(1); //Get second point in tail
                //Slice last point from array
                newPath = polyline.getPath().getArray().slice(1)
            } else { //Remove last point or null
                headStart = polyline.getPath().getArray().at(-2); //Get second last point in tail
                //Slice last point from array
                newPath = polyline.getPath().getArray().slice(0, -1)
            }

            if(headStart !== null && newPath !== null){
                //Get second point (the mouse) from head
                const headEnd = (this.mapCoreV2.polylineDrawingHead as google.maps.Polyline).getPath().getArray().at(1);
                //Set path for polyline head to prevent flicker
                this.mapCoreV2.polylineDrawingHead.setPath([headStart,headEnd])
                //Set new path for polyline tail
                polyline.setPath(newPath)
            }
        } else {
            //Removed last point, close drawing mode
            this.closeDrawingMode(false, true)
        }
    }

    public removePointFromPolyline(event): void {
        if (this.mapCoreV2.selectedMapItem !== null) {
            if (typeof event.vertex === 'number') {
                //Only allow point removal if there are more than 2 points in the path
                if (this.mapCoreV2.selectedMapItem.googlePolyline.getPath().getLength() > 2) {
                    this.logger.log('[Grid][MapDrawingManagerService] ' + 'Remove selected point from polyline')
                    let mapItemPathArray = this.mapCoreV2.selectedMapItem.googlePolyline.getPath().getArray();
                    mapItemPathArray.splice(event.vertex, 1)
                    this.mapCoreV2.selectedMapItem.googlePolyline.setPath(mapItemPathArray)
                }
                this.mapCoreV2.mapHelperManagerService.handleSnapAndParallelPoints();
            }
        }
    }

    public addPointToPolyline(event): void {
        if (this.mapCoreV2.selectedMapItem !== null) {
            if (typeof event.vertex === 'number') {
                //Check if clicked point is the first or last item
                if (event.vertex === 0 || event.vertex === this.mapCoreV2.selectedMapItem.googlePolyline.getPath().getLength() - 1) {
                    this.logger.log('[Grid][MapDrawingManagerService] ' + 'Add point to polyline')
                    this.mapCoreV2.mapItemEditPathVertexPoint = event.vertex;
                    this.handleDrawClick(event)
                }
            }
        }
    }

    public deleteEditMapItem() {
        this.logger.log('[Grid][MapDrawingManagerService] ' + 'Delete edit item')
        this.mapCoreV2.mapUIManagerService.toggleEditOptionsToolbar(false)
    }

    public handleFinishEditMapItem(saveData:boolean){
        if(!saveData){
            //Dont want to save data, revert changes
            this.logger.log('[Grid][MapDrawingManagerService] ' + 'Finish edit item, do not save and revert changes')
            this.mapCoreV2.selectedMapItem.revertEditChanges();
        } else {
            //Save changes
            this.logger.log('[Grid][MapDrawingManagerService] ' + 'Finish edit item, save changes')
            this.mapCoreV2.mapDataManagerService.saveDrawing(true)
        }
        this.handleFinishEditDrawing();
    }

    public handleFinishEditDrawing(): void {
        this.mapCoreV2.polylineDrawingHead.setPath([])
        this.mapCoreV2.polylineDrawingHead.setMap(null);
        this.mapCoreV2.mapItemEditPath = null;
        this.mapCoreV2.mapItemEditPathVertexPoint = null;
        this.mapCoreV2.mapUIManagerService.removeActiveClass('function_addpoint')
        this.mapCoreV2.mapUIManagerService.updatePolylineLength(0);
        this.mapCoreV2.mapHelperManagerService.setState(drawingStates.DRAW_FINISH);
        this.mapCoreV2.mapHelperManagerService.setDrawingType(null)
        this.mapCoreV2.mapHelperManagerService.clearSnappedPolylines()
        this.mapCoreV2.mapHelperManagerService.hideHighlightPoint();
        this.mapCoreV2.mapUIManagerService.updatePolylineLength(0);
        this.mapCoreV2.selectedMapItem.setEditable(false)
        this.mapCoreV2.selectedMapItem.highlight(true)
        this.mapCoreV2.mapUIManagerService.closeMapItemEditSelectionMode()
    }

    public closeEditMode():void{
        if (this.mapCoreV2.selectedMapItem.hasEditItemChanged()) {
            this.mapCoreV2.mapEventManagerService.showPopupWarning(
                'cancelChangesMapItemEdit',
                this.mapCoreV2.ts.translate('grid.closeDrawingWarning.title'),
                this.mapCoreV2.ts.translate('grid.closeDrawingWarning.label'),
                this.mapCoreV2.ts.translate('grid.closeDrawingWarning.cancelBtn'),
                this.mapCoreV2.ts.translate('grid.closeDrawingWarning.confirmBtn')
            );
        } else {
            this.handleFinishEditMapItem(false);
        }
    }

    public closeDrawingMode(saveDrawing: boolean, ignoreWarning: boolean = false, popupAction:string = 'closeDrawingMode', drawingType:mapItemTypes = null): void {
        if (this.mapCoreV2.mapLayerManagerService.drawingLayer !== null || this.mapCoreV2.polylineDrawingTail !== null) { //Has made changes
            if (!saveDrawing) { //Has made changes but tries to cancel
                if (ignoreWarning) { //Has agreed to lose drawing in popup.
                    this.logger.log('[Grid][MapDrawingManagerService] ' + 'Close drawing mode, do not save and lose changes')
                    this.resetDrawing();
                } else { //Show warning popup that changes will be lose, are u sure?
                    this.logger.log('[Grid][MapDrawingManagerService] ' + 'Close drawing mode but made changes, show warning')
                    this.mapCoreV2.mapEventManagerService.showPopupWarning(
                        popupAction,
                        this.mapCoreV2.ts.translate('grid.closeDrawingWarning.title'),
                        this.mapCoreV2.ts.translate('grid.closeDrawingWarning.label'),
                        this.mapCoreV2.ts.translate('grid.closeDrawingWarning.cancelBtn'),
                        this.mapCoreV2.ts.translate('grid.closeDrawingWarning.confirmBtn'),
                        drawingType
                    );
                }
            } else { //Has made changes and wants to save drawing
                this.logger.log('[Grid][MapDrawingManagerService] ' + 'Close drawing mode, save changes made')
                if (this.mapCoreV2.activeDrawingType === mapItemTypes.POLYLINE || this.mapCoreV2.activeDrawingType === mapItemTypes.ANNOTATION) {
                    //Set polyline tail to drawing layer
                    this.mapCoreV2.mapLayerManagerService.drawingLayer = {
                        mapItem: this.mapCoreV2.polylineDrawingTail,
                        mapItemType: this.mapCoreV2.activeDrawingType
                    }
                } else if (this.mapCoreV2.activeDrawingType === mapItemTypes.MARKER) {
                    //Nothing. Marker has been set to drawing layer on click already
                }
                this.mapCoreV2.mapDataManagerService.saveDrawing(false);
            }
        } else { //Nothing done, safely close
            this.logger.log('[Grid][MapDrawingManagerService] ' + 'Close drawing mode, no changes made')
            this.resetDrawing();
            if(drawingType !== null){
                this.mapCoreV2.mapHelperManagerService.setDrawingType(drawingType)
                this.mapCoreV2.mapHelperManagerService.handleSnapAndParallelPoints()
            }
        }
        //Disable edit button
        this.mapCoreV2.mapUIManagerService.toggleEditButtonEnabled(false)
    }

    public resetDrawing(): void {
        //Reset all drawing elements
        this.logger.log('[Grid][MapDrawingManagerService] ' + 'Reset drawing to starting state')
        this.mapCoreV2.mapLayerManagerService.clearDrawingLayer()
        this.mapCoreV2.mapUIManagerService.closeDrawingModeUi();
        this.mapCoreV2.polylineDrawingHead.setPath([])
        this.mapCoreV2.polylineDrawingHead.setMap(null)
        this.mapCoreV2.mapHelperManagerService.hideHighlightPoint();
        this.mapCoreV2.mapHelperManagerService.setState(drawingStates.DRAW_NEW)
        this.mapCoreV2.mapHelperManagerService.setDrawingType(null)
        this.mapCoreV2.mapHelperManagerService.clearSnappedPolylines()
        this.mapCoreV2.markerDrawing.setMap(null)
        this.mapCoreV2.jointDrawing.setMap(null)
        this.mapCoreV2.jointDisabledDrawing.setMap(null)
        this.mapCoreV2.mapHelperManagerService.highlightPolyline(false)

    }
}
