<div class="module-wrapper"
     (globalResizeOutsideAngular)="handleResize()">
    <div class="container-fluid full-height">
        <div #dragContainer
             class="row full-height flex-nowrap">
            <div [style.width]="mobileMode?'100%':treeCollapsed?TREE_COLLAPSED_WIDTH + 'px':leftPanelWidth + 'px'"
                 class="module-tree-container full-height no-mobile-padding pl-0"
                 [hidden]="isTreeHidden()">
                <tree-component #treeComponent
                                *ngIf="initialized"
                                [hideActionIcon]="_treeSettings.hideActionIcon"
                                [childNodeIcon]="_treeSettings.childNodeIcon"
                                [nodeActionIcon]="_treeSettings.nodeActionIcon"
                                [nodeActionTitleKey]="_treeSettings.nodeActionTitleKey"
                                [unfoldFolderClick]="_treeSettings.unfoldFolderClick"
                                [tree]="currentTree"
                                [hideTree]="hideTree"
                                [module]="MODULE_PATH"
                                [counts]="MODULE_COUNTS"
                                [collapsed]="treeCollapsed"
                                (onSwitchTree)="handleSwitchTree()"
                                (onToggleCollapse)="handleToggleCollapseTree($event)"
                                (onTreeNodeCreate)="handleTreeNodeCreate($event)"
                                (onTreeNodeAction)="handleTreeNodeAction($event)"
                                (onSelectionChange)="handleTreeSelectionChanged($event)">
                </tree-component>
            </div>
            <slider-component [refId]="leftSliderId"
                              *ngIf="!mobileMode && leftSliderId"
                              class="{{isTreeSliderHidden()?'hidden':''}}"
                              [minX]="-(leftPanelDefaultWidth /2)"
                              [maxX]="(leftPanelDefaultWidth /2)"
                              (onSlide)="handleTreeSliderSlide($event)">
            </slider-component>
            <div [style.min-width]="MAP_TABLE_MIN_WIDTH + 'px'"
                 [style.paddingLeft]="mobileMode?'':treeCollapsed?'0.5rem':''"
                 [style.paddingRight]="mobileMode || hideForm ?'':formCollapsed?'0.5rem':''"
                 class="module-map-container-stretched module-map-container no-mobile-padding"
                 [hidden]="!isActiveState(STATE_MAP)">
                <map-table-component #mapTableComponent
                                     *ngIf="initialized"
                                     [tableOptions]="tableOptions"
                                     [filterString]="filterString"
                                     [allowSingleSelect]="_mapTableSettings.allowSingleSelect"
                                     [allowMultiSelect]="(_mapTableSettings.allowMultiSelect === false)? false : !mobileMode && auth.allowBatchUpdate()"
                                     [allowCreateMarker]="_mapTableSettings.allowCreateMarker"
                                     [allowMixedView]="(_mapTableSettings.allowMixedView === false)? false : !mobileMode"
                                     [allowAutoLoadMarkers]="_mapTableSettings.allowAutoLoadMarkers"
                                     [allowMarkerDrag]="(_mapTableSettings.allowMarkerDrag === false)? false : auth.allowMoveMapItem()"
                                     [useCustomIconColors]="_mapTableSettings.useCustomIconColors"
                                     [iconSet]="_mapTableSettings.iconSet"
                                     [isBasicMode]="_mapTableSettings.isBasicMode"
                                     [hideMap]="_mapTableSettings.hideMap"
                                     [showReportInfoText]="_mapTableSettings.showReportInfoText"
                                     [showSearchBar]="_mapTableSettings.showSearchBar"
                                     [allowMultiLineInRow]="_mapTableSettings.allowMultiLineInRow"
                                     [showMapTableLoaders]="showMapTableLoaders"
                                     (onAutoLoadRequest)="handleAutoLoadRequest($event)"
                                     (onSelectionChange)="handleTableMapSelectionChange($event)"
                                     (onTableFilterChange)="handleTableFilterChange($event)"
                                     (onMapLongPress)="handleMapLongPress($event)">
                    <ng-content unbroken-top-bar select="[mapTableButtons]"></ng-content>
                    <ng-content broken-top-bar select="[mapTableButtonsBrokenTopBar]"></ng-content>
                </map-table-component>
            </div>
            <slider-component [refId]="rightSliderId"
                              *ngIf="!mobileMode && rightSliderId"
                              class="{{isFormSliderHidden()?'hidden':''}}"
                              [minX]="-(rightPanelDefaultWidth/2)"
                              [maxX]="(rightPanelDefaultWidth/2)"
                              (onSlide)="handleFormSliderSlide($event)">
            </slider-component>
            <div [style.min-width]="!formCollapsed?FORM_MIN_WIDTH + 'px':FORM_COLLAPSED_WIDTH + 'px'"
                 class="module-form-container no-mobile-padding pr-0"
                 [style.width]="mobileMode?'100%':formCollapsed?FORM_COLLAPSED_WIDTH + 'px':rightPanelWidth + 'px'"
                 [hidden]="hideForm || !isActiveState(STATE_FORM)">
                <section-form-component [module]="this.MODULE_PATH"
                                        *ngIf="initialized"
                                        #formComponent
                                        [collapsed]="formCollapsed"
                                        [formData]="formData"
                                        (onToggleCollapse)="handleToggleCollapseForm($event)"
                                        (onComponentEvent)="handleComponentEvent($event)">
                </section-form-component>
            </div>
        </div>
    </div>
</div>
