<complex-list-item [item]="listItem" (onMenuItemClick)="handleMenuItemClick($event)">
    <div class="pt-2">
        <key-value [key]="'mapserver.layerslabel' | translate">
            <div class="d-flex flex-column w-100 align-self-center">
                <lumi-select *ngIf="mapLayers?.length > 0" class="ml-auto mr-1 w-100"
                             [options]="mapLayers"
                             [selectedOptions]="selectedMapLayers"
                             [showOptionFilter]="mapLayers?.length > 10"
                             [disabled]="false"
                             [readonly]="false"
                             (onOptionsSelect)="handleOptionSelect($event)"
                             [multi]="true">
                </lumi-select>
            </div>
            <lumi-button class="pl-2 align-self-center" (onClick)="handleClickSave()"
                         [size]="'small'"
                         [icon]="'save'"
                         [rank]="'tertiary'"
                         [title]="'project-activity.save.title' | translate">
            </lumi-button>
        </key-value>
        <div class="form-attachment-loader mt-2 w-100 {{isLoading || isLoadingError?'':'d-none'}}" style="margin-bottom: -0.5rem">
            <div class="progress">
                <div *ngIf="isLoading" class="indeterminate"></div>
            </div>
        </div>
        <div class="d-flex align-items-center" style="margin-left: -1rem;">
            <i *ngIf="!isLoadingError && serverSettings.disabled" class="material-icons">cloud_off</i>
            <p *ngIf="!isLoadingError && serverSettings.disabled">{{'mapserver.disabled' | translate}}</p>
            <i *ngIf="isLoadingError" class="material-icons" style="color: #ff3333">warning</i>
            <p *ngIf="isLoadingError">{{this.errorMessage}}</p>
        </div>
    </div>
</complex-list-item>
