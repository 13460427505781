/**
 * Created by Christiaan on 01/03/2017.
 */
import {
    ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output
} from '@angular/core';

import {GlobalAlertService} from "../global-alert.service";
import {ButtonCode, GlobalPopup} from '../global-popup';
import {AbstractBasePopupComponent} from './abstract-base-popup.component';
import {TranslateService} from "../../../shared/services/translate/translate.service";
import {TooltipService} from "../../../shared/services/tooltip/tooltip.service";
import {LoggerService} from "../../../shared/services/logger/logger.service";

@Component ({
    selector: 'global-popup-delete-baseobject-component',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <default-popup-wrapper-component [componentRef]="this" [disableButtonsOverride]="disableButtonsOverride" [globalPopup]="globalPopup" [title]="globalPopup.title" (onClose)="closePopup($event)">
                <ng-container *ngIf="hasCascadingDeleteItems()">
                    <div>{{(hasArchiveButton()?'baseobject.deleteorarchive':'baseobject.deleteonly') | translate}}</div>
                    <ul class="pl-3 pt-1">
                        <ng-container *ngFor="let effect of globalPopup.data.cascadeEffects">
                            <ng-container *ngIf="effect.cascading">
                            <li style="list-style-type: disc">
                                <div class="pr-1"><b>{{effect.label}}:</b>
                                    <span *ngIf="effect.content">
                                        <ng-container *ngFor="let item of effect.content, let last = last">
                                            {{item}}{{last?'':','}}
                                        </ng-container>
                                    </span>
                                    <span *ngIf="!effect.content">{{effect.count}} item{{effect.count > 1?'s':''}}</span>
                                </div>
                            </li>
                            </ng-container>
                        </ng-container>
                    </ul>
                </ng-container>
                <ng-container *ngIf="hasBlockingCascadeItems()">
                    <div>{{'De volgende objecten staan het verwijderen van dit item in de weg:' | translate}}</div>
                    <ul class="pl-3 pt-1">
                        <ng-container *ngFor="let effect of globalPopup.data.cascadeEffects">
                            <ng-container *ngIf="!effect.cascading">
                                <li style="list-style-type: disc">
                                    <div class="pr-1"><b>{{effect.label}}:</b>
                                        <span *ngIf="effect.content">
                                            <ng-container *ngFor="let item of effect.content, let last = last">
                                                {{item}}{{last?'':','}}
                                            </ng-container>
                                        </span>
                                        <span *ngIf="!effect.content">{{effect.count}} item{{effect.count > 1?'s':''}}</span>
                                    </div>
                                </li>
                            </ng-container>
                        </ng-container>
                    </ul>
                </ng-container>
        </default-popup-wrapper-component>
    `
})

export class GlobalPopupDeleteBaseObjectComponent extends AbstractBasePopupComponent
{
    public disableButtonsOverride:any = (button:any) => {return button.code == ButtonCode.DELETE && this.hasBlockingCascadeItems()};

    public constructor(protected cd:ChangeDetectorRef, protected globalAlertService:GlobalAlertService, protected elementRef:ElementRef, protected ts:TranslateService, protected tooltipService:TooltipService, protected logger:LoggerService) {
        super(cd, globalAlertService, elementRef, ts, tooltipService, logger);
    }

    handlePopupAction(event: MouseEvent, alert: GlobalPopup, button: any): void {
        this.onPopupAction.emit({event:event, alert:alert, button:button});
    }

    public closePopup(event:any):void
    {
        this.doDefaultCloseHandling(event, false);
    }

    public hasArchiveButton():boolean
    {
        let button:any;

        for(let i = 0; i < this.globalPopup.buttonArray.length; i++) {
            button = this.globalPopup.buttonArray[i];
            if (button.code == ButtonCode.ARCHIVE){
                return true;
            }
        }

        return false;
    }

    public hasCascadingDeleteItems():boolean
    {
        let result:boolean = false;

        if (this.globalPopup && this.globalPopup.data && this.globalPopup.data.cascadeEffects){
            this.globalPopup.data.cascadeEffects.forEach( (effect:any) => {
                if (effect.cascading){
                    result = true;
                }
            })
        }

        return result;
    }

    public hasBlockingCascadeItems():boolean
    {
        let result:boolean = false;

        if (this.globalPopup && this.globalPopup.data && this.globalPopup.data.cascadeEffects){
            this.globalPopup.data.cascadeEffects.forEach( (effect:any) => {
                if (!effect.cascading){
                    result = true;
                }
            })
        }

        return result;
    }
}
