import {AfterViewInit, ChangeDetectorRef, Component, Renderer2, ViewChild} from '@angular/core';
import {ValidationConstraintService} from '../../services/validation-constraint.service';
import {TooltipService} from '../../../../services/tooltip/tooltip.service';
import {Subscription} from 'rxjs';
import {GlobalModel} from '../../../../services/state/global.model';
import 'moment/locale/nl';
import {MapCoreComponent} from '../../../map/map-core.component';
import {AbstractFormFieldComponent} from '../abstract/abstract-form-field.component';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MapItem} from '../../../map/map-item/map-item';
import Utils from '../../../../utils/utils';
import {TranslateService} from '../../../../services/translate/translate.service';
import {GlobalEvent} from '../../../../interfaces/global-event';
import {LoggerService} from "../../../../services/logger/logger.service";

@Component({
    selector: 'form-location',
    template: `
        <ng-container [formGroup]="locationForm">
            <!--<div><h2>Item verplaatsen</h2></div>-->
            <!-- <label class="" >Locatie</label>-->
            <div class="mt-1 d-flex {{isBasicMode()?'w-100':''}} lightgrey-background">
                <div class="{{isBasicMode() || !showCoordinates?'d-none':'d-flex'}} pb-2 flex-wrap flex-column w-50 mr-3 pl-2">
                    <div class="d-flex pt-2 flex-column" style="flex-grow: 1">
                        <label class="pr-2 mb-0 text-nowrap" for="latitude">{{'Latitude' | translate}}
                            <required-field class="{{clearCoordinates?'hidden':''}}"></required-field>
                        </label>
                        <input #inputLatitude [class.invalid]="isInvalid('latitude')" class="no-spinners"
                               [attr.disabled]="isDisabledCoordinateField()"
                               placeholder="{{'locationcomponent.nocoordinate' | translate}}"
                               title="{{'Latitude' | translate}}" type="number" lang="en"
                               (change)="handleChangeLatLngInput($event)" [(ngModel)]="latitude"
                               formControlName="latitude">
                    </div>
                    <div class="d-flex pt-2 flex-column" style="flex-grow: 1">
                        <label class="pr-2 mb-0 text-nowrap" for="longitude">{{'Longitude' | translate}}
                            <required-field class="{{clearCoordinates?'hidden':''}}"></required-field>
                        </label>
                        <input #inputLongitude [class.invalid]="isInvalid('longitude')" class="no-spinners"
                               [attr.disabled]="isDisabledCoordinateField()"
                               placeholder="{{'locationcomponent.nocoordinate' | translate}}"
                               title="{{'Longitude' | translate}}" type="number" lang="en"
                               (change)="handleChangeLatLngInput($event)" [(ngModel)]="longitude"
                               formControlName="longitude">
                    </div>
                    <div class="d-flex pt-2 flex-column" style="flex-grow: 1">
                        <label class="pr-2 mb-0 text-nowrap" for="x_coord">{{'X-coördinaat' | translate}}</label>
                        <input #xCoord [attr.disabled]="isDisabledCoordinateField()" class="no-spinners"
                               title="{{'X-coördinaat' | translate}}"
                               placeholder="{{'locationcomponent.nocoordinate' | translate}}" type="number" lang="en"
                               (change)="handleChangeXYInput($event)">
                    </div>
                    <div class="d-flex pt-2 flex-column" style="flex-grow: 1">
                        <label class="pr-2 mb-0 text-nowrap" for="y_coord">{{'Y-coördinaat' | translate}}</label>
                        <input #yCoord [attr.disabled]="isDisabledCoordinateField()" class="no-spinners"
                               title="{{'Y-coördinaat' | translate}}"
                               placeholder="{{'locationcomponent.nocoordinate' | translate}}" type="number" lang="en"
                               (change)="handleChangeXYInput($event)">
                    </div>
                    <div class="{{isControlReadOnly() || hideEmptyCoordinates?'d-none':'d-flex'}} pt-2 flex-column"
                         style="flex-grow: 1">
                        <label class="custom-control m-0 custom-checkbox">
                            <span class="custom-control-description checkbox-description"
                                  style="margin-top: auto;">{{'locationcomponent.clear' | translate}}</span>
                            <input type="checkbox" class="custom-control-input"
                                   checked="{{clearCoordinates == true?'checked':''}}"
                                   (change)="handleClearCoordinates()"/>
                            <span class="custom-control-indicator"></span>
                        </label>
                    </div>
                </div>
                <div class="card z-depth-0 {{isBasicMode() || !showCoordinates?'w-100':'w-50'}}">
                    <map-core-component #mapComponent [isBasicMode]="isBasicMode()" [allowSearch]="!isBasicMode()"
                                        [allowLocation]="!isBasicMode()" [allowCreateMarker]="true"
                                        [isMapInPopup]="true"
                                        [allowEasyMarkerDrag]="!isBasicMode()" [allowMarkerPopup]="false"
                                        [allowMarkerDrag]="!isBasicMode() && !isControlDisabled()"
                                        [allowSingleSelect]="false" [allowMultiSelect]="false"
                                        (onDragMarker)="handleDragMarker($event)"
                                        (onLongPress)="handleLongPress($event)"
                                        [ngStyle]="{width:'100%', height:showCoordinates?'300px':'200px'}"
                                        class="map-table-component">
                    </map-core-component>
                </div>
            </div>
        </ng-container>
    `,
})
export class FormLocationComponent extends AbstractFormFieldComponent implements AfterViewInit {
    @ViewChild('mapComponent', {static: false}) mapComponent: MapCoreComponent;
    @ViewChild('inputLatitude', {static: false}) inputLatitude: any;
    @ViewChild('inputLongitude', {static: false}) inputLongitude: any;
    @ViewChild('xCoord', {static: false}) inputXCoord: any;
    @ViewChild('yCoord', {static: false}) inputYCoord: any;

    private static readonly MARKER_BASEOBJECT_ID: number = 1;

    clearCoordinates: boolean = false;
    private subMobileMode: Subscription;
    private mobileMode: boolean = false;

    readonly locationForm: UntypedFormGroup;
    latitude: number = 0;
    longitude: number = 0;
    private markerIcon: string = '';

    showCoordinates: boolean = true;
    hideEmptyCoordinates: boolean = false;

    private subGPSPositionUpdate: Subscription;

    constructor(public renderer: Renderer2, public validationConstraintService: ValidationConstraintService, private formBuilder: UntypedFormBuilder, public tooltipService: TooltipService, private cd: ChangeDetectorRef, private model: GlobalModel, private translate: TranslateService, protected logger:LoggerService) {
        super(renderer, validationConstraintService, tooltipService, logger);

        //Build the local form. Needs to be available for the template, to connect form controls
        this.locationForm = this.formBuilder.group({
            latitude: ['', Validators.required],
            longitude: ['', Validators.required],
        });
    }

    isBasicMode(): boolean {
        return this.model.publicPortalMode.value;
    }

    handleChangeXYInput(event: any): void {
        let newLatLng: any = Utils.rd2wgs_2(this.inputXCoord.nativeElement.value, this.inputYCoord.nativeElement.value);
        this.latitude = newLatLng[0];
        this.longitude = newLatLng[1];

        this.updateMarkers();
    }

    isDisabledCoordinateField(): any {
        if (this.isControlDisabled() || this.clearCoordinates) {
            return '';
        }
        return null;
    }

    handleChangeLatLngInput(event: any) {
        this.updateMarkers();
        this.updateXYCoords();
    }

    private hasValidCoordinates(): boolean {
        return this.latitude && this.longitude && this.latitude > 0 && this.longitude > 0 && !this.clearCoordinates;
    }

    // Update the position of the marker, without changing the map zoom or panning
    private updateMarkerPosition() {
        if (this.hasValidCoordinates()) {
            let marker: any = this.mapComponent.getMarkerByBaseObjectId(FormLocationComponent.MARKER_BASEOBJECT_ID);
            if (marker) {
                marker.setPosition({lat: this.latitude, lng: this.longitude});
            }
        }
    }

    // Update the markers by replacing them with new ones
    private updateMarkers() {
        let newMarkers: any;

        if (this.hasValidCoordinates()) {
            newMarkers = [this.createMarker()];
        }
        else {
            newMarkers = [];
        }

        this.mapComponent.replaceMarkers(newMarkers, false, true);
    }

    private createMarker(): MapItem {

        //TODO: haal uit marker
        let marker: MapItem = new MapItem();
        marker.lat = this.latitude;
        marker.lng = this.longitude;
        marker.icon = this.markerIcon;
        marker.label = '  ';//"Nieuwe locatie";
        marker.baseObjects = [{id: FormLocationComponent.MARKER_BASEOBJECT_ID.toString(), label: ''}];

        return marker;
    }

    handleClearCoordinates() {
        this.clearCoordinates = !this.clearCoordinates;

        //Set the state of the input fields
        this.setInputState();
    }

    private setInputState() {
        if (this.clearCoordinates) {
            this.latitude = null;
            this.longitude = null;
            this.inputXCoord.nativeElement.value = '';
            this.inputYCoord.nativeElement.value = '';

            //Clear the validation
            this.locationForm.get('longitude').clearValidators();
            this.locationForm.get('longitude').updateValueAndValidity();
            this.locationForm.get('latitude').clearValidators();
            this.locationForm.get('latitude').updateValueAndValidity();
        }
        else {

            //Add validation
            this.locationForm.get('longitude').setValidators([Validators.required]);
            this.locationForm.get('longitude').updateValueAndValidity();
            this.locationForm.get('latitude').setValidators([Validators.required]);
            this.locationForm.get('latitude').updateValueAndValidity();

            this.getStartCoordinates();

            //No start coordinates because the value started empty, revert to are center
            if (this.latitude == null && this.longitude == null) {
                this.latitude = this.model.currentAreaal.value.center.lat;
                this.longitude = this.model.currentAreaal.value.center.lng;
            }

            this.updateXYCoords();
        }

        this.updateMarkers();
    }


    private getStartCoordinates() {
        //Try to fetch a preset position for this form component
        let movePosition: any = this.model.formLocationComponentPosition;

        if (movePosition) {
            this.latitude = movePosition.lat;
            this.longitude = movePosition.lng;
        }
        else {
            //No value given, set default initial values
            this.latitude = this.config.latitude;
            this.longitude = this.config.longitude;
        }
    }

    ngAfterViewInit() {
        this.subGPSPositionUpdate = this.model.onGlobalEvent.subscribe((event: GlobalEvent) => {
            if (event.type == GlobalEvent.EVENT_GPS_POSITION_UPDATE) {
                if (!this.clearCoordinates) {
                    this.latitude = event.data.currentPosition.lat;
                    this.longitude = event.data.currentPosition.lng;
                    this.updateMarkerPosition();
                    this.updateXYCoords();
                    this.cd.detectChanges();
                }
            }
        });

        this.subMobileMode = this.model.mobileMode.subscribe((value: boolean) => {

            //Don't forget to unsubscribe
            this.mobileMode = value;
            this.cd.detectChanges(); //Mark for change was not enough here
        });

        //Add the local formgroup to the parent form
        this.group.setControl('location', this.locationForm);

        this.getStartCoordinates();

        //No start coordinates because area is empty? Check the checkbox
        if (this.latitude == null && this.longitude == null) {
            if (this.config.attr?.setCenterArea) {
                this.latitude = this.model.currentAreaal.value.center.lat;
                this.longitude = this.model.currentAreaal.value.center.lng;
                this.clearCoordinates = false;
            } else {
                this.clearCoordinates = true;
            }
        }

        //Set the state of the fields
        this.setInputState();

        if (this.config.attr && this.config.attr.showCoordinates) {
            this.showCoordinates = this.config.attr.showCoordinates == true;
        }

        if (this.config.attr && this.config.attr.hideEmptyCoordinates) {
            this.hideEmptyCoordinates = this.config.attr.hideEmptyCoordinates == true;
        }

        if (this.config.attr) {
            this.markerIcon = <string>this.config.attr.icon;
        }

        let newMarker: any = [this.createMarker()];
        this.mapComponent.replaceMarkers(newMarker, false, true);

        //Wait for markers to be placed, then set zoomlevel. This is alway[s 1 item, so it's safe to hard-set it
        setTimeout(() => {
            this.mapComponent.setZoom(18);
        });
    }

    private updateXYCoords(): void {
        let newXY: any = Utils.wgs2rd_2(this.latitude, this.longitude);
        this.inputXCoord.nativeElement.value = newXY[1];
        this.inputYCoord.nativeElement.value = newXY[0];
    }

    set validationConstraints(value: any) {
        //this.tooltipService.hideAllTooltips();
        this.tooltipService.destroyToolTip(this.inputLatitude);
        this.tooltipService.destroyToolTip(this.inputLongitude);

        if (value) {

            this.logger.log('[FormLocationComponent] ' + 'value: ', value);

            let input: any = null;
            let message: string = this.translate.translate('Ongeldige waarde');

            if (value.latitude && value.latitude.errors) {
                input = this.inputLatitude;
                //message = value.latitude.errors[0].message;
            }
            else if (value.longitude && value.longitude.errors) {
                input = this.inputLongitude;
                //message = value.longitude.errors[0].message;
            }

            if (input) {
                this.tooltipService.createAndShowTooltip(this.renderer, input, message, TooltipService.PLACEMENT_BOTTOM, TooltipService.TOOLTIP_MODE_MANUAL, '', true);
            }
        }
    }

    isInvalid(control: string): boolean {
        let result: boolean = false;

        if (this.locationForm.controls[control] && this.locationForm.controls[control].status == 'INVALID') {
            result = true;
        }

        if (this.clearCoordinates) {
            result = false;
        }

        return result;
    }

    handleDragMarker(event: any) {
        this.logger.log('[FormLocationComponent] ' + 'markerdrag: ', event);

        this.mapComponent.disableLocationTracking();
        this.latitude = event.posNew.lat();
        this.longitude = event.posNew.lng();

        this.updateXYCoords();
    }

    handleLongPress(event: any) {
        this.logger.log('[FormLocationComponent] ' + 'longpress: ', event);

        if (!this.clearCoordinates) {
            this.mapComponent.disableLocationTracking();
            this.latitude = event.position.lat();
            this.longitude = event.position.lng();

            this.updateMarkerPosition();
            this.updateXYCoords();
            this.cd.detectChanges();
        }
    }

    ngOnDestroy() {
        // nullify the chart element explicitly
        if (this.subMobileMode) {
            this.subMobileMode.unsubscribe();
        }

        if (this.subGPSPositionUpdate) {
            this.subGPSPositionUpdate.unsubscribe();
        }

        //this.tooltipService.hideAllTooltips();
        this.tooltipService.destroyToolTip(this.inputLatitude);
        this.tooltipService.destroyToolTip(this.inputLongitude);
    }
}
