import {AfterViewInit, Component, EventEmitter, HostBinding, Input, Output} from "@angular/core";
import {TooltipService} from "../../../../services/tooltip/tooltip.service";

@Component({
    selector: 'form-component-label',
    template: `
        <label class="m-0 pr-2 {{batchUpdateMode?'':'pl-0 cursor-default'}} custom-control custom-checkbox"
               for="{{config.name}}">
        <ng-container *ngIf="batchUpdateMode">
                <lumi-checkbox [small]="true"
                               (change)="setIncluded($event)"
                               [value]="included">
                </lumi-checkbox>
            </ng-container>
            <span class="custom-control-description checkbox-description">{{ config.label }}</span>
            <ng-container *ngIf="_infoText">
                <help-icon-component [tooltipText]="_infoText"
                                     [tooltipPlacement]="PLACEMENT_BOTTOM"></help-icon-component>
            </ng-container>
            <required-field [visible]="config.required"></required-field>
        </label>
    `
})

export class FormComponentLabelComponent implements AfterViewInit {
    
    @HostBinding('class') hostClasses:string = '';
    @Output() onClickInclude: EventEmitter<boolean> = new EventEmitter();
    @Input() batchUpdateMode:boolean = false;
    @Input() included:boolean = false;

    @Input() set config(value:any)
    {
        this._config = value;

        //Extract infotext, if there is any
        if (this._config && this._config.attr && this._config.attr.infoText) {
            this._infoText = this._config.attr.infoText;
        }
    }

    get config():any
    {
        return this._config;
    }

    public _infoText:string = "";
    private _config:any;

    ngAfterViewInit(){
        if (this.batchUpdateMode){
            this.hostClasses = 'form-label-part-large';
        }else{
            this.hostClasses = 'form-label-part';
        }
    }

    get PLACEMENT_BOTTOM(): string {
        return TooltipService.PLACEMENT_BOTTOM;
    }
    
    public setIncluded($event: Event) {
        this.included = (<HTMLInputElement>$event.target).checked;
        if (this.batchUpdateMode){
            this.onClickInclude.emit(this.included);
        }
    }
}
