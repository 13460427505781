import {NgZone, Directive, ElementRef, Renderer2, AfterViewInit, Input} from '@angular/core';

@Directive({
    selector: '[initialFocus]'
})
export class InitialFocusDirective implements AfterViewInit {
    @Input() initialFocusOn: boolean = true;
    
    // TODO: waarom 300? simpelweg een timout is niet genoeg, maar waarom is zoveel vertraging nog nodig, is er ook een event voor?
    private static readonly FOCUS_DELAY:number = 300;

    constructor(private el: ElementRef, private zone: NgZone, private renderer: Renderer2) {}

    ngAfterViewInit() {
        if (this.initialFocusOn) {
            this.zone.runOutsideAngular(() => setTimeout(() => {
                this.renderer.selectRootElement(this.el.nativeElement).focus();
            }, InitialFocusDirective.FOCUS_DELAY));
        }
    }
}
