/**
 * Created by Christiaan on 01/03/2017.
 */
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    ViewChild
} from '@angular/core';

import {GlobalAlertService} from '../../global-alert.service';
import {ButtonCode, GlobalPopup} from '../../global-popup';
import {TranslateService} from '../../../../shared/services/translate/translate.service';
import {
    TableOptions,
    TableOptionsField,
    TableOptionsSet
} from '../../../../shared/components/table/tableColumnSelector/table-options';
import {AuthorizationService} from '../../../../shared/services/authorization/authorization.service';
import {TableOptionsService} from '../../../../shared/components/table/table-options.service';
import {GlobalModel} from '../../../../shared/services/state/global.model';
import {TreeMapFormComponent} from '../../../../shared/components/tree-map-form/tree-map-form.component';
import {RequestFailure} from '../../../../shared/services/http/request-failure';
import {AbstractBasePopupComponent} from '../abstract-base-popup.component';
import {TooltipService} from '../../../../shared/services/tooltip/tooltip.service';
import {TableColumnSelectorComponent} from '../../../../shared/components/table/tableColumnSelector/tableColumnSelector.component';
import {TableSortDirection} from '../../../../shared/components/table/shared/baseTable/baseTable.interface';
import {PopupDisplayMode} from '../default-popup-wrapper/default-popup-wrapper.interface';
import {LumiSelectOption} from '../../../../shared/components/commonUI/select/lumi-select/lumi-select.interface';
import {LoggerService} from "../../../../shared/services/logger/logger.service";

@Component({
    selector: 'global-popup-table-options-component',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: 'global-popup-table-options.component.html'
})
export class GlobalPopupTableOptionsComponent extends AbstractBasePopupComponent implements AfterViewInit {
    @ViewChild('columnSelectorComponent', {static: false}) columnSelector: TableColumnSelectorComponent;
    
    public liClasses: any = 'dropdown btn-group  waves-effect waves-light luminizer-main-menu-drop-down-item-container position-relative';
    public aClasses: any = 'nav-item nav-link pr-2 waves-effect waves-light luminizer-main-menu-drop-down-item';
    
    public tableOptionSets: TableOptionsSet[] = [];
    public selectedOptionsSetId: number = -1;
    public loadingSet: boolean = false;
    public hideMap: boolean = false;
    public activeSmartSelectionName: string = null;
    public POPUP_DISPLAY_MODE = PopupDisplayMode;
    public showFilterOptions: boolean = true;
    
    public tableFields: TableOptionsField[] = [];
    public secondaryButtons: any = [];
    public tempShowArchived: boolean = false;
    public tempIsPreferred: boolean = false;
    private tableOptions: TableOptions;
    private currentModulePath: string = '';
    
    public constructor(
        protected cd: ChangeDetectorRef,
        protected globalAlertService: GlobalAlertService,
        protected elementRef: ElementRef,
        public ts: TranslateService,
        private model: GlobalModel,
        public auth: AuthorizationService,
        private tableOptionService: TableOptionsService,
        protected tooltipService: TooltipService,
        protected logger:LoggerService
    ) {
        super(cd, globalAlertService, elementRef, ts, tooltipService, logger);
    }
    
    get BUTTON_CODE_SAVE(): string {
        return ButtonCode.SAVE;
    }
    
    ngAfterViewInit() {
        this.currentModulePath = TreeMapFormComponent.moduleNameToPath(this.model.currentModule.value);
        
        // Create local buttons
        this.secondaryButtons = [
            {
                label: this.ts.translate('optionset.saveselectionas'),
                code: ButtonCode.SAVE_AS,
                isPrimary: false
            },
            {
                label: this.ts.translate('optionset.saveselection'),
                code: ButtonCode.SAVE,
                isPrimary: false
            },
        ];
        
        // Get data send with the creation of the popup
        this.tableOptions = this.globalPopup.data.tableOptions;
        this.tableFields = this.tableOptions.tableFields;
        this.hideMap = this.globalPopup.data.hideMap;
        
        this.getOptionSets();
        this.initForm(this.tableOptions);
        this.cd.detectChanges();
    }
    
    // Manual select option set from the dropdown
    public handleSelectOptionSet(sets: LumiSelectOption[]): void {
        // Change selected set, and do the call
        this.selectedOptionsSetId = Number(sets[0].id);
        this.activeSmartSelectionName = null;
        if (this.selectedOptionsSetId !== -1) {
            this.activeSmartSelectionName = this.tableOptionSets.find(set => set.id === this.selectedOptionsSetId).name;
        }
        this.model.changedSmartSelection.next(false);
        this.getOptionSet();
    }
    
    public handleRenameOptionSet(): void {
        let selectedOptionSet: any = this.getOptionSetById(this.selectedOptionsSetId);
        if (selectedOptionSet) {
            this.globalAlertService.addPopupInput(
                this.ts.translate('optionset.renametitle'),
                this.ts.translate('optionset.renamelabel',
                    [selectedOptionSet.name]),
                selectedOptionSet.name,
                [{
                    label: this.ts.translate('Annuleren'),
                    code: ButtonCode.ANNULEREN,
                    isPrimary: false
                }, {
                    label: this.ts.translate('Ok'), code: ButtonCode.OK,
                    callback: (code: string, data: any) => {
                        // TODO: meer naam-validatie?
                        let newName: string = data.input;
                        newName = newName.trim();
                        
                        this.tableOptionService.updateTableOptionSet(
                            this.currentModulePath,
                            selectedOptionSet.id,
                            newName,
                            null,
                            null,
                            (response: any) => {
                                // Get the list, with new name
                                if (response) {
                                    this.getOptionSets(response.id);
                                    this.cd.detectChanges();
                                    this.model.changedSmartSelection.next(false);
                                }
                            }
                        );
                        
                    }, isPrimary: true
                }], () => {
                });
        }
        
    }
    
    public handleDeleteOptionSet(): void {
        let selectedOptionSet: any = this.getOptionSetById(this.selectedOptionsSetId);
        
        if (selectedOptionSet) {
            this.globalAlertService.addPopup(
                this.ts.translate('optionset.removetitle'),
                this.ts.translate('optionset.remove',
                    [selectedOptionSet.name]),
                [{
                    label: this.ts.translate('Annuleren'),
                    code: ButtonCode.ANNULEREN,
                    isPrimary: false
                }, {
                    label: this.ts.translate('Verwijderen'), code: 'DELETE',
                    callback: () => {
                        this.tableOptionService.deleteTableOptionSet(this.currentModulePath, selectedOptionSet.id, (json: any) => {
                                // delete success, reload option sets
                                this.getOptionSets();
                                this.model.changedSmartSelection.next(false);
                            },
                            (failure: RequestFailure) => {
                                this.globalAlertService.addPopup(this.ts.translate('Waarschuwing'), failure.message, [{
                                    label: this.ts.translate('Ok'),
                                    code: 'OK',
                                    isPrimary: true
                                }], () => {
                                });
                            },
                            () => {
                            });
                        
                    }, isPrimary: true
                }], () => {
                });
        }
    }
    
    public handleChangeArchived(): void {
        this.model.changedSmartSelection.next(true);
        // Toggle archived button state
        this.tempShowArchived = !this.tempShowArchived;
    }
    
    // Return true is the current table options are displayed (so no specific option set)
    public isCurrentTableOptions(): boolean {
        return this.selectedOptionsSetId < 0;
    }
    
    public handlePopupAction(event: MouseEvent, alert: GlobalPopup, button: any): void {
        // Save button for the table options
        if (button.code === ButtonCode.OK) {
            const previousOptions: String = JSON.stringify(this.tableOptions);
            this.buildNewTableOptions(this.tableOptions);
            const tableOptionsChanged: boolean = JSON.stringify(this.tableOptions) !== previousOptions;
            
            // Hide alert and perform callback of button
            this.onPopupAction.emit({
                event: event,
                alert: alert,
                button: button,
                data: {
                    tableOptionsChanged: tableOptionsChanged,
                    activeSmartSelectionName: this.activeSmartSelectionName
                }
            });

            // Save new options + close popup
            if (!this.isCurrentTableOptions()) {
                this.handleEditOptionSet();
            }
        } else if (button.code === ButtonCode.SAVE) {
            if (!this.isCurrentTableOptions()) {
                this.handleEditOptionSet();
            }
        } else if (button.code === ButtonCode.SAVE_AS) {
            this.handleAddOptionSet();
        } else if (button.code === ButtonCode.RESET) {
            this.globalAlertService.addPopup(this.ts.translate('filter.resettitle'), this.ts.translate('filter.resetfull'),
                [{
                    label: this.ts.translate('Annuleren'),
                    code: ButtonCode.ANNULEREN,
                    isPrimary: false
                },
                    {
                        label: this.ts.translate('Doorgaan'), code: 'RESET',
                        callback: () => {
                            
                            this.logger.log('[GlobalPopupTableOptionsComponent] ' + 'RESET all fields');
                            
                            this.resetToDefaults(this.tableFields);
                            this.tableOptions.showArchived = this.tempShowArchived;
                            
                            this.model.changedSmartSelection.next(false);
                            // Hide alert and perform callback of button
                            this.onPopupAction.emit({
                                event: event,
                                alert: alert,
                                button: button,
                                data: {tableOptionsChanged: true, activeSmartSelectionName: ''}
                            });
                            
                        }, isPrimary: true
                    }], () => {
                });
            /*}else if (button.code === ButtonCode.ADD_FILTER){
                this.handleClickAddFilter(event);
            */
        } else {
            // Cancel and other
            this.model.changedSmartSelection.next(false);
            this.onPopupAction.emit({event: event, alert: alert, button: button});
        }
    }
    
    public closePopup(event: any): void {
        this.doDefaultCloseHandling(event, false);
    }
    
    public onColumnChange(): void {
        this.model.changedSmartSelection.next(true);
    }
    
    public handleChangePreferred(): void {
        this.tempIsPreferred = !this.tempIsPreferred;
    }
    
    private initForm(tableOptions: TableOptions): void {
        this.tableFields = [...tableOptions.tableFields];
        this.tempShowArchived = tableOptions.showArchived;
    }
    
    // Option set list call
    private getOptionSets(autoSelectId: number = -1): void {
        this.activeSmartSelectionName = null;
        this.loadingSet = true;
        this.tableOptionService.listTableOptionSets(this.currentModulePath, (json: any) => {
            
            this.loadingSet = false;
            if (!json) {
                this.logger.log('[GlobalPopupTableOptionsComponent] ' + 'No valid options, exit');
                return;
            }
            
            this.tableOptionSets = json;
            // Add 'current table options' to dropdown
            this.tableOptionSets.unshift({id: -1, name: this.ts.translate('optionset.custom')});
            this.tableOptionSets.forEach(set => {
                if (set.isPreferred === undefined || set.isPreferred === null) {
                    set.isPreferred = false;
                }
            });
            
            if (this.tableOptionSets.length > 0) {
                if (autoSelectId > -1) {
                    this.selectedOptionsSetId = autoSelectId;
                    this.activeSmartSelectionName = this.tableOptionSets.find(set => set.id === this.selectedOptionsSetId).name;
                } else if (this.globalPopup.data
                    && this.globalPopup.data.selectedScheme > 0
                    && this.getOptionSetById(this.globalPopup.data.selectedTableOptionSet)
                ) {
                    this.selectedOptionsSetId = this.globalPopup.data.selectedTableOptionSet;
                    this.globalPopup.data.selectedTableOptionSet = -1;
                } else {
                    this.selectedOptionsSetId = Number(this.tableOptionSets[0].id);
                }
                
                // Get the selected option set
                this.getOptionSet();
            }
            
            this.cd.detectChanges();
            
        }, () => {
        }, () => {
        });
    }
    
    // Get local option set by id
    private getOptionSetById(schemeId: number): TableOptionsSet {
        for (let i: number = 0; i < this.tableOptionSets.length; i++) {
            if (this.tableOptionSets[i].id === schemeId) {
                return this.tableOptionSets[i];
            }
        }
        
        return null;
    }
    
    private getOptionSet(): void {
        // Skip when loading 'custom' set
        if (this.isCurrentTableOptions()) {
            
            // Re-init the form with the original values
            this.initForm(this.tableOptions);
            this.cd.detectChanges();
            
            return;
        }
        
        this.loadingSet = true;
        this.cd.detectChanges();
        
        this.tableOptionService.getTableOptionSet(this.currentModulePath, this.selectedOptionsSetId, (json: any) => {
            
            this.loadingSet = false;
            
            // Merge the table options. Current table options should be leading. They might contain new columns ect
            // Don't change the this.tableOptions, keep the original
            let newTableOptions: any = {
                tableFields: JSON.parse(JSON.stringify(this.tableFields)),
                showArchived: this.tempShowArchived
            };
            this.tableOptionService.mergeTableOptions(newTableOptions, json.data.tableOptions);
            this.tempIsPreferred = json.isPreferred;
            this.initForm(newTableOptions);
            
            this.cd.detectChanges();
        }, () => {
            this.loadingSet = false;
            this.cd.detectChanges();
        }, () => {
        });
    }
    
    private handleAddOptionSet(): void {
        let selectedOptionSet: any = this.getOptionSetById(this.selectedOptionsSetId);
        if (selectedOptionSet) {
            this.globalAlertService.addPopupInput(
                this.ts.translate('optionset.createtitle'),
                this.ts.translate('optionset.createlabel'),
                '',
                [{
                    label: this.ts.translate('Annuleren'),
                    code: ButtonCode.ANNULEREN,
                    isPrimary: false
                },
                    {
                        label: this.ts.translate('Ok'), code: ButtonCode.OK,
                        callback: (code: string, data: any) => {
                            
                            let newTableOptions: any = {
                                tableFields: this.columnSelector.getResultingTableFields(),
                                showArchived: this.tempShowArchived
                            };
                            
                            // TODO: meer naam-validatie?
                            let newName: string = data.input;
                            newName = newName.trim();
                            
                            this.tableOptionService.createTableOptionSet(
                                this.currentModulePath,
                                newName,
                                this.tempIsPreferred,
                                {tableOptions: newTableOptions},
                                (response: any) => {
                                    if (response) {
                                        if (response.isPreferred) {
                                            this.clearPreferredFromOtherSets(response.id);
                                        }
                                        this.getOptionSets(response.id);
                                        this.model.changedSmartSelection.next(false);
                                        this.cd.detectChanges();
                                    }
                                }, () => {
                                }, () => {
                                }
                            );
                        }, isPrimary: true
                    }], () => {
                });
        }
    }
    
    // Save the data, not the name per se
    private handleEditOptionSet(): void {
        let selectedOptionSet: any = this.getOptionSetById(this.selectedOptionsSetId);
        if (selectedOptionSet) {
            this.globalAlertService.addPopup(
                this.ts.translate('optionset.savetitle'),
                this.ts.translate('optionset.savelabel',
                    [selectedOptionSet.name]),
                [{
                    label: this.ts.translate('Annuleren'),
                    code: ButtonCode.ANNULEREN,
                    isPrimary: false
                }, {
                    label: this.ts.translate('Doorgaan'), code: 'SAVE',
                    callback: () => {
                        let newTableOptions: TableOptions = {
                            tableFields: this.columnSelector.getResultingTableFields(),
                            showArchived: this.tempShowArchived
                        };
                        
                        this.tableOptionService.updateTableOptionSet(
                            this.currentModulePath,
                            selectedOptionSet.id,
                            selectedOptionSet.name,
                            this.tempIsPreferred,
                            {tableOptions: newTableOptions},
                            (response: any) => {
                                // Refresh the option set so that a set with no columns will be displayed as the default selection
                                if (response) {
                                    selectedOptionSet.isPreferred = response.isPreferred;
                                    if (response.isPreferred) {
                                        this.clearPreferredFromOtherSets(response.id);
                                    }
                                    this.getOptionSet();
                                    this.cd.detectChanges();
                                    this.model.changedSmartSelection.next(false);
                                }
                            }
                        );
                        
                    }, isPrimary: true
                }], () => {
                });
        }
    }
    
    private clearPreferredFromOtherSets(preferredSetId: number): void {
        this.tableOptionSets.forEach(set => {
            if (set.id !== preferredSetId && set.isPreferred) {
                this.tableOptionService.updateTableOptionSet(
                    this.currentModulePath,
                    Number(set.id),
                    set.name,
                    false,
                    null,
                    result => {
                        set.isPreferred = result.isPreferred;
                    },
                    () => {
                    }
                );
            }
        });
    }
    
    private buildNewTableOptions(tableOptions: TableOptions): void {
        const newTableFields = this.columnSelector.getResultingTableFields();
        if (!newTableFields.some(field => field.visible)) {
            this.resetToDefaults(newTableFields);
        }
        tableOptions.tableFields = newTableFields;
        tableOptions.showArchived = this.tempShowArchived;
    }
    
    private resetToDefaults(tableFields: TableOptionsField[]): void {
        this.tempShowArchived = false;
        
        let rankCounter = 1;
        tableFields.forEach((field: any) => {
            field.visible = field.default;
            field.inMouseOver = field.default;
            field.sorting = false;
            field.sortingDirection = TableSortDirection.SORT_DIRECTION_UNSET;
            field.columnRank = field.visible ? rankCounter : null;
            field.filter = null;
            
            if (field.visible) {
                rankCounter++;
            }
        });
        const firstVisibleTableField = tableFields.filter(field => field.visible)[0];
        firstVisibleTableField.sortDirection = TableSortDirection.SORT_DIRECTION_ASC;
        firstVisibleTableField.sorting = true;
    }
    
    getSelectedTableOption() {
        return this.tableOptionSets.filter(set => set.id === this.selectedOptionsSetId) || [];
    }
}
