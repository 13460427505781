import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import {LumiSelectOption} from './lumi-select.interface';
import Utils from '../../../../utils/utils';

@Component({
    selector: 'lumi-select',
    templateUrl: './lumi-select.component.html',
})
export class LumiSelectComponent implements OnInit, AfterViewInit {
    @Input() disabled: boolean;
    @Input() readonly: boolean;
    @Input() multi: boolean = false;
    @Input() dropdownSizeLarge: boolean = false;
    @Input() singleLineDropdown: boolean = true;
    @Input() sortSelection: boolean = true;
    @Input() showOptionFilter: boolean = true;
    @Input() options: LumiSelectOption[] = [];
    @Input() selectedOptions: LumiSelectOption[] = [];
    @Output() onOptionsSelect: EventEmitter<LumiSelectOption[]> = new EventEmitter();
    @ViewChild('optionFilter', {static: false}) optionFilter: ElementRef<HTMLInputElement>;
    @ViewChild('dropdown', {static: false}) dropdown: ElementRef<HTMLDivElement>;
    @ViewChild('dropdownToggler', {static: false}) dropdownToggler: ElementRef<HTMLDivElement>;
    
    public Utils = Utils;
    
    public showDropdown: any = false;
    public isBeingToggled: any = false;
    public filteredOptions: LumiSelectOption[] = [];
    public highlightedOption: LumiSelectOption = null;
    private outOfViewCheckResults: any = null;
    private preventKeyUpEvent: boolean = false;
    
    constructor(private changeDetectorRef: ChangeDetectorRef) {
    }
    
    ngOnInit() {
    }
    
    ngAfterViewInit() {
        setTimeout(() => {
            this.filteredOptions = [...this.options];
        });
    }
    
    public toggleDropDown($event: MouseEvent|KeyboardEvent): void {
        Utils.preventDefault($event);
        
        if (this.disabled || this.readonly) {
            return;
        }
        
        this.isBeingToggled = true;
        this.outOfViewCheckResults = null;
        this.highlightedOption = null;
        this.showDropdown = !this.showDropdown;

        let numberOfTries = 0;
        let timeoutCallback = () => {
            if (this.dropdown) {
                this.outOfViewCheckResults = Utils.isOutOfViewport(this.dropdown.nativeElement);

                if (this.outOfViewCheckResults?.outOfBottom) {
                    this.dropdown.nativeElement.scrollTop = 1000000000;
                } else {
                    this.dropdown.nativeElement.scrollTop = 0;
                }

                this.dropdown.nativeElement.scrollLeft = 0;
                this.isBeingToggled = false;
                this.changeDetectorRef.detectChanges();

                if (this.showOptionFilter && this.optionFilter) {
                    if (this.showDropdown) {
                        setTimeout(() => {
                            this.optionFilter.nativeElement.focus();
                        }, 50);
                    } else {
                        this.optionFilter.nativeElement.value = '';
                        this.updateFilteredOptions('');
                    }
                }
            } else {
                if (numberOfTries < 20) {
                    setTimeout(timeoutCallback, 100);
                    numberOfTries++;
                }
            }
        };
        setTimeout(timeoutCallback);
    }
    
    public handleClickOption($event: MouseEvent | Event, option: LumiSelectOption): void {
        if (option.disabled) {
            Utils.preventDefault($event);
            return;
        }
        if (!this.multi) {
            this.selectedOptions = [option];
        } else {
            $event.preventDefault();
            $event.stopImmediatePropagation();
            
            if (this.selectedOptions.some(_option => _option.id === option.id)) {
                const index = this.selectedOptions.findIndex(_option => _option.id === option.id);
                this.selectedOptions.splice(index, 1);
            } else {
                this.selectedOptions.push(option);
            }
        }
        this.updateSelectedOptionsList();
    }
    
    public handleFilterInput(event: any): void {
        this.updateFilteredOptions(event.target.value);
    }
    
    public handleKeyDownDropDown(e: KeyboardEvent): void {
        if (!this.showDropdown) {
            return;
        }
        
        let currentHighlightedIndex: number = this.filteredOptions.indexOf(this.highlightedOption);
        
        if (e.key === 'Escape') {
            Utils.preventDefault(e);
            this.toggleDropDown(null);
            this.dropdownToggler.nativeElement.focus();
            this.preventKeyUpEvent = true;
        }
        
        if (e.key === 'Enter' && this.highlightedOption) {
            Utils.preventDefault(e);
            this.handleClickOption(e, this.highlightedOption);
            if (!this.multi) {
                this.dropdownToggler.nativeElement.focus();
                this.closeDropdown();
            }
            this.preventKeyUpEvent = true;
            
        }
        
        if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
            Utils.preventDefault(e);
            
            // Move up or down
            let factor: number = e.keyCode === 40 ? 1 : -1;
            if (this.outOfViewCheckResults?.outOfBottom) {
                currentHighlightedIndex -= factor;
            } else {
                currentHighlightedIndex += factor;
            }
            
            // Make it safe
            if (currentHighlightedIndex < 0) {
                currentHighlightedIndex = this.filteredOptions.length - 1;
            } else if (currentHighlightedIndex >= this.filteredOptions.length) {
                currentHighlightedIndex = 0;
            }
            
            // Apply selected item to view
            this.highlightedOption = this.filteredOptions[currentHighlightedIndex];
        }
        
        setTimeout(() => {
            let highlightedElement: any = document.querySelector('lumi-select label.highlighted');
            if (highlightedElement) {
                this.dropdown.nativeElement.scrollTop =
                    (highlightedElement.offsetTop - (highlightedElement.getBoundingClientRect().height * 3));
            }
        });
    }
    
    public getDropDownBottom(): string {
        if (this.outOfViewCheckResults?.outOfBottom) {
            return (this.dropdownToggler.nativeElement.clientHeight) + 'px';
        } else {
            return 'inherit';
        }
    }
    
    public getDropDownDirection(): string {
        return this.outOfViewCheckResults?.outOfBottom ? 'flex-column-reverse' : 'flex-column';
    }
    
    public getDropDownLeft(): string {
        if (this.outOfViewCheckResults?.outOfRight) {
            return -(this.outOfViewCheckResults?.pixelsOutOfRight) + 'px';
        } else {
            return 'inherit';
        }
    }
    
    private updateFilteredOptions(filterString: string = ''): void {
        this.filteredOptions = this.options.filter((option: LumiSelectOption) => {
            return option.name.toLowerCase().indexOf(filterString.toLowerCase()) !== -1;
        });
    }
    
    public closeDropdown(): void {
        this.showDropdown = false;
        this.changeDetectorRef.detectChanges();
    }
    
    public removeChoice($event: MouseEvent, option: LumiSelectOption): void {
        Utils.preventDefault($event);
    
        if (this.selectedOptions.some(_option => _option?.id === option.id)) {
            const index = this.selectedOptions.findIndex(_option => _option?.id === option.id);
            this.selectedOptions.splice(index, 1);
        }
        this.updateSelectedOptionsList();
    }
    
    private updateSelectedOptionsList(): void {
        if (this.sortSelection) {
            this.selectedOptions.sort((optionA: LumiSelectOption, optionB: LumiSelectOption) => {
                if (optionA?.id === String(Number(optionA?.id)) && optionB?.id === String(Number(optionB?.id))) {
                    return Number(optionA?.id) - Number(optionB?.id);
                }
                if (optionA?.id < optionB?.id) { return -1; }
                if (optionA?.id > optionB?.id) { return 1; }
                return 0;
            });
        }
        this.onOptionsSelect.emit(this.selectedOptions);
    }
    
    public isSelected(option: LumiSelectOption): boolean {
        return this.selectedOptions.some(_option => _option?.id === option.id);
    }
    
    public onKeyUpToggler($event: KeyboardEvent): void {
        if (this.preventKeyUpEvent) {
            this.preventKeyUpEvent = false;
            Utils.preventDefault($event);
            return;
        }
        if (!Utils.hasFocus(this.dropdownToggler.nativeElement)) {
            return;
        }
        if ($event.key === 'Enter' && !this.showDropdown) {
            Utils.preventDefault($event);
            this.toggleDropDown($event);
        }
        if ($event.key === 'Escape') {
            Utils.preventDefault($event);
            Utils.removeAllFocus();
        }
    }
}
