/**
 * Created by Christiaan on 07/04/2017.
 */

import {Directive, NgZone, Output, EventEmitter} from '@angular/core';

@Directive({
    selector: '[globalFocusOutsideAngular]'
})

export class GlobalFocusOutsideAngularDirective
{
    private static readonly EVENT_FOCUS:string = "focus";

    @Output('globalFocusOutsideAngular') focusHandler: EventEmitter<any> = new EventEmitter();

    constructor(private ngZone:NgZone)
    {
        this.ngZone.runOutsideAngular(() => {
            window.addEventListener(GlobalFocusOutsideAngularDirective.EVENT_FOCUS, (event:any)=> {
               this.focusHandler.emit(event);
            })
        });
    }

    //Alternatieve aanpak via hostlistener:
    /*
    @HostListener('window:resize', ['$event'])

    handleResize(event: Event) {
        this.resizeHandler.emit(event);
    }
    */
}
