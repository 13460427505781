/**
 * Created by Christiaan on 07/04/2017.
 */

import {Directive, NgZone, Output, EventEmitter, OnDestroy} from '@angular/core';

@Directive({
    selector: '[globalResizeOutsideAngular]'
})

export class GlobalResizeOutsideAngularDirective implements OnDestroy
{
    private static readonly EVENT_RESIZE:string = "resize";
    private static readonly EMIT_BUFFER_TIME: number = 250; //Milliseconds

    @Output('globalResizeOutsideAngular') resizeHandler: EventEmitter<any> = new EventEmitter();

    private emitBuffer:any;

    constructor(private ngZone:NgZone)
    {
        this.ngZone.runOutsideAngular(() => {
            window.addEventListener(GlobalResizeOutsideAngularDirective.EVENT_RESIZE, (event:any)=> {

                clearTimeout(this.emitBuffer);
                this.emitBuffer = setTimeout(
                    () => {
                        this.resizeHandler.emit(event);
                    }
                    , GlobalResizeOutsideAngularDirective.EMIT_BUFFER_TIME);
            })
        });
    }

    ngOnDestroy(){
        if (this.emitBuffer) {
            clearTimeout(this.emitBuffer);
        }
    }

    //Alternatieve aanpak via hostlistener:
    /*
    @HostListener('window:resize', ['$event'])

    handleResize(event: Event) {
        this.resizeHandler.emit(event);
    }
    */
}
