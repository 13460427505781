import {
    Component, ElementRef, ChangeDetectionStrategy, OnDestroy, OnInit, ViewChild, ChangeDetectorRef, Input
} from '@angular/core';
import {ChangeableComponent} from "../../../shared/components/changeable/changeable.component";
import {MenuSearchService} from "./menu-search.service";
import {Router} from "@angular/router";
import {FormDataService} from "../../../shared/components/form/services/form-data.service";
import {Areaal} from "../../../shared/interfaces/areaal";
import {GlobalModel} from "../../../shared/services/state/global.model";
import {Subscription} from 'rxjs';
import {TranslateService} from "../../../shared/services/translate/translate.service";
import {MenuComponent} from '../menu.component';
import {ISearchResults,ISearchResultsMatchCategory,ISearchGroups} from './menu-search.interface';
import {StorageService} from "../../../shared/services/storage/storage.service";
import {MenuItem} from "../menu-item/menu-item";

@Component ({
    selector: 'menu-search-component',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: 'menu-search.component.html',
    styleUrls: ['./menu-search.component.scss']
})

export class MenuSearchComponent extends ChangeableComponent implements OnInit, OnDestroy{

    @ViewChild('searchInput', {static: false})searchInput:ReturnType<typeof Input>;

    private static readonly SEARCH_DELAY:number = 750;

    public showSearchResults:boolean = false;
    public searchResults:ISearchResults[] = [];
    private searchDelayTimer:ReturnType<typeof setTimeout> | null;
    private lastSearch:string = "";
    private defaultSearchGroup:ISearchGroups = null;
    private subCurrentAreaal:Subscription;
    public subAvailableSearchGroups:Subscription;
    private subIsMobileMode:Subscription;
    public availableSearchGroups:ISearchGroups[] = [];
    public activeSearchCategory:string = null;
    public showLoading:boolean = true;
    private closeSearchTimeout:ReturnType<typeof setTimeout> | null;
    public searchHasFinished:boolean = false;
    public showMobileSearch:boolean = false;
    public totalResultsFound:number = 0;
    private mobileMode:boolean = false;

    constructor(protected elementRef:ElementRef, protected searchService:MenuSearchService, protected cd:ChangeDetectorRef, protected router:Router, public model:GlobalModel, protected ts:TranslateService, public menuComponent:MenuComponent, protected storageService: StorageService) {
        super(elementRef);

        this.subCurrentAreaal = this.model.currentAreaal.subscribe((value:Areaal) => {
            this.searchResults = [];
            this.lastSearch = "";
            this.closeSearch(false);
            this.cd.markForCheck();
        });
        this.subAvailableSearchGroups = this.model.searchGroups.subscribe((value: ISearchGroups[]) => {
            this.availableSearchGroups = value;
        });
        this.subIsMobileMode = this.model.mobileMode.subscribe((value: boolean) => {
            this.mobileMode = value;
        });
    }

    ngOnInit(){
        this.defaultSearchGroup = {label: this.ts.translate('globalsearch.allSearchGroups'),searchGroup: ''};
        if(this.model.activeSearchGroup === null){
            this.model.activeSearchGroup = this.defaultSearchGroup;
            this.availableSearchGroups.unshift(this.defaultSearchGroup);
        }
        this.getActiveSearchCategory();
    }

    public closeSearch(delay:boolean):void{
        if(window.innerWidth > 767){ //Prevent close for mobile
            if(delay){
                this.closeSearchTimeout = setTimeout(() => {
                    this.showSearchResults = false;
                    this.cd.detectChanges();
                });
            } else {
                this.showSearchResults = false;
            }
        }
    }

    public preventCloseSearch():void{
        setTimeout(() => {
            if(this.closeSearchTimeout){
                clearTimeout(this.closeSearchTimeout);
            }
        });
    }

    public changeActiveSearchGroup(group:ISearchGroups){
        if(this.searchInput.nativeElement.value != ""){
            this.performSearch(group);
        }
        this.model.activeSearchGroup = group;
    }

    public changeActiveSearchCategory(category:string){
        this.activeSearchCategory = category;
        this.calculateTotalResultsFound();
        this.storageService.setValue(StorageService.KEY_GLOBAL_SEARCH_CATEGORY, category);
    }

    private getActiveSearchCategory():void{
        if (this.storageService.keyExists(StorageService.KEY_GLOBAL_SEARCH_CATEGORY)) {
            this.storageService.getStringValue(StorageService.KEY_GLOBAL_SEARCH_CATEGORY, value => {
                this.activeSearchCategory = value;
            });
        }
    }

    public handleSearchFieldKeyUp(event){
        //Reset pending timer
        if (this.searchDelayTimer){
            clearTimeout(this.searchDelayTimer);
            this.searchDelayTimer = null;
        }

        //Catch enter, perform search directly
        if (event.keyCode == 13){
            this.performSearch(this.model.activeSearchGroup);
        }else{
            //Create new timer
            this.searchDelayTimer = setTimeout(() => {
                this.searchDelayTimer = null;
                this.performSearch(this.model.activeSearchGroup);
            }, MenuSearchComponent.SEARCH_DELAY);
        }
    }

    public handleSearchFieldFocus(){
        this.showSearchResults = true;
    }

    public handleSearchFieldClick(event){
        event.stopPropagation();
    }

    public handleSelectResult(result:ISearchResultsMatchCategory){
        this.closeSearch(false);
        this.menuComponent.handleMenuAction();
        this.router.navigateByUrl(this.getResultHref(result)); // navigate to the href of the anchor
    }

    public showDropdownSearchResults():boolean{
        if(this.showSearchResults && this.searchResults && this.searchInput.nativeElement.value != ""){
            return true;
        } else {
            return false;
        }
    }

    public getResultHref(result):string{
        //Mobile needs state=2 here because the form must be opened.
        if(this.mobileMode){
            return result.url + "?state=2&origin=" + FormDataService.ORIGIN_SEARCH;
        } else {
            return result.url + "?origin=" + FormDataService.ORIGIN_SEARCH;
        }
    }

    private performSearch(activeSearchGroup:ISearchGroups){
        if (this.searchInput && this.searchInput.nativeElement.value != ""){
            if ((this.lastSearch != this.searchInput.nativeElement.value) || (this.model.activeSearchGroup != activeSearchGroup)){
                this.searchHasFinished = false;
                let keyword:string = this.searchInput.nativeElement.value; //this.cleanupEncodeKeyword(this.searchInput.nativeElement.value);
                keyword = keyword.trim();
                if(keyword == "") {
                    return;
                }

                this.totalResultsFound = 0;
                this.lastSearch = this.searchInput.nativeElement.value;
                this.showSearchResults = true;
                this.toggleLoading(true);
                this.cd.detectChanges();

                this.searchService.performSearch(keyword, activeSearchGroup, (results:any) => {
                    this.searchResults = results;

                    if(this.searchResults.length > 0){
                        this.getActiveSearchCategory();
                        if(this.searchResults.find(_x => _x.label == this.activeSearchCategory) === undefined){
                            this.activeSearchCategory = this.searchResults[0].label;
                        }
                    }

                    this.searchHasFinished = true;
                    this.showSearchResults = true;
                    this.calculateTotalResultsFound();
                    this.cd.detectChanges();
                }, () => {
                    //Fail, do nothing
                })
            }
        }
        this.toggleLoading(false);
    }

    private calculateTotalResultsFound():void{
        this.totalResultsFound = 0;
        if(this.searchResults.length > 0){
            this.searchResults.find(_x => _x.label == this.activeSearchCategory).results.forEach(_x => {
                _x.results.forEach(_y => {
                    this.totalResultsFound++;
                })
            })
        } else {
            this.totalResultsFound = 0;
        }
    }

    public toggleLoading(showLoading?:boolean): void{
        this.showLoading = showLoading;
    }

    public toggleMobileSearch():void {
        this.showMobileSearch = this.showMobileSearch ? false : true;
        if(this.showMobileSearch){
            setTimeout(()=>{
                this.searchInput.nativeElement.focus();
            })
        }
    }

    ngOnDestroy() {
        if (this.subCurrentAreaal){
            this.subCurrentAreaal.unsubscribe();
        }
        if(this.subAvailableSearchGroups){
            this.subAvailableSearchGroups.unsubscribe();
        }
        if(this.subIsMobileMode){
            this.subIsMobileMode.unsubscribe();
        }
    }
}
