import {Injectable} from "@angular/core";

@Injectable() export class ScrollSpyService {

    //Let op: Offset for scrollspy, NOT for auto-scrolling
    private static readonly SCROLL_OFFSET:number = 30;
    private static readonly BUTTONBAR_SCROLL_OFFSET:number = 65;

    //Defined for memory performance
    private elementHash:string = "";
    private currentScrollPosition:number = 0;

    //Use target scrollposition to determine which section to highlight
    public handleScroll(target:any, elements:any, hasFormTopButtonBar:boolean, objectSections:any):void
    {
        if (!target || !objectSections || objectSections.length <= 0){ return };

        //Remove any existing 'active' list group item(s)
        for (let section of objectSections){
            section.active = false;
        }

        //Get the current buttonbar height, or use the default if none is found
        let buttonbarHeight:number = $('.form-group.form-group--mutationButtons.button-bar-fixed').outerHeight() - 8 || 0;
        this.currentScrollPosition = (target.scrollTop + ScrollSpyService.SCROLL_OFFSET + buttonbarHeight);

        /*let buttonbarHeight:number = $('.form-group.form-group--mutationButtons.button-bar-fixed').outerHeight() - 8 || ScrollSpyService.BUTTONBAR_SCROLL_OFFSET;
        this.currentScrollPosition = (target.scrollTop + ScrollSpyService.SCROLL_OFFSET + (hasFormTopButtonBar?buttonbarHeight:0));*/

        //Activate the first item from the bottom, but only if it has a matching section
        for (let i = elements.length - 1; i >= 0; i--) {
            if (this.currentScrollPosition > elements[i].offsetTop) {
                if (this.didActivateMatchingSection(elements[i], objectSections)){
                    return;
                }
            }
        }

        //No match found, activate first item by default
        objectSections[0].active = true;
    }

    //Search for a section matching the element and activate it, return true when successfully activated
    private didActivateMatchingSection(element:any, objectSections:any):boolean
    {
        this.elementHash = element.id;
        for (let i = 0; i < objectSections.length; i++){
            if (objectSections[i].hash == this.elementHash){
                objectSections[i].active = true;
                if (objectSections[i].active && objectSections[i].parentSection){
                    objectSections[i].parentSection.active = true;
                }
                return true
            }
        }
        return false;
    }
}
