<login-header (flippingLanguage)="handleFlippingLanguage($event)"></login-header>
<div class="login-background">
  <div *ngIf="isLoginVisible(_user); else loading_content" class="login-container">
    <div class="login-container-inner d-flex align-items-center justify-content-between">
      <div class="login-notify-outer">
        <login-notify></login-notify>
      </div>
      <div class="loading-form-container"></div>
      <div class="login-container-right d-flex align-items-center">
        <div class="login-form">
          <form [formGroup]="passwordForm" novalidate autocomplete="off" (globalKeydownOutsideAngular)="handleKeyDown($event)">
            <h2 class="password-forget-title">{{formTitle}}</h2>
            <div class="password-forget-subtext">{{formSubTitle}}</div>
            <div class="input-group position-relative">
              <i class="material-icons">lock</i>
              <input class="form-control p-0" formControlName="newPassword" placeholder="{{formNewPassword}}" #newPassword type="password" autocomplete="off"/>
              <i class="material-icons cursor-pointer" style="position: absolute; right: 0; z-index: 10" (click)="handleShowGenerator()">vpn_key</i>
            </div>
            <!-- Generate password popup -->
            <div *ngIf="generatorService.showGenerator" class="password-generate-container">
              <div class="result-container">
                <span id="result">{{'generatepass.result' | translate}}</span>
                <lumi-button (onClick)="generatorService.handleClipboard()"
                             [rank]="'secondary'"
                             [icon]="'content_paste'">
                </lumi-button>
              </div>
              <div class="settings">
                <div class="setting">
                  <label>{{'generatepass.length' | translate}}: {{generatorService.passwordLength}}</label>
                  <input (input)="handleChangePasswordLength($event)" type="range" id="length" min="8" max="24" value="10"/>
                </div>
                <!-- Settings for generator, can be turned on/off so you can choose to use them in the generator -->
                <!--                                    <div class="setting">-->
                <!--                                        <label>{{'generatepass.uppercase' | translate}}</label>-->
                <!--                                        <input type="checkbox" id="uppercase" checked disabled/>-->
                <!--                                    </div>-->
                <!--                                    <div class="setting">-->
                <!--                                        <label>{{'generatepass.lowercase' | translate}}</label>-->
                <!--                                        <input type="checkbox" id="lowercase" checked disabled/>-->
                <!--                                    </div>-->
                <div class="setting">
                  <label>{{'generatepass.numbers' | translate}}</label>
                  <input (change)="handleClickNumbers()" type="checkbox" id="numbers" [checked]="generatorService.numbersChecked" [disabled]="generatorService.numbersDisabled"/>
                </div>
                <div class="setting">
                  <label>{{'generatepass.symbols' | translate}}</label>
                  <input (change)="handleClickSymbols()" type="checkbox" id="symbols" [checked]="generatorService.symbolsChecked" [disabled]="generatorService.symbolsDisabled"/>
                </div>
              </div>
              <lumi-button (onClick)="generatorService.handleGeneratePassword()"
                           [rank]="'secondary'"
                           [fullWidth]="true"
                           [label]="'generatepass.button' | translate">
              </lumi-button>
            </div>
            <!--  End of password generator -->
            <div class="input-group">
              <i class="material-icons">lock</i>
              <input class="form-control p-0" formControlName="repeatPassword" placeholder="{{formRepeatPassword}}" #repeatPassword type="password" autocomplete="off"/>
            </div>
            <div *ngIf="failure" class="animated fadeIn alert alert-{{ failure.severity }} login-alert" role="alert">
              <strong>{{ failure.title }}</strong> {{failure.message}}
            </div>
            <div class="login-btn-container">
              <lumi-button (onClick)="handleClickSend($event)"
                           [disabled]="disableSubmitButton"
                           [rank]="'secondary'"
                           [size]="'large'"
                           [fullWidth]="true"
                           [label]="'Log in' | translate">
              </lumi-button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <login-footer></login-footer>
  </div>
  <ng-template #loading_content>
    <div class="loading-filler"></div>
    <div class="loading-form-container d-flex">
      <div class="px-5 py-3 loading-form w-100 d-flex justify-content-center align-items-center flex-column" style="max-width: 30rem;">
        <img class="mx-auto img-fluid d-flex pb-4" style="display: none" src="{{getLogoPath()}}">
        <div class="loader"></div>
      </div>
    </div>
  </ng-template>
</div>