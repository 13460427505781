<div class="form-group form-group--{{name}} {{getClassNames()}}" id="{{name}}" [hidden]="!isVisible()">
    <div style="display: inherit; width: fit-content; width: -moz-fit-content" class="{{expanded?'':'d-none'}}">
        <ng-container *ngFor="let childConfig of config.children">
            <form-button (onComponentEvent)="handleComponentEvent($event)"
                         [group]="form"
                         [config]="childConfig">
            </form-button>
        </ng-container>
    </div>
</div>
