<login-header (flippingLanguage)="handleFlippingLanguage($event)" [beforeLogin]="isLoginVisible(_user)"></login-header>
<div class="login-background">
  <div *ngIf="isLoginVisible(_user); else loading_content" class="login-container">

    <div class="snowflakes" aria-hidden="true" *ngIf="model.isXmasMode()">
      <div class="snowflake">
        <div class="inner">❅</div>
      </div>
      <div class="snowflake">
        <div class="inner">❅</div>
      </div>
      <div class="snowflake">
        <div class="inner">❅</div>
      </div>
      <div class="snowflake">
        <div class="inner">❅</div>
      </div>
      <div class="snowflake">
        <div class="inner">❅</div>
      </div>
      <div class="snowflake">
        <div class="inner">❅</div>
      </div>
      <div class="snowflake">
        <div class="inner">❅</div>
      </div>
      <div class="snowflake">
        <div class="inner">❅</div>
      </div>
      <div class="snowflake">
        <div class="inner">❅</div>
      </div>
      <div class="snowflake">
        <div class="inner">❅</div>
      </div>
      <div class="snowflake">
        <div class="inner">❅</div>
      </div>
      <div class="snowflake">
        <div class="inner">❅</div>
      </div>
    </div>

    <div class="login-container-inner d-flex align-items-center justify-content-between">
      <div class="login-notify-outer">
        <login-notify></login-notify>
      </div>
      <div class="loading-form-container"></div>
      <div class="login-container-right d-flex align-items-center">
        <div class="login-form">
          <form [formGroup]="loginForm" novalidate (globalKeydownOutsideAngular)="handleKeyDown($event)">
            <h2 class="login-title">{{'Login' | translate}}</h2>
            <div class="input-group">
              <i class="material-icons">face</i>
              <input class="form-control" type="text" formControlName="username" placeholder="{{'Gebruikersnaam' | translate}}" #username/>
            </div>
            <div class="input-group">
              <i class="material-icons">lock</i>
              <input class="form-control" (keydown)="handlePasswordKeyDown($event)" (blur)="handlePasswordBlur($event)" formControlName="password" type="password" placeholder="{{'Wachtwoord' | translate}}" #password/>
            </div>
            <div *ngIf="failure" class="animated fadeIn alert alert-{{ failure.severity }} login-alert" role="alert">
              <strong>{{ failure.title }}</strong> {{failure.message}}
            </div>
            <div class="login-btn-container">
              <lumi-button (onClick)="handleClickLogin($event)"
                           [disabled]="disableSubmitButton"
                           [rank]="'secondary'"
                           [size]="'large'"
                           [fullWidth]="true"
                           [label]="'Log in' | translate">
              </lumi-button>
            </div>
            <div class="login-pass-forget"><a (click)="handleForgotPass($event)">{{'Wachtwoord vergeten?' | translate}}</a></div>
          </form>
        </div>
      </div>
      <login-footer></login-footer>
    </div>
  </div>

  <ng-template #loading_content>
    <div class="loading-filler"></div>
    <div class="loading-form-container d-flex">
      <div class="px-5 py-3 loading-form w-100 d-flex justify-content-center align-items-center flex-column" style="max-width: 30rem;">
        <img class="mx-auto img-fluid d-flex pb-4" style="display: none" src="{{getLogoPath()}}">
        <div class="loader"></div>
      </div>
    </div>
  </ng-template>
</div>