import {Component} from "@angular/core";
import {UntypedFormGroup} from "@angular/forms";
import {Attachment} from '../form-attachment/form-attachment.interface';
import {GlobalAlertService} from '../../../../../wrapper/global-alert/global-alert.service';
import {ListItem} from '../../../commonUI/list/complex-list-item/complex-list-item.interface';

@Component({
    selector: 'form-image',
    template: `
    <div class="dynamic-field form-image" [formGroup]="group" (click)="handleOpenAttachment(getImage())" >
        <img *ngIf="hasImage()" src="{{getImage()}}" class="img-fluid">
    </div>
    
    `
})


export class FormImageComponent
{
    config:any;
    group:UntypedFormGroup;

    constructor(        private globalAlertService: GlobalAlertService
    ) {}

    hasImage() {
        return (this.config.attr && this.config.attr.image && this.config.attr.image != "");
    }

    getImage() {
        return this.config.attr.image;
    }
    handleOpenAttachment(imageUrl:string) {
        let attachmentImageList:Attachment[] = [];
        let fileName: string = imageUrl.split('/').pop().split("?")[0];
        let listItem: ListItem = { title: fileName } as ListItem;
        const attachment = { id: -1, getUrl: imageUrl, listItem: listItem, fileName:  fileName, imageComponent: true } as Attachment ;
        attachmentImageList.push(attachment)

        if(attachmentImageList.length > 0){
            this.globalAlertService.addPopupViewImagesCarousel({
                attachmentList: attachmentImageList,
                activeAttachmentId: -1
            }, () => {
            }, () => {
            });
        }
    }
}
