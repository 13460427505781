<ng-container *ngFor="let preparation of preparations">
    <complex-list-item class="{{preparation.isNew?'animated fadeIn':''}}"
                       [style.color]="preparation.isDeleted?AppSettings.getColor('$selection-gray'):''"
                       [iconColor]="getColorByCode(preparation.code)"
                       [item]="preparation.listItem"
                       [readonly]="disabled()"
                       (onMenuItemClick)="onPreparationAction($event, preparation)">
        <div style="margin-top: 20px">
            <key-value [key]="'preparation.status' | translate">
                <div class="form-log-message align-items-center d-flex flex-row pt-1 w-100" style="margin-top: -5px">
                    <div class="form-link-anchor {{preparation.status === 2 || !preparation.editUrl?'disabled':''}} pr-2"
                         (click)="handleClickUpdatePreparation($event, preparation)">{{preparation.status_name}}</div>
                </div>
            </key-value>
        </div>
        <key-value [key]="'preparation.completiondate' | translate"
                   [truncateKey]="false"
                   [value]="preparation.completion_date">
        </key-value>
        <key-value  [key]="'preparation.message_contractor' | translate"
                    [value]="preparation.message_contractor || '-'">
        </key-value>
        <ng-container *ngIf="preparation.message_client !== null">
        <key-value  [key]="'preparation.message_client' | translate"
                    [value]="preparation.message_client || '-'">
        </key-value>
        </ng-container>

        <div class="form-attachment-loader {{preparation.isLoading?'':'d-none'}}">
            <div class="progress">
                <div class="indeterminate"></div>
            </div>
        </div>
    </complex-list-item>
</ng-container>
<div class="form-log-container">
    <div *ngIf="!preparations || preparations.length <= 0">
        {{'preparation.nopreparations' | translate}}
    </div>
</div>
