import {MapCoreV2Component} from "../map-core-V2.component"
import {IDrawingLayer, IGeoJSON, IMapItem, mapItemTypes} from "./map-manager.interface";
import {take} from "rxjs";
import {LoggerService} from "../../../services/logger/logger.service";


export default class MapDataManagerService {
    constructor(private mapCoreV2:MapCoreV2Component, protected logger:LoggerService){
    }

    public saveDrawing(update:boolean){
        this.logger.log('[Grid][MapDataManagerService] ' + 'Save drawing, update: '+update)

        //Get the map item from the drawing layer (new line) or the selected polyline (update existing line)
        let mapItemToSave = null;
        if(this.mapCoreV2.mapLayerManagerService.drawingLayer !== null){
            mapItemToSave = this.mapCoreV2.mapLayerManagerService.drawingLayer
        } else if(this.mapCoreV2.selectedMapItem.googlePolyline !== null){
            mapItemToSave = this.mapCoreV2.selectedMapItem.googlePolyline
        }
        if(mapItemToSave !== null){
            let lat = null;
            let lng = null;
            let geometry = null;
            let dropDownData:any[] = [];
            let newUrl = '';
            let createUrl = '';

            this.logger.log('[Grid][MapDataManagerService] ' + 'Save drawing type ',mapItemToSave)
            if(mapItemToSave.mapItemType === mapItemTypes.POLYLINE){
                mapItemToSave.mapItem = mapItemToSave.mapItem as google.maps.Polyline;
                //Set the geometry
                geometry = {
                    type:'LineString',
                    coordinates:mapItemToSave.mapItem.getPath().getArray()
                };

                //Specify the available option for selecting a type
                dropDownData.push({
                    value: 'cable',
                    label: this.mapCoreV2.ts.translate('objecttype.cable'),
                },{
                    value: 'conduit',
                    label: this.mapCoreV2.ts.translate('Conduit'),
                },{
                    value: 'wire',
                    label: this.mapCoreV2.ts.translate('Wire'),
                })

                //If there is a style and layer selected when builing the line (right now this is mandatory but might change in the future), add this in the 'new' url
                newUrl = (this.mapCoreV2.mapHelperManagerService.getSelectedStyleId() !== null && this.mapCoreV2.mapHelperManagerService.getSelectedLayerId() !== null && mapItemToSave.mapItemType === mapItemTypes.POLYLINE)
                    ? 'mapitem/form/drawing/new/' + this.mapCoreV2.mapHelperManagerService.getSelectedLayerId() + '/' + this.mapCoreV2.mapHelperManagerService.getSelectedStyleId() + '/'
                    : 'mapitem/form/drawing/new/'
                createUrl = 'mapitem/form/drawing/create/';
            } else if(mapItemToSave.mapItemType === mapItemTypes.ANNOTATION){
                mapItemToSave.mapItem = mapItemToSave.mapItem as google.maps.Polyline;
                //Set the geometry
                geometry = {
                    type:'LineString',
                    coordinates:mapItemToSave.mapItem.getPath().getArray()
                };

                //Specify the available option for selecting a type
                dropDownData.push({
                    value: 'annotation',
                    label: this.mapCoreV2.ts.translate('Annotation'),
                })

                newUrl = 'mapitem/form/drawing/new/'
                createUrl = 'mapitem/form/drawing/create/';
            } else if(mapItemToSave.mapItemType === mapItemTypes.JOINT){
                mapItemToSave.mapItem = mapItemToSave.mapItem as google.maps.Marker;
                //Set the geometry
                geometry = {
                    type:'Point',
                    coordinates: {
                        latitude: mapItemToSave.mapItem.getPosition().lat(),
                        longitude: mapItemToSave.mapItem.getPosition().lng()
                    }
                };

                //Specify the available option for selecting a type
                dropDownData.push({
                    value: 'joint',
                    label: this.mapCoreV2.ts.translate('Joint'),
                })
                newUrl = 'mapitem/form/drawing/new/'
                createUrl = 'mapitem/form/drawing/create/';
            } else if(mapItemToSave.mapItemType === mapItemTypes.MARKER){
                mapItemToSave.mapItem = mapItemToSave.mapItem as google.maps.Marker;
                //Set the marker lat lng position
                lat = mapItemToSave.mapItem.getPosition().lat();
                lng = mapItemToSave.mapItem.getPosition().lng();

                //Specify the available option for selecting a type
                dropDownData.push({
                    value: 'mast',
                    label: this.mapCoreV2.ts.translate('Mast'),
                },{
                    value: 'schakelkast',
                    label: this.mapCoreV2.ts.translate('Schakelkast')
                })
                if (this.mapCoreV2.auth.allowCreateGenericAsset()) {
                    dropDownData.push({
                        value: 'genericAsset',
                        label: this.mapCoreV2.ts.translate('Overig')
                    });
                }
                newUrl = 'mapitem/form/new/';
                createUrl = 'mapitem/form/create/';
            }

            if(!update && newUrl != ''){
                //Adding a new item
                this.logger.log('[Grid][MapDataManagerService] ' + 'Save drawing add new item')
                //Show popup form for creating an asset
                this.mapCoreV2.globalAlertService.addPopupCreateDrawingItem(
                    this.mapCoreV2.ts.translate('Item aanmaken'),
                    '',
                    newUrl,
                    createUrl,
                    dropDownData,
                    geometry,
                    lat,
                    lng,
                    (buttonCode, response) => {
                        //Get new item from server and add to map
                        this.mapCoreV2.mapCoreV2Service.getMapItemByBaseobjectId(response.baseObjectId).subscribe((formPostResult) => {
                            this.mapCoreV2.appendCreatedMapItem(formPostResult)
                            // this.mapCoreV2.mapLayerManagerService.clearDrawingLayer()
                            this.mapCoreV2.mapDrawingManagerService.resetDrawing();
                        });
                        //Get new item from server and add to table
                        this.mapCoreV2.mapCoreV2Service.getTableItemByBaseobjectId(response.baseObjectId).subscribe((formPostResult) => {
                            this.mapCoreV2.appendCreatedMapItemToTable(formPostResult)
                        });
                    }, () => {
                        // this.mapCoreV2.mapLayerManagerService.clearDrawingLayer()
                        this.mapCoreV2.mapDrawingManagerService.resetDrawing();
                    }
                );
            } else {
                //Updating a map item. Can only be a linestring.
                const baseObjectId = this.mapCoreV2.selectedMapItem.getBaseObjectId()
                this.logger.log('[Grid][MapDataManagerService] ' + 'Save drawing edit baseObjectId: '+baseObjectId)
                const path = [];
                this.mapCoreV2.selectedMapItem.getPath().map(_path => {
                    path.push([_path.lat(), _path.lng()])
                })
                const geometry: IGeoJSON = {
                    type: 'LineString',
                    coordinates: path
                }
                //Update so change path and send to V2 service to save
                let saveEditReturn = this.mapCoreV2.mapCoreV2Service.savePolylineEdit(baseObjectId, geometry)
            }
        } else {
            this.logger.log('[Grid][MapDataManagerService] ' + 'Save drawing type is null. Cannot save')
        }
    }
}
