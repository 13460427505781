
import {
    AfterViewInit,
    ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, ViewChild,
} from '@angular/core';

import {GlobalAlertService} from '../global-alert.service';
import {ButtonCode, GlobalPopup} from '../global-popup';
import {HTTPError} from '../../../shared/services/http/http-error';
import {RequestFailure} from '../../../shared/services/http/request-failure';
import {FormDataService} from '../../../shared/components/form/services/form-data.service';
import {GlobalModel} from '../../../shared/services/state/global.model';
import {FormComponent} from '../../../shared/components/form/containers/form/form.component';
import {AuthorizationService} from '../../../shared/services/authorization/authorization.service';
import {TranslateService} from '../../../shared/services/translate/translate.service';
import {DefaultPopupWrapperComponent} from './default-popup-wrapper/default-popup-wrapper.component';
import {AbstractBasePopupComponent} from './abstract-base-popup.component';
import {TooltipService} from '../../../shared/services/tooltip/tooltip.service';
import { FormEvent } from '../../../shared/components/form/containers/form/form.interface';
import {LoggerService} from "../../../shared/services/logger/logger.service";

@Component({
    selector: 'global-popup-add-external-malfunction-report-to-workorder-component',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <default-popup-wrapper-component #wrapper [componentRef]="this"
                                         [disableButtonsOverride]="disableButtonsOverride" [globalPopup]="globalPopup"
                                         [showLoader]="isLoading" (onClose)="closePopup($event)">
            <ng-container *ngIf="formData && config">
                <form-component #form
                                [config]="config"
                                [name]="getFormName()"
                                [validationConstraints]="validationConstraints"
                                [invalidControlsErrors]="invalidControlsErrors"
                                [readOnly]="readOnly"
                                [formIsSubmitted]="formIsSubmitted"
                                (onComponentEvent)="handleComponentEvent($event)">
                </form-component>
            </ng-container>
        </default-popup-wrapper-component>
    `,
})
export class GlobalPopUpAddExternalMalfunctionReportToWorkorderComponent extends AbstractBasePopupComponent implements AfterViewInit {
    private static readonly FORM_URL: string = 'report-malfunction/external-malfunction-report/add-external-malfunction-report-to-workorder/';

    @Input() itemId: number = 0;
    @ViewChild('form', {static: false}) form: any;
    @ViewChild('wrapper', {static: false}) wrapper: DefaultPopupWrapperComponent;

    disableButtonsOverride: any = (button: any) => {
        return (this.config && this.config.disabled && (button.code !== ButtonCode.ANNULEREN)) ||
            (this.form && this.form.form && this.form.form.status === 'INVALID' && button.code !== ButtonCode.ANNULEREN);
    };

    public constructor(protected cd: ChangeDetectorRef, protected globalAlertService: GlobalAlertService,
                       private auth: AuthorizationService, protected formDataService: FormDataService,
                       protected elementRef: ElementRef, private model: GlobalModel,
                       protected ts: TranslateService, protected tooltipService: TooltipService, protected logger:LoggerService) {
        super(cd, globalAlertService, elementRef, ts, tooltipService, logger);
    }

    ngAfterViewInit() {

        // Retrieve map item id from the popup data
        this.itemId = this.globalPopup.data.mapItemId;

        let popupData: any = this.globalPopup.data;

        this.model.formLocationComponentPosition = null;


        this.submitButton = this.getSubmitButton();

        this.requestFormData();
    }

    handlePopupAction(event: MouseEvent, alert: GlobalPopup, button: any): void {
        // callback for submit button comes from this form, not from outside this component
        if (button.code === ButtonCode.EXTERNAL_MALFUNCTION_REPORT_PAIR_NEW) {
            this.form.handleComponentEvent({event: FormEvent.EXTERNAL_MALFUNCTION_REPORT_PAIR_NEW_WORKORDER, data: {}});
            // this.form.submitForm();
        } else if (button.code === ButtonCode.EXTERNAL_MALFUNCTION_REPORT_PAIR_EXISTING) {
            this.form.handleComponentEvent({event: FormEvent.EXTERNAL_MALFUNCTION_REPORT_PAIR_EXISTING_WORKORDER, data: {}});
            // this.form.submitForm();
        }
        else {
            // Hide alert and perform callback of button
            this.onPopupAction.emit({event: event, alert: alert, button: button});
        }
    }

    handleComponentEvent(eventData: any): void {

        if (eventData.event === FormEvent.EXTERNAL_MALFUNCTION_REPORT_PAIR_EXISTING_WORKORDER) {
            this.submitFormData(eventData.data.formData, false);
        } else if (eventData.event === FormEvent.EXTERNAL_MALFUNCTION_REPORT_PAIR_NEW_WORKORDER) {
            this.submitFormData(eventData.data.formData, true);
        }
    }

    private submitFormData(form: any, new_workorder: boolean): void {
        if (this.itemId === 0) {
            this.logger.log('[GlobalAlertMoveItemComponent] ' + 'ERROR: trying to submit the form, but it is not linked to an item');
            return;
        }

        this.handleSubmitForm();
        let url = GlobalPopUpAddExternalMalfunctionReportToWorkorderComponent.FORM_URL + 'update/' + this.itemId + '/pair';

        if (new_workorder) {
            url = GlobalPopUpAddExternalMalfunctionReportToWorkorderComponent.FORM_URL + 'update/' + this.itemId + '/new';
        }
        this.formDataService.setFormData(form, this.getFormName(), url,
            (formData: any) => {
                // this.formData = formData;
                this.handleSubmitResponse();
                // Hide alert and perform success callback via submit button
                this.onPopupAction.emit({
                    event: null,
                    alert: this.globalPopup,
                    button: this.submitButton,
                    data: formData,
                });
            },
            (failure: RequestFailure) => {
                this.handleSubmitResponse(failure.formErrors);
            },
            (error: HTTPError) => {
                this.handleSubmitResponse(null, true);
            },
        );

    }

    closePopup(event: any): void {
        this.doDefaultCloseHandling(event, false);
    }

    public requestFormData(): void {
        this.formDataService.getFormData(GlobalPopUpAddExternalMalfunctionReportToWorkorderComponent.FORM_URL + 'get/' + this.itemId,
            (formData: any) => {

                // TODO: pas hier de latlng van de popup.data toe op de formData, als beschikbaar
                // En update dus de kaart en de locatie enzo

                // setTimeout(() => {
                this.formData = formData;
                this.isLoading = false;


                this.cd.detectChanges();
                this.wrapper.runChangeDetection();
                // });
            },
            () => {
                this.closePopup(null);
            },
            () => {
                this.closePopup(null);
            },
        );
    }
}
