/**
 * Created by Christiaan on 01/03/2017.
 */
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    OnDestroy,
    OnInit, QueryList, ViewChildren,
} from '@angular/core';
import {GlobalModel} from '../../shared/services/state/global.model';
import {GlobalAlert} from './global-alert';
import {GlobalAlertService} from './global-alert.service';
import {ChangeableComponent} from '../../shared/components/changeable/changeable.component';
import {Subscription} from 'rxjs';
import {GlobalPopup, GlobalPopupType, PopupActionEvent} from './global-popup';
import {AuthorizationService} from '../../shared/services/authorization/authorization.service';
import {TranslateService} from '../../shared/services/translate/translate.service';
import {LoggerService} from "../../shared/services/logger/logger.service";

@Component({
    selector: 'global-alert-component',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `

        <!--Add all alerts that need to show as fullscreen popup-->
        <!-- The overflow-y auto is moved up one div. This is a fix for the date picker calendar getting cut off on some popups-->
        <ng-container *ngFor="let popup of _globalPopups">
            <div #popupBackground1 (mousedown)="handleClickPopupBackground($event,popup)"
                 class="d-flex global-popup justify-content-center align-items-center" style="overflow-y: auto;">
                <div #popupBackground2 class="w-100"
                     style="margin:auto; max-height: 100%;  display:flex; justify-content:center; overflow-y: visible; align-items: baseline">
                    <div #popupBackground3 class="col-12 col-lg-8 no-mobile-padding-x pt-3"
                         style="max-width:900px; display:flex; flex-direction:column; justify-content:center"
                         [ngSwitch]="popup.type">
                        <global-popup-basic-component *ngSwitchCase="GLOBAL_POPUP_TYPE.BASIC"
                                                      (onPopupAction)="handlePopupAction($event)"
                                                      [globalPopup]="popup"></global-popup-basic-component>
                        <global-popup-create-areaal-component
                                *ngSwitchCase="GLOBAL_POPUP_TYPE.CREATE_AREAAL"
                                (onPopupAction)="handlePopupAction($event)"
                                [globalPopup]="popup">
                        </global-popup-create-areaal-component>
                        <global-popup-create-mapserver-component
                                *ngSwitchCase="GLOBAL_POPUP_TYPE.CREATE_MAP_SERVER"
                                (onPopupAction)="handlePopupAction($event)"
                                [globalPopup]="popup">
                        </global-popup-create-mapserver-component>
                        <global-popup-timer-component *ngSwitchCase="GLOBAL_POPUP_TYPE.TIMER"
                                                      (onPopupAction)="handlePopupAction($event)"
                                                      [globalPopup]="popup"></global-popup-timer-component>
                        <global-popup-basic-input-component *ngSwitchCase="GLOBAL_POPUP_TYPE.BASIC_INPUT"
                                                            (onPopupAction)="handlePopupAction($event)"
                                                            [globalPopup]="popup"></global-popup-basic-input-component>
                        <global-popup-basic-form-component *ngSwitchCase="GLOBAL_POPUP_TYPE.BASIC_FORM"
                                                           (onPopupAction)="handlePopupAction($event)"
                                                           [globalPopup]="popup">
                        </global-popup-basic-form-component>
                        <global-popup-move-item-component *ngSwitchCase="GLOBAL_POPUP_TYPE.MOVE_ITEM"
                                                          (onPopupAction)="handlePopupAction($event)"
                                                          [globalPopup]="popup"></global-popup-move-item-component>
                        <global-popup-create-pv-storing-component *ngSwitchCase="GLOBAL_POPUP_TYPE.CREATE_PV_STORING"
                                                                  (onPopupAction)="handlePopupAction($event)"
                                                                  [globalPopup]="popup"></global-popup-create-pv-storing-component>
                        <global-popup-create-msb-melding-component *ngSwitchCase="GLOBAL_POPUP_TYPE.CREATE_MSB_MELDING"
                                                                  (onPopupAction)="handlePopupAction($event)"
                                                                  [globalPopup]="popup"></global-popup-create-msb-melding-component>
                        <global-popup-create-workorder-from-external-malfunction-report-component *ngSwitchCase="GLOBAL_POPUP_TYPE.CREATE_WORKORDER_FROM_EXTERNAL_MALFUNCTION_REPORT"
                                                                   (onPopupAction)="handlePopupAction($event)"
                                                                   [globalPopup]="popup"></global-popup-create-workorder-from-external-malfunction-report-component>
                        <global-popup-external-malfunction-report-change-state-component *ngSwitchCase="GLOBAL_POPUP_TYPE.EXTERNAL_MALFUNCTION_REPORT_CHANGE_STATE"
                                                                         (onPopupAction)="handlePopupAction($event)"
                                                                         [globalPopup]="popup"></global-popup-external-malfunction-report-change-state-component>
                        
                        <global-popup-add-msb-melding-to-workorder-component *ngSwitchCase="GLOBAL_POPUP_TYPE.ADD_MSB_MELDING_TO_WORKORDER"
                                                                   (onPopupAction)="handlePopupAction($event)"
                                                                   [globalPopup]="popup"></global-popup-add-msb-melding-to-workorder-component>
                        <global-popup-werkbon-suggestion-create-new-workorder *ngSwitchCase="GLOBAL_POPUP_TYPE.WERKBON_SUGGESTION_CREATE_NEW_WORKORDER"
                                                                   (onPopupAction)="handlePopupAction($event)"
                                                                   [globalPopup]="popup"></global-popup-werkbon-suggestion-create-new-workorder>
                        <global-popup-add-external-malfunction-report-to-workorder-component *ngSwitchCase="GLOBAL_POPUP_TYPE.ADD_EXTERNAL_MALFUNCTION_REPORT_TO_WORKORDER"
                                                                             (onPopupAction)="handlePopupAction($event)"
                                                                             [globalPopup]="popup"></global-popup-add-external-malfunction-report-to-workorder-component>

                        <global-popup-create-additional-msb-melding-component *ngSwitchCase="GLOBAL_POPUP_TYPE.CREATE_ADDITIONAL_MSB_MELDING"
                                                                             (onPopupAction)="handlePopupAction($event)"
                                                                             [globalPopup]="popup"></global-popup-create-additional-msb-melding-component>
                       
                        <global-popup-msb-melding-change-state-component *ngSwitchCase="GLOBAL_POPUP_TYPE.MSB_MELDING_CHANGE_STATE"
                                                                   (onPopupAction)="handlePopupAction($event)"
                                                                   [globalPopup]="popup"></global-popup-msb-melding-change-state-component>
                        <global-popup-create-dropdown-component *ngSwitchCase="GLOBAL_POPUP_TYPE.CREATE_DROPDOWN"
                                                                (onPopupAction)="handlePopupAction($event)"
                                                                [globalPopup]="popup"></global-popup-create-dropdown-component>
                        <global-popup-create-masterdata-component *ngSwitchCase="GLOBAL_POPUP_TYPE.CREATE_MASTERDATA"
                                                                  (onPopupAction)="handlePopupAction($event)"
                                                                  [globalPopup]="popup"></global-popup-create-masterdata-component>
                        <global-popup-edit-dropdown-component *ngSwitchCase="GLOBAL_POPUP_TYPE.EDIT_DROPDOWN"
                                                              (onPopupAction)="handlePopupAction($event)"
                                                              [globalPopup]="popup"></global-popup-edit-dropdown-component>
                        <global-popup-merge-dropdown-component *ngSwitchCase="GLOBAL_POPUP_TYPE.MERGE_DROPDOWN"
                                                               (onPopupAction)="handlePopupAction($event)"
                                                               [globalPopup]="popup"></global-popup-merge-dropdown-component>
                        <global-popup-create-baseobject-component *ngSwitchCase="GLOBAL_POPUP_TYPE.CREATE_BASEOBJECT"
                                                                  (onPopupAction)="handlePopupAction($event)"
                                                                  [globalPopup]="popup"></global-popup-create-baseobject-component>
                        <global-popup-delete-baseobject-component *ngSwitchCase="GLOBAL_POPUP_TYPE.DELETE_BASEOBJECT"
                                                                  (onPopupAction)="handlePopupAction($event)"
                                                                  [globalPopup]="popup"></global-popup-delete-baseobject-component>
                        <global-popup-copy-baseobject-component *ngSwitchCase="GLOBAL_POPUP_TYPE.COPY_BASEOBJECT"
                                                                (onPopupAction)="handlePopupAction($event)"
                                                                [globalPopup]="popup"></global-popup-copy-baseobject-component>
                        <global-popup-create-mapitem-component *ngSwitchCase="GLOBAL_POPUP_TYPE.CREATE_MAPITEM"
                                                               (onPopupAction)="handlePopupAction($event)"
                                                               [globalPopup]="popup"></global-popup-create-mapitem-component>
                        <global-popup-create-drawingitem-component *ngSwitchCase="GLOBAL_POPUP_TYPE.CREATE_DRAWINGITEM"
                                                               (onPopupAction)="handlePopupAction($event)"
                                                               [globalPopup]="popup"></global-popup-create-drawingitem-component>
                        <global-popup-browser-warning-component *ngSwitchCase="GLOBAL_POPUP_TYPE.WARNING"
                                                                (onPopupAction)="handlePopupAction($event)"
                                                                [globalPopup]="popup"></global-popup-browser-warning-component>
                        <global-popup-map-legend-component *ngSwitchCase="GLOBAL_POPUP_TYPE.MAP_LEGEND"
                                                           (onPopupAction)="handlePopupAction($event)"
                                                           [globalPopup]="popup"></global-popup-map-legend-component>
                        <global-popup-about-us-component *ngSwitchCase="GLOBAL_POPUP_TYPE.ABOUT_US"
                                                         (onPopupAction)="handlePopupAction($event)"
                                                         [globalPopup]="popup"></global-popup-about-us-component>
                        <global-popup-map-option-component *ngSwitchCase="GLOBAL_POPUP_TYPE.MAP_OPTIONS"
                                                           (onPopupAction)="handlePopupAction($event)"
                                                           [globalPopup]="popup"></global-popup-map-option-component>
                        <global-popup-table-options-component *ngSwitchCase="GLOBAL_POPUP_TYPE.TABLE_OPTIONS"
                                                              (onPopupAction)="handlePopupAction($event)"
                                                              [globalPopup]="popup"></global-popup-table-options-component>
                        <global-popup-create-exception-component *ngSwitchCase="GLOBAL_POPUP_TYPE.CREATE_EXCEPTION"
                                                                 (onPopupAction)="handlePopupAction($event)"
                                                                 [globalPopup]="popup"></global-popup-create-exception-component>
                        <global-popup-edit-scheme-component *ngSwitchCase="GLOBAL_POPUP_TYPE.EDIT_SCHEME"
                                                            (onPopupAction)="handlePopupAction($event)"
                                                            [globalPopup]="popup"></global-popup-edit-scheme-component>
                        <global-popup-set-override-component *ngSwitchCase="GLOBAL_POPUP_TYPE.SET_OVERRIDE"
                                                             (onPopupAction)="handlePopupAction($event)"
                                                             [globalPopup]="popup"></global-popup-set-override-component>
                        <global-popup-add-table-filter-component *ngSwitchCase="GLOBAL_POPUP_TYPE.ADD_TABLE_FILTER"
                                                                 (onPopupAction)="handlePopupAction($event)"
                                                                 [globalPopup]="popup"></global-popup-add-table-filter-component>
                        <global-popup-view-attachment-component *ngSwitchCase="GLOBAL_POPUP_TYPE.VIEW_ATTACHMENT"
                                                                (onPopupAction)="handlePopupAction($event)"
                                                                [globalPopup]="popup"></global-popup-view-attachment-component>
                        <global-popup-images-carousel-component *ngSwitchCase="GLOBAL_POPUP_TYPE.VIEW_IMAGES_CAROUSEL"
                                                                (onPopupAction)="handlePopupAction($event)"
                                                                [globalPopup]="popup"></global-popup-images-carousel-component>
                        <global-popup-create-work-preparation-component
                                *ngSwitchCase="GLOBAL_POPUP_TYPE.CREATE_WORK_PREPARATION"
                                (onPopupAction)="handlePopupAction($event)"
                                [globalPopup]="popup"></global-popup-create-work-preparation-component>
                        <global-popup-edit-work-preparation-component
                                *ngSwitchCase="GLOBAL_POPUP_TYPE.EDIT_WORK_PREPARATION"
                                (onPopupAction)="handlePopupAction($event)"
                                [globalPopup]="popup"></global-popup-edit-work-preparation-component>
                        <global-popup-create-work-specification-component
                                *ngSwitchCase="GLOBAL_POPUP_TYPE.CREATE_WORK_SPECIFICATION"
                                (onPopupAction)="handlePopupAction($event)"
                                [globalPopup]="popup"></global-popup-create-work-specification-component>
                        <global-popup-create-weekprijs-component
                                *ngSwitchCase="GLOBAL_POPUP_TYPE.CREATE_WEEKPRIJS"
                                (onPopupAction)="handlePopupAction($event)"
                                [globalPopup]="popup"></global-popup-create-weekprijs-component>
                        <global-popup-create-work-activity-component
                                *ngSwitchCase="GLOBAL_POPUP_TYPE.CREATE_WORK_ACTIVITY"
                                (onPopupAction)="handlePopupAction($event)"
                                [globalPopup]="popup"></global-popup-create-work-activity-component>
                        <global-popup-create-check-activity-component
                                *ngSwitchCase="GLOBAL_POPUP_TYPE.CREATE_CHECK_ACTIVITY"
                                (onPopupAction)="handlePopupAction($event)"
                                [globalPopup]="popup"></global-popup-create-check-activity-component>
                        <global-popup-scanner-component
                                *ngSwitchCase="GLOBAL_POPUP_TYPE.BARCODE_SCANNER"
                                (onPopupAction)="handlePopupAction($event)"
                                [globalPopup]="popup"></global-popup-scanner-component>
                        <global-popup-create-public-report-component
                                *ngSwitchCase="GLOBAL_POPUP_TYPE.CREATE_PUBLIC_REPORT"
                                (onPopupAction)="handlePopupAction($event)"
                                [globalPopup]="popup"></global-popup-create-public-report-component>
                        <global-popup-create-generic-report-component
                                *ngSwitchCase="GLOBAL_POPUP_TYPE.CREATE_GENERIC_REPORT"
                                (onPopupAction)="handlePopupAction($event)"
                                [globalPopup]="popup"></global-popup-create-generic-report-component>
                        <global-popup-view-stedin-order-component
                                *ngSwitchCase="GLOBAL_POPUP_TYPE.VIEW_STEDIN_ORDER"
                                (onPopupAction)="handlePopupAction($event)"
                                [globalPopup]="popup"></global-popup-view-stedin-order-component>
                        <global-popup-form-preview-component
                                *ngSwitchCase="GLOBAL_POPUP_TYPE.FORM_PREVIEW"
                                (onPopupAction)="handlePopupAction($event)"
                                [globalPopup]="popup"></global-popup-form-preview-component>
                        <global-popup-add-attachment-to-generic-report-component
                                *ngSwitchCase="GLOBAL_POPUP_TYPE.ADD_ATTACHMENT_TO_GENERIC_REPORT"
                                (onPopupAction)="handlePopupAction($event)"
                                [globalPopup]="popup"></global-popup-add-attachment-to-generic-report-component>
                        <global-popup-edit-time-registration-component
                                *ngSwitchCase="GLOBAL_POPUP_TYPE.EDIT_TIME_REGISTRATION"
                                (onPopupAction)="handlePopupAction($event)"
                                [globalPopup]="popup"></global-popup-edit-time-registration-component>
                        <global-popup-create-user-component
                                *ngSwitchCase="GLOBAL_POPUP_TYPE.CREATE_USER"
                                (onPopupAction)="handlePopupAction($event)"
                                [globalPopup]="popup"></global-popup-create-user-component>
                        <global-popup-invite-user-component
                                *ngSwitchCase="GLOBAL_POPUP_TYPE.INVITE_USER"
                                (onPopupAction)="handlePopupAction($event)"
                                [globalPopup]="popup"></global-popup-invite-user-component>
                        <global-popup-request-refresh-component
                                *ngSwitchCase="GLOBAL_POPUP_TYPE.REFRESH_REQUEST"
                                (onPopupAction)="handlePopupAction($event)"
                                [globalPopup]="popup"></global-popup-request-refresh-component>
                        <global-popup-create-map-items
                                *ngSwitchCase="GLOBAL_POPUP_TYPE.CREATE_MAPITEMS"
                               (onPopupAction)="handlePopupAction($event)"
                               [globalPopup]="popup"></global-popup-create-map-items>
                        <global-popup-translation-component
                                *ngSwitchCase="GLOBAL_POPUP_TYPE.TRANSLATE"
                                (onPopupAction)="handlePopupAction($event)"
                                [globalPopup]="popup"></global-popup-translation-component>
                        <global-popup-first-login-component
                                *ngSwitchCase="GLOBAL_POPUP_TYPE.FIRST_LOGIN"
                                (onPopupAction)="handlePopupAction($event)"
                                [globalPopup]="popup"></global-popup-first-login-component>
                        <div class="card p-3"
                             *ngSwitchDefault>{{'Implementatiefout: Geen geldig popuptype:' | translate}}
                            {{popup.type}}
                        </div>
                    </div>
                </div>
            </div>
            
        </ng-container>

        <div *ngIf="_globalAlerts && _globalAlerts.length > 0"
             class="global-alert-container col-md-12 col-lg-6 col-xxl-4">
            <div style="animation-duration: 0.5s"
                 class="alert alert-danger animated fadeIn z-depth-3 global-alert {{alert.removed?'global-alert-removed':''}}"
                 *ngFor="let alert of _globalAlerts" role="alert">
                <i class="material-icons global-alert-close-icon {{alert.removed?'d-none':''}}" role="button"
                   (click)="handleCloseAlert($event,alert)">close</i>
                <table>
                    <tbody>
                    <tr>
                        <td class="global-alert-type-icon_container">
                            <i class="forbidden-icon material-icons global-alert-type-icon"
                               style.color="{{alert.icon.color}}">{{alert.icon.type}}</i>
                        </td>
                        <td>
                            <p class="global-alert-title">{{alert.title}}</p>
                            <div class="global-alert-sub-title" [innerHTML]=alert.subTitle></div>
                            <div> <!--Change to display:flex to display side by side-->
                                <div [innerHTML]=alert.label></div>
                                <div *ngIf="showReleaseNotesLink(alert)">
                                    <a routerLink="/information/release-notes" routerLinkActive="active"
                                       (click)="handleClickReleaseNotes(alert)"> {{'Bekijk de release notes' | translate}}</a>
                                    <!--
                                    //Used for special occasion
                                    <a routerLink="/settings/user-profile" routerLinkActive="active" (click)="handleClickReleaseNotes(alert)"> {{'goto.userprofile' | translate}}</a>
                                    <a href="/assets/img/menu.png" target="_blank"> {{'goto.menuoverview' | translate}}</a>
                                    -->
                                </div>
                                <ng-container *ngIf="isDebugMessage(alert)">
                                    <div class="pt-3">
                                        <i>Deze melding is alleen zichtbaar voor Service Engineers</i>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="allowViewStackTrace(alert)">
                                    <div class="d-inline-block">
                                        <div class="pt-2 d-flex cursor-pointer"
                                             (click)="handleClickViewStackTrace(alert)">{{'View stacktrace'}}<i
                                                class="material-icons p-0 pl-1 md-18">search</i></div>
                                    </div>
                                </ng-container>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    `,
})
export class GlobalAlertComponent extends ChangeableComponent implements OnInit, OnDestroy {
    public static readonly ALERT_CLOSE_TIME: number = 500;
    public GLOBAL_POPUP_TYPE = GlobalPopupType;

    @ViewChildren('popupBackground1') popupBackground1: QueryList<ElementRef<HTMLDivElement>>;
    @ViewChildren('popupBackground2') popupBackground2: QueryList<ElementRef<HTMLDivElement>>;
    @ViewChildren('popupBackground3') popupBackground3: QueryList<ElementRef<HTMLDivElement>>;

    _globalAlerts: GlobalAlert[] = [];
    _globalPopups: GlobalPopup[] = [];

    private subGlobalAlerts: Subscription;
    private subGlobalPopups: Subscription;

    // TODO: verplaats deze logica naar wrapper component
    private static clickCloseButton(): void {
        // Look for a close button and try to click it. If no element is found, nothing will happen
        $('global-alert-component .global-popup i.global-alert-close-icon').last().click();
    }

    constructor(public model: GlobalModel, private globalAlertService: GlobalAlertService, protected elementRef: ElementRef, private cd: ChangeDetectorRef, private auth: AuthorizationService, private ts: TranslateService, protected logger:LoggerService) {
        super(elementRef);
    }

    ngOnInit() {

        this.subGlobalAlerts = this.model.globalAlerts.subscribe((value: GlobalAlert[]) => {
            // Don't forget to unsubscribe
            this._globalAlerts = value;

            if (this._globalAlerts) {
                this._globalAlerts.forEach((alert: GlobalAlert) => {
                    if (alert.autoFade > -1) {
                        if (!alert.isAutoFadeActive) {
                            alert.isAutoFadeActive = true;
                            setTimeout(() => {
                                if (alert && !alert.removed) {
                                    this.handleCloseAlert(null, alert);
                                }
                            }, alert.autoFade);
                        }
                    }
                });
            }

            this.cd.detectChanges();
        });

        this.subGlobalPopups = this.model.globalPopups.subscribe((value: GlobalPopup[]) => {
            // Don't forget to unsubscribe
            this._globalPopups = value;
            this.cd.detectChanges();
        });
    }

    ngOnDestroy() {
        this.subGlobalAlerts.unsubscribe();
        this.subGlobalPopups.unsubscribe();
    }

    handleClickPopupBackground(event: MouseEvent, popup: GlobalPopup): void {
        // Switched event to mouseDown to prevent click>drag>release-on-background>close-popup
        if ((event.target == this.popupBackground1?.toArray()[this._globalPopups.length - 1]?.nativeElement) || (event.target == this.popupBackground2?.toArray()[this._globalPopups.length - 1]?.nativeElement) || (event.target == this.popupBackground3?.toArray()[this._globalPopups.length - 1]?.nativeElement)) {
            this.logger.log('[GlobalAlertComponent] ' + 'Click on popup background detected, trying to close the popup: ' + popup.type);
            GlobalAlertComponent.clickCloseButton();
        }
    }

    handleCloseAlert(event: MouseEvent, alert: GlobalAlert): void {
        alert.removed = true;
        this.cd.detectChanges();

        // Element is hidden in css, but remove from array just for performance sake
        setTimeout(() => {
            this.globalAlertService.removeAlert(alert);
        }, GlobalAlertComponent.ALERT_CLOSE_TIME);
    }

    // TODO: dit is niet super netjes. Alleen die releasenotes button is wel de enige uitzondering die er nu is, dus dit voldoet voor nu.
    showReleaseNotesLink(alert: GlobalAlert): boolean {
        return alert.type == GlobalAlertService.ALERT_TYPE_FIRST_LOGIN;
    }

    isDebugMessage(alert: GlobalAlert): boolean {
        return alert.type == GlobalAlertService.ALERT_TYPE_DEBUG;
    }

    allowViewStackTrace(alert: GlobalAlert): boolean {
        if (this.auth.allowShowDebug()) {
            return alert.error && alert.error != '' && alert.type == GlobalAlertService.ALERT_TYPE_HTTP_ERROR;
        } else {
            return false;
        }
    }

    // Show the symphony error page for debugging
    handleClickViewStackTrace(alert: GlobalAlert): void {
        let newWindow: any = window.open('', '_blank', 'width=1024, height=768');

        if (newWindow) {
            newWindow.document.write(alert.error);
        } else {
            // Error page doesn't work on mobile
            this.globalAlertService.addAlert(this.ts.translate('Error'), this.ts.translate('Kan window niet openen'), this.ts.translate('Het nieuwe venster kan in de huidige modus niet worden geopend. Wissel naar de desktop-modus.'));
        }
    }

    handleClickReleaseNotes(alert: GlobalAlert): void {
        // Release note link is clicked, remove the info alert
        this.globalAlertService.removeAlert(alert);
    }

    // Respond to a button click
    handlePopupAction(data: PopupActionEvent): void {
        let popup: GlobalPopup = data.alert;
        // let event: MouseEvent = data.event;
        let button: any = data.button;
        let restData: any = data.data;

        if (!popup.removed) {

            // Only remove once
            popup.removed = true;

            // Perform a callback with buttoncode and data
            if (button.callback) {
                button.callback(button.code, restData);
            } else {
                // No callback, execute close callback as default
                if (popup.callbackClose) {
                    popup.callbackClose();
                }
            }

            // Remove the alert
            this.globalAlertService.removePopup(popup);
            this.cd.detectChanges();
        }
    }
}
