/**
 * Created by Christiaan on 23/03/2017.
 */

import {Component} from '@angular/core';

@Component ({
    selector: 'load-areaal-component',
    template: `        
        <div class="login-container">
            <div class="loading-filler"></div>
            <div class="loading-form-container">
                <div class="loading-form-small" style="max-width: 25rem;">
                    <!--<div>{{'Areaalgegevens worden geladen...' | translate}}</div>-->
                    <div class="loader"></div>
                </div>
            </div>
        </div>
`
})

export class LoadAreaalComponent {
    constructor() {}
}
