<ng-container [formGroup]="group">
    <div class="d-block w-100">
        <div class="d-flex">
            <form-component-label *ngIf="config.label" [batchUpdateMode]="batchUpdateMode" [config]="config"
                                  [included]="included"
                                  (onClickInclude)="handleClickInclude($event)"></form-component-label>

            <div class="d-flex w-100 {{'id-' + componentId}}">
                <div class="d-flex flex-column w-100">
                    <div class="d-flex flex-row w-100">
                        <div class="d-flex flex-column w-100" #currentFormField>
                            <lumi-select *ngIf="this.config?.options?.length > 0"
                                         class="lumi-select-field"
                                         [attr.data-selected-option]="_selectedOption?.name"
                                         [options]="this.config.options"
                                         [selectedOptions]="[_selectedOption]"
                                         [readonly]="isControlReadOnly()"
                                         [disabled]="isControlDisabled()"
                                         [showOptionFilter]="showOptionFilter()"
                                         (onOptionsSelect)="onOptionsSelect($event)">
                            </lumi-select>
                        </div>

                        <div class="pl-1"
                             *ngIf="config && config.attr && config.attr.creationHref && !isControlDisabled()">
                            <div class="card cursor-pointer" (click)="handleAdd(false)"
                                 [title]="'Maak een nieuw item aan' | translate">
                                <i class="p-2 material-icons " style="font-size: 20px">add</i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="d-flex" *ngIf="config && config.attr && config.attr.fillSuggestion">
            <div style="width: 55%"></div>
            <div class="form-read-only-label w-100">
                <i class="material-icons" style="font-size: small; padding: 0;">
                    subdirectory_arrow_right
                </i>
                <i style="font-size: small">Suggestie: </i>
                <i (click)="handleAdd(true)" class="form-link-anchor">
                    {{config.attr.fillSuggestion}}
                </i>
            </div>
        </div>
    </div>
</ng-container>
