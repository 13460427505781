import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CheckActivitiesComponent} from './check-activities.component';
import {CheckActivityComponent} from './check-activity/check-activity.component';
import {ProgressBarComponent} from './progress-bar/progress-bar.component';
import {ActivityStatusIconComponent} from './check-activity/activity-status-icon/activity-status-icon.component';
import {ReactiveFormsModule} from '@angular/forms';
import {CommonPipesModule} from '../../pipes/common-pipes.module';
import {CommonUIModule} from '../commonUI/common-ui.module';
import {FileModule} from '../file/file.module';

@NgModule({
    declarations: [
        CheckActivitiesComponent,
        CheckActivityComponent,
        ProgressBarComponent,
        ActivityStatusIconComponent
    ],
    exports: [
        CheckActivitiesComponent,
        CheckActivityComponent,
        ProgressBarComponent,
        ActivityStatusIconComponent
    ],
    imports: [
        CommonModule,
        CommonPipesModule,
        ReactiveFormsModule,
        CommonUIModule,
        FileModule
    ]
})
export class CheckActivitiesModule {
}
