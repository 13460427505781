import {Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild} from '@angular/core';
import {StatusButtonAction, StatusButtonItem} from './status-button.interface';
import Utils from '../../../utils/utils';
import {LoggerService} from "../../../services/logger/logger.service";

@Component({
    selector: 'status-button',
    templateUrl: './status-button.component.html'
})
export class StatusButtonComponent implements OnInit {
    public showStatusDropdown: boolean = false;
    @Input() items: StatusButtonItem[] = [];
    @Input() actions: StatusButtonAction[] = [];
    @Input() disabled: boolean;
    @Input() buttonLabel: string;
    
    @Output() selectionChanged: EventEmitter<StatusButtonItem> = new EventEmitter<StatusButtonItem>();
    @Output() onAction: EventEmitter<StatusButtonAction> = new EventEmitter<StatusButtonAction>();
    
    @ViewChild('menuButton', {static: false}) menuButton: ElementRef<HTMLDivElement>;
    @ViewChild('menuItemsContainer', {static: false}) menuItemsContainer: ElementRef<HTMLDivElement>;
    public menuItemElements: Element[] = [];
    private focussedMenuItem: Element;
    private preventKeyUpEvent: boolean = false;
    
    constructor(protected logger:LoggerService) {
    }
    
    ngOnInit() {
    }
    
    public toggleStatusDropdown(event: MouseEvent|KeyboardEvent) {
        event.stopImmediatePropagation();
        this.showStatusDropdown = !this.showStatusDropdown;
        if (this.showStatusDropdown) {
            this.setMenuItemElementsArray();
            if (event.type !== 'click') {
                this.focusMenuItem(this.menuItemElements[0]);
            }
        }
    }
    
    public setMenuItemElementsArray(): void {
        this.menuItemElements = [];
        Array.from(this.menuItemsContainer.nativeElement.children).forEach(element => this.searchRecursiveForItems(element));
    }
    
    @HostListener('window:click', ['$event'])
    public closeStatusDropdown() {
        this.showStatusDropdown = false;
    }
    
    public getLabel(): string {
        if (this.buttonLabel && this.buttonLabel !== '') {
            return this.buttonLabel;
        }
        return this.items.some(item => item.isActive) ? this.items.find(item => item.isActive).label : '...';
    }
    
    public onItemClick(item: StatusButtonItem) {
        this.selectionChanged.emit(item);
    }
    
    public onActionClick(action: StatusButtonAction) {
        this.onAction.emit(action);
    }
    
    public getItemClass(item: StatusButtonItem): string {
        if (item.isDisabled) {
            return item.isActive ? 'sb-dropdown-option-active-disabled' : 'sb-dropdown-option-disabled';
        }
        return item.isActive ? 'sb-dropdown-option-active' : 'sb-dropdown-option';
    }
    
    public onKeyUpMenuButton($event: KeyboardEvent) {
        if (this.preventKeyUpEvent) {
            this.preventKeyUpEvent = false;
            Utils.preventDefault($event);
            return;
        }
        if (!Utils.hasFocus(this.menuButton.nativeElement)) {
            return;
        }
        if ($event.key === 'Enter' && !this.showStatusDropdown) {
            this.toggleStatusDropdown($event);
        }
        if ($event.key === 'Escape') {
            Utils.preventDefault($event);
            Utils.removeAllFocus();
        }
    }
    
    public onkeyDownMenu($event: KeyboardEvent) {
        if (!this.showStatusDropdown) {
            return;
        }
        const currentFocussedIndex: number = this.menuItemElements.indexOf(this.focussedMenuItem);
        
        if ($event.key === 'ArrowDown' || $event.key === 'ArrowRight') {
            Utils.preventDefault($event);
            if (currentFocussedIndex === this.menuItemElements.length - 1) {
                this.focusMenuItem(this.menuItemElements[0]);
            } else {
                this.focusMenuItem(this.menuItemElements[currentFocussedIndex + 1]);
            }
        }
        
        if ($event.key === 'ArrowUp' || $event.key === 'ArrowLeft') {
            Utils.preventDefault($event);
            if (currentFocussedIndex === 0) {
                this.focusMenuItem(this.menuItemElements[this.menuItemElements.length - 1]);
            } else {
                this.focusMenuItem(this.menuItemElements[currentFocussedIndex - 1]);
            }
        }
        
        if ($event.key === 'Enter') {
            Utils.preventDefault($event);
            this.focussedMenuItem.dispatchEvent(new Event('click', {bubbles: true}));
            this.menuButton.nativeElement.focus();
            this.preventKeyUpEvent = true;
        }
        
        if ($event.key === 'Escape') {
            Utils.preventDefault($event);
            this.closeStatusDropdown();
            this.menuButton.nativeElement.focus();
            this.preventKeyUpEvent = true;
        }
        
        if ($event.key === 'Tab') {
            this.closeStatusDropdown();
        }
    }
    
    private searchRecursiveForItems(element: Element): void {
        if (!element) {
            return;
        }
        if (element.attributes['menuItem']) {
            this.menuItemElements.push(element);
        }
        if (element.children?.length > 0) {
            Array.from(element.children).forEach(_element => this.searchRecursiveForItems(_element));
        }
    }
    
    private focusMenuItem(item: Element): void {
        this.logger.log(item)
        if (!item) {
            return;
        }
        this.focussedMenuItem = item;
        this.menuItemElements.forEach(_item => _item.classList.remove('focussed'));
        item.classList.add('focussed');
    }
}
