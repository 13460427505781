import {AfterViewInit, ChangeDetectorRef, Component, NgZone, Renderer2,} from '@angular/core';
import {FormInputComponent} from '../form-input/form-input.component';
import {ValidationConstraintService} from '../../services/validation-constraint.service';
import {TooltipService} from '../../../../services/tooltip/tooltip.service';
import {TranslateService} from '../../../../services/translate/translate.service';
import {GlobalAlertService} from '../../../../../wrapper/global-alert/global-alert.service';
import {HttpService2} from '../../../../services/http-service-2.0/http2.0.service';
import {FormDataService} from '../../services/form-data.service';
import {RequestFailure} from '../../../../services/http/request-failure';
import {HTTPError} from '../../../../services/http/http-error';
import {GlobalModel} from '../../../../services/state/global.model';
import {take} from 'rxjs/operators';
import {MapServerService} from '../../../map/map-server/map-server.service';
import {MapServerLayer} from '../../../map/map-server/map-server.interface';
import {FormMapServerValueInterface} from './form-map-server.interface';
import {ServerType} from '../../../../utils/map-wmslayer-generator';
import {ButtonCode} from '../../../../../wrapper/global-alert/global-popup';
import {LoggerService} from "../../../../services/logger/logger.service";

@Component({
    selector: 'form-map-server',
    templateUrl: 'form-map-server.component.html'
})
export class FormMapServerComponent extends FormInputComponent implements AfterViewInit {

    public constructor(public renderer: Renderer2, public validationConstraintService: ValidationConstraintService, public tooltipService: TooltipService, public ts: TranslateService, public globalAlertService: GlobalAlertService, public formService:FormDataService, public model:GlobalModel, protected ngZone:NgZone, protected httpService:HttpService2, private mapServerService:MapServerService, protected logger:LoggerService, public cd:ChangeDetectorRef) {
        super(renderer, validationConstraintService, tooltipService, globalAlertService, ts, logger, model);
    }

    ngAfterViewInit(): void {
    }

    public handleClickEdit(server:FormMapServerValueInterface) {
        this.globalAlertService.addPopupCreateEditMapServer(this.ts.translate('mapserver.edittitle'), this.ts.translate('mapserver.editlabel'), 'settings/area/edit-server/' + server.id, 'settings/area/post-server/' + server.id, (buttonCode: ButtonCode, response: any) => {
            // Show success alert
            if(this.model.currentAreaal.getValue().wmsData !== null) {
                const index = this.model.currentAreaal.getValue().wmsData.findIndex(wmsData => wmsData.id === server.id)
                this.model.currentAreaal.getValue().wmsData[index].disabled = response[index].disabled;
                this.model.currentAreaal.getValue().wmsData[index].label = response[index].name;
            }
            this.config.attr.mapServerList = response;
            this.globalAlertService.addAlertSuccess(this.ts.translate('mapserver.editsuccesstitle'), this.ts.translate('mapserver.editsuccesssubtitle'), this.ts.translate('mapserver.editsuccesslabel'));
        }, (buttonCode: ButtonCode, data: any) => {
            if (data.form.controls.serverData.controls.serverType.value.includes(ServerType.WMS)) {
                this.mapServerService.checkWMSConnection(data.form.controls.serverData.controls.url.value).pipe(
                    take(1)
                ).subscribe((result) => {
                    if (result) {
                        this.globalAlertService.addAlertSuccess(this.ts.translate('mapserver.connectionsuccess.title'), this.ts.translate('mapserver.connectionsuccess.subtitle'), this.ts.translate('mapserver.connectionsuccess.label'));
                    } else {
                        this.globalAlertService.addAlert(this.ts.translate('mapserver.connectionfail.title'), this.ts.translate('mapserver.connectionfail.subtitle'), this.ts.translate('mapserver.connectionfail.label'));
                    }
                })
            }
        }, (response: any) => {
            this.logger.log('[FormGroupComponent] ', response);
        });
    }

    public handleClickSave(server:FormMapServerValueInterface, event:MapServerLayer[]) {
        let saveUrl = 'settings/area/save-server-layers/' + server.id;

        this.formService.saveMapServerLayers(saveUrl, event.map(layer => layer.id)).pipe(
            take(1)
        ).subscribe(
            () => {
                if(this.model.currentAreaal.getValue().wmsData !== null){
                    const index = this.model.currentAreaal.getValue().wmsData.findIndex(wmsData => wmsData.id === server.id)
                    this.model.currentAreaal.getValue().wmsData[index] = {id: server.id, label:server.name, layers:event.map(layer => {return {value:String(layer.id), label:layer.name}}), url:server.url, active:true, disabled:false}
                }
                this.globalAlertService.addAlertSuccess(this.ts.translate('mapserver.savesuccesstitle'), this.ts.translate('mapserver.savesuccesssubtitle'), this.ts.translate('mapserver.savesuccesslabel'));
            },
            (error) => {
                this.logger.log('[FormMapServerComponent] ', error);
            }
        )
    }

    public handleClickDelete(server:FormMapServerValueInterface) {

        let deleteUrl = 'settings/area/delete-server/' + server.id;
        this.formService.deleteMapServer(deleteUrl,
            (formData:any) => {
                this.config.attr.mapServerList = formData;
                if(this.model.currentAreaal.getValue().wmsData !== null) {
                    const index = this.model.currentAreaal.getValue().wmsData.findIndex(formData => formData.id === server.id)
                    this.model.currentAreaal.getValue().wmsData.splice(index, 1)
                }
                this.globalAlertService.addAlertSuccess(this.ts.translate('mapserver.deletesuccesstitle'), this.ts.translate('mapserver.deletesuccesssubtitle'), this.ts.translate('mapserver.deletesuccesslabel'));
            },
            (failure: RequestFailure) => {
                this.logger.log('[FormMapServerComponent] ', failure);
            },
            (error:HTTPError) => {
                this.logger.log('[FormMapServerComponent] ', error);
            }
        );
    }
}

