/**
 * Created by Christiaan on 01/03/2017.
 */
import {
    AfterViewInit,
    ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, ViewChild
} from '@angular/core';

import {GlobalAlertService} from "../global-alert.service";
import {GlobalPopup} from "../global-popup";
import {FormDataService} from "../../../shared/components/form/services/form-data.service";
import {GlobalModel} from "../../../shared/services/state/global.model";
import {TranslateService} from "../../../shared/services/translate/translate.service";
import {AbstractBasePopupComponent} from "./abstract-base-popup.component";
import {TooltipService} from "../../../shared/services/tooltip/tooltip.service";
import {FormComponent} from "../../../shared/components/form/containers/form/form.component";
import {DefaultPopupWrapperComponent} from "./default-popup-wrapper/default-popup-wrapper.component";
import { FormEvent } from '../../../shared/components/form/containers/form/form.interface';
import {LoggerService} from "../../../shared/services/logger/logger.service";

@Component ({
    selector: 'global-popup-add-attachment-to-generic-report-component',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <default-popup-wrapper-component #wrapper [disableButtonsOverride]="disableButtonsOverride" [componentRef]="this" [hideCloseButton]="true" [disableButtons]="readOnly" [globalPopup]="globalPopup" [showLoader]="isLoading" (onClose)="closePopup($event)">
            <div class="pr-0">
                <div *ngIf="formData && config">
                    <form-component #form
                            [config]="config" 
                            [label]="formData.schema.label"
                            [name]="getFormName()"
                            [validationConstraints]="validationConstraints"
                            [invalidControlsErrors]="invalidControlsErrors"
                            [readOnly]="readOnly"
                            [formIsSubmitted]="formIsSubmitted"
                            (onComponentEvent)="handleComponentEvent($event)">
                    </form-component>
                </div>
            </div>
        </default-popup-wrapper-component>
    `
})

export class GlobalPopupAddAttachmentToGenericReportComponent extends AbstractBasePopupComponent implements AfterViewInit
{
    @Input() requestUrl:string = "";
    //@Input() submitUrl:string = "";

    @ViewChild('form', {static: false}) form:any;
    @ViewChild('wrapper', {static: false}) wrapper: DefaultPopupWrapperComponent;

    public disableButtonsOverride:any = (button:any) => {return this.attachmentInPreview};
    private attachmentInPreview:boolean = false;

    public constructor(protected cd:ChangeDetectorRef, protected globalAlertService:GlobalAlertService, protected formDataService:FormDataService, protected elementRef:ElementRef, private model:GlobalModel, protected ts:TranslateService, protected tooltipService:TooltipService, protected logger:LoggerService) {
        super(cd, globalAlertService, elementRef, ts, tooltipService, logger);
    }

    ngAfterViewInit(){

        //Retrieve map item id from the popup data
        this.requestUrl = this.globalPopup.data.requestUrl;
        //this.submitUrl = this.globalPopup.data.submitUrl;

/*        let popupData:any = this.globalPopup.data;
        if (popupData && popupData.lat && popupData.lng){
            this.model.formLocationComponentPosition = {lat:popupData.lat, lng:popupData.lng};
        }else{
            this.model.formLocationComponentPosition = null;
        }

        this.submitButton = this.getSubmitButton();*/

        this.requestFormData();
    }

    handlePopupAction(event: MouseEvent, alert: GlobalPopup, button: any): void {
        //callback for submit button comes from this form, not from outside this component
        /*if (button == this.submitButton){
            this.form.handleComponentEvent({event:FormEvent.SAVE, data:{}});
            //this.form.submitForm();
        }else{*/
            //Hide alert and perform callback of button
            this.onPopupAction.emit({event:event, alert:alert, button:button});
        //}
    }
    
    public handleComponentEvent(eventData:any):void
    {
        switch (eventData.event) {
            /*case FormEvent.SAVE:
                this.submitFormData(eventData.data.formData);
                break;*/
            case FormEvent.ATTACHMENT_PREVIEWED:
                this.attachmentInPreview = true;
                this.cd.detectChanges();
                this.wrapper.runChangeDetection();
                break;
            case FormEvent.ATTACHMENT_CLEARED:
                this.attachmentInPreview = false;
                this.cd.detectChanges();
                this.wrapper.runChangeDetection();
                break;
        }
    }

    /*private submitFormData(form: any):void
    {
        this.handleSubmitForm();

        //this.formDataService.setFormData(form, this.getFormName(), this.submitUrl,
        this.formDataService.setFormData(form, this.getFormName(), "",
            (formData:any) => {
                //this.formData = formData;
                this.handleSubmitResponse();

                //Hide alert and perform success callback via submit button
                this.onPopupAction.emit({event:null, alert:this.globalPopup, button:this.submitButton, data:formData}); //, data:{lat:form.location.latitude, lng:form.location.longitude}});
            },
            (failure: RequestFailure) => {
                this.logger.log("[GlobalPopupCreateGenericReportComponent] " + "Handle failure response: ", failure);
                this.handleSubmitResponse(failure.formErrors);
            },
            (error:HTTPError) => {
                this.handleSubmitResponse(null, true);
            }
        );
    }*/

    public closePopup(event:any):void
    {
        this.doDefaultCloseHandling(event, false);
    }

    public requestFormData():void
    {
        this.formDataService.getFormData(this.requestUrl,
            (formData:any) => {
                    this.formData = formData;
                    this.isLoading = false;
                    this.cd.detectChanges();
            },
            () => {
                this.closePopup(null);
            },
            () => {
                this.closePopup(null);
            }
        );
    }
}
