import {ChangeDetectorRef, Component, HostBinding, Renderer2, ViewChild} from '@angular/core';
import {ValidationConstraintService} from '../../services/validation-constraint.service';
import {GlobalAlertService} from '../../../../../wrapper/global-alert/global-alert.service';
import {TooltipService} from '../../../../services/tooltip/tooltip.service';
import {FormDataService} from '../../services/form-data.service';
import {Router} from '@angular/router';
import {TranslateService} from '../../../../services/translate/translate.service';
import {AbstractFormWidget} from './abstract-form-widget';
import {GlobalModel} from '../../../../services/state/global.model';
import {AuthorizationService} from '../../../../services/authorization/authorization.service';
import {GlobalEvent} from '../../../../interfaces/global-event';
import {Subscription} from 'rxjs';
import {ExportService} from '../../../../../modules/export/export.service';
import {ExportOptions} from '../../../../../modules/dashboard/widgets/widget';
import {BaseTableComponent} from '../../../table/shared/baseTable/baseTable.component';
import {
    DataType,
    ExportType,
    RowData,
    TableData,
    TableSortDirection,
    TableSorting
} from '../../../table/shared/baseTable/baseTable.interface';
import {HTTPService} from '../../../../services/http/http.service';
import {TableColumn, TableRow} from '../../../map-table/map-table.interface';
import {BaseTableService} from '../../../table/shared/baseTable/baseTableService';
import {ButtonCode} from '../../../../../wrapper/global-alert/global-popup';
import {LoggerService} from "../../../../services/logger/logger.service";

@Component({
    selector: 'form-table',
    template: `
        <ng-container [formGroup]="group">
            <label class="form-sub-title d-block {{!showDatePickers?'mb-0':''}} pt-3 pb-1" for="{{config.name}}"><p
                    class="align-middle d-inline-block">{{config.label ? config.label : ''}}</p>
                <ng-container *ngIf="infoText">
                    <help-icon-component class="align-middle d-inline-block" [tooltipText]="infoText"
                                         [tooltipPlacement]="PLACEMENT_BOTTOM"></help-icon-component>
                </ng-container>
                <div *ngIf="showExportOptions"
                     class="widget-title d-inline-block form-settings align-middle float-right">
                    <menu-dropdown [menuTitle]="'menu.exportto' | translate">
                        <ng-container *ngFor="let option of exportOptions">
                            <div *ngIf="option.show" menuItem class="md-focusable form-setting-option"
                                 (click)="handleExportClick(option.name)"
                                 title="{{'menu.exportexceltitle' | translate}}">{{option.label}}</div>
                        </ng-container>
                    </menu-dropdown>
                </div>
            </label>
            <div *ngIf="showDatePickers" class="line-graph-date-row">
                <div [title]="'Startdatum' | translate" class="col-6 date-row-left">
                    <date-input [inputType]="'date'"
                                [allowClear]="false"
                                [maxDate]="disableUntil"
                                [initialValue]="startDate"
                                (onDateChanged)="onStartDateChanged($event)">
                    </date-input>
                </div>
                <div [title]="'Einddatum' | translate" class="col-6 date-row-right no-padding">
                    <date-input [inputType]="'date'"
                                [allowClear]="false"
                                [maxDate]="disableUntil"
                                [initialValue]="endDate"
                                (onDateChanged)="onEndDateChanged($event)">
                    </date-input>
                </div>
            </div>
            <div class="table-component-container">
                <base-table-component
                        *ngIf="tableData"
                        #baseTableComponent
                        [tableOptions]="{
                            allowDeleteRow: allowDelete,
                            canActivateRows: canActivateRows
                        }"
                        [isLoading]="isLoading"
                        [tableData]="tableData"
                        (onRowDelete)="handleClickDelete($event)"
                        (onRowAction)="handleClickTableRow($event)">
                </base-table-component>
            </div>
        </ng-container>
    `,
})
export class FormTableComponent extends AbstractFormWidget {
    private static readonly ACTION_EDIT_TIME_REGISTRATION: string = 'EDIT_TIME_REGISTRATION';
    
    @ViewChild('baseTableComponent', {static: false}) baseTableComponent: BaseTableComponent;
    @HostBinding('class') hostClasses = 'd-flex flex-column';
    
    public tableData: TableData = {
        headers: [],
        rows: []
    };
    public canActivateRows: boolean = false;
    exportOptions: ExportOptions[] = [];
    showExportOptions: boolean = true;
    allowDelete: boolean = false;
    private subOnGlobalEvent: Subscription = null;
    private isDestroyed: boolean = false;


    constructor(
        public renderer: Renderer2,
        public validationConstraintService: ValidationConstraintService,
        private auth: AuthorizationService,
        public tooltipService: TooltipService,
        public globalAlertService: GlobalAlertService,
        public formDataService: FormDataService,
        private router: Router,
        public ts: TranslateService,
        public model: GlobalModel,
        public cd: ChangeDetectorRef,
        private httpService: HTTPService,
        public exportService: ExportService,
        private baseTableService: BaseTableService,
        protected logger:LoggerService
    ) {
        super(renderer, validationConstraintService, tooltipService, model, formDataService, globalAlertService, ts, cd, logger);
    }
    
    handleExportClick(option: string) {
        switch (option) {
            case 'excel':
                this.handleExportToExcel();
                break;
            case 'csv':
                this.handleExportToCsv();
                break;
            case 'clipboard':
                this.handleCopyToClipboard();
                break;
            default:
                this.handleExportToExcel();
        }
    }
    
    ngOnInit() {
        if (this.config.name === 'chooseReport') {
            this.allowDelete = true;
        }
        
        if (!this.config.attr.exportOptions) {
            this.exportOptions = [
                {name: 'excel', label: 'Excel (.xlsx)', show: true},
                {name: 'csv', label: 'Csv (.csv)', show: false},
                {name: 'clipboard', label: 'Clipboard', show: true},
            ];
        } else {
            this.exportOptions = this.config.attr.exportOptions;
        }
        
        if (this.config.attr.showExportOptions !== undefined) {
            this.showExportOptions = this.config.attr.showExportOptions === true;
        }
        
        if (this.config.attr && this.config.attr.url) {
            this.initWidget('' + this.config.attr.url);
        }
        
        if (this.auth.allowShowDebug() && this.config && !this.config.label) {
            this.globalAlertService.addAlertDebugMessage(
                'Geen label',
                this.config.name + ' heeft geen label',
                'Geef een label mee aan de form table ' + this.config.name + '.'
            );
        }
        
        this.subOnGlobalEvent = this.model.onGlobalEvent.subscribe((event: GlobalEvent) => {
            if (event.type === GlobalEvent.EVENT_TIME_REGISTRATION_UPDATE && this.config.name === 'tijdregistraties') {
                if (this.config.attr && this.config.attr.url) {
                    this.loadWidgetData('' + this.config.attr.url);
                } else {
                    if (this.auth.allowShowDebug()) {
                        this.globalAlertService.addAlertDebugMessage(
                            'Geen URL',
                            'Geen URL gevonden',
                            'De tabel verwacht een URL om data mee op te halen, maar die is niet meegegeven'
                        );
                    }
                }
            }
        });
    }
    
    ngAfterViewInit() {
        this.handleAfterViewInit();
    }
    
    handleClickTableRow(clickData: RowData[]): void {
        let row = clickData[0];
        const clickActionIndex = this.tableData.headers.findIndex(header => header.type === DataType.ACTION);
        const clickAction = clickData[0].cells[clickActionIndex];
        
        if (clickAction && clickAction.label !== '' && this.config.name !== 'chooseReport') {
            this.logger.log('[FormTableComponent] ' + 'clickaction executed: ' + clickAction.label);
            if (clickAction.label === FormTableComponent.ACTION_EDIT_TIME_REGISTRATION) {
                let rowId = row.uniqueId;
                this.globalAlertService.addPopupEditTimeRegistration(
                    this.auth.allowDeleteTimeRegistration(),
                    'stedin-orders/time-registrations/new/' + rowId,
                    'stedin-orders/time-registrations/create/' + rowId,
                    (event: string) => {
                        // Handle delete and save
                        if (event === ButtonCode.DELETE) {
                            this.formDataService.deleteTimeRegistration('stedin-orders/time-registrations/delete/' + rowId, () => {
                                this.loadWidgetData('' + this.config.attr.url);
                            });
                        } else if (event === ButtonCode.OK) {
                            this.loadWidgetData('' + this.config.attr.url);
                        }
                    }, () => {
                    });
            } else {
                this.router
                    .navigateByUrl(clickAction.label)
                    .catch(err => this.logger.error(err));
            }
        } else {
            if (this.config.name === 'chooseReport') {
                clickAction.label = 'export-wizard/download-options/' + clickAction.label.replace(/^\D+/g, '');
                this.exportService.getExportFormData(clickAction.label,
                    () => {
                        // Call success, model adjusted
                    });
            }
        }
    }
    
    ngOnDestroy() {
        this.isDestroyed = true;
        if (this.subOnGlobalEvent) {
            this.subOnGlobalEvent.unsubscribe();
        }
    }
    
    public handleClickDelete($event: RowData) {
        const clickDataIndex = this.tableData.headers.findIndex(header => header.type === DataType.ACTION);
        let reportId = $event.cells[clickDataIndex].label.match(/\d+/)[0];
        
        this.globalAlertService.addPopup(
            this.ts.translate('export.deletetitle'),
            this.ts.translate('export.delete'),
            [{
                label: this.ts.translate('Annuleren'),
                code: ButtonCode.ANNULEREN,
                isPrimary: false
            }, {
                label: this.ts.translate('Verwijderen'),
                code: ButtonCode.DELETE,
                callback: () => {
                    this.httpService.doGetRequest('export-wizard/existing-reports/delete/' + reportId, (json: any) => {
                        this.setTableData(json.tableItems, null);
                        this.globalAlertService.addAlertSuccess(
                            this.ts.translate('export.isdeletedtitle'),
                            this.ts.translate('export.isdeletedtext'),
                            ''
                        );
                    });
                },
                isPrimary: true
            }],
            () => {
            }
        );
    }
    
    protected handleWidgetDataReceived(json: TableWidgetData): void {
        if (json.tableItems) {
            this.setTableData(json.tableItems, json.sorting as any);
        }
    }
    
    private handleExportToExcel(): void {
        this.baseTableComponent.exportTable(ExportType.XLSX);
    }
    
    private handleExportToCsv(): void {
        if (this.config) {
            this.baseTableComponent.exportTable(ExportType.CSV);
        }
    }
    
    private handleCopyToClipboard(): void {
        this.baseTableComponent.exportTable(ExportType.CLIPBOARD);
    }
    
    private setTableData(
        tableData: (TableRow | TableColumn[])[],
        sorting: { code: string, direction: string }
    ) {
        if (tableData.length < 1) {
            this.tableData.headers = [];
            this.tableData.rows = [];
        } else {
            this.tableData = this.baseTableService.createTableData(
                <TableRow[]>tableData.slice(1, tableData.length),
                <TableColumn[]>tableData[0]
            );
            const clickDataIndex = this.tableData.headers.findIndex(header => header.type === DataType.ACTION);
            this.canActivateRows = this.tableData.rows.some(
                row => row.cells[clickDataIndex] && row.cells[clickDataIndex].label && row.cells[clickDataIndex].label !== ''
            );
        }
        setTimeout(() => {
            let newSorting: TableSorting;
            if (sorting) {
                newSorting = {
                    columnId: this.tableData.headers.find(header => header.code === sorting.code).columnId,
                    sortDirection: TableSortDirection[sorting.direction],
                };
            }
            this.baseTableComponent.updateTable(null, newSorting);
        });
    }
}

export interface TableWidgetData {
    tableItems: (TableRow | TableColumn[])[]
    sorting: { [key: string]: string }
}
