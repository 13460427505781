<ng-container [formGroup]="group">

    <label class="" [innerHtml]="(config.attr && config.attr.description?config.attr.description:'')"></label>

    <div class="d-flex">
        <form-component-label [config]="config"></form-component-label>

        <div class="w-100 align-self-center" style="font-size: 12px;">
            <file-selector [isDisabled]="isControlDisabled()"
                           [multiInput]="false"
                           [fileTypesString]="fileType"
                           (onChanges)="onInputChanged($event)">
            </file-selector>
            <div>
                {{'form-file.selected' | translate}} <i>{{fileName}}</i>
            </div>
        </div>
    </div>
</ng-container>
