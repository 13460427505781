import {ChangeDetectorRef, Component, ElementRef, Renderer2, ViewChild} from '@angular/core';
import 'moment/locale/nl';
import {AbstractFormFieldComponent} from '../abstract/abstract-form-field.component';
import {ValidationConstraintService} from '../../services/validation-constraint.service';
import {TooltipService} from '../../../../services/tooltip/tooltip.service';
import {GlobalModel} from '../../../../services/state/global.model';
import * as moment from 'moment/moment';
import {FormDataService} from '../../services/form-data.service';
import {GlobalAlertService} from '../../../../../wrapper/global-alert/global-alert.service';
import {TranslateService} from '../../../../services/translate/translate.service';
import {TableWidgetData} from './form-table.component';
import {IMyDate} from 'angular-mydatepicker';
import {LoggerService} from "../../../../services/logger/logger.service";

@Component({template: ''})
export class AbstractFormWidget extends AbstractFormFieldComponent {
    private static readonly DEFAULT_DAY_RANGE: number = 3;

    infoText: string;
    protected dateNow = new Date();
    public endDate: IMyDate;
    public startDate: IMyDate;
    showDatePickers: boolean = true;
    isLoading: boolean = false;
    protected widgetDataUrl: string = '';

    disableUntil: IMyDate = { //Disable tomorrow and further
        year: this.dateNow.getFullYear(),
        month: this.dateNow.getMonth(),
        day: this.dateNow.getDate() + 1,
    };

    constructor(public renderer: Renderer2, public validationConstraintService: ValidationConstraintService, public tooltipService: TooltipService, public model: GlobalModel, public formDataService: FormDataService, public globalAlertService: GlobalAlertService, public ts: TranslateService, public cd: ChangeDetectorRef, protected logger:LoggerService) {
        super(renderer, validationConstraintService, tooltipService, logger);
    }

    public onEndDateChanged(isoString: string): void {
        const date = new Date(isoString);
        const mySingleDateModel = {
            date: {
                year: date.getFullYear(),
                month: date.getMonth()+1,
                day: date.getDate()
            },
            jsDate: date
        };
        this.model.storedEndDates[this.config.name] = mySingleDateModel.date;
        this.endDate = mySingleDateModel.date;
        this.getWidgetData(this.widgetDataUrl, this.startDate, this.endDate);
    }

    public onStartDateChanged(isoString: string): void {
        const date = new Date(isoString);
        const mySingleDateModel = {
            date: {
                year: date.getFullYear(),
                month: date.getMonth()+1,
                day: date.getDate()
            },
            jsDate: date
        };
        this.model.storedStartDates[this.config.name] = mySingleDateModel.date;
        this.startDate = mySingleDateModel.date;
        this.getWidgetData(this.widgetDataUrl, this.startDate, this.endDate);
    }

    protected initWidget(widgetDataUrl: string): void {
        this.initDates();
        this.loadWidgetData(widgetDataUrl);
    }

    protected loadWidgetData(widgetDataUrl: string): void {
        if (widgetDataUrl) {
            this.widgetDataUrl = widgetDataUrl;
            this.getWidgetData(widgetDataUrl, this.startDate, this.endDate);
        }
        else {
            this.globalAlertService.addAlert(this.ts.translate('Formulier incompleet'), this.ts.translate('Geen URL meegegeven'), this.ts.translate('De graph verwacht een URL, maar die is niet meegegeven'));
        }
    }

    protected handleAfterViewInit(): void {

    }

    private initDates(): void {
        if (this.config.attr && this.config.attr.showDatePickers != undefined) {
            this.showDatePickers = this.config.attr.showDatePickers == true;
        }

        if (!this.showDatePickers) {
            return;
        }

        //Set default dates
        const dateNow = moment();
        this.endDate = {
            year: parseInt(dateNow.format('YYYY')),
            month: parseInt(dateNow.format('M')),
            day: parseInt(dateNow.format('D')),
        };
        dateNow.subtract(AbstractFormWidget.DEFAULT_DAY_RANGE, 'days');
        this.startDate = {
            year: parseInt(dateNow.format('YYYY')),
            month: parseInt(dateNow.format('M')),
            day: parseInt(dateNow.format('D')),
        };

        this.logger.log('[AbstractFormWidget] ', this.config);
        //Override defaults with config data
        if (this.config && this.config.attr) {
            if (this.config.attr.endDate || (this.config.attr.graphs && this.config.attr.graphs[0].endDate)) {
                const configEndDate = moment(this.config.attr.endDate ? '' + this.config.attr.endDate : this.config.attr.graphs[0].endDate);
                this.endDate = {
                    year: +configEndDate.format('YYYY'),
                    month: +configEndDate.format('M'),
                    day: +configEndDate.format('D'),
                };
            }

            if (this.config.attr.startDate || (this.config.attr.graphs && this.config.attr.graphs[0].startDate)) {
                const configStartDate = moment(this.config.attr.startDate ? '' + this.config.attr.startDate : this.config.attr.graphs[0].startDate);
                this.startDate = {
                    year: +configStartDate.format('YYYY'),
                    month: +configStartDate.format('M'),
                    day: +configStartDate.format('D'),
                };
            }
        }

        //Override config dates with user selected dates
        if (this.model.storedStartDates[this.config.name]) {
            this.startDate = this.model.storedStartDates[this.config.name];
        }

        if (this.model.storedEndDates[this.config.name]) {
            this.endDate = this.model.storedEndDates[this.config.name];
        }
    }

    protected getWidgetData(url: string, startDate: IMyDate, endDate: IMyDate): void {
        if (url == '') {
            this.logger.log('[FormGraphComponent] ' + 'WARNING: No valid url for linegraph');
            return;
        }

        this.isLoading = true;
        let fullUrl: string = url;
        if (this.showDatePickers) {
            fullUrl += '/' + this.getFormattedMyDate(startDate) + '/' + this.getFormattedMyDate(endDate);
        }

        this.formDataService.getGraphData(fullUrl,
            (json: TableWidgetData) => {
                this.isLoading = false;

                this.handleWidgetDataReceived(json);
            },
        );
    }

    protected handleWidgetDataReceived(json: TableWidgetData): void {

    }

    //Can also be done with Moment, but this is so simple, a simple string will suffice
    protected getFormattedMyDate(myDate: IMyDate): string {
        return myDate.year + '-' + myDate.month + '-' + myDate.day;
    }

    ngOnDestroy() {
        super.ngOnDestroy();

    }

    get PLACEMENT_BOTTOM(): string {
        return TooltipService.PLACEMENT_BOTTOM;
    }
}
