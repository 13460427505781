import {
    Component, ElementRef, ChangeDetectionStrategy, ChangeDetectorRef
} from '@angular/core';
import {MenuSearchService} from "./menu-search.service";
import {Router} from "@angular/router";
import {GlobalModel} from "../../../shared/services/state/global.model";
import {TranslateService} from "../../../shared/services/translate/translate.service";
import {MenuComponent} from '../menu.component';
import {StorageService} from "../../../shared/services/storage/storage.service";
import {MenuSearchComponent} from "./menu-search.component";

@Component ({
    selector: 'menu-search-mobile-component',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: 'menu-search.component.html',
    styleUrls: ['./menu-search.component.scss']
})

export class MenuSearchMobileComponent extends MenuSearchComponent{
    constructor(protected elementRef:ElementRef, protected searchService:MenuSearchService, protected cd:ChangeDetectorRef, protected router:Router, public model:GlobalModel, protected ts:TranslateService, public menuComponent:MenuComponent,protected storageService: StorageService) {
        super(elementRef,searchService,cd,router,model,ts,menuComponent,storageService);
    }
}
