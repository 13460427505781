<login-header (flippingLanguage)="handleFlippingLanguage($event)"></login-header>
<div class="login-background">
  <div *ngIf="isLoginVisible(_user); else loading_content" class="login-container">
    <div class="login-container-inner d-flex align-items-center justify-content-between">
      <div class="login-notify-outer">
        <login-notify></login-notify>
      </div>
      <div class="loading-form-container"></div>
      <div class="login-container-right d-flex align-items-center">
        <div class="login-form">
          <form [formGroup]="passwordChangeForm" novalidate (globalKeydownOutsideAngular)="handleKeyDown($event)">
            <h2 class="password-forget-title">{{formTitle}}</h2>
            <div class="password-forget-subtext">{{formSubTitle}}</div>
            <div class="input-group">
              <i class="material-icons">face</i>
              <input class="form-control" type="text" formControlName="username" placeholder="{{formUsername}}" #username/>
            </div>
            <div *ngIf="failure" class="animated fadeIn alert alert-{{ failure.severity }} login-alert" role="alert">
              <strong>{{ failure.title }}</strong> {{failure.message}}
            </div>
            <div class="login-btn-container">
              <lumi-button (onClick)="handleClickSend($event)"
                           [disabled]="disableSubmitButton"
                           [rank]="'secondary'"
                           [size]="'large'"
                           [fullWidth]="true"
                           [label]="formSubmit">
              </lumi-button>
            </div>
            <div class="login-pass-forget"><a (click)="handleCancel($event)">{{formLogin}}</a></div>
          </form>
        </div>
      </div>
    </div>
    <login-footer></login-footer>
  </div>
  <ng-template #loading_content>
    <div class="loading-filler"></div>
    <div class="loading-form-container d-flex">
      <div class="px-5 py-3 loading-form w-100 d-flex justify-content-center align-items-center flex-column" style="max-width: 30rem;">
        <img class="mx-auto img-fluid d-flex pb-4" style="display: none" src="{{getLogoPath()}}">
        <div class="loader"></div>
      </div>
    </div>
  </ng-template>
</div>