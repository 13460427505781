import {AfterViewInit, Component, HostBinding, Renderer2} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {AbstractFormFieldComponent} from '../abstract/abstract-form-field.component';
import {ValidationConstraintService} from '../../services/validation-constraint.service';
import {TooltipService} from '../../../../services/tooltip/tooltip.service';
import {LoggerService} from "../../../../services/logger/logger.service";

@Component({
    selector: 'form-file',
    templateUrl: './form-file.component.html',
})
export class FormFileComponent extends AbstractFormFieldComponent implements AfterViewInit {

    @HostBinding('class') hostClasses = 'd-flex flex-column';

    config: any;
    group: UntypedFormGroup;
    readOnly: boolean;
    invalidControlsErrors: any;
    public selectedFiles: string[] = [];

    public fileName: string = '';
    fileType: string = '';
    private reader: FileReader = new FileReader();

    constructor(public renderer: Renderer2, public validationConstraintService: ValidationConstraintService, public tooltipService: TooltipService, protected logger:LoggerService) {
        super(renderer, validationConstraintService, tooltipService, logger);
    }

    ngOnInit() {
        if (this.config) {
            if (this.config.attr && this.config.attr.fileType) {
                this.fileType = this.config.attr.fileType;
            }
        }
    }

    ngAfterViewInit() {
        //Manually trigger a formchange, so validation is checked
        this.group.controls[this.config.name].updateValueAndValidity();
    }
    
    onInputChanged(files: FileList) {
        this.fileName = '';

        if (files && files.length > 0) {
            
            Array.from(files).forEach((file: File) => {
                this.logger.log('[FormFileComponent] ' + 'event.target.files: ', file);
    
                this.fileName = file.name;
                
                //TODO: enable als je file voor het echie wilt versturen
                // const [file] = event.target.files;
                this.reader.readAsDataURL(file);
    
                this.reader.onload = () => {
                    //Add a fake form control. this will be replaced later, in the service (you are not allowed to change the value of a file-type input. so editing needs to be done later, in the json)
                    this.group.addControl(this.config.name + '_content', new UntypedFormControl(''));
                    this.group.controls[this.config.name + '_content'].patchValue(this.reader.result);
    
                    //TODO: dit is wel een beetje lelijk, na de eerste keer een bestand kiezen komt deze input altijd door de required-validatie heen. Maar opzich kan je hem niet meer leeghalen, dus het is redelijk safe
                    //Fix for Safari, required on a file input doen't work, so remove the validation after first input
                    this.group.controls[this.config.name].clearValidators();
                    this.group.controls[this.config.name].updateValueAndValidity();
                };
            });
        }
    }

    disabled(): string|null {
        let state = null;

        if (this.readOnly === true) {
            state = '';
        }
        else if (this.readOnly === false) {
            state = null;
        }
        if (this.config.disabled === true) {
            state = '';
        }

        return state;
    }

    disabledClass(): string {
        let className = null;

        if (this.readOnly === true) {
            className = 'disabled';
        }
        else if (this.readOnly === false) {
            className = '';
        }
        if (this.config.disabled === true) {
            className = 'disabled';
        }

        return className + ' w-100';
    }

    stringify(json: object): string {
        return JSON.stringify(json);
    }
}
