import {ChangeDetectorRef, Component, EventEmitter, Output, Renderer2, ViewChild} from '@angular/core';
import {AbstractFormFieldComponent} from '../abstract/abstract-form-field.component';
import {ValidationConstraintService} from '../../services/validation-constraint.service';
import {TooltipService} from '../../../../services/tooltip/tooltip.service';
import {GlobalModel} from '../../../../services/state/global.model';
import {FormDataService} from '../../services/form-data.service';
import {GlobalAlertService} from '../../../../../wrapper/global-alert/global-alert.service';
import {TranslateService} from '../../../../services/translate/translate.service';
import { FormEvent } from '../../containers/form/form.interface';
import {
    FormWorkActivityAttrConfig,
    WorkActivity
} from './form-work-activity.interface';
import {ListItem, ListItemMenuItemEvent} from '../../../commonUI/list/complex-list-item/complex-list-item.interface';
import { AppSettings } from 'src/app/app.settings';
import {ButtonCode} from '../../../../../wrapper/global-alert/global-popup';
import {LoggerService} from "../../../../services/logger/logger.service";

@Component({
    selector: 'form-work-activity',
    templateUrl: './form-work-activity.component.html'
})

export class FormWorkActivityComponent extends AbstractFormFieldComponent {
    @Output() onComponentEvent: EventEmitter<any> = new EventEmitter();

    public activities: WorkActivity[] = [];
    
    public AppSettings = AppSettings;

    constructor(
        public renderer: Renderer2,
        public validationConstraintService: ValidationConstraintService,
        public tooltipService: TooltipService,
        private model: GlobalModel,
        private formDataService: FormDataService,
        private cd: ChangeDetectorRef,
        private globalAlertService: GlobalAlertService,
        public ts: TranslateService,
        protected logger:LoggerService
    ) {
        super(renderer, validationConstraintService, tooltipService, logger);
    }

    ngOnInit() {
        // Set the logs for the view
        if (this.config && this.config.attr) {
            if (this.config.attr.activities) {
                const attrConfig: FormWorkActivityAttrConfig = this.config.attr;
                this.activities = attrConfig.activities.map(activity => {
                    activity.listItem = this.getListItem(activity);
                    return activity;
                });
            }
        }
    }
    
    public isDeletable(activity: any): boolean {
        return activity.deleteUrl && activity.deleteUrl != '';
    }
    
    public isUpdatable(activity: any): boolean {
        return activity.editUrl && activity.editUrl != '';
    }
    
    public handleClickUpdateActivity(event: MouseEvent, activity: any): void {
        let baseObjectId: number = this.rootConfig.base_object_id;

        this.globalAlertService.addPopupCreateWorkActivity(baseObjectId, activity.editUrl,  activity.saveEditUrl, null,
            () => {
                this.onComponentEvent.emit({event: FormEvent.WORK_ACTIVITY_CREATE_SUCCESS, data: {referenceId: baseObjectId}});
            }, () => {
                // Move is canceled, or failed. Do nothing
            });
    }
    
    public handleClickDeleteActivity(event: MouseEvent, activity: any): void {
        this.globalAlertService.addPopup(this.ts.translate('activity.deletetitle'), this.ts.translate('activity.delete'),
            [
                {label: this.ts.translate('Annuleren'), code: ButtonCode.ANNULEREN, isPrimary: true},
                {label: this.ts.translate('Verwijderen'), code: ButtonCode.DELETE,
                callback: () => {

                    // Show grayed-out activity until delete call finished
                    activity.isDeleted = true;
                    this.cd.detectChanges();

                    this.formDataService.deleteWorkActivity(activity.deleteUrl,
                        () => {
                            // Remove the old activity, client side
                            let deleteIndex: number = this.activities.indexOf(activity);

                            if (deleteIndex > -1) {
                                this.activities.splice(deleteIndex, 1);
                                this.cd.detectChanges();
                            }

                            this.onComponentEvent.emit({event: FormEvent.WORK_ACTIVITY_DELETE_SUCCESS, data: {}});
                        }, () => {
                            activity.isDeleted = false;
                            this.cd.detectChanges();
                        }, () => {
                            activity.isDeleted = false;
                            this.cd.detectChanges();
                        });
                },
                  isPrimary: false}, ], () => {});
    }
    
    private getListItem(activity: WorkActivity): ListItem {
        const item: ListItem = {
            id: JSON.stringify(activity),
            title: activity.name !== '' ? activity.name : this.ts.translate('activity.unknown'),
            icon: 'build',
            menuTitle: this.ts.translate('activity.menu'),
            menuItems: []
        };
        if (this.isUpdatable(activity)) {
            item.menuItems.push({
                label: this.ts.translate('menuItem.edit'),
                icon: 'edit',
                action: 'edit'
            });
        }
        if (this.isDeletable(activity)) {
            item.menuItems.push({
                label: this.ts.translate('menuItem.delete'),
                icon: 'delete',
                action: 'delete'
            });
        }
        return item;
    }
    
    public onWorkActivityAction($event: ListItemMenuItemEvent, activity: WorkActivity): void {
        switch ($event.action) {
            case 'edit':
                this.handleClickUpdateActivity(null, activity);
                break;
            case 'delete':
                this.handleClickDeleteActivity(null, activity);
        }
    }
}
