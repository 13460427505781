import {Component, Input, OnInit} from '@angular/core';
import {TranslateService} from '../../../../services/translate/translate.service';
import {GlobalModel} from '../../../../services/state/global.model';
import {take} from 'rxjs/operators';
import {GuiService} from '../../gui.service';
import {GuiChildConfig} from '../../gui.interface';

@Component({
    selector: 'gateway-reset',
    templateUrl: './gateway-reset.component.html',
    styleUrls: ['./gateway-reset.component.scss']
})
export class GatewayResetComponent implements OnInit {
    @Input('config') config:GuiChildConfig;

    public timestamp:string = '';

    constructor(
        private ts:TranslateService,
        private model:GlobalModel,
        private guiService:GuiService
    ) {}

    ngOnInit(): void {
        this.timestamp = this.ts.translate('gatewayreset.defaulttext')
    }

    public handleClickSend() {
        const currentTime = new Date(Date.now());
        this.timestamp = this.ts.translate('gatewayreset.send') + currentTime.toTimeString().split(' ', 1)[0]

        this.guiService.sendResetGateway(this.config.url_prefix+'reset-rf3').pipe(
            take(1)
        ).subscribe(
            (success) => {
                this.timestamp = this.ts.translate('gatewayreset.reset') + currentTime.toTimeString().split(' ', 1)[0]
            },
            (error) => {
                this.timestamp = this.ts.translate('gatewayreset.failure') + currentTime.toTimeString().split(' ', 1)[0]
            }
        )
    }
}
