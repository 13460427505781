import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpService2} from '../../services/http-service-2.0/http2.0.service';
import {BasicHttpGetResult} from '../../services/http-service-2.0/http.interface';

@Injectable({
    providedIn: 'root'
})
export class GuiService {

    constructor(
        protected httpService2: HttpService2,
    ) {
    }

    public sendResetGateway(url: string): Observable<BasicHttpGetResult> {
        return this.httpService2.doGetRequest(url);
    }
}
