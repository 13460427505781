import {AfterViewInit, Component} from '@angular/core';
import {AppSettings} from '../../app.settings';

export const PREFIX = '--';

@Component({
    selector: 'sass-helper',
    template: '<div></div>'
})
export class SassHelperComponent implements AfterViewInit {
    // see scss/luminizer/_variables.scss -> body
    private cssColors  = [
        'color_light_grey',
        'color_light_blue',
        'selection-blue',
        'selection-gray',
        'color_text_darkgray',
        'color_dark-grey',
        'color_darker-grey',
        'color_grey-default-label'
    ];
    
    constructor() { }
    
    ngAfterViewInit() {
        this.cssColors.forEach(name => {
            AppSettings.setColor(name, this.readProperty(name));
        });
    }
    
    /**
     * Read the custom property of body section with given name.
     * @param name The property name.
     * @returns The property value.
     */
    readProperty(name: string): string {
        const bodyStyles = window.getComputedStyle(document.body);
        return bodyStyles.getPropertyValue(PREFIX + name);
    }
}
