/**
 * Created by Christiaan on 01/03/2017.
 */
import {
    AfterViewInit,
    ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, ViewChild
} from '@angular/core';

import {GlobalAlertService} from '../global-alert.service';
import {GlobalPopup} from '../global-popup';
import {HTTPError} from '../../../shared/services/http/http-error';
import {RequestFailure} from '../../../shared/services/http/request-failure';
import {FormDataService} from '../../../shared/components/form/services/form-data.service';
import {GlobalModel} from '../../../shared/services/state/global.model';
import {FormComponent} from '../../../shared/components/form/containers/form/form.component';
import {TranslateService} from '../../../shared/services/translate/translate.service';
import {AbstractBasePopupComponent} from './abstract-base-popup.component';
import {TooltipService} from '../../../shared/services/tooltip/tooltip.service';
import { FormEvent } from '../../../shared/components/form/containers/form/form.interface';
import {LoggerService} from "../../../shared/services/logger/logger.service";

@Component ({
    'selector': 'global-popup-view-stedin-order-component',
    'changeDetection': ChangeDetectionStrategy.OnPush,
    'template': `
        <default-popup-wrapper-component [componentRef]="this"
                                         [disableButtons]="true"
                                         [globalPopup]="globalPopup"
                                         [showLoader]="isLoading"
                                         [hideButtons]="true"
                                         (onClose)="closePopup($event)">
            <div class="pr-0">
                <div *ngIf="formData && config">
                    <form-component #form
                            [config]="config"
                            [label]="formData.schema.label"
                            [name]="getFormName()"
                            [validationConstraints]="validationConstraints"
                            [invalidControlsErrors]="invalidControlsErrors"
                            [readOnly]="true"
                            [formIsSubmitted]="formIsSubmitted">
                    </form-component>
                </div>
            </div>
        </default-popup-wrapper-component>
    `
})

export class GlobalPopupViewStedinOrderComponent extends AbstractBasePopupComponent implements AfterViewInit
{
    @Input() requestUrl: string = '';
    @Input() submitUrl: string = '';

    @ViewChild('form', {'static': false}) form: any;

    // tslint:disable-next-line:max-line-length
    public constructor(protected cd: ChangeDetectorRef,
                       protected globalAlertService: GlobalAlertService,
                       protected formDataService: FormDataService,
                       protected elementRef: ElementRef,
                       private model: GlobalModel,
                       protected ts: TranslateService,
                       protected tooltipService: TooltipService,
                       protected logger:LoggerService) {
        super(cd, globalAlertService, elementRef, ts, tooltipService, logger);
    }

    ngAfterViewInit() {
        this.requestUrl = this.globalPopup.data.requestUrl;
        this.requestFormData();
    }

    handlePopupAction(event: MouseEvent, alert: GlobalPopup, button: any): void {
        if (button === this.submitButton){
            this.form.handleComponentEvent({'event': FormEvent.SAVE, 'data': {}});
        }else{
            this.onPopupAction.emit({'event': event, 'alert': alert, 'button': button});
        }
    }

    public closePopup(event: any): void
    {
        this.doDefaultCloseHandling(event, false);
    }

    public requestFormData(): void
    {
        this.formDataService.getFormData(this.requestUrl,
            (formData: any) => {

                // TODO: pas hier de latlng van de popup.data toe op de formData, als beschikbaar
                // En update dus de kaart en de locatie enzo

                // setTimeout(() => {
                    this.formData = formData;
                    this.isLoading = false;
                    this.cd.detectChanges();
                // });
            },
            () => {
                this.closePopup(null);
            },
            () => {
                this.closePopup(null);
            }
        );
    }
}
