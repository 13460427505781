import {Injectable} from '@angular/core';
import {INotifications} from './notifications.interface';
import {HttpService2} from '../../shared/services/http-service-2.0/http2.0.service';
import {Observable} from "rxjs";
import {LoggerService} from "../../shared/services/logger/logger.service";
import {FormPostResult} from '../../shared/services/http-service-2.0/http.interface';

@Injectable()
export class NotificationsService {

    constructor(protected httpService2: HttpService2, protected logger:LoggerService) {}

    public changeIsNewState(notificationId:number):Observable<FormPostResult>{
        this.logger.log('[NotificationsService] - Post new state for notificationId: '+notificationId);
        return <Observable<FormPostResult>>this.httpService2.doFormPostRequest(`push-notifications/change-state`, JSON.parse(JSON.stringify({notificationId: notificationId})), false);
    }

    public getNotificationList():Observable<INotifications[]> {
        this.logger.log('[NotificationsService] - Get notification list');
        return <Observable<INotifications[]>>this.httpService2.doGetRequest(`push-notifications/get-notifications`,false);
    }
}
