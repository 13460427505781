import {Component, Input, OnInit} from '@angular/core';
import {CheckActivityCellChild} from './check-activity-cell-child.interface';

@Component({
  selector: 'check-activity-cell-child',
  templateUrl: './check-activity-cell-child.component.html',
})
export class CheckActivityCellChildComponent implements OnInit {
  @Input() cellChild: CheckActivityCellChild;

  constructor() { }

  ngOnInit() {
  }

}
