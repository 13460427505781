
<div *ngIf="!isStedin() && !isRWS()" [title]="'Legenda' | translate">
    <div class="d-flex map-legend-row pt-3" style="font-weight: bold">
        <div class="w-50 d-flex flex-column">
            <div class="d-flex align-items-center">{{'Type' | translate}}</div>
        </div>
        <div class="w-50 d-flex flex-column">
            <div class="d-flex align-items-center">{{'Kleur' | translate}}</div>
        </div>
    </div>
    <ng-container *ngIf="iconSet === 'mechanic'">
        <div *ngIf="iconsLoading" class="py-1 d-flex justify-content-center position-absolute">
            <div class="loader"></div>
        </div>
        <ng-container *ngFor="let icon of icons">
            <div class="d-flex map-legend-row">
                <div class="w-50 d-flex flex-column">
                    <div class="d-flex align-items-center">
                        <div class="pr-2"><img src="{{getImage(icon.imgName)}}"/></div>
                        {{icon.name}}
                    </div>
                </div>
                <div class="w-50 d-flex flex-column">
                    <ng-container *ngFor="let variation of icon.variations">
                        <div class="d-flex align-items-center">
                            <div class="pr-2"><img src="{{getImage(variation.imgName)}}"/></div>
                            {{variation.name}}
                        </div>
                    </ng-container>
                </div>
            </div>
        </ng-container>
    </ng-container>
    <ng-container *ngIf="iconSet === 'smartdevices'">
        <div class="d-flex map-legend-row">
            <div class="w-50 d-flex flex-column justify-content-center">
                <div class="d-flex align-items-center">
                    <div class="pr-2"><img src="{{getImage('sta-blue')}}"/></div>
                    {{'devicestate.deviceStatus' | translate}}
                </div>
            </div>
            <div class="w-50 d-flex flex-column">
                <div class="d-flex align-items-center">
                    <div class="pr-2"><img src="{{getImage('sta-light-blue')}}"/></div>
                    {{'new' | translate}}
                </div>
                <div class="d-flex align-items-center">
                    <div class="pr-2"><img src="{{getImage('sta-grey')}}"/></div>
                    {{'offline' | translate}}
                </div>
                <div class="d-flex align-items-center">
                    <div class="pr-2"><img src="{{getImage('sta-red')}}"/></div>
                    {{'fout' | translate}}
                </div>
                <div class="d-flex align-items-center">
                    <div class="pr-2">
                        <img src="{{getImage('sta-blue-b')}}"/>/<img src="{{getImage('sta-light-blue-b')}}"/>/<img src="{{getImage('sta-grey-b')}}"/>/<img src="{{getImage('sta-red-b')}}"/>
                    </div>
                    {{'attention' | translate}}
                </div>
            </div>
        </div>
        <div class="d-flex map-legend-row">
            <div class="w-50 d-flex flex-column justify-content-center">
                <div class="d-flex align-items-center">
                    <div class="pr-2"><img src="{{getImage('gtw-blue')}}"/></div>
                    {{'Segment' | translate}}
                </div>
            </div>
            <div class="w-50 d-flex flex-column">
                <div class="d-flex align-items-center">
                    <div class="pr-2"><img src="{{getImage('gtw-light-blue')}}"/></div>
                    {{'new' | translate}}
                </div>
                <div class="d-flex align-items-center">
                    <div class="pr-2"><img src="{{getImage('gtw-grey')}}"/></div>
                    {{'offline' | translate}}
                </div>
                <div class="d-flex align-items-center">
                    <div class="pr-2"><img src="{{getImage('gtw-red')}}"/></div>
                    {{'fout' | translate}}
                </div>
                <div class="d-flex align-items-center">
                    <div class="pr-2">
                        <img src="{{getImage('gtw-blue-b')}}"/>/<img src="{{getImage('gtw-light-blue-b')}}"/>/<img src="{{getImage('gtw-grey-b')}}"/>/<img src="{{getImage('gtw-red-b')}}"/>
                    </div>
                    {{'attention' | translate}}
                </div>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="iconSet === 'default'">
        <div class="d-flex map-legend-row">
            <div class="w-50 d-flex flex-column">
                <div class="d-flex align-items-center">
                    <div class="pr-2"><img src="{{getImage('mst-blue')}}"/></div>
                    {{'Lichtpunt' | translate}}
                </div>
            </div>
            <div class="w-50 d-flex flex-column">
                <div class="d-flex align-items-center">
                    <div class="pr-2"><img src="{{getImage('mst-orange')}}"/></div>
                    {{'Met werkorder' | translate}}
                </div>
            </div>
        </div>
        <div class="d-flex map-legend-row">
            <div class="w-50 d-flex flex-column justify-content-center">
                <div class="d-flex align-items-center">
                    <div class="pr-2"><img src="{{getImage('dyn-blue')}}"/></div>
                    {{'Dynamisch lichtpunt' | translate}}
                </div>
            </div>
            <div class="w-50 d-flex flex-column">
                <div class="d-flex align-items-center">
                    <div class="pr-2"><img src="{{getImage('dyn-purple')}}"/></div>
                    {{'Niet in bedrijf' | translate}}
                </div>
                <div class="d-flex align-items-center">
                    <div class="pr-2"><img src="{{getImage('dyn-red')}}"/></div>
                    {{'Lamp/VSA-fout' | translate}}
                </div>
                <div class="d-flex align-items-center">
                    <div class="pr-2"><img src="{{getImage('dyn-grey')}}"/></div>
                    {{'OLC/gateway offline' | translate}}
                </div>
                <div class="d-flex align-items-center">
                    <div class="pr-2">
                        <img src="{{getImage('dyn-orange')}}"/>/<img
                            src="{{getImage('dyn-orange-purple')}}"/>/<img
                            src="{{getImage('dyn-orange-red')}}"/>/<img src="{{getImage('dyn-orange-grey')}}"/>
                    </div>
                    {{'Met werkorder' | translate}}
                </div>
            </div>
        </div>
        <div class="d-flex map-legend-row">
            <div class="w-50 d-flex flex-column">
                <div class="d-flex align-items-center">
                    <div class="pr-2"><img src="{{getImage('arm-blue')}}"/></div>
                    {{'Armatuur' | translate}}
                </div>
                <div class="d-flex align-items-center">
                    <div class="pr-2"><img src="{{getImage('lmp-blue')}}"/></div>
                    {{'Lamp' | translate}}
                </div>
                <div class="d-flex align-items-center">
                    <div class="pr-2"><img src="{{getImage('vsa-blue')}}"/></div>
                    {{'VSA' | translate}}
                </div>
            </div>
        </div>
        <div class="d-flex map-legend-row">
            <div class="w-50 d-flex flex-column justify-content-center">
                <div class="d-flex align-items-center">
                    <div class="pr-2"><img src="{{getImage('olc-blue')}}"/></div>
                    {{'OLC' | translate}}
                </div>
            </div>
            <div class="w-50 d-flex flex-column">
                <div class="d-flex align-items-center">
                    <div class="pr-2"><img src="{{getImage('olc-purple')}}"/></div>
                    {{'Niet in bedrijf' | translate}}
                </div>
                <div class="d-flex align-items-center">
                    <div class="pr-2"><img src="{{getImage('olc-red')}}"/></div>
                    {{'Lamp/VSA-fout' | translate}}
                </div>
                <div class="d-flex align-items-center">
                    <div class="pr-2"><img src="{{getImage('olc-grey')}}"/></div>
                    {{'OLC/gateway offline' | translate}}
                </div>
            </div>
        </div>
        <div class="d-flex map-legend-row">
            <div class="w-50 d-flex flex-column">
                <div class="d-flex align-items-center">
                    <div class="pr-2"><img src="{{getImage('sch-blue')}}"/></div>
                    {{'Schakelkast' | translate}}
                </div>
            </div>
            <div class="w-50 d-flex flex-column">
                <div class="d-flex align-items-center">
                    <div class="pr-2"><img src="{{getImage('sch-orange')}}"/></div>
                    {{'Met werkorder' | translate}}
                </div>
            </div>
        </div>
        <div class="d-flex map-legend-row">
            <div class="w-50 d-flex flex-column">
                <div class="d-flex align-items-center">
                    <div class="pr-2"><img src="{{getImage('seg-blue')}}"/></div>
                    {{'Segment' | translate}}
                </div>
            </div>
        </div>
        <div class="d-flex map-legend-row">
            <div class="w-50 d-flex flex-column justify-content-center">
                <div class="d-flex align-items-center">
                    <div class="pr-2"><img src="{{getImage('ond-blue')}}"/></div>
                    {{'Werkorder' | translate}}
                </div>
            </div>
            <div class="w-50 d-flex flex-column justify-content-center">
                <!-- TODO: Project en werkorder icons zijn zelf in te stellen. Call naar de backend om status + icon op te halen. -->
                <div class="d-flex align-items-center">
                    {{'iconlegend.masterdata' | translate}}
                </div>
            </div>
        </div>
    </ng-container>
    <div class="d-flex map-legend-row">
        <div class="w-50 d-flex flex-column">
            <div class="d-flex align-items-center">
                <div class="pr-2"><img src="{{getImage(LOCATION_ICON_ACTIVE, false, false)}}"/></div>
                {{'location.live' | translate}}
            </div>
        </div>
        <div class="w-50 d-flex flex-column justify-content-center">
            <!--<div class="d-flex align-items-center">
                <div class="pr-2"><img src="{{getImage(LOCATION_ICON_ACTIVE, false)}}"/></div>{{'location.live' | translate}}
            </div>-->
            <div class="d-flex align-items-center">
                <div class="pr-2"><img src="{{getImage(LOCATION_ICON_INACTIVE, false, false)}}"/></div>
                {{'location.lastknown' | translate}}
            </div>
        </div>
    </div>
</div>

<div *ngIf="isStedin()" [title]="'Legenda' | translate">
    <div class="d-flex map-legend-row pt-3" style="font-weight: bold">
        <div class="w-50 d-flex flex-column">
            <div class="d-flex align-items-center">{{'Type' | translate}}</div>
        </div>
        <div class="w-50 d-flex flex-column">
            <div class="d-flex align-items-center">{{'Kleur' | translate}}</div>
        </div>
    </div>
    <div class="d-flex map-legend-row">
        <div class="w-50 d-flex flex-column">
            <div class="d-flex align-items-center">
                <div class="pr-2"><img src="{{getImage('ond-blue')}}"/></div>
                {{'Werkorder' | translate}}
            </div>
            <!--<div class="d-flex align-items-center">
                <div class="pr-2"><img src="{{getImage('mlt-blue')}}"/></div>{{'Meerdere bonnen' | translate}}
            </div>-->
        </div>
        <div class="w-50 d-flex flex-column justify-content-center">
            <!-- TODO: Project en werkorder icons zijn zelf in te stellen. Call naar de backend om status + icon op te halen. -->
            <div class="d-flex align-items-center">
                {{'iconlegend.masterdata' | translate}}
            </div>
<!--            <div class="d-flex align-items-center">-->
<!--                <div class="pr-2"><img src="{{getImage('ond-green')}}"/></div>-->
<!--                {{'Gerepareerd' | translate}}-->
<!--            </div>-->
<!--            <div class="d-flex align-items-center">-->
<!--                <div class="pr-2"><img src="{{getImage('ond-blue')}}"/></div>-->
<!--                {{'In uitvoering' | translate}}-->
<!--            </div>-->
<!--            <div class="d-flex align-items-center">-->
<!--                <div class="pr-2"><img src="{{getImage('ond-grey')}}"/></div>-->
<!--                {{'Onbehandelbaar' | translate}}-->
<!--            </div>-->
<!--            <div class="d-flex align-items-center">-->
<!--                <div class="pr-2"><img src="{{getImage('ond-red')}}"/></div>-->
<!--                {{'Calamiteit' | translate}}-->
<!--            </div>-->
<!--            <div class="d-flex align-items-center">-->
<!--                <div class="pr-2"><img src="{{getImage('ond-orange')}}"/></div>-->
<!--                {{'Toegewezen' | translate}}-->
<!--            </div>-->
        </div>
    </div>
    <div class="d-flex map-legend-row">
        <div class="w-50 d-flex flex-column">
            <div class="d-flex align-items-center">
                <div class="pr-2"><img src="{{getImage(LOCATION_ICON_ACTIVE, false, false)}}"/></div>
                {{'location.live' | translate}}
            </div>
        </div>
        <div class="w-50 d-flex flex-column justify-content-center">
            <!--<div class="d-flex align-items-center">
                <div class="pr-2"><img src="{{getImage(LOCATION_ICON_ACTIVE, false)}}"/></div>{{'location.live' | translate}}
            </div>-->
            <div class="d-flex align-items-center">
                <div class="pr-2"><img src="{{getImage(LOCATION_ICON_INACTIVE, false, false)}}"/></div>
                {{'location.lastknown' | translate}}
            </div>
        </div>
    </div>
</div>

<div *ngIf="isRWS()" [title]="'Legenda' | translate">
    <div class="d-flex map-legend-row pt-3" style="font-weight: bold">
        <div class="w-50 d-flex flex-column">
            <div class="d-flex align-items-center">{{'Type' | translate}}</div>
        </div>
        <div class="w-50 d-flex flex-column">
            <div class="d-flex align-items-center">{{'Kleur' | translate}}</div>
        </div>
    </div>
    <div class="d-flex map-legend-row">
        <div class="w-50 d-flex flex-column">
            <div class="d-flex align-items-center">
                <div class="pr-2"><img src="{{getImage('ond-blue')}}"/></div>
                {{'Segmentstatus' | translate}}
            </div>
            <!--<div class="d-flex align-items-center">
                <div class="pr-2"><img src="{{getImage('mlt-blue')}}"/></div>{{'Meerdere bonnen' | translate}}
            </div>-->
        </div>
        <div class="w-50 d-flex flex-column justify-content-center">
            <!-- Gegaan van wkr (werkorder) icon naar ond (onderhoud) icon -->
            <div class="d-flex align-items-center">
                <div class="pr-2"><img src="{{getImage('rws-seg-auto-aan', true)}}"/></div>
                {{'Automatisch aan' | translate}}
            </div>
            <div class="d-flex align-items-center">
                <div class="pr-2"><img src="{{getImage('rws-seg-auto-uit', true)}}"/></div>
                {{'Automatisch uit' | translate}}
            </div>
            <div class="d-flex align-items-center">
                <div class="pr-2"><img src="{{getImage('rws-seg-local-hand-aan', true)}}"/></div>
                {{'Handmatig aan' | translate}}
            </div>
            <div class="d-flex align-items-center">
                <div class="pr-2"><img src="{{getImage('rws-seg-local-wiu-aan', true)}}"/></div>
                {{'Handmatig aan voor werk in uitvoering' | translate}}
            </div>
            <div class="d-flex align-items-center">
                <div class="pr-2"><img src="{{getImage('rws-seg-local-wiu-uit', true)}}"/></div>
                {{'Handmatig uit voor werk in uitvoering' | translate}}
            </div>
            <div class="d-flex align-items-center">
                <div class="pr-2"><img src="{{getImage('rws-seg-remote-wiu-aan', true)}}"/></div>
                {{'Aan voor werk in uitvoering via Luminizer' | translate}}
            </div>
            <div class="d-flex align-items-center">
                <div class="pr-2"><img src="{{getImage('rws-seg-remote-wiu-uit', true)}}"/></div>
                {{'Uit voor werk in uitvoering via Luminizer' | translate}}
            </div>
            <div class="d-flex align-items-center">
                <div class="pr-2"><img src="{{getImage('rws-seg-remote-override-aan', true)}}"/></div>
                {{'Calamiteit. Override vanuit Luminizer' | translate}}
            </div>
            <div class="d-flex align-items-center">
                <div class="pr-2"><img src="{{getImage('rws-seg-defect', true)}}"/></div>
                {{'Defect' | translate}}
            </div>
            <div class="d-flex align-items-center">
                <div class="pr-2"><img src="{{getImage('rws-seg-offline', true)}}"/></div>
                {{'Geen communicatie mogelijk' | translate}}
            </div>
        </div>
    </div>
    <div class="d-flex map-legend-row">
        <div class="w-50 d-flex flex-column">
            <div class="d-flex align-items-center">
                <div class="pr-2"><img src="{{getImage(LOCATION_ICON_ACTIVE, false, false)}}"/></div>
                {{'location.live' | translate}}
            </div>
        </div>
        <div class="w-50 d-flex flex-column justify-content-center">
            <!--<div class="d-flex align-items-center">
                <div class="pr-2"><img src="{{getImage(LOCATION_ICON_ACTIVE, false)}}"/></div>{{'location.live' | translate}}
            </div>-->
            <div class="d-flex align-items-center">
                <div class="pr-2"><img src="{{getImage(LOCATION_ICON_INACTIVE, false, false)}}"/></div>
                {{'location.lastknown' | translate}}
            </div>
        </div>
    </div>
</div>
