import {Injectable} from '@angular/core';
import {OwlDateTimeIntl,DateTimeAdapter} from "ng-pick-datetime-ex";
import {TranslateService} from '../../../../services/translate/translate.service';

@Injectable() export class OwlDateTimeLocalization extends OwlDateTimeIntl{

    constructor(private translateService: TranslateService, dateTimeAdapter: DateTimeAdapter<any>) {
        super();
        dateTimeAdapter.setLocale(this.translateService.getLanguage());
    }

    /** A label for the cancel button */
    cancelBtnLabel= this.translateService.translate('datetimepicker.cancelBtnLabel');

    /** A label for the set button */
    setBtnLabel= this.translateService.translate('datetimepicker.setBtnLabel');

    /** A label for the range 'from' in picker info */
    rangeFromLabel= this.translateService.translate('datetimepicker.rangeFromLabel');

    /** A label for the range 'to' in picker info */
    rangeToLabel= this.translateService.translate('datetimepicker.rangeToLabel');
}

