<div class="form-group form-group--{{name}} {{getClassNames()}}" id="{{name}}" [hidden]="!isVisible()">
    <status-progress *ngIf="formGroup?.statusItems?.length > 1"
                     (onStatusClick)="handleStatusProgressClick($event)"
                     [statusItems]="formGroup.statusItems"
                     [disabled]="isArchived"
                     [subStatuses]="subStatuses">
    </status-progress>
    <sub-statuses [subStatuses]="subStatuses"
                  [disabled]="isArchived">
    </sub-statuses>
    <div (click)="handleClickExpand()"
         (keyup)="onKeyUp($event)"
         style="margin-left: -2px"
         class="form-group-label-container {{showLabel()?'mb-3':'m-0'}} {{!isCollapsible() || expanded?'':'collapsed'}}">
        <ng-container *ngIf="isCollapsible() && showLabel()">
            <i class="material-icons tree-node-icon"
               #expandSwitch
               tabindex="0"
               (keydown)="onKeyDownToggler($event)">{{expanded ? 'expand_more' : 'keyboard_arrow_right'}}</i>
        </ng-container>
        <div style="display: inline-flex; width: 100%; min-width:85%">
            <ng-container *ngIf="showLabel()">
                <div style="align-self: center" class="form-group-label form-group-label--{{name}} mb-0 text-truncate" [title]="label">{{label}}</div>
            </ng-container>
            <ng-container *ngIf="infoText">
                <help-icon-component [tooltipText]="infoText"
                                     [tooltipPlacement]="PLACEMENT_BOTTOM">
                </help-icon-component>
            </ng-container>
            <div style="margin-left: auto; margin-right: -5px; margin-top: -9px; margin-bottom: -10px;">
                <status-button *ngIf="formGroup"
                               [buttonLabel]="isArchived? ('status.button.archived' | translate) : null"
                               [disabled]="isStatusButtonDisabled"
                               [items]="buttonItems"
                               [actions]="buttonActions"
                               (selectionChanged)="handleSelectionChange($event)"
                               (onAction)="handleActionClick($event)">
                </status-button>
            </div>
        </div>
    </div>
    <div style="display: inherit" class="{{expanded?'':'d-none'}} w-100">
        <ng-container *ngFor="let childConfig of config.children">
            <ng-container *ngIf="notButtonsOnly(childConfig)"
                          fieldDirective
                          [config]="childConfig"
                          [rootConfig]="rootConfig"
                          [group]="form"
                          (onComponentEvent)="handleComponentEvent($event)"
                          [validationConstraints]="validationConstraints"
                          [invalidControlsErrors]="invalidControlsErrors"
                          [readOnly]="readOnly"
                          [formIsSubmitted]="formIsSubmitted">
            </ng-container>
        </ng-container>
    </div>
</div>
