import {Component, OnInit, Input, ChangeDetectorRef} from '@angular/core';
import {Widget} from "./widget";
import {WidgetService} from "./widget.service";
import {RequestFailure} from "../../../shared/services/http/request-failure";
import {GlobalAlertService} from "../../../wrapper/global-alert/global-alert.service";

@Component({
    selector: 'small-widget-component',
    template: `

    <div class="card widget-panel">
        <div class="card small-widget-title-container {{widget.iconClass}}">
            <i class="material-icons md-light md-24 widget-icon">{{widget.iconName}}</i>
        </div>
        <div class="small-widget-title"> 
            {{widget.title}}
        </div>
        <div class="{{textClass}}" [style.color]="widget.textColor" [innerHTML]="widget.value?widget.value:'...'">
        </div>
        <div *ngIf="widget.description" class="widget-subtext-container"> 
            <i class="material-icons md-18 widget-subtext-icon">info_outline</i>
            <div class="widget-subtext">
                {{widget.description}}
            </div>
        </div>
    </div>
`
})

export class SmallWidgetComponent implements OnInit {

    @Input('widget') public widget:Widget;

    public textClass:string = "small-widget-text";

    constructor(private widgetService:WidgetService, private globalAlertService:GlobalAlertService, private cd:ChangeDetectorRef) {}

    ngOnInit()
    {
        this.widgetService.getWidgetData(this.widget.id, "",
            (json:any) => {
                this.widget.value = json.value;
                this.widget.description = json.description;
                this.widget.textColor = json.textColor;
                this.widget.title = json.title?json.title:this.widget.title;
                this.cd.markForCheck();
            },
            (failure:RequestFailure) => {}
        );

        //Exceptions
        if (this.widget.code == "AREAAL_CODE" || this.widget.code == 'AREA_NAME') {
            this.textClass = "small-widget-resizeable-text";
        }

        //Handle invalid data
        if (!this.widget.iconClass || !this.widget.iconName || this.widget.iconName == "" || this.widget.iconClass == ""){
            this.globalAlertService.addAlert(GlobalAlertService.ALERT_TITLE_ERROR, "Unknown widget icon", "Voor small-widget-type [" + this.widget.code + "] is geen icon informatie gedefinieerd");
        }

        this.cd.markForCheck();
    }
}
