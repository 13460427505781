import {Component, DoCheck, ElementRef, OnDestroy} from '@angular/core';
import {Observable, Subscription, timer} from 'rxjs';
import {AppSettings} from '../../../app.settings';

/**
 * Created by Christiaan on 16/10/2017.
 */
@Component({
    selector: 'changeable-component',
    template: ''
})
export class ChangeableComponent implements DoCheck, OnDestroy {
    private static readonly QUICK_CHANGE_TIME: number = 100; //ms
    private static readonly STATE_RESET_TIME: number = 1000; //ms
    private static readonly MAX_QUICK_CHANGES: number = 5;

    private lastChange: Date = new Date();
    private currentTime: Date;
    private quickChangesDetected: number = 0;
    private handleChangeDetectionTimer: Observable<number> = timer(ChangeableComponent.STATE_RESET_TIME, 0);
    private subHandleChangeDetection: Subscription = new Subscription();

    constructor(protected elementRef: ElementRef) {
    }

    ngDoCheck() {
        if (AppSettings.ENABLE_VISIBLE_CHANGE_DETECTION) {
            this.currentTime = new Date();

            //this.logger.log("[ChangeableComponent] " + "TRIGGER CHANGEDETECTION: quickChangesDetected: " + this.quickChangesDetected + " tijd: " + (this.currentTime - this.lastChange));

            if ((this.currentTime.getTime() - this.lastChange.getTime()) < ChangeableComponent.QUICK_CHANGE_TIME) {
                this.quickChangesDetected++;
            }
            else {
                this.quickChangesDetected = 0;
            }

            this.lastChange = this.currentTime;

            if (this.quickChangesDetected > ChangeableComponent.MAX_QUICK_CHANGES) {
                //this.logger.log("[ChangeableComponent] " + "TE VEEL CHANGEDETECTION VOOR DIT COMPONENT", this.elementRef.nativeElement);
                this.elementRef.nativeElement.style.WebkitFilter = 'brightness(' + (100 - (this.quickChangesDetected * 4)) + '%)';
                //this.elementRef.nativeElement.style.transform = "rotate(" + (this.quickChangesDetected * 4) + "deg)";
                this.restartTimer();
            }
            else {
                this.elementRef.nativeElement.style.WebkitFilter = '';
                //this.elementRef.nativeElement.style.transform = "";
                this.stopTimer();
            }
        }
    }

    private restartTimer() {
        this.stopTimer();

        //Start a new timer
        this.subHandleChangeDetection = this.handleChangeDetectionTimer.subscribe(() => {
            //Stop this timer
            this.subHandleChangeDetection.unsubscribe();

            //Perform action
            this.elementRef.nativeElement.style.WebkitFilter = '';
            //this.elementRef.nativeElement.style.transform = "";
        });
    }

    private stopTimer() {
        //Stop running timers
        this.subHandleChangeDetection.unsubscribe();
    }

    ngOnDestroy() {
        this.stopTimer();
    }
}
