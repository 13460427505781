import {AfterViewInit, Component} from '@angular/core';
import 'moment/locale/nl';
import {FormInputComponent} from './form-input.component';

@Component({
    selector: 'form-input',
    template: `
        <ng-container [formGroup]="group">
            <form-component-label [batchUpdateMode]="batchUpdateMode" [config]="config" [included]="included"
                                  (onClickInclude)="handleClickInclude($event)"></form-component-label>
            <div class="range-field d-flex" style="align-items:center">
                <input
                        #currentFormField
                        [disableControl]="isControlDisabled()"
                        name="{{config.name}}"
                        type="range"
                        step="1"
                        [attr.min]="getMinimumValue()"
                        [attr.max]="getMaximumValue()"
                        [attr.placeholder]="config.placeholder"
                        [formControlName]="config.name"
                        [(ngModel)]="initialValue"
                        (input)="onFormInput($event)"
                >
            </div>
            <div class="range-value-feedback p-1 align-self-center">{{ valueFeedback }}</div>
        </ng-container>
    `,
})

export class FormRangeInputComponent extends FormInputComponent implements AfterViewInit {

    // TODO: de rest van de eriknismes hier uit halen...

    private static readonly DEFAULT_VALUE = '50';
    private static readonly DEFAULT_MIN_VALUE: number = 0;
    private static readonly DEFAULT_MAX_VALUE: number = 100;

    public valueFeedback = '';
    infoText: string;

    ngAfterViewInit(): void {
        this.setDefaultRangeValue();
    }

    private setDefaultRangeValue() {
        if (this.group.get(this.config.name)) {
            if (typeof this.group.get(this.config.name).value === 'undefined') {
                // when there is no pre-set value for a range input
                // explicitly set default to 50 in the form control
                // synchronizing it with the default behavior for MDB's implementation of a 'range' type input (centered dot on slider)
                this.group.get(this.config.name).patchValue(FormRangeInputComponent.DEFAULT_VALUE);
                this.group.get(this.config.name).markAsTouched({onlySelf: true});
                this.valueFeedback = FormRangeInputComponent.DEFAULT_VALUE;
            }
            else if (this.group.get(this.config.name).value) {
                this.valueFeedback = this.group.get(this.config.name).value;
            }
        }
    }

    public onFormInput(event: any) {
        if (event.target && event.target.value) {
            this.valueFeedback = event.target.value;
        }
    }

    getMinimumValue(): number {
        if (this.config.initialConstraints && this.config.initialConstraints.range && this.config.initialConstraints.range.min) {
            return this.config.initialConstraints.range.min;
        }
        else if (this.config.attr && this.config.attr.min) {
            return (typeof this.config.attr.min === 'number') ? this.config.attr.min : parseFloat('' + this.config.attr.min);
        }
        return FormRangeInputComponent.DEFAULT_MIN_VALUE; // default to 0(%)
    }

    getMaximumValue(): number {
        if (this.config.initialConstraints && this.config.initialConstraints.range && this.config.initialConstraints.range.max) {
            return this.config.initialConstraints.range.max;
        }
        else if (this.config.attr && this.config.attr.max) {
            return (typeof this.config.attr.max === 'number') ? this.config.attr.max : parseFloat('' + this.config.attr.max);
        }
        return FormRangeInputComponent.DEFAULT_MAX_VALUE; // default to 100(%)
    }
}
