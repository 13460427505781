<div class="file-management">
    <lumi-button *ngIf="!readonly && !isAddingFolder"
                 (onClick)="addNewFolder()"
                 [rank]="BUTTON_RANK.TERTIARY"
                 [icon]="'add'"
                 [disabled]="disabled"
                 [label]="'filemanager.addfolder' | translate"
                 [title]="'filemanager.addfolder' | translate">
    </lumi-button>
    <div class="fm-folder-container" *ngIf="isAddingFolder">
        <div class="fm-folder cursor-default">
            <i class="fm-folder-icon">add</i>
            <i class="fm-folder-icon">folder</i>
            <input type="text"
                   #folderNameInput
                   class="fm-folder-title"
                   style="height: auto"/>
            <lumi-button style="margin-right: 2rem;"
                         (onClick)="saveNewFolder()"
                         [rank]="BUTTON_RANK.TERTIARY"
                         [title]="'opslaan' | translate"
                         [size]="'small'"
                         [icon]="'save'">
            </lumi-button>
            <div class="fm-close-button"
                 title="{{'filemanager.close' | translate}}"
                 (click)="cancelAddNewFolder()">
                <i>close</i>
            </div>
        </div>
    </div>
    <ng-container *ngFor="let folder of folders">
        <file-manager-folder [folderName]="folder.name"
                             [readOnly]="readonly"
                             [files]="folder.files"
                             (onFolderEdit)="editFolder($event, folder.id)"
                             (onFolderDelete)="deleteFolder(folder.id)"
                             (onFilesDownload)="downloadFiles($event)"
                             (onFilesDelete)="deleteFiles($event)"
                             (onFilesAdd)="addFilesToFolder($event, folder.id)">
        </file-manager-folder>
    </ng-container>
</div>
