import {Component, OnInit, Input, ViewChild, ChangeDetectorRef, NgZone} from '@angular/core';
import {Widget} from "./widget";
import {GlobalAlertService} from "../../../wrapper/global-alert/global-alert.service";
import {WidgetService} from "./widget.service";
import {RequestFailure} from "../../../shared/services/http/request-failure";
import {MapCoreComponent} from "../../../shared/components/map/map-core.component";
import {MenuService} from "../../../dynamic-menu/menu/menu.service";
import {LoggerService} from "../../../shared/services/logger/logger.service";

@Component({
    selector: 'map-widget-component',
    template: `
        <div class="card widget-panel">
            <div class="card small-widget-title-container {{widget.iconClass}}">
                <i class="material-icons md-light md-24 widget-icon">{{widget.iconName}}</i>
            </div>
            <div class="map-widget-title">
                {{widget.title}}
            </div>
            <map-core-component #mapComponent [isLoading]="isLoading" class="widget-map-container" (onClickMarker)="handleClickMarker($event)" [style.padding-bottom]="widget.description?'0rem':'1rem'"></map-core-component>
            <div *ngIf="!isLoading" class="map-widget-subtext-container d-flex align-items-center py-1">

                <div><i class="material-icons md-18 widget-subtext-icon py-0 ">info_outline</i></div>
                <div class="d-flex justify-content-between w-100 flex-wrap">
                    <div *ngIf="widget.description" >{{widget.description}}</div>
                    <div>{{totalMarkers}} {{'Items' | translate}}</div>
                </div>
            </div>
        </div>
    `
})

export class MapWidgetComponent implements OnInit {

    @Input('widget') public widget:Widget;
    @ViewChild('mapComponent', {static: false}) mapComponent:MapCoreComponent;

    public isLoading:boolean;
    public totalMarkers:number = 0;

    constructor(private widgetService:WidgetService, private globalAlertService:GlobalAlertService, private menuService:MenuService, private cd:ChangeDetectorRef, private zone:NgZone, protected logger:LoggerService) {}

    ngOnInit()
    {
        this.isLoading = true;
        this.widgetService.getWidgetData(this.widget.id, "",
            (json:any) => {
                this.isLoading = false;
                this.widget.title = json.title?json.title:this.widget.title;

                this.mapComponent.replaceMarkers(json.mapItems, false, true);

                this.logger.log("[MapWidgetComponent] " + "json.mapItems: " , json.mapItems);

                let total:number = 0;
                if (json.mapItems && json.mapItems.length > 0){
                    json.mapItems.forEach( (mapItem:any) => {
                        if (mapItem.baseObjects){
                            total += mapItem.baseObjects.length;
                        }else{
                            total += 1;
                        }
                    });
                }

                this.totalMarkers = total;

                this.widget.description = json.description;
                this.cd.markForCheck();
            },
            (failure:RequestFailure) => {}
        );

        //Handle invalid data
        if (!this.widget.iconClass || !this.widget.iconName || this.widget.iconName == "" || this.widget.iconClass == ""){
            this.globalAlertService.addAlert(GlobalAlertService.ALERT_TITLE_ERROR, "Unknown widget icon", "Voor small-widget-type [" + this.widget.code + "] is geen icon informatie gedefinieerd");
        }

        this.cd.markForCheck();
    }

    public handleClickMarker(clickData:any):void
    {
        this.logger.log("[MapWidgetComponent] " + "Click marker on widget: ", clickData);

        let mapItemRef:any = clickData.markers[0].dataRef;
        let baseObjectId:number = mapItemRef.baseObjectId;
        let action:string = "";

        if (mapItemRef.actionUrl) {
            action = mapItemRef.actionUrl;
        } else if(mapItemRef.baseObjects) {
            mapItemRef.baseObjects.forEach((baseObject:any) => {
                if (baseObject.id == baseObjectId){
                    if (baseObject.actionUrl && baseObject.actionUrl != ""){
                        action = baseObject.actionUrl;
                    }
                }
            });
        } else {
            this.logger.log('[MapWidgetComponent] no action url found in map item and/or base object');
        }

        if (action != ""){

            //Run in zone, so the following actions trigger change detection
            this.zone.run(() => {
                this.widgetService.handleClickItem(action);
            });
        }
    }
}
