import {NgModule} from '@angular/core';
import {StatusProgressComponent} from './status-progress/status-progress.component';
import {StatusButtonComponent} from './status-button/status-button.component';
import {CommonModule} from '@angular/common';
import {CommonPipesModule} from '../../pipes/common-pipes.module';
import {SubStatusesComponent} from './sub-statuses/sub-statuses.component';
import {CommonUIModule} from '../commonUI/common-ui.module';


@NgModule({
    exports: [
        StatusProgressComponent,
        StatusButtonComponent,
        SubStatusesComponent
    ],
    imports: [
        CommonModule,
        CommonPipesModule,
        CommonUIModule
    ],
    declarations: [
        StatusProgressComponent,
        StatusButtonComponent,
        SubStatusesComponent
    ]
})
export class StatusModule {
}
