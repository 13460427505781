/**
 * Created by Christiaan on 07/04/2017.
 */

import {Directive, NgZone, Output, EventEmitter} from '@angular/core';

@Directive({
    selector: '[globalMouseupOutsideAngular]'
})

export class GlobalMouseupOutsideAngularDirective
{
    private static readonly EVENT_MOUSEUP:string = "mouseup";
    private static readonly EVENT_TOUCHEND:string = "touchend";

    @Output('globalMouseupOutsideAngular') mouseupHandler: EventEmitter<any> = new EventEmitter();

    constructor(private ngZone:NgZone)
    {
        this.ngZone.runOutsideAngular(() => {
            document.addEventListener(GlobalMouseupOutsideAngularDirective.EVENT_MOUSEUP, (event:any)=> {
                this.mouseupHandler.emit(event);
            });
            document.addEventListener(GlobalMouseupOutsideAngularDirective.EVENT_TOUCHEND, (event:any)=> {
                this.mouseupHandler.emit(event);
            });
        });
    }
}
