import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostBinding,
    Input, QueryList,
    Renderer2,
    ViewChild,
    ViewChildren,
    EventEmitter,
    Output
} from '@angular/core';
import 'moment/locale/nl';
import {
    AbstractFormFieldComponent,
    FormFieldConfigInterface,
    FormFieldConfigOptionInterface
} from '../../abstract/abstract-form-field.component';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {TooltipService} from "../../../../../services/tooltip/tooltip.service";
import {ValidationConstraintService} from "../../../services/validation-constraint.service";
import {config, Subscription} from 'rxjs';
import {GlobalModel} from '../../../../../services/state/global.model';
import {FormButtonComponentEvent} from '../../form-button/form-button.component';
import {CheckboxComponentEvent} from '../form-checkbox.interface';
import {LoggerService} from "../../../../../services/logger/logger.service";

@Component({
    selector: 'form-checkbox-default',
    templateUrl: `./form-checkbox-default.component.html`
})

/**
 * MUST be compatible with Symfony2 ChoiceType checkboxes
 * @see https://symfony.com/doc/current/reference/forms/types/choice.html#select-tag-checkboxes-or-radio-buttons
 */
export class FormCheckBoxDefaultComponent extends AbstractFormFieldComponent{

    @Input() config:FormFieldConfigInterface;
    @Input() group:UntypedFormGroup;

    @HostBinding('class') hostClasses;
    @ViewChild('currentFormField', {static: false}) currentFormField: any;
    @ViewChildren('checkbox') checkboxes: QueryList<ElementRef<HTMLElement>>;

    @Output() onComponentEvent: EventEmitter<CheckboxComponentEvent> = new EventEmitter();

    checkedMap: any [] = [];
    private mobileMode:boolean = false;
    private subMobileMode:Subscription = null;

    constructor(public renderer:Renderer2, public validationConstraintService:ValidationConstraintService, public tooltipService:TooltipService, public formBuilder:UntypedFormBuilder, public model:GlobalModel, public cd:ChangeDetectorRef, protected logger:LoggerService) {
        super(renderer, validationConstraintService, tooltipService, logger);
    }

    ngOnInit()
    {
        if(this.config && this.config.attr && this.config.attr['batch-update'] === true){
            this.batchUpdateMode = true;
        }

        this.hostClasses = (this.config.attr && this.config.attr.selectAll) || this.config.options.length > 1?'d-flex flex-column':'';

        let formGroupTemplate = {};

        this.checkedMap = [];

        this.subMobileMode = this.model.mobileMode.subscribe((value:boolean) => {
            this.mobileMode = value;
            // this.cd.detectChanges();
        });

        //When no label given
        if (config && (!this.config.label || this.config.label == '')){
            //When just one checkbox
            if (this.config.options && this.config.options.length == 1){
                //Make the first checkbox label the config label
                this.config.label = this.config.options[0].name;
            }
        }

        // remove existing controls, overriding... Though this could be done differently, but that is for later.
        this.group.removeControl(this.config.name);

        // first make a list of controls usable by the template
        for(let i = 0 ; i < this.config.options.length; i++) {
            formGroupTemplate[this.config.options[i].id] = new UntypedFormControl({'value': this.config.options[i].id, 'disabled': false});
        }
        // and put the form controls in a form group
        let formGroup = this.formBuilder.group(formGroupTemplate);

        // here something goes wrong on first load, the value of the control is not loaded correctly with the value acessor override
        this.group.addControl(this.config.name, formGroup);
        for(let i = 0 ; i < this.config.options.length; i++) {
            // isBoxChecked will probably need to be refactored:
            // a solution would be to imperatively check boxes that need to be checked, on first page load (getting the form data),
            // based on the initial value and config.options arrays (these have the same promised order, they are synchronized)
            let checkBoxValue = ''; // default, 'false'
            if (this.config.initialValue[i] === true) {
                checkBoxValue = this.config.options[i].id.toString() // may need to be 'name?' TODO check if it needs to be name
            }
            // patch the value with either
            //      '' (empty value, will be sent like this to the back-end)
            // OR
            //      the id
            this.group.get([this.config.name, this.config.options[i].id]).patchValue(checkBoxValue)
        }
        //this.setUpHostClasses();
    }

    /**
     * For checking check boxes imperatively when getting a form
     *
     * @param {number} index
     * @param initialValue
     */
    isBoxChecked(index: number, initialValue: any)
    {
        this.checkedMap[index] = initialValue[index] && initialValue[index] === true;
    }

    public handleClickSelectAll(){
        let halfLength:number = this.config.options.length / 2;
        let checkCounter: number = 0;

        this.config.options.forEach((option:FormFieldConfigOptionInterface) => {
            if(this.group.get([this.config.name, option.id]).value){
                checkCounter++;
            }
        });

        if(checkCounter >= halfLength){
            for(let i=0; i<this.config.options.length;i++) {
                this.group.get([this.config.name, this.config.options[i].id]).patchValue('');
            }
        } else {
            for(let i=0; i<this.config.options.length;i++) {
                this.group.get([this.config.name, this.config.options[i].id]).patchValue(this.config.options[i].id.toString());
            }
        }
    }

    onCheckBoxChanged(event:any, index:any) {
        this.onComponentEvent.emit({index: index, value:this.group.value[this.config.name], doVisibleCheck:this.config.attr?<boolean>this.config.attr.doVisibleCheck:false})
    }

    stringify(json:any){
        return JSON.stringify(json);
    }

    optionDisabled(option:any)
    {
        let state = this.disabled();

        //TODO: Niet getest
        if (option && option.disabled == true) {
            state = '';
        }

        
        return state;
    }

    ngOnDestroy() {
        if(this.subMobileMode){
            this.subMobileMode.unsubscribe()
        }
    }
    
    onChange($event: Event, index: number) {
        this.checkedMap[index] = (<HTMLInputElement>$event.target).checked;
        this.onCheckBoxChanged($event, index);
    }
}
