import {MapItem} from "./map-item";

export interface IWorkingLayer {
    mapItem: ILayerMapItem;
    mapItemType:mapItemTypes;
    layerId: number;
    isParallel?:boolean;
    isSnappable?:boolean;
}

export interface IMapItemOriginalValue{
    path:any
}

export interface IDrawingLayer{
    mapItem: google.maps.Polyline | google.maps.Marker;
    mapItemType:mapItemTypes
}

export interface IMapItemLayers{
    id:number,
    name:string
}

export interface IMapItemStyles{
    id:number,
    name:string,
    strokeColor:string,
    strokeWeight:number,
    strokeStyle:string,
    zIndex:number
}

export interface IRegisteredEvents{
    element:any,
    action:string
}

export interface ILayerMapItem{
    id:number,
    mapItem:google.maps.Marker | google.maps.Polyline,
    label?:string,
    strokeStyle?:string,
    zIndex:number,
    isInBoundingBox:boolean
}

export interface IMousePosition {
    isMouseOnCanvas:boolean,
    currentMouseLatLng: google.maps.LatLng
}

export interface ISnappablePoint{
    latLng:google.maps.LatLng,
    mapItem:MapItem
}

export interface IParallelHeading{
    heading:number,  // number from -180 to 180
    startLatLng: google.maps.LatLng
    endLatLng: google.maps.LatLng
    mapItem:MapItem,
    lineSegmentPoly: google.maps.Polyline
}

export interface IBaseObject {
    id: number,
    label: string,
    icon: string
}

export interface IGeoJSON {
    type: 'Point' | 'LineString';
    coordinates: number[];
}

export interface ISelectedBaseObjectIds {
    baseObjectId:string
}

export interface IMapItem {
    id: number,
    label: string,
    icon: string,
    baseObjects: IBaseObject[],
    styleId: number,
    layerId: number,
    objectType: string,  // 'cable'|'wire'|'conduit'|'joint'|'marker'|'annotation'
    markerV1?:google.maps.Marker,
    geometry?: IGeoJSON
}

export interface IAnnotation {
    polyline: google.maps.Polyline,
    infoWindow: google.maps.InfoWindow
}

export enum mapItemTypes {
    'MARKER' = 'MARKER',
    'POLYLINE' = 'POLYLINE',
    'JOINT' = 'JOINT',
    'ANNOTATION' = 'ANNOTATION'
}

export enum drawingStates {
    'DRAW_NEW' = 'DRAW_NEW',
    'DRAW_NEW_HEAD' = 'DRAW_NEW_HEAD',
    'DRAW_NEW_HEAD_SNAP' = 'DRAW_NEW_HEAD_SNAP',
    'DRAW_EDIT' = 'DRAW_EDIT',
    'DRAW_EDIT_HEAD' = 'DRAW_EDIT_HEAD',
    'DRAW_FINISH' = 'DRAW_FINISH'
}
