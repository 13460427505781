import {
    ChangeDetectorRef,
    Component,
    Input,
    Renderer2,
} from '@angular/core';
import {FormInputComponent} from './form-input.component';
import {ValidationConstraintService} from '../../services/validation-constraint.service';
import {TooltipService} from '../../../../services/tooltip/tooltip.service';
import {GlobalAlertService} from '../../../../../wrapper/global-alert/global-alert.service';
import {TranslateService} from '../../../../services/translate/translate.service';
import {GlobalModel} from "../../../../services/state/global.model";
import {UntypedFormControl, UntypedFormGroup} from "@angular/forms";
import {LoggerService} from "../../../../services/logger/logger.service";

@Component({
    selector: 'form-date-input',
    templateUrl: 'form-date-input.component.html',
})

export class FormDateInputComponent extends FormInputComponent {
    @Input('showLabel') showLabel: boolean = true;

    constructor(
        public renderer: Renderer2,
        public model: GlobalModel,
        public validationConstraintService: ValidationConstraintService,
        public tooltipService: TooltipService,
        public globalAlertService: GlobalAlertService,
        public ts: TranslateService,
        protected logger:LoggerService,
        public cd: ChangeDetectorRef
    ) {
        super(renderer, validationConstraintService, tooltipService, globalAlertService, ts, logger, model);

        this.group = new UntypedFormGroup({
            datePicker1: new UntypedFormControl(''),
        });
    }
}
