import {Injectable} from '@angular/core';
import {StorageService} from "../storage/storage.service";
import {ILogMessage, loggerLevel} from './logger.interface';
import {UserRoles} from "../../interfaces/user.interface";
import {GlobalModel} from "../state/global.model";
import {Router} from "@angular/router";

@Injectable()
export class LoggerService{
    private debugModeEnabled:boolean = false;
    constructor(private storage: StorageService,private model:GlobalModel,private router:Router){
        this.storage.getBooleanValue(StorageService.KEY_DEBUG_MODE, (value: boolean) => {
            this.debugModeEnabled = value;
        });
    }

    public trace(message:(any|any[]) = null, logObject:any = null):void {
        this.logger(loggerLevel.TRACE, message, logObject)
    }
    public debug(message:(any|any[]) = null, logObject:any = null):void {
        this.logger(loggerLevel.DEBUG, message, logObject)
    }
    public info(message:(any|any[]) = null, logObject:any = null):void {
        this.logger(loggerLevel.INFO, message, logObject)
    }
    public log(message:(any|any[]) = null, logObject:any = null):void {
        this.logger(loggerLevel.LOG, message, logObject)
    }
    public warn(message:(any|any[]) = null, logObject:any = null):void {
        this.logger(loggerLevel.WARN, message, logObject)
    }
    public error(message:(any|any[]) = null, logObject:any = null):void {
        this.logger(loggerLevel.ERROR, message, logObject)
    }

    private logger(level:loggerLevel, message:(any|any[]) = null, logObject:any):void{
        if(this.debugModeEnabled && this.hasUserEngineerRole() && message !== null){
            const outputMessage = this.buildLogMessage(message,logObject)
            switch(level){
                case loggerLevel.TRACE:
                    console.trace(message,outputMessage)
                    break;
                case loggerLevel.DEBUG:
                    console.debug(message,outputMessage)
                    break;
                case loggerLevel.INFO:
                    console.info(message,outputMessage)
                    break;
                case loggerLevel.LOG:
                    console.log(message,outputMessage)
                    break;
                case loggerLevel.WARN:
                    console.warn(message,outputMessage)
                    break;
                case loggerLevel.ERROR:
                    console.error(message,outputMessage)
                    break;
                default:
                    console.log(message,outputMessage)
                    break;
            }
        }
    }

    private buildLogMessage(message:(any|any[]),logObject:any):ILogMessage {
        const stackTrace = this.printStackTrace()
        let returnMessage:ILogMessage = {
            message: message,
            additionalInfo: logObject,
            routeUrl: this.router.url,
            stackTrace: stackTrace,
            componentName: stackTrace[0]
                .split('.')[0],
            dateTime: new Date()
                .toLocaleString("nl-NL", {year: "numeric", month: "2-digit", day: "2-digit", hour: "2-digit", minute: "2-digit", second: '2-digit', timeZoneName: 'short'}),
            lineNumber: stackTrace[0]
                .split('.js:')[1].split(':')[0],
            character: stackTrace[0]
                .split('.js:')[1].split(':')[1].replace(')','')
        };
        return returnMessage;
    }

    private printStackTrace():string[] {
        const stack = new Error().stack
        let stackStraceArray:string[] = [];
        stack.split('\n')
            .slice(5)
            .map((line) => {
                stackStraceArray.push(line.replace(/\s+at\s+/, ''))
            });
        return stackStraceArray;
    }

    private hasUserEngineerRole(): boolean {
        return this.model.getUserRoleCodes().some(role => role === UserRoles.ENGINEER);
    }
}
