<div class="fm-folder-container">
    <div class="fm-folder" (click)="toggleExpandFolder()">
        <i *ngIf="!expanded" class="fm-folder-icon" style="font-size: medium">keyboard_arrow_right</i>
        <i *ngIf="expanded" class="fm-folder-icon" style="font-size: medium">expand_more</i>
        <i *ngIf="!expanded" class="fm-folder-icon">folder</i>
        <i *ngIf="expanded" class="fm-folder-icon">folder_open</i>
        <div class="fm-folder-count">
            <span>{{files.length}}</span>
        </div>
        <ng-container *ngIf="!editingFolder">
            <span class="fm-folder-title">{{folderName}}</span>
            <menu-dropdown *ngIf="!readOnly && !disabled"
                           [buttonSizeSmall]="true"
                           [menuTitle]="'filemanager.folder' | translate">
                <a menuItem (click)="editFolder($event)"
                   [title]="'filemanager.change' | translate"
                   class="md-item">
                    <i>edit</i>
                    {{'filemanager.change' | translate}}
                </a>
                <a menuItem (click)="deleteFolder($event)"
                   [title]="'filemanager.delete' | translate"
                   class="md-item">
                    <i>delete</i>
                    {{'filemanager.delete' | translate}}
                </a>
            </menu-dropdown>
        </ng-container>
        <ng-container *ngIf="editingFolder">
            <input #folderNameInput
                   style="height: auto"
                   type="text"
                   class="fm-folder-title"
                   (click)="preventDefault($event)"
                   [value]="folderName"/>
            <lumi-button style="margin-right: 2rem;"
                         (onClick)="saveFolder($event)"
                         [rank]="BUTTON_RANK.TERTIARY"
                         [size]="'small'"
                         [title]="'opslaan' | translate"
                         [icon]="'save'">
            </lumi-button>
            <div class="fm-close-button"
                 title="{{'filemanager.close' | translate}}"
                 (click)="cancelEditFolder($event)">
                <i>close</i>
            </div>
        </ng-container>
    </div>
    <div class="fm-folder-content" *ngIf="expanded">
        <div class="fm-file-list-container">
            <div class="fm-file-list">
                <ng-container *ngFor="let file of files">
                    <div class="{{isFileSelected(file) ? 'fm-file-active':'fm-file'}}"
                         (click)="selectFile($event, file)">
                        <i>description</i>
                        <span>{{file.name}}</span>
                    </div>
                </ng-container>
            </div>
            <div class="fm-file-info" *ngIf="selectedFiles.length > 0">
                <div class="fm-close-button"
                     title="{{'filemanager.close' | translate}}"
                     (click)="closeFileInfo()">
                    <i>close</i>
                </div>
                <div class="fm-file-button-row" *ngIf="!readOnly">
                    <lumi-button (onClick)="download($event)"
                                 [rank]="BUTTON_RANK.TERTIARY"
                                 [size]="'small'"
                                 [title]="'filemanager.download' | translate"
                                 [icon]="'get_app'">
                    </lumi-button>
                    <lumi-button (onClick)="deleteFile($event)"
                                 [rank]="BUTTON_RANK.TERTIARY"
                                 [size]="'small'"
                                 [disabled]="disabled"
                                 [title]="'filemanager.delete' | translate"
                                 [icon]="'delete'">
                    </lumi-button>
                </div>
                <div class="fm-file-data">
                    <ng-container *ngIf="selectedFiles.length < 2">
                        <span class="fm-file-name">{{selectedFiles[0].name}}</span>
                        <div class="fm-file-info-row" *ngIf="selectedFiles[0].type">
                            <div class="fm-file-info-label">
                                {{'filemanager.type' | translate}}
                            </div>
                            <div class="fm-file-info-value">
                                {{selectedFiles[0].type}}
                            </div>
                        </div>
                        <div class="fm-file-info-row" *ngIf="selectedFiles[0].creator">
                            <div class="fm-file-info-label">
                                {{'filemanager.creator' | translate}}
                            </div>
                            <div class="fm-file-info-value">
                                {{selectedFiles[0].creator}}
                            </div>
                        </div>
                        <div class="fm-file-info-row" *ngIf="selectedFiles[0].dateCreated">
                            <div class="fm-file-info-label">
                                {{'filemanager.creationDate' | translate}}
                            </div>
                            <div class="fm-file-info-value">
                                {{selectedFiles[0].dateCreated}}
                            </div>
                        </div>
                        <div class="fm-file-info-row" *ngIf="selectedFiles[0].size">
                            <div class="fm-file-info-label">
                                {{'filemanager.size' | translate}}
                            </div>
                            <div class="fm-file-info-value">
                                {{selectedFiles[0].size}}
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="selectedFiles.length > 1">
                        <span class="fm-multi-file">{{selectedFiles.length}} {{'filemanager.selected' | translate}}</span>
                    </ng-container>
                </div>
            </div>
        </div>
        <ng-container *ngIf="!readOnly">
            <lumi-button *ngIf="!globalModel.mobileMode.value && !showFileSelector && files?.length > 0"
                         (onClick)="openFileSelector($event)"
                         [rank]="BUTTON_RANK.TERTIARY"
                         [label]="'filemanager.addMoreFiles' | translate"
                         [disabled]="disabled"
                         [icon]="'add'">
            </lumi-button>
            <div class="fm-file-selector-container"
                 *ngIf="globalModel.mobileMode.value || showFileSelector || files?.length < 1">
                <div class="fm-close-button"
                     title="{{'filemanager.close' | translate}}"
                     *ngIf="!globalModel.mobileMode.value && files?.length > 0"
                     (click)="closeFileSelector($event)">
                    <i>close</i>
                </div>
                <file-selector (onChanges)="addFiles($event)" [isDisabled]="disabled"></file-selector>
            </div>
        </ng-container>
    </div>
</div>
