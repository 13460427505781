/**
 * Created by Christiaan on 13/03/2017.
 */
import {LOCALE_ID, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {DashboardRoutingModule} from './dashboard.routing';
import {DashboardComponent} from './dashboard.component';
import {WidgetModule} from "./widgets/widget.module";
import {TranslateService} from '../../shared/services/translate/translate.service';

@NgModule({
    imports: [
        CommonModule,
        DashboardRoutingModule,
        WidgetModule
    ],
    declarations: [DashboardComponent],
    providers: [
        {
            provide: LOCALE_ID,
            useValue: TranslateService.LOCALE
        }
    ]
})

export class DashboardModule {
}
