import {Component, Renderer2} from '@angular/core';
import {AbstractFormFieldComponent} from '../abstract/abstract-form-field.component';
import {ValidationConstraintService} from '../../services/validation-constraint.service';
import {TooltipService} from '../../../../services/tooltip/tooltip.service';
import {ListItem} from '../../../commonUI/list/complex-list-item/complex-list-item.interface';
import {LogItem} from './form-log.interface';
import {LoggerService} from "../../../../services/logger/logger.service";

@Component({
    selector: 'form-log',
    templateUrl: './form-log-component.ts.html'
})
export class FormLogComponent extends AbstractFormFieldComponent {
    public logs: LogItem[] = [];
    
    constructor(
        public renderer: Renderer2,
        public validationConstraintService: ValidationConstraintService,
        public tooltipService: TooltipService,
        protected logger:LoggerService
    ) {
        super(renderer, validationConstraintService, tooltipService, logger);
    }
    
    ngOnInit() {
        // Set the logs for the view
        if (this.config && this.config.attr && this.config.attr.logs) {
            this.logs = this.config.attr.logs.map(log => {
                log.listItem = this.getListItem(log);
                return log;
            });
        }
    }
    
    private getListItem(log: LogItem): ListItem {
        return {
            id: JSON.stringify(log),
            title: log.name,
            subtitle: log.created
        };
    }
}
