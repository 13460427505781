/**
 * Created by Christiaan on 13/04/2017.
 */
import { NgModule }      from '@angular/core';

import {GlobalKeyupOutsideAngularDirective} from "./global-keyup-outside-angular.directive";
import {GlobalKeydownOutsideAngularDirective} from "./global-keydown-outside-angular.directive";
import {ScrollOutsideAngularDirective} from "./scroll-outside-angular.directive";
import {GlobalResizeOutsideAngularDirective} from "./global-resize-outside-angular.directive";
import {KeydownOutsideAngularDirective} from "./keydown-outside-angular.directive";
import {InputOutsideAngularDirective} from "./input-outside-angular.directive";
import {MouseoverOutsideAngularDirective} from "./mouseover-outside-angular.directive";
import {ClickOutsideAngularDirective} from "./click-outside-angular.directive";
import {GlobalClickOutsideAngularDirective} from "./global-click-outside-angular.directive";
import {GlobalMouseupOutsideAngularDirective} from "./global-mouseup-outside-angular.directive";
import {GlobalFocusOutsideAngularDirective} from "./global-foucs-outside-angular.directive";
import {DisableControlDirective} from "./disable-control.directive";
import {InitialFocusDirective} from "./initial-focus.directive";
import {GlobalKeypressOutsideAngularDirective} from "./global-keypress-outside-angular.directive";
import {GlobalMousedownOutsideAngularDirective} from "./global-mousedown-outside-angular.directive";

@NgModule({
    exports: [
        ScrollOutsideAngularDirective,
        GlobalKeyupOutsideAngularDirective,
        GlobalKeydownOutsideAngularDirective,
        GlobalResizeOutsideAngularDirective,
        KeydownOutsideAngularDirective,
        InputOutsideAngularDirective,
        MouseoverOutsideAngularDirective,
        ClickOutsideAngularDirective,
        GlobalClickOutsideAngularDirective,
        GlobalMouseupOutsideAngularDirective,
        GlobalFocusOutsideAngularDirective,
        DisableControlDirective,
        InitialFocusDirective,
        GlobalKeypressOutsideAngularDirective,
        GlobalMousedownOutsideAngularDirective
    ],
    declarations: [
        ScrollOutsideAngularDirective,
        GlobalKeyupOutsideAngularDirective,
        GlobalKeydownOutsideAngularDirective,
        GlobalResizeOutsideAngularDirective,
        KeydownOutsideAngularDirective,
        InputOutsideAngularDirective,
        MouseoverOutsideAngularDirective,
        ClickOutsideAngularDirective,
        GlobalClickOutsideAngularDirective,
        GlobalMouseupOutsideAngularDirective,
        GlobalFocusOutsideAngularDirective,
        DisableControlDirective,
        InitialFocusDirective,
        GlobalKeypressOutsideAngularDirective,
        GlobalMousedownOutsideAngularDirective
    ]
})

export class CommonDirectivesModule {}
