/**
 * Created by Christiaan on 22/03/2017.
 */
export class RequestFailure {

    private static readonly SEVERITY_INFO = "info";
    private static readonly SEVERITY_WARNING = "warning";

    message:string = "";
    title:string = "";
    severity:string = RequestFailure.SEVERITY_INFO;
    formErrors:any = {};
    displayAsAlert:boolean;
    displayAsPopup:boolean;
}
