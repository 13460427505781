/**
 * Created by Christiaan on 16/03/2017.
 */

import {Injectable} from '@angular/core';
import {GlobalModel} from '../../shared/services/state/global.model';
import {HttpService2} from '../../shared/services/http-service-2.0/http2.0.service';
import {map} from 'rxjs/operators';
import {DashboardHttpGetResult} from './dashboard.interface';
import {Observable} from 'rxjs';
import {LoggerService} from "../../shared/services/logger/logger.service";

@Injectable({
    providedIn: 'root'
})
export class DashboardService {
    public static readonly GET_DASHBOARD_PATH: string = 'dashboard/get'; // keeping this for reference and isDashboardLoading
    
    constructor(
        private model: GlobalModel,
        private httpService: HttpService2,
        protected logger:LoggerService
    ) {
    }
    
    public getDashboard(uri: string): Observable<void> {
        return this.httpService.doGetRequest(
            uri,
            false // not sending area id explicitly, this comes from the back-end via normalization
        ).pipe(
            map((result: DashboardHttpGetResult) => {
                if (result && result.dashboard) {
                    this.model.currentDashboard.next(result.dashboard);
                }
                return;
            })
        );
    }
    
    public allowedToLoadDefaultDashboard(): boolean {
        // if (this.httpService.getQueryParam("autoRefresh") == "false"){
        if (!this.model.dashboardAutoRefresh) {
            this.logger.log('[DashboardService] ' + 'Skip auto refresh dashboard');
        } else {
            this.logger.log('[DashboardService] ' + 'Autoload dashboard');
            // Load the first dashboard in the list
            if (this.model.dashboardItems && this.model.dashboardItems.length > 0) {
                return true;
            }
        }
        return false;
    }
}
