import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import {LoginService} from '../../wrapper/login/login.service';
import {GlobalModel} from '../services/state/global.model';
import {AuthorizationService} from '../services/authorization/authorization.service';
import {PublicPortalComponent} from '../../modules/publicPortal/public-portal.component';
import {LuminizerRoutes} from '../interfaces/routes';
import {StorageService} from '../services/storage/storage.service';
import {UserRoles} from '../interfaces/user.interface';
import {LoggerService} from "../services/logger/logger.service";
import {TranslateService} from "../services/translate/translate.service";

@Injectable()
export class RouteGuard  {
    constructor(
        private router: Router,
        private loginService: LoginService,
        private model: GlobalModel,
        private authorizationService: AuthorizationService,
        private storageService: StorageService,
        protected logger:LoggerService,
        private ts: TranslateService,
    ) {
    }
    
    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        await this.ts.initTranslations();

        let allowFirstActivationRedirect: boolean = !this.storageService.keyExists('RouteGuard.allowFirstActivationRedirect');
        
        if (state.url.indexOf(LuminizerRoutes.PUBLIC_PORTAL_PAGE) !== -1) {
            this.checkPublicPortalMode(state);
        }
        
        if (route.data['unblockable'] && route.data['unblockable'] == true) {
            this.logger.log('[RouteGuard] ' + 'Route ' + route + ' is unblockable');
            return true;
        }
        
        let allowAccess: boolean;
        
        this.logger.log('[RouteGuard] ' + 'Is loggedin: ' + this.loginService.isLoggedInLocally(this.model.user.value));
        if (this.loginService.isLoggedInLocally(this.model.user.value)) {
            if (this.checkSingleRoleUser()) {
                this.logger.log('[RouteGuard] ' + 'User has single Role');
                const redirectRoute = this.getRedirectRouteForRole();
                if (redirectRoute !== null && allowFirstActivationRedirect) {
                    this.storageService.setValue('RouteGuard.allowFirstActivationRedirect', false);
                    this.logger.log('[RouteGuard] ' + 'Role has redirectRoute and redirect is allowed');
                    
                    if (state.url.indexOf(redirectRoute) === -1) {
                        this.logger.log('[RouteGuard] ' + 'redirectRoute is not current route; redirecting to ' + redirectRoute);
                        return this.router.parseUrl(`/${redirectRoute}`);
                    }
                }
            }

            // The user is logged in, now check on userrole
            if (this.authorizationService.hasModuleAuthorization(route.data['configModule'])) {
                this.authorizationService.setActiveModule(route.data['configModule']);
                allowAccess = true;
            } else {
                this.router.navigate([LuminizerRoutes.FORBIDDEN_PAGE], {queryParams: {}});
                allowAccess = false;
            }
        } else {
            // The user is not logged in, so redirect to login page with the return url
            this.router.navigate([LuminizerRoutes.LOGIN_PAGE], {queryParams: {returnUrl: state.url}});
            allowAccess = false;
        }
        
        this.logger.log('[RouteGuard] ' + 'Allow access to ' + route + '? ' + (allowAccess ? 'YES' : 'NO'));
        
        return allowAccess;
    }
    
    private checkPublicPortalMode(state: RouterStateSnapshot) {
        if (!this.model.publicPortalMode.value) {
            // NOTE: de route en state bevatten hier nog geen url params, dus die kunnen niet gebruikt worden
            //  Query params werken niet want die verdwijnen als je de page zou refreshen, dus url params is wel de way to go.
            //  Daarom worden ze hier handmatig uit de url gehaald
            // TODO: ooit een mooiere, meer stabiele oplossing bedenken
            let firstParam: string = '';
            let searchString: string = PublicPortalComponent.FORM_URL + '/';
            let searchStringIndex: number = state.url.indexOf(searchString);
            if (searchStringIndex != -1) {
                let urlParams: string = state.url.substr(searchStringIndex + searchString.length);
                let endOfFirstParamIndex: number = urlParams.indexOf('/');
                if (endOfFirstParamIndex != -1) {
                    firstParam = urlParams.substr(0, endOfFirstParamIndex);
                }
            }
            // ----------------------------------------------------------------------
        
            let areaId: number = -1;
            let area: string = firstParam; // queryparam
            if (area != '' && !isNaN(Number(area))) {
                areaId = Number(area);
            }
        
            if (areaId > 0) {
            
                this.logger.log('[RouteGuard] ' + 'TURN ON PUBLIC PORTAL MODE FOR AREA: ' + areaId);
            
                this.model.publicPortalMode.next(true);
                this.model.publicPortalArea.next(areaId);
            } else {
                this.logger.log('[RouteGuard] ' + 'Area ' + areaId + ' is not valid. Block public portal mode');
            }
        }
    }
    
    private checkSingleRoleUser(): boolean {
        return this.model.getUserRoleCodes().length < 2;
    }
    
    private getRedirectRouteForRole(): string {
        const roleCode = this.model.getUserRoleCodes()[0];
        
        switch (roleCode) {
            case UserRoles.OVERRIDE:
                if (this.authorizationService.hasModuleAuthorization(LuminizerRoutes.CALAMITY_CONTROL_PAGE)) {
                    return LuminizerRoutes.CALAMITY_CONTROL_PAGE;
                }
                break;
            case UserRoles.MONTEUR:
                if (this.authorizationService.hasModuleAuthorization(LuminizerRoutes.MECHANIC)) {
                    return LuminizerRoutes.MECHANIC;
                }
        }
        
        return null;
    }
}
