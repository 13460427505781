/**
 * Created by Christiaan on 05/05/2017.
 */
export interface BaseObjectInterface {
    lat?: number
    lng?: number
    label: string
    icon?: string
    id: string
    selected?: boolean
    hidden?: boolean
    actionUrl?: string
}

export interface MapItemInterface {
    lat: number
    lng: number
    label: string
    icon: string
    id: string
    selected?: boolean
    hidden?: boolean
    baseObjects: BaseObjectInterface[]
    char?: string
    iconChanged?: boolean
    hasLabel?: boolean
    actionUrl?: string
    baseObjectId?: number
    oldSelectedState?: boolean
    dataLayerLabels?: [{name:any, value:string}]
    dataLayer?: boolean
    objectType?: 'cable'|'wire'|'conduit'|'joint'|'annotation'
    styleId?:number
    layerId?:number
    geometry?:{
        type:'LineString';
        coordinates:number[];
    }
}

export class MapItem implements MapItemInterface {
    //TODO: add reference to werkorder/mast/oid
    lat: number;
    lng: number;
    label: string;
    //fillColor:string
    icon: string;
    //type:string
    id: string;
    selected?: boolean;
    hidden?: boolean;
    //actionUrl:string

    baseObjects: BaseObjectInterface[];
    char?: string = '';
    baseObjectId?: number;
    objectType?: 'cable'|'wire'|'conduit'|'joint'|'annotation';
    styleId?:number;
    layerId?:number;
    geometry?:{
        type:'LineString';
        coordinates:number[];
    }
}
