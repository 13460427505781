import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpService2} from '../../../../services/http-service-2.0/http2.0.service';
import {IInteractiveTable, INewRowSchema} from './interactive-table.interface';
import {map} from "rxjs/operators";
import {FormPostResult} from "../../../../services/http-service-2.0/http.interface";
import {LoggerService} from "../../../../services/logger/logger.service";

@Injectable({
    providedIn: 'root'
})
export class InteractiveTableService {

    constructor(protected httpService2: HttpService2, protected logger:LoggerService) {}

    public getTableRows(urlPrefix: string): Observable<IInteractiveTable> {
        return <Observable<IInteractiveTable>>this.httpService2.doGetRequest(urlPrefix+'index');
    }

    public newTableRow(urlPrefix: string): Observable<INewRowSchema> {
        return <Observable<INewRowSchema>>this.httpService2.doGetRequest(urlPrefix+'new');
    }

    public editTableRow(urlPrefix: string, rowId:number): Observable<INewRowSchema> {
        return <Observable<INewRowSchema>>this.httpService2.doGetRequest(urlPrefix+'edit/'+rowId);
    }

    public deleteTableRow(urlPrefix: string, rowId: number): Observable<FormPostResult> {
        const postValues = {id: rowId};
        return this.httpService2.doFormPostRequest(urlPrefix+'delete', JSON.parse(JSON.stringify(postValues))).pipe(
            map((result) => {
                this.logger.log('[InteractiveTableService] delete result', result);
                return result;
            })
        );
    }

}
