import {AfterViewInit, Component, Input, Renderer2, ViewChild} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import 'moment/locale/nl';
import {ValidationConstraintService} from '../../services/validation-constraint.service';
import {TooltipService} from '../../../../services/tooltip/tooltip.service';
import {Subscription} from 'rxjs';
import {ExportOptions} from '../../../../../modules/dashboard/widgets/widget';
import {BaseFormConfig, ConfigType} from '../field/fieldDirective.interface';
import {LogItem} from '../form-log/form-log.interface';
import {Attachment} from '../form-attachment/form-attachment.interface';
import {Comment} from '../form-comment/form-comment.interface';
import {FormMapServerValueInterface} from '../form-map-server/form-map-server.interface';
import {LoggerService} from "../../../../services/logger/logger.service";

@Component({
    selector: 'abstract-form-field',
    template: ``,
})
export class AbstractFormFieldComponent implements AfterViewInit {
    @ViewChild('currentFormField', {static: false}) currentFormField: any;
    @Input() formIsSubmitted: boolean;

    config: FormFieldConfigInterface;
    protected rootConfig: any;

    group: UntypedFormGroup;
    selectedValue: any;
    @Input() readOnly: boolean;
    //invalidControlsErrorsForField:any;
    batchUpdateMode: boolean = false;
    included: boolean = false;

    private subGroupValueChanges: Subscription;

    constructor(public renderer: Renderer2, public validationConstraintService: ValidationConstraintService, public tooltipService: TooltipService, protected logger:LoggerService) {
    }

    ngAfterViewInit() {
        //When in batch update mode, remove the item from the form
        if (this.batchUpdateMode) {
            let control: any = this.group.controls[this.config.name];
            control.disable();
        }

        // Remove tooltips/validation errors on click
        if (this.currentFormField) {
            this.renderer.listen(this.currentFormField.nativeElement, 'click', (event) => {
                this.tooltipService.hideAllTooltips();
            });
        }

        if (this.group) {
            //TODO: dit stond eerst bij on init, gaat dit goed? staat nu hier omdat anders date componenten niet worden meegenomen met de clientside controlvalidatie
            this.subGroupValueChanges = this.group.valueChanges.subscribe(data => this.onFormGroupValueChanges(data));
        }
    }

    //When clicking the includebutton, visible in batchupdate mode
    handleClickInclude(included) {
        if (this.batchUpdateMode) {
            this.included = included;
            let control: any = this.group.controls[this.config.name];

            if (!this.included) {
                control.disable();
            }
            else {
                control.enable();
            }
        }
    }

    private _validationConstraints: any;

    set validationConstraints(value: any) {

        // If this element had an form error, clear it. Then it will be set with a new error or left empty
        if (this._validationConstraints && this._validationConstraints.length > 0) {
            this.tooltipService.destroyToolTip(this.currentFormField);
        }

        this._validationConstraints = value;

        if (value && value.length > 0 && this.formIsSubmitted) {
            this.showValidationConstraints(value);
        }
    }

    get validationConstraints(): any {
        return this._validationConstraints;
    }

    get initialValue(): any {
        if (this.config.initialValue) { //the initial value may be missing
            // if the initial value contains a date object
            if ((<FormDateValueInterface>this.config.initialValue).date) {
                // the model will be the date
                return (<FormDateValueInterface>this.config.initialValue).date;
            }
            return this.config.initialValue;
        }
        else if (this.batchUpdateMode) {
            //Return explicit empty, but present, initial value for batchupdate
            return '';
        }
    }

    //ngModel can set the initial config, but just don't do anything with it.
    set initialValue(value: any) {
        //Do nothing
    }

    //Deze werkt een beetje raar. Moet of leeg zijn (niet disabled) of een waarde hebben (disabled)
    //Werkt dus niet met false en true
    // TODO: deze uitfaseren. gebruik isControlReadOnly() of isControlDisabled()
    disabled(): any {
        let state = null;

        if (this.readOnly === true) {
            state = '';
        }
        else if (this.readOnly === false) {
            state = null;
        }

        if (this.config.disabled === true) {
            state = '';
        }

        if (this.batchUpdateMode && !this.included) {
            state = '';
        }

        return state;
    }

    // When the control is disabled from the backend, and will never be editable for this user in it's session (so not temporarily disabled)
    // For the client this is called readonly (no edit rights and never will) in the html forms it is called disabled.
    // Readonly fields should be displayed as plain label. Not greyed out or anything.
    // When a field is readonly it will never move up in usability, i.e. it will never get the disabled look, and never the editable look (of course).
    isControlReadOnly(): boolean {
        //True = Form control disabled + plain-label-look
        //False = form control disabled or enabled + greyedout or editable look
        return this.config.disabled === true;// || this.rootConfig.disabled === true
    }

    // Return true when the form control needs to be excluded from a form submit.
    // Disabled fields should be displayed as greyed out. They look like you can't use them now, but might in some other moment.
    // So, unlike readonly, disabled controls can move up in usability. They might become editable.
    public isControlDisabled(): boolean {
        //True = Form control disabled + greyed-out-look
        //False = Form control enabled + editable look
        return (this.readOnly === true || this.config.disabled === true || (this.batchUpdateMode && !this.included));
        //|| this.rootConfig.disabled === true
    }


    //TODO: klopt het dat dit wordt gedaan voor ieder veld in het form?? niet alleen voor dit veld. Is dit allemaal voor de twee wachtwoord velden die samen moeten werken? mega inefficient.
    //Show invalid control errors, from before submitting. So not server side form errors
    protected onFormGroupValueChanges(data: any) {

        //Get all errors
        let invalidControlsErrors: any = this.validationConstraintService.getControlErrors(this.group, data);

        //Find the ones for this field
        let invalidControlsErrorsForField: any = invalidControlsErrors[this.config.name];
        if (invalidControlsErrorsForField) {

            //When not in batchupdate, or in batchupdate but included > show error
            if (!this.batchUpdateMode || this.included) {

                //Remove present tooltips. (also remove old tooltip if no errors are present)
                this.tooltipService.destroyToolTip(this.currentFormField);
                //Show error tooltip
                let message: string = this.validationConstraintService.getErrorMessageForControlErrors([invalidControlsErrorsForField]);
                this.tooltipService.createAndShowTooltip(
                    this.renderer,
                    this.currentFormField,
                    message,
                );
            }
        }
    }

    // Show errors from the config/server after submitting
    protected showValidationConstraints(validationConstraints: any, placement: string = TooltipService.PLACEMENT_BOTTOM): void {
        let message: string = this.validationConstraintService.getErrorMessageForValidationConstraints(validationConstraints);
        this.logger.log('[AbstractFormFieldComponent] ' + 'Show form error message: ' + message + '\n For field: ', this.currentFormField);

        if (this.currentFormField) {
            setTimeout(() => {
                this.tooltipService.createAndShowTooltip(this.renderer, this.currentFormField, message, placement);
            });
        }
        else {
            this.logger.log('[AbstractFormFieldComponent] ' + 'WARNING: Want to show form error message but no html element is connected to this form-field');
        }
    }

    ngOnDestroy() {
        // destroy the tooltip for the current input
        if (this.currentFormField && this.tooltipService) {
            this.tooltipService.destroyToolTip(this.currentFormField);
        }

        if (this.subGroupValueChanges) {
            this.subGroupValueChanges.unsubscribe();
        }
    }
}

export interface FormFieldConfigOptionInterface {
    id: string | number
    name: string
    checked?: boolean
    attr?: { dataUsage?: number, url?: string }
}

export interface FormDateValueInterface {
    date?: string
    formatted: string
    unformatted: string
}

export interface FormFieldAttrInterface {
    [key: string]: string | number | boolean | object;
    inputGroup?: {
        addOn: {
            text: string,
            position: string
        }
    };
    marks?: {
        toolTip: string
        icon: string,
        isClickable:boolean,
        type: string
    }[];
    graphs?: {
        label: string
        url: string
        startDate: string
        endDate: string
    }[];
    maxLength?: number;
    logs?: LogItem[];
    attachments?: Attachment[];
    comments?: Comment[];
    exportOptions?: ExportOptions[];
    syncStatus?: string;
}

export interface FormFieldConfigInterface extends BaseFormConfig {
    name: string
    type: ConfigType
    initialValue?: string | number | boolean | null | FormDateValueInterface | FormMapServerValueInterface[]
    disabled: boolean
    attr: FormFieldAttrInterface
    label: string
    options?: FormFieldConfigOptionInterface[]
    required?: boolean
    placeholder?: string
    initialConstraints?: {
        range?: {
            min?: number
            max: number
        }

    }
    latitude?: number
    longitude?: number
    initialFocus?: boolean
    base_object_id?: number
    referenceId?: number
    children?: FormFieldConfigInterface[]
}
