import {Component, Input, OnInit} from '@angular/core';
import {Timeline} from './timeline.interface';

@Component({
  selector: 'timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss']
})

export class TimelineComponent implements OnInit {
    @Input() public config: Timeline;
    @Input() public bar: boolean = true;
    @Input() public nextDate: number;

    public timeDifference:number;

    constructor() { }

    ngOnInit(): void {
        if (this.nextDate && this.config.created) {
            this.timeDifference = (this.nextDate - this.config.created);
        }
    }

}
