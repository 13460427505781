
import {
    AfterViewInit,
    ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, ViewChild,
} from '@angular/core';

import {GlobalAlertService} from '../global-alert.service';
import {ButtonCode, GlobalPopup} from '../global-popup';
import {HTTPError} from '../../../shared/services/http/http-error';
import {RequestFailure} from '../../../shared/services/http/request-failure';
import {FormDataService} from '../../../shared/components/form/services/form-data.service';
import {GlobalModel} from '../../../shared/services/state/global.model';
import {AuthorizationService} from '../../../shared/services/authorization/authorization.service';
import {TranslateService} from '../../../shared/services/translate/translate.service';
import {DefaultPopupWrapperComponent} from './default-popup-wrapper/default-popup-wrapper.component';
import {AbstractBasePopupComponent} from './abstract-base-popup.component';
import {TooltipService} from '../../../shared/services/tooltip/tooltip.service';
import { FormEvent } from '../../../shared/components/form/containers/form/form.interface';
import {LoggerService} from "../../../shared/services/logger/logger.service";
import {GlobalEvent} from '../../../shared/interfaces/global-event';
import {Subscription} from 'rxjs';
import {LocalizationService} from '../../../shared/components/localization/localization.service';

@Component({
    selector: 'global-popup-translation-component',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <default-popup-wrapper-component #wrapper [componentRef]="this"
                                         [globalPopup]="globalPopup"
                                         [showLoader]="isLoading" (onClose)="closePopup($event)">
            <ng-container *ngIf="formData && config">
                <form-component #form
                                [config]="config"
                                [name]="getFormName()"
                                [readOnly]="readOnly"
                                [formIsSubmitted]="formIsSubmitted"
                                (onComponentEvent)="handleComponentEvent($event)">
                </form-component>
            </ng-container>
        </default-popup-wrapper-component>
    `,
})
export class GlobalPopupLocalizationComponent extends AbstractBasePopupComponent implements AfterViewInit, OnDestroy {
    private static readonly FORM_URL: string = 'translations/translate/form';
    private static readonly TRANSLATE_URL: string = 'translations/translate';

    protected subscriptions: Subscription[] = [];

    @Input() localizationKey: string = '';
    @ViewChild('form', {static: false}) form: any;
    @ViewChild('wrapper', {static: false}) wrapper: DefaultPopupWrapperComponent;

    disableButtonsOverride: any = (button: any) => {
        return (this.config && this.config.disabled && (button.code !== ButtonCode.ANNULEREN)) ||
            (this.form && this.form.form && this.form.form.status === 'INVALID' && button.code !== ButtonCode.ANNULEREN);
    };


    public constructor(protected cd: ChangeDetectorRef, protected globalAlertService: GlobalAlertService,
                       private auth: AuthorizationService, protected formDataService: FormDataService,
                       protected elementRef: ElementRef, private model: GlobalModel,
                       protected ts: TranslateService, protected tooltipService: TooltipService, protected logger:LoggerService, private localizationService: LocalizationService) {
        super(cd, globalAlertService, elementRef, ts, tooltipService, logger);

        this.subscriptions.push(this.model.onGlobalEvent.subscribe((event: GlobalEvent) => {
            switch (event.type) {
                case GlobalEvent.EVENT_FORM_MARKS_CLICK:
                    if(event.data.type === 'translate'){
                        this.processTranslation(event)
                    }
            }
        }));
    }

    ngAfterViewInit() {

        // Retrieve map item id from the popup data
        this.localizationKey = this.globalPopup.data.localizationKey;

        let popupData: any = this.globalPopup.data;

        this.model.formLocationComponentPosition = null;

        this.submitButton = this.getSubmitButton();

        this.requestFormData();
    }

    private processTranslation(event:any):void{
        const targetFieldId = event.data.targetFieldId;
        const targetField: HTMLInputElement = (document.getElementById(targetFieldId) as HTMLInputElement);
        const targetLanguage: string = event.data.targetLanguage;
        const targetLanguageCode: string = this.convertLocale(targetLanguage)
        const sourceLanguageCode: string = (document.querySelectorAll('form[name="'+this.getFormName()+'"] .lumi-select-field')[1] as Element)?.getAttribute('data-selected-option');
        const sourceLanguage: string = this.convertLocale(sourceLanguageCode)
        const sourceField = document.querySelectorAll('form[name="'+this.getFormName()+'"] .__'+sourceLanguage+'__')[0];
        let sourceValue: string = (sourceField as HTMLInputElement)?.value

        if(sourceField.classList.contains('is-editor')){ //The source is a wysiwyg editor field
            let sourceHtmlContent = sourceField.querySelectorAll('.tox-edit-area iframe')[0] as HTMLIFrameElement;
            if(sourceHtmlContent){
                sourceValue = sourceHtmlContent.contentDocument.body.innerHTML
            }
        }

        //Show dots to indicate loading
        if(targetField.classList.contains('is-editor')){ //Target is a wysiwyg editor
            (targetField.querySelectorAll('.tox-edit-area iframe')[0] as HTMLIFrameElement).contentDocument.body.innerHTML = '...'
        } else { //Target is a plain text field
            targetField.value = '...'
        }

        this.localizationService.getTranslation(GlobalPopupLocalizationComponent.TRANSLATE_URL, sourceLanguageCode, targetLanguageCode, sourceValue).subscribe((formPostResult) => {
            const returnValue = (formPostResult as any).value

            //Set return value to field
            if(targetField.classList.contains('is-editor')){ //Target is a wysiwyg editor
                (targetField.querySelectorAll('.tox-edit-area iframe')[0] as HTMLIFrameElement).contentDocument.body.innerHTML = returnValue
            } else { //Target is a plain text field
                targetField.value = returnValue
            }

            //set return value in form
            this.form['form']['value']['translations'][targetLanguageCode]['value'] = returnValue
        });
    }

    private convertLocale(input:string):string{
        if(input === 'nl'){
            return 'Dutch'
        } else if(input === 'en'){
            return 'English'
        } else if(input === 'de'){
            return 'German'
        } else if(input === 'fr') {
            return 'French'
        } else if(input === 'sl') {
            return 'Slovenian'
        } else if(input === 'Dutch'){
            return 'nl'
        } else if(input === 'English'){
            return 'en'
        } else if(input === 'German'){
            return 'de'
        } else if(input === 'French'){
            return 'fr'
        } else if (input === 'Slovenian'){
            return 'sl'
        }
    }

    handlePopupAction(event: MouseEvent, alert: GlobalPopup, button: any): void {
        // callback for submit button comes from this form, not from outside this component
        if (button === this.submitButton) {
            this.form.handleComponentEvent({event: FormEvent.SAVE, data: {}});
            // this.form.submitForm();
        } else {
            // Hide alert and perform callback of button
            this.onPopupAction.emit({event: event, alert: alert, button: button});
        }
    }

    handleComponentEvent(eventData: any): void {
        if (eventData.event === FormEvent.SAVE) {
            this.submitFormData(eventData.data.formData);
        }
    }

    private submitFormData(form: any): void {
        if (this.localizationKey === '') {
            this.logger.log('[GlobalAlertMoveItemComponent] ' + 'ERROR: trying to submit the form, but it is not linked to an item');
            return;
        }

        this.handleSubmitForm();
        let url = GlobalPopupLocalizationComponent.FORM_URL + '/set/' + this.localizationKey;

        this.formDataService.setFormData(form, this.getFormName(), url,
            (formData: any) => {
                // this.formData = formData;
                this.handleSubmitResponse();
                // Hide alert and perform success callback via submit button
                this.onPopupAction.emit({
                    event: null,
                    alert: this.globalPopup,
                    button: this.submitButton,
                    data: formData,
                });
            },
            (failure: RequestFailure) => {
                this.handleSubmitResponse(failure.formErrors);
            },
            (error: HTTPError) => {
                this.handleSubmitResponse(null, true);
            }, false
        );

    }

    closePopup(event: any): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
        this.doDefaultCloseHandling(event, false);
    }

    public requestFormData(): void {
        this.formDataService.getFormData(GlobalPopupLocalizationComponent.FORM_URL + '/get/' + this.localizationKey,
            (formData: any) => {

                // TODO: pas hier de latlng van de popup.data toe op de formData, als beschikbaar
                // En update dus de kaart en de locatie enzo

                // setTimeout(() => {

                this.formData = formData;
                this.isLoading = false;


                this.cd.detectChanges();
                this.wrapper.runChangeDetection();
                // });
            },
            () => {
                this.closePopup(null);
            },
            () => {
                this.closePopup(null);
            }, false, false
        );
    }

    ngOnDestroy() {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
    }
}
