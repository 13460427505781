import {ChangeDetectorRef, Component, NgZone, OnInit} from '@angular/core';
import {FormGroupComponent} from '../form-group/form-group.component';
import {StorageService} from '../../../../services/storage/storage.service';
import {GlobalModel} from '../../../../services/state/global.model';
import {GlobalAlertService} from '../../../../../wrapper/global-alert/global-alert.service';
import {TranslateService} from '../../../../services/translate/translate.service';
import {HttpService2} from '../../../../services/http-service-2.0/http2.0.service';
import {MapServerService} from '../../../map/map-server/map-server.service';

@Component({
  selector: 'form-button-group',
  templateUrl: './form-button-group.component.html'
})
export class FormButtonGroupComponent extends FormGroupComponent implements OnInit {

    constructor(
        public cd: ChangeDetectorRef,
        public storage: StorageService,
        public model: GlobalModel,
    ) {
        super(cd, storage, model);
    }

  ngOnInit() {
  }

}
