<div #scrollContainer class="map-table-table-container" (scroll)="onScroll()">
    <table class="table table-widget-table {{_tableOptions.clearHeader?'clear-header':''}} table-overflow">
        <thead>
        <tr *ngIf="headers && headers.length > 0" class="table-header text-color-white">
            <ng-container *ngFor="let header of headers">
                <th *ngIf="header.isVisible" class="text-color-white">
                    <div class="d-flex align-items-center" (click)="sortByColumn(header.columnId)">
                        <div class="pr-2" *ngIf="allowFiltering && checkActiveColumnFilter(header.columnId)"
                             (click)="filterColumn($event, header.columnId)">&#9656;
                        </div>
                        <i *ngIf="allowFiltering && !checkActiveColumnFilter(header.columnId)"
                           (click)="filterColumn($event,header.columnId)" [title]="'table.filtercolumn' | translate"
                           style="margin-left:-0.5rem; font-size:20px;"
                           class="material-icons p-0">
                            {{'more_vert'}}
                        </i>
                        <span class="text-truncate" title="{{header.label}}">{{header.label}}</span>
                        <i *ngIf="header.sortDirection !== TABLE_SORT_DIRECTION.SORT_DIRECTION_UNSET"
                           class="material-icons table_icon">
                            {{header.sortDirection === TABLE_SORT_DIRECTION.SORT_DIRECTION_ASC ? 'expand_more' : 'expand_less'}}
                        </i>
                    </div>
                </th>
            </ng-container>
            <th *ngIf="_tableOptions.allowDeleteRow"></th>
        </tr>
        </thead>
        <tbody [id]="rows">
        <tr #topPlaceHolder></tr>
        <ng-container *ngFor="let row of viewModelRows">
            <tr [ngStyle]="{height: rowHeightInPX + 'px'}"
                *ngIf="row.isVisible"
                (click)="onRowClick($event,row)"
                [ngClass]="getRowClass(row)"
                (mousedown)="handleMouseDownRow($event)"
                (mouseover)="handleMouseOverRow($event,row)">

                <ng-container *ngFor="let cell of row.cells; let cellIndex = index">
                    <td *ngIf="cell.isVisible">
                        <table-cell (onTruncateClick)="toggleTruncation(cell)"
                                    [selected]="row.isSelected"
                                    [maxCellCharacters]="maxCellCharacters"
                                    [maxCellLines]="maxCellLines"
                                    [cell]="cell">
                        </table-cell>
                    </td>
                </ng-container>
                <td *ngIf="_tableOptions.allowDeleteRow" (click)="deleteRow($event,row)" class="table-row-delete cursor-pointer"
                    style="width:1rem"><i
                        class="material-icons md-dark" style="font-size: 1.25rem; padding: 0">delete</i></td>
            </tr>
        </ng-container>
        <tr #bottomPlaceHolder>
        </tr>
        </tbody>
    </table>

    <ng-container *ngIf="rows.length < 1">
        <div class="table-no-data-container">
            <div class="chart-widget-no-data">{{'Geen informatie beschikbaar' | translate}}</div>
        </div>
    </ng-container>
</div>

<div *ngIf="(isLoading$ | async)" class="table-component-info-container"
     [ngStyle]="{width:_tableOptions.parentWidthPercentage + '%'}">
    <div class="loading-form-small">
        <div class="loader"></div>
    </div>
</div>
