<default-popup-wrapper-component [componentRef]="this" [disableButtons]="readOnly"
                                 [hideButtons]="isLoading" [globalPopup]="globalPopup"
                                 [title]="globalPopup.title"
                                 (onClose)="closePopup($event)">

    <div *ngIf="isLoading" class="py-3 d-flex justify-content-center">
        <div class="loader"></div>
    </div>

    <ng-container *ngIf="formData && config">
        <form-component #form
                        [config]="config"
                        [name]="getFormName()"
                        [validationConstraints]="validationConstraints"
                        [invalidControlsErrors]="invalidControlsErrors"
                        [readOnly]="readOnly"
                        [formIsSubmitted]="formIsSubmitted"
                        (onComponentEvent)="handleComponentEvent($event)"
        ></form-component>
    </ng-container>
</default-popup-wrapper-component>
