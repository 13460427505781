import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';

@Component({
    selector: 'activity-status-icon',
    templateUrl: './activity-status-icon.component.html'
})
export class ActivityStatusIconComponent implements OnInit {
    @Input() small: boolean = false;
    @Input() hasAttention: boolean;
    @Input() isDone: boolean;
    @Input() readonly: boolean;
    @Output() onClick: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();
    @ViewChild('checkMark', {static: false}) checkMark: ElementRef<HTMLDivElement>;
    @ViewChild('checkButton', {static: false}) checkButton: ElementRef<HTMLDivElement>;
    
    constructor() {
    }
    
    ngOnInit() {
    }
    
    public getCheckMarkClass(): string {
        let classString: string = this.small ? 'asi-checkmark-div-small' : 'asi-checkmark-div';
        if (this.readonly) {
            classString += '-disabled';
        }
        if (this.isDone) {
            classString += ' div-checked';
        }
        return classString;
    }
    
    public onToggleStatusDone($event: MouseEvent) {
        this.onClick.emit($event);
    }
    
    public fadeInAndOutCheckmarkClass(): void {
        this.checkMark.nativeElement.className = this.small ? 'asi-checkmark-disabled-small' : 'asi-checkmark-disabled';
        setTimeout(() => {
            this.checkMark.nativeElement.className = this.getDivClass();
        }, 500);
    }
    
    public getDivClass() {
        let className = this.hasAttention ? 'asi-checkmark-attention' : 'asi-checkmark-todo';
        if (this.small) {
            className += '-small';
        }
        return className;
    }
}
