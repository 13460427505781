import {TreeNodeLMX} from './tree-node-lmx';

/**
 * Created by Christiaan on 10/05/2017.
 */
export class TreeLMX {
    public id: number;
    public code: string;
    public label: string;

    //@Type(() => TreeNodeLMX)
    public treeNodes: TreeNodeLMX;

    //Curretn active filter query for the tree
    public currentActiveFilterQuery: string;

    public countDescriptions: {
        length: number
    };
}
