<div class="login-logo">
    <a href="/"><img src="{{getLogoPath()}}" alt="Luminizer" title="Luminizer" /></a>
</div>
<div class="login-header" *ngIf="beforeLogin">
    <div class="item language">
        <img [attr.src]="activeFlagSrc" [attr.title]="activeLanguageName" [attr.alt]="activeLanguageName" class="dropdown-toggle" role="button" id="dropdownLanguages" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownLanguages">
            <ng-container *ngFor="let language of languages">
                <a *ngIf="language.value != activeLanguageCode" class="dropdown-item" [attr.disabled]="language.value === activeLanguageCode" (click)="handleChangeLanguage(language.value)">
                    <img src="/assets/img/flags/flag-{{language.value}}.svg" [attr.title]="language.label" [attr.alt]="language.label" /> {{language.label}}
                </a>
            </ng-container>
        </div>
    </div>
</div>