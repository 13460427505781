import {ChangeDetectionStrategy, Component, EventEmitter, Output, Renderer2} from '@angular/core';
import {AbstractFormFieldComponent} from '../abstract/abstract-form-field.component';
import {TooltipService} from "../../../../services/tooltip/tooltip.service";
import {ValidationConstraintService} from "../../services/validation-constraint.service";
import {FormButtonComponentEvent} from '../form-button/form-button.component';
import {LoggerService} from "../../../../services/logger/logger.service";

@Component({
    selector: 'form-checkbox',
    template: `
        <form-checkbox-default *ngIf="(config && config.attr && !config.attr.bulkCheck) || (config && !config.attr) && group"
                               [config]="config"
                               [readOnly]="readOnly"
                               [formIsSubmitted]="formIsSubmitted"
                               [group]="group" (onComponentEvent)="handleComponentEvent($event)">
        </form-checkbox-default>
        <form-checkbox-bulk *ngIf="config && (config.attr && config.attr.bulkCheck) && group"
                            [config]="config"
                            [readOnly]="readOnly"
                            [formIsSubmitted]="formIsSubmitted"
                            [group]="group" (onComponentEvent)="handleComponentEvent($event)">
        </form-checkbox-bulk>
    `, changeDetection: ChangeDetectionStrategy.Default
})

export class FormCheckBoxComponent extends AbstractFormFieldComponent {

    @Output() onComponentEvent: EventEmitter<FormButtonComponentEvent> = new EventEmitter();

    constructor(public renderer:Renderer2, public validationConstraintService:ValidationConstraintService, public tooltipService:TooltipService, protected logger:LoggerService) {
        super(renderer, validationConstraintService, tooltipService, logger)
    }

    // Pass component event further up
    handleComponentEvent(eventData: any): void {
        this.onComponentEvent.emit(eventData);
    }
}
