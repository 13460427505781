/**
 * Created by Christiaan on 07/04/2017.
 */

import {Directive, NgZone, Output, EventEmitter} from '@angular/core';

@Directive({
    selector: '[globalMousedownOutsideAngular]'
})

export class GlobalMousedownOutsideAngularDirective
{
    private static readonly EVENT_MOUSEDOWN:string = "mousedown";
    private static readonly EVENT_TOUCHSTART:string = "touchend";

    @Output('globalMousedownOutsideAngular') mouseupHandler: EventEmitter<any> = new EventEmitter();

    constructor(private ngZone:NgZone)
    {
        this.ngZone.runOutsideAngular(() => {
            document.addEventListener(GlobalMousedownOutsideAngularDirective.EVENT_MOUSEDOWN, (event:any)=> {
                this.mouseupHandler.emit(event);
            });
            document.addEventListener(GlobalMousedownOutsideAngularDirective.EVENT_TOUCHSTART, (event:any)=> {
                this.mouseupHandler.emit(event);
            });
        });
    }
}
