/**
 * Created by Christiaan on 01/03/2017.
 */
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component, ElementRef,
    EventEmitter,
    Input, OnDestroy,
    Output,
} from '@angular/core';

import {GlobalAlertService} from "../global-alert.service";
import {GlobalPopup} from "../global-popup";
import {TranslateService} from "../../../shared/services/translate/translate.service";
import Utils from "../../../shared/utils/utils";
import {FormAttachmentComponent} from "../../../shared/components/form/components/form-attachment/form-attachment.component";
import {FormDataService} from "../../../shared/components/form/services/form-data.service";
import {AbstractBasePopupComponent} from "./abstract-base-popup.component";
import {TooltipService} from "../../../shared/services/tooltip/tooltip.service";
import {LoggerService} from "../../../shared/services/logger/logger.service";

@Component ({
    selector: 'global-popup-view-attachment-component',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <default-popup-wrapper-component [globalPopup]="globalPopup" [showLoader]="isLoading"
                                         [title]="data?data.fileName:ts.translate('attachment.load')"
                                         (onClose)="closePopup($event)">
            <div *ngIf="previewImg" class="lightgrey-background cursor-pointer d-flex flex-column"
                 title="{{'attachment.click' | translate}}" (click)="handleDownloadFile($event)">
                <div class="d-flex justify-content-center" style="flex-shrink:0">
                    <div class="dit-is-een-safari-fix">
                        <img style="max-width:100%;flex-shrink: 0; max-height: 85vh; width: auto; height:auto;"
                             [src]="previewImg">
                    </div>
                </div>
            </div>
        </default-popup-wrapper-component>
    `
})

export class GlobalPopupViewAttachmentComponent extends AbstractBasePopupComponent implements AfterViewInit
{
    public previewImg:any = "";
    private fileName:string = "";
    public data:any;


    public constructor(protected cd:ChangeDetectorRef, protected elementRef:ElementRef, protected globalAlertService:GlobalAlertService, public ts:TranslateService, private formDataService:FormDataService, protected tooltipService:TooltipService, protected logger:LoggerService) {
        super(cd, globalAlertService, elementRef, ts, tooltipService, logger);
    }

    ngAfterViewInit():void
    {
        if (this.globalPopup.data && this.globalPopup.data.attachment){

            this.logger.log("[GlobalPopupViewAttachmentComponent] " + "attachment: " , this.globalPopup.data.attachment);

            this.fileName = this.globalPopup.data.attachment.fileName;
            let attachmentId:number = this.globalPopup.data.attachment.id;
            let url:string = this.globalPopup.data.attachment.url;

            this.formDataService.getAttachment(url + attachmentId,
                (data: any) => {
                    this.data = data;
                    this.logger.log("[FormAttachmentComponent] " + "Attachmentdata received for: " + data.fileName);
                    this.previewImg = decodeURIComponent(data.fileData);
                    this.isLoading = false;
                    this.cd.detectChanges();
                }
                ,
                () => {
                    this.isLoading = false;
                    this.cd.detectChanges();
                },
                () => {
                    this.isLoading = false;
                    this.cd.detectChanges();
                }
            )
        }else{
            this.logger.log("[GlobalPopupViewAttachmentComponent] " + "ERROR: no attachment data, cant load attachment");
            this.isLoading = false;
        }

        this.cd.detectChanges();
    }
    
    public handleDownloadFile(event:any):void
    {
        Utils.downloadAsFile(true, this.data.fileData, this.data.fileName, 'global-popup-view-attachment-component');
    }

    public closePopup(event:any):void
    {
        this.doDefaultCloseHandling(event, false);
    }

    handlePopupAction(event: MouseEvent, alert: GlobalPopup, button: any): void {
        //Hide alert and perform callback of button
        this.onPopupAction.emit({event:event, alert:alert, button:button});
    }
}
