import {Injectable} from '@angular/core';
import {HttpService2} from "../../services/http-service-2.0/http2.0.service";
import {FormPostResult} from "../../services/http-service-2.0/http.interface";
import {LoggerService} from "../../services/logger/logger.service";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";


@Injectable()
export class LocalizationService {
    constructor(protected httpService2: HttpService2, protected logger:LoggerService) {
    }

    public getTranslation(url:string, sourceLocale:string, targetLocale:string, sourceValue:string): Observable<FormPostResult>{
        this.logger.log('[LocalizationService] ' + 'Get the translation in '+targetLocale+' for sourceValue: '+sourceValue)
        let postValues: any = {
            sourceLocale: sourceLocale,
            targetLocale:targetLocale,
            value:sourceValue
        };
        return this.httpService2.doFormPostRequest(url, postValues, false).pipe(
            map((result) => {
                return result;
            })
        );
    }
}
