import {Injectable} from "@angular/core";
import {RequestFailure} from "../../shared/services/http/request-failure";
import {GlobalModel} from "../../shared/services/state/global.model";
import {HTTPService} from "../../shared/services/http/http.service";
import {HTTPError} from "../../shared/services/http/http-error";
import {GlobalAlertService} from "../../wrapper/global-alert/global-alert.service";

@Injectable()
export class ExportService
{
    protected startUrl = 'export-wizard/start';

    public constructor(private model:GlobalModel, protected httpService:HTTPService, protected globalAlertService:GlobalAlertService) {}

    public getExportFormData(requestURL:any, successCallBack?:() => any, failCallBack?:(failure:RequestFailure) => any):void
    {
        if (!requestURL || requestURL == ""){
            requestURL = this.startUrl;
        }

        this.httpService.doGetRequest(
            requestURL,
            (json:any, url:string) => {

                this.model.exportFormData.next(json);

                successCallBack();

            }, (failure:RequestFailure) => {

                failCallBack(failure)

            }, (error:HTTPError) => {
                //Error is handled in httpService
            }, true
        );
    }

    public setExportFormData(url:string, form:any, formName:string, successCallBack?:(formName:string) => any, failCallBack?:(failure:RequestFailure) => any, errorCallBack?:(error:HTTPError) => any)
    {
        //TODO: dit moet generieker voor alle form objecten met een child 'file'
        if (form && form.file_selector){
            form.file_selector.file = form.file_selector.file_content;
            delete form.file_selector.file_content;
        }

        let fullForm = {[formName]:form};

        this.httpService.doFormPostRequest(url, fullForm,
            (json:any)=>{

                this.model.exportFormData.next(json);

                //Let component know request is send
                successCallBack(json.schema.name);
            }, (failure:RequestFailure)=>{

                //The username is not valid or other failure
                failCallBack(failure);
            }, (error:HTTPError) => {
                //Error is handled in httpService
                errorCallBack(error);

            }, true
        );
    }
}
