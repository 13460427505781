/**
 * Created by Christiaan on 01/05/2017.
 */
export class GlobalAlert
{
    title:string;
    subTitle:string;
    label:string;
    removed:boolean;
    icon:any;
    type:number;
    error:any;
    autoFade:number;
    isAutoFadeActive:boolean;
}
