import {LumiSelectOption} from '../../commonUI/select/lumi-select/lumi-select.interface';

export interface CheckActivity {
    name: string;
    done: boolean;
    description: string;
    additionalInfo: CheckActivityAdditionalInfo
    combinationName?: string;
    note?: string
    attention: boolean;
    inputs: (ActivityTextInput|ActivitySelectInput|ActivityUploadInput|ActivityCheckboxInput)[];
    isLoading: boolean;
    id: number
    objectCode: string;
    readonly: boolean;
}

export interface CheckActivityAdditionalInfo {
    creator: string;
    created: string;
    updated: string;
}

export interface ActivityInput {
    id: number;
    value: string|number;
    label: string;
    required: boolean;
    fieldName: string;
    activityInputType: ActivityInputType
    readonly: boolean;
}

export interface ActivityTextInput extends ActivityInput {
    activityInputType: ActivityInputType.STRING;
    value: string;
    regex?: string;
    invalidRegexMessage?: string;
    textArea?: boolean;
    showScanner?: boolean;
}

export interface ActivitySelectInput extends ActivityInput {
    activityInputType: ActivityInputType.SELECT;
    options: LumiSelectOption[];
}

export interface ActivityUploadInput extends ActivityInput {
    activityInputType: ActivityInputType.UPLOAD;
    value: string;
    attachmentPropertyName: string;
}

export interface ActivityCheckboxInput extends ActivityInput {
    activityInputType: ActivityInputType.CHECKBOX;
    value: string;
}

export enum ActivityInputType {
    'STRING' = 'string',
    'SELECT' = 'select',
    'UPLOAD' = 'upload',
    'CHECKBOX' = 'checkbox',
    'NUMBER' = 'number'
}
