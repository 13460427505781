import {
    AfterViewInit,
    Component,
    EventEmitter,
    OnInit,
    Output,
    QueryList,
    Renderer2,
    ViewChildren
} from '@angular/core';
import {AbstractFormFieldComponent} from '../form/components/abstract/abstract-form-field.component';
import {ValidationConstraintService} from '../form/services/validation-constraint.service';
import {FormEvent} from '../form/containers/form/form.interface';
import {CheckActivity} from './check-activity/check-activity.interface';
import {ProjectActivitiesConfig, ProjectActivitiesConfigActivity} from './check-activities.interface';
import {CheckActivityComponent} from './check-activity/check-activity.component';
import {ProgressBarItem} from './progress-bar/progress-bar.interface';
import {GlobalAlertService} from '../../../wrapper/global-alert/global-alert.service';
import {TranslateService} from '../../services/translate/translate.service';
import {FormDataService} from '../form/services/form-data.service';
import {TooltipService} from '../../services/tooltip/tooltip.service';
import {finalize, take} from 'rxjs/operators';
import {ButtonCode} from '../../../wrapper/global-alert/global-popup';
import {LoggerService} from "../../services/logger/logger.service";

@Component({
    selector: 'check-activities',
    templateUrl: './check-activities.component.html'
})
export class CheckActivitiesComponent extends AbstractFormFieldComponent implements OnInit, AfterViewInit {
    public checkActivities: CheckActivity[];
    public config: ProjectActivitiesConfig;
    @ViewChildren('checkActivityComponent') checkActivityComponents: QueryList<CheckActivityComponent>;
    
    @Output() onComponentEvent: EventEmitter<any> = new EventEmitter();
    public isLoading: boolean;
    public progressBarItems: ProgressBarItem[];
    private activities: ProjectActivitiesConfigActivity[];
    
    constructor(
        public renderer: Renderer2,
        private globalAlertService: GlobalAlertService,
        private translateService: TranslateService,
        private formDataService: FormDataService,
        public validationConstraintService: ValidationConstraintService,
        private toolTipService: TooltipService,
        protected logger: LoggerService
    ) {
        super(
            renderer,
            validationConstraintService,
            toolTipService,
            logger
        );
    }
    
    ngOnInit() {
        if (this.config && this.config.attr) {
            if (this.config.attr.activities) {
                this.activities = this.config.attr.activities;
                this.activities.sort( (a,b) => {
                    return a.rank - b.rank;
                })
                this.setProjectActivities();
            }
        }
    }
    
    public isDeletable(checkActivity: CheckActivity): boolean {
        const activity = this.activities.find(_activity => _activity.id === checkActivity.id);
        return activity.deleteUrl && activity.deleteUrl !== '';
    }
    
    public isUpdatable(checkActivity: CheckActivity): boolean {
        const activity = this.activities.find(_activity => _activity.id === checkActivity.id);
        return activity.editUrl && activity.editUrl !== '';
    }
    
    public updateActivity(checkActivity: CheckActivity): void {
        const baseObjectId: number = this.config.base_object_id;
        const activity = this.activities.find(_activity => _activity.id === checkActivity.id);
        
        this.globalAlertService.addPopupCreateCheckActivity(
            baseObjectId,
            activity.editUrl,
            activity.saveEditUrl,
            null,
            false,
            () => {
                this.onComponentEvent.emit({
                    event: FormEvent.ACTIVITY_CREATE_SUCCESS,
                    data: {referenceId: baseObjectId}
                });
            }, () => {
            });
    }
    
    public deleteActivity(checkActivity: CheckActivity): void {
        const activity = this.activities.find(_activity => _activity.id === checkActivity.id);
        
        this.globalAlertService.addPopup(
            this.translateService.translate('activity.deletetitle'),
            this.translateService.translate('activity.delete'),
            [
                {
                    label: this.translateService.translate('Annuleren'),
                    code: ButtonCode.ANNULEREN,
                    isPrimary: true
                },
                {
                    label: this.translateService.translate('Verwijderen'), code: ButtonCode.DELETE,
                    callback: () => {
                        checkActivity.isLoading = true;
                        
                        this.formDataService.deleteCheckActivity(activity.deleteUrl,
                            () => {
                                checkActivity.isLoading = false;
                                // Remove the old activity, client side
                                let deleteIndex: number = this.activities.indexOf(activity);
                                
                                if (deleteIndex > -1) {
                                    this.activities.splice(deleteIndex, 1);
                                    this.setProjectActivities();
                                }
                                
                                this.onComponentEvent.emit({event: FormEvent.ACTIVITY_DELETE_SUCCESS, data: {}});
                            }, () => {
                                checkActivity.isLoading = false;
                                this.onDeleteFailed(checkActivity);
                            }, () => {
                                checkActivity.isLoading = false;
                                this.onDeleteFailed(checkActivity);
                            });
                    },
                    isPrimary: false
                }], () => {
            });
    }
    
    public saveActivity(checkActivity: CheckActivity): void {
        const baseObjectId: number = this.config.base_object_id;
        // get copy
        const activityCopy: ProjectActivitiesConfigActivity = JSON.parse(JSON.stringify(
            this.activities.find(_activity => _activity.id === checkActivity.id)
        ));
        const previousActivity: ProjectActivitiesConfigActivity = JSON.parse(JSON.stringify(activityCopy));
        
        checkActivity.isLoading = true;
        
        // apply the new data (do not retain references)
        activityCopy.inputData = checkActivity.inputs;
        activityCopy.activityDone = checkActivity.done;
        activityCopy.activityFlagged = checkActivity.attention;
        activityCopy.note = checkActivity.note;
        
        // save the copy
        this.formDataService.saveCheckActivity(activityCopy.saveUrl, activityCopy)
            .pipe(
                take(1),
                finalize(() => {
                    checkActivity.isLoading = false;
                })
            ).subscribe(() => {
                // locally update actual activity
                const activity = this.activities.find(_activity => _activity.id === checkActivity.id);
                activity.inputData = activityCopy.inputData;
                activity.activityDone = activityCopy.activityDone;
                activity.activityFlagged = activityCopy.activityFlagged;
                activity.note = activityCopy.note;
                
                this.onComponentEvent.emit({
                    event: FormEvent.ACTIVITY_SAVE_SUCCESS,
                    data: {
                        referenceId: baseObjectId
                    },
                    areAllActivitiesDone: this.activities.every(_activity => _activity.activityDone)
                });
            }, () => {
                this.handleSaveFailed(previousActivity, checkActivity);
            });
    }
    
    public getProgressBarItems(): ProgressBarItem[] {
        return this.progressBarItems = this.checkActivities.map(activity => {
            return {
                isDone: activity.done,
                hasAttention: activity.attention
            };
        });
    }
    
    private onDeleteFailed(checkActivity: CheckActivity): void {
        const component: CheckActivityComponent = this.checkActivityComponents.toArray()
            .find(_component => _component.activity.id === checkActivity.id);
        
        component.showRequestError(this.translateService.translate('check-activity.error.delete'));
    }
    
    private handleSaveFailed(previousActivity: ProjectActivitiesConfigActivity, checkActivity: CheckActivity) {
        const component: CheckActivityComponent = this.checkActivityComponents.toArray()
            .find(_component => _component.activity.id === checkActivity.id);
        // failed so reset local data to previous state;
        if (previousActivity.activityDone !== checkActivity.done) {
            component.toggleDone();
        }
        if (previousActivity.activityFlagged !== checkActivity.attention) {
            component.toggleAttention();
        }
        component.showRequestError(this.translateService.translate('check-activity.error.save'));
        checkActivity.isLoading = false;
    }
    
    private setProjectActivities(): void {
        this.checkActivities = [...this.activities.map(this.createCheckActivity)];
    }
    
    private createCheckActivity(activity: ProjectActivitiesConfigActivity): CheckActivity {
        return JSON.parse(JSON.stringify(<CheckActivity>{
            name: activity.name,
            done: activity.activityDone,
            description: activity.description,
            objectCode: activity.objectCode,
            additionalInfo: activity.additionalInfo,
            combinationName: activity.combinationName,
            note: activity.note,
            attention: activity.activityFlagged,
            inputs: [...activity.inputData],
            isLoading: false,
            id: activity.id,
            readonly: activity.readonly
        }));
    }
}
