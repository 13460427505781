/**
 * Created by Jasper on 10/03/2021.
 */

import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
}) export class PrintService {

    constructor() {}

    public printMap() {
        const body = $('body');
        const mapContainer = $('.map-component-container');
        const mapContainerParent = mapContainer.parent();
        const printContainer = $('<div style="position:relative;width:100%;max-width:1920px;">'); //Give max width to prevent smaller tekst in pdf

        printContainer
            .height(mapContainer.height())
            .append(mapContainer)
            .prependTo(body);

        const content = body
            .children()
            .not(printContainer)
            .not('script')
            .detach();

        /**
         * Needed for those who use Bootstrap 3.x, because some of
         * its `@media print` styles ain't play nicely when printing.
         */
        const patchedStyle = $('<style media="print">')
            .text(`
              img { max-width: none !important; }
              a[href]:after { content: ""; }
            `)
            .appendTo('head');

        window.focus();
        window.print();

        body.prepend(content);
        mapContainerParent.prepend(mapContainer);

        printContainer.remove();
        patchedStyle.remove();
        return false;
    }

    public printManual() {
        const body = $('body');
        const manualContainer = $('.release-notes-container');
        const manualContainerParent = manualContainer.parent();
        const printContainer = $('<div style="position:relative;width:100%;max-width:1920px;">'); //Give max width to prevent smaller tekst in pdf

        printContainer
            .height(manualContainer.height())
            .append(manualContainer)
            .prependTo(body);

        const content = body
            .children()
            .not(printContainer)
            .not('script')
            .detach();

        /**
         * Needed for those who use Bootstrap 3.x, because some of
         * its `@media print` styles ain't play nicely when printing.
         */
        const patchedStyle = $('<style media="print">')
            .text(`
              img { width:100%; height:auto; max-width: 1920px !important; }
              a[href]:after { content: ""; }
            `)
            .appendTo('head');

        window.focus();
        window.print();

        body.prepend(content);
        manualContainerParent.prepend(manualContainer);

        printContainer.remove();
        patchedStyle.remove();
        return false;
    }
}
