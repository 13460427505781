import {Injectable} from '@angular/core';
import * as XLSX from 'xlsx';

@Injectable({
    providedIn: 'root'
})
export class ExportService {
    constructor() {
    }

    public exportToExcel(tableData:string): void {
        let fileName: string = 'table_export_' + this.getExportFileNamePostfix() + '.xlsx';

        // Fix for crash on empty content (so when not even column headers are present)
        if (tableData === '') {
            tableData = '-';
        }

        let table = '<table><tr style="font-weight: bold"><td>' + tableData + '</td></tr></table>';
        let workbook: XLSX.WorkBook = XLSX.read(table, {
            type: 'string',
            cellStyles: true,
            cellHTML: true,
            raw: true,
        });

        let workSheet: XLSX.WorkSheet = workbook.Sheets[workbook.SheetNames[0]];

        // Set the width of filled columns only
        let range = XLSX.utils.decode_range(workSheet['!ref']);

        // Get number of rows and columns
        let ncols = range.e.c - range.s.c + 1;

        // Create the settings array
        let wscols: { wch: number }[] = [];
        for (let i = 0; i < ncols; i++) {
            wscols.push({wch: 20});
        }

        // Add column settings to the sheet
        workSheet['!cols'] = wscols;

        // Download the file
        XLSX.writeFile(workbook, fileName);
    }

    public getExportFileNamePostfix(): string {
        let dt = new Date();
        let day = dt.getDate();
        let month = dt.getMonth() + 1;
        let year = dt.getFullYear();
        let hour = dt.getHours();
        let mins = dt.getMinutes();

        return (day < 10 ? '0' : '') + day + '-'
            + (month < 10 ? '0' : '') + month + '-'
            + year + '_'
            + (hour < 10 ? '0' : '') + hour + '-'
            + (mins < 10 ? '0' : '') + mins;
    }
}
