<button type="button"
        #menuButton
        class="{{disabled? 'status-button-disabled':'status-button'}}"
        (click)="toggleStatusDropdown($event)" *ngIf="items?.length > 1"
        (keyup)="onKeyUpMenuButton($event)"
        (keydown)="onkeyDownMenu($event)">
    <div class="sb-label">
        <span>{{getLabel()}}</span>
        <i>arrow_drop_down</i>
    </div>
    <div class="sb-dropdown-wrapper {{showStatusDropdown?'d-block':'d-none'}}">
        <div #menuItemsContainer class="sb-dropdown">
            <ng-container *ngFor="let item of items; let index = index">
                <div menuItem class="md-focusable" [ngClass]="getItemClass(item)"
                     (click)="onItemClick(item)">
                    <span class="text-truncate" title="{{item.label}}">{{item.label}}</span>
                    <i *ngIf="item.isFinished">check</i>
                </div>
            </ng-container>
            <ng-container *ngFor="let action of actions; let index = index">
                <p class="sb-dropdown-divider" *ngIf="!(action.show === false)"></p>
                <div *ngIf="!(action.show === false)"
                     menuItem
                     class="sb-dropdown-action md-focusable"
                     (click)="onActionClick(action)">
                    <span>{{action.label}}</span>
                    <i>{{action.icon}}</i>
                </div>
            </ng-container>
        </div>
    </div>
</button>
