<div class="my-2 {{config.attr && config.attr.selectAll?'d-block':'d-none'}}">
    <lumi-button (onClick)="handleClickSelectAll()"
                 [rank]="'secondary'"
                 [title]="'checkbox.selectAll' | translate"
                 [label]="'checkbox.selectAll' | translate">
    </lumi-button>
</div>

<ng-container #currentFormField *ngFor="let option of config.options; let _index=index" [formGroup]="group">

    <div (mousedown)="handleMouseDownCheckbox(option, _index)"  (mouseoverOutsideAngular)="handleMouseOver(option, _index)"  class="w-100 d-flex no-user-select">

        <ul class="m-0">
            <li class="w-100 d-flex" formGroupName="{{ config.name }}" (globalMousedownOutsideAngular)="handleMouseDown()" (globalMouseupOutsideAngular)="handleMouseUp()">
                <lumi-checkbox formControlName="{{option.id}}"
                               #checkbox
                               (click)="handleClickCheckbox($event)"
                               [checkedString]="option.id + ''"
                               (change)="onChange($event, _index)"
                               [disableControl]="optionDisabled(option) !== null"
                               [isDisabled]="optionDisabled(option) !== null">
                </lumi-checkbox>
            </li>
        </ul>

        <!-- NOTE: alleen dit component lijnt uit naar boven. Niet gebruiken in andere componenten -->
        <form-component-label *ngIf="(config.label && config.label != '') || (option.name && option.name != '')"
                              [style.align-self]="'center'"
                              [batchUpdateMode]="batchUpdateMode"
                              [config]="config.options.length > 1 && option.name != ''?option:config"
                              [included]="included"
                              (onClickInclude)="handleClickInclude($event)">
        </form-component-label>

    </div>
</ng-container>
