import {Injectable} from "@angular/core";
import {RequestFailure} from "../../services/http/request-failure";
import {HTTPService} from "../../services/http/http.service";
import {GlobalAlertService} from "../../../wrapper/global-alert/global-alert.service";
import {HTTPError} from "../../services/http/http-error";
import {TableOptions} from "./tableColumnSelector/table-options";
import {GlobalModel} from '../../services/state/global.model';

@Injectable()
export class TableOptionsService
{
    constructor(protected httpService:HTTPService, protected globalAlertService:GlobalAlertService, protected model:GlobalModel) {}

    public resetTableOptions(tableOptions:TableOptions):void
    {
        tableOptions.showArchived = false;

        // Apply the new filter
        let rankCounter = 1;
        tableOptions.tableFields.forEach((field: any) => {
            //Clear the filter ect
            field.filter = null;
            field.visible = field.default;
            field.inMouseOver = field.inMouseOver;
            field.sorting = false;
            field.sortDirection = -1;
            field.columnRank = field.visible ? rankCounter : null;
            
            if (field.visible) {
                rankCounter++;
            }
        });
    }

    // Merge the filters of tableFields with existing tableOptions. (keep the same object reference)
    public mergeTableOptions(tableOptions:TableOptions, newTableOptions:TableOptions):void
    {
        //Apply show archived option
        tableOptions.showArchived = newTableOptions.showArchived;

        // Apply the new filter
        tableOptions.tableFields.forEach((field: any) => {

            //Clear the filter
            field.filter = null;

            let newTableField:any;

            //Find a match, and set the filter
            for (let i = 0; i < newTableOptions.tableFields.length; i++) {
                newTableField = newTableOptions.tableFields[i];
                if (field.code === newTableField.code) {
                    field.filter = newTableField.filter;
                    field.visible = newTableField.visible;
                    field.inMouseOver = newTableField.inMouseOver;
                    field.sorting = newTableField.sorting;
                    field.sortDirection = newTableField.sortDirection;
                    field.columnRank = newTableField.columnRank;
                    break;
                }
            }
        });
    }

    public getTableOptionSet(modulePath:string, tableOptionSetId:number, successCallBack?:(json:any) => any, failCallBack?:(failure:RequestFailure) => any, errorCallBack?:(error:HTTPError) => any)
    {
        this.httpService.doGetRequest('selection/' + modulePath + '/get/' + tableOptionSetId,
            (json:any)=>{
                //Let component know request is send
                successCallBack(json);
            }, (failure:RequestFailure)=>{
                //The username is not valid or other failure
                failCallBack(failure);
            }, (error:HTTPError) => {
                errorCallBack(error);
            },
            false, false);
    }

    public createTableOptionSet(modulePath:string, name:string, isPreferred: boolean, data:any, successCallBack?:(json:any) => any, failCallBack?:(failure:RequestFailure) => any, errorCallBack?:(error:HTTPError) => any)
    {
        let postValues:any = {name: name, data:data, isPreferred: isPreferred};
        this.httpService.doFormPostRequest('selection/' + modulePath + '/set', postValues,
            (json:any)=>{
                //Let component know request is send
                successCallBack(json);
            }, (failure:RequestFailure)=>{
                //The username is not valid or other failure
                failCallBack(failure);
            }, (error:HTTPError) => {
                errorCallBack(error);
            },
            false);
    }

    public listTableOptionSets(modulePath:string, successCallBack?:(json:any) => any, failCallBack?:(failure:RequestFailure) => any, errorCallBack?:(error:HTTPError) => any)
    {
        this.httpService.doGetRequest('selection/' + modulePath + '/list',
            (json:any)=>{
                //Let component know request is send
                successCallBack(json);
            }, (failure:RequestFailure)=>{
                //The username is not valid or other failure
                failCallBack(failure);
            }, (error:HTTPError) => {
                errorCallBack(error);
            },
            false, false);
    }

    public deleteTableOptionSet(modulePath:string, tableOptionSetId:number, successCallBack?:(json:any) => any, failCallBack?:(failure:RequestFailure) => any, errorCallBack?:(error:HTTPError) => any)
    {
        this.httpService.doGetRequest('selection/' + modulePath + '/delete/' + tableOptionSetId,
            (json:any)=>{
                //Let component know request is send
                successCallBack(json);
            }, (failure:RequestFailure)=>{
                //The username is not valid or other failure
                failCallBack(failure);
            }, (error:HTTPError) => {
                errorCallBack(error);
            },
            false, false);
    }

    public updateTableOptionSet(modulePath:string, tableOptionSetId:number, name:string, isPreferred: boolean, data:any, successCallBack?:(json:any) => any, failCallBack?:(failure:RequestFailure) => any, errorCallBack?:(error:HTTPError) => any)
    {
        let postValues:any = {name: name, data: data, isPreferred: isPreferred};

        this.httpService.doFormPostRequest('selection/' + modulePath + '/update/' + tableOptionSetId, postValues,
            (json:any)=>{
                //Let component know request is send
                successCallBack(json);
            }, (failure:RequestFailure)=>{
                //The username is not valid or other failure
                failCallBack(failure);
            }, (error:HTTPError) => {
                errorCallBack(error);
            },
            false);
    }
}
