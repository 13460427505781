/**
 * Created by Christiaan on 07/04/2017.
 */

import {Directive, NgZone, Output, EventEmitter} from '@angular/core';

@Directive({
    selector: '[globalClickOutsideAngular]'
})

export class GlobalClickOutsideAngularDirective
{
    private static readonly EVENT_CLICK:string = "click";

    @Output('globalClickOutsideAngular') clickHandler: EventEmitter<any> = new EventEmitter();

    constructor(private ngZone:NgZone)
    {
        this.ngZone.runOutsideAngular(() => {
            document.addEventListener(GlobalClickOutsideAngularDirective.EVENT_CLICK, (event:any)=> {
                this.clickHandler.emit(event);
            })
        });
    }
}
