import {Injectable} from "@angular/core";
import {RequestFailure} from "../../../shared/services/http/request-failure";
import {HTTPService} from "../../../shared/services/http/http.service";
import {GlobalAlertService} from "../../../wrapper/global-alert/global-alert.service";
import {HTTPError} from "../../../shared/services/http/http-error";

@Injectable()
export class DimmingService
{
    constructor(protected httpService:HTTPService, protected globalAlertService:GlobalAlertService) {}

    public deleteDimmingException(url:string, successCallBack?:(json:any) => any, failCallBack?:(failure:RequestFailure) => any, errorCallBack?:(error:HTTPError) => any)
    {
        this.httpService.doGetRequest(url,
            (json:any)=>{
                //Let component know request is send
                successCallBack(json);
            }, (failure:RequestFailure)=>{
                //The username is not valid or other failure
                failCallBack(failure);
            }, (error:HTTPError) => {
                errorCallBack(error);
            },
            false, true);
    }

    public getDimmingScheme(dimmingSchemeId:number, successCallBack?:(json:any) => any, failCallBack?:(failure:RequestFailure) => any, errorCallBack?:(error:HTTPError) => any)
    {
        this.httpService.doGetRequest('control-form/dimgroup/scheme/get/' + dimmingSchemeId,
            (json:any)=>{
                //Let component know request is send
                successCallBack(json);
            }, (failure:RequestFailure)=>{
                //The username is not valid or other failure
                failCallBack(failure);
            }, (error:HTTPError) => {
                errorCallBack(error);
            },
            true, false);
    }

    public listDimmingSchemes(successCallBack?:(json:any) => any, failCallBack?:(failure:RequestFailure) => any, errorCallBack?:(error:HTTPError) => any)
    {
        this.httpService.doGetRequest('control-form/dimgroup/scheme/list',
            (json:any)=>{
                //Let component know request is send
                successCallBack(json);
            }, (failure:RequestFailure)=>{
                //The username is not valid or other failure
                failCallBack(failure);
            }, (error:HTTPError) => {
                errorCallBack(error);
            },
            true, false);
    }

    public deleteDimmingScheme(schemeId:number, successCallBack?:(json:any) => any, failCallBack?:(failure:RequestFailure) => any, errorCallBack?:(error:HTTPError) => any)
    {
        this.httpService.doGetRequest('control-form/dimgroup/scheme/delete/' + schemeId,
            (json:any)=>{
                //Let component know request is send
                successCallBack(json);
            }, (failure:RequestFailure)=>{
                //The username is not valid or other failure
                failCallBack(failure);
            }, (error:HTTPError) => {
                errorCallBack(error);
            },
            true, false);
    }

    public updateDimmingScheme(schemeId:number, moments:any[], successCallBack?:(json:any) => any, failCallBack?:(failure:RequestFailure) => any, errorCallBack?:(error:HTTPError) => any)
    {
        let postValues:any = {dimmingScheme: {dimmingMoments: moments}};

        this.httpService.doPostRequest('control-form/dimgroup/scheme/update/' + schemeId, postValues,
            (json:any)=>{
                //Let component know request is send
                successCallBack(json);
            }, (failure:RequestFailure)=>{
                //The username is not valid or other failure
                failCallBack(failure);
            }, (error:HTTPError) => {
                errorCallBack(error);
            },
            true);
    }

    public setDimGroupOverride(dimGroupId:number, lightLevel:number, successCallBack?:(formData:any) => any, failCallBack?:(failure:RequestFailure) => any, errorCallBack?:(error:HTTPError) => any):void
    {
        this.httpService.doGetRequest('control-form/dimgroup/override/set/' + dimGroupId + "/" + lightLevel,
            (json:any, url:string) => {
                successCallBack(json);
            }, (failure:RequestFailure) => {
                failCallBack(failure)
            }, (error: HTTPError) => {
                errorCallBack(error)
            },
            true
        );
    }

    public releaseDimGroupOverride(dimGroupId:number, successCallBack?:(formData:any) => any, failCallBack?:(failure:RequestFailure) => any, errorCallBack?:(error:HTTPError) => any):void
    {
        this.httpService.doGetRequest('control-form/dimgroup/override/release/' + dimGroupId,
            (json:any, url:string) => {
                successCallBack(json);
            }, (failure:RequestFailure) => {
                failCallBack(failure)
            }, (error: HTTPError) => {
                errorCallBack(error)
            },
            true
        );
    }
}
