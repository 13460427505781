<ng-container *ngFor="let activity of activities">
    <complex-list-item class="{{activity.isNew?'animated fadeIn':''}}"
                       [style.color]="activity.isDeleted?AppSettings.getColor('$selection-gray'):''"
                       [item]="activity.listItem"
                       [readonly]="disabled()"
                       (onMenuItemClick)="onWorkActivityAction($event, activity)">
        <key-value [key]="'activity.created' | translate"
                   [value]="activity.created">
        </key-value>
        <ng-container *ngFor="let keyValue of activity.keyValues">
            <key-value [key]="keyValue.key"
                       [value]="keyValue.value || '-'">
            </key-value>
        </ng-container>
        
        <div class="form-attachment-loader {{activity.isLoading?'':'d-none'}}">
            <div class="progress">
                <div class="indeterminate"></div>
            </div>
        </div>
    </complex-list-item>
</ng-container>
<div class="form-log-container">
    <div *ngIf="!activities || activities.length <= 0">
        {{'activity.noactivities' | translate}}
    </div>
</div>
