import { Pipe, PipeTransform } from '@angular/core';
import {TranslateService} from "../services/translate/translate.service";

@Pipe({
    name: 'translate',
})

export class TranslatePipe implements PipeTransform {

    constructor(private translateService:TranslateService) {}

    transform(key: string, params?: any[]): string
    {
        if (!key){
            return;
        }

        return this.translateService.translate(key, params);
    }
}
