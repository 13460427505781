/**
 * Created by Christiaan on 01/03/2017.
 */
import {
    ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output
} from '@angular/core';

import {GlobalAlertService} from "../global-alert.service";
import {GlobalPopup} from "../global-popup";
import {GlobalAlertComponent} from "../global-alert.component";
import {AbstractBasePopupComponent} from "./abstract-base-popup.component";
import {TranslateService} from "../../../shared/services/translate/translate.service";
import {TooltipService} from "../../../shared/services/tooltip/tooltip.service";
import {LoggerService} from "../../../shared/services/logger/logger.service";

@Component ({
    selector: 'global-popup-basic-component',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <default-popup-wrapper-component [componentRef]="this" [globalPopup]="globalPopup" [description]="globalPopup.label" [title]="globalPopup.title" (onClose)="closePopup($event)">
        </default-popup-wrapper-component>
    `
})

export class GlobalPopupBasicComponent extends AbstractBasePopupComponent
{
    public constructor(protected cd:ChangeDetectorRef, protected globalAlertService:GlobalAlertService, protected elementRef:ElementRef, protected ts:TranslateService, protected tooltipService:TooltipService, protected logger:LoggerService) {
        super(cd, globalAlertService, elementRef, ts, tooltipService, logger);
    }

    handlePopupAction(event: MouseEvent, alert: GlobalPopup, button: any): void {
        this.onPopupAction.emit({event:event, alert:alert, button:button});
    }
    
    public closePopup(event:any):void
    {
        this.doDefaultCloseHandling(event, false);
    }
}
