/**
 * Created by Christiaan on 01/03/2017.
 */
import {AfterViewInit, ChangeDetectorRef, Component, Input, OnChanges} from '@angular/core';
import {GlobalModel} from '../../../../../shared/services/state/global.model';
import MapIconGenerator from '../../../../../shared/utils/map-icon-generator';
import {IconListGetResult, IconType} from './icon-legend.interface';
import {HttpService2} from '../../../../../shared/services/http-service-2.0/http2.0.service';
import {finalize, map, take} from 'rxjs/operators';
import {BasicHttpGetResult} from '../../../../../shared/services/http-service-2.0/http.interface';
import {LoggerService} from "../../../../../shared/services/logger/logger.service";

@Component({
    selector: 'icon-legend-component',
    templateUrl: 'icon-legend.component.html'
})
export class IconLegendComponent implements OnChanges {
    @Input() iconSet: 'mechanic' | 'smartdevices' | 'default' = 'default';
    public icons: IconType[] = [];
    public iconsLoading: boolean = false;
    
    public constructor(private model: GlobalModel, private cd: ChangeDetectorRef, private httpService: HttpService2, protected logger:LoggerService) {
        
        this.logger.log('[GlobalPopupIconLegendComponent] ', this.model.currentAreaal);
    }
    
    get LOCATION_ICON_ACTIVE(): string {
        return MapIconGenerator.LOCATION_ICON_ACTIVE;
    }
    
    get LOCATION_ICON_INACTIVE(): string {
        return MapIconGenerator.LOCATION_ICON_INACTIVE;
    }
    
    public static getImage(icon: string, includeSize: boolean = true, rws: boolean = false): string {
        let result: string;
        
        if (rws) {
            result = MapIconGenerator.ICON_BASE_PATH + MapIconGenerator.ICON_PATH_RWS + icon;
        } else {
            result = MapIconGenerator.ICON_BASE_PATH + (includeSize ? MapIconGenerator.ICON_PATH_SIZE_16 : '') + icon;
        }
        result += '.png';
        
        return result;
    }
    
    ngOnChanges() {
        if (this.iconSet === 'mechanic') {
            this.iconsLoading = true;
            this.httpService.doGetRequest('mechanic/get-legend').pipe(
                take(1),
                map((result: BasicHttpGetResult) => result as IconType[]),
                finalize(() => {
                    this.iconsLoading = false;
                    this.cd.detectChanges();
                })
            ).subscribe((result: IconType[]) => {
                this.icons = result;
            });
        }
    }
    
    public isStedin(): boolean {
        if (this.model.currentAreaal.value.code.toLowerCase().indexOf('stedin') > -1) {
            return true;
        }
    }
    
    public isRWS(): boolean {
        let areaal: string = this.model.currentAreaal.value.code.toLowerCase();
        if (areaal.indexOf('rws_midden') > -1) {
            return true;
        }
    }
    
    public getImage(icon: string, rws: boolean = false, includeSize: boolean = true): string {
        return IconLegendComponent.getImage(icon, includeSize, rws);
    }
}
