export enum loggerLevel {
    TRACE = 'TRACE',
    DEBUG = 'DEBUG',
    INFO = 'INFO',
    LOG = 'LOG',
    WARN = 'WARN',
    ERROR = 'ERROR'
}

export interface ILogMessage {
    componentName: string,
    dateTime: string,
    message: (any|any[]),
    routeUrl: string,
    lineNumber: string,
    character: string,
    stackTrace: string[],
    additionalInfo:any
}
