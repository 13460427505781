import * as moment from 'moment/moment';

export interface Filter {
    tableColumnId?: string|number;
    tableColumnCode?: string|number;
    filterCommand: FilterCommand;
    filterValues: string[];
}

export interface DateInfo {
    today: moment.Moment|string;
    tomorrow: moment.Moment|string;
    yesterday: moment.Moment|string;
}

export enum FilterCommand {
    'EQUALS' = 'EQUALS',
    'NOT_EQUALS' = 'NOT_EQUALS',
    'NOT_EMPTY' = 'NOT_EMPTY',
    'PAST_X_DAYS' = 'PAST_X_DAYS',
    'PAST_DAYS' = 'PAST_DAYS',
    'TODAY_OR_PAST_X_DAYS' = 'TODAY_OR_PAST_X_DAYS',
    'TODAY_OR_PAST_DAYS' = 'TODAY_OR_PAST_DAYS',
    'TODAY_OR_FUTURE_X_DAYS' = 'TODAY_OR_FUTURE_X_DAYS',
    'TODAY_OR_FUTURE_DAYS' = 'TODAY_OR_FUTURE_DAYS',
    'FUTURE_X_DAYS' = 'FUTURE_X_DAYS',
    'FUTURE_DAYS' = 'FUTURE_DAYS',
    'TODAY' = 'TODAY',
    'EMPTY' = 'EMPTY',
    'CONTAINS' = 'CONTAINS',
    'NOT_CONTAINS' = 'NOT_CONTAINS',
    'IN_SET' = 'IN_SET',
    'NOT_IN_SET' = 'NOT_IN_SET',
    'BETWEEN' = 'BETWEEN',
    'NOT_BETWEEN' = 'NOT_BETWEEN',
    'SMALLER' = 'SMALLER',
    'BIGGER' = 'BIGGER',
    'SMALLER_OR_EQUAL' = 'SMALLER_OR_EQUAL',
    'BIGGER_OR_EQUAL' = 'BIGGER_OR_EQUAL',
}

export enum ActivityFilterString {
    DONE = 'done;groen;af;gedaan;gereed;klaar;voltooid',
    ATTENTION = 'attention;flagged;oranje;aandacht;vlag;attentie;opletten',
    NOT_STARTED = 'todo;grijs;te doen;'
}
