/**
 * Created by Christiaan on 16/03/2017.
 */

import {Injectable} from '@angular/core';
import {HTTPService} from '../../shared/services/http/http.service';
import {GlobalModel} from '../../shared/services/state/global.model';
import {Router} from '@angular/router';
import {AppSettings} from '../../app.settings';
import {RequestFailure} from '../../shared/services/http/request-failure';
import {HTTPError} from '../../shared/services/http/http-error';
import {GlobalAlertService} from '../global-alert/global-alert.service';
import {AreaalService} from '../../shared/services/areaal/areaal.service';
import {User} from './user';
import {TranslateService} from '../../shared/services/translate/translate.service';
import {Subscription} from 'rxjs';
import Utils from '../../shared/utils/utils';
import {GlobalEvent} from '../../shared/interfaces/global-event';
import {TimerService} from '../../shared/services/timer/timer.service';
import {StorageService} from '../../shared/services/storage/storage.service';
import {LuminizerRoutes} from '../../shared/interfaces/routes';
import {LoggerService} from "../../shared/services/logger/logger.service";

@Injectable()
export class LoginService {
    public static readonly LOGOUT_PATH: string = 'application-state/logout';
    public static readonly PUBLIC_PORTAL_USER: string = 'resident';
    public static readonly PUBLIC_PORTAL_PASSWORD: string = 'public';
    // public static readonly TWO_FA_PATH = '2FA_PATH';

    private static UNKNOWN_ERROR_MESSAGE: string = '';// = "Het inloggen is niet gelukt, probeer het nog eens";
    private returnUrl: string = '';

    private subChangeLanguage: Subscription;
    private subOnGlobalEvent: Subscription;
    private subIdleLogout: Subscription;

    constructor(private model: GlobalModel, private router: Router, private httpService: HTTPService, private globalAlertService: GlobalAlertService, private areaalService: AreaalService, private ts: TranslateService, private timerService: TimerService, private storage: StorageService, protected logger:LoggerService) {

        this.subChangeLanguage = this.model.changeLanguage.subscribe(() => {
            LoginService.UNKNOWN_ERROR_MESSAGE = this.ts.translate('Het inloggen is niet gelukt, probeer het nog eens');
        });

        this.subOnGlobalEvent = this.model.onGlobalEvent.subscribe((event: GlobalEvent) => {
            if (event.type == GlobalEvent.EVENT_OUT_OF_SESSION) {
                this.logout(true, () => {
                }, () => {
                });
            }
        });

        this.subIdleLogout = this.model.idleLogout.subscribe(() => {
            if (this.model.idleLogout.value === true) {
                this.logout(false, () => {
                }, () => {
                });
            }
        });
    }

    public getIsNewPasswordParam(): string {
        return this.httpService.getQueryParam('isNewPassword');
    }

    public login(username: string, password: string, successCallBack?: (returnURL: string) => any, failCallBack?: (failure: RequestFailure) => any, errorCallBack?: (error: RequestFailure) => any) {
        //Save the initial url to redirect the user if necessary
        this.returnUrl = this.httpService.getQueryParam('returnUrl') || LuminizerRoutes.INITIAL_PAGE;

        this.httpService.doGetRequest(AppSettings.GET_LOGIN_TOKEN_PATH,
            () => {

                //The app will never end up here. The token request will always end up at error-handling

                //For safety: Send a empty failure to let the login-check-call know the request finished > update view
                let failure: RequestFailure = new RequestFailure;
                failure.message = LoginService.UNKNOWN_ERROR_MESSAGE;
                failCallBack(failure);


            }, (failure: RequestFailure) => {

                //The app will never end up here, the user can't make a input mistake with the first login call, since there is no input
                this.logger.log('[LoginService] ' + 'failure: ', failure);

                //For safety: Send a empty failure to let the login-check-call know the request finished > update view
                failCallBack(failure);

            }, (error: HTTPError) => {

                //this.logger.log('error triggered at login in service:', error);

                //The get-token always triggers an error

                //The token is supplied via the error callback
                let csrfToken: string = (error && error.error && error.error.data && error.error.data.csrfToken) ? error.error.data.csrfToken : '';


                //Check for a token, if found, start the second login call, with credentials
                if (csrfToken && csrfToken != '') {

                    //Build the post values, including the token
                    let postValues: any = {
                        username: username,
                        password: password,
                        csrfToken: csrfToken,
                        language: this.ts.getLanguage(),
                    };

                    //Perform the second call
                    this.httpService.doPostRequest(
                        AppSettings.LOGIN_PATH,
                        postValues,
                        (json: any, url: string) => {
                            this.logger.log('[LoginService] ', json);
                            if (json.user.twoFactorAuth && json.user.twoFactorInProgress) {
                                this.router.navigate([LuminizerRoutes.TWO_FACTOR_AUTH_PAGE]);
                            }
                            else {
                                this.handleUserDataReceived(json, this.returnUrl, url);
                            }

                            //Let component know login is complete
                            successCallBack(this.returnUrl);
                        },
                        (failure: RequestFailure) => {

                            //Will not be triggered

                            //Let component display failure
                            failCallBack(failure);
                        },
                        (error: HTTPError) => {

                            if (error && error.error && error.error.data && error.error.data.failure) {

                                //Failure handling for login-call
                                let classObject: any = error.error.data.failure;
                                let failure: RequestFailure = classObject as RequestFailure;

                                this.logger.log('[LoginService] ' + 'error: ', error);
                                this.logger.log('[LoginService] ' + 'failure: ' + failure + ' message: ' + failure.message);

                                errorCallBack(failure);
                            }
                            else {
                                //When this fires, something is truly broken in the login process on the server
                                this.logger.log('[LoginService] ' + 'error: ', error);
                                //alert("Login Error: No valid error response. Login process is broken. \n\nCode: " + error.status + " Status: " + error.statusText + "\nURL: " + error.url);
                                this.globalAlertService.addAlert('Login Error', '- No valid error response', 'Login process is broken.<br>Code: ' + error.status + ' Status: ' + error.statusText + '<br>URL: ' + error.url);
                            }
                        }, false,
                    );
                }
                else {
                    //App will end up here if the user was not logged in, and did not receive a new token with the get-login call
                    //Or any other error occurred (apart from the 403 Forbidden)
                    this.logger.log('[LoginService] ' + 'ERROR: NO TOKEN WITH FIRST/GET LOGIN CALL');

                    this.globalAlertService.addAlert('Login Error', 'No valid token', 'URL: ' + error.url + '<br>Could not get a valid token. The login process is broken.');

                    //Create a failure to display by the view
                    let failure: RequestFailure = new RequestFailure;
                    failure.message = LoginService.UNKNOWN_ERROR_MESSAGE;
                }
            }, false);
    }

    //Check if loggedin on the server
    public checkLogin(successCallBack?: () => any, failCallBack?: (failure: RequestFailure) => any) {

        //Save the initial url to redirect the user if necessary
        this.returnUrl = this.httpService.getQueryParam('returnUrl') || LuminizerRoutes.INITIAL_PAGE;

        this.httpService.doGetRequest(
            AppSettings.CHECK_LOGIN_PATH,
            (json: any, url: string) => {

                //The app will only end up here if the user was already logged in on the server
                this.logger.log('[LoginService] ' + 'The user was logged in on the server'); // + JSON.stringify(json));
                let twoFactorAuth: boolean = json.user.twoFactorAuth;
                let twoFactorInProgress: boolean = json.user.twoFactorInProgress;
                if (twoFactorAuth && twoFactorInProgress) {
                    this.logger.log('[LoginService] ' + 'The user has an active 2 FA session'); // + JSON.stringify(json));
                    this.router.navigate([LuminizerRoutes.TWO_FACTOR_AUTH_PAGE]);
                }
                else {
                    this.logger.log('[LoginService] ' + 'The user has not 2FA enabled');
                    this.handleUserDataReceived(json, this.returnUrl, url);
                }

                //Let component know login is complete
                successCallBack();

            }, (failure: RequestFailure) => {

                //The app will never end up here, the user can't make a input mistake with the check-login call, since there is no input

                //For safety: Send a empty failure to let the login-check-call know the request finished > update view
                failCallBack(failure);

            }, (error: HTTPError) => {

                //You performed a login check but the user was not logged in
                //Send a empty failure to let the login-check-call know the request finished > update view
                let failure: RequestFailure = new RequestFailure;
                if (error.error) {
                    failure.message = error.error.message;
                }
                else {
                    this.globalAlertService.addAlert('Login Error', 'No valid response', 'URL: ' + error.url + '<br>Could not determine if a user is logged in. The login process is broken.');
                }
                failCallBack(failure);

            }, false);
    }

    private handleUserDataReceived(json: any, returnUrl: string, calledUrl: string) {
        // Auto-logout public portal user when not in public portal mode
        if (!this.model.publicPortalMode.value && json && json.user && json.user.username == LoginService.PUBLIC_PORTAL_USER) {
            this.logger.log('[LoginService] ' + 'Public portal user detected, log out now');
            this.logoutLocal();
            return;
        }

        //All information should be present at this time to leave the login screen
        this.areaalService.processAreaalData(json, calledUrl);

        this.timerService.idleTimerSeconds = json.user.autoLogout * 60;
        if (this.timerService.idleTimerSeconds > 0) {
            this.timerService.startIdleTimer();
        }
        else {
            this.logger.log('[IdleTimer] ' + 'Timer set to 0, not started.');
        }

        if (this.model.publicPortalMode.value) {
            this.model.publicPortalWelcomeText = json.user.publicPortalWelcomeText;
            // Dont navigate yet when using the public portal. Wait for areaal-switch to complete
            return;
        }
        else {
            // In all other cases: navigate to previous url, if one is given
            this.handleReturnURL(returnUrl);
        }
    }

    public handleReturnURL(returnUrl: string): void {
        //TODO: deze shit opruimen.
        // LOL

        // Check for url after idle logout
        const idleReturnUrl = this.storage.keyExists(StorageService.KEY_IDLE_RETURN_URL);
        if (idleReturnUrl) {
            this.storage.getStringValue(StorageService.KEY_IDLE_RETURN_URL, (value) => {
                this.returnUrl = value;
            });
            this.storage.removeItem(StorageService.KEY_IDLE_RETURN_URL);
            this.router.navigate([this.returnUrl]);
        }
        else {
            // this.globalAlertService.addPopupWarning(() => {}, () => {});
            // Show IE warning, only for IE users
            if (Utils.getInternetExplorerVersion() > 0 && !this.model.publicPortalMode.value) {
                this.globalAlertService.addPopupWarning(() => {
                }, () => {
                });
            }

            //Check for queryParams in returnUrl (e.g. present in a 'flash-path' or 'autoRefresh')
            let separatorIndex: number = returnUrl.indexOf(';');
            if (separatorIndex != -1) {

                //TODO: Werkt nu alleen voor 1 parameter, niet voor meer. En sowieso kan dit vast mooier/automagisch. Hij convert matrix notation (in string, niet in url. Komt door returnUrl) naar object
                //params found
                let returnPath: string = returnUrl.substring(1, separatorIndex);
                let returnParams: string = returnUrl.substring(separatorIndex + 1).replace('=', '":"');
                let paramsJson: any = JSON.parse('{"' + returnParams + '"}');

                this.router.navigate([returnPath, paramsJson]);
            }
            else if (returnUrl.indexOf('?') != -1) {
                // Queryparams found
                separatorIndex = returnUrl.indexOf('?');

                // Strip the params
                let returnPath: string = returnUrl.substring(1, separatorIndex);
                let queryPart: string = returnUrl.substring(separatorIndex);
                let queryParams: any = Utils.queryStringToJSON(queryPart);

                //Navigate to return url + params
                this.router.navigate([returnPath], {queryParams: queryParams});
            }
            else {

                //TODO: als dit er meer worden, mooier mechanisme bedenken
                //Add pages that should not be reachable through return url after login
                if (returnUrl.indexOf(LuminizerRoutes.LOAD_AREAAL_PAGE) != -1) {
                    this.router.navigate([LuminizerRoutes.INITIAL_PAGE]);
                }
                else {
                    this.router.navigate([returnUrl]);
                }
            }
        }

    }

    public logout(outOfSession: boolean, successCallBack?: () => any, failCallBack?: (failure: RequestFailure) => any) {
        let path: string = LoginService.LOGOUT_PATH;

        this.httpService.doGetRequest(path,

            () => {

                this.logger.log('[LoginService] ' + 'Logout success response');

                //this.router.navigate([LuminizerRoutes.LOGIN_PAGE]);

                //Refresh the page. This fixes some issues with the Flash Player (opposed to just navigating to login)
                window.location.href = window.location.origin + '/' + LuminizerRoutes.LOGIN_PAGE + (outOfSession ? '?sessionExpired=true' : ''); //https://acceptatie.luminizer.nl/login"

                //Let component know login is complete
                successCallBack();
            }, (failure: RequestFailure) => {

                //The app won't get here, there is no way the user can cause a failure since logging-out is only a button-press
                failCallBack(failure);
            },
            () => {
            },
            false);
    }

    //If a view uses this function, add a subscribtion to user
    public isLoggedInLocally(user: User): boolean {
        //return this.model.user.value != null;
        return user != null;
    }

    //Logout locally
    public logoutLocal() {
        this.model.user.next(null);
    }
}
