/**
 * Created by Christiaan on 01/03/2017.
 */
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component, ElementRef,
    EventEmitter,
    Input, OnDestroy,
    Output,
    QueryList,
    ViewChild,
    ViewChildren
} from '@angular/core';

import {GlobalAlertService} from "../global-alert.service";
import {ButtonCode, GlobalPopup} from '../global-popup';
import {TranslateService} from "../../../shared/services/translate/translate.service";
import {DimmingExceptionsComponent} from "../../../shared/components/form/components/dimming/dimming-exceptions.component";
import {TinyGraphComponent} from "../../../shared/components/form/components/form-graph/tiny-graph.component";
import {DimmingService} from "../../../modules/control/dimming/dimming.service";
import Utils from "../../../shared/utils/utils";
import {Subscription} from "rxjs";
import {GlobalModel} from "../../../shared/services/state/global.model";
import {RequestFailure} from "../../../shared/services/http/request-failure";
import {AuthorizationService} from "../../../shared/services/authorization/authorization.service";
import {AbstractBasePopupComponent} from './abstract-base-popup.component';
import {TooltipService} from "../../../shared/services/tooltip/tooltip.service";
import {LoggerService} from "../../../shared/services/logger/logger.service";

@Component ({
    selector: 'global-popup-edit-scheme-component',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <default-popup-wrapper-component [globalPopup]="globalPopup" [title]="'dimmingschemes.edit' | translate" (onClose)="closePopup($event)">

            <div class="d-flex flex-column w-100 p-0">

                <div class="dynamic-form" style="">
                    <div class="d-flex flex-row mt-1 align-items-center">
                        <select class="form-control p-0 m-0" style="background: white" (change)="handleSelectScheme($event)">
                            <option *ngFor="let scheme of dimmingSchemes" [selected]="(scheme.id == selectedDimmingSchemeId)"  [value]="scheme.id">{{scheme.name}}</option>
                        </select>
                        <div class="ml-2 card cursor-pointer" (click)="handleAddScheme($event)" [title]="'Maak een nieuw item aan' | translate">
                            <i class="p-2 material-icons " style="font-size: 20px">add</i>
                        </div>
                        <div *ngIf="auth.allowEditDimmingScheme()" class="ml-2 card cursor-pointer" (click)="handleEditcheme($event)" [title]="'dropdown.edit' | translate">
                            <i class="p-2 material-icons " style="font-size: 20px">edit</i>
                        </div>
                        <div class="ml-2 card cursor-pointer" (click)="handleDeleteScheme($event)" title="{{'Item verwijderen' | translate}}">
                            <i class="p-2 material-icons " style="font-size: 20px">delete</i>
                        </div>
                    </div>
                </div>

                <div class="py-2">{{(dimmingGroups && dimmingGroups.length > 0) || (exceptions && exceptions.length > 0)?('dimmingSchemes.dimminggroups' | translate):('dimmingSchemes.nodimminggroups' | translate)}}{{getDimmingGroupsString()}}</div>

                <div class="d-flex flex-row flex-wrap-reverse w-100">
                    <div class="col-12 col-lg-6 p-0" style="overflow: hidden">
                        <tiny-graph #graph (onChangeChannel)="handleChangeChannel($event)" (onAddChannel)="handleAddChannel($event)" (onRemoveChannel)="handleRemoveChannel($event)" [hideChannelSelector]="loadingGraph" [showChannelEditButtons]="true" [alwaysShowChannelSelector]="true" style="margin-top:-1rem; overflow: hidden;"></tiny-graph>
                    </div>
                    <div class="col-12 col-lg-6 p-0 d-flex flex-column dimming-scheme-moments-list">
                        <div class="dimming-scheme-moments-list-container">
                            <ng-container *ngFor="let moment of dimmingMoments">
                                <div class="d-flex mb-1 mx-2 p-1 flex-row dimming-scheme-moments-list-block align-items-center" (mouseover)="handleMouseOverMoment($event, moment)" (mouseleave)="handleMouseOutMoment($event, moment)">
                                    <div *ngIf="!mobileMode" class="d-flex flex-row moment-time-block">
                                        <div #hourInputs class="moment-time-part unselectable-content px-1" (blur)="handleBlurTime($event)" (focus)="handleFocus($event, moment)" [tabindex]="0" (keydown)="handleKeyDownHours($event, moment)" input type="text" pattern="[0-9]*">{{formatNumber(moment.hour)}}</div>
                                        <div>:</div>
                                        <div class="moment-time-part unselectable-content px-1" (blur)="handleBlurTime($event)" (focus)="handleFocus($event, moment)" [tabindex]="0" (keydown)="handleKeyDownMinutes($event, moment)">{{formatNumber(moment.minute)}}</div>
                                    </div>
                                    <div *ngIf="mobileMode" class="d-flex flex-row mobile-moment-time-block">
                                        <select #mobileHourInput class="mobile-moment-time-part unselectable-content px-1" (change)="handleChangeHoursMobile($event, moment)">
                                            <option *ngFor="let i of this.hours" [value]="i.value" [selected]="(i.value == moment.hour)">{{i.label}}</option>
                                        </select>
                                        <div>:</div>
                                        <select #mobileMinuteInput class="mobile-moment-time-part unselectable-content px-1" [value]="moment.minute" (change)="handleChangeMinutesMobile($event, moment)">
                                            <option *ngFor="let i of this.minutes" [value]="i.value" [selected]="i.value == moment.minute">{{i.label}}</option>
                                        </select>
                                    </div>

                                    <!--<div class="w-100 px-2 align-self-end">
                                        <svg  fill="#e9e9e9" viewBox="0 0 200 35">
                                            &lt;!&ndash;<path d="M{{200 /2}} {{50 / 2}} L{{200 /2}} 50 L0 50 Z" />&ndash;&gt;
                                            <svg:path [attr.d]="'M' + (200 /(100 / moment.percentage)) + ' ' + (35 - (35 /(100 / moment.percentage))) + ' L' + (200 /(100 / moment.percentage)) + ' 35 L0 35 Z'" />
                                        </svg>
                                    </div>-->

                                    <div *ngIf="!mobileMode" class="moment-time-part unselectable-content px-1 mr-2 ml-auto" style="width: 70px; text-align: end;" [tabindex]="0" (focus)="handleFocus($event, moment)" (keydown)="handleKeyDownPercentage($event, moment)">{{formatPercentage(moment.percentage)}}</div>
                                    <select *ngIf="mobileMode" #mobilePercentageInput class="mobile-moment-time-part percentage unselectable-content px-1" [value]="moment.percentage" (change)="handleChangePercentageMobile($event, moment)">
                                        <option *ngFor="let i of this.percentages" [value]="i.value" [selected]="i.value == moment.percentage">{{i.label+'%'}}</option>
                                    </select>

                                    <div *ngIf="(dimmingMoments.length > 1) && ((moment.isHovered) || mobileMode); else placeholder" (click)="handleDeleteMoment($event, moment)" style="position:absolute; right:0; top:0;" class="cursor-pointer unselectable-content" title="{{'Item verwijderen' | translate}}" >
                                        <i class="material-icons p-1" style="font-size: 14px;">clear</i>
                                    </div>
                                    <!-- The weirdest IE 11 bug-fix ever-->
                                    <ng-template #placeholder>
                                        <div></div>
                                    </ng-template>
                                    <!-- ------------------------------ -->
                                </div>
                            </ng-container>
                        </div>
                        <div *ngIf="dimmingMomentsForAllChannels && dimmingMomentsForAllChannels.length > 0" class="d-flex flex-row justify-content-end mb-0 p-2 unselectable-content mt-auto" style="min-height: 52px;">
                            <div class="ml-2 card cursor-pointer" (click)="handleAddMoment($event)" title="{{'dimmingSchemes.addmoment' | translate}}">
                                <i class="p-2 material-icons " style="font-size: 20px">add</i>
                            </div>
                            <div class="ml-2 card cursor-pointer" (click)="handleSaveScheme($event)" title="{{'dimmingSchemes.savescheme' | translate}}">
                                <i class="p-2 material-icons " style="font-size: 20px">check</i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </default-popup-wrapper-component>
    `
})

export class GlobalPopupEditSchemeComponent extends AbstractBasePopupComponent implements AfterViewInit, OnDestroy
{
    @ViewChild('graph', {static: false}) tinyGraph:TinyGraphComponent;
    @ViewChildren('hourInputs') hourInputs:QueryList<any>;
    @ViewChild('mobileHourInput', {static: false}) mobileHourInput:any;
    @ViewChild('mobileMinuteInput', {static: false}) mobileMinuteInput:any;
    @ViewChild('mobilePercentageInput', {static: false}) mobilePercentageInput:any;

    private dataChanged:boolean = false;
    public dimmingSchemes:any = [];
    public selectedDimmingSchemeId:number = -1;
    public dimmingMoments:any = [];
    private oldDimmingMoments:any = [];
    public dimmingGroups:any = [];
    public exceptions:any = [];
    public dimmingMomentsForAllChannels:any = [];
    public loadingGraph:boolean = false;
    
    public hours:any = [];
    public minutes:any = [];
    public percentages:any = [];
    
    public mobileMode:boolean = false;
    private subMobileMode: Subscription = null;

    public constructor(protected cd:ChangeDetectorRef, protected elementRef:ElementRef, protected globalAlertService:GlobalAlertService, protected dimmingService:DimmingService, protected ts:TranslateService, private model:GlobalModel, public auth:AuthorizationService, protected tooltipService:TooltipService, protected logger:LoggerService) {
        super(cd, globalAlertService, elementRef, ts, tooltipService, logger);
        this.subMobileMode = this.model.mobileMode.subscribe((value:boolean) => {
            //Don't forget to unsubscribe
            this.mobileMode = value;
        });
    }
    
    public getDimmingGroupsString():string
    {
        let resultLimit:number = 5;
        let result:string = "";

        let groupsAndExceptions:any = this.dimmingGroups.concat(this.exceptions); // + "," + this.exceptions;

        if (groupsAndExceptions && groupsAndExceptions.length > 0){

            for(let i = 0; i < resultLimit; i++) {
                if (i <= groupsAndExceptions.length - 1){
                    result += groupsAndExceptions[i] + (i != groupsAndExceptions.length-1?", ":'');
                }
            }

            //Append 'and x other groups'
            if (groupsAndExceptions.length > resultLimit){
                result += this.ts.translate("dimming.other", [groupsAndExceptions.length - resultLimit]);
            }
        }

        return result;
    }

    ngOnInit(){

        for (let i=0;i<=23;i++){
            this.hours.push({value:i, label:this.formatNumber(i)});
        }
        for (let i=0;i<=59;i++){
            this.minutes.push({value:i, label:this.formatNumber(i)});
        }
        for (let i=0;i<=100;i++){
            this.percentages.push({value:i, label:this.formatNumber(i)});
        }
    }

    ngAfterViewInit(){
        this.getDimmingSchemes();
    }
    
    public handleBlurTime(event:any):void{
        this.sortDimmingMoments();
        this.updateGraphWithLocalData();
    }
    
    public handleChangeHoursMobile(event:any, moment:any):void{
        moment.hour = this.mobileHourInput.nativeElement.value;

        this.updateDimmingMomentsTime();
        this.sortDimmingMoments();
        this.updateGraphWithLocalData();
    }
    
    public handleChangeMinutesMobile(event:any, moment:any):void{
        moment.minute = this.mobileMinuteInput.nativeElement.value;

        this.updateDimmingMomentsTime();
        this.sortDimmingMoments();
        this.updateGraphWithLocalData();
    }
    
    public handleChangePercentageMobile(event:any, moment:any):void{
        moment.percentage = this.mobilePercentageInput.nativeElement.value;

        this.updateDimmingMomentsTime();
        this.updateGraphWithLocalData();
    }
    
    public handleKeyDownHours(event:any, moment:any):void
    {
        if (Utils.isNumberKeycode(event.keyCode))
        {
            if (moment.freshFocus){
                moment.hour = parseInt(event.key);
            }else if ((parseInt(moment.hour.toString() + event.key.toString()) < 24)){
                moment.hour = parseInt(moment.hour.toString() + event.key.toString());
            }else{
                moment.hour = parseInt(event.key);
            }

            moment.freshFocus = false;
            this.updateDimmingMomentsTime();

        }
    }
    
    public handleKeyDownMinutes(event:any, moment:any):void
    {
        if (Utils.isNumberKeycode(event.keyCode)){
            if (moment.freshFocus){
                moment.minute = parseInt(event.key);
            }else if ((parseInt(moment.minute.toString() + event.key.toString()) < 60)){
                moment.minute = parseInt(moment.minute.toString() + event.key.toString());
            }else{
                moment.minute = parseInt(event.key);
            }

            moment.freshFocus = false;

            this.updateDimmingMomentsTime();
        }
    }
    
    public handleKeyDownPercentage(event:any, moment:any):void
    {
        if (Utils.isNumberKeycode(event.keyCode)){

            if (moment.freshFocus){
                moment.percentage = parseInt(event.key);
            }else if ((parseInt(moment.percentage.toString() + event.key.toString()) < 101)){
                moment.percentage = parseInt(moment.percentage.toString() + event.key.toString());
            }else{
                moment.percentage = parseInt(event.key);
            }

            moment.freshFocus = false;

            this.updateDimmingMomentsTime();
            this.updateGraphWithLocalData();
        }
    }
    
    public handleFocus(event:any, moment:any):void
    {
        moment.freshFocus = true;
    }
    
    public formatNumber(number:number):string
    {
        return Utils.formatNumberWithLeadingZero(number);
    }
    
    public formatPercentage(percentage:number):string
    {
        if (percentage == 0){
            return percentage + "%";
        }else if (percentage < 10){
            return percentage + "%"; //'0'+ percentage + "%";
        }else{
            return percentage + "%";
        }
    }
    
    public handleAddChannel(event:any):void
    {
        //Add a first basic moment
        this.dimmingMomentsForAllChannels.push([this.getEmptyMoment()]);

        //Set the selected channel of the graph to the new channel
        this.tinyGraph.setSelectedChannel(event.newChannel);

        //Update local dimmingmoments and update the graph
        this.changeChannel(event.newChannel);
        this.cd.detectChanges();
    }
    
    public handleRemoveChannel(event:any):void{

        //Add a first basic moment
        this.dimmingMomentsForAllChannels.splice(this.tinyGraph.getSelectedChannel(), 1);

        //Set the selected channel of the graph to channel 0
        this.tinyGraph.setSelectedChannel(0);

        //Update local dimmingmoments and update the graph
        this.changeChannel(0);
        this.cd.detectChanges();
    }
    
    public handleChangeChannel(event:any):void
    {
        let currentChannel:number = event.currentChannel;

        this.changeChannel(currentChannel);
    }

    private changeChannel(currentChannel:number):void
    {
        this.dimmingMoments = this.dimmingMomentsForAllChannels[currentChannel];

        this.formatAllDimmingMoments(false);

        this.updateGraphWithLocalData();

        this.cd.detectChanges();
    }

    private formatAllDimmingMoments(storeDimmingMoments:boolean):void{

        if (storeDimmingMoments){
            this.oldDimmingMoments = [];
        }

        this.dimmingMomentsForAllChannels.forEach((channels:any) => {
            channels.forEach((moment:any) => {
                moment.time = moment.time.substr(0, 5);
                moment.hour = parseInt(moment.time.substr(0, 2));
                moment.minute = parseInt(moment.time.substr(3, 2));
                moment.isHovered = false;

                if (storeDimmingMoments){
                    this.oldDimmingMoments.push(moment.time + " " + moment.percentage);
                }
            });
        });
    }
    
    public handleSelectScheme(event:any)
    {
        this.selectedDimmingSchemeId = event.target.value;
        this.getDimmingScheme(() => {
            this.updateGraph( () => {this.loadingGraph = false;});
        });
    }

    private updateDimmingMomentsTime():void
    {
        this.logger.log("[GlobalPopupEditSchemeComponent] moment for all channels->", this.dimmingMomentsForAllChannels);
        this.dimmingMomentsForAllChannels.forEach((channels:any) => {
            channels.forEach((moment:any) => {
                moment.time = this.formatNumber(moment.hour)+":"+this.formatNumber(moment.minute);
            });
        });
    }

    private updateGraphWithLocalData():void
    {
        let dataTable:any = {cols: [{id:1, label:"Tijdstip", type:"datetime"}, {id:2, label:"Percentage", type:"number"}], rows: []};

        let defaultDate:string = "Date(1970,01,01,";

        //Add starting point
        let firstMoment:any = this.dimmingMoments[0];
        let lastMoment:any = this.dimmingMoments[this.dimmingMoments.length-1];
        dataTable.rows.push({c:[{v:defaultDate + this.formatNumber(0) + "," + this.formatNumber(0) + ",00)", f:this.formatNumber(0) + ":" + this.formatNumber(0) + ":00"},{v: lastMoment.percentage}]});
        dataTable.rows.push({c:[{v:defaultDate + this.formatNumber(firstMoment.hour) + "," + this.formatNumber(firstMoment.minute) + ",00)", f:this.formatNumber(firstMoment.hour) + ":" + this.formatNumber(firstMoment.minute) + ":00"},{v: lastMoment.percentage}]});

        //Add points and generate between-points to keep a straight line
        for (let i = 0; i < this.dimmingMoments.length; i++) {
            let moment:any = this.dimmingMoments[i];
            if (i-1 >= 0){
                let prevMoment:any = this.dimmingMoments[i-1];
                dataTable.rows.push({c:[{v:defaultDate + this.formatNumber(moment.hour) + "," + this.formatNumber(moment.minute) + ",00)", f:this.formatNumber(moment.hour) + ":" + this.formatNumber(moment.minute) + ":00"},{v: prevMoment.percentage}]});
            }
            dataTable.rows.push({c:[{v:defaultDate + this.formatNumber(moment.hour) + "," + this.formatNumber(moment.minute) + ",00)", f:this.formatNumber(moment.hour) + ":" + this.formatNumber(moment.minute) + ":00"},{v: moment.percentage}]});
        }

        // Add ending point
        //let lastMoment:any = this.dimmingMoments[this.dimmingMoments.length-1];
        dataTable.rows.push({c:[{v:defaultDate + this.formatNumber(24) + "," + this.formatNumber(0) + ",00)", f:this.formatNumber(24) + ":" + this.formatNumber(0) + ":00"},{v: lastMoment.percentage}]});

        //Update graph
        this.tinyGraph.applyDataTable(dataTable);
    }
    
    public handleSaveScheme(event:MouseEvent):void
    {
        this.updateDimmingMomentsTime();
        this.sortDimmingMoments();
        this.updateGraphWithLocalData();

        //Only send time, id, hour and minute, and add channel as a property (flatten the array)
        let strippedDimmingMoments:any = [];
        let moment:any;
        for (let i:number = 0; i < this.dimmingMomentsForAllChannels.length; i++) {
            for (let j:number = 0; j < this.dimmingMomentsForAllChannels[i].length; j++) {
                moment = this.dimmingMomentsForAllChannels[i][j];
                strippedDimmingMoments.push({id: moment.id, channel:i, hour: moment.hour, minute: moment.minute, time: moment.time, percentage: moment.percentage});
                this.logger.log("[GlobalPopupEditSchemeComponent] stripped ->", strippedDimmingMoments);
            }
        }

        this.dimmingService.updateDimmingScheme(this.selectedDimmingSchemeId, strippedDimmingMoments, () => {
            // update success, reload scheme
            this.getDimmingScheme(() => {
                this.updateGraph( () => {this.loadingGraph = false;});
            });

            this.dataChanged = true;

            this.globalAlertService.addAlertSuccess(this.ts.translate('dimming.savetitle'), this.ts.translate('dimming.savetext' ), '');
        }, () => {}, () => {});
    }

    private getSchemeById(schemeId:number):any
    {
        for (let i:number = 0; i < this.dimmingSchemes.length; i++) {
            if (this.dimmingSchemes[i].id == schemeId){
                return this.dimmingSchemes[i];
            }
        }

        return null;
    }
    
    public handleAddScheme(event:MouseEvent):void
    {
        let creationHref:string = "control-form/dimgroup/scheme/create";
        let label:string = "dimschema's";

        this.globalAlertService.addPopupCreateDropdown(this.ts.translate("Item toevoegen aan: ") + label, "", creationHref, (buttonCode:any, response:any) => {

            //User pressed OK and call succeeded
            if (response){
                this.dataChanged = true;
                this.getDimmingSchemes(response.id);
                this.cd.detectChanges();
            }
        }, () => {
            this.logger.log("[FormSelectComponent] " + "Gebruiker heeft op ANNULEREN of kruisje gedrukt");
        });
    }
    
    public handleEditcheme(event:MouseEvent):void
    {
        let selectedScheme:any = this.getSchemeById(this.selectedDimmingSchemeId);
        let href:string = "control-form/dimgroup/scheme/edit/" + this.selectedDimmingSchemeId;

        this.globalAlertService.addPopupEditDropdown(this.ts.translate('dropdown.edittitle', ["'" + selectedScheme.name + "'"]), "", href, selectedScheme.name, (buttonCode:any, response:any) => {

            this.logger.log("[GlobalPopupEditSchemeComponent] " + "response: " , response);

            //User pressed OK and call succeeded
            if (response){

                selectedScheme.name = response.name;

                this.dataChanged = true;

                //this.getDimmingSchemes(response.id);
                this.cd.detectChanges();
            }
        }, () => {
            this.logger.log("[FormSelectComponent] " + "Gebruiker heeft op ANNULEREN of kruisje gedrukt");
        });
    }
    
    public handleDeleteMoment(event:MouseEvent, moment:any):void
    {
        if(this.dimmingMoments.length <= 1){
            return;
        }

        //Remove moment locally
        let index = this.dimmingMoments.indexOf(moment, 0);

        if (index > -1) {
            this.dimmingMoments.splice(index, 1);
        }

        this.updateGraphWithLocalData();
    }
    
    public handleAddMoment(event:MouseEvent):void
    {
        this.dimmingMoments.push(this.getEmptyMoment());
        this.sortDimmingMoments();

        // auto focus on first element. Give some time to render the new component
        setTimeout(() => {
            if (this.hourInputs.toArray().length > 0){
                this.hourInputs.toArray()[0].nativeElement.focus();
            }
        });

        this.updateGraphWithLocalData();
    }
    
    public getEmptyMoment():any
    {
        return {id: -1, time: "00:00", hour:0, minute:0, percentage: 100, isHovered:false};
    }

    private sortDimmingMoments():void
    {
        this.dimmingMoments = this.dimmingMoments.sort(
            function (a:any, b:any) {

                return sortByString(a.time, b.time, true);

                function sortByString(stringA:string, stringB:string, ascending:boolean){
                    if (stringA < stringB){
                        return ascending ? -1 : 1;
                    }
                    if (stringA > stringB){
                        return ascending ? 1 : -1;
                    }
                    return 0;
                }
            });
    }
    
    public handleDeleteScheme(event:MouseEvent):void{

        let selectedScheme:any = this.getSchemeById(this.selectedDimmingSchemeId);

        if (selectedScheme){
            this.globalAlertService.addPopup(this.ts.translate("dimmingschemes.removetitle"), this.ts.translate("dimmingschemes.remove", [selectedScheme.name]), [{label:this.ts.translate("Annuleren"), code:ButtonCode.ANNULEREN, isPrimary:true},
                {label:this.ts.translate("Verwijderen"), code:"DELETE",
                callback:() => {
                    this.dimmingService.deleteDimmingScheme(selectedScheme.id, (json:any) => {
                        // delete success, reload schemes
                        this.getDimmingSchemes();

                        this.dataChanged = true;

                        this.globalAlertService.addAlertSuccess(this.ts.translate('dimming.deletetitle'), this.ts.translate('dimming.savetext' ), '');
                        },
                        (failure:RequestFailure) => {
                            this.globalAlertService.addPopup(this.ts.translate("Waarschuwing"), failure.message, [{label:this.ts.translate("Ok"), code:"OK", isPrimary:true}], () => {});
                        },
                        () => {});

                }, isPrimary:false}], () => {});
        }
    }

    // Handle mouse over this element.
    public handleMouseOverMoment(event:MouseEvent, moment:any):void
    {
        moment.isHovered = true;
    }
    
    public handleMouseOutMoment(event:MouseEvent, moment:any):void
    {
        moment.isHovered = false;
    }

    private updateGraph(successCallBack?:() => any):void
    {
        if (this.selectedDimmingSchemeId > -1){
            this.tinyGraph.updateGraphData(this.GRAPH_PATH + this.selectedDimmingSchemeId, successCallBack)
        }
    }

    private getDimmingScheme(successCallBack?:() => any):void
    {
        this.loadingGraph = true;
        this.cd.detectChanges();

        this.dimmingService.getDimmingScheme(this.selectedDimmingSchemeId, (json:any) => {

            //Store the moments for all chanels
            this.dimmingMomentsForAllChannels = json.dimmingScheme.dimmingSchemeMoments;

            //Reset the selected channel to 0 so dimmingschemes with only 1 channel don't try to load higher channels
            if (this.tinyGraph.getSelectedChannel() > this.dimmingMomentsForAllChannels.length -1){
                this.tinyGraph.setSelectedChannel(0);
            }

            this.dimmingMoments = this.dimmingMomentsForAllChannels[this.tinyGraph.getSelectedChannel()]; //json.dimmingScheme.dimmingSchemeMoments;
            this.formatAllDimmingMoments(true);

            this.dimmingGroups = json.dimmingScheme.dimgroups;
            this.exceptions = json.dimmingScheme.exceptions;

            this.cd.detectChanges();

            successCallBack();

        }, () => {}, () => {});
    }

    private getDimmingSchemes(autoSelectId:number = -1){

        this.dimmingService.listDimmingSchemes((json:any) => {

            this.dimmingSchemes = json.dimmingSchemes;

            if (this.dimmingSchemes.length > 0){

                if (autoSelectId > -1){
                    this.selectedDimmingSchemeId = autoSelectId;
                } else if (this.globalPopup.data && this.globalPopup.data.selectedScheme > 0 && this.getSchemeById(this.globalPopup.data.selectedScheme)){
                    this.selectedDimmingSchemeId = this.globalPopup.data.selectedScheme;
                    this.globalPopup.data.selectedScheme = -1;
                    this.tinyGraph.setSelectedChannel(this.globalPopup.data.selectedChannel);
                }else{
                    this.selectedDimmingSchemeId = this.dimmingSchemes[0].id;
                }

                this.getDimmingScheme(() => {
                    this.updateGraph( () => {this.loadingGraph = false;});
                });
            }

            this.cd.detectChanges();

        }, () => {}, () => {});
    }

    handlePopupAction(event: MouseEvent, alert: GlobalPopup, button: any): void {
        //Hide alert and perform callback of button
        this.onPopupAction.emit({event:event, alert:alert, button:button, data:{dataChanged:this.dataChanged}});
    }

    public closePopup(event:any):void
    {
        //check if moments have changed. If they did, show a warning
        let momentsMatch = true;
        let currentDimmingMoments:any = [];

        //Create a flattened array off current moments
        this.dimmingMomentsForAllChannels.forEach((channels:any) => {
            channels.forEach((moment:any) => {
                currentDimmingMoments.push(moment.time + " " + moment.percentage);
            });
        });

        //Copare old to current
        for (let i: number = 0; i < this.oldDimmingMoments.length; i++) {
            if (currentDimmingMoments[i] && this.oldDimmingMoments[i] == currentDimmingMoments[i]){
                //Still a match
            }else{
                momentsMatch = false;
                break;
            }
        }

        //Handle the case of there being more current moments than old moments and all old moments still match
        if (currentDimmingMoments.length != this.oldDimmingMoments.length){
            momentsMatch = false;
        }

        //When the moments have changed, show a are-your-sure-popup
        this.doDefaultCloseHandling(event, !momentsMatch);
    }

    get GRAPH_PATH():string{
        return DimmingExceptionsComponent.GRAPH_PATH;
    }

    ngOnDestroy(){
        if (this.subMobileMode){
            this.subMobileMode.unsubscribe();
        }
    }
}
