<div class="login-notify" *ngIf="notifyMessages">
    <div id="notifications" class="carousel">
        <div class="carousel-inner">
            <div class="carousel-controls">
                <a class="carousel-control-prev-custom" href="#notifications" role="button" data-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="sr-only">Previous</span>
                </a>
                <a class="carousel-control-next-custom" href="#notifications" role="button" data-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="sr-only">Next</span>
                </a>
            </div>
            <div class="carousel-item" *ngFor="let notifyMessage of notifyMessages.notifyMessages; let i = index" [ngClass]="{'active': i === 0}">
                <div class="d-flex justify-content-end badge-container" *ngIf="isNewNotification(notifyMessage.isoDateTime) || notifyMessage.warning">
                    <span *ngIf="isNewNotification(notifyMessage.isoDateTime) && !notifyMessage.warning" class="badge badge-pill badge-warning">{{'login.notifications.newitem' | translate}}</span>
                    <span *ngIf="notifyMessage.warning" class="badge badge-pill badge-danger">{{'login.notifications.warning' | translate}}</span>
                </div>
                <div class="card">
                    <div class="card-image d-flex justify-content-center" *ngIf="notifyMessage.imgPath">
                        <img [attr.src]="imagePathPrefix+notifyMessage.imgPath" [attr.title]="notifyMessage.title" [attr.alt]="notifyMessage.title" />
                    </div>
                    <div class="card-body">
                        <h5 class="card-title">{{notifyMessage.title}}</h5>
                        <p class="card-text" [innerHTML]="notifyMessage.body"></p>
                    </div>
                    <div class="card-footer text-right">
                        <small>{{convertIsoDateTime(notifyMessage.isoDateTime)}}</small>
                    </div>
                </div>
            </div>
        </div>
        <ol class="carousel-indicators position-relative">
            <li *ngFor="let notifyMessage of notifyMessages.notifyMessages; let i = index" [attr.data-slide-to]="i" [ngClass]="{'active': i === 0}" data-target="#notifications"></li>
        </ol>
    </div>
</div>