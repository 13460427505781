import {Injectable} from '@angular/core';
import {HTTPService} from "../../services/http/http.service";
import {RequestFailure} from "../../services/http/request-failure";
import {HTTPError} from "../../services/http/http-error";
import {GlobalEvent} from "../../interfaces/global-event";
import {GlobalAlertService} from "../../../wrapper/global-alert/global-alert.service";
import {GlobalModel} from "../../services/state/global.model";
import {TranslateService} from "../../services/translate/translate.service";
import Utils from "../../utils/utils";
import {ButtonCode} from "../../../wrapper/global-alert/global-popup";
import {MapItemService} from "./map-item/map-item.service";
import {IGeoJSON} from "./managers/map-manager.interface";
import {MapCoreComponent} from "./map-core.component";
import {Observable} from "rxjs";
import {HttpService2} from "../../services/http-service-2.0/http2.0.service";
import {FormPostResult} from "../../services/http-service-2.0/http.interface";
import {map} from "rxjs/operators";
import {LoggerService} from "../../services/logger/logger.service";


@Injectable()
export class MapCoreV2Service {
    constructor(protected httpService: HTTPService, protected httpService2: HttpService2, private mapItemService:MapItemService, private globalAlertService:GlobalAlertService, private model:GlobalModel, private ts:TranslateService, protected logger:LoggerService) {
    }

    public getMapItemByBaseobjectId(baseObjectId:number): Observable<FormPostResult>{
        this.logger.log('[Grid][MapCoreV2Service] ' + 'Get map item by baseObjectId: '+baseObjectId)
        //Get the new map item from the server by baseobjectid
        let postValues: any = {
            tree: {'code': 'ADMINISTRATIVE_AREA_2_ROUTE', 'references': undefined, 'objectTypes': ['schakelkast','mast','cable','wire','conduit','joint','annotation'], 'baseObjectIds': [baseObjectId]},
        };
        return this.httpService2.doFormPostRequest(`mapitem/list/asset-management`, postValues, true).pipe(
            map((result) => {
                return result;
            })
        );
    }

    public getTableItemByBaseobjectId(baseObjectId:number): Observable<FormPostResult>{
        this.logger.log('[Grid][MapCoreV2Service] ' + 'Get table item by baseObjectId: '+baseObjectId)
        //Get the new table item from the server by baseobjectid
        let postValues: any = {
            tree: {'code': 'ADMINISTRATIVE_AREA_2_ROUTE', 'references': undefined, 'objectTypes': ['schakelkast','mast','cable','wire','conduit','joint','annotation'], 'baseObjectIds': [baseObjectId]},
        };
        return this.httpService2.doFormPostRequest(`tableitem/list/asset-management`, postValues, true).pipe(
            map((result) => {
                return result;
            })
        );
    }

    public getBBoxItems(bounds: google.maps.LatLngBounds): Observable<FormPostResult>{
        this.logger.log('[Grid][MapCoreV2Service] ' + 'Get map item by bounding box')

        let southWest = bounds.getSouthWest()
        let northEast = bounds.getNorthEast()

        let postValues: any = {
            'tree': {
                'code': 'BOUNDING_BOX',
                'gridBounds': {
                    'south': southWest.lat(),
                    'west': southWest.lng(),
                    'north': northEast.lat(),
                    'east': northEast.lng(),
                },
                'objectTypes': ['cable', 'wire', 'conduit', 'joint', 'annotation'],
                'showArchived': false
            }
        };
        return this.httpService2.doFormPostRequest(`mapitem/list/asset-management`, postValues, true).pipe(
            map((result) => {
                return result;
            })
        );
    }

    public savePolylineEdit(baseObjectId:number, geometry:IGeoJSON):any{
        this.logger.log('[Grid][MapCoreV2Service] ' + 'Save polyline edit for baseObjectId: '+baseObjectId)
        //Save the polyline edit
        let postValues: any = {
            geometry: geometry
        };
        this.httpService.doFormPostRequest(`mapitem/form/drawing/edit/${baseObjectId}`, postValues, (json: any) => {
                return json;
            }, (failure: RequestFailure) => {}, (error: HTTPError) => {}, true
        )
    }

    public deleteUndergroundBaseObject(baseObjectData:any):void{
        this.logger.log('[Grid][MapCoreV2Service] ' + 'Delete or archive base object with baseObjectId: '+baseObjectData.referenceId)
        //Show warning to delete base object
        const deleteUrl = 'asset-management-form/baseobject/delete'
        this.mapItemService.getBaseObjectDeleteEffects(deleteUrl, baseObjectData.referenceId,
            (json:any) => {
                //Effects successfully received, trigger popup
                this.globalAlertService.addPopupDeleteBaseObject( Utils.capitalizeFirstLetter(this.ts.translate("baseobject.delete", [baseObjectData.formData.generalInfo.code, baseObjectData.formData.generalInfo.description])), json,
                    [
                        {label:this.ts.translate("Annuleren"), code:ButtonCode.ANNULEREN, isPrimary:true},
                        {label:this.ts.translate('Archiveren'), code:ButtonCode.ARCHIVE, callback:() => {
                                this.mapItemService.archiveBaseObject(deleteUrl, baseObjectData.referenceId,
                                    (json:any) => {
                                        this.logger.log('[Grid][MapCoreV2Service] ' + 'Baseobject gearchiveerd: '+baseObjectData.referenceId)
                                        this.globalAlertService.addAlertSuccess(this.ts.translate('treenode.archivetitle'), this.ts.translate('treenode.archivetext' ), '');
                                        this.model.onGlobalEvent.next(new GlobalEvent(GlobalEvent.EVENT_REMOVE_UNDERGROUND_BASEOBJECT_SUCCESS, {baseObjectId:baseObjectData.referenceId}));

                                    },
                                    (failure:RequestFailure) => {},
                                    (error:HTTPError) => {},
                                )
                            }, isPrimary:false},
                        {label:this.ts.translate('Verwijderen'), code:'DELETE', callback:() => {
                                this.mapItemService.deleteBaseObject(deleteUrl, baseObjectData.referenceId,
                                    (json:any) => {
                                        this.logger.log('[Grid][MapCoreV2Service] ' + 'Baseobject verwijderd: '+baseObjectData.referenceId)
                                        this.globalAlertService.addAlertSuccess(this.ts.translate('treenode.deletetitle'), this.ts.translate('treenode.deletetext' ), '');
                                            this.model.onGlobalEvent.next(new GlobalEvent(GlobalEvent.EVENT_REMOVE_UNDERGROUND_BASEOBJECT_SUCCESS, {baseObjectId:baseObjectData.referenceId}));
                                    },
                                    (failure:RequestFailure) => {},
                                    (error:HTTPError) => {},
                                )
                            }, isPrimary:false}], () => {
                        //Close popup, do nothing
                    });
            },
            (failure:RequestFailure) => {
                //User can't help this failure, do nothing
            },
            (error:HTTPError) => {
                //HTTP error is auto reported, do nothing
            },
        )
    }
}
