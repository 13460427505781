/**
 * Created by Christiaan on 01/03/2017.
 */
import {
    AfterViewInit,
    ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, ViewChild
} from '@angular/core';

import {GlobalAlertService} from "../global-alert.service";
import {ButtonInterface, GlobalPopup} from '../global-popup';
import {RequestFailure} from "../../../shared/services/http/request-failure";
import {FormDataService} from "../../../shared/components/form/services/form-data.service";
import {GlobalModel} from "../../../shared/services/state/global.model";
import {TranslateService} from "../../../shared/services/translate/translate.service";
import {DimmingExceptionsComponent} from "../../../shared/components/form/components/dimming/dimming-exceptions.component";
import {TinyGraphComponent} from "../../../shared/components/form/components/form-graph/tiny-graph.component";
import {AbstractBasePopupComponent} from './abstract-base-popup.component';
import {TooltipService} from "../../../shared/services/tooltip/tooltip.service";
import { FormEvent } from '../../../shared/components/form/containers/form/form.interface';
import {LoggerService} from "../../../shared/services/logger/logger.service";

@Component ({
    selector: 'global-popup-create-exception-component',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <default-popup-wrapper-component [componentRef]="this" [disableButtons]="readOnly" [globalPopup]="globalPopup" [showLoader]="isLoading" (onClose)="closePopup($event)">
            <div class="pr-0">
                <div *ngIf="formData && config">
                    <form-component #form
                            [config]="config" 
                            [label]="formData.schema.label"
                            [name]="getFormName()"
                            [validationConstraints]="validationConstraints"
                            [invalidControlsErrors]="invalidControlsErrors"
                            [readOnly]="readOnly"
                            [formIsSubmitted]="formIsSubmitted"
                            (onComponentEvent)="handleComponentEvent($event)">
                    </form-component>
                    <div class="d-flex flex-row mb-2 dynamic-form">
                        <label class="form-label-part" style="font-weight:400;">{{'dimscheme.example' | translate }}</label>
                        <tiny-graph style="" #graph [graphHeight]="100" ></tiny-graph>
                    </div>
                </div>
            </div>
        </default-popup-wrapper-component>
    `
})

export class GlobalPopupCreateExceptionComponent extends AbstractBasePopupComponent implements AfterViewInit
{
    @Input() requestUrl:string = "";
    @Input() submitUrl:string = "";

    @ViewChild('form', {static: false}) form:any;
    @ViewChild('graph', {static: false}) tinyGraph:TinyGraphComponent;

    private selectedDimmingSchemeId:number = -1;

    public constructor(protected cd:ChangeDetectorRef, protected globalAlertService:GlobalAlertService, protected formDataService:FormDataService, protected elementRef:ElementRef, private model:GlobalModel, protected ts:TranslateService, protected tooltipService:TooltipService, protected logger:LoggerService) {
        super(cd, globalAlertService, elementRef, ts, tooltipService, logger);
    }

    ngAfterViewInit(){

        //Retrieve map item id from the popup data
        this.requestUrl = this.globalPopup.data.requestUrl;
        this.submitUrl = this.globalPopup.data.submitUrl;

        let popupData:any = this.globalPopup.data;
        if (popupData && popupData.lat && popupData.lng){
            this.model.formLocationComponentPosition = {lat:popupData.lat, lng:popupData.lng};
        }else{
            this.model.formLocationComponentPosition = null;
        }

        this.submitButton = this.getSubmitButton();

        this.requestFormData();
    }

    //Unused for now
    // private handleClickGraph(event:MouseEvent):void{
    //     let selectedChannel:number = this.tinyGraph.getSelectedChannel();
    //     this.onPopupAction.emit({event: <any>FormEvent.CLICK_GRAPH, alert: null, button: null, data: {dimmingSchemeId: this.selectedDimmingSchemeId, channel: selectedChannel}})
    // }

    handlePopupAction(event: MouseEvent, alert: GlobalPopup, button: ButtonInterface): void {
        //callback for submit button comes from this form, not from outside this component
        if (button == this.submitButton){
            this.form.handleComponentEvent({event:FormEvent.SAVE, data:{}});
            //this.form.submitForm();
        }else{
            //Hide alert and perform callback of button
            this.onPopupAction.emit({event:event, alert:alert, button:button});
        }
    }

    handleComponentEvent(eventData: any):void{

        switch (eventData.event) {
            case FormEvent.SAVE:
                this.submitFormData(eventData.data.formData);
                break;
            case FormEvent.CHANGE_SELECT:
                this.logger.log("[GlobalPopupCreateExceptionComponent] " + "eventData.data: " , eventData.data);

                if (eventData.data.name && eventData.data.name.toLowerCase() == 'dimschema'){

                    this.selectedDimmingSchemeId = eventData.data.selectedOption;

                    if (eventData.data.selectedOption){
                        this.tinyGraph.updateGraphData(this.GRAPH_PATH + eventData.data.selectedOption + "/exception");
                    }else{
                        this.tinyGraph.clearGraphData();
                    }
                }
                break;
        }
    }

    private submitFormData(form: any):void
    {
        this.handleSubmitForm();

        this.formDataService.setFormData(form, this.getFormName(), this.submitUrl,
            (formData:any) => {
                //this.formData = formData;
                this.handleSubmitResponse();

                //Hide alert and perform success callback via submit button
                this.onPopupAction.emit({event:null, alert:this.globalPopup, button:this.submitButton, data:formData}); //, data:{lat:form.location.latitude, lng:form.location.longitude}});
            },
            (failure: RequestFailure) => {
                this.logger.log("[GlobalPopupCreateBaseObjectComponent] " + "Handle failure response: ", failure);
                this.handleSubmitResponse(failure.formErrors);
            },
            () => {
                this.handleSubmitResponse(null, true);
            }
        );
    }

    closePopup(event: any):void
    {
        this.doDefaultCloseHandling(event, true);
    }

    public requestFormData():void
    {
        this.formDataService.getFormData(this.requestUrl,
            (formData:any) => {

                //TODO: pas hier de latlng van de popup.data toe op de formData, als beschikbaar
                //En update dus de kaart en de locatie enzo

                //setTimeout(() => {
                    this.formData = formData;
                    this.isLoading = false;
                    this.cd.detectChanges();
                //});
            },
            () => {
                this.closePopup(null);
            },
            () => {
                this.closePopup(null);
            }
        );
    }

    get GRAPH_PATH():string{
        return DimmingExceptionsComponent.GRAPH_PATH;
    }
}
