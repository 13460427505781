/**
 * Created by Christiaan on 16/03/2017.
 */

import {Injectable} from '@angular/core';
import {HTTPService} from "../../shared/services/http/http.service";
import {GlobalModel} from "../../shared/services/state/global.model";
import {AppSettings} from "../../app.settings";

@Injectable() export class MenuService {

    constructor(private httpService:HTTPService, private model:GlobalModel) {}

    public menuEnabled():boolean{
        //Vertaling: Als geen angular-areaal-wissel OF geen popup zichtbaar in flex) DAN menu enabled
        return !this.httpService.isPendingCallPath([AppSettings.SWITCH_AREAAL_PATH]);
    }
}
