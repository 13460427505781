import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    Input,
    Output,
    ViewChild
} from '@angular/core';
import {AbstractControl, UntypedFormGroup} from '@angular/forms';
import {TooltipService} from '../../../../services/tooltip/tooltip.service';
import {StorageService} from '../../../../services/storage/storage.service';
import {GlobalModel} from '../../../../services/state/global.model';
import {Subscription} from 'rxjs';
import {GlobalEvent} from '../../../../interfaces/global-event';
import {FormButtonComponentEvent} from '../form-button/form-button.component';
import {FormFieldConfigInterface, FormFieldConfigOptionInterface} from '../abstract/abstract-form-field.component';
import {ButtonConfig, FormGroupConfig} from '../field/fieldDirective.interface';
import {SubStatusItem} from '../../../status/sub-statuses/sub-statuses.interface';
import Utils from '../../../../utils/utils';

@Component({
    selector: 'form-group-component',
    templateUrl: 'form-group.component.html',
})

export class FormGroupComponent {
    @Input() label: string = '';
    @Input() config: FormGroupConfig;
    @Input() rootConfig: any;
    @Input() level: number;
    @Input() form: UntypedFormGroup;
    @Input() name: string;
    @Input() invalidControlsErrors: any;
    @Input() validationConstraints: any;
    @Input() readOnly: boolean;
    @Input() formIsSubmitted: boolean;
    @Input() buttonsOnly: boolean = false;

    @Output() onComponentEvent: EventEmitter<FormButtonComponentEvent> = new EventEmitter();

    @HostBinding('class') hostClasses = '';
    @HostBinding('style.padding-top') public buttonBarPadding: string = '';
    
    @ViewChild('expandSwitch', {static: false}) expandSwitch: ElementRef<HTMLDivElement>;
    
    expanded: boolean = true;
    infoText: string;
    private subOnGlobalEvent: Subscription;
    public subStatuses: SubStatusItem[];

    constructor(protected cd: ChangeDetectorRef,
                protected storage: StorageService,
                protected model: GlobalModel,
    ) {
        this.subOnGlobalEvent = this.model.onGlobalEvent.subscribe((event: GlobalEvent) => {
            if (event.type == GlobalEvent.EVENT_SCROLL_TO_SECTION) {
                if (this.config && event.data.hash == '#' + this.config.name) {
                    if (event.data.autoExpandSection) {
                        this.tryExpand(true);
                    }
                }
            }
        });
    }

    // Pass component event further up
    handleComponentEvent(eventData: any): void {
        if (this.config && this.config.children && eventData.doVisibleCheck) {
            for (let i = 0; i < this.config.children.length; i++) {
                if (this.config.children[i].attr && this.config.children[i].attr.visible != undefined) {
                    this.config.children[i].attr.visible = Object.values(eventData.value)[0] === Object.keys(eventData.value)[0];
                }
            }
        }
        this.onComponentEvent.emit(eventData);
    }

    public handleClickExpand(): void {
        this.tryExpand();
    }

    private tryExpand(forceExpand: boolean = false): void {
        if (!this.isCollapsible()) {
            return;
        } else {
            if (forceExpand) {
                this.expanded = true;
            } else {
                this.expanded = !this.expanded;
            }

            this.model.onGlobalEvent.next(new GlobalEvent(GlobalEvent.EVENT_EXPAND_FORM_GROUP, {expanded: this.expanded}));
            this.storage.setValue(this.rootConfig.name + '.' + this.name, this.expanded);

            setTimeout(() => {
                this.cd.detectChanges();
            });
        }
    }

    public notButtonsOnly(config: any): boolean {
        let visible: boolean = true;
        if (this.buttonsOnly) {
            visible = config.type == 'button' || this.hasButtonChild(config);
        }
        return visible;
    }

    // Search for buttons in the config recursively. Return true if children or grand childeren have buttons
    private hasButtonChild(currentConfig: any): boolean {
        for (let i = currentConfig.children.length - 1; i >= 0; i--) {
            if (currentConfig.children[i].type == 'button') {
                return true;
            } else {
                if (currentConfig.children[i].children && currentConfig.children[i].children.length) {
                    return this.hasButtonChild(currentConfig.children[i]);
                }
            }
        }

        return false;
    }

    ngOnInit() {
        // Config now available
        if (this.isCollapsible()) {
            this.expanded = false;

            let storageKey: string = this.rootConfig.name + '.' + this.name;

            if (!this.storage.keyExists(storageKey)) {
                this.expanded = true;
            }

            // Check local storage. Use the name of the form from the root config to differentiate between forms
            this.storage.getBooleanValue(this.rootConfig.name + '.' + this.name, (value: boolean) => {
                this.expanded = value;
            });
        } else {
            this.expanded = true;
        }

        // TODO: vanuit PHP meegeven
        if (this.config.name.includes('import_wizard')) {
            for (let i = 0; i < this.config.children.length; i++) {
                if (this.config.children[i].name.includes('sub_data')) {
                    this.config.children[i].attr = {selectAllCheckboxGroups: true};
                    break;
                }
            }
        }
        
        if (this.config?.attr?.subStatuses?.length > 0) {
            this.subStatuses = this.config.attr.subStatuses;
        }
    }

    public handleClickSelectAllCheckboxes() {
        let checkboxes: AbstractControl[] = [];
        let options: FormFieldConfigOptionInterface[] = [];
        let checkedCounter: number = 0;

        this.rootConfig.children.forEach((formGroup: FormFieldConfigInterface) => {
            if (formGroup.name.includes('sub_data')) {
                formGroup.children.forEach((formGroupChild: FormFieldConfigInterface) => {
                    formGroupChild.options.forEach((option) => {
                        options = options.concat(option);
                        checkboxes = checkboxes.concat(this.form.parent.get([formGroup.name, formGroupChild.name, option.name]));
                    });
                });
            }
        });

        checkboxes.forEach((checkbox) => {
            if (checkbox.value !== '') {
                checkedCounter++;
            }
        });

        if (checkedCounter >= options.length / 2) {
            for (let i = 0; i < checkboxes.length; i++) {
                checkboxes[i].patchValue('');
            }
        } else {
            for (let i = 0; i < checkboxes.length; i++) {
                checkboxes[i].patchValue(options[i].name);
            }
        }
    }

    public showLabel(): boolean {
        return (this.label && this.label !== '');
    }

    public isVisible(): boolean {
        let result: boolean = true;

        // Visible attr isn't always set, so only use it when explicitly set to false
        if (this.config && this.config.attr && this.config.attr.visible == false) {
            result = false;
        }

        return result;
    }

    public isChildVisible(childConfig:any): boolean {
        let result: boolean = true;

        // Visible attr isn't always set, so only use it when explicitly set to false
        if (childConfig && childConfig.attr && childConfig.attr.visible == false) {
            result = false;
        }

        return result;
    }

    public showDescription(): boolean {
        return (this.config && this.config.attr && this.config.attr.description && this.config.attr.description != '');
    }

    public isCollapsible(): boolean {
        return (this.config && this.config.attr && this.config.attr.collapsible);
    }

    public disabled() {
        let state = null;

        if (this.readOnly === true) {
            state = '';
        } else if (this.readOnly === false) {
            state = null;
        }
        if (this.config.disabled === true) {
            state = '';
        }

        return state;
    }

    public isMutationButtonGroup(): boolean {
        // TODO: er is een property isFixedTopButtonBar maar die is nog niet lekker bereikbaar hier, dus nu hardcoded op ID
        return this.name && this.name == 'mutationButtons';
    }

    public getClassNames(): string {

        let result: string = '';

        if (this.isMutationButtonGroup()) {
            result += 'button-bar-fixed flex-column align-items-center';
            this.hostClasses = 'button-bar-fixed-container';
            this.buttonBarPadding = $('.button-bar-fixed-container .button-bar-fixed').outerHeight() + 'px';
        }

        if (this.level == 0) {
            result += 'm-0 p-0';
        }

        if (!this.expanded) {
            result += 'mb-0';
        }

        return result;

    }

    ngOnDestroy() {
        // TODO: dit is niet allemaal nodig toch? Alleen ff listeners stoppen, de rest gaat vanzelf via GC
        this.form = null;
        this.config = null;
        this.name = null;
        this.invalidControlsErrors = null;
        this.validationConstraints = null;
        this.formIsSubmitted = null;
        this.label = null;
        this.readOnly = null;
    }

    get PLACEMENT_BOTTOM(): string {
        return TooltipService.PLACEMENT_BOTTOM;
    }

    public handleClickHeaderButton($event: MouseEvent): void {
        $event.stopImmediatePropagation();

        const headerButton: ButtonConfig = this.config.attr.headerButton;

        if (headerButton) {
            let action: string = null;
            let url: string = '';
            let mapItem: number = -1;
            let attr: object = {};

            if (headerButton.attr) {
                attr = headerButton.attr;

                if (headerButton.attr.action) {
                    action = headerButton.attr.action;
                }
                if (headerButton.attr.url) {
                    url = headerButton.attr.url;
                }
                if (headerButton.attr.map_item_id) {
                    mapItem = headerButton.attr.map_item_id;
                }
            }

            this.onComponentEvent.emit({event: action, data: {url: url, mapItem: mapItem, attr: attr}, config: this.config});
        }
    }
    
    public onKeyDownToggler($event: KeyboardEvent): void {
        if (!Utils.hasFocus(this.expandSwitch.nativeElement)) {
            return;
        }
        if ($event.key === 'Enter') {
            this.tryExpand();
        }
    }
    
    public onKeyUp($event: KeyboardEvent) {
        if (!Utils.hasFocus(this.expandSwitch.nativeElement)) {
            return;
        }
        if ($event.key === 'Escape') {
            Utils.preventDefault($event);
            Utils.removeAllFocus();
        }
    }
}
