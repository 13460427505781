import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    HostBinding, HostListener,
    Input, OnDestroy,
    Output,
    Renderer2, ViewChild,
} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {AbstractFormFieldComponent, FormFieldConfigInterface} from '../abstract/abstract-form-field.component';
import {GlobalAlertService} from '../../../../../wrapper/global-alert/global-alert.service';
import {ValidationConstraintService} from '../../services/validation-constraint.service';
import {TooltipService} from '../../../../services/tooltip/tooltip.service';
import {GlobalModel} from '../../../../services/state/global.model';
import {TranslateService} from '../../../../services/translate/translate.service';
import {GlobalEvent} from '../../../../interfaces/global-event';
import Utils from '../../../../utils/utils';
import {Subscription} from 'rxjs';
import { FormEvent } from '../../containers/form/form.interface';
import {LumiSelectOption} from '../../../commonUI/select/lumi-select/lumi-select.interface';
import {LoggerService} from "../../../../services/logger/logger.service";

@Component({
    selector: 'form-select',
    templateUrl: './form-select.component.html',
})
export class FormSelectComponent extends AbstractFormFieldComponent implements AfterViewInit, OnDestroy {
    private static readonly NUM_OPTIONS_FOR_SHOW_FILTER: number = 10;
    @Input() invalidControlsErrors: any;
    @Input() Config: FormSelectConfig;
    @Input() workSpecification: boolean = false;
    @Input() weekprijs: boolean = false;

    @Output() onComponentEvent: EventEmitter<any> = new EventEmitter();
    @HostBinding('class') hostClasses = 'd-flex mt-1';

    @ViewChild('currentFormField', {static: false}) currentFormField: any;

    public config: FormSelectConfig;
    public group: UntypedFormGroup;
    public readOnly: boolean;
    public filteredOptions: any = [];
    public componentId = Utils.getRandomNumber(0, 99999999); // Generate a random id to keep multiple of these multi select components apart
    public _selectedOption?: any = null;

    private readonly subOnGlobalEvent: Subscription;

    @Input() set selectedOption(optionId: number) {
        if (this.config?.options && this.config.options.some(option => option.id === optionId)) {
            this._selectedOption = this.config.options.find(option => option.id === optionId);
        }
    }

    constructor(
        private globalAlertService: GlobalAlertService,
        public cd: ChangeDetectorRef,
        public renderer: Renderer2,
        public validationConstraintService: ValidationConstraintService,
        public tooltipService: TooltipService,
        private model: GlobalModel,
        private ts: TranslateService,
        protected logger:LoggerService
    ) {
        super(renderer, validationConstraintService, tooltipService, logger);

        // Fill with fake group, to support this component outside of a form
        this.group = new UntypedFormGroup({
            fake1: new UntypedFormControl(''),
        });

        this.subOnGlobalEvent = this.model.onGlobalEvent.subscribe((event: GlobalEvent) => {
            // Just before the form is submitted, update the control values
            if (event.type === GlobalEvent.EVENT_PRE_READ_FORM) {
                this.updateFormValue();
            }
        });
    }
    
    ngOnInit() {
        if (this.config === undefined && this.Config) {
            this.config = this.Config;
        }
        if (this.workSpecification) {
            this.hostClasses = 'd-flex mt-1 w-100';
        }
    }
    
    @HostListener('window:click', ['$event'])
    public destroyToolTip(): void {
        this.tooltipService.destroyToolTip(this.currentFormField);
        this.cd.detectChanges();
    }

    ngAfterViewInit() {
        // Handle batchupdate mode
        super.ngAfterViewInit();

        // Trigger a change event for this select (used to trigger the loading of graphs on some specific forms)
        setTimeout(() => {
            this.onComponentEvent.emit({
                event: FormEvent.CHANGE_SELECT,
                data: {name: this.config.name, selectedOption: this._selectedOption ? this._selectedOption.id : -1},
            });
        });

        // Add the empty-value and placeholder
        if (this.config.options) {
            if (this.allowEmptyOption()) {
                this.config.options.unshift({id: undefined, name: '...'});
            }
            if (this.config.placeholder) {
                this.config.options.unshift({id: undefined, name: this.config.placeholder});
            }
        }

        this.extractSelectedItems();

        if (this.config.hidden) {
            this.hostClasses = 'd-none';
        }
    }
    
    public showOptionFilter(): boolean {
        if (this.config) {
            return this.config.options && this.config.options.length >= FormSelectComponent.NUM_OPTIONS_FOR_SHOW_FILTER;
        }
    }

    public getSelectedIdsAsArray(): any|any[] {
        return this._selectedOption;
    }

    private allowEmptyOption(): boolean {

        let result = false;

        if (this.config) {
            // When there is no placeholder, add empty value

            if (!this.config.placeholder) {
                result = true;
            }

            // When not-nullable, don't add an empty value (for batchupdate, when nothing is required,
            // but when you enable a field it cannot be null)
            // TODO: moet dit ook voor required fields, buiten batchupdatemode?
            if (this.config.attr && this.config.attr.nullable === false) {
                result = false;
            }
        }

        return result;

    }

    // Mark objects from the initialvalue as selected in this component
    public extractSelectedItems(): void {
        this._selectedOption = null;

        if (this.config?.options) {
            if (this.config?.initialValue && this.config.options.some(option => option.id === this.config.initialValue)) {
                this._selectedOption = this.config.options.find(option => option.id === this.config.initialValue);
            } else {
                if (this.allowEmptyOption()) {
                    this._selectedOption = this.config.options.find(option => option.id === undefined);
                } else if (this.config?.attr?.nullable === false && this.config.options.length > 0 ) {
                    this._selectedOption = this.config.options[0];
                } else {
                    this._selectedOption = null;
                }
            }
        }
    }

    private updateFormValue(): void {
        if (this.group && this.group.get(this.config.name)) {

            // Convert undefined (for '...') to a decent patch value
            let patchValue: any;
            if (!this._selectedOption || this._selectedOption.id === undefined) {
                patchValue = '';
            } else {
                patchValue = this._selectedOption.id;
            }

            this.group.get(this.config.name).patchValue(patchValue);
        }
    }

    handleChangeOption(event: Event, option: any): void {
        this._selectedOption = option;

        if (this.config && this.config.action) {
            // TODO: dit in een const. En als er meer komen een switch van maken
            if (this.config.action === 'batchupdate') {
                for (let i = 0; i < this.config.options.length; i++) {
                    if (this._selectedOption.id === this.config.options[i].id) {
                        if (this.config.options[i].attr.url !== '') {
                            this.model.onGlobalEvent.next(
                                new GlobalEvent(GlobalEvent.EVENT_LOAD_FORM_REQUEST, {url: this.config.options[i].attr.url})
                            );
                        }
                    }
                }
            }
        }

        this.onComponentEvent.emit({
            event: FormEvent.CHANGE_SELECT,
            data: {
                name: this.config.name,
                selectedOption: this._selectedOption.id,
                selectedOptionLabel: this._selectedOption.name,
            },
        });
        this.cd.detectChanges();
    }

    private getOptionByName(name: string | number | boolean | object): any {
        let match: any = null;
        let option: any;

        for (let i = 0; i <= this.config.options.length - 1; i++) {
            option = this.config.options[i];
            if (option.name === name) {
                match = option;
                break;
            }
        }

        return match;
    }

    handleAdd(suggestion: boolean): void {
        let itemText: string = this.ts.translate('onbekend');
        if (this.config.label) {
            itemText = this.config.label;
        }

        let postPath: string;
        postPath = <string>this.config.attr.creationHref;

        let autoFill = false;
        if (!!this.config.attr.initialPopUpValue) {
            autoFill = true;
        }

        // if value exists select in dropdown instead of trying to add a new one
        if (this.config.attr &&
            this.config.attr.initialPopUpValue &&
            (this.getOptionByName(this.config.attr.initialPopUpValue) !== null) &&
            suggestion
        ) {
            this._selectedOption = this.getOptionByName(this.config.attr.initialPopUpValue);
            return;
        }

            this.globalAlertService.addPopupCreateDropdown(
            this.ts.translate('Item toevoegen aan: ') + itemText,
            '',
            postPath,
            (buttonCode: any, response: any) => {
                // User pressed OK and call succeeded§
                this.logger.log('[FormSelectComponent] ' + 'Gebruiker heeft op OK gedrukt, response:', response);
                if (response) {
                    let action: string = FormEvent.ADD_SUCCESS;
                    this.onComponentEvent.emit({event: action, data: {itemType: itemText}});
    
                    // Update dropdown with new value
                    // Create the new option. Id is a string
                    let newOption: any = {id: '' + response.id, name: response.name};
                    this.config.options.push(newOption);
                    this._selectedOption = newOption;
                    this.logger.log('[FormSelectComponent] ' + 'this.config.options: ', this.config.options);
    
                    // TODO: items are still unsorted here, so the new entry appears at the bottom of the list. Sort it
                    
                    this.cd.detectChanges();
                    this.onComponentEvent.emit({
                        event: FormEvent.CHANGE_SELECT,
                        data: {name: this.config.name, selectedOption: this._selectedOption.id},
                    });
                }
            },
            () => {
                this.logger.log('[FormSelectComponent] ' + 'Gebruiker heeft op ANNULEREN of kruisje gedrukt');
            },
            autoFill ? this.config.attr.initialPopUpValue : ''
        );
    }

    ngOnDestroy() {
        if (this.subOnGlobalEvent) {
            this.subOnGlobalEvent.unsubscribe();
        }
    }
    
    public onOptionsSelect(selectedOption: LumiSelectOption[]): void {
        if (this.config?.options?.length > 0 && this.config.options.some(option => option.id === selectedOption[0].id)) {
            this.handleChangeOption(null, this.config.options.find(option => option.id === selectedOption[0].id));
        }
    }
}

export interface FormSelectConfig extends FormFieldConfigInterface {
    hidden?: boolean
    action?: FormSelectActions;
}

export interface FormSelectOption {
    id: number
    name: string
}

export enum FormSelectActions {
    'batchupdate' = 'batchupdate'
}
