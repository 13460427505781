/**
 * Created by Jasper on 10/12/2019.
 */
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, ViewChild} from '@angular/core';
import {GlobalAlertService} from "../global-alert.service";
import {ButtonCode, GlobalPopup} from '../global-popup';
import {FormDataService} from "../../../shared/components/form/services/form-data.service";
import {TranslateService} from "../../../shared/services/translate/translate.service";
import {StorageService} from '../../../shared/services/storage/storage.service';
import {AbstractBasePopupComponent} from './abstract-base-popup.component';
import {TooltipService} from "../../../shared/services/tooltip/tooltip.service";
import {DefaultPopupWrapperComponent} from "./default-popup-wrapper/default-popup-wrapper.component";
import {LoggerService} from "../../../shared/services/logger/logger.service";

@Component ({
    selector: 'global-popup-browser-warning-component',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
       <!-- <default-popup-wrapper-component *ngIf="!this.supportEnded" #wrapper [hideCloseButton]="true" [componentRef]="this" [globalPopup]="globalPopup" [disableButtonsOverride]="disableButtonsOverride" [title]="'popup-warning.title'| translate " (onClose)="closePopup($event)">
            <div class="d-flex w-100 pb-1">
                <div class="px-0 container-fluid w-100">
                    <div class="pb-3" [innerHTML]="'popup-warning.subtitle' | translate"></div>
                    
                    <div class="grid-container">
                        <div class="browser-container ie-11 mr-1">
                            <div class="images">
                                <div class="img">
                                    <p>Internet Explorer</p>    
                                    <img class="pb-2 pt-2 img-fluid" src="assets/img/browser/ie-11-icon.png"/>
                                    <i class="material-icons clear">clear</i>
                                </div>
                            </div>
                            <div class="functions">
                                <ul>
                                    <li>{{'popup-warning.slowuser' | translate}}</li>
                                    <li>{{'popup-warning.slowpage' | translate}}</li>
                                    <li>{{'popup-warning.lessfeatures' | translate}}</li>
                                    <li>{{'popup-warning.badsecurity' | translate}}</li>
                                    <li class="text-underline" [innerHTML]="'popup-warning.microsoft' | translate"></li>
                                </ul>
                            </div>
                        </div>
                        <div class="browser-container ml-1">
                            <div class="images">
                                <div class="img">
                                    <p>Chrome</p>
                                    <a href="https://www.google.com/chrome/" target="_blank"><img class="pb-2 pt-2 img-fluid" src="assets/img/browser/chrome-icon.png"/></a>
                                    <i class="material-icons done">done</i>
                                </div>
                                <div class="img">
                                    <p>Firefox</p>
                                    <a href="https://www.mozilla.org/en-US/firefox/new/" target="_blank"><img class="pb-2 pt-2 img-fluid" src="assets/img/browser/firefox-icon.png"/></a>
                                    <i class="material-icons done">done</i>
                                </div>
                                <div class="img">
                                    <p>Edge</p>
                                    <a href="https://www.microsoft.com/en-us/windows/microsoft-edge" target="_blank"><img class="pb-2 pt-2 img-fluid" src="assets/img/browser/edge-icon.png"/></a>
                                    <i class="material-icons done">done</i>
                                </div>
                            </div>
                            <div class="pb-2" [innerHTML]="'popup-warning.download' | translate"></div>
                            <div class="functions">
                                <ul>
                                    <li>{{'popup-warning.flexible' | translate}}</li>
                                    <li>{{'popup-warning.faster' | translate}}</li>
                                    <li>{{'popup-warning.features' | translate}}</li>
                                    <li>{{'popup-warning.security' | translate}}</li>
                                </ul>
                            </div>
                            <div class="pb-3" [innerHTML]="'popup-warning.help' | translate"></div>
                        </div>
                    </div>
                </div>
            </div>
        </default-popup-wrapper-component>-->

        <default-popup-wrapper-component #wrapper [hideCloseButton]="true" [componentRef]="this" [globalPopup]="globalPopup" [disableButtonsOverride]="disableButtonsOverride" [title]="'popup-warning-ended.title'| translate " (onClose)="closePopup($event)">
            <div class="d-flex w-100 pb-1">
                <div class="px-0 container-fluid w-100">
                    <div class="pb-3" [innerHTML]="'popup-warning-ended.subtitle' | translate"></div>
                        <div class="">
                            <div class="images justify-content-center">
                                <div class="img m-0">
                                    <p>Chrome</p>
                                    <a href="https://www.google.com/chrome/" target="_blank"><img class="pb-2 pt-2 img-fluid" src="assets/img/browser/chrome-icon.png"/></a>
                                    <i class="material-icons done">done</i>
                                </div>
                                <div class="img m-0">
                                    <p>Firefox</p>
                                    <a href="https://www.mozilla.org/en-US/firefox/new/" target="_blank"><img class="pb-2 pt-2 img-fluid" src="assets/img/browser/firefox-icon.png"/></a>
                                    <i class="material-icons done">done</i>
                                </div>
                                <div class="img m-0">
                                    <p>Edge</p>
                                    <a href="https://www.microsoft.com/en-us/windows/microsoft-edge" target="_blank"><img class="pb-2 pt-2 img-fluid" src="assets/img/browser/edge-icon.png"/></a>
                                    <i class="material-icons done">done</i>
                                </div>
                            </div>
                            <!--<div class="pb-2" [innerHTML]="'popup-warning.download' | translate"></div>-->
                            <!--<div class="pb-3" [innerHTML]="'popup-warning-ended.recommended' | translate"></div>-->
                            <div class="pb-3 text-center" [innerHTML]="'popup-warning.download' | translate"></div>
                            <div class="pb-3" [innerHTML]="'popup-warning.help' | translate"></div>
                        </div>

                    <!-- TODO: zet aan als  echt weg moet -->
                    <!--<form class="p-2 mt-3 d-flex warning-terms">
                        <div class="map-option-label"><b>{{"warning.terms" | translate}}</b></div>
                        <div class="switch">
                            <label>
                                <input type="checkbox" [checked]="acceptTerms" (change)="handleToggleTerms($event)">
                                <span class="lever"></span>
                            </label>
                        </div>
                    </form>-->
                </div>
            </div>
        </default-popup-wrapper-component>
    `
})

export class GlobalPopupBrowserWarningComponent extends AbstractBasePopupComponent
{
    @ViewChild('wrapper', {static: false}) wrapper: DefaultPopupWrapperComponent;

    private acceptTerms:boolean = true;
    public disableButtonsOverride:any = (button:any) => {return button.code == ButtonCode.ANNULEREN && !this.acceptTerms};

    public constructor(protected cd:ChangeDetectorRef, protected globalAlertService:GlobalAlertService, protected formDataService:FormDataService, protected elementRef:ElementRef, protected ts:TranslateService, protected storageService:StorageService, protected tooltipService:TooltipService, protected logger:LoggerService) {
        super(cd, globalAlertService, elementRef, ts, tooltipService, logger);
    }

    private handleToggleTerms(e:any):void{
        this.acceptTerms = !this.acceptTerms;
        this.wrapper.runChangeDetection();
        this.cd.detectChanges();
    }

    public closePopup(event:any):void
    {
        if (this.acceptTerms){
            this.doDefaultCloseHandling(event, false);
        }
    }

    handlePopupAction(event: MouseEvent, alert: GlobalPopup, button: any): void {
        this.onPopupAction.emit({event:event, alert:alert, button:button});
    }
}
