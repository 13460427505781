import {Input, ChangeDetectorRef, Component} from '@angular/core';
import {Widget} from "./widget";
import {GlobalAlertService} from "../../../wrapper/global-alert/global-alert.service";
import {StorageService} from "../../../shared/services/storage/storage.service";
import {LoggerService} from "../../../shared/services/logger/logger.service";

@Component({
    selector: 'base-widget',
    template: ''
})
export class BaseWidgetComponent
{
    @Input('widget') public widget: Widget;

    public widgetOptions:any = [];
    private static allStoredWidgetParams:any;

    constructor(protected globalAlertService: GlobalAlertService, protected cd:ChangeDetectorRef, protected storage:StorageService, protected logger:LoggerService) {
        if (BaseWidgetComponent.allStoredWidgetParams == null){
            this.getAllStoredWidgetParams();
        }
    }

    //Check if key is active in an option, if not, make active in the first option
     private checkKeyActive(key:string){

         //TODO: Als er twee geselecteerd zijn, selecteerde de eerste ofzo, een van beide

         //Search for an active option of the type-key
         for(let i = 0; i < this.widgetOptions.length; i++) {
             if (this.widgetOptions[i].paramKey == key){
                 if (this.widgetOptions[i].active){
                     //Active key found. This option is activated already, do nothing
                     return;
                 }
             }
         }

         //No active option for this key found, activate the first instance of the key
         for(let i = 0; i < this.widgetOptions.length; i++) {
             if (this.widgetOptions[i].paramKey == key){
                 this.widgetOptions[i].active = true;
                 return;
             }
         }
     }

     //Process new options (e.g. add separators)
     public applyWidgetOptions(options:any)
     {
         if (options){
             this.widgetOptions = options;

             let lastKey: any = "";

             //Add separator before every key, except the first
             for(let i = 0; i < this.widgetOptions.length; i++) {
                 if (lastKey != this.widgetOptions[i].paramKey) {

                     //Skip first key
                    if (lastKey != ""){
                        this.widgetOptions.splice(i, 0, {label: "", paramKey: "", paramValue: ""});
                    }

                    lastKey = this.widgetOptions[i].paramKey;
                 }
             }
         }
     }

     //Activate the first option-instance of every key
     protected activateFirstOptions()
     {
         let lastKey: any = "";

         this.widgetOptions.forEach((option: any) =>
         {
             if (lastKey == "" || lastKey != option.paramKey) {
                 lastKey = option.paramKey;
                 option.active = true;
             }
         });
     }

    private buildWidgetParams()
    {
        //Activate first option of every key, if none is selected
        let lastKey:any = "";
        this.widgetOptions.forEach((option:any) => {

            if (lastKey == "" || lastKey != option.paramKey){
                lastKey = option.paramKey;
                this.checkKeyActive(lastKey);
            }
        });

        //Build the params based on the active options
        let result = "";
        this.widgetOptions.forEach((option:any) => {

            if (option.active && option.paramKey !== 'toExcel'){

                if (result == ""){
                    result += "?";
                }else{
                    result += "&";
                }

                    result += (option.paramKey + "=" + option.paramValue);

            }
        });

        return result;
    }

    //Look for stored widget params or return empty string
    public getStoredWidgetParams(id:number){

        if (BaseWidgetComponent.allStoredWidgetParams.params && BaseWidgetComponent.allStoredWidgetParams.params.length > 0){

            for(let i = 0; i < BaseWidgetComponent.allStoredWidgetParams.params.length; i++) {
                if (BaseWidgetComponent.allStoredWidgetParams.params[i].id == id){
                    this.logger.log("[WidgetComponent] " + "match found in stored widget params: ", BaseWidgetComponent.allStoredWidgetParams.params[i]);
                    return BaseWidgetComponent.allStoredWidgetParams.params[i].params;
                }
            }

        }

        this.logger.log("[BaseWidgetComponent] " + "no match found with stored widget params");

        return "";
    }

    //Update a matching row in stored widget params, or create a new row
    public updateStoredWidgetParams(id:number, newParams:string) {

        if (BaseWidgetComponent.allStoredWidgetParams.params){

            //Look for existing
            for (let i = 0; i < BaseWidgetComponent.allStoredWidgetParams.params.length; i++) {
                if (BaseWidgetComponent.allStoredWidgetParams.params[i].id == id) {
                    BaseWidgetComponent.allStoredWidgetParams.params[i].params = newParams;
                    return;
                }
            }

            //No existing, create new
            BaseWidgetComponent.allStoredWidgetParams.params.push({id: id, params: newParams});
        }
    }

    //Get the full widgets settings object, for all widgets
    private getAllStoredWidgetParams()
    {
        //Set base value
        BaseWidgetComponent.allStoredWidgetParams = {params:[]};

        //Read from storage or keep base value
        this.storage.getStringValue(StorageService.KEY_WIDGET_PARAMS, (value: any) => {
            BaseWidgetComponent.allStoredWidgetParams = JSON.parse(value);
        });
    }

    //Activate options by the params from the url
    protected activateOptionsByParams(params:string)
    {
        let paramKeyValueParts:any[];

        //Remove the '?'
        params = params.substr(1);

        //Split in param parts
        let paramParts:any[] = params.split("&");
        paramParts.forEach((part:any)=>
        {
            //Split key and value
            paramKeyValueParts = part.split("=");
            this.activateOption(paramKeyValueParts[0], paramKeyValueParts[1]);
        })
    }

    //Handle widget-specific client-side actions after activating an option
    protected handleActivateOption(key:string, value:string){
        //OVERRIDE THIS
    }

    //Handle widget-specific actions after clicking an option (like reloading widget data)
    protected handleClickOption(key:string, value:string){
        //OVERRIDE THIS
    }

    //Deactivate all same-key values and activate exact match
    private activateOption(key:string, value:string)
    {
        let option:any;
        for(let i = 0; i < this.widgetOptions.length; i++) {
            option = this.widgetOptions[i];
            if (option.paramKey === key && value === 'toExcel') {
                option.active = false;
            } else if (option.paramKey === key){
                if (option.paramValue === value){
                    // Activate exact match
                    option.active = true;
                }else{
                    // Deactivate same key
                    option.active = false;
                }
            }
        }

        this.handleActivateOption(key, value)
    }

    //For child class, get params from activated options or from stored params
    protected getWidgetParams():string{

        let result = "";

        //If widget options are present
        if (this.widgetOptions.length > 0)
        {
            this.logger.log("[BaseWidgetComponent] " + "build widget params by options: " , this.widgetOptions);
            //Build widget params based on selected items
            result = this.buildWidgetParams();
        }else{
            //When no widget options are present, try to fetch them from the local storage
            result = this.getStoredWidgetParams(this.widget.id);
        }

        return result;
    }

    //For child classes, update this widget settings and store total settings
    protected storeWidgetParams(id:number, params:string){
        //Store widgetparams on client (store new or override old with old)
        if (!params.includes('toExcel')) {
            this.updateStoredWidgetParams(id, params);
            this.storage.setValue(StorageService.KEY_WIDGET_PARAMS, JSON.stringify(BaseWidgetComponent.allStoredWidgetParams));
        }
    }

    //Click handler for clicking an option. Use in child classes
    public handleClickWidgetOption(option:any)
    {
        this.logger.log("[BaseWidgetComponent] " + "Click widget option: ", option);

        //Activate the clicked option and deactivated all similar keys
        this.activateOption(option.paramKey, option.paramValue);

        //Handle widget-specific actions after clicking an option (like reloading widget data)
        if(option.paramKey !== 'toExcel'){
            this.handleClickOption(option.paramKey, option.paramValue);
        }
    }
}
