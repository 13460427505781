/**
 * Created by Christiaan on 07/04/2017.
 */

import {Directive, NgZone, Output, EventEmitter} from '@angular/core';

@Directive({
    selector: '[globalKeydownOutsideAngular]'
})

export class GlobalKeydownOutsideAngularDirective
{
    private static readonly EVENT_KEY_DOWN:string = "keydown";

    @Output('globalKeydownOutsideAngular') keydownHandler: EventEmitter<any> = new EventEmitter();

    constructor(private ngZone:NgZone)
    {
        this.ngZone.runOutsideAngular(() => {
            window.addEventListener(GlobalKeydownOutsideAngularDirective.EVENT_KEY_DOWN, (event:any)=> {
                this.keydownHandler.emit(event);
            })
        });
    }

    //Alternatieve aanpak via hostlistener:
    /*
    @HostListener('window:keydown', ['$event'])

    handleKeyDown(event: Event) {
        this.keydownHandler.emit(event);
    }

    */
}
