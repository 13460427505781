/**
 * Created by Christiaan on 15/03/2017.
 */
import {LOCALE_ID, NgModule} from '@angular/core';
import {CommonModule}  from '@angular/common';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {LoginComponent}  from './login/login.component';
import {LoginHeaderComponent}  from './login/shared/login-header.component';
import {LoginFooterComponent}  from './login/shared/login-footer.component';
import {LoginNotifyComponent}  from './login/shared/login-notify.component';
import {LoginNotifyService} from './login/shared/login-notify.service';
import {PageNotFoundComponent} from './page-not-found/page-not-found.component';
import {ForbiddenComponent} from './forbidden/forbidden.component';
import {GlobalAlertComponent} from './global-alert/global-alert.component';
import {LoadAreaalComponent} from './load-areaal/load-areaal.component';
import {PasswordForgetComponent} from './login/password/password-forget.component';
import {PasswordNewComponent} from './login/password/password-new.component';
import {UserInviteComponent} from './user-invite/user-invite-component';
import {AlertsModule} from './global-alert/alerts/alerts.module';
import {CommonPipesModule} from '../shared/pipes/common-pipes.module';
import {CommonDirectivesModule} from '../shared/directives/common/common-directives.module';
import {TwoFactorAuthComponent} from './login/two-factor-auth/two-factor-auth.component';
import {CommonUIModule} from '../shared/components/commonUI/common-ui.module';
import {TranslateService} from '../shared/services/translate/translate.service';

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        ReactiveFormsModule,
        AlertsModule,
        CommonPipesModule,
        CommonDirectivesModule,
        CommonUIModule,
        FormsModule
    ],
    exports: [
        LoginComponent,
        LoginHeaderComponent,
        LoginFooterComponent,
        LoginNotifyComponent,
        PasswordForgetComponent,
        PasswordNewComponent,
        UserInviteComponent,
        TwoFactorAuthComponent,
        PageNotFoundComponent,
        ForbiddenComponent,
        LoadAreaalComponent,
        GlobalAlertComponent,
    ],
    declarations: [
        LoginComponent,
        LoginHeaderComponent,
        LoginFooterComponent,
        LoginNotifyComponent,
        PasswordForgetComponent,
        PasswordNewComponent,
        UserInviteComponent,
        TwoFactorAuthComponent,
        PageNotFoundComponent,
        ForbiddenComponent,
        LoadAreaalComponent,
        GlobalAlertComponent,
    ],
    providers: [
        LoginNotifyService,
        {
            provide: LOCALE_ID,
            useValue: TranslateService.LOCALE
        }
    ]
})

export class WrapperModule {
    constructor() {
    }
}
