import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ButtonRank, ButtonSize} from './lumi-button.interface';
import Utils from '../../../../utils/utils';

@Component({
    selector: 'lumi-button',
    templateUrl: './lumi-button.component.html'
})
export class LumiButtonComponent implements OnInit {
    @Input() rank: 'primary' | 'secondary' | 'tertiary' | 'input' | ButtonRank = ButtonRank.PRIMARY;
    @Input() icon: string;
    @Input() label: string;
    @Input() title: string;
    @Input() disabled: boolean = false;
    @Input() fullWidth: boolean = false;
    @Input() active: boolean = false;
    @Input() size: 'small' | 'medium' | 'large' | ButtonSize = ButtonSize.MEDIUM;
    @Input() centerLabel: boolean = true;
    
    @Output() onClick: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();
    @ViewChild('button', {static: false}) button: ElementRef<HTMLButtonElement>;
    
    constructor() {
    }
    
    ngOnInit() {
    }
    
    public getClass(): string {
        return `lumi-button-${this.rank} ${this.active ? 'active' : ''} ${this.disabled ? 'disabled' : ''}`;
    }
    
    public emitClick($event: MouseEvent) {
        this.onClick.emit($event);
    }
    
    public getStyle(): { [key: string]: string } {
        let styleObject = {
            width: 'fit-content',
            height: 'fit-content',
            'justify-content': 'center'
        };
        if (this.fullWidth) {
            styleObject.width = '100%';
        }
        if (this.size === ButtonSize.SMALL) {
            if (!this.label) {
                styleObject.width = '1.6rem';
            }
            styleObject.height = '1.6rem';
        }
        if (this.size === ButtonSize.LARGE) {
            if (this.label) {
                styleObject['text-transform'] = 'uppercase';
                styleObject['padding'] = '1rem';
                styleObject['padding-left'] = '0.5rem';
            }
        }
        if (!this.centerLabel) {
            styleObject['justify-content'] = 'left';
        }
        return styleObject;
    }
    
    public getIconClass(): string {
        return `lumi-button-icon-${this.rank}${this.size === ButtonSize.SMALL ? '-small' : ''}`;
    }
    
    public onKeyUp($event: KeyboardEvent) {
        if (!Utils.hasFocus(this.button.nativeElement)) {
            return;
        }
        if ($event.key === 'Escape') {
            Utils.preventDefault($event);
            Utils.removeAllFocus();
        }
    }
}
