import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CommonPipesModule} from '../../../pipes/common-pipes.module';
import {FilterableTableComponent} from './filterableTable.component';
import {BaseTableModule} from '../shared/baseTable/baseTable.module';

@NgModule({
    imports: [CommonPipesModule, CommonModule, BaseTableModule],
    exports: [FilterableTableComponent],
    declarations: [
        FilterableTableComponent
    ]
})
export class FilterableTableModule {
}
