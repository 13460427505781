import {ChangeDetectorRef, Component, EventEmitter, Output, Renderer2, ViewChild} from '@angular/core';
import {AbstractFormFieldComponent} from '../abstract/abstract-form-field.component';
import {ValidationConstraintService} from '../../services/validation-constraint.service';
import {TooltipService} from '../../../../services/tooltip/tooltip.service';
import {GlobalModel} from '../../../../services/state/global.model';
import {User} from '../../../../../wrapper/login/user';
import {FormDataService} from '../../services/form-data.service';
import {GlobalAlertService} from '../../../../../wrapper/global-alert/global-alert.service';
import {TranslateService} from '../../../../services/translate/translate.service';
import { FormEvent } from '../../containers/form/form.interface';
import {ListItem, ListItemMenuItemEvent} from '../../../commonUI/list/complex-list-item/complex-list-item.interface';
import {Comment} from './form-comment.interface';
import { AppSettings } from 'src/app/app.settings';
import {ButtonCode} from '../../../../../wrapper/global-alert/global-popup';
import {LoggerService} from "../../../../services/logger/logger.service";

@Component({
    selector: 'form-comment',
    templateUrl: './form-comment.component.html'
})
export class FormCommentComponent extends AbstractFormFieldComponent {
    private static readonly NEW_MESSAGE_MAX_CHARS: number = 1500;
    private static readonly COMMENT_CREATE_PATH: string = 'components/comments/create/';

    @ViewChild('messageInput', {static: false}) messageInput: any;
    @Output() onComponentEvent: EventEmitter<any> = new EventEmitter();

    public comments: Comment[] = [];
    public isLoading: boolean = false;

    public AppSettings = AppSettings;
    
    constructor(
        public renderer: Renderer2,
        public validationConstraintService: ValidationConstraintService,
        public tooltipService: TooltipService,
        private model: GlobalModel,
        private formDataService: FormDataService,
        private cd: ChangeDetectorRef,
        private globalAlertService: GlobalAlertService,
        private ts: TranslateService,
        protected logger:LoggerService
    ) {
        super(renderer, validationConstraintService, tooltipService, logger);
    }

    ngOnInit() {
        // Set the logs for the view
        if (this.config && this.config.attr && this.config.attr.comments) {
            this.comments = this.config.attr.comments.map(comment => {
                comment.listItem = this.getListItem(comment);
                return comment;
            });
        }
    }

    public getCharacterCount(): string {
        return (this.messageInput ? this.messageInput.nativeElement.value.length : 0) + '/' + FormCommentComponent.NEW_MESSAGE_MAX_CHARS;
    }

    public isDeletable(comment: any): boolean {
        return comment.deleteUrl && comment.deleteUrl != '';
    }

    public handleClickDeleteComment(event: MouseEvent, comment: any): void {
        this.globalAlertService.addPopup(this.ts.translate('comment.deletetitle'), this.ts.translate('comment.delete'), [{label: this.ts.translate('Annuleren'), code: ButtonCode.ANNULEREN, isPrimary: true},
            {label: this.ts.translate('Verwijderen'), code: ButtonCode.DELETE,
                callback: () => {

                    // Show grayed-out comment until delete call finished
                    comment.isDeleted = true;
                    this.cd.detectChanges();

                    this.formDataService.deleteComment(comment.deleteUrl,
                        () => {
                            // Remove the old comment, client side
                            let deleteIndex: number = this.comments.indexOf(comment);

                            if (deleteIndex > -1) {
                                this.comments.splice(deleteIndex, 1);
                                this.cd.detectChanges();
                            }

                            this.onComponentEvent.emit({event: FormEvent.COMMENT_DELETE_SUCCESS, data: {}});
                        }, () => {
                            comment.isDeleted = false;
                            this.cd.detectChanges();
                        }, () => {
                            comment.isDeleted = false;
                            this.cd.detectChanges();
                        });
                },
                isPrimary: false}, ], () => {});
    }

    public handleClickAddComment(event: MouseEvent): void {
        if (this.isLoading) {
            return;
        }

        let baseObjectId: number = this.rootConfig.base_object_id;
        let message: string = this.messageInput.nativeElement.value;

        this.tooltipService.destroyToolTip(this.messageInput);
        if (message.length <= 0) {
            this.tooltipService.createAndShowTooltip(this.renderer, this.messageInput, this.ts.translate('comment.empty'), TooltipService.PLACEMENT_BOTTOM, TooltipService.TOOLTIP_MODE_MANUAL, '', true);
            return;
        }

        this.isLoading = true;

        this.formDataService.addComment(FormCommentComponent.COMMENT_CREATE_PATH + baseObjectId, message,
            (data: any) => {
                // Clear the input
                this.messageInput.nativeElement.value = '';

                // Trigger animation + cleanup
                data.isNew = true;
                setTimeout(() => {data.isNew = false; this.cd.detectChanges(); }, 1000);

                // Add the new comment at the beginning of the array
                data.listItem = this.getListItem(data);
                this.comments.unshift(data);

                // Enable the input again
                this.isLoading = false;

                // Update the view
                this.cd.detectChanges();

                this.onComponentEvent.emit({event: FormEvent.COMMENT_ADD_SUCCESS, data: {}});
            },
            () => {
                this.isLoading = false;
                this.cd.detectChanges();
            },
            () => {
                this.isLoading = false;
                this.cd.detectChanges();
            });
    }

    public getUserName(): string {
        let user: User = this.model.user.getValue();
        return user.surname + ', ' + user.firstName;
    }

    get NEW_MESSAGE_MAX_CHARS(): number {
        return FormCommentComponent.NEW_MESSAGE_MAX_CHARS;
    }
    
    private getListItem(comment: Comment): ListItem {
        const listItem: ListItem = {
            id: JSON.stringify(comment),
            title: comment.name,
            subtitle: comment.created,
            icon: 'message'
        };
        if (this.isDeletable(comment)) {
            listItem.menuItems = [{
                label: this.ts.translate('menuItem.delete'),
                icon: 'delete',
                action: 'delete'
            }];
        }
        
        return listItem;
    }
    
    public onCommentAction($event: ListItemMenuItemEvent, comment: any) {
        if ($event.action === 'delete') {
            this.handleClickDeleteComment(null, comment);
        }
    }
}
