import {Injectable} from '@angular/core';
import {HttpService2} from '../../../services/http-service-2.0/http2.0.service';
import {asapScheduler, Observable, of, scheduled, throwError} from 'rxjs';
import {catchError, map, mergeMap, switchMap, take, toArray} from 'rxjs/operators';
import xml2js from 'xml2js';
import {MapServer, MapServerLayer, MapServerWithLayers} from './map-server.interface';
import {StorageService} from '../../../services/storage/storage.service';
import {GlobalModel} from '../../../services/state/global.model';
import {ServerType, ServerTypeVersion} from '../../../utils/map-wmslayer-generator';
import {LoggerService} from "../../../services/logger/logger.service";

@Injectable({
    providedIn: 'root'
})
export class MapServerService {

    public layersCache:any = {};

    constructor(private httpService: HttpService2, private storage:StorageService, private model:GlobalModel, protected logger:LoggerService) {
    }

    public getMapServers(areaalId: number): Observable<MapServer[]> {
        return this.httpService.doGetRequest(`a/${areaalId}/general/map-servers/get-servers`, false).pipe(
            map(result => (<MapServer[]>result)
                .map((server) => {
                    server.active = server.defaultEnabled;

                    this.storage.getBooleanValue(`${StorageService.KEY_MAP_SINGLE_WMS_ENABLED}${server.id}`, (value) => {
                        server.active = value;
                    });
                    return server;
                })
            )
        );
    }

    public getMapServersWithLayers(areaalId: number): Observable<MapServerWithLayers[]> {
        return this.getMapServers(areaalId).pipe(
            take(1),
            switchMap((servers: MapServer[]) => {
                return scheduled(servers, asapScheduler)
            }),
            mergeMap((server: MapServer) => {
                if (!server.disabled) {
                    return this.getMapServerLayers(server).pipe(
                        catchError((err) => {
                            return of({error: {status: err.status, url: err.url, name:err.name}})
                        }),
                        map((layers: MapServerLayer[] | any) => {
                            if (layers.error?.status === 404 && layers.error?.name === 'HttpErrorResponse') {
                                return {
                                    id: server.id,
                                    name: server.name,
                                    layers: [],
                                    url: server.url,
                                    layerIds: server.layerIds,
                                    active: server.active,
                                    disabled: true
                                };
                            }
                            const mapServerWithLayers: MapServerWithLayers = {
                                id: server.id,
                                name: server.name,
                                layers: layers.filter(layer => {
                                    return server.layerIds.some(id => id === layer.id);
                                }),
                                url: server.url,
                                layerIds: server.layerIds,
                                active: server.active,
                                disabled: server.disabled
                            };
                            return mapServerWithLayers;
                        })
                    );
                } else {
                    return of({
                        id: server.id,
                        name: server.name,
                        layers: [],
                        url: server.url,
                        layerIds: server.layerIds,
                        active: server.active,
                        disabled: server.disabled
                    });
                }
            }),
            toArray(),
        )
    }

    private getActualLayersRecursively(Layer) {
        if (Layer.Layer) {
            return this.getActualLayersRecursively(Layer.Layer)
        } else if (Layer.length > 0) {
            return Layer.reduce((_layers, layer) => {
                _layers = _layers.concat(this.getActualLayersRecursively(layer))
                return _layers;
            }, []);
        } else {
            return Layer;
        }
    }

    public getMapServerLayers(server): Observable<MapServerLayer[]> {
        if (server.serverType.includes(ServerType.WMS)) {
            if (server.serverType.includes(ServerTypeVersion.VERSION_1_3_0)) {
                if (this.layersCache[JSON.stringify(server)]) {
                    return scheduled([this.layersCache[JSON.stringify(server)]], asapScheduler)
                }
                return this.getMapServerLayersWMS(server.url).pipe(
                    take(1),
                    map((response: any) => {
                        let parsedResponse;
                        xml2js.parseString(response, (err, result) => {
                            parsedResponse = result;
                            if (err !== null) {
                                this.logger.log('[MapServerService] Error parsing data: ', err);
                            }
                        });
                        const layers: MapServerLayer[] = [];
                        this.getActualLayersRecursively(parsedResponse.WMS_Capabilities.Capability[0].Layer[0]).forEach((layer: any) => {
                            layers.push({
                                id: layer.Name[0],
                                name: layer.Title[0],
                            });
                        });
                        this.layersCache[JSON.stringify(server)] = layers;
                        return layers;
                    })
                );
            } else {
                return throwError('version of WMS not supported yet: ' + server)
            }
        } else {
            return throwError('type of server not supported yet: ' + server)
        }
    }

    private getMapServerLayersWMS(serverUrl: string, serverApiKey?: string, serverUsername?: string, ServerPassword?: string): Observable<any> {
        let param = serverUrl.includes('?') ? '&' : '?';
        return this.httpService.doExternalGetRequest(`${serverUrl}${param}request=GetCapabilities&service=WMS`, 'text');
    }

    public checkWMSConnection(serverUrl: string): Observable<boolean> {
        let param = serverUrl.includes('?') ? '&' : '?';
        return this.httpService.doExternalGetRequest(`${serverUrl}${param}request=GetCapabilities&service=WMS`, 'text').pipe(
            map((result: any) => {
                // TODO: Meer checks op de XML WMS connectie
                return !!result.includes('Layer');
            }),
            catchError(
                (result) => {
                    return of(false);
                }
            )
        );
    }
}
