import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component, ElementRef
} from '@angular/core';

import {GlobalAlertService} from '../../global-alert.service';
import {ButtonInterface, GlobalPopup} from '../../global-popup';
import {TranslateService} from '../../../../shared/services/translate/translate.service';
import {AbstractBasePopupComponent} from '../abstract-base-popup.component';
import {TooltipService} from '../../../../shared/services/tooltip/tooltip.service';
import {LoggerService} from "../../../../shared/services/logger/logger.service";

@Component({
    selector: 'global-popup-request-refresh-component',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: `./global-popup-request-refresh.component.html`
})
export class GlobalPopupRequestRefreshComponent extends AbstractBasePopupComponent {

    public constructor(
        protected cd: ChangeDetectorRef,
        protected elementRef: ElementRef,
        protected globalAlertService: GlobalAlertService,
        protected ts: TranslateService,
        protected tooltipService: TooltipService,
        protected logger:LoggerService
    ) {
        super(cd, globalAlertService, elementRef, ts, tooltipService, logger);
    }

    closePopup(event: any): void {
        this.doDefaultCloseHandling(event, false);
    }

    handlePopupAction(event: MouseEvent, alert: GlobalPopup, button: ButtonInterface): void {
        // Hide alert and perform callback of button
        this.onPopupAction.emit({event: event, alert: alert, button: button, data: false});
    }
}
