<default-popup-wrapper-component [globalPopup]="globalPopup" [componentRef]="this" [showLoader]="isLoading"
                                 [title]="model.notificationModalList[0].title"
                                 (onClose)="closePopup($event)">
    <div class="notifications-modal">
        <div class="notification-modal-item" *ngFor="let notification of model.notificationModalList;let i = index" [ngClass]="{'first-item':i === 0}">
            <div class="date">{{notification.date | date : 'MMM dd, YYYY HH:mm':'':'nl-NL'}}</div>
            <div [innerHTML]="notification.message"></div>
        </div>
    </div>
</default-popup-wrapper-component>
