import {ChangeDetectorRef, Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormGroupComponent} from '../form-group.component';
import {StatusButtonAction, StatusButtonItem} from '../../../../status/status-button/status-button.interface';
import {ConfigType} from '../../field/fieldDirective.interface';
import {FormEvent} from '../../../containers/form/form.interface';
import {StorageService} from '../../../../../services/storage/storage.service';
import {GlobalModel} from '../../../../../services/state/global.model';
import {SubStatusItem} from '../../../../status/sub-statuses/sub-statuses.interface';
import {
    FormGroupStatus,
    FormGroupWithStatus,
    FormGroupWithStatusConfig,
    StatusChangeRequestsHttpResult,
    StatusConfig,
    StatusConfigAction,
    StatusConfigOptions
} from './form-group-with-status.interface';
import {HttpService2} from '../../../../../services/http-service-2.0/http2.0.service';
import {map, take} from 'rxjs/operators';
import {GlobalAlertService} from '../../../../../../wrapper/global-alert/global-alert.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {ButtonCode, PopupResult, PopupResultCode} from '../../../../../../wrapper/global-alert/global-popup';
import {TranslateService} from '../../../../../services/translate/translate.service';
import {StatusItem} from '../../../../status/status-progress/status-progress.interface';
import {StatusService} from '../../../../status/status.service';

@Component({
    selector: 'form-group-with-status',
    templateUrl: './form-group-with-status.component.html',
})
export class FormGroupWithStatusComponent extends FormGroupComponent implements OnInit {
    @Output() onComponentEvent: EventEmitter<any> = new EventEmitter();
    
    public formGroup: FormGroupWithStatus;
    public buttonItems: StatusButtonItem[];
    public buttonActions: StatusButtonAction[];
    public config: FormGroupWithStatusConfig;
    public isStatusButtonDisabled: boolean = false;
    public subStatuses: SubStatusItem[];
    public isArchived: boolean = false;
    
    private statusConfig: StatusConfig;
    
    constructor(
        public cd: ChangeDetectorRef,
        public storage: StorageService,
        public model: GlobalModel,
        private httpService: HttpService2,
        private alertService: GlobalAlertService,
        private translateService: TranslateService,
        private statusService: StatusService
    ) {
        super(cd, storage, model);
    }
    
    ngOnInit() {
        super.ngOnInit();
        this.isArchived = this.config?.attr?.archived;
        this.statusConfig = this.config.children.find(childConfig =>
            childConfig.type === ConfigType.status
        ) as StatusConfig;
        
        if (this.statusConfig && this.statusConfig.options) {
            this.isStatusButtonDisabled = this.config.disabled || this.statusConfig.disabled;
            this.formGroup = {
                statusItems: this.statusConfig.options.map((configOption: StatusConfigOptions) => {
                    return {
                        baseStatus: configOption.attr.baseStatus,
                        disabled: configOption.attr.disabled,
                        id: configOption.id,
                        isActive: configOption.attr.isActive,
                        isFinished: configOption.attr.isFinished,
                        label: configOption.name,
                        additionalStatusInfo: configOption.attr.additionalStatusInfo
                    };
                })
            };
            
            this.form.controls[this.statusConfig.name].setValue(
                this.formGroup.statusItems.find(statusItem => statusItem.isActive) ?
                    this.formGroup.statusItems.find(statusItem => statusItem.isActive).id :
                    null
            );
        }
        
        if (this.formGroup) {
            if (this.statusConfig?.attr?.actions?.length > 0) {
                this.buttonActions = this.statusConfig.attr.actions.map((action: StatusConfigAction) => {
                    return {
                        id: action.id,
                        label: action.label,
                        icon: action.icon
                    };
                });
            }
            this.setButtonItems();
        }
    }
    
    public setStatusActive(status: FormGroupStatus): void {
        let currentIndex = this.formGroup.statusItems.indexOf(status);
        this.formGroup.statusItems.forEach((_status, index) => {
            _status.isActive = false;
            if (index < currentIndex) {
                _status.isFinished = true;
            }
            if (index > currentIndex) {
                _status.isFinished = false;
            }
        });
        status.isActive = true;
    
        if (this.form) {
            this.form.controls[this.statusConfig.name].setValue(status.id);
        }

        this.onComponentEvent.emit({event: FormEvent.SAVE_SUCCESS});
    }
    
    public getActiveStatus(): FormGroupStatus {
        return this.formGroup.statusItems.find(status => status.isActive);
    }
    
    public handleActionClick(buttonAction: StatusButtonAction): void {
        const action = this.statusConfig.attr.actions.find(_action => _action.id === buttonAction.id);
        const url = `${action.action}/${this.config.base_object_id}`;
        this.httpService.doPostRequest(url, {} as JSON).pipe(
            take(1)
        ).subscribe(() => {
            this.onComponentEvent.emit({event: FormEvent.SAVE_SUCCESS});
        });
    }
    
    public handleSelectionChange(buttonItem: StatusButtonItem): void {
        this.showWarning(buttonItem).pipe(
            take(2)
        ).subscribe(warningResult => {
            if (warningResult) {
                if (this.statusConfig.action) {
                    const url = `${this.statusConfig.action}/${buttonItem.id}`;
                    this.statusService.getStatusChangeRequests(url).pipe(
                        take(1)
                    ).subscribe((result: StatusChangeRequestsHttpResult) => {
                        if (result.schema) {
                            this.alertService.openPopupBasicForm(
                                result,
                                url,
                                this.translateService.translate('status-change-request-popup.title', [buttonItem.label]),
                                this.translateService.translate('status-change-request-popup.label')
                            ).pipe(
                                take(2)
                            ).subscribe((popupResult: PopupResult) => {
                                if (popupResult?.code === PopupResultCode.OK) {
                                    this.setStatusActive(
                                        this.formGroup.statusItems.find(item => item.id === buttonItem.id));
                                }
                            });
                        } else {
                            this.setStatusActive(
                                this.formGroup.statusItems.find(item => item.id === buttonItem.id));
                        }
                    });
                } else {
                    this.setStatusActive(
                        this.formGroup.statusItems.find(item => item.id === buttonItem.id));
                }
            }
        });
    }
    
    private setButtonItems(): void {
        this.buttonItems = this.formGroup.statusItems.map(item => {
            return {
                id: item.id,
                label: item.label,
                isActive: item.isActive,
                isFinished: item.isFinished,
                isDisabled: item.disabled
            };
        });
    }
    
    public handleStatusProgressClick(statusItem: StatusItem) {
        const buttonItem: StatusButtonItem = this.buttonItems.find(item => item.id === statusItem.id);
        if (!buttonItem.isDisabled) {
            this.handleSelectionChange(buttonItem);
        }
    }
    
    private showWarning(buttonItem: StatusButtonItem): Observable<boolean> {
        const warningPopupResult: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
        const currentActiveButtonIndex: number = this.buttonItems.findIndex(_buttonItem => _buttonItem.isActive);
        const buttonItemIndex: number = this.buttonItems.findIndex(_buttonItem => _buttonItem.id === buttonItem.id);
        if (buttonItemIndex < currentActiveButtonIndex || buttonItemIndex - currentActiveButtonIndex > 1) {
            const popupText: string = buttonItemIndex < currentActiveButtonIndex ?
                this.translateService.translate('status-change-warning-popup.label.back', [buttonItem.label]) :
                this.translateService.translate('status-change-warning-popup.label.forward', [buttonItem.label]);
            this.alertService.addPopup(
                this.translateService.translate('status-change-warning-popup.title', [buttonItem.label]),
                popupText,
                [
                    {
                        label: this.translateService.translate('Annuleren'),
                        code: ButtonCode.ANNULEREN,
                        isPrimary: true
                    },
                    {
                        label: this.translateService.translate('status-change-warning-popup.label.button'),
                        code: ButtonCode.OK,
                        isPrimary: false,
                        callback: () => {
                            warningPopupResult.next(true);
                        }
                    }
                ], () => {
                    warningPopupResult.next(false);
                }
            );
        } else {
            warningPopupResult.next(true);
        }
        
        return warningPopupResult;
    }
}
