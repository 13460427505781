import {ChangeDetectorRef, Component, OnInit, Input} from '@angular/core';
import {LoginNotifyService} from './login-notify.service';
import {ILoginNotifyInterface} from './login-notify.interface';
import {AppSettings} from "../../../app.settings";
import * as dayjs from 'dayjs'
import {StorageService} from "../../../shared/services/storage/storage.service";

@Component ({
    selector: 'login-notify',
    templateUrl: 'login-notify.component.html'
})

export class LoginNotifyComponent implements OnInit{
    constructor(private loginNotifyService:LoginNotifyService, private cd:ChangeDetectorRef, private storage:StorageService){}

    private dateTimeFormat: string = "MMM D, YYYY HH:mm";
    public notifyMessages: ILoginNotifyInterface;
    public imagePathPrefix:string = AppSettings.getBaseUrl();
    private currentLanguageCode:string = "nl";

    ngOnInit() {
        //Get language from storage
        this.storage.getStringValue(StorageService.KEY_SELECTED_LANGUAGE, (value: any) => {
            this.currentLanguageCode = value;
        });

        this.loginNotifyService.getNotificationMessages(this.currentLanguageCode).subscribe((result: ILoginNotifyInterface) => {
            this.notifyMessages =  result;
            setTimeout( () => {
                this.cd.detectChanges();

                // @ts-ignore
                $('.carousel').carousel({
                    interval: 8000
                })
            });
        });
    }

    public isNewNotification(dateTime: string){
        const daysAgo = dayjs().subtract(7, 'days');
        if(dayjs(dateTime, this.dateTimeFormat) > daysAgo){
            return true;
        } else {
            return false;
        }
    }

    public convertIsoDateTime(dateTime: string){
        return dayjs(dateTime).format(this.dateTimeFormat);
    }
}

