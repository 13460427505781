import {ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {AbstractBasePopupComponent} from '../abstract-base-popup.component';
import {GlobalAlertService} from '../../global-alert.service';
import {TranslateService} from '../../../../shared/services/translate/translate.service';
import {TooltipService} from '../../../../shared/services/tooltip/tooltip.service';
import {ButtonInterface, GlobalPopup} from '../../global-popup';
import {MapItemImporterComponent} from '../../../../shared/components/map/map-item/map-item-importer/map-item-importer.component';
import {LoggerService} from "../../../../shared/services/logger/logger.service";

@Component({
    selector: 'global-popup-create-map-items',
    templateUrl: './global-popup-create-map-items.component.html'
})
export class GlobalPopupCreateMapItemsComponent extends AbstractBasePopupComponent implements OnInit {
    @ViewChild('importer', {static: false}) mapItemImporter: MapItemImporterComponent;
    
    public constructor(
        protected cd: ChangeDetectorRef,
        protected globalAlertService: GlobalAlertService,
        protected elementRef: ElementRef,
        protected ts: TranslateService,
        protected tooltipService: TooltipService,
        protected logger:LoggerService
    ) {
        super(cd, globalAlertService, elementRef, ts, tooltipService, logger);
    }
    
    ngOnInit() {
    }
    
    public closePopup(event: any): void {
        this.doDefaultCloseHandling(event, true);
    }
    
    public handlePopupAction(event: MouseEvent, alert: GlobalPopup, button: ButtonInterface): void {
        // Hide alert and perform callback of button
        this.onPopupAction.emit({
            event: event,
            alert: alert,
            button: button,
            data: this.mapItemImporter.getNewMapItems()
        });
    }
    
}
