<form-component-label [batchUpdateMode]="batchUpdateMode" [config]="config" [included]="included" (onClickInclude)="handleClickInclude($event)"></form-component-label>
<div class="d-flex w-100">
    <div class="d-flex flex-column w-100" #currentFormField>
        <ngx-colors ngx-colors-trigger
                    [(ngModel)]="selectedColor"
                    (ngModelChange)="selectColor()"
                    [hideTextInput]="true"
                    [hideColorPicker]="false"
                    [colorPickerControls]="'no-alpha'"
                    [palette]="availableColorPallet"
                    [disabled]="isControlDisabled()"
        ></ngx-colors>
    </div>
</div>
