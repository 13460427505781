export enum UserRoles {
    DB_ADMIN = 'ROLE_DB_ADMIN',
    ADMIN = 'ROLE_ADMIN',
    VAKTECHNISCH_MEDEWERKER = 'ROLE_VAKTECHNISCH_MEDEWERKER',
    ASS_VAKTECHNISCH_MEDEWERKER = 'ROLE_ASS_VAKTECHNISCH_MEDEWERKER',
    AANNEMER = 'ROLE_AANNEMER',
    MONTEUR = 'ROLE_MONTEUR',
    DAEMON = 'ROLE_DAEMON',
    DATA_BEHEERDER = 'ROLE_DATA_BEHEERDER',
    EDITOR = 'ROLE_EDITOR',
    FIETSER = 'ROLE_FIETSER',
    FUNCTIONEEL_BEHEERDER = 'ROLE_FUNCTIONEEL_BEHEERDER',
    GEMEENTE = 'ROLE_GEMEENTE',
    KANTONNIER = 'ROLE_KANTONNIER',
    KIJKER = 'ROLE_KIJKER',
    OVERIGE_GEBRUIK = 'ROLE_OVERIGE_GEBRUIK',
    PLANNER = 'ROLE_PLANNER',
    REST = 'ROLE_REST',
    OVERRIDE = 'ROLE_OVERRIDE',
    DIMGROUP_ACCORDEER = 'ROLE_DIMGROUP_ACCORDEER',
    ENGINEER = 'ROLE_ENGINEER'
}
