import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {StatusChangeRequestsHttpResult} from '../form/components/form-group/form-group-with-status/form-group-with-status.interface';
import {map} from 'rxjs/operators';
import {HttpService2} from '../../services/http-service-2.0/http2.0.service';
import {LoggerService} from "../../services/logger/logger.service";

@Injectable({
    providedIn: 'root'
})
export class StatusService {
    constructor(
        private httpService: HttpService2,
        protected logger:LoggerService
    ) {
    }
    
    public getStatusChangeRequests(url: string): Observable<StatusChangeRequestsHttpResult> {
        if (url) {
            return this.httpService.doGetRequest(url).pipe(
                map(result => result as StatusChangeRequestsHttpResult)
            );
        } else {
            this.logger.log('[FormGroupWithStatusComponent]' + 'status change requested, but no url found');
        }
    }
}
