/**
 * Created by Christiaan on 23/03/2017.
 */

import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
/*import {TimerObservable} from 'rxjs/observable/TimerObservable';*/
import {Router} from "@angular/router";
import {AppSettings} from "../../app.settings";
/*import {Subscription} from "rxjs/Subscription";*/
import { Subscription, timer } from 'rxjs';
import {TranslateService} from "../../shared/services/translate/translate.service";
import {GlobalModel} from "../../shared/services/state/global.model";
import {LuminizerRoutes} from '../../shared/interfaces/routes';
import {LoggerService} from "../../shared/services/logger/logger.service";

@Component ({
    selector: 'page-not-found-component',
    template: `        
        <div class="login-background">
            <div class="login-container">
                <div class="login-filler"></div>
                <div class="forbidden-form-container">
                    <div class="forbidden-form">
                        <table>
                            <tbody>
                                <tr>
                                    <td>
                                        <i class="forbidden-icon material-icons md-80">error_outline</i>
                                    </td>
                                    <td>
                                        <h1>{{title}}</h1>
                                        <p>{{subTitle}}</p>
                                        <p>{{getMessage()}}</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <!--<footer-component [isSimpleFooter]="true"></footer-component>-->
            </div>
        </div>
    `
})
export class PageNotFoundComponent implements OnInit, OnDestroy{

    private static readonly REDIRECT_SECONDS:number = 10; //Redirect after n seconds
    
    public title:string = ''; //'Pagina niet gevonden';
    public subTitle:string = ''; //'De opgevraagde pagina kan niet worden gevonden';
    private secondsUntilRedirect:number;
    private subscription:Subscription;
    private subChangeLanguage:Subscription;

    constructor(private router:Router, private ts:TranslateService, private model:GlobalModel, private cd:ChangeDetectorRef, protected logger:LoggerService) {

        this.secondsUntilRedirect = PageNotFoundComponent.REDIRECT_SECONDS;

        this.subChangeLanguage = this.model.changeLanguage.subscribe( () => {
            this.title = ts.translate('Pagina niet gevonden');
            this.subTitle = ts.translate('De opgevraagde pagina kan niet worden gevonden');
        });
    }
    
    public getMessage():string{
        //U wordt automatisch doorverwezen naar de begin-pagina over {{secondsUntilRedirect}} seconden
        return this.ts.translate('page.redirect', [this.secondsUntilRedirect]);
    }

    ngOnInit(){
        let navigateTimer = timer(1000,1000);
        this.subscription = navigateTimer.subscribe((t:any)=>{
            this.secondsUntilRedirect--;

            if (this.secondsUntilRedirect <= 0){
                this.secondsUntilRedirect = 0;
                this.subscription.unsubscribe();
                this.logger.log("[PageNotFoundComponent] " + "Redirecting to login page...");
                this.router.navigate([LuminizerRoutes.LOGIN_PAGE]);
            }

            this.cd.detectChanges();
        });
    }

    ngOnDestroy(){
        //Stop the counter when the component is removed again
        this.subscription.unsubscribe();
    }

}
