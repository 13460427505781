import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TranslateService} from '../../../../services/translate/translate.service';
import {GlobalModel} from '../../../../services/state/global.model';
import {take} from 'rxjs/operators';
import {GuiService} from '../../gui.service';
import {GuiChildConfig, StedinChildConfig} from '../../gui.interface';
import {GlobalAlertService} from '../../../../../wrapper/global-alert/global-alert.service';
import {FormEvent} from '../../../form/containers/form/form.interface';
import {GlobalEvent} from '../../../../interfaces/global-event';
import {LoggerService} from "../../../../services/logger/logger.service";

@Component({
    selector: 'create-stedin-order',
    templateUrl: './create-stedin-order.component.html',
    styleUrls: ['./create-stedin-order.component.scss']


})
export class CreateStedinOrderComponent implements OnInit {
    @Input('config') config: StedinChildConfig;
    public timestamp: string = '';
    public label: string = '';
    public isCreated: boolean = false;

    constructor(
        private ts: TranslateService,
        private model: GlobalModel,
        private guiService: GuiService,
        protected globalAlertService: GlobalAlertService,
        protected logger:LoggerService
    ) {}

    ngOnInit(): void {
        this.timestamp = this.ts.translate('gatewayreset.defaulttext');
       // this.label = this.config.kabelBonId; // this.ts.translate('workorder.createstedinorder');

    }
    public handleClickViewStedinOrder() {
        this.globalAlertService.addPopupViewStedinOrder('stedin-orders/get-for-workorder/' + this.config.kabelBonBaseObjectId,
            (buttonCode: any, response: any) => {

            }, () => {}
        )
    }

    public handleClickCreateStedinOrder() {
        this.globalAlertService.addPopupCreateGenericReport(
            'report-generic/new-report-for-workorder',
            'report-generic/save-report-for-workorder/' + this.config.werkorderId,
            (buttonCode: any, response: any) => {
            // Show success alert
            this.globalAlertService.addAlertSuccess(
                this.ts.translate('reportgeneric.reportsuccesstitle'),
                this.ts.translate('reportgeneric.reportsuccesssubtitle'),
                this.ts.translate('reportgeneric.reportsuccesslabel',
                    [this.model.currentAreaal.value.label]));

            // Refresh the map icon
            if (response && response.schema && response.schema.base_object_id) {
                this.isCreated = true;
                this.model.onGlobalEvent.next(new GlobalEvent(GlobalEvent.EVENT_REFRESH_FORM,
                    {referenceId: this.config.werkorderBaseObjectId}));
                this.label = this.ts.translate('workorder.createdstedinorder');
            }
            else {
                this.logger.log('[ReportGenericComponent] ' + 'ERROR: no base_object_id given');
            }
        }, () => {
        }, this.config.latitude, this.config.longitude);
    }
}
