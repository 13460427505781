import {
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnInit,
    Output,
    Renderer2,
    ViewChild
} from '@angular/core';
import {GlobalModel} from '../../../services/state/global.model';
import {TooltipService} from '../../../services/tooltip/tooltip.service';
import {TranslateService} from '../../../services/translate/translate.service';

@Component({
    selector: 'file-selector',
    templateUrl: './file-selector.component.html'
})
export class FileSelectorComponent implements OnInit {
    @ViewChild('fileInput', {static: false}) public fileInput: ElementRef<HTMLInputElement>;
    @ViewChild('toolTipAnchor', {static: false}) public toolTipAnchor: ElementRef<HTMLDivElement>;
    
    @Input() public isDisabled: boolean;
    @Input() public multiInput: boolean = true;
    @Input() public fileTypesString: string = '';
    @Input() public buttonOnly: boolean = false;
    @Input() public noText: boolean = false;
    @Output() public onChanges: EventEmitter<FileList> = new EventEmitter<FileList>();
    
    constructor(
        public globalModel: GlobalModel,
        private tooltipService: TooltipService,
        private renderer: Renderer2,
        private translateService: TranslateService
    ) {
    }
    
    ngOnInit() {
    }

    public getButtonLabel() {
        if (!this.noText) {
            return this.translateService.translate(this.multiInput?'attachment.chooseFiles.multi':'attachment.chooseFiles.single');
        } else {
            return '';
        }
    }
    
    public onFilesSelected() {
        this.onChanges.emit(this.fileInput.nativeElement.files);
    }
    
    public selectFiles($event: MouseEvent) {
        $event.preventDefault();
        this.fileInput.nativeElement.click();
    }
    
    public onFilesDropped(files: FileList) {
        this.destroyToolTip();
        if (this.multiInput || files.length < 2) {
            let filesWithIncorrectType: File[] = [];
            Array.from(files).forEach((file: File) => {
                const extensionIndex = file.name.split('.').length - 1;
                const extension = '.' + file.name.split('.')[extensionIndex];
                if (this.fileTypesString !== '' && !this.fileTypesString.includes(extension)) {
                    filesWithIncorrectType.push(file);
                }
            });
            if (filesWithIncorrectType.length < 1) {
                this.fileInput.nativeElement.files = files;
            } else {
                this.tooltipService.createAndShowTooltip(
                    this.renderer,
                    this.toolTipAnchor,
                    this.translateService.translate(
                        (filesWithIncorrectType.length > 1 ? 'attachment.incorrectType.multi' : 'attachment.incorrectType.single'),
                        [filesWithIncorrectType.map(file => file.name), this.fileTypesString]
                    ),
                    TooltipService.PLACEMENT_BOTTOM,
                    TooltipService.TOOLTIP_MODE_MANUAL,
                    '',
                    true
                );
            }
        } else {
            this.tooltipService.createAndShowTooltip(
                this.renderer,
                this.toolTipAnchor,
                this.translateService.translate('attachment.onlySingle'),
                TooltipService.PLACEMENT_BOTTOM,
                TooltipService.TOOLTIP_MODE_MANUAL,
                '',
                true
            );
        }
        this.onChanges.emit(this.fileInput.nativeElement.files);
    }
    
    @HostListener('window:click', ['$event'])
    public destroyToolTip() {
        this.tooltipService.destroyToolTip(this.toolTipAnchor);
    }
}
