import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MapTableComponent} from './map-table.component';
import {GeoDataManagementTableModule} from '../table/geoDataManagementTable/geoDataManagementTable.module';
import {MapModule} from '../map/map.module';
import {CommonPipesModule} from '../../pipes/common-pipes.module';
import {CommonUIModule} from '../commonUI/common-ui.module';
import {CommonDirectivesModule} from '../../directives/common/common-directives.module';

@NgModule({
  declarations: [
    MapTableComponent
  ],
  exports: [
      MapTableComponent
  ],
    imports: [
        CommonModule,
        GeoDataManagementTableModule,
        MapModule,
        CommonPipesModule,
        CommonUIModule,
        CommonDirectivesModule
    ]
})
export class MapTableModule { }
