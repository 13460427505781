import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component, ElementRef,
    EventEmitter,
    Input, OnDestroy,
    Output,
} from '@angular/core';

import {GlobalAlertService} from "../global-alert.service";
import {GlobalPopup} from "../global-popup";
import {TranslateService} from "../../../shared/services/translate/translate.service";
import Utils from "../../../shared/utils/utils";
import {FormAttachmentComponent} from "../../../shared/components/form/components/form-attachment/form-attachment.component";
import {FormDataService} from "../../../shared/components/form/services/form-data.service";
import {AbstractBasePopupComponent} from "./abstract-base-popup.component";
import {TooltipService} from "../../../shared/services/tooltip/tooltip.service";
import {
    Attachment, IAttachmentCarousel,
    IImageCarousel
} from "../../../shared/components/form/components/form-attachment/form-attachment.interface";
import {LoggerService} from "../../../shared/services/logger/logger.service";
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {BasicMapItem} from '../../../shared/components/map/map-item/map-item-importer/map-item-importer.interface';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {HttpService2} from '../../../shared/services/http-service-2.0/http2.0.service';
import {RequestFailure} from '../../../shared/services/http/request-failure';
import {HTTPError} from '../../../shared/services/http/http-error';
import {HTTPService} from '../../../shared/services/http/http.service';

@Component ({
    selector: 'global-popup-images-carousel-component',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styles:['#popupImageCarousel{margin-top:20px;}.carousel-item img{cursor:pointer;}'],
    template: `
        <default-popup-wrapper-component [globalPopup]="globalPopup" [showLoader]="isLoading"
                                         [title]="ts.translate('popupcarousel.title')"
                                         (onClose)="closePopup($event)">
            <div id="popupImageCarousel" class="carousel slide" data-ride="false" data-keyboard="true">
                <ol class="carousel-indicators" *ngIf="this.imageList.length > 1">
                    <li *ngFor="let image of this.imageList;let i = index;" data-target="#popupImageCarousel" [ngClass]="{'active':image.imageDetails.id === this.globalPopup.data.activeAttachmentId}" [attr.data-slide-to]="i"></li>
                </ol>
                <div class="carousel-inner">
                    <div class="carousel-item justify-content-center" *ngFor="let image of this.imageList;let i = index;" [ngClass]="{'active':image.imageDetails.id === this.globalPopup.data.activeAttachmentId}">
                        <img [attr.src]="image.imageSrc" [attr.title]="'attachment.download' | translate:[image.imageDetails.listItem.title]" [attr.alt]="'attachment.download' | translate:[image.imageDetails.listItem.title]" class="img-fluid" (click)="handleDownloadFile(i)" />
                    </div>
                </div>
                <a class="carousel-control-prev" href="#popupImageCarousel" role="button" data-slide="prev" *ngIf="this.imageList.length > 1">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="sr-only">Previous</span>
                </a>
                <a class="carousel-control-next" href="#popupImageCarousel" role="button" data-slide="next" *ngIf="this.imageList.length > 1">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="sr-only">Next</span>
                </a>
            </div>
        </default-popup-wrapper-component>
    `
})

export class GlobalPopupImagesCarouselComponent extends AbstractBasePopupComponent implements AfterViewInit {
    imageList:IImageCarousel[] = [];

    public constructor(protected cd:ChangeDetectorRef,
                       protected elementRef:ElementRef,
                       protected globalAlertService:GlobalAlertService,
                       public ts:TranslateService,
                       private formDataService:FormDataService,
                       protected tooltipService:TooltipService,
                       protected logger:LoggerService,
                       protected http:HttpClient,
                       protected httpService:HTTPService) {
        super(cd, globalAlertService, elementRef, ts, tooltipService, logger);
    }

    ngAfterViewInit() {
        this.getImagesForCarousel(this.globalPopup.data)
    }

    private getImagesForCarousel(attachmentsList:IAttachmentCarousel){
        let currentAttachmentItem = 0;

        attachmentsList.attachmentList.map(attachmentItem => {
            if (attachmentItem.imageComponent) {
                let postValues: any = {
                    url: attachmentItem.getUrl
                };
                this.httpService.doFormPostRequest(`general/image/get`, postValues, (json: any) => {
                    this.imageList[currentAttachmentItem] = {
                        imageDetails: attachmentItem,
                        imageSrc:  json.base64image
                    };
                    this.isLoading = false;
                    this.cd.detectChanges();
                    }, (failure: RequestFailure) => {}, (error: HTTPError) => {}, true
                )
            } else {
                this.formDataService.getAttachment(attachmentItem.getUrl,
                    (data: any) => {
                        this.logger.log("[FormAttachmentComponent] " + "Attachmentdata received for: " + attachmentItem.getUrl);
                        this.imageList[currentAttachmentItem] = {
                            imageDetails: attachmentItem,
                            imageSrc: data.fileData
                        };
                        currentAttachmentItem++;

                        if (currentAttachmentItem === attachmentsList.attachmentList.length) {
                            this.logger.log("[FormAttachmentComponent] " + "Finished getting images");
                            this.isLoading = false;
                            this.cd.detectChanges();
                        }
                    }
                    ,
                    () => {
                        this.logger.log("[GlobalPopupViewAttachmentComponent] " + "ERROR: no attachment data, cant load attachment");
                    },
                    () => {
                        this.logger.log("[GlobalPopupViewAttachmentComponent] " + "ERROR: no attachment data, cant load attachment");
                    }
                )
            }
        })


    }

    // public getBase64(file: string) {
    //
    //     console.log(file);
    //
    //     this.http.post('/image/get',['url' =>  file])
    //
    //
    //     this.http.get(file, { responseType: 'blob' })
    //         .subscribe(blob => {
    //             const reader = new FileReader();
    //             const binaryString = reader.readAsDataURL(blob);
    //             reader.onload = (event: any) => {
    //                 //console.log(event.target.result);
    //                return  event.target.result
    //             };
    //             reader.onerror = (event: any) => {
    //                 console.log("File could not be read: " + event.target.error.code);
    //             };
    //
    //         });
    // }
    public toDataUrl = async (url) => {
        let headers = new Headers();
        headers.append('Access-Control-Allow-Origin', '*');

        const data = await fetch(url,{headers});
        const blob = await data.blob();
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = () => {
                const base64data = reader.result;
                resolve(base64data);
            };
            reader.onerror = reject;
        });
    };
    // public toDataUrl(url, callback) {
    //     let xhr = new XMLHttpRequest();
    //     xhr.setRequestHeader("Access-Control-Allow-Origin","*");
    //     xhr.onload = function() {
    //         let reader = new FileReader();
    //         reader.onloadend = function() {
    //             callback(reader.result);
    //         }
    //         reader.readAsDataURL(xhr.response);
    //     };
    //     xhr.open('GET', url);
    //     xhr.responseType = 'blob';
    //     xhr.send();
    // }

    public handleDownloadFile(imageItem:number):void{
        Utils.downloadAsFile(true, this.imageList[imageItem].imageSrc, this.imageList[imageItem].imageDetails.listItem.title, 'global-popup-view-attachment-component');
    }

    public closePopup(event:any):void{
        this.doDefaultCloseHandling(event, false);
    }

    handlePopupAction(event: MouseEvent, alert: GlobalPopup, button: any): void {
        //Hide alert and perform callback of button
        this.onPopupAction.emit({event:event, alert:alert, button:button});
    }
}
