import {AfterViewInit, ChangeDetectorRef, Component, Renderer2} from '@angular/core';
import {ValidationConstraintService} from '../../services/validation-constraint.service';
import {FormInputComponent} from '../form-input/form-input.component';
import {GlobalAlertService} from '../../../../../wrapper/global-alert/global-alert.service';
import {TooltipService} from '../../../../services/tooltip/tooltip.service';
import {Subscription} from 'rxjs';
import {GlobalModel} from '../../../../services/state/global.model';
import 'moment/locale/nl';
import {RequestFailure} from '../../../../services/http/request-failure';
import {MapItemService} from '../../../map/map-item/map-item.service';
import {TranslateService} from '../../../../services/translate/translate.service';
import {AppSettings} from '../../../../../app.settings';
import {IBushData,IBushDataNodeWorkorder} from './form-bush-item-tree.interface';
import {LoggerService} from "../../../../services/logger/logger.service";

@Component({
    selector: 'form-bush-item-tree',
    templateUrl: 'form-bush-item-tree.component.html'
})
export class FormBushItemTreeComponent extends FormInputComponent implements AfterViewInit {
    private mapItemId: number = 0;
    isExpanded: boolean = false;
    bushData: IBushData;
    workorderData: IBushDataNodeWorkorder;
    baseObjectId: number = 0;

    constructor(public renderer: Renderer2, public validationConstraintService: ValidationConstraintService, public tooltipService: TooltipService, public globalAlertService: GlobalAlertService, public cd: ChangeDetectorRef, public model: GlobalModel, public mapItemService: MapItemService, public ts: TranslateService, protected logger:LoggerService) {
        super(renderer, validationConstraintService, tooltipService, globalAlertService, ts, logger, model);

        this.isExpanded = model.formBushItemTreeExpanded;
    }

    ngAfterViewInit() {
        //Get map item id
        if (this.config.attr) {
            if (this.config.attr.map_item_id) {
                this.mapItemId = (typeof this.config.attr.map_item_id === 'number')
                    ? this.config.attr.map_item_id
                    : parseInt('' + this.config.attr.map_item_id);
            }
            else {
                this.globalAlertService.addAlert(GlobalAlertService.ALERT_TITLE_ERROR, this.ts.translate('Geen map item data'), this.ts.translate('Voor dit item is geen map item id gedefinieerd'));
            }

            if (this.config.attr.base_object_id) {
                this.baseObjectId = (typeof this.config.attr.base_object_id === 'number') ? this.config.attr.base_object_id : parseInt('' + this.config.attr.base_object_id);
            }
        }

        this.logger.log('[FormBushItemTreeComponent] ' + 'baseObjectId id: ' + this.baseObjectId);

        this.getBushData();
        this.getWorkorderData();
    }

    handleClickExpand() {
        this.isExpanded = !this.isExpanded;
        this.model.formBushItemTreeExpanded = this.isExpanded;
    }

    getNodeCount(nodes: any): number {
        let nodeCount: number = 0;
        if (nodes && nodes.length > 0) {
            nodes.forEach((node: any) => {
                nodeCount++;
                if (node.children && node.children.length > 0) {
                    nodeCount += this.getNodeCount(node.children);
                }
            });
        }
        return nodeCount;
    }

    private getBushData() {
        this.logger.log('[FormBushItemTreeComponent] ' + 'get bush data: ' + this.mapItemId);
        this.mapItemService.getBushItems('mapitem/bush/' + this.mapItemId, (json: any) => {
            this.bushData = json;
            if (!this.cd['destroyed']) {
                this.cd.detectChanges();
            }
        }, (failure: RequestFailure) => {
            //User can't change this, do nothing
        });
    }

    private getWorkorderData() {
        this.logger.log('[FormBushItemTreeComponent] ' + 'get workorder data: ' + this.mapItemId);
        this.mapItemService.getBushItems('mapitem/workorders/' + this.mapItemId, (json: any) => {
            this.workorderData = json;
            if (!this.cd['destroyed']) {
                this.cd.detectChanges();
            }
        }, (failure: RequestFailure) => {
            //User can't change this, do nothing
        });
    }
}
