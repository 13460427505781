<ng-container [formGroup]="group">
    <!-- Add id to enable scrolling to this element via formsections -->
    <!-- TODO: Dit component hoort niet zo los gebruikt te worden maar hoort in een formgroup gewrapped te ziten. Dan klopt de paddingen en het id wel automatisch -->
    <div [id]="config.name" class="w-100 bush-item-tree-container pb-3">
        <ng-container *ngIf="bushData; else loading;">
            <div style="overflow-x: auto; position:relative"
                 [style.min-height]="isExpanded?'50px':''">
                <div (click)="handleClickExpand()"
                     style="margin-left: -2px"
                     class="form-group-label-container {{isExpanded ?'':'collapsed'}}">
                    <i class="material-icons tree-node-icon"
                       tabindex="0"
                       >{{isExpanded ? 'expand_more' : 'keyboard_arrow_right'}}</i>
                    <div style="display: inline-flex; width: 100%; min-width:85%">
                        <div style="align-self: center" class="form-group-label mb-0 text-truncate" [title]="bushData.children[0].label">{{bushData.children[0].label}} - {{getNodeCount(bushData.children)}} {{'Items op deze locatie' | translate}}</div>
                    </div>
                </div>
                <div [style.display]="(isExpanded) ? 'inline':'none'">
                    <form-bush-item-tree-node [style.display]="(bushData && bushData.children) ? 'inline':'none'"
                                              [baseHref]="config.attr.baseHref"
                                              [nodes]="bushData.children"
                                              [workorders]="workorderData"
                                              [currentBaseObject]="baseObjectId"></form-bush-item-tree-node>
                </div>
            </div>
        </ng-container>
        <ng-template #loading>
            <div class="d-flex align-items-center p-1 lightgrey-background">
                <div class="loader-small"></div>
                <label class="pl-2 m-0">{{'Items op deze locatie ophalen...' | translate}}</label>
            </div>
        </ng-template>
    </div>
</ng-container>