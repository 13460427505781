import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    ViewChild
} from '@angular/core';
import {GlobalAlertService} from '../../global-alert.service';
import {ButtonCode, ButtonInterface, GlobalPopup} from '../../global-popup';
import {HTTPError} from '../../../../shared/services/http/http-error';
import {RequestFailure} from '../../../../shared/services/http/request-failure';
import {FormDataService} from '../../../../shared/components/form/services/form-data.service';
import {TranslateService} from '../../../../shared/services/translate/translate.service';
import {AbstractBasePopupComponent} from '../abstract-base-popup.component';
import {TooltipService} from '../../../../shared/services/tooltip/tooltip.service';
import {FormEvent} from '../../../../shared/components/form/containers/form/form.interface';
import {GlobalModel} from '../../../../shared/services/state/global.model';
import {LoggerService} from "../../../../shared/services/logger/logger.service";

@Component({
    selector: 'global-popup-create-mapserver-component',
    changeDetection: ChangeDetectionStrategy.OnPush,
    templateUrl: './global-popup-create-mapserver.component.html'
})

export class GlobalPopupCreateMapserverComponent extends AbstractBasePopupComponent implements AfterViewInit {

    @Input() requestUrl:string = "";
    @Input() submitUrl:string = "";

    @ViewChild('form', {static: false}) form: any;

    public constructor(
        protected cd: ChangeDetectorRef,
        protected globalAlertService: GlobalAlertService,
        protected formDataService: FormDataService,
        protected elementRef: ElementRef,
        protected ts: TranslateService,
        protected tooltipService: TooltipService,
        protected model: GlobalModel,
        protected logger:LoggerService
    ) {
        super(cd, globalAlertService, elementRef, ts, tooltipService, logger);
    }
    
    ngAfterViewInit() {
        this.requestUrl = this.globalPopup.data.requestUrl;
        this.submitUrl = this.globalPopup.data.submitUrl;
        this.submitButton = this.getSubmitButton();
        this.requestFormData();
    }
    
    handlePopupAction(event: MouseEvent, alert: GlobalPopup, button: ButtonInterface): void {
        // callback for submit button comes from this form, not from outside this component
        if (button === this.submitButton) {
            this.form.handleComponentEvent({event: FormEvent.SAVE, data: {}});
            // this.form.submitForm();
        } else if (button.code === ButtonCode.OK) {
            button.callback(button.code, this.form)
        } else {
            // Hide alert and perform callback of button
            this.onPopupAction.emit({event: event, alert: alert, button: button, data:this.form});
        }
    }

    public handleComponentEvent(eventData: any): void {
        if (eventData.event === FormEvent.SAVE) {
            this.submitFormData(eventData.data.formData);
        }
    }
    
    private submitFormData(form: any): void {
        this.handleSubmitForm();

        this.formDataService.setFormData(form, this.getFormName(), this.submitUrl,
            (formData: any) => {
                this.logger.log('[GlobalPopupCreateMapserverComponent] succes', formData);
                // this.formData = formData;
                this.handleSubmitResponse();

                // Hide alert and perform success callback via submit button
                this.onPopupAction.emit({
                    event: null,
                    alert: this.globalPopup,
                    button: this.submitButton,
                    data: formData
                });
                //  data:{lat:form.location.latitude, lng:form.location.longitude}});
            },
            (failure: RequestFailure) => {
                this.logger.log('[GlobalPopupCreateBaseObjectComponent] ' + 'Handle failure response: ', failure);
                this.handleSubmitResponse(failure.formErrors);
            },
            (error: HTTPError) => {
                this.handleSubmitResponse(null, true);
            }
        );
    }
    
    public closePopup(event: any): void {
        this.logger.log('[GlobalPopupCreateMapserverComponent] ', event);
        this.doDefaultCloseHandling(event, true);
    }

    public requestFormData():void
    {
        this.formDataService.getFormData(this.requestUrl,
            (formData:any) => {
                this.formData = formData;
                this.logger.log('[GlobalPopupCreateMapserverComponent] ', formData);
                this.isLoading = false;
                this.cd.detectChanges();
            },
            () => {
                this.closePopup(null);
            },
            () => {
                this.closePopup(null);
            }
        );
    }
}
