import {
    ChangeDetectorRef,
    Component, EventEmitter, Input, OnDestroy, Output
} from '@angular/core'
import {MenuItem} from "./menu-item";
import {Router} from "@angular/router";
import {GlobalAlertService} from "../../../wrapper/global-alert/global-alert.service";
import {GlobalModel} from "../../../shared/services/state/global.model";
import {AppSettings} from "../../../app.settings";
import {RequestFailure} from "../../../shared/services/http/request-failure";
import {DashboardService} from "../../../modules/dashboard/dashboard.service";
import {MenuService} from "../menu.service";
import { Subscription } from 'rxjs';
import {HTTPService} from "../../../shared/services/http/http.service";
import {AuthorizationService} from "../../../shared/services/authorization/authorization.service";
import {LuminizerRoutes} from '../../../shared/interfaces/routes';
import {take} from 'rxjs/operators';
import {LoggerService} from "../../../shared/services/logger/logger.service";

@Component({
    selector: "dynamic-menu-item-component",
    template: `
        <ng-container *ngFor="let menuItem of menuItems">
            <!--<li class="nav-item dropdown btn-group">-->
            <ng-container *ngIf="isValidMenuItem(menuItem)">
                <li [class]="getDropdownListClasses(menuItem, level)">
                    <a style="display: flex; align-items: center;" [class]="getNavigationLinkClasses(menuItem, level)" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" (click)="handleMenuItemClick($event, menuItem, level)">
                        <!-- for now: if there is an icon, show it and make icon have a tooltip/tiptool -->
                        <ng-template #label>
                            <div style="font-size: 15px;">{{menuItem.label}}</div>
                        </ng-template>
                        <ng-container *ngIf="menuItem.icon; else label">
                            <i class="material-icons md-light menu-icon">{{ menuItem.icon }}</i>
                        </ng-container>
                    </a>
                    <ng-container *ngIf="menuItem.children.length > 0">
                        <div class="dropdown-menu dropdown dropdown-menu-{{expandDirection}} {{level > 0?'menu-submenu':''}}" [style.width]="level > 0?'200px':''">
                            <dynamic-menu-item-component [menuItems]="menuItem.children" [level]="level+1" (menuAction)="handleMenuAction($event)"></dynamic-menu-item-component>
                        </div>
                    </ng-container>
                </li>
            </ng-container>
        </ng-container>
    `,
})

export class DynamicMenuItemComponent implements OnDestroy{

    private subIsGridModeActive: Subscription = null;
    private isGridModeActive:boolean = false;
    private subMobileMode: Subscription = null;
    private isMobileMode:boolean = false;

    @Input() menuItems:MenuItem[];
    @Input() level:number = 0;
    @Input() expandDirection:string = DynamicMenuItemComponent.EXPAND_DIRECTION_LEFT;
    @Output('menuAction') menuActionEvent: EventEmitter<any> = new EventEmitter();

    public static readonly ITEM_TYPE_DEFAULT: string = 'DefaultMenuItem';
    public static readonly ITEM_TYPE_DASHBOARD: string = 'DashboardMenuItem';
    public static readonly ITEM_TYPE_BLANK_TARGET: string = 'BlankTargetMenuItem';
    public static readonly EXPAND_DIRECTION_LEFT: string = 'left';
    public static readonly EXPAND_DIRECTION_RIGHT: string = 'right';

    private subPendingCallPaths:Subscription;

    constructor(private globalAlertService:GlobalAlertService, private router:Router, private dashboardService:DashboardService, private menuService:MenuService, private model:GlobalModel, private auth:AuthorizationService, private cd:ChangeDetectorRef, private httpService:HTTPService, protected logger:LoggerService)
    {
        this.subPendingCallPaths = this.httpService.pendingCallPaths.subscribe((paths:string[]) => {
            //Don't forget to unsubscribe

            //Refresh the loading state by checking the pending calls
            this.cd.markForCheck();
        });
        this.subIsGridModeActive = this.model.gridDrawModeActive.subscribe((value: boolean) => {
            this.isGridModeActive = value
        });
        this.subMobileMode = this.model.mobileMode.subscribe((value: boolean) => {
            this.isMobileMode = value
        });
    }

    ngOnDestroy()
    {
        if(this.subPendingCallPaths){
            this.subPendingCallPaths.unsubscribe();
        }
        if(this.subIsGridModeActive){
            this.subIsGridModeActive.unsubscribe();
        }
        if(this.subMobileMode){
            this.subMobileMode.unsubscribe();
        }
    }

    public handleMenuAction(event:any)
    {
        this.menuActionEvent.emit(event);
    }

    public handleMenuItemClick(event:any, menuItem: MenuItem, level:number)
    {
        // TODO: als je ooit nog dieper wilt gaan in het menu moet dit recursief
        menuItem.children.forEach((subMenuItem:MenuItem) =>{
            subMenuItem.showSubMenu = false;
        });

        if (menuItem.enabled) {
            if (menuItem.uri != null) {

                let actionPerformed: boolean = true;

                switch (menuItem.type) {
                    case DynamicMenuItemComponent.ITEM_TYPE_DEFAULT: {
                        this.router.navigate([menuItem.uri]);
                        break;
                    }
                    case DynamicMenuItemComponent.ITEM_TYPE_DASHBOARD: {

                        this.model.dashboardAutoRefresh = false;
                        //this.router.navigate([AppSettings.DASHBOARD_PAGE], {queryParams: {autoRefresh: "false"}});
                        this.router.navigate([LuminizerRoutes.DASHBOARD_PAGE]);//, {queryParams: {autoRefresh: "false"}});

                        //get a specific dashboard
                        this.dashboardService.getDashboard(menuItem.uri).pipe(
                            take(1)
                        ).subscribe();
                        break;
                    }
                    case DynamicMenuItemComponent.ITEM_TYPE_BLANK_TARGET:
                        window.open(AppSettings.getBaseUrl() + menuItem.uri);
                        break;
                    default: {
                        actionPerformed = false;
                        this.logger.log("[DynamicMenuItemComponent] " + "WARNING: UNKNOW ITEM TYPE |" + menuItem.type + "|");

                        if (this.auth.allowShowDebug()) {
                            this.globalAlertService.addAlertDebugMessage("Unknown menu item type", "Value: [" + menuItem.type + "]", "The menu item is of an unknown type. Not sure how to handle it.");
                        }
                        break;
                    }
                }

                if (actionPerformed) {
                    this.menuActionEvent.emit();
                    this.cd.detectChanges()
                }
            } else {
                if (menuItem.children.length > 0 && level > 0){
                    event.preventDefault();
                    event.stopImmediatePropagation();
                    menuItem.showSubMenu = !menuItem.showSubMenu;
                }
            }
        }
    }

    public getDropdownListClasses(menuItem:MenuItem, level:number):string
    {
        let result:string = "dropdown btn-group ";
        menuItem.enabled = true;

        if (!this.menuService.menuEnabled()) {
            result += ' disabled';
            menuItem.enabled = false;
        } else {
            if (menuItem.children.length > 0) {
                result += ' nav-item';
            }
        }

        if (menuItem.enabled && menuItem.children.length <= 0){
            result += ' waves-effect waves-light'
        }

        if (level > 0) {
            result += ' luminizer-main-menu-drop-down-item-container position-relative ';

            if (menuItem.showSubMenu){
                result +=  ' show-submenu';
            }
        }
        
        return result;
    }


    //TODO: deze functie verder worden opgeruimd
    public getNavigationLinkClasses(menuItem:MenuItem, level:number):string{

        let classValue = 'nav-item nav-link pr-2';

        //If menu is enabled, and flash items too if it is one
        if (this.menuService.menuEnabled()){

            //Dashboards is an exception, for now handle it separate. If more menu items come like this one, make this check more dynamic
            //Grid menu item is a bit of an outcast. It just needs to set a parameter but lives on the /assets uri.Therefore a dirty fix to highlight the grid menu item if on asset uri but has grid parameter.
            if (menuItem.code.toLowerCase() == "dashboards" && this.router.isActive(LuminizerRoutes.DASHBOARD_PAGE, false)) {
                classValue += ' active';
            } else if(menuItem.code.toLowerCase() == "grid" && this.router.isActive('assets', false)) {
                if(this.isGridModeActive){
                    classValue += ' active';
                }
            } else if(menuItem.code.toLowerCase() == "asset-management" && this.router.isActive('assets', false)) { //This check if here because of the grid check before. Read comments above.
                if(!this.isGridModeActive){
                    classValue += ' active';
                }
            } else {
                //TODO: dit is even snel gedaan voor menu's die drie niveaus diep zijn. Maak dit ooit mooi recursive
                //Check root
                if (menuItem.uri && (this.router.isActive(menuItem.uri, false))){
                    classValue += ' active';
                }else {
                    //Check if child elements are active
                    for (let childItem of menuItem.children) {
                        if ((childItem.uri && this.router.isActive(childItem.uri, false))) {
                            classValue += ' active';
                            break;
                        }
                        for (let grandChildItem of childItem.children) {
                            if ((grandChildItem.uri && this.router.isActive(grandChildItem.uri, false))) {
                                classValue += ' active';
                                break;
                            }
                        }
                    }
                }
            }
            classValue += ' waves-effect waves-light';
        }else{
            classValue += ' disabled';
        }

        //Highlight selected dashboard is the dashboard page is visible. Match id with currentdashboard
        //TODO: Dit kan veel mooier, het werkt nu totaal niet dynamisch, maar alleen voor dashboard items
        if (menuItem.type == DynamicMenuItemComponent.ITEM_TYPE_DASHBOARD && this.model.currentDashboard.value){
            if (this.router.isActive(LuminizerRoutes.DASHBOARD_PAGE, false) && menuItem.uri) {

                //Get every number
                let numbers:any = menuItem.uri.match(/\d+/g);

                //Match the last number
                if (numbers && numbers.length > 0){
                    if (numbers[numbers.length-1] == String(this.model.currentDashboard.value.id)) {
                        classValue += ' selected-searchitem';
                    }
                }
            }
        }

        if (menuItem.children.length > 0 && !menuItem.icon) {
            classValue += ' dropdown-toggle';
        }
        if (level > 0) {
            classValue += ' luminizer-main-menu-drop-down-item';
        }

        return classValue;
    }

    //Check if item has a link or sub-items or is a flash item (we want to retain) or has a uri, else hide the item
    public isValidMenuItem(menuItem:MenuItem):boolean{
        //Menu item grid is not available on mobile
        if(menuItem.code.toLowerCase() == "grid"){
            if(!this.isMobileMode){
                return true
            } else {
                return false
            }
        } else {
            return menuItem.children.length > 0 || menuItem.uri != null;
        }
    }
}
