<h2 class="py-0">{{'table-column-selector.title' | translate}}</h2>
<div class="cst-multi-select">
    <form-multi-select [dropdownSizeLarge]="true"
                       [singleLineDropdown]="true"
                       [sortSelection]="false"
                       (onChange)="handleChangeColumns()"
                       #columnPicker>
    </form-multi-select>
</div>
<div class="column-selector-table">
    <table class="cst-table" *ngIf="visibleColumns.length > 0">
        <thead>
        <tr class="cst-header">
            <th *ngIf="visibleColumns.length > 1" title="{{'table-column-selector.header.rank'| translate}}">
                {{'table-column-selector.header.rank'| translate}}
            </th>
            <th title="{{'table-column-selector.header.column'| translate}}">
                {{'table-column-selector.header.column'| translate}}
            </th>
            <th title="{{'table-column-selector.header.filter'| translate}}" *ngIf="allowAddFilters">
                {{'table-column-selector.header.filter'| translate}}
            </th>
            <th title="{{'table-column-selector.header.sort'| translate}}" *ngIf="allowSorting">
                {{'table-column-selector.header.sort'| translate}}
            </th>
            <th title="{{'table-column-selector.header.map'| translate}}" *ngIf="allowToggleOnMap">
                {{'table-column-selector.header.map'| translate}}
            </th>
            <th *ngIf="visibleColumns.length > 1" title="{{'table-column-selector.header.remove'| translate}}">
                {{'table-column-selector.header.remove'| translate}}
            </th>
        </tr>
        </thead>
        <tbody #tableBody [hidden]="hideTableBody">
        <ng-container *ngFor="let column of visibleColumns; let i = index">
            <tr [ngClass]="getRowStyle(column)">
                <td *ngIf="visibleColumns.length > 1" style="padding-left: 0;">
                    <div class="cst-rank-column">
                        <div *ngIf="!mobileMode"
                             class="cst-drag-button"
                             title="{{'table-column-selector.button.drag.title'| translate}}"
                             (mousedown)="onDragStart($event, column)">
                            <i>drag_indicator</i>
                        </div>
                        <div class="cst-up-down-buttons">
                            <button class="cst-icon-button-small"
                                 *ngIf="i > 0"
                                 (click)="rankUp(column)"
                                 title="{{'table-column-selector.button.up.title'| translate}}">
                                <i>arrow_drop_up</i>
                            </button>
                            <button class="cst-icon-button-small"
                                 *ngIf="i < visibleColumns.length - 1"
                                 (click)="rankDown(column)"
                                 title="{{'table-column-selector.button.down.title'| translate}}">
                                <i>arrow_drop_down</i>
                            </button>
                        </div>
                    </div>
                </td>
                <td class="cst-bold-column" (click)="truncateLabel ? toggleTruncation() : null">
                    <span title="{{column.label}}"
                          [ngStyle]="truncateLabel ? {cursor: 'pointer'} : null">{{column.label | truncate:column.truncateLabel:maxLabelChars}}</span>
                    <span title="{{column.label}}" style="cursor: pointer" *ngIf="column.truncateLabel">...</span>
                </td>
                <td *ngIf="allowAddFilters">
                    <div class="cst-filter-block"
                         *ngIf="column.filter">
                        <table-filter-component
                                (onDeleteFilter)="handleDeleteFilter(column)"
                                (onFilterChange)="handleChangeFilter($event, column)"
                                [showLabel]="false"
                                [tableField]="getTableFieldForColumn(column)">
                        </table-filter-component>
                    </div>
                    <lumi-button *ngIf="!column.filter"
                                 (onClick)="addFilter(column)"
                                 [icon]="'add'"
                                 [label]="'table-column-selector.button.addFilter'| translate"
                                 [rank]="'tertiary'">
                    </lumi-button>
                </td>
                <td *ngIf="allowSorting">
                    <form-select
                            (onComponentEvent)="setSorting($event)"
                            [Config]="getSortingConfigForColumn(column)"
                            [selectedOption]="column.sortDirection">
                    </form-select>
                </td>
                <td *ngIf="allowToggleOnMap" (click)="toggleOnMap(column)">
                    <div class="{{column.onMap?'cst-map-check-div-checked':'cst-map-check-div-unchecked'}}">
                        <div class="cst-map-check"></div>
                    </div>
                </td>
                <td *ngIf="visibleColumns.length > 1">
                    <lumi-button [disabled]="!column.removable"
                                 (onClick)="onRemoveColumn(column)"
                                 [icon]="'clear'"
                                 [title]="('table-column-selector.button.delete.title' + (column.removable? '':'.disabled')) | translate"
                                 [rank]="'tertiary'">
                    </lumi-button>
                </td>
            </tr>
        </ng-container>
        </tbody>
    </table>
</div>
