import {Component, OnInit, Renderer2} from '@angular/core';
import {AbstractFormFieldComponent} from '../../abstract/abstract-form-field.component';
import {ValidationConstraintService} from '../../../services/validation-constraint.service';
import {TooltipService} from '../../../../../services/tooltip/tooltip.service';
import {MapIconOption} from '../../../../map-icon-selector/map-icon-selector/map-icon-selector.interface';
import MapIconGenerator from '../../../../../utils/map-icon-generator';
import {IconPickerConfig} from './form-map-icon-picker.interface';
import {LoggerService} from "../../../../../services/logger/logger.service";

@Component({
    selector: 'form-map-icon-picker',
    templateUrl: './form-map-icon-picker.component.html'
})
export class FormMapIconPickerComponent extends AbstractFormFieldComponent implements OnInit {
    public config: IconPickerConfig;
    private readonly BASE_ICON_PATH = MapIconGenerator.ICON_BASE_PATH + '/16x16/';
    private readonly EXTENSION = '.png';
    
    public mapIconOptions: MapIconOption[] = [];
    public selectedOption: MapIconOption;
    
    constructor(
        public renderer: Renderer2,
        public validationConstraintService: ValidationConstraintService,
        public tooltipService: TooltipService,
        protected logger:LoggerService
    ) {
        super(renderer, validationConstraintService, tooltipService, logger);
    }
    
    ngOnInit() {
        if (this.config?.attr?.icons?.length > 0) {
            let id = 0;
            this.mapIconOptions = this.config.attr.icons.map(icon => {
                return {
                    id: id++,
                    name: icon.name,
                    imgPath: this.BASE_ICON_PATH + icon.img + this.EXTENSION
                };
            });
            const fullImgPath = this.BASE_ICON_PATH + this.config.initialValue + this.EXTENSION;
            if (this.mapIconOptions.some(option => option.imgPath === fullImgPath)) {
                this.selectedOption = this.mapIconOptions.find(option => option.imgPath === fullImgPath);
    
                this.onMapIconSelect(this.selectedOption);
            }
        }
    }
    
    public onMapIconSelect(selectedOption: MapIconOption) {
        this.selectedOption = selectedOption;
        this.group.get(this.config.name).patchValue(
            selectedOption.imgPath.replace(this.BASE_ICON_PATH, '')
                .replace(this.EXTENSION, '')
        );
    }
}
