<!--
Todo: Elements should contain formGroup when part of form but not when outside of form.
Extreme ugly solution below because of duplicate code but I do not see a solution with the experience I have now. Must look at this again.
-->

<div class="d-flex input-group p-1 pr-0 m-0 datetime-input border-color-grey-input" *ngIf="!readonly && parentFormGroup" [formGroup]="parentFormGroup">
    <!--Date input-->
    <ng-container *ngIf="inputType == 'date'">
        <owl-date-time #datePicker [disabled]="disabled" [firstDayOfWeek]="1" [pickerType]="'calendar'"></owl-date-time>
        <div class="w-100">
            <input #dateInput
                   [(ngModel)]="dateTimeModel"
                   [required]="isRequired"
                   [owlDateTime]="datePicker"
                   [min]="dateTimeMinValue"
                   [max]="dateTimeMaxValue"
                   [formControlName]="config.name"
                   [attr.disabled]="disabled"
                   (dateTimeChange)="onDatePicked()"
                   (keyup)="onManualInputKeyUp($event)"
                   (change)="onManualInputChange($event)"
                   name="{{config.name}}">
        </div>
        <lumi-button *ngIf="allowClear"
                     [icon]="'close'"
                     [size]="'small'"
                     [rank]="'input'"
                     [title]="'dateInput.delete' | translate"
                     [disabled]="disabled"
                     (click)="clearDateTime()">
        </lumi-button>
        <lumi-button [icon]="'event'"
                     [size]="'small'"
                     [rank]="'input'"
                     [disabled]="disabled"
                     [title]="'dateInput.pick' | translate"
                     [owlDateTimeTrigger]="datePicker">
        </lumi-button>
    </ng-container>

    <!--Time input-->
    <ng-container *ngIf="inputType == 'time'">
        <owl-date-time #timePicker [disabled]="disabled" [firstDayOfWeek]="1" [pickerType]="'timer'"></owl-date-time>
        <div class="w-100">
            <input #timePickerInput
                    [(ngModel)]="dateTimeModel"
                    [required]="isRequired"
                    [owlDateTime]="timePicker"
                    [min]="dateTimeMinValue"
                    [max]="dateTimeMaxValue"
                    [formControlName]="config.name"
                    [attr.disabled]="disabled"
                    (dateTimeChange)="onDatePicked()"
                    (keyup)="onManualInputKeyUp($event)"
                    (change)="onManualInputChange($event)"
                    name="{{config.name}}">
        </div>
        <lumi-button *ngIf="allowClear"
                     [icon]="'close'"
                     [size]="'small'"
                     [rank]="'input'"
                     [title]="'dateInput.delete' | translate"
                     [disabled]="disabled"
                     (click)="clearDateTime()">
        </lumi-button>
        <lumi-button [icon]="'access_time'"
                     [size]="'small'"
                     [rank]="'input'"
                     [disabled]="disabled"
                     [title]="'dateInput.pick' | translate"
                     [owlDateTimeTrigger]="timePicker">
        </lumi-button>
    </ng-container>

    <!--Datetime input-->
    <ng-container *ngIf="inputType == 'datetime'">
        <owl-date-time #dateTimePicker [disabled]="disabled" [firstDayOfWeek]="1" [pickerType]="'both'"></owl-date-time>
        <div class="w-100">
            <input #dateTimePickerInput
                   [(ngModel)]="dateTimeModel"
                   [required]="isRequired"
                   [owlDateTime]="dateTimePicker"
                   [min]="dateTimeMinValue"
                   [max]="dateTimeMaxValue"
                   [formControlName]="config.name"
                   [attr.disabled]="disabled"
                   (dateTimeChange)="onDatePicked()"
                   (keyup)="onManualInputKeyUp($event)"
                   (change)="onManualInputChange($event)"
                   name="{{config.name}}">
        </div>
        <lumi-button *ngIf="allowClear"
                     [icon]="'close'"
                     [size]="'small'"
                     [rank]="'input'"
                     [title]="'dateInput.delete' | translate"
                     [disabled]="disabled"
                     (click)="clearDateTime()">
        </lumi-button>
        <lumi-button [icon]="'event'"
                     [size]="'small'"
                     [rank]="'input'"
                     [disabled]="disabled"
                     [title]="'dateInput.pick' | translate"
                     [owlDateTimeTrigger]="dateTimePicker">
        </lumi-button>
    </ng-container>
</div>



<!-- NON FORM -->
<div class="d-flex input-group p-1 pr-0 m-0 datetime-input border-color-grey-input" *ngIf="!readonly && !parentFormGroup">
    <!--Date input-->
    <ng-container *ngIf="inputType == 'date'">
        <owl-date-time #datePicker [disabled]="disabled" [firstDayOfWeek]="1" [pickerType]="'calendar'"></owl-date-time>
        <div class="w-100">
            <input #dateInput
                   [(ngModel)]="dateTimeModel"
                   [required]="isRequired"
                   [owlDateTime]="datePicker"
                   [min]="dateTimeMinValue"
                   [max]="dateTimeMaxValue"
                   [attr.disabled]="disabled"
                   (dateTimeChange)="onDatePicked()"
                   (keyup)="onManualInputKeyUp($event)"
                   (change)="onManualInputChange($event)">
        </div>
        <lumi-button *ngIf="allowClear"
                     [icon]="'close'"
                     [size]="'small'"
                     [rank]="'input'"
                     [title]="'dateInput.delete' | translate"
                     [disabled]="disabled"
                     (click)="clearDateTime()">
        </lumi-button>
        <lumi-button [icon]="'event'"
                     [size]="'small'"
                     [rank]="'input'"
                     [disabled]="disabled"
                     [title]="'dateInput.pick' | translate"
                     [owlDateTimeTrigger]="datePicker">
        </lumi-button>
    </ng-container>

    <!--Time input-->
    <ng-container *ngIf="inputType == 'time'">
        <owl-date-time #timePicker [disabled]="disabled" [firstDayOfWeek]="1" [pickerType]="'timer'"></owl-date-time>
        <div class="w-100">
            <input #timePickerInput
                   [(ngModel)]="dateTimeModel"
                   [required]="isRequired"
                   [owlDateTime]="timePicker"
                   [min]="dateTimeMinValue"
                   [max]="dateTimeMaxValue"
                   [attr.disabled]="disabled"
                   (dateTimeChange)="onDatePicked()"
                   (keyup)="onManualInputKeyUp($event)"
                   (change)="onManualInputChange($event)">
        </div>
        <lumi-button *ngIf="allowClear"
                     [icon]="'close'"
                     [size]="'small'"
                     [rank]="'input'"
                     [title]="'dateInput.delete' | translate"
                     [disabled]="disabled"
                     (click)="clearDateTime()">
        </lumi-button>
        <lumi-button [icon]="'access_time'"
                     [size]="'small'"
                     [rank]="'input'"
                     [disabled]="disabled"
                     [title]="'dateInput.pick' | translate"
                     [owlDateTimeTrigger]="timePicker">
        </lumi-button>
    </ng-container>

    <!--Datetime input-->
    <ng-container *ngIf="inputType == 'datetime'">
        <owl-date-time #dateTimePicker [disabled]="disabled" [firstDayOfWeek]="1" [pickerType]="'both'"></owl-date-time>
        <div class="w-100">
            <input #dateTimePickerInput
                   [(ngModel)]="dateTimeModel"
                   [required]="isRequired"
                   [owlDateTime]="dateTimePicker"
                   [min]="dateTimeMinValue"
                   [max]="dateTimeMaxValue"
                   [attr.disabled]="disabled"
                   (dateTimeChange)="onDatePicked()"
                   (keyup)="onManualInputKeyUp($event)"
                   (change)="onManualInputChange($event)">
        </div>
        <lumi-button *ngIf="allowClear"
                     [icon]="'close'"
                     [size]="'small'"
                     [rank]="'input'"
                     [title]="'dateInput.delete' | translate"
                     [disabled]="disabled"
                     (click)="clearDateTime()">
        </lumi-button>
        <lumi-button [icon]="'event'"
                     [size]="'small'"
                     [rank]="'input'"
                     [disabled]="disabled"
                     [title]="'dateInput.pick' | translate"
                     [owlDateTimeTrigger]="dateTimePicker">
        </lumi-button>
    </ng-container>
</div>

<!-- READONLY LABEL -->
<div class="form-read-only-label" *ngIf="readonly">{{getInitialInputForDisabledElement()}}</div>