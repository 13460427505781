import {ChangeDetectorRef, Component, EventEmitter, HostBinding, Input, OnInit, Output, Renderer2} from "@angular/core";
import {UntypedFormGroup} from "@angular/forms";
import {AbstractFormFieldComponent} from "../../abstract/abstract-form-field.component";
import {GlobalAlertService} from "../../../../../../wrapper/global-alert/global-alert.service";
import {ValidationConstraintService} from "../../../services/validation-constraint.service";
import {TooltipService} from "../../../../../services/tooltip/tooltip.service";
import {GlobalModel} from "../../../../../services/state/global.model";
import {TranslateService} from "../../../../../services/translate/translate.service";
import {AppSettings} from "../../../../../../app.settings";
import {LoggerService} from "../../../../../services/logger/logger.service";

@Component({
    selector: 'form-color-picker',
    template: `
        <ng-container [formGroup]="group" (globalMouseupOutsideAngular)="handleGlobalMouseUp($event)">
            
            <form-component-label [batchUpdateMode]="batchUpdateMode" [config]="config" [included]="included" (onClickInclude)="handleClickInclude($event)"></form-component-label>
            
            <div class="d-flex w-100">
                <div class="d-flex flex-column w-100">
                    <div #currentFormField (click)="handleClickDropDown($event)"  class="d-flex multi-select-drop-down-container {{isControlReadOnly()?'read-only':''}}" style="min-height: calc(2.25rem + 2px)">
                        <div *ngIf="selectedOption" class="d-flex align-items-center w-100">
                            <div class="color-picker-color-container {{isControlReadOnly()?'read-only':''}} w-100 d-flex align-items-center justify-content-center">
                                <div class="color-picker-color {{isControlReadOnly()?'read-only':''}}" [style.background-color]="selectedOption.color" style="border-radius: 5px; width: 100%; margin-left: 0.25rem; margin-right: 0.25rem;"></div>
                            </div>
                        </div>
                        <div *ngIf="!isControlDisabled()" style="margin-left:auto;"><i class="p-0 w-100 h-100 d-flex align-items-center material-icons unselectable-content grey-input-background" style="font-size: 20px;">arrow_drop_down</i></div>
                    </div>
                    <div class="" style="position: relative">
                        <div class="{{dropDownCollapsed?'d-none':'d-flex'}} flex-row flex-wrap w-100 color-picker-option-list">
                            <ng-container *ngFor="let option of colorOptions;">
                                <div class="d-flex align-items-center cursor-pointer"  (click)="handleClickOption($event, option)">
                                    <div class="color-picker-color-container d-flex align-items-center justify-content-center">
                                        <div class="color-picker-color" [style.background-color]="option.color"></div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
                <!--<div class="{{(disabled() == null)?'pl-1':''}} " *ngIf="config && config.attr && config.attr.creationHref"> &lt;!&ndash;'disabled-add-button'&ndash;&gt;
                    <div class="card {{(disabled() == null)?'cursor-pointer':'d-none'}} h-100 justify-content-center" (click)="handleAdd()" [title]="'Maak een nieuw item aan' | translate">
                        <i class="p-2 material-icons " style="font-size: 20px">add</i>
                    </div>
                </div>-->
            </div>

        </ng-container>`
})
export class FormColorPickerComponent extends AbstractFormFieldComponent implements OnInit {

    // TODO: DIT COMPONENT IS NOG NIET OFFICIEEL GETEST VOOR BATCHUPDATE EN REQUIRED FIELDS
    // TODO: Theoretisch zou het wel moeten werken met de huidige implementatie, maar dat is nog niet met zekerheid getoetst.

    @HostBinding('class') hostClasses = 'd-flex mt-1';
    @Output() onComponentEvent: EventEmitter<any> = new EventEmitter();
    @Input() invalidControlsErrors: any;

    config: any;
    group: UntypedFormGroup;
    readOnly: boolean;
    
    public colorOptions:any = [
        {id:"0", color:'#' + AppSettings.COLOR_CANARY_YELLOW},
        {id:"1", color:'#' + AppSettings.COLOR_YELLOW},
        {id:"2", color:'#' + AppSettings.COLOR_ORANGE},
        {id:"3", color:'#' + AppSettings.COLOR_RED},
        {id:"4", color:'#' + AppSettings.COLOR_RASPBERRY},
        {id:"5", color:'#' + AppSettings.COLOR_PURPLE},
        {id:"6", color:'#' + AppSettings.COLOR_MAGENTA},
        {id:"7", color:'#' + AppSettings.COLOR_BLUE},
        {id:"8", color:'#' + AppSettings.COLOR_SOFT_BLUE},
        {id:"9", color:'#' + AppSettings.COLOR_TURQUUOISE},
        {id:"10", color:'#' + AppSettings.COLOR_EMERALD},
        {id:"11", color:'#' + AppSettings.COLOR_GREEN},
        {id:"12", color:'#' + AppSettings.COLOR_SOFT_GREEN},
        {id:"13", color:'#' + AppSettings.COLOR_LIGHT_GREY},
        {id:"14", color:'#' + AppSettings.COLOR_GREY},
        {id:"15", color:'#' + AppSettings.COLOR_DARK_GREY},
        {id:"16", color:'#FF1493'},
        {id:"17", color:'#FFA500'},
        {id:"18", color:'#FF0000'},
        {id:"19", color:'#800080'},
        {id:"20", color:'#0000FF'},
        {id:"21", color:'#00FFFF'},
        {id:"22", color:'#90E900'},
        {id:"23", color:'#008000'},
        {id:"24", color:'#BC8F8F'},
        {id:"25", color:'#A52A2A'}
        ];
    
    public dropDownCollapsed:boolean = true;
    public selectedOption:any = null;

    constructor(private globalAlertService: GlobalAlertService, public cd:ChangeDetectorRef, public renderer: Renderer2, public validationConstraintService: ValidationConstraintService, public tooltipService: TooltipService, private model:GlobalModel, private trans:TranslateService, protected logger:LoggerService) {
        super(renderer, validationConstraintService, tooltipService, logger);
    }
    
    public handleClickOption(event:MouseEvent, option:any)
    {
        this.selectedOption = option;
        this.dropDownCollapsed = true;

        this.updateFormValue();
    }
    
    public handleGlobalMouseUp(event:MouseEvent):void{
        //TODO: Als er ooit een slimme manier is om dit zonder jquery te doen, dan die gebruiken

        // Look for parents matching the selector. If no match, you clicked outside the component
        // Zoek naar boven naar een color picker met daarin dflex en w100 classes op 1 element
        if ($(event.target).closest('form-color-picker>.d-flex.w-100').length == 0){
            this.dropDownCollapsed = true;
            this.cd.detectChanges();
        }
    }
    
    public handleClickDropDown(event:MouseEvent):void
    {
        if (!this.isControlDisabled()){
            this.dropDownCollapsed = !this.dropDownCollapsed;
        }
    }

    private updateFormValue():void
    {
        if (this.selectedOption){
            this.group.get(this.config.name).patchValue(this.selectedOption.color);
        }else{
            this.group.get(this.config.name).patchValue("");
        }

        if (this.isControlDisabled()){
            this.group.get(this.config.name)['disable']();
        }
    }

    ngOnInit()
    {
        if (this.config && this.config.initialValue) {
                this.colorOptions.forEach((option: any) => {
                    if (this.config.initialValue.toLowerCase() == option.color.toLowerCase()) {
                        option.checked = true;
                        this.selectedOption = option;
                    }
                });

            if (this.selectedOption == null){
                //Custom color. Not present in the dropdown
                this.selectedOption = {id:'-1', color:this.config.initialValue}
            }

        }else{
            this.logger.log("[FormColorPickerComponent] " + "No valid initial value for color picker component");
        }

        if (this.selectedOption == null){
            //No known color, no custom color. Take white...
            this.selectedOption = {id:'-1', color:'#FFFFFF'}
        }

        this.updateFormValue();
    }
}
