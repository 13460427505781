import {ChangeDetectorRef, Component, EventEmitter, Output, Renderer2} from '@angular/core';
import {AbstractFormFieldComponent} from '../abstract/abstract-form-field.component';
import {ValidationConstraintService} from '../../services/validation-constraint.service';
import {TooltipService} from '../../../../services/tooltip/tooltip.service';
import {GlobalModel} from '../../../../services/state/global.model';
import {FormDataService} from '../../services/form-data.service';
import {GlobalAlertService} from '../../../../../wrapper/global-alert/global-alert.service';
import {TranslateService} from '../../../../services/translate/translate.service';
import {FormEvent} from '../../containers/form/form.interface';
import {ListItem, ListItemMenuItemEvent} from '../../../commonUI/list/complex-list-item/complex-list-item.interface';
import { AppSettings } from 'src/app/app.settings';
import {
    Weekprijs, WeekprijsConfig,
} from './form-weekprijs.interface';
import {ButtonCode} from '../../../../../wrapper/global-alert/global-popup';
import {LoggerService} from "../../../../services/logger/logger.service";

@Component({
    selector: 'form-weekprijs',
    templateUrl: './form-weekprijs.component.html'
})

export class FormWeekprijsComponent extends AbstractFormFieldComponent {

    @Output() onComponentEvent: EventEmitter<any> = new EventEmitter();

    public weekprijzen: Weekprijs[] = [];

    public AppSettings = AppSettings;
    public total: number;
    public config: WeekprijsConfig;

    constructor(
        public renderer: Renderer2,
        public validationConstraintService: ValidationConstraintService,
        public tooltipService: TooltipService,
        private model: GlobalModel,
        private formDataService: FormDataService,
        private cd: ChangeDetectorRef,
        private globalAlertService: GlobalAlertService,
        public ts: TranslateService,
        protected logger:LoggerService
    ) {
        super(renderer, validationConstraintService, tooltipService, logger);
    }

    ngOnInit() {
        // Set the logs for the view
        if (this.config && this.config.attr) {
            if (this.config.attr.weekprijzen) {
                this.weekprijzen = this.config.attr.weekprijzen.map(weekprijs => {
                    weekprijs.listItem = this.getListItem(weekprijs);
                    return weekprijs;
                });
                this.total = this.weekprijzen.reduce((cumulative: number, weekprijs: Weekprijs) => {
                    cumulative = cumulative + weekprijs.weekprijsAantal * weekprijs.weekprijsBedrag;
                    return cumulative;
                }, 0);
            }
        }
    }

    public isDeletable(weekprijs: Weekprijs): boolean {
        return weekprijs.deleteUrl && weekprijs.deleteUrl !== '';
    }

    public isUpdatable(weekprijs: Weekprijs): boolean {
        return weekprijs.editUrl && weekprijs.editUrl !== '';
    }

    public handleClickUpdateWeekprijs(event: MouseEvent, weekprijs: Weekprijs): void {
        let termijnStaatId: number = weekprijs.termijnstaatId;

        this.globalAlertService.addPopupCreateWeekprijs(termijnStaatId, weekprijs.editUrl, weekprijs.saveEditUrl, null,
            () => {
                 this.onComponentEvent.emit({
                     event: FormEvent.WEEKPRIJS_CREATE_SUCCESS,
                     data: {termijnstaatId: termijnStaatId}
                 });
            }, () => {
                // Move is canceled, or failed. Do nothing
            });
    }

    public handleClickDeleteWeekprijs(event: MouseEvent, weekprijs: Weekprijs): void {
        this.globalAlertService.addPopup(this.ts.translate('weekprijs.deletetitle'), this.ts.translate('weekprijs.delete'),
            [
                {
                    label: this.ts.translate('Annuleren'),
                    code: ButtonCode.ANNULEREN,
                    isPrimary: true
                },
                {
                    label: this.ts.translate('Verwijderen'), code: ButtonCode.DELETE,
                    callback: () => {

                        // Show grayed-out specification until delete call finished
                        weekprijs.isDeleted = true;
                        this.cd.detectChanges();

                        this.formDataService.deleteWeekprijs(weekprijs.deleteUrl,
                            () => {
                                // Remove the old specification, client side
                                let deleteIndex: number = this.weekprijzen.indexOf(weekprijs);

                                if (deleteIndex > -1) {
                                    this.weekprijzen.splice(deleteIndex, 1);
                                    this.cd.detectChanges();
                                }

                                this.onComponentEvent.emit({
                                    event: FormEvent.WEEKPRIJS_DELETE_SUCCESS,
                                    data: {}
                                });
                            }, () => {
                                weekprijs.isDeleted = false;
                                this.cd.detectChanges();
                            }, () => {
                                weekprijs.isDeleted = false;
                                this.cd.detectChanges();
                            });
                    },
                    isPrimary: false
                }, ], () => {
            });
    }

    private getListItem(weekprijs: Weekprijs): ListItem {
        const item: ListItem = {
            id: JSON.stringify(weekprijs),
            title: weekprijs.code !== '' ? weekprijs.code : this.ts.translate('weekprijs.unknown'),
            icon: 'note_add',
            menuTitle: this.ts.translate('weekprijs.menu'),
            menuItems: [],
            created: weekprijs.created
        };
        if (this.isUpdatable(weekprijs)) {
            item.menuItems.push({
                label: this.ts.translate('menuItem.edit'),
                icon: 'edit',
                action: 'edit'
            });
        }
        if (this.isDeletable(weekprijs)) {
            item.menuItems.push({
                label: this.ts.translate('menuItem.delete'),
                icon: 'delete',
                action: 'delete'
            });
        }
        return item;
    }

    public onWeekprijsAction($event: ListItemMenuItemEvent, weekprijs: Weekprijs): void {
        switch ($event.action) {
            case 'edit':
                this.handleClickUpdateWeekprijs(null, weekprijs);
                break;
            case 'delete':
                this.handleClickDeleteWeekprijs(null, weekprijs);
        }
    }
}
