import {ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {IMyDate} from 'angular-mydatepicker';
import {FormFieldConfigInterface} from "../../../form/components/abstract/abstract-form-field.component";
import {UntypedFormGroup} from "@angular/forms";
import * as dayjs from 'dayjs'
import * as customParseFormat from 'dayjs/plugin/customParseFormat'
import {OwlDateTimeLocalization} from '../../../../components/commonUI/input/date-input/date-input.translations';
import {TranslateService} from '../../../../../shared/services/translate/translate.service';
import{TooltipService} from '../../../../services/tooltip/tooltip.service';

@Component({
    selector: 'date-input',
    templateUrl: './date-input.component.html'
})
export class DateInputComponent implements OnInit {
    @ViewChild('dateInput', {static: false}) private datePicker: ElementRef;
    @ViewChild('timePickerInput', {static: false}) private timePicker: ElementRef;
    @ViewChild('dateTimePickerInput', {static: false}) private dateTimePicker: ElementRef;

    @Input() inputType: 'date' | 'time' | 'datetime' = 'date';
    @Input() disabled: boolean = false;
    @Input() readonly: boolean = false;
    @Input() allowClear: boolean = true;
    @Input() batchUpdateMode: boolean = false;
    @Input() parentFormGroup:UntypedFormGroup | null = null;
    @Input() config: FormFieldConfigInterface | null = null;
    @Output() onDateChanged: EventEmitter<Date> = new EventEmitter();

    dateTimeMinValue: Date = null;
    dateTimeMaxValue: Date = null;
    dateTimeFormat: string[] = [];
    isRequired: boolean = false;
    input: ElementRef<HTMLInputElement> = null;
    dateTimeModel;

    constructor(
        public changeDetectorRef: ChangeDetectorRef,
        private ts: TranslateService,
        private tooltipService:TooltipService
    ) {}

    @Input() set minDate(date: IMyDate) {
        if (date !== null) {
            this.dateTimeMinValue = new Date(date.year, date.month, date.day);
            this.changeDetectorRef.detectChanges();
        }
    }

    @Input() set maxDate(date: IMyDate) {
        if (date !== null) {
            this.dateTimeMaxValue = new Date(date.year, date.month, date.day);
            this.changeDetectorRef.detectChanges();
        }
    }

    @Input() set initialValue(initialValue: string | IMyDate | null | undefined) {
        if (initialValue) {
            if(typeof initialValue === "string") {
                this.dateTimeModel = new Date(initialValue);
            } else {
                this.dateTimeModel = new Date(initialValue.year, initialValue.month - 1, initialValue.day);
            }
        }
        setTimeout(() => {
            this.changeDetectorRef.detectChanges();
        });
    }

    ngOnInit() {
        this.input = this.datePicker;
        this.readConfig();

        dayjs.extend(customParseFormat);
        require('dayjs/locale/'+this.ts.getLanguage());

        switch (this.inputType) {
            case "date": {
                this.dateTimeFormat.push("DD-MM-YYYY");
                this.dateTimeFormat.push("D-MM-YYYY");
                this.dateTimeFormat.push("DD-M-YYYY");
                this.dateTimeFormat.push("D-M-YYYY");
                break;
            }
            case "time": {
                this.dateTimeFormat.push("HH:mm");
                this.dateTimeFormat.push("H:mm");
                this.dateTimeFormat.push("HH:m");
                this.dateTimeFormat.push("H:m");
                break;
            }
            case "datetime": {
                this.dateTimeFormat.push("DD-MM-YYYY HH:mm");
                this.dateTimeFormat.push("DD-MM-YYYY HH:m");
                this.dateTimeFormat.push("DD-MM-YYYY H:mm");
                this.dateTimeFormat.push("DD-MM-YYYY H:m");
                this.dateTimeFormat.push("D-MM-YYYY HH:mm");
                this.dateTimeFormat.push("D-MM-YYYY HH:m");
                this.dateTimeFormat.push("D-MM-YYYY H:mm");
                this.dateTimeFormat.push("D-MM-YYYY H:m");
                this.dateTimeFormat.push("DD-M-YYYY HH:mm");
                this.dateTimeFormat.push("DD-M-YYYY HH:m");
                this.dateTimeFormat.push("DD-M-YYYY H:mm");
                this.dateTimeFormat.push("DD-M-YYYY H:m");
                this.dateTimeFormat.push("D-M-YYYY HH:mm");
                this.dateTimeFormat.push("D-M-YYYY HH:m");
                this.dateTimeFormat.push("D-M-YYYY H:mm");
                this.dateTimeFormat.push("D-M-YYYY H:m");
                break;
            }
        }
    }

    ngAfterViewInit() {
        this.readConfig();
    }

    clearDateTime() {
        if(!this.disabled && this.allowClear) {
            switch(this.inputType) {
                case "date": {
                    this.datePicker.nativeElement.value = "";
                    break;
                }
                case "time": {
                    this.timePicker.nativeElement.value = "";
                    break;
                }
                case "datetime": {
                    this.dateTimePicker.nativeElement.value = "";
                    break;
                }
            }
            this.dateTimeModel = "";
            this.changeDetectorRef.detectChanges();
        }
    }

    getInitialInputForDisabledElement():string {
        let returnString = "";
        if(this.dateTimeModel) {
            switch (this.inputType) {
                case "date": {
                    returnString = new Date(this.dateTimeModel)
                        .toLocaleString("nl-NL", {year: "numeric", month: "2-digit", day: "2-digit"});
                    break;
                }
                case "time": {
                    returnString = new Date(this.dateTimeModel)
                        .toLocaleString("nl-NL", {hour: "2-digit", minute: "2-digit"});
                    break;
                }
                case "datetime": {
                    returnString = new Date(this.dateTimeModel)
                        .toLocaleString("nl-NL", {
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit",
                            hour: "2-digit",
                            minute: "2-digit"
                        });
                    break;
                }
            }
        }
        return returnString;
    }

    public onDatePicked() {
        this.onDateChanged.emit(this.dateTimeModel);
        this.changeDetectorRef.detectChanges();
    }

    public onManualInputKeyUp(input):void{
        if(dayjs(input.target.value, this.dateTimeFormat, this.ts.getLanguage()).isValid()){
            this.tooltipService.hideAllTooltips();
        } else {
        }
    }

    public onManualInputChange(input):void{
        if(dayjs(input.target.value, this.dateTimeFormat, this.ts.getLanguage()).isValid()){
            this.dateTimeModel = dayjs(input.target.value, this.dateTimeFormat, this.ts.getLanguage()).toISOString();
            this.onDatePicked();
        }
    }

    private readConfig(): void {
        if (this.config) {
            if (this.batchUpdateMode && this.parentFormGroup) {
                this.parentFormGroup.controls[this.config.name].disable();
            }

            if (this.config.attr && this.config.attr.allowPastDates == false) {
                let today: Date = new Date();
                today.setDate(today.getDate() - 1);
                this.minDate = {
                    year: today.getFullYear(),
                    month: today.getMonth(),
                    day: today.getDate()
                };
            }

            this.isRequired = this.config.required;
        }

        setTimeout(() => {
            this.changeDetectorRef.detectChanges();
        });
    }
}
