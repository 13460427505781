import {
    AfterViewInit,
    Component,
    ElementRef, EventEmitter,
    HostListener,
    Input, Output,
    QueryList,
    ViewChild,
    ViewChildren
} from '@angular/core';
import {StatusItem} from './status-progress.interface';
import {SubStatusItem} from '../sub-statuses/sub-statuses.interface';
import {AppSettings} from '../../../../app.settings';
import Timeout = NodeJS.Timeout;

@Component({
    selector: 'status-progress',
    templateUrl: './status-progress.component.html',
})
export class StatusProgressComponent implements AfterViewInit {
    @ViewChildren('progressItem') progressItems: QueryList<ElementRef<HTMLDivElement>>;
    @ViewChild('scrollContainer', {static: false}) private scrollContainer: ElementRef<HTMLDivElement>;

    @Input() public statusItems: StatusItem[];
    @Input() public subStatuses: SubStatusItem[];
    @Input() public disabled: boolean = false;
    @Output() onStatusClick: EventEmitter<StatusItem> = new EventEmitter<StatusItem>();
  
    public INFO_WINDOW_WIDTH: number = 260;
    private infoWindowForStatus: StatusItem = null;
    private pressTimer: any;
    
    constructor() {
    }
    
    ngAfterViewInit() {
        let activeItem = this.statusItems.find(status => status.isActive);
        if (activeItem) {
            const element = this.progressItems.find(item => item.nativeElement.getAttribute('id') === String(activeItem.id));
            let scrollContainerPosition = element.nativeElement.parentElement.getBoundingClientRect().left;
            this.scrollContainer.nativeElement.scrollTo({
                left: element.nativeElement.getBoundingClientRect().left - scrollContainerPosition
            });
        }
    }
    
    public setShowInfoWindowForStatus($event: MouseEvent|TouchEvent, status: StatusItem): void {
        $event.stopImmediatePropagation();
        if (!this.infoWindowForStatus) {
            this.infoWindowForStatus = status;
        }
    }
    
    public getShowInfoWindowForStatus(status: StatusItem): boolean {
        return this.infoWindowForStatus === status;
    }
    
    @HostListener('window:click', ['$event'])
    public hideInfoWindow(): void {
        this.infoWindowForStatus = null;
    }
    
    public getInfoWindowPosition(status: StatusItem): { left: string, top: string } {
        const element = this.progressItems.find(item => item.nativeElement.getAttribute('id') === String(status.id));
        let statusItemPosition = element.nativeElement.getBoundingClientRect().left;
        let scrollContainerPosition = element.nativeElement.parentElement.getBoundingClientRect().left;
        let scrollContainerWidth = element.nativeElement.parentElement.getBoundingClientRect().width;
        let statusItemVerticalPosition = element.nativeElement.parentElement.parentElement.parentElement.getBoundingClientRect().bottom;
    
        return {
            left: Math.min(
                Math.max((statusItemPosition - scrollContainerPosition), 10),
                (scrollContainerWidth - (this.INFO_WINDOW_WIDTH * 3 / 4))
            ) + 'px',
            top: statusItemVerticalPosition - 50 + 'px'
        };
    }
    
    public getProgressItemClass(status: StatusItem): string {
        let itemClass = 'sp-icon';
        if (status.isActive) {
            itemClass = 'sp-icon-active';
            if (status.isFinished) {
                itemClass = 'sp-icon-finished-active';
            }
        } else if (status.isFinished) {
            itemClass = 'sp-icon-finished';
        }
        
        if (this.disabled) {
            itemClass = itemClass + '-disabled';
        }
        
        return itemClass;
    }
    
    public getColor(subStatus: SubStatusItem): { color: string } {
        let color: string = subStatus.color;
        if (this.disabled) {
            color = AppSettings.getColor('$color_darker-grey');
        }
        return {color: color};
    }
    
    public getBorderColor(subStatus: SubStatusItem): { 'border-color': string } {
        let color: string = subStatus.color;
        if (this.disabled) {
            color = AppSettings.getColor('$color_darker-grey');
        }
        return {'border-color': color};
    }
    
    public onTouchStart($event: TouchEvent, status: StatusItem) {
        this.pressTimer = setTimeout(() => {
            this.setShowInfoWindowForStatus($event, status);
        }, 1000);
    }
    
    public onTouchEnd($event: TouchEvent, status: StatusItem) {
        if (this.pressTimer) {
            clearTimeout(this.pressTimer);
            this.onStatusClick.emit(status);
        }
    }
    
    public handleStatusClick($event: MouseEvent, status: StatusItem) {
        this.onStatusClick.emit(status);
    }
}
