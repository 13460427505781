import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnInit,
    Output,
    Renderer2,
    ViewChild,
} from '@angular/core';
import 'moment/locale/nl';
import {MapCoreComponent} from '../../../map/map-core.component';
import {ValidationConstraintService} from '../../services/validation-constraint.service';
import {TranslateService} from '../../../../services/translate/translate.service';
import {TooltipService} from '../../../../services/tooltip/tooltip.service';
import {GlobalAlertService} from '../../../../../wrapper/global-alert/global-alert.service';
import {FormDataService} from '../../services/form-data.service';
import {GlobalModel} from '../../../../services/state/global.model';
import {AbstractFormWidget} from './abstract-form-widget';
import {HTTPService} from '../../../../services/http/http.service';
import {MapTableService} from '../../../map-table/map-table.service';
import { FormEvent } from '../../containers/form/form.interface';
import {LoggerService} from "../../../../services/logger/logger.service";

@Component({
    selector: 'form-map',
    template: `
        <ng-container [formGroup]="group">
            <label *ngIf="config.label && config.label != ''"
                   class="form-sub-title d-flex {{!showDatePickers?'mb-0':''}} pt-3 pb-1"
                   for="{{config.name}}">{{ config.label }}
                <ng-container *ngIf="infoText">
                    <help-icon-component [tooltipText]="infoText"
                                         [tooltipPlacement]="PLACEMENT_BOTTOM"></help-icon-component>
                </ng-container>
            </label>
            <div *ngIf="showDatePickers" class="line-graph-date-row">
                <div [title]="'Startdatum' | translate" class="col-6 date-row-left">
                    <date-input [inputType]="'date'"
                                [allowClear]="false"
                                [maxDate]="disableUntil"
                                [initialValue]="startDate"
                                (onDateChanged)="onStartDateChanged($event)">
                    </date-input>
                </div>
                <div [title]="'Einddatum' | translate" class="col-6 date-row-right no-padding">
                    <date-input [inputType]="'date'"
                                [allowClear]="false"
                                [maxDate]="disableUntil"
                                [initialValue]="endDate"
                                (onDateChanged)="onEndDateChanged($event)">
                    </date-input>

                </div>
            </div>
            <div class="d-flex lightgrey-background">
                <map-core-component #mapComponent [allowLocation]="true" [allowAutoLoadMarkers]="true"
                                    [allowCreateMarker]="false" [allowMarkerDrag]="false" [allowSingleSelect]="true" [isMapInPopup]="true"
                                    [allowMultiSelect]="false"
                                    [isLoading]="httpService.isPendingCallPath([mapTableService.MAP_ITEM_PATH])"
                                    (onClickMarker)="handleClickMarker($event)"
                                    [disableClusterer]="true"
                                    (onBoundsChangedDuringAutoLoad)="handleBoundsChanged($event)"
                                    [ngStyle]="{width:'100%', height:'500px'}"
                                    class="map-table-component"></map-core-component>
            </div>
        </ng-container>
    `,
})
export class FormMapComponent extends AbstractFormWidget implements AfterViewInit, OnInit {
    @ViewChild('mapComponent', {static: false}) mapComponent: MapCoreComponent;
    @Output() onComponentEvent: EventEmitter<any> = new EventEmitter<any>();
    @Output() onAutoLoadRequest: EventEmitter<any> = new EventEmitter();

    private static readonly ACTION_MOVE_MAPITEM: string = 'MOVE_MAPITEM';
    private static readonly ACTION_CREATE_PV_STORING: string = 'CREATE_PV_STORING';
    private static readonly ACTION_CREATE_MSB_MELDING: string = 'CREATE_MSB_MELDING';
    private static readonly ACTION_ADD_MSB_MELDING_TO_WORKORDER: string = 'ADD_MSB_MELDING_TO_WORKORDER';

    private static readonly ACTION_CREATE_WORKORDER_FROM_EXTERNAL_MALFUNCTION_REPORT: string = 'CREATE_WORKORDER_FROM_EXTERNAL_MALFUNCTION_REPORT';
    private static readonly ACTION_ADD_EXTERNAL_MALFUNCTION_REPORT_TO_WORKORDER: string = 'ADD_EXTERNAL_MALFUNCTION_REPORT_TO_WORKORDER';


    constructor(public httpService: HTTPService, public mapTableService: MapTableService, public renderer: Renderer2, public validationConstraintService: ValidationConstraintService, public ts: TranslateService, public tooltipService: TooltipService, public globalAlertService: GlobalAlertService, public formDataService: FormDataService, public cd: ChangeDetectorRef, public model: GlobalModel, protected logger:LoggerService) {
        super(renderer, validationConstraintService, tooltipService, model, formDataService, globalAlertService, ts, cd, logger);
    }

    ngOnInit(): void {
        if (this.config.attr && this.config.attr.url) {
            this.initWidget('' + this.config.attr.url);
        }
    }

    ngAfterViewInit() {
        this.handleAfterViewInit();
    }

    protected handleWidgetDataReceived(json: any): void {
        if (json.mapItems) {
            this.mapComponent.replaceMarkers(json.mapItems, false, true);
        }
    }

    handleBoundsChanged(event: any): void {
        this.onAutoLoadRequest.emit(event);
        this.cd.detectChanges();
    }

    handleClickMarker(clickData: any): void {
        this.logger.log('[FormMapComponent] ' + 'Click marker on widget: ', clickData);

        let mapItemRef: any = clickData.markers[0].dataRef;
        let baseObjectId: number = mapItemRef.baseObjectId;
        let action: string = '';

        if (mapItemRef.actionUrl) {
            action = mapItemRef.actionUrl;
        }
        else if (mapItemRef.baseObjects) {
            mapItemRef.baseObjects.forEach((baseObject: any) => {
                if (baseObject.id == baseObjectId) {
                    if (baseObject.actionUrl && baseObject.actionUrl != '') {
                        action = baseObject.actionUrl;
                    }
                }
            });
        }
        else {
            this.logger.log('[FormMapComponent] no action url found in map item and/or base object');
        }

        if (action != '') {
            // TODO: als er meer komt een switchje van maken
            if (action == FormMapComponent.ACTION_MOVE_MAPITEM) {
                this.globalAlertService.addPopupMoveItem(mapItemRef.id,
                    () => {
                        //Object is successfully moved
                        this.onComponentEvent.emit({
                            event: FormEvent.MOVE_MAPITEM_SUCCESS,
                            data: {mapItemId: mapItemRef.id},
                        });
                    }, () => {
                        //Move is canceled, or failed. Do nothing
                    }, mapItemRef.lat, mapItemRef.lng);
            }
            if (action === FormMapComponent.ACTION_CREATE_PV_STORING) {
                // is dit nou de check of er een popup mag komen, checken op icon ???
                if (mapItemRef.icon === 'mst-blue') {
                    this.globalAlertService.addPopupCreatePVStoring(mapItemRef.id,
                        () => {
                            // Object is successfully paired
                            this.onComponentEvent.emit({
                                event: FormEvent.PERFECTVIEW_PAIRED_SUCCESS,
                                data: {},
                            });
                            this.onComponentEvent.emit({event: FormEvent.CANCEL, data: {}});
                        }, () => {
                            // Move is canceled, or failed. Do nothing
                        }, mapItemRef.lat, mapItemRef.lng);
                }
            }
            if (action === FormMapComponent.ACTION_CREATE_MSB_MELDING) {
                this.globalAlertService.addPopupCreateMsbMelding(mapItemRef.id,
                    () => {
                        // Object is successfully paired
                        this.onComponentEvent.emit({
                            event: FormEvent.MSB_MELDING_PAIRED_SUCCESS,
                            data: {},
                        });
                        this.onComponentEvent.emit({event: FormEvent.CANCEL, data: {}});
                    }, () => {
                        // Move is canceled, or failed. Do nothing
                    }, mapItemRef.lat, mapItemRef.lng);
            }
            if (action === FormMapComponent.ACTION_CREATE_WORKORDER_FROM_EXTERNAL_MALFUNCTION_REPORT) {
                this.globalAlertService.addPopupCreateWorkorderFromExternalMalfunctionReport(mapItemRef.id,
                    () => {
                        // Object is successfully paired
                        this.onComponentEvent.emit({
                            event: FormEvent.EXTERNAL_MALFUNCTION_REPORT_PAIRED_SUCCESS,
                            data: {},
                        });
                        this.onComponentEvent.emit({event: FormEvent.CANCEL, data: {}});
                    }, () => {
                        // Move is canceled, or failed. Do nothing
                    }, mapItemRef.lat, mapItemRef.lng);
            }
            if (action === FormMapComponent.ACTION_ADD_EXTERNAL_MALFUNCTION_REPORT_TO_WORKORDER) {
                this.globalAlertService.addPopupAddExternalMalfunctionReportToWorkorder(mapItemRef.id,
                    () => {
                        // Object is successfully paired
                        this.onComponentEvent.emit({
                            event: FormEvent.EXTERNAL_MALFUNCTION_REPORT_PAIRED_SUCCESS,
                            data: {},
                        });
                        this.onComponentEvent.emit({event: FormEvent.CANCEL, data: {}});
                    }, () => {
                        // Move is canceled, or failed. Do nothing
                    });
            }
            if (action === FormMapComponent.ACTION_ADD_MSB_MELDING_TO_WORKORDER) {
                this.globalAlertService.addPopupAddMsbMeldingToWorkorder(mapItemRef.id,
                    () => {
                        // Object is successfully paired
                        this.onComponentEvent.emit({
                            event: FormEvent.MSB_MELDING_PAIRED_SUCCESS,
                            data: {},
                        });
                        this.onComponentEvent.emit({event: FormEvent.CANCEL, data: {}});
                    }, () => {
                        // Move is canceled, or failed. Do nothing
                    });
            }

        }
    }
}
