import {Component, OnInit, Input, ViewChild, ChangeDetectorRef} from '@angular/core';
import {WidgetService} from "../widget.service";
import {RequestFailure} from "../../../../shared/services/http/request-failure";
import {Widget} from "../widget";
//import moment = require("moment");
//import * as moment from 'moment/moment';

import * as moment from 'moment/moment';
import {Activity} from "./activity";
import {MenuService} from "../../../../dynamic-menu/menu/menu.service";
//import 'moment/locale/nl';


@Component({
    selector: 'feed-widget-component',
    template: `
    <div class="card widget-panel">
        <div class="widget-title">{{widget.title}}</div>
        <div class="feed-widget-container-container">
            <div class="feed-widget-container" *ngIf="!isLoading && activities && activities.length > 0; else no_content">
                <div class="card card-panel feed-widget-activity" *ngFor="let activity of activities">
                    <!--TODO: Als de implementatie af is, naar objecten linken met standaard a href-->
                    <div><b>{{activity.name}}</b> {{'plaatste bericht op' | translate}} <span class="feed-widget-activity-link" (click)="handleClickActivityObject(activity)">{{getActivityType(activity)}} {{activity.objectId}}</span> {{'om' | translate}} {{getFormattedDate(activity.dateTime)}}</div>
                    <div class="feed-widget-message">{{getTruncatedMessage(activity)}}<i *ngIf="isMessageTruncatable(activity)" (click)="activity.truncate = (!activity.truncate)" class="material-icons feed-widget-message-btn-expand">{{activity.truncate?'expand_more':'expand_less'}}</i></div>
                </div>
            </div>
        </div>
        <!--<div class="chart-widget-filler"></div>-->
         <div *ngIf="widget.description" class="widget-subtext-container"> 
            <i class="material-icons md-18 widget-subtext-icon">info_outline</i>
            <div class="widget-subtext">
                {{widget.description}}
            </div>
        </div>
    </div>
    
<!--    <ng-template #no_description> 
        <div style="height: 2.65rem"> </div>
    </ng-template>-->
    
    <ng-template #no_content>
        <div class="chart-widget-no-data-container">
            <div class="chart-widget-no-data">{{isLoading?'':'Geen informatie beschikbaar' | translate}}</div>    
        </div>
    </ng-template>
`
})

export class FeedWidgetComponent implements OnInit
{
    @Input('widget') public widget:Widget;

    public activities:Activity[];
    public isLoading = true;

    private static readonly TRUNCATED_MESSAGE_LENGTH:number = 300;

    constructor(private widgetService:WidgetService, private menuService:MenuService, private cd:ChangeDetectorRef) {}

    ngOnInit() {
        this.isLoading = true;
        this.widgetService.getWidgetData(this.widget.id, "",
            (json:any) => {
                this.isLoading = false;
                this.activities = json.activities; //plainToClass(Activity, json.activities);
                this.widget.description = json.description;
                this.cd.markForCheck();
            },
            (failure:RequestFailure) => {}
        )

        this.cd.markForCheck();
    }

    public getFormattedDate(date:string)
    {
        return moment(date).format('DD-MM-YYYY HH:mm');
    }

    public handleClickActivityObject(activity:Activity)
    {
        if (activity.actionUrl){
            this.widgetService.handleClickItem(activity.actionUrl)
        }
    }

    public getTruncatedMessage(activity:Activity)
    {
        if (activity.truncate && this.isMessageTruncatable(activity)){
            return activity.message.substring(0, FeedWidgetComponent.TRUNCATED_MESSAGE_LENGTH) + "...";
        }else{
            return activity.message;
        }
    }

    public getActivityType(activity:Activity):string
    {
        let result:string = activity.objectType;

        result = result.toLowerCase()
        result = result.substr(0,1).toUpperCase() + result.substr(1);
        result = result.replace("_", "");

        return result;
    }

    public isMessageTruncatable(activity:Activity){
        return activity.message && activity.message.length > FeedWidgetComponent.TRUNCATED_MESSAGE_LENGTH;
    }
}
