/**
 * Created by Christiaan on 07/04/2017.
 */

import {Directive, NgZone, Output, EventEmitter, ElementRef} from '@angular/core';

@Directive({
    selector: '[mouseoverOutsideAngular]'
})

export class MouseoverOutsideAngularDirective
{
    private static readonly EVENT_MOUSE_OVER:string = "mouseover";

    @Output('mouseoverOutsideAngular') mouseoverHandler: EventEmitter<any> = new EventEmitter();

    constructor(private elementRef: ElementRef, private ngZone:NgZone)
    {
        this.ngZone.runOutsideAngular(() => {
            this.elementRef.nativeElement.addEventListener(MouseoverOutsideAngularDirective.EVENT_MOUSE_OVER, (event:any)=> {
                this.mouseoverHandler.emit(event);
            })
        });
    }

    //Alternatieve aanpak via hostlistener:
    /*
    @HostListener('window:mouseover', ['$event'])

    handleMouseover(event: Event) {
        this.mouseoverHandler.emit(event);
    }
    */
}
