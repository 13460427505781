<div class="form-group form-group--{{name}} {{getClassNames()}}" id="{{name}}" [hidden]="!isVisible()">

    <sub-statuses [subStatuses]="subStatuses"></sub-statuses>

    <div class="my-2 {{config.attr && config.attr.selectAllCheckboxGroups?'d-block':'d-none'}}">
        <lumi-button (onClick)="handleClickSelectAllCheckboxes()"
                     [rank]="'secondary'"
                     [label]="'checkbox.selectAll' | translate">
        </lumi-button>
    </div>

    <div (click)="handleClickExpand()"
         (keyup)="onKeyUp($event)"
         style="margin-left: -2px"
         class="form-group-label-container {{showLabel()?'mb-3':'m-0'}} {{!isCollapsible() || expanded?'':'collapsed'}}">
        <ng-container *ngIf="isCollapsible() && showLabel()">
            <i class="material-icons tree-node-icon"
               #expandSwitch
               tabindex="0"
               (keydown)="onKeyDownToggler($event)">{{expanded ? 'expand_more' : 'keyboard_arrow_right'}}</i>
        </ng-container>
        <div style="display: inline-flex; width: 100%; min-width:85%">
            <ng-container *ngIf="showLabel()">
                <div style="align-self: center" class="form-group-label form-group-label--{{name}} mb-0" [ngClass]="{'text-truncate':config.children.length > 0}" [title]="label">{{label}}</div>
            </ng-container>
            <ng-container *ngIf="infoText">
                <help-icon-component [tooltipText]="infoText"
                                     [tooltipPlacement]="PLACEMENT_BOTTOM">
                </help-icon-component>
            </ng-container>
            <div style="margin-left: auto; margin-right: -5px; margin-top: -9px; margin-bottom: -10px;" *ngIf="config?.attr?.headerButton">
                <lumi-button (onClick)="handleClickHeaderButton($event)"
                             [disabled]="disabled()"
                             [size]="'medium'"
                             [label]="config.attr.headerButton.label"
                             [title]="config.attr.headerButton.attr?.toolTip"
                             [rank]="'secondary'"
                             [icon]="config.attr.headerButton.attr?.icon">
                </lumi-button>
            </div>
        </div>
    </div>
    <ng-container *ngIf="showDescription()">
        <label [innerHtml]="config.attr.description" class="{{level == 0?'form-padding':''}}"></label>
    </ng-container>

    <div style="display: inherit" class="{{expanded?'':'d-none'}} w-100">
        <ng-container *ngFor="let childConfig of config.children">
            <ng-container *ngIf="notButtonsOnly(childConfig) && isChildVisible(childConfig)"
                          fieldDirective
                          [config]="childConfig"
                          [rootConfig]="rootConfig"
                          [group]="form"
                          (onComponentEvent)="handleComponentEvent($event)"
                          [validationConstraints]="validationConstraints"
                          [invalidControlsErrors]="invalidControlsErrors"
                          [readOnly]="readOnly"
                          [formIsSubmitted]="formIsSubmitted"
            >
            </ng-container>
        </ng-container>
    </div>

    <!--TODO: Deze melding vervangt voor een groot deel het oude validation-warning component. Ooit kan dat component verder worden uitgefaseerd-->
    <div class="validation-warning" *ngIf="validationConstraints && isMutationButtonGroup()">
        <div class="d-flex align-items-center flex-row">
            <i class="material-icons">warning</i>
            <p><strong>{{"invalidform" | translate}}</strong></p>
        </div>
    </div>
</div>
