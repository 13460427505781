import {GlobalAlertService} from "../../wrapper/global-alert/global-alert.service";
import Utils from "./utils";
import {NgZone} from "@angular/core";
import WMSUtils from "./wms-utils";
import { HttpClient,HttpParams } from '@angular/common/http';
import {HttpService2} from '../services/http-service-2.0/http2.0.service';
import {map, repeat, take} from 'rxjs/operators';
import {CalamityControlPanel} from '../../modules/calamity-control/calamity-control.interface';
import {StorageService} from '../services/storage/storage.service';
import {LoggerService} from "../services/logger/logger.service";
declare var google: any;

/**
 * Created by Christiaan on 21/03/2017.
 */
export default class MapWMSLayerGenerator {

    public static SHOWN_WMS_ERROR_WARNING: boolean = false;
    public static readonly TILE_SIZE: number = 512; //Not too small, to avoid a wave of requests after panning

    //Coordinate reference systems
    private static CRS_CRS_84: string = "CRS:84";    //Most common in WMS
    private static CRS_EPSG_4326: string = "EPSG:4326";  //Similair to CRS_84 qua coordinates
    private static CRS_EPSG_28992: string = "EPSG:28992";
    private static CRS_EPSG_3857: string = "EPSG:3857"; //Spherical Mercator, google maps coordinaat (new format?)
    private static CRS_EPSG_102100: string = "EPSG:102100";  //Spherical Mercator, google maps coordinaat (old format?)
    private static CRS_EPSG_900913: string = "EPSG:900913";

    //Most WMS have CRS_EPSG_4326 support (basically lat/lng coordinates)
    //If this needs to change in the future, don't forget to create new BBOX support for the CRS

    //TODO: CRS is weer teruggezet naar 3857/102100 voor gld en zuid holland wms, die worden er scherper van. Is er een reden dat eerst 4326 aanstond?
    public static CURRENT_CRS: string = MapWMSLayerGenerator.CRS_EPSG_3857; //MapWMSLayerGenerator.CRS_EPSG_4326; //MapWMSLayerGenerator.CRS_CRS_84; //MapWMSLayerGenerator.CRS_EPSG_102100;

    //Use GetCapabilities on the WMS to get available coordinate reference systems (crs)
    //For default Dutch WMS the following crs are available: CRS:84, EPSG:4326, EPSG:28992. Although CRS_EPSG_102100 seems to be supported too

    private wmsLayers: any[] = [];
    private hasImplementationError: boolean = false;

    //FullWMS: fullscreen wms layer without streaming
    //TiledWMS: tiled wms with streaming (preferred)
    constructor(private zone: NgZone, private enableFullWMS: boolean, private enableTiledWMS: boolean, private httpService:HttpService2, private storage:StorageService, protected logger:LoggerService) {
    }

    public tryWMSSettings(wmsData: any, errorCallback: Function) {
        // if (!MapWMSLayerGenerator.SHOWN_WMS_ERROR_WARNING) {

            if (!wmsData) {
                this.logger.log("[MapCoreComponent] " + "No WMS to check, skipping WMS-test... " + wmsData.label);
                return;
            }

            let wmsRootURL: string = wmsData.url;
            let wmsLayers: any[] = wmsData.layers;

            //Use a tile from google map as default testpoint (it's CRS independent)
            let testURL = this.getBasicWMSLayerURL(MapWMSLayerGenerator.TILE_SIZE, MapWMSLayerGenerator.TILE_SIZE, wmsRootURL, this.getWMSLayersString(wmsLayers)) + this.getTiledBbox({
                x: 263,
                y: 168
            }, 10);

            if (wmsLayers == undefined || wmsLayers.length < 1) {
                this.triggerErrorAlert(errorCallback, true);
            }

            this.logger.log("[MapWMSLayerGenerator] " + wmsData.label + " Test WMS for: " + testURL);

            //TODO: als er een nettere oplossing is om te testen of de url werkt, hier implementeren
            //Vanalles geprobeerd hier om op een nette manier te checken of de wms wil inladen
            //Uiteindelijk is de makkelijkste/snelste manier om gewoon een plaatje aan te maken met de testurl. Je kan dan goed bij de load/error events
            let testImg = new Image();
            testImg.addEventListener("load",
                () => {
                    this.logger.log("[MapWMSLayerGenerator] " + "WMS test performed, url loaded correctly: " + wmsData.label);
                });
            testImg.addEventListener("error",
                (event: any) => {
                    this.logger.log("[MapWMSLayerGenerator] " + wmsData.label + " WMS test performed, url loaded incorrectly. Details: ", event);
                    //Prevent the warning to popup more than once (check is performed again, two requests might have been loading at the same time)
                    // if (!MapWMSLayerGenerator.SHOWN_WMS_ERROR_WARNING) {
                        this.triggerErrorAlert(errorCallback, false);
                    // }
                });
            testImg.src = testURL;
        // }
    }

    private triggerErrorAlert(errorCallBack: Function, isLayerError: boolean) {
        MapWMSLayerGenerator.SHOWN_WMS_ERROR_WARNING = true;
        errorCallBack(this.hasImplementationError, isLayerError);
    }

    //OVERLAY WMS LAYER (full size overlay, one image equals the entire map. Doesn't refresh very smoothly and can take some time to load)
    //Trigger every time the map bounds change
    public addFullWMSOverlay(map: any, wmsData: any, index:number): void {
        if (!this.enableFullWMS || !wmsData) {
            return;
        }

        let wmsRootURL: string = wmsData.url;
        let wmsLayers: any[] = wmsData.layers;

        this.removeFullWMSOVerlay(index);

        this.zone.runOutsideAngular(() => {

            let mapBounds: any = map.getBounds();
            let mapDiv: any = map.getDiv();

            this.wmsLayers[index] = new google.maps.GroundOverlay(this.getBasicWMSLayerURL(mapDiv.offsetWidth, mapDiv.offsetHeight, wmsRootURL, this.getWMSLayersString(wmsLayers)) + this.getFullMapBbox(mapBounds), mapBounds);
            this.wmsLayers[index].setMap(map);
        });
    }

    public removeFullWMSOVerlay(index:number): void {
        if (!this.enableFullWMS) {
            return
        }

        this.zone.runOutsideAngular(() => {
            if (this.wmsLayers[index]) {
                this.wmsLayers[index].setMap(null);
            }
        });
    }

    //TILED WMS LAYER, PREFERRED METHOD (more stable when panning and can show part of the overlay when not everything is loaded yet (streaming))
    //Set up once and turn on/off by adding/removing the layer
    private  getTiledWMSOverlayOptions(map: any, wmsRootURL: string, wmsLayers: any[], name: string): any {

        return {
            getTileUrl: (coord: any, zoom: any) => {
                return this.getBasicWMSLayerURL(MapWMSLayerGenerator.TILE_SIZE, MapWMSLayerGenerator.TILE_SIZE, wmsRootURL, this.getWMSLayersString(wmsLayers)) + this.getTiledBbox(coord, zoom);
            },
            tileSize: new google.maps.Size(MapWMSLayerGenerator.TILE_SIZE, MapWMSLayerGenerator.TILE_SIZE),
            minZoom: 0,
            maxZoom: 50,
            opacity: 1, //1 = Full opacity
            isPng: true,
            name: name
        };
    }

    public addTiledWMSOverlay(map: any, wmsData: any, index:number): void {
        if (!this.enableTiledWMS || !wmsData) {
            return
        }

        let wmsRootURL: string = wmsData.url;
        let wmsLayers: any[] = wmsData.layers;

        this.zone.runOutsideAngular(() => {
            let overlayWMS = new google.maps.ImageMapType(this.getTiledWMSOverlayOptions(map, wmsRootURL, wmsLayers, wmsData.id));

            overlayWMS.baseGetTile = overlayWMS.getTile;

            // Override getTile so we may add event listeners to know when the images load
            overlayWMS.getTile = function (tileCoord: any, zoom: number, ownerDocument: any) {

                // Get the DOM node generated by the out-of-the-box ImageMapType
                let node: any = overlayWMS.baseGetTile(tileCoord, zoom, ownerDocument);

                //TODO: geprobeerd om hier een nette handler aan een image te hangen zodat je ziet of hij correct wordt ingeladen of niet
                //Dat is niet gelukt. De image wordt in code aangemaakt bij GM, en er is niet met zekerheid te zeggen of hij bestaat of misschien al is ingeladen
                //De events willen ook niet bubbelen, use capture werkt ook niet
                //Voor nu deze methode hier gelaten, want hij geeft wel inzicht in 'wanneer' een tile wordt ingeladen

                //this.logger.log("[MapWMSLayerGenerator] " + "Requesting tile...");

                return node;
            };

            let layers = map.overlayMapTypes.getArray();
            // Check if layer exists in array to add or not
            let layer = layers.find(_layer => _layer?.name === wmsData.id)
            if (layer) {
                return;
            } else {
                if (wmsData.active) {
                    map.overlayMapTypes.push(overlayWMS);
                }
            }
        });
    }

    public removeTiledWMSOverlay(map: any): void {
        if (!this.enableTiledWMS) {
            return;
        }
        map.overlayMapTypes.clear();
    }

    public removeSingleTiledWMSOverlay(map: any, id:number): void {
        if (!this.enableTiledWMS) {
            return;
        }

        let layers = map.overlayMapTypes.getArray();
        let layer = layers.find(_layer => _layer.name === id)

        if (layer) {
            let index = layers.findIndex(_layer => _layer === layer)
            // Set with null value, so index stays the same
            map.overlayMapTypes.removeAt(index);
        }
    }

    private getWMSLayersString(wmsLayers: any[]): string {

        let wmsLayersString: string = "";

        if (wmsLayers) {
            //Extract all layer values and create one layer string
            for (let i = 0; i < wmsLayers.length; i++) {
                wmsLayersString += wmsLayers[i].value + (i != wmsLayers.length - 1 ? "," : "");
            }
        }

        return wmsLayersString
    }

    private getFullMapBbox(mapBounds: any): string {
        //Create boundingbox. Implementation depends on CRS

        //RD coordinates for CRS_EPSG_28992
        //var bbox:string = Utils.wgs2rd_2(mapBounds.getSouthWest().lat(), mapBounds.getSouthWest().lng()) + "," + Utils.wgs2rd_2(mapBounds.getNorthEast().lat(), mapBounds.getNorthEast().lng())

        //Lng/lat coordinates for CRS_CRS_84
        //var bbox:string = mapBounds.getSouthWest().lng() + "," + mapBounds.getSouthWest().lat() + "," + mapBounds.getNorthEast().lng() + "," + mapBounds.getNorthEast().lat()

        //Lat/lng coordinates for CRS_EPSG_4326
        //TODO: als deze weer aangaat, en CRS is EPSG_4326, check of hier de lat lng goed staan. Moeten mogelijk worden omgedraaid
        if (MapWMSLayerGenerator.CURRENT_CRS == MapWMSLayerGenerator.CRS_EPSG_4326) {
            return "&BBOX=" + mapBounds.getSouthWest().lat() + "," + mapBounds.getSouthWest().lng() + "," + mapBounds.getNorthEast().lat() + "," + mapBounds.getNorthEast().lng();
        } else if (MapWMSLayerGenerator.CURRENT_CRS == MapWMSLayerGenerator.CRS_EPSG_102100) {
            //Web mercator coordinates for CRS_EPSG_102100
            return "&BBOX=" + Utils.latLonToMercator(mapBounds.getSouthWest().lat(), mapBounds.getSouthWest().lng()) + "," + Utils.latLonToMercator(mapBounds.getNorthEast().lat(), mapBounds.getNorthEast().lng())
        }

        return this.handleUnknownCRS();
    }

    private getTiledBbox(coord: any, zoom: any): string {
        //this.logger.log("[MapWMSLayerGenerator] " + "coord: " + coord + "zoom: " + zoom, coord);

        let lULP = new google.maps.Point(coord.x * MapWMSLayerGenerator.TILE_SIZE, (coord.y + 1) * MapWMSLayerGenerator.TILE_SIZE);
        let lLRP = new google.maps.Point((coord.x + 1) * MapWMSLayerGenerator.TILE_SIZE, coord.y * MapWMSLayerGenerator.TILE_SIZE);

        let projectionMap = new WMSUtils();

        //Handle 3857 and 102100 the same (new and old format)
        if (MapWMSLayerGenerator.CURRENT_CRS == MapWMSLayerGenerator.CRS_EPSG_3857 || MapWMSLayerGenerator.CURRENT_CRS == MapWMSLayerGenerator.CRS_EPSG_102100) {
            let lULg = projectionMap.fromDivPixelToSphericalMercator(lULP, zoom);
            let lLRg = projectionMap.fromDivPixelToSphericalMercator(lLRP, zoom);

            let lUL_Latitude = lULg.y;
            let lUL_Longitude = lULg.x;
            let lLR_Latitude = lLRg.y;
            let lLR_Longitude = lLRg.x;

            //GJ: there is a bug when crossing the -180 longitude border (tile does not render) - this check seems to fix it
            if (lLR_Longitude < lUL_Longitude) {
                lLR_Longitude = Math.abs(lLR_Longitude);
            }

            return "&BBOX=" + lUL_Longitude + "," + lUL_Latitude + "," + lLR_Longitude + "," + lLR_Latitude;

        } else if (MapWMSLayerGenerator.CURRENT_CRS == MapWMSLayerGenerator.CRS_EPSG_4326) {
            let lULg = projectionMap.fromDivPixelToLatLng(lULP, zoom);
            let lLRg = projectionMap.fromDivPixelToLatLng(lLRP, zoom);

            let lUL_Latitude = lULg.lng();
            let lUL_Longitude = lULg.lat();
            let lLR_Latitude = lLRg.lng();
            let lLR_Longitude = lLRg.lat();

            //GJ: there is a bug when crossing the -180 longitude border (tile does not render) - this check seems to fix it
            //TODO: moeten long en lat voor deze check worden omgedraaid? Fix werkt mogelijk niet nu
            if (lLR_Longitude < lUL_Longitude) {
                lLR_Longitude = Math.abs(lLR_Longitude);
            }

            return "&BBOX=" + lUL_Longitude + "," + lUL_Latitude + "," + lLR_Longitude + "," + lLR_Latitude;
        }

        return this.handleUnknownCRS();
    }

    private handleUnknownCRS(): string {
        //This generator is set to a certain CRS. When it isn't supported by the functions that translate coordinates, show an error in the console

        this.hasImplementationError = true;

        this.logger.log("[MapWMSLayerGenerator] " + "--------------------------------------------------------------------");
        this.logger.log("[MapWMSLayerGenerator] " + "IMPLEMENTATION ERROR: CRS [" + MapWMSLayerGenerator.CURRENT_CRS + "] is not supported");
        this.logger.log("[MapWMSLayerGenerator] " + "No valid BBOX build");
        this.logger.log("[MapWMSLayerGenerator] " + "--------------------------------------------------------------------");
        return "&BBOX=" + "UNKNOWN";
    }

    getBasicWMSLayerURL(mapWidth: any, mapHeight: any, rootURL: string, layers: string): string {
        let crs = MapWMSLayerGenerator.CURRENT_CRS;
        let service = "WMS";
        let version = "1.3.0";
        let transparent = "TRUE";
        let format = "image/png";
        let request = "GetMap";

        let param = rootURL.includes('?') ? '&' : '?';
        this.logger.log("[MapWMSLayerGenerator] for testing, part of the WMS URL > ", rootURL + param + "CRS=" + encodeURIComponent(crs) + "&VERSION=");
        return rootURL + param + "CRS=" + encodeURIComponent(crs) + "&VERSION=" + encodeURIComponent(version) + "&FORMAT=" + encodeURIComponent(format) + "&WIDTH=" + encodeURIComponent(mapWidth) + "&LAYERS=" + encodeURIComponent(layers) + "&SERVICE=" + encodeURIComponent(service) + "&STYLES=&HEIGHT=" + encodeURIComponent(mapHeight) + "&REQUEST=" + encodeURIComponent(request) + "&TRANSPARENT=" + encodeURIComponent(transparent)
    }
}

export enum ServerType {
    WMS = 'WMS',
    WFS = 'WFS',
    WFTS = 'WFTS'
}

export enum ServerTypeVersion {
    VERSION_1_3_0 = '1.3.0',
}
