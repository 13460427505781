/**
 * Created by Christiaan on 07/04/2017.
 */

import {Directive, NgZone, Output, EventEmitter} from '@angular/core';

@Directive({
    selector: '[globalKeyupOutsideAngular]'
})

export class GlobalKeyupOutsideAngularDirective
{
    private static readonly EVENT_KEY_UP:string = "keyup";

    @Output('globalKeyupOutsideAngular') keyupHandler: EventEmitter<any> = new EventEmitter();

    constructor(private ngZone:NgZone)
    {
        this.ngZone.runOutsideAngular(() => {
            window.addEventListener(GlobalKeyupOutsideAngularDirective.EVENT_KEY_UP, (event:any)=> {
                this.keyupHandler.emit(event);
            })
        });
    }

    //Alternatieve aanpak via hostlistener:
    /*
     @HostListener('window:keyup', ['$event'])

     handleKeyUp(event: Event) {
         this.keyupHandler.emit(event);
     }
     */
}
