<div class="mobile-search-icon d-block d-md-none" (click)="toggleMobileSearch()"><i class="material-icons">search</i></div>
<div class="form-search-container" [ngClass]="{'expanded-mobile' : showMobileSearch}" (globalClickOutsideAngular)="closeSearch(true)">
  <form class="form-inline md-form form-sm form-search">
      <div class="d-flex align-items-end search-field-container">
        <div *ngIf="(showSearchResults || showMobileSearch) && availableSearchGroups.length > 1" class="search-container">
          <div class="dropdown" (click)="preventCloseSearch()">
            <div class="current-filter dropdown-toggle" id="dropdownSearchButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{model.activeSearchGroup.label}}</div>
            <div class="dropdown-menu filter-dropdown" aria-labelledby="dropdownSearchButton">
              <ul>
                <ng-container *ngFor="let searchGroup of availableSearchGroups">
                  <li *ngIf="searchGroup != model.activeSearchGroup" (click)="changeActiveSearchGroup(searchGroup)">{{searchGroup.label}}</li>
                </ng-container>
              </ul>
            </div>
          </div>
        </div>
        <input #searchInput class="form-control menu-search" [ngClass]="{'expanded' : showSearchResults || showMobileSearch}" type="text" placeholder="{{'Zoek' | translate}}" aria-label="search" (keyup)="handleSearchFieldKeyUp($event)" (focus)="handleSearchFieldFocus()" (click)="handleSearchFieldClick($event)" title="{{'search.tooltip' | translate}}">
        <div class="mobile-search-close d-block d-md-none" (click)="toggleMobileSearch()"><i class="material-icons">close</i></div>
      </div>
      <div class="global-search-dropdown" [ngClass]="{'expanded' : showDropdownSearchResults()}" (click)="preventCloseSearch()">
        <div class="loading-block d-flex justify-content-center align-items-center" *ngIf="showLoading">
          <div class="d-none d-md-block"><img src="/assets/img/loading2022.gif" alt="{{'loading' | translate}}" title="{{'loading' | translate}}" /></div>
          <div class="d-block d-md-none"><img src="/assets/img/loading2022-blue.gif" alt="{{'loading' | translate}}" title="{{'loading' | translate}}" /></div>
        </div>
        <ul class="nav nav-tabs m-0" role="tablist" *ngIf="searchHasFinished">
          <li class="nav-item" *ngFor="let resultCategory of searchResults; let resultCategoryIndex = index">
            <a class="nav-link" [ngClass]="{'active':(!activeSearchCategory && resultCategoryIndex === 0) || (resultCategory.label == activeSearchCategory)}" (click)="changeActiveSearchCategory(resultCategory.label)">{{resultCategory.label}}</a>
          </li>
        </ul>
        <div class="tab-content" *ngIf="searchHasFinished">
          <div class="tab-pane fade" role="tabpanel" *ngFor="let resultCategory of searchResults; let resultCategoryIndex = index" [ngClass]="{'show active':(!activeSearchCategory && resultCategoryIndex === 0) || (resultCategory.label == activeSearchCategory)}">
            <div class="group-container" [attr.group-items]="resultCategory.results.length">
              <div class="group-item" *ngFor="let resultGroup of resultCategory.results">
                <h6>{{resultGroup.label}}</h6>
                <div class="group-item-content">
                  <ul class="d-flex align-content-start flex-wrap">
                    <li *ngFor="let resultItem of resultGroup.results;" (click)="handleSelectResult(resultItem)">{{resultItem.label}}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="noresults" *ngIf="searchResults.length === 0">
            {{'Geen resultaten' | translate}}
          </div>
        </div>
        <div class="results-found text-right" *ngIf="totalResultsFound > 0">{{'globalsearch.totalResults' | translate}} {{totalResultsFound}}</div>
      </div>
  </form>
</div>