import {NgModule} from '@angular/core';
import {FileSelectorComponent} from './file-selector/file-selector.component';
import {FileDropzoneDirective} from './file-selector/file-dropzone-directive/file-dropzone.directive';
import {CommonPipesModule} from '../../pipes/common-pipes.module';
import {CommonModule} from '@angular/common';
import { FileManagerComponent } from './file-manager/file-manager.component';
import { FileManagerFolderComponent } from './file-manager/file-manager-folder/file-manager-folder.component';
import {CommonUIModule} from '../commonUI/common-ui.module';


@NgModule({
    exports: [
        FileSelectorComponent,
        FileDropzoneDirective,
        FileManagerComponent
    ],
    imports: [
        CommonPipesModule,
        CommonModule,
        CommonUIModule
    ],
    declarations: [
        FileSelectorComponent,
        FileDropzoneDirective,
        FileManagerComponent,
        FileManagerFolderComponent
    ]
})
export class FileModule {
}
