/**
 * Created by Christiaan on 01/03/2017.
 */
import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {GlobalAlertService} from '../global-alert.service';
import {GlobalPopup} from '../global-popup';
import {HTTPError} from '../../../shared/services/http/http-error';
import {RequestFailure} from '../../../shared/services/http/request-failure';
import {FormDataService} from '../../../shared/components/form/services/form-data.service';
import {GlobalModel} from '../../../shared/services/state/global.model';
import {TranslateService} from '../../../shared/services/translate/translate.service';
import {AbstractBasePopupComponent} from './abstract-base-popup.component';
import {TooltipService} from '../../../shared/services/tooltip/tooltip.service';
import {FormEvent} from '../../../shared/components/form/containers/form/form.interface';
import {LumiSelectOption} from '../../../shared/components/commonUI/select/lumi-select/lumi-select.interface';
import {LoggerService} from "../../../shared/services/logger/logger.service";

@Component ({
    selector: 'global-popup-create-work-activity-component',
    changeDetection: ChangeDetectionStrategy.OnPush,
    template: `
        <default-popup-wrapper-component [componentRef]="this"
                                         [disableButtons]="readOnly"
                                         [hideButtons]="isLoading"
                                         [globalPopup]="globalPopup"
                                         [title]="ts.translate(activityDropDownData && activityDropDownData.length > 0?'activity.createtitle':'activity.updatetitle')"
                                         (onClose)="closePopup($event)">
            <ng-container *ngIf="activityDropDownData && activityDropDownData.length > 0">
                <div class="dynamic-form mb-3">
                    <div class="d-flex mt-1 ">
                        <label class="m-0 form-label-part" for="activityDropDown">
                            {{'Kies type:' | translate}}
                        </label>
                        <div class="d-flex w-100">
                            <lumi-select *ngIf="activityDropDownData?.length > 0"
                                         class="w-100"
                                         [options]="activityDropDownData"
                                         [selectedOptions]="getSelectedOptions()"
                                         [disabled]="isLoading"
                                         [showOptionFilter]="activityDropDownData.length > 10"
                                         (onOptionsSelect)="handleChangeActivity($event)">
                            </lumi-select>
                        </div>
                    </div>
                </div>
            </ng-container>

            <div *ngIf="isLoading" class="py-3 d-flex justify-content-center">
                <!--<div class="pr-3" style="line-height: 32px;height: 32px;">{{'Gegevens ophalen...' | translate}}</div>-->
                <div class="loader"></div>
            </div>

            <ng-container *ngIf="formData && config">
                <form-component #form
                                [config]="config"
                                [name]="getFormName()"
                                [validationConstraints]="validationConstraints"
                                [invalidControlsErrors]="invalidControlsErrors"
                                [readOnly]="readOnly"
                                [formIsSubmitted]="formIsSubmitted"
                                (onComponentEvent)="handleComponentEvent($event)"
                ></form-component>
            </ng-container>
        </default-popup-wrapper-component>
    `
})

export class GlobalPopupCreateWorkActivityComponent extends AbstractBasePopupComponent implements AfterViewInit {
    @Input() requestUrl: string = '';
    @Input() submitUrl: string = '';
    @Input() baseObjectId: number = -1;

    @ViewChild('form', {static: false}) form: any;

    public activityDropDownData: LumiSelectOption[] = []; // = [{value:'mast', label:this.ts.translate("Mast")}, {value:'schakelkast', label:this.ts.translate('Schakelkast')}];
    private selectedOptionId: number | string;

    public constructor(protected cd: ChangeDetectorRef, protected globalAlertService: GlobalAlertService, protected formDataService: FormDataService, protected elementRef: ElementRef, private model: GlobalModel, public ts: TranslateService, protected tooltipService: TooltipService, protected logger:LoggerService) {
        super(cd, globalAlertService, elementRef, ts, tooltipService, logger);
    }

    ngAfterViewInit() {
        // Retrieve map item id from the popup data
        this.requestUrl = this.globalPopup.data.requestUrl;
        this.submitUrl = this.globalPopup.data.submitUrl;
        this.baseObjectId = this.globalPopup.data.baseObjectId;

        // Update activity dropdowndata, and trigger view refresh
        if (this.globalPopup?.data?.activityDropDownData?.length > 0) {
            this.activityDropDownData = this.globalPopup.data.activityDropDownData.map(dropdownItem => {
                return {
                    id: dropdownItem.id,
                    name: dropdownItem.label
                };
            });
            this.selectedOptionId = this.activityDropDownData[0].id;
        }
        this.submitButton = this.getSubmitButton();
        this.cd.detectChanges();

        setTimeout(() => {
            this.requestFormData();
        });
    }

    handlePopupAction(event: MouseEvent, alert: GlobalPopup, button: any): void {
        // callback for submit button comes from this form, not from outside this component
        if (button == this.submitButton) {
            this.form.handleComponentEvent({event: FormEvent.SAVE, data: {}});
            // this.form.submitForm();
        } else {
            // Hide alert and perform callback of button
            this.onPopupAction.emit({event: event, alert: alert, button: button});
        }
    }
    
    public handleComponentEvent(eventData: any): void {
        if (eventData.event == FormEvent.SAVE) {
            this.submitFormData(eventData.data.formData);
        }
    }

    private submitFormData(form: any): void {
        this.handleSubmitForm();
        let url: string = this.submitUrl;
        
        if (this.selectedOptionId) {
            url = this.submitUrl + this.selectedOptionId + '/' + this.baseObjectId;
        }

        this.formDataService.setFormData(form, this.getFormName(), url,
            (formData: any) => {
                // this.formData = formData;
                this.handleSubmitResponse();

                // Hide alert and perform success callback via submit button
                this.onPopupAction.emit({event: null, alert: this.globalPopup, button: this.submitButton, data: formData}); // , data:{lat:form.location.latitude, lng:form.location.longitude}});
            },
            (failure: RequestFailure) => {
                this.logger.log('[GlobalPopupCreateBaseObjectComponent] ' + 'Handle failure response: ', failure);
                this.handleSubmitResponse(failure.formErrors);
            },
            (error: HTTPError) => {
                this.handleSubmitResponse(null, true);
            }
        );
    }

    public closePopup(event: any): void {
        this.doDefaultCloseHandling(event, true);
    }

    public handleChangeActivity($event: LumiSelectOption[]): void {
        this.selectedOptionId = $event[0].id;
        this.formData = null;
        this.requestFormData();
    }

    public requestFormData(): void {
        this.isLoading = true;
        let url: string = this.requestUrl;
    
        if (this.selectedOptionId) {
            url = this.requestUrl + this.selectedOptionId + '/' + this.baseObjectId;
        }

        this.formDataService.getFormData(url,
            (formData: any) => {
                this.formData = formData;
                this.isLoading = false;
                this.cd.detectChanges();
            },
            () => {
                this.closePopup(null);
            },
            () => {
                this.closePopup(null);
            }
        );
    }
    
    public getSelectedOptions() {
        return this.activityDropDownData.filter(item => item.id === this.selectedOptionId);
    }
}
